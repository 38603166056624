import {GridColumnBase} from '../../../../shared/grid.column.base';
import {GridColumnDefinition} from '../../../../shared/grid.column.definition';
import {TranslateService} from '@ngx-translate/core';
import {SpecialEquipment, VehicleStatus} from '../../model/vehicle.model';

export class VehicleStatusGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;
  params: any;
  dialogTitle: string;
  public showVehicleInformation: boolean = false;
  specialEquipments: SpecialEquipment[] = [];
  modelTypeCode: string;
  createdDate: Date;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
    this.params = {
      rowData: new VehicleStatus(),
      $event: {}
    };
  }

  columns = [{
    field: '#',
    headerName: '',
    width: 40,
    minWidth: 40,
    rowGroup: false,
    hide: false,
    editable: false,
    filter: false,
    checkboxSelection: true,
    }, {
      field: 'derivate',
      headerName: 'derivativeCode',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'prodType',
      headerName: 'prodType',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'vin',
      headerName: 'vin',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'order',
      headerName: 'order',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'transferPriceProd',
      headerName: 'transferPriceProd',
      sortable: true,
      filter: true,
      resizable: true,
    valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'f2Date',
      headerName: 'f2Date',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'plannedDate',
      headerName: 'plannedDate',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'transferPrice',
      headerName: 'vehicleTransferPrice',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.moneyFormatter,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'vehicleStatus',
      headerName: 'vehicleStatus',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'reason',
      headerName: 'reason',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'information',
      headerName: 'operationInformation',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellRenderer: 'dialogueComponent',
      cellRendererParams: {
        onCellClicked: this.information.bind(this)
      }
    }];

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  information(params: any) {
    this.params = params;
    let vehicleStatus = this.params.rowData;
    this.modelTypeCode = vehicleStatus.modelTypeCode;
    this.specialEquipments = vehicleStatus.specialEquipments;
    this.createdDate = vehicleStatus.createdDate;
    this.showVehicleInformation = true;
  }
}
