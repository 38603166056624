import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridApi, GridOptions } from 'ag-grid-community';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'bmw-example-component',
  template: `
    <ag-grid-angular
      style="height: 500px; overflow: scroll"
      class="zebra"
      [rowData]="runtimeCompilerData.data"
      [columnDefs]="runtimeCompilerData.columns"
      [gridOptions]="runtimeCompilerData.gridOptions"
      [defaultColDef]="runtimeCompilerData.defaultColDef"
      [sideBar]="runtimeCompilerData.sideBar"
      [statusBar]="runtimeCompilerData.statusBar"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
  `
})
export class BmwexampleComponent {
  runtimeCompilerData = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        width: 80,
        minWidth: 80,
        enableRowGroup: false,
        checkboxSelection: true,
        editable: false
      },
      {
        field: 'carName',
        headerName: 'Car Name',
        width: 200,
        minWidth: 200
      },
      {
        field: 'year',
        headerName: 'Year',
        width: 80,
        minWidth: 80,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorParams: {
          values: [
            '2010',
            '2011',
            '2012',
            '2013',
            '2014',
            '2015',
            '2016',
            '2017',
            '2018',
            '2019',
            '2020',
            '2021',
            '2022',
            '2023'
          ]
        },
        filter: false,
        filterParams: {
          // tslint:disable-next-line:only-arrow-functions
          comparator(filterLocalDateAtMidnight, cellValue): number {
            const dateAsString = cellValue;
            if (dateAsString == null) { return -1; }
            const dateParts = dateAsString.split('/');
            const cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          browserDatePicker: true
        }
      },
      {
        field: 'maxHP',
        headerName: 'Maximum power - Horsepower (hp)',
        width: 100,
        minWidth: 100,
        filter: 'agSetColumnFilter'
      },
      {
        field: 'engSize',
        headerName: 'Engine size - Engine capacity',
        width: 150,
        minWidth: 150,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 500 }
      },
      {
        field: 'range',
        headerName: 'Range',
        width: 150,
        minWidth: 150
      },
      {
        field: 'fuelCap',
        headerName: 'Fuel Tank Capacity',
        width: 150,
        minWidth: 150
      },
      {
        field: 'co2Emissons',
        headerName: 'CO2 emissions',
        width: 150,
        minWidth: 150
      },
      {
        field: 'topSpeed',
        headerName: 'Top Speed',
        width: 150,
        minWidth: 150
      },
      {
        field: 'accelaration100KMh',
        headerName: 'Acceleration 0 to 100 km/h (0 to 62 mph)',
        width: 150,
        minWidth: 150
      },
      {
        field: 'accelaration1000m',
        headerName: 'Acceleration 0 to 1000m',
        width: 150,
        minWidth: 150
      }
    ],
    data: [
      {
        id: 1,
        carName: 'F10 5 Series Sedan 525d',
        year: 2010,
        maxHP: 204,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1129 Km or 702 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '162 g/Km (BMW)',
        topSpeed: '236 km/h or 147 Mph',
        accelaration100KMh: '7.2 s',
        accelaration1000m: '27.7 s'
      },
      {
        id: 2,
        carName: 'F10 5 Series Sedan 525d',
        year: 2011,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1400 Km or 870 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '132 g/Km (BMW)',
        topSpeed: '243 km/h or 151 Mph',
        accelaration100KMh: '7.0 s',
        accelaration1000m: '27.5 s'
      },
      {
        id: 3,
        carName: 'F10 5 Series Sedan 525d',
        year: 2012,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1400 Km or 870 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '132 g/Km (BMW)',
        topSpeed: '243 km/h or 151 Mph',
        accelaration100KMh: '7.0 s',
        accelaration1000m: '27.5 s'
      },
      {
        id: 4,
        carName: 'F10 5 Series Sedan 525d Aut',
        year: 2010,
        maxHP: 204,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1147 Km or 713 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '161 g/Km (BMW)',
        topSpeed: '236 km/h or 147 Mph',
        accelaration100KMh: '7.2 s',
        accelaration1000m: '27.8 s'
      },
      {
        id: 5,
        carName: 'F10 5 Series Sedan 525d Aut',
        year: 2011,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1458 Km or 906 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '126 g/Km (BMW)',
        topSpeed: '239 km/h or 149 Mph',
        accelaration100KMh: '7.0 s',
        accelaration1000m: '27.5 s'
      },
      {
        id: 6,
        carName: 'F10 5 Series Sedan 525d Aut',
        year: 2012,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1458 Km or 906 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '126 g/Km (BMW)',
        topSpeed: '239 km/h or 149 Mph',
        accelaration100KMh: '7.0 s',
        accelaration1000m: '27.5 s'
      },
      {
        id: 7,
        carName: 'F10 5 Series Sedan 525d xDrive',
        year: 2012,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1372 Km or 853 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '134 g/Km (BMW)',
        topSpeed: '234 km/h or 145 Mph',
        accelaration100KMh: '7.2 s',
        accelaration1000m: '27.6 s'
      },
      {
        id: 8,
        carName: 'F10 5 Series Sedan 525d xDrive Aut',
        year: 2011,
        maxHP: 218,
        engSize: '1995 cm3 (121.7 cu-in)',
        range: '1372 Km or 853 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '134 g/Km (BMW)',
        topSpeed: '234 km/h or 145 Mph',
        accelaration100KMh: '7.0 s',
        accelaration1000m: '27.6 s'
      },
      {
        id: 9,
        carName: 'F10 5 Series Sedan 530d',
        year: 2010,
        maxHP: 245,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1111 Km or 690 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '166 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.3 s',
        accelaration1000m: '26.5 s'
      },
      {
        id: 10,
        carName: 'F10 5 Series Sedan 530d',
        year: 2011,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1228 Km or 763 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '149 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.1 s',
        accelaration1000m: '26.0 s'
      },
      {
        id: 11,
        carName: 'F10 5 Series Sedan 530d',
        year: 2012,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1228 Km or 763 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '149 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.1 s',
        accelaration1000m: '26.0 s'
      },
      {
        id: 12,
        carName: 'F10 5 Series Sedan 530d Aut',
        year: 2010,
        maxHP: 245,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1129 Km or 702 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '162 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.3 s',
        accelaration1000m: '26.5 s'
      },
      {
        id: 13,
        carName: 'F10 5 Series Sedan 530d Aut',
        year: 2011,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1320 Km or 820 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '139 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.0 s',
        accelaration1000m: '25.9 s'
      },
      {
        id: 14,
        carName: 'F10 5 Series Sedan 530d Aut',
        year: 2012,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1320 Km or 820 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '139 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.0 s',
        accelaration1000m: '25.9 s'
      },
      {
        id: 15,
        carName: 'F10 5 Series Sedan 530d xDrive',
        year: 2011,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1228 Km or 763 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '150 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.1 s',
        accelaration1000m: '25.8 s'
      },
      {
        id: 16,
        carName: 'F10 5 Series Sedan 530d xDrive',
        year: 2012,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1272 Km or 791 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '146 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.1 s',
        accelaration1000m: '26.1 s'
      },
      {
        id: 17,
        carName: 'F10 5 Series Sedan 530d xDrive Aut',
        year: 2011,
        maxHP: 258,
        engSize: '2993 cm3 (182.6 cu-in)',
        range: '1272 Km or 791 miles',
        fuelCap: `70 L
        15.4 UK gallons
        18.5 US gallons`,
        co2Emissons: '146 g/Km (BMW)',
        topSpeed: '250 km/h or 155 Mph',
        accelaration100KMh: '6.1 s',
        accelaration1000m: '26.1 s'
      }
    ],
    gridOptions: {
      getRowStyle: params => {
        return { order: params.node.rowIndex };
      },
      postSort: params => {
        // it needs to wait until new order is set
        setTimeout(() => {
          params.forEach(param => {
            param.updateData(param.data);
          });
        });
      },
      ensureDomOrder: true,
      suppressMovableColumns: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowHeight: 32,
      headerHeight: 32,
      enableRangeSelection: true,
      enableRangeHandle: true,
      rowGroupPanelShow: 'onlyWhenGrouping'
    } as GridOptions,
    defaultColDef: {
      sortable: true,
      unSortIcon: true,
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: 'textFilter',
      defaultColToEdit: '',
      autoHeight: true,
      resizable: true
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: undefined
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left'
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    }
  };

  private gridApi: GridApi;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  constructor(private http: HttpClient) {}

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
  }
}
