<form data-testid="form-edit" [formGroup]="editOperationForm" class="reversed">
  <div *ngIf="errorMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
  </div>
  <bmw-progress-circle data-testid="progress-loadingScreen" [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
  <bmw-fieldset data-testid="fieldset-edit-operation" opened="true">
    <bmw-fieldset-header>{{ 'menu.manufacturing-cost.tabs.operations.fieldset-header-edit' | translate }}</bmw-fieldset-header>
    <bmw-fieldset-container>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l">
        <bmw-inputfield data-testid="input-field-operation-name" id="operationName">
          <bmw-label>{{ 'menu.manufacturing-cost.tabs.operations.name' | translate }}</bmw-label>
          <input
            [required]="true"
            data-testid="input-operation-name"
            bmwInput
            type="text"
            name="operationName"
            formControlName="operationName"
            data-e2e="operationName"
            maxlength="255"
          />
          <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
        </bmw-inputfield>
        <bmw-label for="valid-from">{{ 'menu.manufacturing-cost.tabs.operations.validfrom' | translate }}</bmw-label>
        <bmw-datepicker
          [required]="true"
          inputId="valid-from"
          formControlName="validFrom"
          placeholder="DD/MM/YYYY"
          dateFormat="dd/mm/yy"
          data-e2e="valid-from"
          monthNavigator="true"
          yearNavigator="true"
          yearRange="2021:2051"
          [minDate]="minDate"
        >
          <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
        </bmw-datepicker>
      </div>
      <div class="pull-right">
        <bmw-button data-testid="button-submit" button="primary" type="submit" [disabled]="!editOperationForm.valid" (clickEvent)="onSubmit()" class="bmw__margin-right__xs">
          {{ 'menu.manufacturing-cost.tabs.operations.save' | translate }}
        </bmw-button>
      </div>
      <div class="pull-left">
        <bmw-button data-testid="button-cancel" (clickEvent)="cancelEditOperation()" button="secondary">
          {{ 'menu.manufacturing-cost.tabs.operations.cancel' | translate }}
        </bmw-button>
      </div>
    </bmw-fieldset-container>
  </bmw-fieldset>
</form>
