<div *ngIf="errorMessage"
     class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
  <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
</div>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
<div *ngIf="!errorMessage" class="bmw__margin-top__xs">
  <div *ngIf="warnMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="error">{{warnMessage}}</bmw-inline-message>
  </div>
  <div *ngIf="infoMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message *ngIf="infoMessage" type="info" (closeEvent)="closeInfoMessage();">
      {{infoMessage}}
    </bmw-inline-message>
  </div>
  <div class="bmw__margin-top__xs">
    <app-product-master-data-display-grid
      [rowData]="rowData"
      [noSeriesCalculationRole]="noSeriesCalculationRole"
      (refresh)="refreshData()"
      (delete)="getConfirmation($event)"
    ></app-product-master-data-display-grid>
  </div>
</div>

<bmw-dialog
  [type]="'warning'"
  [show]="showConfirm"
  [closeButton]="true"
  [title]="'Confirm deletion'"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="deleteData()"
  (secondaryClick)="showConfirm = false"
  (closeClick)="showConfirm = false">
  <p class="bmw-regular-text">{{ 'messages.confirm-deletion' | translate }}
  </p>
</bmw-dialog>
<bmw-dialog
  [type]="'info'"
  [show]="showPrompt"
  [closeButton]="true"
  [title]="'Nothing is selected to delete.'"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showPrompt = false"
  (closeClick)="showPrompt = false">
  <p class="bmw-regular-text">{{ 'messages.please-select' | translate }}</p>
</bmw-dialog>

<bmw-dialog
  [type]="'info'"
  [show]="showDeletePrompt"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showDeletePrompt = false"
  (closeClick)="showDeletePrompt = false">
  <p class="bmw-regular-text">{{ 'messages.delete-error' | translate }}</p>
</bmw-dialog>
