import { Component, OnInit } from '@angular/core';
import {ProductionWeekUpdateRequest} from "../model/aggregations.models";
import {AggregationService} from "../service/aggregation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-aggregation-update-price',
  templateUrl: './aggregation-update-price.component.html',
  styleUrls: ['./aggregation-update-price.component.scss']
})
export class AggregationUpdatePriceComponent implements OnInit {

  loading: boolean = false;
  inLineMessage: string;
  rowData: any;
  inlineType: string = 'success';

  constructor(private service: AggregationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.inLineMessage = null;
    this.loadPriceRecalcRequests();
  }

  updateBomPrice(request: ProductionWeekUpdateRequest) {
    this.loading = true;
    this.inLineMessage = undefined;
    this.inlineType = 'success';
    if (!request.isError) {
      this.service.updateBomPrices(request).subscribe(({data}) => {
        this.inLineMessage = this.translate.instant('menu.period-end-closing.price-update-initiated');
        this.loadPriceRecalcRequests();
        this.loading = false;
      }, error => {
        this.inLineMessage = request.error;
        this.inlineType = 'error';
        this.loading = false;
      });
    } else {
      this.inLineMessage = request.error;
      this.inlineType = 'error';
      this.loading = false;
    }
  }

  loadPriceRecalcRequests() {
    this.service.recalculationRequests().subscribe(({data, loading}) => {
      this.rowData = this.service.toDomain(data.allPriceRecalculationRequest);
    });
  }

  onRefresh() {
    this.loadPriceRecalcRequests();
  }
}
