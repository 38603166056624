import {ICellEditorAngularComp} from "ag-grid-angular";
import {Component} from "@angular/core";
import moment from "moment";

@Component({
  selector: 'date-editor-cell',
  template: `
    <div style="width:200px;min-width: 200px;max-width:200px;">
      <bmw-combo-box (changeEvent)="onStatusSelected($event)"  >
        <bmw-combo-box-dropdown-item value="OPENED" label="OPENED"></bmw-combo-box-dropdown-item>
        <bmw-combo-box-dropdown-item value="CANCELLED" label="CANCELLED"></bmw-combo-box-dropdown-item>
        <bmw-combo-box-dropdown-item value="CLOSED" label="CLOSED"></bmw-combo-box-dropdown-item>
      </bmw-combo-box>
    </div>
  `
})
export class OrderStatusCellComponent implements ICellEditorAngularComp {
  private params: any;

  public selectedStatus: any;

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    return this.selectedStatus;
  }

  isPopup(): boolean {
    return true;
  }

  onStatusSelected(status) {
    this.selectedStatus = status;
    this.params.api.stopEditing();
  }

}
