import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {OrdersViewModel} from "../model/OrdersViewModel";
import {PricingPeriodWithOrderDetails} from "../model/PricingPeriodWithOrderDetails";
import {SurchargesForOrder} from "../model/SurchargesForOrder";
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../../header/service/LanguageChangeService";
import {Subscription} from "rxjs";
import moment from "moment";
import {SurchargeViewModel} from "../model/order.model";

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit , OnDestroy {

  @Input() rowData: OrdersViewModel[];
  @Input() baseOrder: OrdersViewModel;
  @Input() showSurcharges: boolean = true;

  resizeSurchargeGrid: boolean;
  surchargeData: any;
  pricingData: any;
  errorMessage: string;
  loadingScreen: boolean = false;
  groupByColumns: string[];
  pricingPeriodViewModel: PricingPeriodWithOrderDetails;
  surchargeViewModel: SurchargesForOrder;
  subscription: Subscription;
  pricingColumns: any = [];
  surchargeColumns: any = [];

  constructor(private translate: TranslateService,
              private langService: LanguageChangeService) {
    this.pricingPeriodViewModel = new PricingPeriodWithOrderDetails();
    this.surchargeViewModel = new SurchargesForOrder();
    this.subscription = this.langService.onChange().subscribe( e=> {
      this.translateAndLoadColumns();
    });
  }

  ngOnInit(): void {
    this.translateAndLoadColumns();
    this.loadData();
  }

  loadData() {
    let selectedOrder = this.baseOrder.orderNo;
    this.pricingData = this.pricingPeriodViewModel.getPricingPeriodsForOrder(selectedOrder, this.rowData);
    this.surchargeData = this.surchargeViewModel.getSurchargesForOrder(selectedOrder, this.rowData);
    this.hideOrDisplaySurcharges(this.surchargeData);
  }

  hideOrDisplaySurcharges(surcharges: SurchargeViewModel[]) {
    surcharges.forEach(s => {
      if (s.surchargeCategory == undefined || s.surchargeCategory == null) {
        this.showSurcharges = false;
      }
    });
  }

  reDrawSurchargeGrid(params: any) {
    this.resizeSurchargeGrid = true;
  }

  translateAndLoadColumns() {
    this.pricingColumns = [
      {
        field: 'fromDate',
        headerName: this.localizeHeader('fromDate'),
        sortable: true
      },
      {field: 'toDate', headerName: this.localizeHeader('toDate'), sortable: true},
      {field: 'priceUnit', headerName: this.localizeHeader('priceUnit'), sortable: false},
      {
        field: 'basePriceValue',
        headerName: this.localizeHeader('basePriceValue'),
        type: 'rightAligned',
        sortable: false
      },
      {field: 'basePriceCurrency', headerName: this.localizeHeader('basePriceCurrency'), sortable: false},
      {
        field: 'nettPriceValue',
        headerName: this.localizeHeader('nettPriceValue'),
        type: 'rightAligned',
        sortable: false
      },
      {field: 'nettPriceCurrency', headerName: this.localizeHeader('nettPriceCurrency'), sortable: false},
      {
        field: 'surchargeValue',
        headerName: this.localizeHeader('surchargeValue'),
        type: 'rightAligned',
        sortable: false
      }
    ];

    this.surchargeColumns = [
      {
        field: 'fromDate',
        headerName: this.localizeHeader('fromDate'),
        sortable: true,
        resizable: true
      },
      {
        field: 'toDate',
        headerName: this.localizeHeader('toDate'),
        sortable: true,
        resizable: true
      },
      {
        field: 'surchargeValue',
        headerName: this.localizeHeader('surchargeValue'),
        type: 'rightAligned',
        sortable: false,
        resizable: true
      },
      {field: 'surchargeType', headerName: this.localizeHeader('surchargeType'), sortable: false, resizable: true},
      {
        field: 'surchargeCategory',
        headerName: this.localizeHeader('surchargeCategory'),
        sortable: false,
        resizable: true
      },
      {
        field: 'surchargeCurrency',
        headerName: this.localizeHeader('surchargeCurrency'),
        sortable: false,
        resizable: true
      }
    ];
  }

  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
