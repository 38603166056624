<form data-testid="form-price-search-bar" [formGroup]="productionWeekSearchForm">
  <bmw-progress-circle [fullScreen]="true" loadingLabel="loading..." [show]="loading"></bmw-progress-circle>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <bmw-fieldset opened="true">
      <bmw-fieldset-header>{{ 'component.search-bar.search-criteria' | translate }}</bmw-fieldset-header>
      <bmw-fieldset-container>
        <div class="bmw__grid__inner">
          <div class="bmw__grid__cell bmw__grid__cell&#45;&#45;span-4-mobile bmw__grid__cell&#45;&#45;span-4-tablet bmw__grid__cell&#45;&#45;span-2 main-form">
            <bmw-combo-box formControlName="productionYear" (changeEvent)="getWeeksAndDerivateCodes($event)">
              <bmw-label>{{ 'component.search-bar.year' | translate }}</bmw-label>
              <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let year of years" value="{{year}}"
                                           label="{{year}}"></bmw-combo-box-dropdown-item>
            </bmw-combo-box>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell&#45;&#45;span-4-mobile bmw__grid__cell&#45;&#45;span-4-tablet bmw__grid__cell&#45;&#45;span-2 main-form">
            <bmw-combo-box formControlName="productionWeek">
              <bmw-label>{{ 'component.search-bar.week' | translate }}</bmw-label>
              <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let week of weeks" value="{{week}}"
                                           label="{{week}}"></bmw-combo-box-dropdown-item>
            </bmw-combo-box>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell&#45;&#45;span-4-mobile bmw__grid__cell&#45;&#45;span-4-tablet bmw__grid__cell&#45;&#45;span-2 main-form">
            <bmw-combo-box formControlName="derivate">
              <bmw-label>{{ 'component.search-bar.derivate' | translate }}</bmw-label>
              <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let derivate of derivateCode" value="{{derivate}}"
                                           label="{{derivate}}"></bmw-combo-box-dropdown-item>
            </bmw-combo-box>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield data-testid="input-snr" id="snr">
              <bmw-label>{{ 'component.search-bar.snr' | translate }}</bmw-label>
              <input data-testid="input-field-snr"
                     bmwInput
                     type="text"
                     name="snr"
                     style="text-transform: uppercase;"
                     maxlength="7"
                     formControlName="snr"
                     data-e2e="snr"
              />
              <bmw-error-message data-testid="error-snr">{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield data-testid="input-ai" id="ai">
              <bmw-label>{{ 'component.search-bar.ai' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                name="ai"
                maxlength="2"
                formControlName="ai"
              />
              <bmw-error-message data-testid="error-ai">{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
            <bmw-button data-testid="button-submit" button="primary" type="submit" (clickEvent)="update()" >
              {{ 'component.search-bar.update' | translate }}
            </bmw-button>
          </div>
        </div>
      </bmw-fieldset-container>
    </bmw-fieldset>
  </div>
</form>

