<form [formGroup]="operationForm" class="reversed">
  <div *ngIf="errorMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
  </div>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
  <bmw-fieldset opened="true">
    <bmw-fieldset-header>{{ 'menu.manufacturing-cost.tabs.operations.fieldset-header-clone' | translate }}</bmw-fieldset-header>
    <bmw-fieldset-container>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l">
        <bmw-inputfield id="operationName">
          <bmw-label>{{ 'menu.manufacturing-cost.tabs.operations.name' | translate }}</bmw-label>
          <input
            [required]="true"
            bmwInput
            type="text"
            name="operationName"
            formControlName="operationName"
            data-e2e="operationName"
          />
          <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
        </bmw-inputfield>
        <bmw-label for="date-of-birth">{{ 'menu.manufacturing-cost.tabs.operations.validfrom' | translate }}</bmw-label>
        <bmw-datepicker
          [required]="true"
          inputId="valid-from"
          formControlName="validFrom"
          placeholder="DD/MM/YYYY"
          dateFormat="dd/mm/yy"
          data-e2e="valid-from"
          monthNavigator="true"
          yearNavigator="true"
          yearRange="2021:2051"
          [minDate]= "minDate"
        >
          <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
        </bmw-datepicker>
        <bmw-inputfield *ngIf="type==='clone'" id="comment">
          <bmw-label>{{ 'menu.manufacturing-cost.tabs.operations.comment' | translate }}</bmw-label>
          <textarea
            [required]="true"
            bmwInput
            type="text"
            name="comment"
            formControlName="comment"
            placeholder="Your comment.."
            rows="8"
          ></textarea>
          <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
        </bmw-inputfield>
      </div>
      <div class="pull-left">
        <bmw-button button="primary" type="submit" [disabled]="!operationForm.valid" (clickEvent)="submit()" class="bmw__margin-right__xs">
          {{ 'menu.manufacturing-cost.tabs.operations.save' | translate }}
        </bmw-button>
      </div>
      <div class="pull-right">
        <bmw-button (clickEvent)="cancel()" button="secondary">
          {{ 'menu.manufacturing-cost.tabs.operations.cancel' | translate }}
        </bmw-button>
      </div>

    </bmw-fieldset-container>
  </bmw-fieldset>
</form>

