import gql from "graphql-tag";
import {Order, Plant} from "../model/order.model";

export const ORDER_FOR_WORKFLOW_STATE = gql`
  query ordersForWorkFlowState($plant: PlantInput!,$orderWorkflowState: OrderWorkflowStateInput!) {
    ordersForWorkFlowState(plant: $plant , orderWorkflowState: $orderWorkflowState) {
    changeDate
    material{
      ai
      snr
    }
    internalIdentifier
    orderCurrency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderNo
    orderState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity{
        fromDate
        toDate
      }
      surcharges{
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
    }
    relatedOrders {
      changeDate
      material {
       ai
       snr
      }
      internalIdentifier
        orderCurrency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      orderNo
      orderState
      orderType
      quantityUnit
      supplierNo
      version
      workflowState
      pricingPeriods {
        internalIdentifier
        priceUnit
        basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
     }
   }
  }
 }
`;

export interface OrderForWorkFlowStateResponse {
  ordersForWorkFlowState: Order[];
}

export type OrderForWorkFlowStateVariables = {
  plant: Plant;
  orderWorkflowState: OrderWorkflowStateInput;
}

export enum OrderWorkflowStateInput {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL'
}
