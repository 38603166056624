import { Component } from '@angular/core';
import {GridApi, ICellRendererParams} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY_STRING, isUndefinedOrEmpty} from '../../../../shared/functions/typescript.utils';
import moment from "moment";
import {PricingAndPaymentColumns} from './pricing-and-payment.columns';
import {AgGridBase} from '../../../../ag.grid.base';

@Component({
  selector: 'app-pricing-and-payments-grid',
  templateUrl: './pricing-and-payments-grid.component.html',
  styleUrls: ['./pricing-and-payments-grid.component.scss']
})
export class PricingAndPaymentsGridComponent extends AgGridBase<PricingAndPaymentColumns> {

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;

  constructor(private translate: TranslateService) {
    super(new PricingAndPaymentColumns(translate));
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    const defaultSortModel = [
      {colId: 'derivative', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
  }
  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  processRowPostCreate(params): void {
    setTimeout(function() {
      if (params.node?.data?.selected) {
        params.node.setSelected(true);
      }
    });
  }
}
