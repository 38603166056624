import { Component, OnInit } from '@angular/core';
import { foundCars, configurationTableColumns, configurationTableData } from 'src/app/mock-data';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';

@Component({
  templateUrl: './master-detail-template.component.html',
  styleUrls: ['./master-detail-template.component.less'],
})
export class MasterDetailTemplateComponent implements OnInit {
  rows = foundCars;
  selectedItem = null;
  configurationTableColumns = configurationTableColumns;
  configurationTableData = configurationTableData;

  constructor(breadcrumbs: BreadcrumbsService) {
    breadcrumbs.update([
      {
        label: 'Rental Cars',
        url: '/',
      },
      {
        label: 'Available Cars',
        url: '/',
      },
      {
        label: 'Master Detail Template',
        url: '/master-detail-template',
      },
    ]);
  }

  ngOnInit(): void {}
}
