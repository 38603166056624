<ag-grid-angular
  style="height: 500px; overflow: scroll"
  class="zebra"
  [rowData]="rowData"
  [columnDefs]="runtimeCompilerData.columns"
  [gridOptions]="runtimeCompilerData.gridOptions"
  [defaultColDef]="runtimeCompilerData.defaultColDef"
  [sideBar]="runtimeCompilerData.sideBar"
  [statusBar]="runtimeCompilerData.statusBar"
  [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="onModelUpdated($event)"
  (rowDataChanged)="refreshGrid($event)"
  (columnRowGroupChanged)="onSaveGridState($event)"
  (columnValueChanged)="onSaveGridState($event)"
  (columnVisible)="onSaveGridState($event)"
  (columnPinned)="onSaveGridState($event)"
  (columnMoved)="onSaveGridState($event)"
  (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
  [pagination]="true"
>
</ag-grid-angular>
