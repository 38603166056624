
export function isUndefinedOrEmpty(value: any): boolean {
  let result: boolean;
  switch(typeof value) {
    case "string":
      result = String(value) === "" || value === undefined || value === null;
      break;
    case "number":
      result = value === undefined || value === null;
      break;
    default: {
      result = value === undefined || value === null || value === NaN;
      break;
    }
  }
  return result;
}

export const EMPTY_STRING = "";
