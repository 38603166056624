import {Apollo, QueryRef} from "apollo-angular";
import {DocumentNode} from "graphql";
import {ApolloQueryResult} from "apollo-client";
import {Observable} from "rxjs";
import {FetchResult} from "apollo-link";

export abstract class ServiceBase {

  private queryReference: QueryRef<any>;
  private apollo: Apollo;

  abstract dispose(): void;

  protected constructor(apollo: Apollo) {
    this.apollo = apollo;
  }

  query<T>(args: any, queryName: DocumentNode, clientName: string = 'default'): Observable<ApolloQueryResult<T>> {
    if (clientName.localeCompare('default') == 0) {
      this.queryReference = this.apollo.watchQuery({
        query: queryName,
        fetchPolicy: "network-only",
        variables: args
      });
    } else {
      this.queryReference = this.apollo.use(clientName).watchQuery({
        query: queryName,
        fetchPolicy: "network-only",
        variables: args
      });
    }

    return this.queryReference.valueChanges;
  }

  mutate<T>(args: any, mutationName: DocumentNode, clientName: string = 'default'): Observable<FetchResult<T>> {
    if (clientName.localeCompare('default') == 0) {
      return this.apollo.mutate({
        mutation: mutationName,
        variables: args
      })
    }
    return this.apollo.use(clientName).mutate({
      mutation: mutationName,
      variables: args
    })
  }

  removeGraphQLErrorOnMessage(errorMessage: string) {
    return errorMessage.replace('GraphQL error: ', '');
  }
}
