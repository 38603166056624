import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class RoleGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
      {
        field: '#',
        headerName: '',
        width: 40,
        minWidth: 40,
        rowGroup: false,
        hide: false,
        editable: false,
        checkboxSelection: true,
        cellRenderer: (params) => {
          params.node.setSelected(params.data.checked);
        },
      },
     {
       field: 'description',
       headerName: 'permission.grid.description',
       resizable: true,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
     },
    {
      field: 'name',
      headerName: 'permission.grid.name',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    },
    {
      field: 'internalIdentifier',
      headerName: 'ID',
      resizable: true,
      rowGroup: false,
      hide: true,
      editable: false,
    }

   ];

}
