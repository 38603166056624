import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(public authService: AuthService,
              public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const expectedRole = route.data.expectedRole;
    const payload = this.authService.getTokenPayload();
    const roleExists = payload.authorities.includes(expectedRole);
    if (!this.authService.isAuthenticated() || !roleExists) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

}
