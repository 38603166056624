import {Money} from '../../manufacturing/model/operations.model';

export class VehicleStatus {
  derivate: string;
  vehicleModelType: string;
  requestNumber: string;
  plannedDate: Date;
  f2Date: Date;
  createdDate: Date;
  productGroup: ProductGroup;
  transferPrice: TransferPrice;
  validityDate: Date;
  vin17: string;
  specialEquipments: SpecialEquipment[];
  vehicleStatus: string;
  year: string;
  month: string;
}

export class SpecialEquipment {
  specialEquipmentCode: string;
  productStatus: string;
}

export enum ProductGroup {
  BODY_SHELL = 'BODY_SHELL',
  CBU = 'CBU',
  CKD = 'CKD',
  MOTOR_BIKE = 'MOTOR_BIKE',
  PSEUDO_ORDERS = 'PSEUDO_ORDERS',
  REPLACEMENT = 'REPLACEMENT',
  SKD = 'SKD'
}


export enum Plant {
  MSF= 'MSF',
  NEDCAR= 'NEDCAR',
  SAL= 'SAL',
  GWM= 'GWM'
}

export class TransferPrice {
  amount: Money;
  vehiclePriceStatus: VehiclePriceStatus;
  reason: string;
}

export enum VehiclePriceStatus {
  IMPORTED = 'IMPORTED',
  RELEASED = 'RELEASED',
  BLOCKED_EXCEEDS_THRESHOLD = 'BLOCKED EXCEEDS THRESHOLD',
  BLOCKED_MISSING_PRICE = 'BLOCKED MISSING PRICE',
  CANCELLED = 'CANCELLED',
  MANUALLY_RELEASED = 'MANUALLY RELEASED'
}
