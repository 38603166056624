import {Injectable} from "@angular/core";
import {Apollo, QueryRef} from 'apollo-angular';
import {
  LATEST_ORDER_QUERY,
  LATEST_ORDER_SNR_AI_QUERY,
  LatestOrderQueryResponse,
  LatestOrderSnrAiQueryResponse,
  LatestOrderSnrAiVariables,
  LatestOrderVariables
} from "../order-latest/order-latest.graphql.query";
import {OrderState, Plant} from "../model/order.model";
import {Transformer} from "../../../helper/transformer";
import {
  ORDER_HISTORY_QUERY,
  ORDER_HISTORY_SNR_AI_QUERY,
  OrderHistoryQueryResponse,
  OrderHistorySnrAiQueryResponse,
  OrderHistorySNRAIVariables,
  OrderHistoryVariables
} from "../order-history/orders.graphql.query";
import {OrdersViewModel} from "../model/OrdersViewModel";
import {DateRangeInput, MaterialInput, OrderChangeInputInput, PricingPeriodChangeInputInput} from "./order.mutations";
import {
  ORDER_FOR_WORKFLOW_STATE,
  OrderForWorkFlowStateResponse,
  OrderForWorkFlowStateVariables,
  OrderWorkflowStateInput
} from "./order.graphql.query";
import {FaasDatesHelper} from "../../../helper/faas.dates.helper";
import {AuthService} from "../../../services/auth.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  orderSnrAiVersionsRef: QueryRef<OrderHistorySnrAiQueryResponse, OrderHistorySNRAIVariables>;
  orderVersionsRef: QueryRef<OrderHistoryQueryResponse, OrderHistoryVariables>;
  orderSnrAiRef: QueryRef<LatestOrderSnrAiQueryResponse, LatestOrderSnrAiVariables>;
  orderRef: QueryRef<LatestOrderQueryResponse, LatestOrderVariables>;
  orderWorkFlowStateRef: QueryRef<OrderForWorkFlowStateResponse, OrderForWorkFlowStateVariables>;
  transformer: any;
  _apollo: Apollo;

  constructor(private translate: TranslateService,
              private apollo: Apollo,
              private authService: AuthService) {
    this.transformer = new Transformer();
    this._apollo = apollo;
  }

  latestOrder(orderNumber: string, orderWorkflowState: OrderWorkflowStateInput): QueryRef<LatestOrderQueryResponse, LatestOrderVariables> {

    this.orderRef = this.apollo.watchQuery<LatestOrderQueryResponse, LatestOrderVariables>({
      query: LATEST_ORDER_QUERY,
      fetchPolicy: "network-only"
    });

    this.orderRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      orderNo: orderNumber?.trim(),
      orderWorkflowState:orderWorkflowState
    });

    return this.orderRef;
  }

  latestOrderWithSnrAi(ai: number , snr: string[], orderWorkflowState: OrderWorkflowStateInput): QueryRef<LatestOrderSnrAiQueryResponse, LatestOrderSnrAiVariables> {
    this.orderSnrAiRef = this.apollo.watchQuery<LatestOrderSnrAiQueryResponse, LatestOrderSnrAiVariables>({
      query: LATEST_ORDER_SNR_AI_QUERY,
      fetchPolicy: "network-only"
    });

    this.orderSnrAiRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      ai: ai,
      snrList: snr,
      orderWorkflowState:orderWorkflowState
    });
    return this.orderSnrAiRef;
  }

  latestOrderWithSnr(snr: string[], orderWorkflowState: OrderWorkflowStateInput): QueryRef<LatestOrderSnrAiQueryResponse, LatestOrderSnrAiVariables> {
    this.orderSnrAiRef = this.apollo.watchQuery<LatestOrderSnrAiQueryResponse, LatestOrderSnrAiVariables>({
      query: LATEST_ORDER_SNR_AI_QUERY,
      fetchPolicy: "network-only"
    });

    this.orderSnrAiRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      snrList: snr,
      orderWorkflowState:orderWorkflowState
    });
    return this.orderSnrAiRef;
  }

  orderHistory(orderNumber: string) {
    this.orderVersionsRef = this.apollo.watchQuery<OrderHistoryQueryResponse, OrderHistoryVariables>({
      query: ORDER_HISTORY_QUERY
    });

    this.orderVersionsRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      orderNo: orderNumber?.trim()
    });
    return this.orderVersionsRef;
  }

  orderHistoryWithSnrAi(ai: number , snr: string[]) {
    this.orderSnrAiVersionsRef = this.apollo.watchQuery<OrderHistorySnrAiQueryResponse, OrderHistorySNRAIVariables>({
      query: ORDER_HISTORY_SNR_AI_QUERY,
      fetchPolicy: "network-only"
    });

    this.orderSnrAiVersionsRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      ai: ai,
      snrList: snr
    });
    return this.orderSnrAiVersionsRef;
  }

  orderHistoryWithSnr(snr: string[]) {
    this.orderSnrAiVersionsRef = this.apollo.watchQuery<OrderHistorySnrAiQueryResponse, OrderHistorySNRAIVariables>({
      query: ORDER_HISTORY_SNR_AI_QUERY,
      fetchPolicy: "network-only"
    });

    this.orderSnrAiVersionsRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      snrList: snr
    });
    return this.orderSnrAiVersionsRef;
  }

  ordersForWorkFlowState(status: OrderWorkflowStateInput): QueryRef<OrderForWorkFlowStateResponse, OrderForWorkFlowStateVariables> {
    this.orderWorkFlowStateRef = this.apollo.watchQuery<OrderForWorkFlowStateResponse, OrderForWorkFlowStateVariables>({
      query: ORDER_FOR_WORKFLOW_STATE,
      fetchPolicy: "network-only"
    });
    this.orderWorkFlowStateRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      orderWorkflowState: status
    });
    return this.orderWorkFlowStateRef;
  }

  removeGraphQLErrorOnMessage(errorMessage: string) {
    let message = errorMessage.replace('GraphQL error: ', '');

    if (message.localeCompare("Order does not exist for given parameters") == 0) {
      message = this.translate.instant('menu.pricing.order-management.error-message.order-does-not-exist');
    }

    return message;
  }

  mapOrder(order: any): any {
    return this.transformer.transformOrder(order);
  }

  mapOrders(orders: any): any {
    return this.transformer.transformOrders(orders);
  }

  getOrderChangeInputFromOrder(viewModel: OrdersViewModel,orders: OrdersViewModel[]): OrderChangeInputInput {
    let result = new OrderChangeInputInput();
    let material = new MaterialInput();

    material.ai = viewModel.ai;
    material.snr = viewModel.snr;
    material.plant = viewModel.plant;

    result.changeDate = this.transformer.formatDateWithTime(viewModel.changeDate);
    result.orderNo = viewModel.orderNo;
    result.orderState = OrderState[viewModel.orderState];
    if ( orders.length > 0 ) {
      result.pricingPeriods = this.extractMutatedPricingPeriodForOrder(viewModel.orderNo,orders);
    }
    result.internalIdentifier = viewModel.internalIdentifier;
    result.material = material;
    return result;
  }

  extractMutatedPricingPeriodForOrder(orderNo: string, orders: OrdersViewModel[]): PricingPeriodChangeInputInput[] {
    let result: any = []
    for (let order of orders) {
      //pricing period date has changed
      if ( (orderNo == order.orderNo ) && ( order.fromDate.localeCompare(order.fromDateOriginal) != 0 )) {
        let pricingPeriod = new PricingPeriodChangeInputInput();
        pricingPeriod.internalIdentifier = order.pricingPeriodInternalIdentifier;
        let dateRange = new DateRangeInput();
        dateRange.fromDate = FaasDatesHelper.formatFaasDate(order.fromDate);
        dateRange.toDate = FaasDatesHelper.formatFaasDate(order.toDate);
        pricingPeriod.validity = dateRange;
        result.push(pricingPeriod);
      }
    }
    return result;
  }

  canApproveAndReject() {
    let permissions = ['T_Freeze_Read_BMW_MSF','T_Freeze_Read_BMW_NED'];
    return this.authService.checkPermission(permissions);
  }


}
