import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ProductMasterDataDisplayGridColumns} from './product-master-data-display-grid.columns';
import {AgGridBase} from "../../../../ag.grid.base";
import { ProductViewHolder} from "../../model/product.model";

@Component({
  selector: 'app-product-master-data-display-grid',
  templateUrl: './product-master-data-display-grid.component.html',
  styleUrls: ['./product-master-data-display-grid.component.scss']
})
export class ProductMasterDataDisplayGridComponent extends AgGridBase<ProductMasterDataDisplayGridColumns> {

  @Output() refresh = new EventEmitter();
  @Output() delete = new EventEmitter<ProductViewHolder[]>();
  rowSelection = 'multiple';
  @Input() noSeriesCalculationRole: boolean = false;

  constructor(translate: TranslateService) {
    super(new ProductMasterDataDisplayGridColumns(translate));
  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onDelete() {
    if (this.gridApi.getSelectedRows().length == 0){
      return;
    }

    let products: ProductViewHolder[] = [];
    this.gridApi.getSelectedRows().forEach( params => {
      let holder = new ProductViewHolder();
      holder.prod_satype = params.prod_satype;
      holder.prod_typecode = params.prod_typecode;
      holder.prod_sacode = params.prod_sacode;
      holder.prod_said = params.prod_said;
      products.push(holder);
    });
    this.delete.emit(products);
  }

  onRefresh() {
    this.refresh.emit();
  }
}
