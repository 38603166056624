import { Component, HostListener, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ManufacturingReportsService } from '../../service/manufacturing-reports.service';
import { Product, ImportStatus, Type } from '../../../product/model/product.model';
import {ManufacturingCostHistory, RoyaltiesIndicator} from '../../model/operations.model';
import { GridApi, GridOptions, ICellRendererParams} from 'ag-grid-community';
import {EMPTY_STRING, isUndefinedOrEmpty} from '../../../../../app/shared/functions/typescript.utils';
import { transferPrice } from '../../service/transfer-price-constants';
import moment from 'moment';

@Component({
  selector: 'app-valid-transfer-price-dynamic',
  templateUrl: './valid-transfer-price-dynamic.component.html',
  styleUrls: ['./valid-transfer-price-dynamic.component.scss']
})
export class ValidTransferPriceDynamicComponent implements OnInit {
  @Input() derivativeList: any[] = [];
  @Input() exchangeRateMap: any;
  exchangeRate: number = 1;
  bodyAndRoofColorList: any[];
  designTrimsList: any[];
  packetList: any[];
  executionList: any[];
  singleSaList: any[];
  selectedExchange: string;
  applyExchangeRate = false;
  filterForm: FormGroup;
  showReport = false;
  showLoading = false;
  selectedDerivatives: any [];
  selectedTypes: any[];
  selectedTypeShadow: any[];
  typeList: any[];
  effectiveFrom: Date;
  effectiveTo: Date;
  datesSelected: {};
  rowData = [];
  rowDataPreProduction = [];
  dialogTitle: string;
  dialogText: string;
  errorMessage: string;
  showPromt = false;
  validity = {
    inputModel: [
      this.effectiveFrom,
      this.effectiveTo
    ]
  };

  runtimeCompilerData = {
    columns: [
    ],
    gridOptions: {
      getRowStyle: params => {
        return { order: params.node.rowIndex };
      },
      postSort: params => {
        //  it needs to wait until new order is set
        setTimeout(() => {
          params.forEach(param => {
            param.updateData(param.data);
          });
        });
      },
      ensureDomOrder: true,
      suppressMovableColumns: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowHeight: 32,
      headerHeight: 32,
      enableRangeSelection: true,
      enableRangeHandle: true,
      rowGroupPanelShow: 'onlyWhenGrouping'
    } as GridOptions,
    defaultColDef: {
      sortable: true,
      unSortIcon: true,
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: 'textFilter',
      defaultColToEdit: '',
      autoHeight: true,
      resizable: true
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: undefined
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left'
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    }
  };

  private gridApi: GridApi;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  constructor(private manufacturingReportsService: ManufacturingReportsService, private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
   }

  ngOnInit(): void {
    this.initialiseFormComponents();
    this.initialisePageValues();
  }

  initialisePageValues() {
    this.dialogTitle = undefined;
    this.dialogText = undefined;
    this.errorMessage = undefined;
    this.selectedExchange = 'CNY';
    this.applyExchangeRate = false;
    this.initialiseTypes();
  }

  clearErrorMessage() {
    this.errorMessage = undefined;
  }

  initialiseFormComponents() {
    this.filterForm = new FormGroup({
      selectedDerivatives: new FormControl(),
      derivativeCheckbox: new FormControl(),
      selectedTypes: new FormControl(),
      typeCheckbox: new FormControl(),
      selectedExchange: new FormControl()
    });
  }

  initialiseTypes() {
    this.selectedDerivatives = [];
    this.selectedTypes = [];
    this.selectedTypeShadow = [];
    this.typeList = [];
  }

  synchroniseSelectedWithCheckBox(mainDataList, selectedList, checkboxName) {
    if (mainDataList.length > 0 && selectedList.length ==  mainDataList.length) {
      this.filterForm.get(checkboxName).setValue(true);
    } else {
      this.filterForm.get(checkboxName).setValue(false);
    }
  }

  selectedDerivativesChanged(event) {
    console.log('selectedDerivativesChanged event: ', event);
    this.synchroniseSelectedWithCheckBox(this.typeList, this.selectedTypes, 'typeCheckbox');
    this.loadTypesForDerivatives(event);
  }

  loadTypesForDerivatives(selectedDerivativeCodes) {
    if (selectedDerivativeCodes.length > 0) {
      this.showLoading = true;
      this.manufacturingReportsService.typesForDerivative(selectedDerivativeCodes)
        .subscribe(({data, loading}) => {
          this.typeList = data.productConfigurationTypesByDerivatives;
          this.sortTypesForDerivatives(this.typeList);
          this.selectedTypes = [];
          this.showLoading = false;
        }, (error) => {
          this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
        });
    } else {
      this.typeList = [];
      this.selectedTypes = [];
      this.selectedTypeShadow = [];
    }
  }

  sortTypesForDerivatives(typeList: Type[]) {
    typeList.sort(function(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  derivativeCheckboxChanged($event: Event) {
    this.selectedDerivatives = [];
    if (this.filterForm.get('derivativeCheckbox').value) {
      this.derivativeList.forEach((product) => {
        this.selectedDerivatives.push(product.derivative.code);
      });
    }
    this.loadTypesForDerivatives(this.selectedDerivatives);
  }

  typeCheckboxChanged($event: Event) {
    this.selectedTypes = [];
    if (this.filterForm.get('typeCheckbox').value) {
      this.typeList.forEach((type) => {
        this.selectedTypes.push(type.code);
      });
    }
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    console.log('onGridReady');
  }

  generateReport() {
    this.clearErrorMessage();
    this.buildReportColumns();
    // this.refreshData();
  }

  buildReportColumns() {
    this.showReport = false;
    this.rowData = undefined;
    this.showLoading = true;
    const bodyAndRoofColorDataList = [];
    const designTrimsDataList = [];
    const packetDataList = [];
    const executionDataList = [];
    const singleSaDataList = [];
    this.manufacturingReportsService.getDynamicColumns()
    .subscribe((result) => {
      const dynamicColumns = result.data.retrieveProductColumns;

      const initialStructure: any[] = [
        {
          field: 'derivativeCode',
          headerName: 'derivativeCode',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 120,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'type.code',
          headerName: 'typeCode',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'type.name',
          headerName: 'typeName',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'includeRoyalties',
          headerName: 'includeRoyaltiesApplicable',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'releaseDate',
          headerName: 'releaseDate',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 160,
          valueFormatter: this.dateTimeFormatter,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'reason',
          headerName: 'reason',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'assignment',
          headerName: 'assignment',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'validFrom',
          headerName: 'validFrom',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 160,
          valueFormatter: this.dateTimeFormatter,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
         {
          field: 'transferPrice',
          headerName: 'transferPrice',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'typeTotal',
          headerName: 'typeTotal',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth : 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'gwmParts',
          headerName: 'gwmParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'bmwParts',
          headerName: 'bmwParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'salParts',
          headerName: 'salParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'thirdPartyParts',
          headerName: 'thirdPartyParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'directLabour',
          headerName: 'directLabour',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'inboundLogistics',
          headerName: 'inboundLogistics',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'warranty',
          headerName: 'warranty',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'importDuties',
          headerName: 'importDuties',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'customsClearanceFee',
          headerName: 'customsClearanceFee',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'fuelAndEnergy',
          headerName: 'fuelAndEnergy',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'variableManufacturingExpenses',
          headerName: 'variableManufacturingExpenses',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'outboundLogistics',
          headerName: 'outboundLogistics',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'nondeductableIndirectTaxesLinked',
          headerName: 'nondeductableIndirectTaxesLinked',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'nondeductableIndirectTaxes',
          headerName: 'nondeductableIndirectTaxes',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'depreciation',
          headerName: 'depreciation',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'indirectLabour',
          headerName: 'indirectLabour',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'otherFixedExpenses',
          headerName: 'otherFixedExpenses',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'royaltiesTechnology',
          headerName: 'royaltiesTechnology',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'profit5Percent',
          headerName: 'profit5Percent',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'yearlyProductionCostReduction',
          headerName: 'yearlyProductionCostReduction',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'optionsTotal',
          headerName: 'optionsTotal',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth : 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
      ];

      const bodyAndRoofColoursChildren = [];
      const designTrimsChildren = [];
      const packetsChildren = [];
      const executionsChildren = [];
      const singleSasChildren = [];

      // Create parents
      // #region Body and roof colour
      const productBodyAndRoofColours = {
        field: 'bodyAndRoofColour',
        headerName: 'bodyAndRoofColour',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      const totalClosedBodyAndRoofColour = {
        field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
        headerName: 'bodyAndRoofColourTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      bodyAndRoofColoursChildren.push(totalClosedBodyAndRoofColour);

      const totalOpenBodyAndRoofColour = {
        field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
        headerName: 'bodyAndRoofColourTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      bodyAndRoofColoursChildren.push(totalOpenBodyAndRoofColour);
      // #endregion

      // #region Design Trims
      const productDesignTrims = {
        field: 'designTrims',
        headerName: 'designTrims',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      const totalClosedDesignTrims = {
        field: 'productDesignTrims.designTrimsTotal',
        headerName: 'designTrimsTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      designTrimsChildren.push(totalClosedDesignTrims);

      const totalOpenDesignTrims = {
        field: 'productDesignTrims.designTrimsTotal',
        headerName: 'designTrimsTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      designTrimsChildren.push(totalOpenDesignTrims);
      // #endregion

      // #region Packet
      const productPackets = {
        field: 'packet',
        headerName: 'packet',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      const totalClosedPackets = {
        field: 'productPacket.packetTotal',
        headerName: 'packetTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      packetsChildren.push(totalClosedPackets);

      const totalOpenPackets = {
        field: 'productPacket.packetTotal',
        headerName: 'packetTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      packetsChildren.push(totalOpenPackets);
      // #endregion

      // #region Execution
      const productExecutions = {
        field: 'execution',
        headerName: 'execution',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      const totalClosedExecutions = {
        field: 'productExecution.executionTotal',
        headerName: 'executionTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      executionsChildren.push(totalClosedExecutions);

      const totalOpenExecutions = {
        field: 'productExecution.executionTotal',
        headerName: 'executionTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      executionsChildren.push(totalOpenExecutions);
      // #endregion

      // #region Single SA
      const productSingleSas = {
        field: 'singleSA',
        headerName: 'singleSA',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      const totalClosedSingleSas = {
        field: 'productSingleSA.singleSATotal',
        headerName: 'singleSATotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      singleSasChildren.push(totalClosedSingleSas);

      const totalOpenSingleSas = {
        field: 'productSingleSA.singleSATotal',
        headerName: 'singleSATotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      singleSasChildren.push(totalOpenSingleSas);
      // #endregion

      // Create Children
      dynamicColumns.forEach(item => {
        if (item.grouping === 'BODY_AND_ROOF_COLOUR') {
          const keyStr = item.code + ' - ' + item.name;
          const genericChildObject = {
            field: 'productBodyAndRoofColours.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
          };
          bodyAndRoofColoursChildren.push(genericChildObject);

          const dataObject = {
            code: item.code,
            name: item.name
          };
          bodyAndRoofColorDataList.push(dataObject);


        } else if (item.grouping === 'DESIGN_TRIMS') {
          const keyStr = item.code + ' - ' + item.name;
          const genericChildObject = {
            field: 'productDesignTrims.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
          };
          designTrimsChildren.push(genericChildObject);

          const dataObject = {
            code: item.code,
            name: item.name
          };
          designTrimsDataList.push(dataObject);

        } else if (item.grouping === 'PACKET') {
          const keyStr = item.code + ' - ' + item.name;
          const genericChildObject = {
            field: 'productPacket.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
          };
          packetsChildren.push(genericChildObject);

          const dataObject = {
            code: item.code,
            name: item.name
          };
          packetDataList.push(dataObject);

        } else if (item.grouping === 'EXECUTION') {
          const keyStr = item.code + ' - ' + item.name;
          const genericChildObject = {
            field: 'productExecution.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
          };
          executionsChildren.push(genericChildObject);

          const dataObject = {
            code: item.code,
            name: item.name
          };
          executionDataList.push(dataObject);

        } else if (item.grouping === 'SINGLE_SA') {
          const keyStr = item.code + ' - ' + item.name;
          const genericChildObject = {
            field: 'productSingleSA.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          singleSasChildren.push(genericChildObject);

          const dataObject = {
            code: item.code,
            name: item.name
          };
          singleSaDataList.push(dataObject);
        }
      });

      // Add children to parents
      Object.assign(productBodyAndRoofColours, {children: bodyAndRoofColoursChildren});
      Object.assign(productDesignTrims, {children: designTrimsChildren});
      Object.assign(productPackets, {children: packetsChildren});
      Object.assign(productExecutions, {children: executionsChildren});
      Object.assign(productSingleSas, {children: singleSasChildren});

      initialStructure.push(productBodyAndRoofColours);
      initialStructure.push(productDesignTrims);
      initialStructure.push(productPackets);
      initialStructure.push(productExecutions);
      initialStructure.push(productSingleSas);

      // Royalties for Options
      const royaltiesForSA = {
        field: 'royaltiesForSA',
        headerName: 'royaltiesForSA',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(royaltiesForSA);

      // Profit for Options
      const profitForSA = {
        field: 'profitForSA',
        headerName: 'profitForSA',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(profitForSA);

      // Rules
      const rules = {
        field: 'rules',
        headerName: 'salReportRules',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(rules);
      this.runtimeCompilerData.columns = Array.from(initialStructure);

      this.bodyAndRoofColorList = bodyAndRoofColorDataList;
      this.designTrimsList = designTrimsDataList;
      this.packetList = packetDataList;
      this.executionList = executionDataList;
      this.singleSaList = singleSaDataList;

      // Get report data
      this.refreshData();
    });

  }

  refreshData() {

    // Get Report Data
    console.log('Selected Exchange: ', this.selectedExchange);
    this.selectedTypeShadow = [...this.selectedTypes];
    this.manufacturingReportsService.transferPricePerModelTypeByPlant()
    .subscribe((result) => {
      this.showLoading = false;
      this.rowData = [];
      this.rowDataPreProduction = [];
      let royaltiesForTypes = new Map();
      result.data.transferPricePerModelTypeByPlant.map(row => {
        if (!royaltiesForTypes.has(row.type.code) && row.packet === null && row.specialEquipment === null) {
          royaltiesForTypes.set(row.type.code, RoyaltiesIndicator[row.transferPrice.royaltiesIndicator]);
        }
      });
      const totalDataSetSortedByTime = result.data.transferPricePerModelTypeByPlant.map(row => {
      return this.transformManufacturingCostHistory(row, royaltiesForTypes.get(row.type.code));
      }).sort((a, b) => a.type.code.localeCompare(b.type.code)
      || moment(a.validFrom).toDate().getTime() - moment(b.validFrom).toDate().getTime()
      || moment(a.releaseDate).toDate().getTime() - moment(b.releaseDate).toDate().getTime());

      const uniqueTypeCodeList = [];
      totalDataSetSortedByTime.forEach((row, index) => {
        if (!uniqueTypeCodeList.includes(row.type.code)) {
          uniqueTypeCodeList.push(row.type.code);
        }
      });

      // #region TRANSFORM - Order by TypeCode
      uniqueTypeCodeList.forEach((item, index) => {
        const typeGroupList = totalDataSetSortedByTime.filter(typeCode => typeCode.type.code === item);
        if (typeGroupList.length === 1) {     // Only Start value
          this.rowData.push(typeGroupList[0]);
        } else if (typeGroupList.length > 1) {
          const typeGroupListLast = typeGroupList.length - 1;
          let total = 0;
          typeGroupList.forEach((row, index) => {
            if (index !== typeGroupListLast) {
              total += row.transferPrice;
              if (index !== 0) {
                row.reason = row.assignment;
              }
              this.rowData.push(row); // All rows except the last
            } else {
              row.reason = 'Active';  // Indicate last row as Active
              this.rowData.push(row);
            }
          });
        }
      });
      // #endregion



      // #region TOP filter
      const tempRowData = this.rowData;

      let totalDataSetSortedAndFiltered = [];

      const totalDataSetSortedByTimeandFiltered = this.topFilter(tempRowData);
      if (totalDataSetSortedByTimeandFiltered.length > 0) {
        totalDataSetSortedAndFiltered = totalDataSetSortedByTimeandFiltered;
      } else if (totalDataSetSortedByTimeandFiltered.length === 0 && (this.selectedDerivatives.length > 0 || this.selectedTypes.length > 0 || this.effectiveTo != null || this.effectiveFrom != null)) {
        //Do nothing: totalDataSetSortedAndFiltered will stay empty, so will this.rowData and no rows will be returned
        console.log('There is no data for filter selection');
      } else {
        console.log('0 0 0 0');
        totalDataSetSortedAndFiltered = tempRowData;
      }

      this.rowData = [];

      totalDataSetSortedAndFiltered.forEach(row => {
        this.rowData.push(row);
      });

      // #endregion

      //#region Calculated Totals
      const preCalculatedColumns = this.rowData;
      this.rowData = [];

      // variables for the copying of data
      const uniqueTypeCodes = [];
      let prevProductBodyAndRoofColours = {};
      let prevProductDesignTrims = {};
      let prevProductPacket = {};
      let prevProductExecution = {};
      let prevProductSingleSA = {};
      let prevRoyalties = {};
      let prevProfits = {};
      let prevRules = {};

      preCalculatedColumns.sort((a, b) => a.type.code.localeCompare(b.type.code)
        || moment(a.validFrom).toDate().getTime() - moment(b.validFrom).toDate().getTime()
        || moment(a.releaseDate).toDate().getTime() - moment(b.releaseDate).toDate().getTime()).forEach(row => {
        row.typeTotal = this.calculateTypeTotal(row);

        if (!uniqueTypeCodes.includes(row.type.code)) {
          uniqueTypeCodes.push(row.type.code);
          prevProductBodyAndRoofColours = { ...row.productBodyAndRoofColours };
          prevProductDesignTrims = { ...row.productDesignTrims };
          prevProductPacket = { ...row.productPacket };
          prevProductExecution = { ...row.productExecution };
          prevProductSingleSA = { ...row.productSingleSA };
          prevRoyalties = row.royaltiesForSA;
          prevProfits = row.profitForSA;
          prevRules = row.rules;
        } else {
          Object.entries(row.productBodyAndRoofColours)
            .forEach(([key, value]) => {
              row.productBodyAndRoofColours[key] = value + prevProductBodyAndRoofColours[key];
              prevProductBodyAndRoofColours[key] = row.productBodyAndRoofColours[key];
            });

          Object.entries(row.productDesignTrims)
            .forEach(([key, value]) => {
              row.productDesignTrims[key] = value + prevProductDesignTrims[key];
              prevProductDesignTrims[key] = row.productDesignTrims[key];
            });

          Object.entries(row.productPacket)
            .forEach(([key, value]) => {
              row.productPacket[key] = value + prevProductPacket[key];
              prevProductPacket[key] = row.productPacket[key];
            });

          Object.entries(row.productExecution)
            .forEach(([key, value]) => {
              row.productExecution[key] = value + prevProductExecution[key];
              prevProductExecution[key] = row.productExecution[key];
            });

          Object.entries(row.productSingleSA)
            .forEach(([key, value]) => {
              row.productSingleSA[key] = value + prevProductSingleSA[key];
              prevProductSingleSA[key] = row.productSingleSA[key];
            });

          row.royaltiesForSA = row.royaltiesForSA + prevRoyalties;
          prevRoyalties = row.royaltiesForSA;
          row.profitForSA = row.profitForSA + prevProfits;
          prevProfits = row.profitForSA;
          row.rules = row.rules + prevRules;
          prevRules = row.rules;
        }
        row.optionsTotal = this.calculateOptionsTotal(row) + row.royaltiesForSA + row.profitForSA + row.rules;
        row.transferPrice = row.typeTotal + row.optionsTotal;
        this.rowData.push(row);
      });
      //#endregion

      // #region TRANSFORM - ExchangeRate
      if (this.selectedExchange === 'EUR') {
        this.applyExchangeRate = true;
        this.exchangeRate =  this.getExchangeRatePerLastDate();
        console.log('Current Exchange Rate: ', this.exchangeRate);
        this.transformExchangeRate(this.rowData);
      } else {
        this.applyExchangeRate = false;
      }
      // #endregion

      console.log('Report 2 - Final Rowdata : ', this.rowData);

      this.selectedTypes = [...this.selectedTypeShadow];

      this.showReport = true;
    });
  }

  calculateTypeTotal(row:any) {
    let total = row.gwmParts +
    row.bmwParts +
    row.salParts +
    row.thirdPartyParts +
    row.directLabour +
    row.inboundLogistics +
    row.warranty +
    row.importDuties +
    row.customsClearanceFee +
    row.fuelAndEnergy +
    row.variableManufacturingExpenses +
    row.outboundLogistics +
    row.nondeductableIndirectTaxes +
    row.nondeductableIndirectTaxesLinked +
    row.depreciation +
    row.indirectLabour +
    row.otherFixedExpenses +
    row.royaltiesTechnology +
    row.profit5Percent +
    row.yearlyProductionCostReduction;
    return total;
  }

  calculateOptionsTotal(row:any) {
    let total = row.productBodyAndRoofColours.bodyAndRoofColourTotal +
    row.productDesignTrims.designTrimsTotal +
    row.productPacket.packetTotal +
    row.productExecution.executionTotal +
    row.productSingleSA.singleSATotal;
    return total;
  }

  replaceIllegalCharacters(inputStr: string) {
    return inputStr.replace(/\./g, ''); // .
  }

  topFilter(totalDataSetSortedByTime: any[]) {
    let totalDataSetSortedByTimeandFiltered = [];
    console.log('Do filter');
    //  0 0 0 1
    if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo !== undefined) {
      console.log('0 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    } else if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo == undefined) {
      console.log('0 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom > this.effectiveFrom;
      });
    } else if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('0 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom > this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo == undefined) {
      console.log('1 0 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo !== undefined) {
      console.log('1 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo == undefined) {
      console.log('1 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('1 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom == undefined && this.effectiveTo == undefined) {
      console.log('1 1 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom == undefined && this.effectiveTo !== undefined) {
      console.log('1 1 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo == undefined) {
      console.log('1 1 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom;
      });
    } else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('1 1 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        const dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    return totalDataSetSortedByTimeandFiltered;
  }

  getExchangeRatePerLastDate() {

    let latestDateRow = this.rowData.reduce((a, b) => {
      return new Date(a.validFrom) > new Date(b.validFrom) ? a : b;
    });

    let latestDate = new Date(latestDateRow.validFrom);
    console.log('latestDate : ', latestDate);

    let year = latestDate.getFullYear();

    let month = latestDate.getMonth();

    let monthlyValues = this.exchangeRateMap.get(year.toString());
    console.log('monthlyValues : ', monthlyValues);

    if (monthlyValues === undefined) {
      this.errorMessage = `The exchange rate that corresponds to report date: ${latestDateRow.validFrom} could not be found. Exchange rate is set to a value of 1.`;
      return 1
    }
    return monthlyValues[month];
  }

  transformExchangeRate(dataSet: any[]) {
    dataSet.forEach((row, index) => {
      for (const key in row) {
        if (key === 'productBodyAndRoofColours'
          || key === 'productDesignTrims'
          || key === 'productExecution'
          || key === 'productPacket'
          || key === 'productSingleSA') {
          for (const innerkey in row[key]) {
            row[key][innerkey] = (row[key][innerkey] * this.exchangeRate).toFixed(2);
          }
        } else if (typeof row[key] === 'number') {
          row[key] = (row[key] * this.exchangeRate).toFixed(2);
        }
      }
    });
  }

  transformManufacturingCostHistory(row: any, royaltiesForTypes: any) {
    const obj =  {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: royaltiesForTypes,
      assignment: row.operationId,
      releaseDate: row.releaseDate,
      reason: (row.previousManufacturingCosts && row.previousManufacturingCosts.length > 0) ? 'N/A' : 'StartValue',
      validFrom: row.validFrom,
      previousManufacturingCosts: row.previousManufacturingCosts,
      previousManufacturingCostsTotal: (row.previousManufacturingCosts.length > 0) ? +(row.previousManufacturingCosts.reduce((sum, prevManCost) => sum + prevManCost.price.value, 0)).toFixed(2) : 0,
      transferPrice: row.price.value,
      gwmParts: row.costs[transferPrice.category.gwmParts],
      bmwParts: row.costs[transferPrice.category.bmwParts],
      salParts: row.costs[transferPrice.category.salParts],
      thirdPartyParts: row.costs[transferPrice.category.thirdPartyParts],
      directLabour: row.costs[transferPrice.category.directLabour],
      inboundLogistics: row.costs[transferPrice.category.inboundLogistics],
      warranty: row.costs[transferPrice.category.warranty],
      importDuties: row.costs[transferPrice.category.importDuties],
      customsClearanceFee: row.costs[transferPrice.category.customsClearanceFee],
      fuelAndEnergy: row.costs[transferPrice.category.fuelAndEnergy],
      variableManufacturingExpenses: row.costs[transferPrice.category.variableManufacturingExpenses],
      outboundLogistics: row.costs[transferPrice.category.outboundLogistic],
      nondeductableIndirectTaxesLinked: row.costs[transferPrice.category.nonDeductibleIndirectTaxesDirectlyLinkedToProject],
      nondeductableIndirectTaxes: row.costs[transferPrice.category.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject],
      depreciation: row.costs[transferPrice.category.depreciation],
      indirectLabour: row.costs[transferPrice.category.indirectLabour],
      otherFixedExpenses: row.costs[transferPrice.category.otherFixedExpenses],
      royaltiesTechnology: row.costs[transferPrice.category.royaltiesForTechnology],
      profit5Percent: row.costs[transferPrice.category.profit],
      yearlyProductionCostReduction: row.costs[transferPrice.category.yearlyProductionCostReduction],
      totalOptions: this.totalOfAllRoyalties(row),
      royaltiesForSA: this.RoyaltiesForSA(row),
      profitForSA: this.ProfitForSA(row),
      rules: this.totalOfRules(row)
    };
      // Body and roof colour

    const productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
        Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentWithoutProfitAndRoyaltyValues(row, colorobj.code)});
      });

    let bodyRoofColorTotal = 0;
    for (const key in productBodyAndRoofColours) {
        bodyRoofColorTotal += productBodyAndRoofColours[key];
      }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});


      // Design Trims - TODO: Check if colorobj.name is the correct param for the function
    const productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
        Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentWithoutProfitAndRoyaltyValues(row, trimobj.code)});
      });

    let designTrimsTotalCalc = 0;
    for (const key in productDesignTrims) {
        designTrimsTotalCalc += productDesignTrims[key];
      }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

      // Packet
    const productPacket = {};
    this.packetList.forEach(packetobj => {
        Object.assign(productPacket, {[packetobj.code]: this.PacketsWithoutProfitAndRoyaltyValues(row, packetobj.code)});
      });

    let packetTotalCalc = 0;
    for (const key in productPacket) {
        packetTotalCalc += productPacket[key];
      }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

      // Execution
    const productExecution = {};
    this.executionList.forEach(executionobj => {
        Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentWithoutProfitAndRoyaltyValues(row, executionobj.code)});
      });

    let executionTotalCalc = 0;
    for (const key in productExecution) {
        executionTotalCalc += productExecution[key];
      }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

      // Single SA
    const productSingleSA = {};
    this.singleSaList.forEach(saobj => {
        Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentWithoutProfitAndRoyaltyValues(row, saobj.code)});
      });

    let saTotalCalc = 0;
    for (const key in productSingleSA) {
        saTotalCalc += productSingleSA[key];
      }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    const finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA};
    return finalObj;

  }

  RoyaltiesForSA(row) {
    let royaltiesNum = 0;
    if (row.transferPrice.royaltiesIndicator === 'SOMETIMES' || row.transferPrice.royaltiesIndicator === 'ALWAYS') {
      if (row.type.specialEquipment !== null) {
        row.type.specialEquipment.map(specialEquipmentRow => {
          const royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost
            .filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
      if (row.type.packets !== null) {
        row.type.packets.map(packet => {
          const royaltyAdjustments = packet.manufacturingCostHistory.transferPrice.transferCategoryCost
            .filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
    }
    return royaltiesNum;
  }

  ProfitForSA(row) {
    let royaltiesNum = 0;
    if (row.type.specialEquipment !== null && row.type.specialEquipment.length > 0) {
      row.type.specialEquipment.map(specialEquipmentRow => {
        const royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    if (row.type.packets !== null && row.type.packets.length > 0) {
      row.type.packets.map(packet => {
        const royaltyAdjustments = packet.manufacturingCostHistory.transferPrice.transferCategoryCost
          .filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return royaltiesNum;
  }

  totalOfAllRoyalties(row) {
    let saSum = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      saSum = row.type.specialEquipment.reduce((sum, item) => sum + item.manufacturingCostHistory.price.value, 0);
    }
    return saSum;
  }

  totalOfPackets(row) {
    let saSum = 0;
    if (row.type.packets && row.type.packets.length > 0) {
      saSum = row.type.packets.reduce((sum, item) => sum + item.manufacturingCostHistory.price.value, 0);
    }
    return saSum;
  }

  totalOfRules(row) {
    let rulesTotal = 0;
    if (row.type.rulesHistory !== null && row.type.rulesHistory.length > 0) {
      rulesTotal = row.type.rulesHistory.reduce((sum, ruleHistory) => sum + ruleHistory.amount.value, 0);
    }
    return rulesTotal;
  }

  SpecialEquipmentWithoutProfitAndRoyaltyValues(row: any, saCode: string) {
    let saSum = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      saSum = row.type.specialEquipment
      .filter(x => x.code === saCode)
      .reduce((sum, item) => sum + this.returnTransferCategoryCost(item), 0);
    }
    return saSum;
  }

  PacketsWithoutProfitAndRoyaltyValues(row: any, saCode: string) {
    let saSum = 0;
    if (row.type.packets && row.type.packets.length > 0) {
      saSum = row.type.packets
      .filter(x => x.code === saCode)
      .reduce((sum, item) => sum +
      this.returnTransferCategoryCost(item)
      , 0);
    }
    return saSum;
  }

  returnTransferCategoryCost(filteredSAItem: any) {
    let returnValue = 0;
    if (filteredSAItem.manufacturingCostHistory.transferPrice.transferCategoryCost &&
      filteredSAItem.manufacturingCostHistory.transferPrice.transferCategoryCost.length > 0) {
      filteredSAItem.manufacturingCostHistory.transferPrice.transferCategoryCost.forEach(transferCategoryCostRow => {
        if (transferCategoryCostRow.adjustmentReason.name !== 'Royalties'
        && transferCategoryCostRow.adjustmentReason.name !== 'Profit') {
          returnValue += transferCategoryCostRow.amount.value;
        }
      });
    }
    return returnValue;
  }

  CategoryCostTotalCalc(row: any, categoryCostName: string) {
    return (row.previousTransferPrices.map(transferCategoryCostArr =>
      transferCategoryCostArr.transferCategoryCost.map(r => ({amount: r.amount, categoryCost: r.categoryCost, }))
        .filter(item => item.categoryCost.name === categoryCostName).reduce((sum, item) => sum + item.amount.value, 0))
      .reduce((sum, item) => sum + item, 0)) + (row.transferPrice.transferCategoryCost
      .filter( item => item.categoryCost.name === categoryCostName).reduce((sum, catCost) => sum + catCost.amount.value, 0));
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  dateTimeFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY HH:mm');
  }

}
