import {OrdersViewModel} from "./OrdersViewModel";
import {Transformer} from "../../../helper/transformer";
import {PriceUnit} from "./order.model";
import moment from "moment";

export class PricingPeriodWithOrderDetails extends Transformer {
  id: number;
  fromDate: string;
  toDate: string;
  basePriceValue: string;
  basePriceCurrency: string;
  nettPriceValue: string;
  nettPriceCurrency: string;
  surchargeValue: string;
  priceUnit: string;
  orderNumber: string;
  version: number;
  state: string;
  supplierNumber: string;
  printDate: string;
  quantityUnit: string;
  orderType: string;


  getPricingPeriodsForOrder(orderNo: string, viewModel: OrdersViewModel[]): PricingPeriodWithOrderDetails[] {
    let prices: any = [];
    let pricingPeriodViewModel = {...this};
    viewModel.forEach(p => {
      if (p.orderNo == orderNo) {
        this.mapOrderToPricingPeriod(pricingPeriodViewModel, p);
        prices.push(pricingPeriodViewModel);
        pricingPeriodViewModel = {...this};
      }
    });
    this.sort(prices);
    return this.getUniquePrices(prices);
  }


  mapOrderToPricingPeriod(pricingPeriodViewModel: PricingPeriodWithOrderDetails, orderViewModel: OrdersViewModel) {
    pricingPeriodViewModel.id = orderViewModel.pricingPeriodInternalIdentifier;
    pricingPeriodViewModel.fromDate = this.dateFormatter(orderViewModel.fromDate);
    pricingPeriodViewModel.toDate = this.dateFormatter(orderViewModel.toDate);
    pricingPeriodViewModel.basePriceValue = orderViewModel.basePriceValue;
    pricingPeriodViewModel.basePriceCurrency = orderViewModel.basePriceCurrency;
    pricingPeriodViewModel.nettPriceValue = orderViewModel.nettPriceValue;
    pricingPeriodViewModel.nettPriceCurrency = orderViewModel.nettPriceCurrency;
    pricingPeriodViewModel.surchargeValue = orderViewModel.surchargeValueComputed;
    pricingPeriodViewModel.orderNumber = orderViewModel.orderNo;
    pricingPeriodViewModel.state = orderViewModel.orderState;
    pricingPeriodViewModel.supplierNumber = orderViewModel.supplierNo;
    pricingPeriodViewModel.printDate = orderViewModel.changeDate;
    pricingPeriodViewModel.version = orderViewModel.version;
    pricingPeriodViewModel.orderType = orderViewModel.orderType;
    pricingPeriodViewModel.quantityUnit = orderViewModel.quantityUnit;
    pricingPeriodViewModel.priceUnit = PriceUnit[orderViewModel.priceUnit] == undefined ? "" : PriceUnit[orderViewModel.priceUnit];
  }

  getUniquePrices(prices: PricingPeriodWithOrderDetails[]) {
    let map = new Map();
    for (let price of prices) {
      map.set(price.id, price);
    }
    return Array.from(map.values());
  }

  sort(arr: PricingPeriodWithOrderDetails[]): void {
    arr.sort((a: PricingPeriodWithOrderDetails, b: PricingPeriodWithOrderDetails) => {
      return +new Date(a.fromDate) - +new Date(b.fromDate);
    });
  }

  dateFormatter(params) {
    return moment(params).format('DD.MM.YYYY');
  }
}
