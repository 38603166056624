import {Component, OnInit, ViewChild} from '@angular/core';
import {RolePermissionMaintananceComponent} from "../role-permission-maintanance/role-permission-maintanance.component";
import {PermissionsComponent} from "../permissions/permissions.component";

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  @ViewChild(RolePermissionMaintananceComponent) roleMaintanance;
  @ViewChild(PermissionsComponent) permissions;

  constructor() { }

  ngOnInit(): void {
  }

  tabClick(tab: string) {
    switch (tab) {
      case "maintenance":
        this.roleMaintanance.loadData();
        break;
      case "permissions":
        this.permissions.loadData();
        break;
    }
  }
}
