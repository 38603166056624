import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Operation, RoyaltiesIndicator, Rule, RuleChangeInput, Status} from "../model/operations.model";
import {RuleService} from "../service/rule.service";
import {Product, SpecialEquipment, Type} from "../../product/model/product.model";
import {ProductService} from "../../product/service/product.service";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import {OperationService} from "../service/operation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['./rule.component.scss']
})
export class RuleComponent implements OnInit {

  @Input() selectedDetail: any[];
  @Input() operation: Operation;

  @Output() showRules = new EventEmitter<boolean>();

  rowData: any;
  whenOperationIsUnderEvaluation: boolean = true;
  statusesAllowedForCapturer = ["IN_PROGRESS", "RESET", "DECLINED"];
  hasCapturerRole: boolean;
  showLoading = false;
  product: Product[];
  executionCountryCodes: Type[]
  errorMessage: string;

  constructor(private ruleService: RuleService, private productService: ProductService, private operationService: OperationService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.showLoading = true;
    this.loadRules();
    this.loadProduct();
    this.whenOperationIsUnderEvaluation = !this.statusesAllowedForCapturer.includes(this.operation.workFlowStatus);
    this.hasCapturerRole = this.operationService.canCapture();
  }

  private loadRules() {
    this.ruleService.getRulesByOperationId(this.operation.internalIdentifier).subscribe((result) => {
      let rules = result.data.operationRulesById;
      let currentRules = rules.map(rule => {
        rule.specialEquipments.sort((a, b) => (a.code < b.code) ? -1 : 1);
        return this.transformRules(rule, false);
      });
      let previousRules = rules.map(rule => {
        return rule.previousRules?.filter(previousRule => previousRule.status === Status.ACTIVE).map(previousRule => {
          previousRule.specialEquipments?.sort((a, b) => (a.code < b.code) ? -1 : 1);
          return this.transformRules(previousRule, true)
        })
      });
      for (let i = 0; i < previousRules.length; i++){
        currentRules = currentRules.concat(previousRules[i]);
      }
      this.rowData = currentRules;
    }, (error) => {
      this.errorMessage = this.ruleService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  transformRules(rule: Rule, previousRule: boolean) {
    return {
      internalIdentifier: rule.internalIdentifier,
      derivativeCode:  rule.derivativeCode,
      type: {
        code:  rule.type?.code
      },
      specialEquipmentIdentifier1: rule.specialEquipments[0]?.internalIdentifier,
      specialEquipmentCode1: rule.specialEquipments[0]?.code,
      specialEquipmentName1: rule.specialEquipments[0]?.name,
      specialEquipmentIdentifier2: rule.specialEquipments[1]?.internalIdentifier,
      specialEquipmentCode2: rule.specialEquipments[1]?.code,
      specialEquipmentName2: rule.specialEquipments[1]?.name,
      price: {
        value: previousRule ? rule.amount?.value : rule.transferCategoryCosts?.filter(t => t.adjustmentReason.name === 'Rule').map(t => t.amount?.value)[0]
      },
      validFrom: previousRule ? rule.validFrom : this.operation?.validFrom,
      includeRoyalties: RoyaltiesIndicator[rule.royaltiesIndicator],
      previousRule: previousRule,
    };
  }

  onBack(showRules:boolean){
    this.selectedDetail = null;
    this.showRules.emit(showRules);
  }

  onSave(params) {
    let validationFailed = false;
    this.errorMessage = undefined;
    this.ruleService.getRulesInProgress().subscribe((rulesInWorkflow) => {
      rulesInWorkflow.data.retrieveRulesInWorkflow.forEach(rule => {
        params.forEach(param => {
          if ((param.type.code === rule.type.code && param.internalIdentifier !== rule.internalIdentifier)  &&
            !param.previousRule &&
            ((param.specialEquipmentCode1 === rule.specialEquipments[0].code &&
              param.specialEquipmentCode2 === rule.specialEquipments[1].code) ||
              (param.specialEquipmentCode1 === rule.specialEquipments[1].code &&
                param.specialEquipmentCode2 === rule.specialEquipments[0].code))) {
            validationFailed = true;
          }
        });
      });
      if (params === true || validationFailed) {
        this.errorMessage = this.translate.instant('messages.ruleValidation');
      } else {
        this.showLoading = true;
        let ruleChangeInput: RuleChangeInput = new RuleChangeInput();
        ruleChangeInput.operationId = this.operation.internalIdentifier;
        ruleChangeInput.plant = SystemGlobalCache.plant();
        ruleChangeInput.ruleInputs = params.filter(row => !row.previousRule).map(row => {
          return this.transformRuleInput(row);
        })
        this.ruleService.createRules(ruleChangeInput).subscribe((result) => {
          this.showLoading = false;
          this.onBack(true);
        }, (error) => {
          this.showLoading = false;
          this.errorMessage = this.ruleService.removeGraphQLErrorOnMessage(error.message);
          this.onBack(true);
        });
      }
    });
  }

  private transformRuleInput(row) {
    return {
      derivativeCode: row.derivativeCode,
      amount: row.total,
      status: Status.NEW,
      royaltiesIndicator: Object.keys(RoyaltiesIndicator).filter(x => RoyaltiesIndicator[x] === row.includeRoyalties)[0],
      type: this.getType(row.derivativeCode, row.type.code),
      specialEquipments: [this.getTypeSpecialEquipment(row.derivativeCode, row.type.code, row.specialEquipmentCode1),
        this.getTypeSpecialEquipment(row.derivativeCode, row.type.code, row.specialEquipmentCode2)],
      transferCategoryCosts: [{
        amount: row.price.value,
        categoryCost: {internalIdentifier: 24, name: 'Rule'},
        adjustmentReason: {internalIdentifier: 10, name: 'Rule'}
      }, {
        amount: row.royalties,
        categoryCost: {internalIdentifier: 24, name: 'Rule'},
        adjustmentReason: {internalIdentifier: 8, name: 'Royalties'}
      }, {
        amount: row.profit,
        categoryCost: {internalIdentifier: 24, name: 'Rule'},
        adjustmentReason: {internalIdentifier: 9, name: 'Profit'}
      }]
    };
  }

  loadProduct() {
    this.ruleService.getProductDerivatives()
      .subscribe(({data, loading}) => {
        this.product = data.productsByPlant;
        this.productService.executionSpecialEquipmentForTypes([...new Set(this.product.map(p => p.derivative.types.map(t => t.code))[0])])
          .subscribe(({data, loading}) => {
            this.executionCountryCodes = data.productExecutionSpecialEquipmentCountryCodeByTypes;
          });
        this.showLoading = false;
      }, (error) => {
        this.ruleService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  getType = (derivativeCode, typeCode) => {
    return this.product.filter(p => p.derivative.code === derivativeCode).map(p => p.derivative.types.filter(t => t.code === typeCode))[0].map(type => {
      let ruleType:Type = new Type();
      ruleType.internalIdentifier = type.internalIdentifier
      return ruleType;
    })[0];
  }

  getTypeSpecialEquipment = (derivativeCode, typeCode, specialEquipmentCode) => {
    return this.product.filter(p => p.derivative.code === derivativeCode).map(p => p.derivative.types.filter(t => t.code === typeCode))[0].map(type => type)[0]
      .specialEquipment.filter(s => s.code === specialEquipmentCode).map(specialEquipment => {
        let ruleSpecialEquipment: SpecialEquipment = new SpecialEquipment();
        ruleSpecialEquipment.internalIdentifier = specialEquipment.internalIdentifier;
        return ruleSpecialEquipment;
      })[0];
  }
}
