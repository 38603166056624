import {PriceSummary} from "./pricesummary.model";

export class PriceSummaryCellData {
  priceDate: string;
  workflowState: PriceWorkflowState
  status: string;
  summaryRow: PriceSummary;
  hideApproveRejectButtons: boolean;

  constructor() {
  }

  get() {
    this.priceDate = this.summaryRow.date;
    this.workflowState = this.getPriceState(this.status);
    return this;
  }

  getPriceState (status: String) {
    if (status === "open")
      return PriceWorkflowState.NEW;
    if (status ===  "released")
      return PriceWorkflowState.APPROVED;
    if (status === "rejected")
      return PriceWorkflowState.REJECTED;
  }

}

export interface FreezePriceSearchRequest {
  priceDate: string;
  workflowState: PriceWorkflowState
}

export enum WorkflowState {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  UPDATED = 'UPDATED'
}

export enum PriceWorkflowState {
  APPROVED = 'APPROVED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  TRANSFERRED = 'TRANSFERRED',
  UPDATED = 'UPDATED'
}
