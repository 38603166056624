import {IWorkFlow} from "./workflow.model";
import {OperationChangeInput} from "../../model/operations.model";
import {Observable} from "rxjs";
import {SUBMIT_OPERATION_FOR_DECLINE} from "../../operation-details/operation-details.graphql.mutation";
import {faas} from "../../../../constants/faas.constants";
import {ServiceBase} from "../../../../services/service.base";
import {Apollo} from "apollo-angular";

export class DeclineWorkflow  extends ServiceBase implements IWorkFlow {

  constructor(apollo: Apollo){
    super(apollo)
  }

  process(operation: OperationChangeInput): Observable<any> {
    const variable = {
      operationInput: operation
    };
    return this.mutate(variable, SUBMIT_OPERATION_FOR_DECLINE, faas.productServiceClientName);
  }

  dispose(): void {
  }

}
