import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import * as _ from "lodash";
import {MaterialSearchRequest} from "./model/MaterialSearchRequest";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-material-search-bar',
  templateUrl: './material-search-bar.component.html',
  styleUrls: ['./material-search-bar.component.scss']
})
export class MaterialSearchBarComponent implements OnInit {

  materialSearchForm: FormGroup;
  searchRequest: MaterialSearchRequest;

  @Output() onSearch = new EventEmitter<MaterialSearchRequest>();
  @Output() onClear : EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {
    this.searchRequest = new MaterialSearchRequest();
  }

  ngOnInit(): void {
    this.materialSearchForm = new FormGroup({
      snr: new FormControl(['']),
      ai: new FormControl([''])
    });
  }

  search() {
    try {
      this.canSearch()
      this.searchRequest.isError = false;
    } catch (e) {
      this.searchRequest.isError = true;
      this.searchRequest.error = e.toString();
    }
    this.onSearch.emit(this.searchRequest);
  }

  canSearch() {
    let result = ((this.materialSearchForm.get("snr").value &&
        !_.isEmpty(this.materialSearchForm.get("snr").value.toString().trim()) ||
        this.materialSearchForm.get("ai").value &&
        !_.isEmpty(this.materialSearchForm.get("ai").value.toString().trim()))
    );

    if (!result)
      throw this.translate.instant("menu.pricing.order-management.error-message.either-snr-ai");

    this.validate();

  }


  validate() {
    let ai = this.materialSearchForm.get("ai").value;
    let snr = this.materialSearchForm.get("snr").value;

    ai = ai?.toString().trim();

    if (!_.isEmpty(snr?.toString().trim())) {
      if (ai?.length == undefined || ai?.length == 0 || ai?.length >= 1) {
        this.searchRequest.ai = (ai?.length == undefined || ai?.length == 0) ? null : parseInt(ai);
        this.searchRequest.snr = snr?.toString().trim();
        return true;
      }
    }
  }

  clearFilters() {
    this.materialSearchForm.setValue({
      snr: null,
      ai: null
    });
    this.searchRequest.ai = null;
    this.searchRequest.snr = null;
    this.searchRequest.isError = false;
    this.searchRequest.error =  null;
    this.onClear.emit();
  }
}
