import { Pipe, PipeTransform } from '@angular/core';
import {FaasDatesHelper} from "../../helper/faas.dates.helper";

@Pipe({
  name: 'faasDate'
})
/**
 * faas date for use for date with this format to give you
 * the standard faas date format of DD.MM.YYYY
 * return moment(date,'YYYY-MM-DD').format('DD.MM.YYYY');
 */
export class FaasDatePipe implements PipeTransform {

  transform(value: string): string {
    return FaasDatesHelper.formatStringDateToString(value);
  }

}
