import gql from "graphql-tag";
import {Product} from "../../product/model/product.model";
import {DateRange, ManufacturingCostHistory, Type} from '../../manufacturing/model/reports.model';
import {ExchangeRate} from '../../exchange-rates/model/exchange-rates.model';
import { ProductVehicle } from '../model/reports.model';
import { ProductType } from '../model/reports.model';
import {OperationFile} from "../model/operations.model";

export const MANUFACTURING_REPORT_PRODUCTS_QUERY = gql`
query productsByPlant($plant: Plant!, $isActive: Boolean) {
  productsByPlant(plant:$plant, isActive: $isActive) {
    internalIdentifier
    validity {
      fromDate
      toDate
    }
    derivative {
      internalIdentifier
      code
      derivativeImportStatus
    }
 }
}`;

export const MANUFACTURING_REPORT_DERIVATIVE_TYPE_QUERY = gql`
query productConfigurationTypesByDerivatives($plant: Plant!, $derivative: [String]!) {
productConfigurationTypesByDerivatives(plant: $plant, derivative: $derivative) {
  internalIdentifier
  code
  name
  typeImportStatus
  validity{
    fromDate
    toDate
  }
}
}`;

export const TRANSFER_PRICE_TRANSITION_PER_MODEL_TYPE_BY_PLANT = gql`
query transferPriceTransitionPerModelTypeByPlant($plant: Plant!) {
  transferPriceTransitionPerModelTypeByPlant(plant: $plant) {
    derivativeCode
    internalIdentifier
    price {
      value
    }
    previousManufacturingCosts {
      internalIdentifier
      price {
        value
      }
    }
    type {
      axlGear
      capacity
      code
      country
      cylinder
      engineName
      engineSeries
      equipBase
      fuel
      internalIdentifier
      name
      steering
      typeImportStatus
      rulesHistory {
        amount {
          ROUNDING_MODE
          value
        }
      }
      productionType {
        productionTypeCode
        productionTypeImportStatus
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
        manufacturingCostHistory {
          derivativeCode
          internalIdentifier
          operationId
          operationInternalIdentifier
          operationName
          releaseDate
          validFrom
          price {
            ROUNDING_MODE
            value
          }
          previousManufacturingCosts {
            derivativeCode
            internalIdentifier
            price {
              ROUNDING_MODE
              value
            }
          }
          transferPrice {
            internalIdentifier
            royaltiesIndicator
            transferCategoryCost {
              internalIdentifier
              amount {
                ROUNDING_MODE
                value
              }
              adjustmentReason {
                internalIdentifier
                name
                changeType{
                  internalIdentifier
                  name
                }
              }
              categoryCost {
                internalIdentifier
                name
              }
            }
          }
        }
      }
      packets {
        code
        generation
        internalIdentifier
        name
        packetImportStatus
        sequence
        manufacturingCostHistory {
          derivativeCode
          internalIdentifier
          operationId
          operationInternalIdentifier
          operationName
          releaseDate
          validFrom
          price {
            ROUNDING_MODE
            value
          }
          previousManufacturingCosts {
            derivativeCode
            internalIdentifier
            price {
              ROUNDING_MODE
              value
            }
          }
          transferPrice {
            internalIdentifier
            royaltiesIndicator
            transferCategoryCost {
              internalIdentifier
              amount {
                ROUNDING_MODE
                value
              }
              adjustmentReason {
                internalIdentifier
                name
                changeType{
                  internalIdentifier
                  name
                }
              }
              categoryCost {
                internalIdentifier
                name
              }
            }
          }
        }
      }
    }
    specialEquipment {
      code
      equipment
      internalIdentifier
      name
      specialEquipmentImportStatus
      type
    }
    packet {
      code
      generation
      internalIdentifier
      name
      packetImportStatus
      sequence
    }
    transferPrice {
      internalIdentifier
      royaltiesIndicator
      transferCategoryCost {
        amount {
          ROUNDING_MODE
          value
        }
        internalIdentifier
        adjustmentReason {
          internalIdentifier
          name
          changeType {
            internalIdentifier
            name
          }
        }
        categoryCost {
          internalIdentifier
          name
        }
      }
    }
    releaseDate
    validFrom
    operationId
    operationName
  }
}
`;

export const MANUFACTURING_REPORT_EXCHANGE_RATES_QUERY = gql`
  query exchangeRates($plant: PlantInput! $year: Int!) {
    exchangeRates(plant:$plant, year:$year) {
    internalIdentifier
    exchangeRateType
    year
    month
    value
    state
    statusDate
    statusReason
  }
 }`;

export const MANUFACTURING_REPORT_EXCHANGE_RATES_YEARS_FOR_PLANT = gql`
  query exchangeRatesYearsForPlant($plant: PlantInput!) {
    exchangeRatesYearsForPlant(plant:$plant)
 }`;

export const MANUFACTURING_REPORT_PAID_TRANSFERPRICE =  gql`
query reportProductPaidTransferPricePerVinByPlant($plant: Plant!, $derivatives: [String], $types: [String],
$validFrom: String, $validTo: String) {
  reportProductPaidTransferPricePerVinByPlant(plant: $plant, derivatives: $derivatives, types: $types, validFrom: $validFrom,
  validTo: $validTo) {
    derivative
    designation
    f2Date
    fnDate
    plant
    royaltiesIndicator
    transferPrice
    type
    vin
    rules
    profitForOptions
    royaltiesForOptions
    productCategories {
      bmwParts
      customsClearanceFee
      depreciation
      directLabour
      fuelAndEnergy
      gwmParts
      importDuties
      inboundLogistics
      indirectLabour
      nonDeductibleIndirectTaxesDirectlyLinkedToProject
      nonDeductibleIndirectTaxesNotDirectlyLinkedToProject
      otherFixedExpenses
      outboundLogistics
      profitFivePercent
      royaltiesForTechnology
      salParts
      thirdPartyParts
      variableManufacturingExpenses
      warranty
      yearlyProductionCostReduction
    }
    productBodyAndRoofColours {
      total
      productOptions {
        amount
        description
        numberOfKeys
      }
    }
    productDesignTrims {
      total
      productOptions {
        amount
        description
        numberOfKeys
      }
    }
    productExecutions {
      total
      productOptions {
        amount
        description
        numberOfKeys
      }
    }
    productPackets {
      total
      productOptions {
        amount
        description
        numberOfKeys
      }
    }
    productSingleSas {
      total
      productOptions {
        amount
        description
        numberOfKeys
      }
    }
  }
}`;

export const DOWNLOAD =  gql`
query downloadPaidTransferPricePerVinByPlant($plant: Plant!, $derivatives: [String], $types: [String],
$validFrom: String, $validTo: String) {
  downloadPaidTransferPricePerVinByPlant(plant: $plant, derivatives: $derivatives, types: $types, validFrom: $validFrom,
  validTo: $validTo) {
    content
    internalIdentifier
    name
  }
}`;

export const MANUFACTURING_REPORT_ACCUMULATED_PAID_TRANSFERPRICE =  gql`
query reportProductAccumulatedTransferPricePerTypeByPlant($plant: Plant!, $derivatives: [String], $types: [String],
$validFrom: String, $validTo: String) {
    reportProductAccumulatedTransferPricePerTypeByPlant(plant: $plant, derivatives: $derivatives, types: $types,
    validFrom: $validFrom, validTo: $validTo) {
    derivative
    designation
    plant
    royaltiesIndicator
    transferPrice
    transferPriceNoOfKeys
    type
    f2Date
    fnDate
    rules
    profitForOptions
    royaltiesForOptions
    productCategories {
      bmwParts
      bmwPartNoOfKeys
      customsClearanceFee
      customsClearanceFeeNoOfKeys
      depreciation
      depreciationNoOfKeys
      directLabour
      directLabourNoOfKeys
      fuelAndEnergy
      fuelAndEnergyNoOfKeys
      gwmParts
      gwmPartNoOfKeys
      importDuties
      importDutiesNoOfKeys
      inboundLogistics
      inboundLogisticsNoOfKeys
      indirectLabour
      indirectLabourNoOfKeys
      nonDeductibleIndirectTaxesDirectlyLinkedToProject
      nonDeductibleIndirectTaxesDirectlyLinkedToProjectNoOfKeys
      nonDeductibleIndirectTaxesNotDirectlyLinkedToProject
      nonDeductibleIndirectTaxesNotDirectlyLinkedToProjectNoOfKeys
      otherFixedExpenses
      otherFixedExpensesNoOfKeys
      outboundLogistics
      outboundLogisticsNoOfKeys
      profitFivePercent
      profitFivePercentNoOfKeys
      royaltiesForTechnology
      royaltiesForTechnologyNoOfKeys
      salParts
      salPartNoOfKeys
      thirdPartyParts
      thirdPartyPartNoOfKeys
      variableManufacturingExpenses
      variableManufacturingExpensesNoOfKeys
      warranty
      warrantyNoOfKeys
      yearlyProductionCostReduction
      yearlyProductionCostReductionNoOfKeys
    }
    productBodyAndRoofColours {
      total
      numberOfKeys
      productOptions {
        amount
        description
		numberOfKeys
      }
    }
    productDesignTrims {
      total
      numberOfKeys
      productOptions {
        amount
        description
		numberOfKeys
      }
    }
    productExecutions {
      total
      numberOfKeys
      productOptions {
        amount
        description
		numberOfKeys
      }
    }
    productPackets {
      total
      numberOfKeys
      productOptions {
        amount
        description
		numberOfKeys
      }
    }
    productSingleSas {
      total
      numberOfKeys
      productOptions {
        amount
        description
		numberOfKeys
      }
    }
  }
}`;

export const RETRIEVE_DATE_RANGE_QUERY = gql`
  query retrieveDateRange($plant: Plant!, $validFrom: String, $validTo: String) {
    retrieveDateRange(plant:$plant, validFrom:$validFrom, validTo:$validTo) {
      periodMaxValue
      periodMaxUnit
      adjusted
      fromDate
      toDate
  }
 }`;

export interface ManufacturingReportProductsQueryResponse {
    productsByPlant: Product[];
}

export interface ManufacturingReportTypesQueryResponse {
  productConfigurationTypesByDerivatives: Type[];
}

export interface TransferPriceTransitionPerModelTypeByPlantQueryReportResponse {
  transferPriceTransitionPerModelTypeByPlant: ManufacturingCostHistory[];
}

export interface ManufacturingReportExchangeRatesQueryResponse {
  exchangeRates: ExchangeRate[];
}

export interface ManufacturingReportExchangeRatesYearsForPlantQueryResponse {
  exchangeRatesYearsForPlant: number[];
}

export interface ManufacturingReportPaidTransferPriceQueryResponse {
  reportProductPaidTransferPricePerVinByPlant: ProductVehicle[];
}

export interface Download {
  downloadPaidTransferPricePerVinByPlant: OperationFile;
}

export interface ManufacturingReportAccumulatedPaidTransferPriceQueryResponse {
  reportProductAccumulatedTransferPricePerTypeByPlant: ProductType[];
}

export interface DateRangeQueryResponse {
  retrieveDateRange: DateRange;
}
