<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h1 class="bmw-h1 bmw__margin-top__l">Found Cars</h1>
      <div class="bmw__margin-top__l">
        <bmw-inline-message
          [type]="'info'"
          [messages]="'Please notice: The listed cars are only available in Germany.'"
        ></bmw-inline-message>
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-master-detail-list style="--list__item__col-size: 60% 40%;">
          <bmw-master-detail-list-row
            *ngFor="let row of rows"
            [selected]="selectedItem === row"
            (updateEvent)="selectedItem = row"
          >
            <bmw-master-detail-list-row-entry label="{{ row.car }}" [bold]="true"></bmw-master-detail-list-row-entry>

            <bmw-master-detail-list-row-entry
              label="{{ row.availableFrom }}"
              [optional]="true"
            ></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry label="{{ row.transmission }}"></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry
              label="{{ row.carPaint }}"
              [optional]="true"
            ></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry
              label="{{ row.upholstery }}"
              [optional]="true"
            ></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry
              label="{{ row.listPrice }}"
              [optional]="true"
            ></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry label="{{ row.price }}" [bold]="true"></bmw-master-detail-list-row-entry>
          </bmw-master-detail-list-row>

          <!-- list-detail content is related to selected list-item -->
          <bmw-master-detail-list-detail *ngIf="selectedItem" (closeEvent)="selectedItem = undefined">
            <div details-header class="bmw-component-headline-text">Configuration details</div>
            <h2 class="bmw-h2 bmw__margin-top__m">{{ selectedItem.car }}</h2>
            <div class="bmw__grid__inner">
              <div class="bmw__grid__cell--span-5">
                <h4 class="bmw-h4 bmw__margin-top__m">Car informations</h4>
                <p class="bmw-regular-text bmw__margin-top__s">
                  A sporty performer built to impress on the road. More dynamic, agile and comfortable. Uncompromisingly
                  ready for action by offering a design, presence and handling that have all been consistently
                  optimised. In short: a breathtaking sports coupe that infuses driving pleasure with new meaning and
                  satisfies even highest demands.
                </p>
              </div>
              <div class="bmw__grid__cell--span-1"></div>
              <div class="bmw__grid__cell--span-6">
                <h4 class="bmw-h4 bmw__margin-top__m">Line</h4>
                <div class="bmw__margin-top__s">
                  <bmw-checkbox>Advantage</bmw-checkbox>
                  <bmw-checkbox [checked]="'checked'">Sport Line</bmw-checkbox>
                  <bmw-checkbox>Luxury Line</bmw-checkbox>
                  <bmw-checkbox>M Sport</bmw-checkbox>
                </div>
              </div>
            </div>
            <h4 class="bmw-h4 bmw__margin-top__l bmw__margin-bottom__s">Rental Options</h4>
          </bmw-master-detail-list-detail>
        </bmw-master-detail-list>
      </div>
    </div>
  </div>
</div>
