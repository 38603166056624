import {Component, OnInit, ViewChild} from '@angular/core';
import {ProductMasterDataDisplayComponent} from "../product-master-data-display/product-master-data-display.component";
import {ProductConfigurationComponent} from "../product-configuration/product-configuration.component";

@Component({
  selector: 'app-product-master-data',
  templateUrl: './product-master-data.component.html',
  styleUrls: ['./product-master-data.component.scss']
})
export class ProductMasterDataComponent {
  @ViewChild (ProductMasterDataDisplayComponent) productMasterDataDisplayComponent;
  @ViewChild (ProductConfigurationComponent) productConfigurationComponent;

  constructor() { }

  tabChanged($event: string) {
    switch ($event) {
      case "data-view":
        this.productMasterDataDisplayComponent.refreshData();
        break;
      default:
        this.productConfigurationComponent.initialisePageValues();
        break;
    }
  }
}
