import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AgGridBase} from "../../../ag.grid.base";
import {RecalcPriceGridColumns} from "./recalc-price-grid.columns";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'faas-recalc-request-grid',
  templateUrl: './recalc-price-grid.component.html',
  styleUrls: ['./recalc-price-grid.component.scss']
})
export class RecalcPriceGridComponent extends AgGridBase<RecalcPriceGridColumns> {
  paginationPageSize: 10;
  rowSelection: 'single';
  @Output() refresh = new EventEmitter();

  constructor(translate: TranslateService) {
    super(new RecalcPriceGridColumns(translate));
  }

  onRefresh() {
    this.refresh.emit();
  }
}
