<div *ngIf="errorMessage" class="bmw__margin-top__l">
  <bmw-inline-message type="error">
    {{errorMessage}}
  </bmw-inline-message>
</div>
<div *ngIf="infoMessage" class="bmw__margin-top__l">
  <bmw-inline-message type="info">
    {{infoMessage}}
  </bmw-inline-message>
</div>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Saving..." [show]="loadingScreen"></bmw-progress-circle>
<form [formGroup]="currencyForm" class="reversed">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 bmw__margin-top__l">
        <bmw-master-detail-list style="--list__item__col-size: 60% 40%;">
          <bmw-master-detail-list-row
            *ngFor="let row of rows"
            [selected]="selectedItem === row"
            (updateEvent)="selectedItem = row">
            <bmw-master-detail-list-row-entry label="{{ row.currencyType }}"
                                              [bold]="true"></bmw-master-detail-list-row-entry>
            <bmw-master-detail-list-row-entry
              label="{{ row.currency }}"
              [optional]="false"
            ></bmw-master-detail-list-row-entry>
          </bmw-master-detail-list-row>
          <!-- list-detail content is related to selected list-item -->
          <bmw-master-detail-list-detail *ngIf="selectedItem" (closeEvent)="selectedItem = undefined">
            <div details-header class="bmw-component-headline-text">{{'menu.pricing.exchange-rates.currency.currency-configuration.title' | translate}}</div>
            <div *ngIf="display">
              <h2 class="bmw-h2 bmw__margin-top__m">{{ selectedItem.currency }}</h2>
              <div class="bmw__grid__inner">
                <div class="bmw__grid__cell--span-5">
                  <hr/>
                  <p class="bmw-regular-text bmw__margin-top__s">
                    <b>{{'menu.pricing.exchange-rates.currency.currency-configuration.currency-type' | translate}}</b> {{selectedItem.currencyType}}

                  </p>
                  <p class="bmw-regular-text bmw__margin-top__s">
                    <b>{{'menu.pricing.exchange-rates.currency.currency-configuration.currency-scale' | translate}}</b> {{selectedItem.scale}}
                  </p>
                </div>
              </div>
            </div>
            <div *ngIf="!display">
              <h2 class="bmw-h2 bmw__margin-top__m">{{'menu.pricing.exchange-rates.currency.currency-configuration.new-currency' | translate}}</h2>
              <div class="bmw__grid__inner">
                <div class="bmw__grid__cell--span-5">
                  <hr/>
                  <div class="bmw__margin-top__m">
                    <bmw-inputfield id="currencyCode">
                      <bmw-label>{{'menu.pricing.exchange-rates.currency.currency-configuration.currency-code' | translate}}</bmw-label>
                      <input
                        bmwInput
                        type="text"
                        maxlength="3"
                        name="currencyCode"
                        placeholder="E.g.: USD"
                        formControlName="currencyCode"
                        style="text-transform: uppercase;"
                      />
                    </bmw-inputfield>
                  </div>
                  <div class="bmw__margin-top__m">
                    <bmw-combo-box [placeholder]="'menu.pricing.exchange-rates.currency.currency-configuration.please-select' | translate" formControlName="currencyType">
                      <bmw-label>{{'menu.pricing.exchange-rates.currency.currency-configuration.type' | translate}}</bmw-label>
                      <bmw-combo-box-dropdown-item *ngFor="let ct of currencyTypes" value="{{ct}}"
                                                   label="{{ct}}"></bmw-combo-box-dropdown-item>
                    </bmw-combo-box>
                  </div>
                  <div class="bmw__margin-top__m">
                    <bmw-inputfield id="currencyScale">
                      <bmw-label>{{'menu.pricing.exchange-rates.currency.currency-configuration.scale' | translate}}</bmw-label>
                      <input
                        bmwInput
                        bmwInputStepper
                        min="0"
                        name="currencyScale"
                        type="number"
                        placeholder="5"
                        formControlName="currencyScale"
                      />
                      <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
                    </bmw-inputfield>
                  </div>
                </div>
              </div>
            </div>
            <div  *ngIf="!display" class="bmw__grid__inner">
              <div class="bmw__grid__cell--span-5">
                <div
                  class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
                  <div
                    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
                    <bmw-button button="secondary" type="submit" (clickEvent)="cancel()" >
                      {{ 'component.bottom-bar.cancel' | translate }}
                    </bmw-button>
                  </div>
                  <div
                    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
                    <bmw-button button="primary" type="submit" (clickEvent)="onSave()" [disabled]="saveDisabled">
                      {{ 'component.bottom-bar.save' | translate }}
                    </bmw-button>
                  </div>
                </div>
              </div>
            </div>
          </bmw-master-detail-list-detail>
        </bmw-master-detail-list>
      </div>
  </div>
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 bmw__margin-top__s">
      <div class="bmw__grid__cell--span-5">
        <div
          class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start">
          <div
            class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
            <bmw-button button="secondary" [hidden]="notAdmin" type="submit" (clickEvent)="onAddNew()" [disabled]="addDisabled" style="margin-left: 5px;">
              {{ 'component.bottom-bar.add' | translate }}
            </bmw-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


