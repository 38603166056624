<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.administration.roles.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab (selectionChange)="tabClick($event)">
          <bmw-content-tab-label for="overview">{{ 'menu.administration.roles.overview.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="overview">
            <app-roles-overview></app-roles-overview>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="maintenance">{{ 'menu.administration.roles.maintenance.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="maintenance">
            <app-role-permission-maintanance></app-role-permission-maintanance>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="permissions">{{ 'menu.administration.roles.permissions.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="permissions">
            <app-permissions></app-permissions>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
