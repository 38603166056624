<bmw-ag-grid-theme>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
  <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="info">{{message}}</bmw-inline-message>
  </div>
  <app-vehicle-status-grid data-testid="grid-vehicle-status-grid"
                           [rowData]="rowData"
                           [hasReleaseVehicleRole]="hasReleaseVehicleRole"
                           (vehicleSelected)="setSelectedVehicle($event)"
                           (reCalculateVehicle)="reCalculate()"
                           (refresh)="retrieveVehicleStatus($event)"
  ></app-vehicle-status-grid>
</bmw-ag-grid-theme>
