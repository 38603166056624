import gql from "graphql-tag";
import {ISOCurrency} from "../../exchange-rates/model/exchange-rates.model";

export const CURRENCIES_QUERY = gql`
  query currencies($plant: PlantInput!) {
    currencies(plant:$plant) {
        internalIdentifier
        currency
        currencyType
        displayFormat
        plant
        scale
      }
 }`;

export const ADD_CURRENCY = gql`
  mutation ($currency: ISOCurrencyInput!) {
    addNewCurrency(currency: $currency)
  }
`;

export interface CurrenciesQueryResponse {
  currencies: ISOCurrency[];
}
