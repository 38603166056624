import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgGridBase} from "../../../../ag.grid.base";
import {TranslateService} from "@ngx-translate/core";
import {RoleGridColumns} from "./role-grid.columns";
import {Permission} from "../../model/user.model";

@Component({
  selector: 'app-role-grid',
  templateUrl: './role-grid.component.html',
  styleUrls: ['./role-grid.component.scss']
})
export class RoleGridComponent extends AgGridBase<RoleGridColumns> {

  @Output() saveRole = new EventEmitter<Permission[]>();
  @Output() cancelView = new EventEmitter();

  rowSelection = 'multiple';

  constructor(translate: TranslateService) {
    super(new RoleGridColumns(translate));
  }

  onCancel() {
    this.cancelView.emit();
  }

  onSave() {
    if (this.gridApi.getSelectedRows().length == 0){
      return;
    }

    let result: any = [];
    this.gridApi.getSelectedRows().forEach( params => {
      let permission = new Permission();
      permission.internalIdentifier = params.internalIdentifier;
      permission.name = params.name;
      permission.description = params.description;
      result.push(permission);
    });
    this.saveRole.emit(result);
  }

}
