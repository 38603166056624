import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OperationService} from "../service/operation.service";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {Subscription} from "rxjs";
import moment from "moment";
import {ManufacturingCostsHistoryTransformer} from "../service/manufacturing-cost-history-transformer";

@Component({
  selector: 'app-manufacturing-costs',
  templateUrl: './manufacturing-costs.component.html',
  styleUrls: ['./manufacturing-costs.component.scss']
})
export class ManufacturingCostsComponent {
  processing = false;
  errorMessage: string;
  rowData: any;
  private subscription: Subscription;
  typeSpecialEquipmentGrouping: any;
  manufacturingCostsHistoryTransformer: ManufacturingCostsHistoryTransformer;

  constructor(private operationService: OperationService, public translate: TranslateService, private plantService: PlantChangeService) {
    this.subscription = this.plantService.onChange().subscribe(message => {
      if (message) {
        this.refreshData();
      }
    });
    this.typeSpecialEquipmentGrouping = this.operationService.getTypeSpecialEquipmentGrouping();
    this.manufacturingCostsHistoryTransformer= new ManufacturingCostsHistoryTransformer();
  }

  refreshData() {
    this.rowData = undefined;
    this.processing = true;

    this.operationService.allManufacturingCosts()
      .subscribe((result) => {
        this.processing = false;
        let history = result.data.manufacturingCostHistoryByPlant.map(row => {
          return this.manufacturingCostsHistoryTransformer.transformManufacturingCostHistory(row);
        }).sort((a, b) => moment(b.validFrom).toDate().getTime() - moment(a.validFrom).toDate().getTime()
          || (a.operationName.includes('Negate') || b.operationName.includes('Negate')
            ? moment(a.releaseDate).toDate().getTime() - moment(b.releaseDate).toDate().getTime()
            : moment(b.releaseDate).toDate().getTime() - moment(a.releaseDate).toDate().getTime()));
        let statusMap = new Map();
        this.rowData = history.map(row => {
          let key = row.type.code + row.specialEquipmentCode;
          if (statusMap.has(key)) {
            if (row.status === 'ACTIVE') {
              row.status = 'PREVIOUS';
            }
          } else {
            statusMap.set(key, row);
          }
          return row;
        }).sort((a, b) => parseInt(a.groupOrder) - parseInt(b.groupOrder));
      }, (error) => {
        this.processing = false;
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
  }

}
