<div class="bmw__grid">
    <form [formGroup]="filterForm" class="reversed">
      <div class="bmw__grid__inner">
        <div class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-combo-box formControlName="selectedDerivatives" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" required="false" (changeEvent)="selectedDerivativesChanged($event);" [(ngModel)]="selectedDerivatives">
            <bmw-label>{{ 'prod_derivative' | translate }}</bmw-label>            
            <bmw-combo-box-dropdown-item *ngFor="let product of derivativeList" [value]="product.derivative.code" [label]="product.derivative.code" ></bmw-combo-box-dropdown-item>
            <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
          </bmw-combo-box>
          <bmw-checkbox formControlName="derivativeCheckbox" (change)="derivativeCheckboxChanged($event);">{{ 'salReportSelectDeselect' | translate }}</bmw-checkbox>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-combo-box  formControlName="selectedTypes" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" required="false" [(ngModel)]="selectedTypes">
            <bmw-label>{{ 'desigtype' | translate }}</bmw-label>            
            <bmw-combo-box-dropdown-item *ngFor="let type of typeList" [value]="type.code" [label]="type.code + ' ' +type.name" ></bmw-combo-box-dropdown-item>
            <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
          </bmw-combo-box>
          <bmw-checkbox formControlName="typeCheckbox" (change)="typeCheckboxChanged($event);">{{ 'salReportSelectDeselect' | translate }}</bmw-checkbox>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-label [for]="'date-entered'">{{ 'salReportValidFrom' | translate }}</bmw-label>
          <bmw-datepicker
            #datepicker
            [inputId]="'date-entered'"
            required="false"
            placeholder="{{ 'salReportDDMMYYYY' | translate }}"
            dateFormat="dd/mm/yy"
            showButtonBar="true"
            showWeek="true"
            [(ngModel)]="effectiveFrom"
            [ngModelOptions]="{standalone: true}"
          >
          </bmw-datepicker>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-label [for]="'date-effective'">{{ 'salReportValidTo' | translate }}</bmw-label>
          <bmw-datepicker
            #datepicker
            [inputId]="'date-effective'"
            required="false"
            placeholder="{{ 'salReportDDMMYYYY' | translate }}"
            dateFormat="dd/mm/yy"
            showButtonBar="true"
            showWeek="true"
            [(ngModel)]="effectiveTo"
            [ngModelOptions]="{standalone: true}"
          >
          </bmw-datepicker>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-combo-box formControlName="selectedExchange" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedExchange">
            <bmw-label>{{ 'salReportCurrency' | translate }}</bmw-label>
            <bmw-combo-box-dropdown-item value="CNY" label="CNY"></bmw-combo-box-dropdown-item>  
            <bmw-combo-box-dropdown-item value="EUR" label="EUR"></bmw-combo-box-dropdown-item>           
          </bmw-combo-box>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
          <bmw-label>&nbsp; </bmw-label>
          <bmw-button button="primary" (click)="generateReport();">{{ 'salReportGenerate' | translate }}</bmw-button>&nbsp;
        </div>
      </div>
    </form>
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <div *ngIf="showLoading">
        <bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..."></bmw-progress-circle>
      </div>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <div *ngIf="showReport" class="bmw__margin-top__xs">
        <ag-grid-angular
            style="height: 500px; overflow: scroll"
            class="zebra"
            [rowData]="rowData"
            [columnDefs]="runtimeCompilerData.columns"
            [gridOptions]="runtimeCompilerData.gridOptions"
            [defaultColDef]="runtimeCompilerData.defaultColDef"
            [sideBar]="runtimeCompilerData.sideBar"
            [statusBar]="runtimeCompilerData.statusBar"
            (firstDataRendered)="onFirstDataRendered($event)"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
      </div>
    </div>
    <bmw-dialog
    [type]="'info'"
    [show]="showPromt"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [primaryDisabled]="false"
    (primaryClick)="showPromt = false"
    (closeClick)="showPromt = false"
  >
    <p class="bmw-regular-text">{{ dialogText }}</p>
  </bmw-dialog>
  </div>
  
  