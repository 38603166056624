import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {OrderSearchNotifierService} from "../service/order-search-notifier.service";

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.sass']
})
export class
OrderManagementComponent implements OnInit {

  orderNumber: string;
  snr: string;

  constructor(private route: ActivatedRoute,
              private orderSearchNotifierService: OrderSearchNotifierService) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.orderNumber = params['orderNumber'];
      this.snr = params['snr'];
    });
  }

  cascadeSearchData(tabName: string) {
    this.orderSearchNotifierService.populateSearchData(tabName);
  }
}
