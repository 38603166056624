import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentTabComponent} from '@bmw-ds/components';
import {OperationsDisplayComponent} from "./operations-display/operations-display.component";
import {OperationDetailsComponent} from './operation-details/operation-details.component';
import {TranslateService} from '@ngx-translate/core';
import {OperationSummary} from './model/operations.model';
import {OperationService} from './service/operation.service';
import {ManufacturingCostsComponent} from "./manufacturing-costs/manufacturing-costs.component";
import {RuleHistoryComponent} from "./rule-history/rule-history.component";
import {ManufacturingCostsActionedComponent} from './manufacturing-costs-actioned/manufacturing-costs-actioned.component';
import {PlantChangeService} from "../header/service/PlantChangeService";
import {Plant} from "../order/model/order.model";


@Component({
  selector: 'app-manufacturing',
  templateUrl: './manufacturing.component.html',
  styleUrls: ['./manufacturing.component.scss']
})
export class ManufacturingComponent implements OnInit {
  @ViewChild('contentTab') tabComponent: ContentTabComponent;
  @ViewChild(OperationsDisplayComponent) operationsDisplayComponent: OperationsDisplayComponent;
  @ViewChild(OperationDetailsComponent) operationDetailsComponent: OperationDetailsComponent;
  @ViewChild(ManufacturingCostsComponent) manufacturingCostsComponent: ManufacturingCostsComponent;
  @ViewChild(RuleHistoryComponent) rulesHistoryComponent: RuleHistoryComponent;
  @ViewChild(ManufacturingCostsActionedComponent) manufacturingCostsActioned: ManufacturingCostsActionedComponent;
  dialogTitle: string;
  dialogText: string;
  showPrompt = false;
  operation: any;
  tobeActionedCount: number;

  constructor(
    public translate: TranslateService,
    public operationService: OperationService,
    private plantService: PlantChangeService
  ) {
  }

  ngOnInit(): void {
    this.getManufacturingCostsToBeActionedCount();
  }


  changeTab(event) {
    const operation: OperationSummary = this.manufacturingCostsActioned.selected !== undefined
      ? this.manufacturingCostsActioned.selected : this.operationsDisplayComponent.selectedOperation;

    if (event === 'operations') {
      this.operationsDisplayComponent.refreshData();
    }

    if (event === 'rulesHistory') {
      this.rulesHistoryComponent.loadRulesHistory();
    }

    if (event === 'tobeActioned') {
      this.manufacturingCostsActioned.loadOperations();
    }

    if (event === 'operationDetails' && !operation) {
      this.dialogTitle = this.translate.instant('messages.noseltoopen');
      this.dialogText = this.translate.instant('messages.selecttoopen');
      this.showPrompt = true;
      this.tabComponent.setSelected('operations');
    }

    if (operation && event === 'operationDetails') {
      this.operationDetailsComponent.loadOperationDetails(operation);
    }

    if (event === 'manufacturingCosts') {
      if (this.operationDetailsComponent.operation !== undefined
        && this.operationsDisplayComponent.selectedOperation !== undefined) {
        this.operationsDisplayComponent.selectedOperation = this.operationDetailsComponent.operation;
      }
      this.manufacturingCostsComponent.refreshData();
    }
  }

  changeTabToOperationsDetails(operation: OperationSummary) {
    this.operationDetailsComponent.loadOperationDetails(operation);
    this.tabComponent.setSelected('operationDetails');
  }

  private getManufacturingCostsToBeActionedCount() {
    this.operationService.getOperationsToBeActionedCount()
      .subscribe((result) => {
        this.tobeActionedCount = result.data.manufacturingCostsToBeActioned.length;
      });
  }
}
