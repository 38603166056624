import gql from "graphql-tag";
import {OperationFile, Operation, OperationSummary, HelpDocument} from '../model/operations.model';

export const ALL_OPERATION_BY_PLANT = gql`
  query operations($plant: Plant!) {
    operations(plant: $plant) {
      costEntries
      internalIdentifier
      operationId
      name
      releaseDate
      statusDate
      statusReason
      statusUser
      validFrom
      workFlowStatus
      files {
        content
        internalIdentifier
        name
      }
    }
 }`;

export const OPERATION_FILE_BY_ID = gql`
  query operationFileById($id: Long!) {
    operationFileById(id: $id) {
      content
      internalIdentifier
      name
    }
 }
`;

export const HELP_DOCUMENT_BY_NAME = gql`
  query retrieveHelpDocument($helpDocumentType: HelpDocumentType!) {
    retrieveHelpDocument(helpDocumentType: $helpDocumentType) {
      content
      internalIdentifier
      name
    }
 }
`;

export const OPERATION_HISTORY_BY_ID = gql`
  query operationHistoryById($id: Long!) {
      operationHistoryById(id: $id) {
        costEntries
        internalIdentifier
        name
        releaseDate
        statusDate
        statusReason
        statusUser
        validFrom
        workFlowStatus
      }
  }
`;

export const OPERATION_STATUS_BY_ID = gql`
  query operationById($id: Long!) {
      operationById(id: $id) {
          workFlowStatus
      }
 }
  `;

export interface OperationsQueryResponse {
  operations: OperationSummary[];
}

export interface OperationFileResponse {
  operationFileById: OperationFile;
}

export interface HelpDocumentResponse {
  retrieveHelpDocument: OperationFile;
}

export interface OperationHistoryResponse {
  operationHistoryById: OperationSummary[];
}

export interface OperationStatusResponse {
  operationById: Partial<Operation>;
}
