import {Component, Input, OnInit} from '@angular/core';
import {RoutingHelper} from "../../helper/routinghelper";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {PlantChangeService} from "../header/service/PlantChangeService";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit  {

  @Input() path: string;
  private routingHelper: RoutingHelper;
  isSALorGWM: boolean = false;
  isSal: boolean = false;
  isDisabledInProd: boolean = false;
  allPlants: boolean = true;

  constructor(private router: Router,
              private plantService: PlantChangeService) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.path = event.url;
    });
    this.routingHelper = new RoutingHelper(this.router);
    this.isDisabledInProd = window.location.href.includes("faas20nsf-prod");
  }

  ngOnInit(): void {
    this.plantService.onChange().subscribe( plant => {
      if (plant) {
        this.isSALorGWM = plant.text === "SAL" || plant.text === "GWM";
        this.isSal = plant.text === "SAL";
        this.router.navigate(['/']);
      }
    });
  }

  goToPage({id}) {
    this.routingHelper.navigate(id);
  }


}
