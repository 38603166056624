import {Component, Input, OnInit} from '@angular/core';
import {PriceComponentData} from "../../model/continous.price.model";
import {PricingPeriodWithOrderDetails} from "../../../order/model/PricingPeriodWithOrderDetails";
import {SurchargesForOrder} from "../../../order/model/SurchargesForOrder";
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../../../header/service/LanguageChangeService";
import moment from "moment";
import {EMPTY_STRING, isUndefinedOrEmpty} from "../../../../shared/functions/typescript.utils";
import {PriceComponentOrder} from "./model/price.component.order";
import {Order} from "../../../order/model/order.model";
import {TranslateContinuousPriceEnums} from "../service/continuous.price.enum.translations";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @Input() priceComponents: PriceComponentData[];
  orderRowData: any = [];
  surchargeData: any = [];
  resizeSurchargeGrid: boolean;
  orderForPriceComponent: PriceComponentOrder;
  pricingPeriodViewModel: PricingPeriodWithOrderDetails;
  surchargeViewModel: SurchargesForOrder;
  orderColumns: any = [];
  surchargeColumns: any = [];
  hideSurcharges: boolean = true;
  hideWeightings: boolean = true;
  baseOrder: Order;
  hideExchangeRates: boolean = true;
  continuousPriceEnums: TranslateContinuousPriceEnums;

  constructor(private translate: TranslateService,
              private langService: LanguageChangeService) {
    this.continuousPriceEnums = new TranslateContinuousPriceEnums();

    this.surchargeViewModel = new SurchargesForOrder();
    this.langService.onChange().subscribe( e=> {
      this.translateAndLoadColumns();
      this.translateInGridEnums();
    });
  }

  ngOnInit(): void {
    this.baseOrder = this.priceComponents.map( p => p.order)[0];
    this.orderForPriceComponent = new PriceComponentOrder(this.priceComponents);
    this.translateAndLoadColumns();
    this.loadData();
    this.hideOrDisplayExchangeRates();
    this.hideOrDisplayWeightings();
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums() {
    let lang = this.translate.currentLang;
    if (this.orderRowData !== undefined) {
      let translatedRowData = [];
      let rows = this.orderRowData;
      rows.forEach(row => {
        if (row !== undefined) {
          row.state = this.continuousPriceEnums.translate(lang, row.state);
          row.orderType = this.continuousPriceEnums.translate(lang, row.orderType);
          row.quantityUnit = this.continuousPriceEnums.translate(lang, row.quantityUnit);
          translatedRowData.push(row); //4A17875
        }
      });
      this.orderRowData = translatedRowData;
    }
    this.reDrawSurchargeGrid(null);
  }

  loadData() {
    this.orderRowData = this.orderForPriceComponent.ordersForPriceComponent();
    this.surchargeData = this.surchargeViewModel.getSurcharges(this.priceComponents.map(o => o.order));
    this.hideOrDisplaySurcharges(this.surchargeData);
    this.translateInGridEnums();
  }

  hideOrDisplaySurcharges = (surcharges: SurchargesForOrder[]) => {
    surcharges.forEach(surcharge => {
      if (surcharge) {
        this.hideSurcharges = false;
      }
    });
  }

  hideOrDisplayExchangeRates = () => {
    this.priceComponents.forEach(s => {
      if (s.exchangeRate) {
        this.hideExchangeRates = false;
      }
    });
  }

  hideOrDisplayWeightings = () => {
    this.priceComponents.forEach(s => {
      if (s.weight) {
        this.hideWeightings = false;
      }
    });
  }


  reDrawSurchargeGrid(params: any) {
    window.dispatchEvent(new Event('resize'));
    this.resizeSurchargeGrid = true;
  }

  translateAndLoadColumns() {
    this.orderColumns = [
      {field: 'orderNumber', headerName: this.localizeHeader('orderNo'), sortable: true},
      {field: 'version', headerName: this.localizeHeader('version'), sortable: false},
      {
        field: 'fromDate',
        headerName: this.localizeHeader('fromDate'),
        sortable: true
      },
      {field: 'toDate', headerName: this.localizeHeader('toDate'), sortable: true},
      {
        field: 'nettPriceValue',
        headerName: this.localizeHeader('nettPriceValue'),
        type: 'rightAligned',
        sortable: false
      },
      {field: 'nettPriceCurrency', headerName: this.localizeHeader('orderCurrency'), sortable: false},
      {field: 'priceUnit', headerName: this.localizeHeader('priceUnit'), sortable: false},
      {
        field: 'basePriceValue',
        headerName: this.localizeHeader('basePriceValue'),
        type: 'rightAligned',
        sortable: false
      },
      {field: 'state', headerName: this.localizeHeader('state'), sortable: true},
      {field: 'orderType', headerName: this.localizeHeader('orderType'), sortable: true},
      {field: 'quantityUnit', headerName: this.localizeHeader('quantityUnit'), sortable: true},
      {field: 'supplierNumber', headerName: this.localizeHeader('supplierNumber'), sortable: true},
      {field: 'printDate', headerName: this.localizeHeader('printDate'), sortable: true},
    ];

    this.surchargeColumns = [
      {field: 'orderNo', headerName: this.localizeHeader('orderNo'), sortable: true},
      {
        field: 'fromDate',
        headerName: this.localizeHeader('fromDate'),
        sortable: true
      },
      {field: 'toDate', headerName: this.localizeHeader('toDate'), sortable: true},
      {
        field: 'surchargeValue',
        headerName: this.localizeHeader('surchargeValue'),
        type: 'rightAligned',
        sortable: false
      },
      {field: 'surchargeType', headerName: this.localizeHeader('surchargeType'), sortable: false},
      {field: 'surchargeCategory', headerName: this.localizeHeader('surchargeCategory'), sortable: false},
    ];
  }

  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

}
