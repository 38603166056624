import gql from "graphql-tag";

export const ALLOCATED_PRODUCTS_BY_PLANT_QUERY = gql`
  query allocatedProductsByPlant($plant: Plant!, $isActive: Boolean, $operationId: Long!) {
    allocatedProductsByPlant(plant: $plant, isActive: $isActive, operationId: $operationId) {
      internalIdentifier
      plant
      validity {
        fromDate
        toDate
      }
      derivative {
        code
        internalIdentifier
        derivativeImportStatus
        types{
          internalIdentifier
          code
          name
          buildType {
            buildTypeCode
            buildTypeImportStatus
          }
          productionType {
            productionTypeCode
            productionTypeImportStatus
          }
          productDataStatus {
            productDataImportStatus
            productDataStatusCode
            productDataStatusCodeNo
          }
          typeImportStatus
          steering
          engineSeries
          validity{
            fromDate
            toDate
          }
          inWorkflow
          specialEquipment {
            internalIdentifier
            code
            name
            specialEquipmentImportStatus
            type
            validity {
              fromDate
              toDate
            }
          }
          packets {
            code
            generation
            internalIdentifier
            name
            sequence
            packetImportStatus
            validity {
              fromDate
              toDate
            }
          }
        }
      }
    }
 }`;

