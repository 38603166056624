
export class RoutingHelper {
  private router: any;
  private readonly routesMap : any;

  constructor(router) {
    this.router = router;
    this.routesMap = new Map();
    this.init();
  }

  navigate(path: string): void {
    this.router.navigate([this.routesMap[path]], {skipLocationChange: true});
  }

  init(): void {
    // key --> is the id tag in app.component.html
    // value --> is the path in app-routing.module
    this.routesMap['order_management'] = 'order-management';
    this.routesMap['weightings'] = 'weightings';
    this.routesMap['home'] = 'home';
    this.routesMap['exchange_rates'] = 'exchange-rates-management';
    this.routesMap['continuous_price'] = 'continuous-price';
    this.routesMap['manual_prices'] = 'manual-prices';
    this.routesMap['freeze_prices'] = 'freeze-prices';
    this.routesMap['prod_master_data'] = 'prod-master-data';
    this.routesMap['user_management'] = 'user-management';
    this.routesMap['manufacturing'] = 'manufacturing';
    this.routesMap['manufacturing_reports'] = 'manufacturing-reports';
    this.routesMap['vehicle'] = 'vehicle';
    this.routesMap['aggregation'] = 'period-end-closing';
    this.routesMap['budget_planning'] = 'budget-planning';
    this.routesMap['prod_master_data_msf_ned'] = 'prod-master-data-ned-msf';
    this.routesMap['quicksight_reports'] = 'quicksight-reports';
  }
}
