import {Store} from "../shared/store";
import {ColumnState} from "ag-grid-community/dist/lib/columnController/columnController";


export class GridSettingsStore extends Store {

    saveGridState<T>(key: string, data: T) {
      this.saveInLocalStorageCache(key,data);
    }

    getGridState<T>(key: string) : ColumnState[] {
      return this.getInLocalStorageCache(key);
    }
}
