<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.404.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab>
          <bmw-content-tab-label for="a" [selected]="true">{{ 'menu.404.current.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="a">
            <div class="bmw__margin-top__s">
              <h2>
                404 - Page not found
              </h2>
<!--              <p *ngIf="path">You might want to go to the <a [routerLink]="path">"{{ path }}" page</a></p>-->
            </div>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
