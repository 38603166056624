import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyFormatter'
})
export class MoneyFormatterPipe implements PipeTransform {

  /* .toFixed has issues on rounding - dont use it in future
  This method does not yield accurate results in some cases, and there are better methods than this.
  If a number rounds of 1.2, then it will show 1.20. If a number like 2.005 is given, it will return 2.000 instead of 2.01.
  value.toFixed(2) */
  transform(value: number): string {
    if (!value) value = 0;
    return String(this.round(value));
  }

   round(value: number) {
    let m = Number((Math.abs(value) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(value);
  }

}
