import {Component, EventEmitter, Input, OnInit} from '@angular/core';

import {Price} from "../../model/continous.price.model";

@Component({
  selector: 'app-freeze-price-confirm-header',
  templateUrl: './freeze-price-confirm-header.component.html',
  styleUrls: ['./freeze-price-confirm-header.component.scss']
})
export class FreezePriceConfirmHeaderComponent implements OnInit {

  @Input() price: any;

  constructor() { }

  ngOnInit(): void {
  }

}
