import {Component, HostListener, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {GridApi, GridOptions} from "ag-grid-community";
import {ExchangeRatesReleaseGridColumns} from "./exchange-rates-release-grid.columns";

@Component({
  selector: 'app-exchange-rates-release-grid',
  templateUrl: './exchange-rates-release-grid.component.html',
  styleUrls: ['./exchange-rates-release-grid.component.scss']
})
export class ExchangeRatesReleaseGridComponent implements OnInit {

  @Input() rowData: any;

  gridApi: GridApi;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
    this.gridColumns = new ExchangeRatesReleaseGridColumns(this.translate);
  }

  ngOnInit() {
    // this.gridColumns.setColumns(this.groupBy);
    this.setGridOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'year', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }
  }
  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'multiple',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 20
    };
  }

  getRowData() {
    let rowData = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(function (node) {
        rowData.push(node.data);
      });
    }
    return rowData
  }

  onRefreshGridData(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
}
