import { Component, HostListener,  OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ManufacturingReportsService } from '../../service/manufacturing-reports.service';
import { Type, RoyaltiesIndicator } from '../../model/reports.model';
import { GridApi, GridOptions, ICellRendererParams} from 'ag-grid-community';
import { EMPTY_STRING, isUndefinedOrEmpty } from '../../../../../app/shared/functions/typescript.utils';
import { transferPrice } from '../../service/transfer-price-constants';
import moment from 'moment';
import { GenericLine } from './transition-of-adjustment-reason-exchangehelper'; // todo: this must probably go
import { log } from 'console';

@Component({
  selector: 'app-transition-of-adjustment-reason-dynamic',
  templateUrl: './transition-of-adjustment-reason-dynamic.component.html',
  styleUrls: ['./transition-of-adjustment-reason-dynamic.component.scss']
})
export class TransitionOfAdjustmentReasonDynamicComponent implements OnInit {
  @Input() derivativeList: any[] = [];
  @Input() exchangeRateMap: any;
  bodyAndRoofColorList: any[];
  designTrimsList: any[];
  packetList: any[];
  executionList: any[];
  singleSaList: any[];
  selectedExchange: string;
  exchangeRateCurrent: number;
  exchangeRatePrevious: number;
  filterForm: FormGroup;
  showReport = false;
  showLoading = false;
  selectedDerivatives: any [];
  selectedTypes: any[];
  selectedTypeShadow: any[];
  typeList: any[];
  effectiveFrom: Date;
  effectiveTo: Date;
  datesSelected: {};
  rowData = [];
  rowDataPreProduction = [];
  rowDataPostProduction = [];
  dialogTitle: string;
  dialogText: string;
  errorMessage: string;
  showPromt = false;
  validity = {
    inputModel: [
      this.effectiveFrom,
      this.effectiveTo
    ]
  };
  transferPriceCumulative: number;
  exchangeRateCumulative: number;
  derivativeTypeComboCumulativeValues = {};
  derivativeTypeComboCumulativeCalculatedValues = {};
  genericLine: any;
  operationIdReasonMap = new Set();

  runtimeCompilerData = {
    columns: [
    ],
    gridOptions: {
      getRowStyle: params => {
        return { order: params.node.rowIndex };
      },
      postSort: params => {
        // it needs to wait until new order is set
        setTimeout(() => {
          params.forEach(param => {
            param.updateData(param.data);
          });
        });
      },
      ensureDomOrder: true,
      suppressMovableColumns: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowHeight: 32,
      headerHeight: 32,
      enableRangeSelection: true,
      enableRangeHandle: true,
      rowGroupPanelShow: 'onlyWhenGrouping'
    } as GridOptions,
    defaultColDef: {
      sortable: true,
      unSortIcon: true,
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: 'textFilter',
      defaultColToEdit: '',
      autoHeight: true,
      resizable: true
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: undefined
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left'
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    }
  };

  private gridApi: GridApi;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  constructor(private manufacturingReportsService: ManufacturingReportsService, private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
   }

  ngOnInit(): void {
    this.initialiseFormComponents();
    this.initialisePageValues();
  }

  initialisePageValues() {
    this.dialogTitle = undefined;
    this.dialogText = undefined;
    this.errorMessage = undefined;
    this.selectedExchange = 'CNY';
    this.initialiseTypes();
  }

  initialiseFormComponents() {
    this.filterForm = new FormGroup({
      selectedDerivatives: new FormControl(),
      derivativeCheckbox: new FormControl(),
      selectedTypes: new FormControl(),
      typeCheckbox: new FormControl(),
      selectedExchange: new FormControl()
    });
  }

  initialiseTypes() {
    this.selectedDerivatives = [];
    this.selectedTypes = [];
    this.selectedTypeShadow = [];
    this.typeList = [];
  }

  synchroniseSelectedWithCheckBox(mainDataList, selectedList, checkboxName) {
    if (mainDataList.length > 0 && selectedList.length === mainDataList.length) {
      this.filterForm.get(checkboxName).setValue(true);
    } else {
      this.filterForm.get(checkboxName).setValue(false);
    }
  }

  selectedDerivativesChanged(event) {
    this.synchroniseSelectedWithCheckBox(this.typeList, this.selectedTypes, 'typeCheckbox');
    this.loadTypesForDerivatives(event);
  }

  loadTypesForDerivatives(selectedDerivativeCodes) {
    if (selectedDerivativeCodes.length > 0) {
      this.showLoading = true;
      this.manufacturingReportsService.typesForDerivative(selectedDerivativeCodes)
        .subscribe(({data, loading}) => {
          this.typeList = data.productConfigurationTypesByDerivatives;
          this.sortTypesForDerivatives(this.typeList);
          this.selectedTypes = [];
          this.showLoading = false;
        }, (error) => {
          this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
        });
    } else {
      this.typeList = [];
      this.selectedTypes = [];
      this.selectedTypeShadow = [];
    }
  }

  sortTypesForDerivatives(typeList: Type[]) {
    typeList.sort(function(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  derivativeCheckboxChanged($event: Event) {
    this.selectedDerivatives = [];
    if (this.filterForm.get('derivativeCheckbox').value) {
      this.derivativeList.forEach((product) => {
        this.selectedDerivatives.push(product.derivative.code);
      });
    }
    this.loadTypesForDerivatives(this.selectedDerivatives);
  }

  typeCheckboxChanged($event: Event) {
    this.selectedTypes = [];
    if (this.filterForm.get('typeCheckbox').value) {
      this.typeList.forEach((type) => {
        this.selectedTypes.push(type.code);
      });
    }
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
  }

  generateReport() {
    this.buildReportColumns();
  }

  buildReportColumns() {
    this.showReport = false;
    this.rowData = undefined;
    this.showLoading = true;
    let bodyAndRoofColorDataList = [];
    let designTrimsDataList = [];
    let packetDataList = [];
    let executionDataList = [];
    let singleSaDataList = [];
    this.manufacturingReportsService.getDynamicColumns()
    .subscribe((result) => {
      let dynamicColumns = result.data.retrieveProductColumns;

      let initialStructure:any[] = [
        {
          field: 'derivativeCode',
          headerName: 'derivativeCode',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 120,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'type.code',
          headerName: 'typeCode',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'type.name',
          headerName: 'typeName',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'workflow',
          headerName: 'repWorkflow',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'includeRoyalties',
          headerName: 'includeRoyaltiesApplicable',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'releaseDate',
          headerName: 'releaseDate',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 160,
          valueFormatter: this.dateTimeFormatter,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'reason',
          headerName: 'reason',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'assignment',
          headerName: 'assignment',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
        {
          field: 'validFrom',
          headerName: 'validFrom',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 160,
          valueFormatter: this.dateTimeFormatter,
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        },
         {
          field: 'transferPrice',
          headerName: 'transferPrice',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'typeTotal',
          headerName: 'typeTotal',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'gwmParts',
          headerName: 'gwmParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'bmwParts',
          headerName: 'bmwParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'salParts',
          headerName: 'salParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'thirdPartyParts',
          headerName: 'thirdPartyParts',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'directLabour',
          headerName: 'directLabour',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'inboundLogistics',
          headerName: 'inboundLogistics',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'warranty',
          headerName: 'warranty',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'importDuties',
          headerName: 'importDuties',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'customsClearanceFee',
          headerName: 'customsClearanceFee',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'fuelAndEnergy',
          headerName: 'fuelAndEnergy',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'variableManufacturingExpenses',
          headerName: 'variableManufacturingExpenses',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'outboundLogistics',
          headerName: 'outboundLogistics',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'nondeductableIndirectTaxesLinked',
          headerName: 'nondeductableIndirectTaxesLinked',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'nondeductableIndirectTaxes',
          headerName: 'nondeductableIndirectTaxes',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'depreciation',
          headerName: 'depreciation',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'indirectLabour',
          headerName: 'indirectLabour',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'otherFixedExpenses',
          headerName: 'otherFixedExpenses',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'royaltiesTechnology',
          headerName: 'royaltiesTechnology',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'profit5Percent',
          headerName: 'profit5Percent',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'yearlyProductionCostReduction',
          headerName: 'yearlyProductionCostReduction',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
         {
          field: 'optionsTotal',
          headerName: 'optionsTotal',
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 150,
          valueFormatter: this.moneyFormatter,
          type: 'rightAligned',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
         },
      ];

      let bodyAndRoofColoursChildren = [];
      let designTrimsChildren = [];
      let packetsChildren = [];
      let executionsChildren = [];
      let singleSasChildren = [];

      // Create parents
      // #region Body and roof colour
      let productBodyAndRoofColours = {
        field: 'bodyAndRoofColour',
        headerName: 'bodyAndRoofColour',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      let totalClosedBodyAndRoofColour = {
        field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
        headerName: 'bodyAndRoofColourTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      bodyAndRoofColoursChildren.push(totalClosedBodyAndRoofColour);

      let totalOpenBodyAndRoofColour = {
        field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
        headerName: 'bodyAndRoofColourTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      bodyAndRoofColoursChildren.push(totalOpenBodyAndRoofColour);
      // #endregion

      // #region Design Trims
      let productDesignTrims = {
        field: 'designTrims',
        headerName: 'designTrims',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      let totalClosedDesignTrims = {
        field: 'productDesignTrims.designTrimsTotal',
        headerName: 'designTrimsTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      designTrimsChildren.push(totalClosedDesignTrims);

      let totalOpenDesignTrims = {
        field: 'productDesignTrims.designTrimsTotal',
        headerName: 'designTrimsTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      designTrimsChildren.push(totalOpenDesignTrims);
      // #endregion

      // #region Packet
      let productPackets = {
        field: 'packet',
        headerName: 'packet',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      let totalClosedPackets = {
        field: 'productPacket.packetTotal',
        headerName: 'packetTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      packetsChildren.push(totalClosedPackets);

      let totalOpenPackets = {
        field: 'productPacket.packetTotal',
        headerName: 'packetTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      packetsChildren.push(totalOpenPackets);
      // #endregion

      // #region Execution
      let productExecutions = {
        field: 'execution',
        headerName: 'execution',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      let totalClosedExecutions = {
        field: 'productExecution.executionTotal',
        headerName: 'executionTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      executionsChildren.push(totalClosedExecutions);

      let totalOpenExecutions = {
        field: 'productExecution.executionTotal',
        headerName: 'executionTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      executionsChildren.push(totalOpenExecutions);
      // #endregion

      // #region Single SA
      let productSingleSas = {
        field: 'singleSA',
        headerName: 'singleSA',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };

      let totalClosedSingleSas = {
        field: 'productSingleSA.singleSATotal',
        headerName: 'singleSATotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'closed',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      singleSasChildren.push(totalClosedSingleSas);

      let totalOpenSingleSas = {
        field: 'productSingleSA.singleSATotal',
        headerName: 'singleSATotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        columnGroupShow: 'open',
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      singleSasChildren.push(totalOpenSingleSas);
      // #endregion

      // Create Children
      dynamicColumns.forEach(item => {
        if (item.grouping === 'BODY_AND_ROOF_COLOUR') {
          let keyStr = item.code + ' - ' + item.name;
          let genericChildObject = {
            field: 'productBodyAndRoofColours.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          bodyAndRoofColoursChildren.push(genericChildObject);

          let dataObject = {
            code: item.code,
            name: item.name
          };
          bodyAndRoofColorDataList.push(dataObject);


        } else if (item.grouping === 'DESIGN_TRIMS') {
          let keyStr = item.code + ' - ' + item.name;
          let genericChildObject = {
            field: 'productDesignTrims.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          designTrimsChildren.push(genericChildObject);

          let dataObject = {
            code: item.code,
            name: item.name
          };
          designTrimsDataList.push(dataObject);

        } else if (item.grouping === 'PACKET') {
          let keyStr = item.code + ' - ' + item.name;
          let genericChildObject = {
            field: 'productPacket.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          packetsChildren.push(genericChildObject);

          let dataObject = {
            code: item.code,
            name: item.name
          };
          packetDataList.push(dataObject);

        } else if (item.grouping === 'EXECUTION') {
          let keyStr = item.code + ' - ' + item.name;
          let genericChildObject = {
            field: 'productExecution.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          executionsChildren.push(genericChildObject);

          let dataObject = {
            code: item.code,
            name: item.name
          };
          executionDataList.push(dataObject);

        } else if (item.grouping === 'SINGLE_SA') {
          let keyStr = item.code + ' - ' + item.name;
          let genericChildObject = {
            field: 'productSingleSA.' + item.code,
            headerName: keyStr,
            sortable: true,
            filter: true,
            resizable: true,
            minWidth: 150,
            columnGroupShow: 'open',
            valueFormatter: this.moneyFormatter,
            type: 'rightAligned',
            headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
          };
          singleSasChildren.push(genericChildObject);

          let dataObject = {
            code: item.code,
            name: item.name
          };
          singleSaDataList.push(dataObject);
        }
      });

      // Add children to parents
      Object.assign(productBodyAndRoofColours, {children:bodyAndRoofColoursChildren});
      Object.assign(productDesignTrims, {children:designTrimsChildren});
      Object.assign(productPackets, {children:packetsChildren});
      Object.assign(productExecutions, {children:executionsChildren});
      Object.assign(productSingleSas, {children:singleSasChildren});

      initialStructure.push(productBodyAndRoofColours);
      initialStructure.push(productDesignTrims);
      initialStructure.push(productPackets);
      initialStructure.push(productExecutions);
      initialStructure.push(productSingleSas);

      // Royalties for Options
      let royaltiesForSA = {
        field: 'royaltiesForSA',
        headerName: 'royaltiesForSA',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(royaltiesForSA);

      // Profit for Options
      let profitForSA = {
        field: 'profitForSA',
        headerName: 'profitForSA',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(profitForSA);

      // Rules
      let rules = {
        field: 'rules',
        headerName: 'salReportRules',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth: 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      };
      initialStructure.push(rules);
      this.runtimeCompilerData.columns = Array.from(initialStructure);

      this.bodyAndRoofColorList = bodyAndRoofColorDataList;
      this.designTrimsList = designTrimsDataList;
      this.packetList = packetDataList;
      this.executionList = executionDataList;
      this.singleSaList = singleSaDataList;

      // Get report data
      this.refreshData();
    });

  }

  refreshData() {
    this.showReport = false;
    this.showLoading = true;

    console.log('Selected Exchange : ', this.selectedExchange);
    console.log('exchangeRateMap : ', this.exchangeRateMap);

    this.selectedTypeShadow = [...this.selectedTypes];

    this.manufacturingReportsService.transferPriceTransitionPerModelTypeByPlant()
    .subscribe((result) => {
      this.showLoading = false;
      this.rowData = [];
      this.rowDataPreProduction = [];
      this.rowDataPostProduction = [];

      let totalDataSetSortedByTime = result.data.transferPriceTransitionPerModelTypeByPlant
      .sort((a, b) => a.type.code.localeCompare(b.type.code)
      || moment(a.validFrom).toDate().getTime() - moment(b.validFrom).toDate().getTime()
      || moment(a.releaseDate).toDate().getTime() - moment(b.releaseDate).toDate().getTime());

      let uniqueTypeCodeList = [];
      totalDataSetSortedByTime.forEach(row => {
        if (!uniqueTypeCodeList.includes(row.type.code)) {
          uniqueTypeCodeList.push(row.type.code);
        }
      });

      // #region TRANSFORM 1 - get lines to output
      uniqueTypeCodeList.forEach((item,index) => {
        let typeGroupList = totalDataSetSortedByTime.filter(typeCode => {return typeCode.type.code === item});
        let typeGroup = [];
        let operationId = 0;
        let adjustmentReason = '';
        let operationMap = new Map();
        let firstOperationAdjustmentGoneThrough = false;

        if (typeGroupList.length === 1) {
          // Only Starting Value
          let StartingValueRow = this.createFirstRow(typeGroupList[0]);
          let StartingValueRowProfit = this.createFirstRowProfit(typeGroupList[0]);
          let StartingValueRowRoyalties = this.createFirstRowRoyalties(typeGroupList[0]);
          this.rowDataPreProduction.push(StartingValueRow);
          this.rowDataPreProduction.push(StartingValueRowProfit);
          this.rowDataPreProduction.push(StartingValueRowRoyalties);
        } else {
          // Starting Value plus the rest of the lines
          typeGroupList.forEach((row,index) => {      // iterate through objects in JSON, each object has a unique operationsID
            operationId = row.operationId;
            if (index === 0 && row.previousManufacturingCosts.length === 0) {
              // First row
              let StartingValueRow = this.createFirstRow(row);
              let StartingValueRowProfit = this.createFirstRowProfit(row);
              let StartingValueRowRoyalties = this.createFirstRowRoyalties(row);
              typeGroup.push(StartingValueRow);
              typeGroup.push(StartingValueRowProfit);
              typeGroup.push(StartingValueRowRoyalties);
            } else {

              let sortedtransferCategoryCost = row.transferPrice.transferCategoryCost
                .sort((a, b) => a.adjustmentReason.name.localeCompare(b.adjustmentReason.name));
              row.type.specialEquipment?.forEach(specialEquipment => {
                specialEquipment.manufacturingCostHistory.transferPrice.transferCategoryCost?.forEach(transferCategoryCost => {
                    if (transferCategoryCost.adjustmentReason.name === 'Profit' || transferCategoryCost.adjustmentReason.name === 'Royalties') {
                      sortedtransferCategoryCost.push(transferCategoryCost);
                    }
                  }
                );
              });
              row.type.packets?.forEach(packet => {
                packet.manufacturingCostHistory.transferPrice.transferCategoryCost?.forEach(transferCategoryCost => {
                    if (transferCategoryCost.adjustmentReason.name === 'Profit' || transferCategoryCost.adjustmentReason.name === 'Royalties') {
                      sortedtransferCategoryCost.push(transferCategoryCost);
                    }
                  }
                );
              });
              sortedtransferCategoryCost.sort((a, b) => a.adjustmentReason.name.localeCompare(b.adjustmentReason.name));

              // Get SA adjustments that do not have a linked type adjustment
              let sortedtransferCategoryCostOptions = [];
              if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
                row.type.specialEquipment.forEach(specialEquipmentRow => {
                  if (specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost
                      && specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.length > 0) {
                    specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.forEach(transferCategoryCostRow => {
                      Object.entries(transferPrice.adjustmentReason).forEach(([key, value]) => {
                        if (transferCategoryCostRow.adjustmentReason.name === value) {
                          sortedtransferCategoryCostOptions.push(transferCategoryCostRow);
                        }
                      });
                    });
                  }
                });
              }
              sortedtransferCategoryCostOptions.sort((a, b) => a.adjustmentReason.name.localeCompare(b.adjustmentReason.name));

              // Get packets adjustments that do not have a linked type adjustment
              let sortedtransferCategoryCostPackets = [];
              if (row.type.packets && row.type.packets.length > 0) {
                row.type.packets.forEach(packetsRow => {
                  if (packetsRow.manufacturingCostHistory.transferPrice.transferCategoryCost
                      && packetsRow.manufacturingCostHistory.transferPrice.transferCategoryCost.length > 0) {
                        packetsRow.manufacturingCostHistory.transferPrice.transferCategoryCost.forEach(transferCategoryCostRow => {
                      Object.entries(transferPrice.adjustmentReason).forEach(([key, value]) => {
                          if (transferCategoryCostRow.adjustmentReason.name === value) {
                            sortedtransferCategoryCostPackets.push(transferCategoryCostRow);
                          }
                        });
                    });
                  }
                });
              }

              sortedtransferCategoryCostPackets.sort((a, b) => a.adjustmentReason.name.localeCompare(b.adjustmentReason.name));

              let uniqueOptions = [];
              sortedtransferCategoryCostOptions.forEach(optionsRow => {
                if (!sortedtransferCategoryCost.some(e => e.adjustmentReason.name === optionsRow.adjustmentReason.name)) {
                  optionsRow['typeLink'] = 'none';
                  uniqueOptions.push(optionsRow);
                }
              });

              let uniquePackets = [];
              sortedtransferCategoryCostPackets.forEach(packetRow => {
                if (!sortedtransferCategoryCost.some(e => e.adjustmentReason.name === packetRow.adjustmentReason.name)) {
                  packetRow['typeLink'] = 'none';
                  uniquePackets.push(packetRow);
                }
              });

              // add uniqueOptions to sortedtransferCategoryCost
              if (uniqueOptions.length > 0) {
                uniqueOptions.forEach(x => {
                  sortedtransferCategoryCost.push(x);
                });
              }

              // add uniquePackets to sortedtransferCategoryCost
              if (uniquePackets.length > 0) {
                uniquePackets.forEach(x => {
                  sortedtransferCategoryCost.push(x);
                });
              }

              sortedtransferCategoryCost.sort((a, b) => a.adjustmentReason.name.localeCompare(b.adjustmentReason.name));

              if (sortedtransferCategoryCost.length === 0) {
                let transferCategoryCost = this.createOperationRowss(row, firstOperationAdjustmentGoneThrough);
                typeGroup.push(transferCategoryCost);
              } else {
                sortedtransferCategoryCost.map((innerRow, innerIndex) => {
                  adjustmentReason = innerRow.adjustmentReason.name;
                  if (operationMap.get(operationId) === undefined) {
                    operationMap.clear();
                    firstOperationAdjustmentGoneThrough = false;
                    operationMap.set(operationId, adjustmentReason);
                  } else if (operationMap.get(operationId) === adjustmentReason) {
                    firstOperationAdjustmentGoneThrough = true;
                  } else if (operationMap.get(operationId) !== adjustmentReason) {
                    operationMap.clear();
                    firstOperationAdjustmentGoneThrough = false;
                    operationMap.set(operationId, adjustmentReason);
                  }
  
                  // Cater for the values that comes from uniquePackets and uniqueOptions
                  if (!firstOperationAdjustmentGoneThrough && innerRow.typeLink !== null && innerRow.typeLink === 'none') {
                    firstOperationAdjustmentGoneThrough = true;
                  }
  
                  let transferCategoryCost = this.createOperationRows(row,innerRow, index, innerIndex,firstOperationAdjustmentGoneThrough);
                  typeGroup.push(transferCategoryCost);
                });
              }
            }
          });

          if (typeGroup.length > 0) {
            let totalSARoyalties = 0;
            let totalSAProfit = 0;
            let totalRules = 0;

            typeGroupList.forEach(row => {
              if (row.previousManufacturingCosts.length > 0) {
                totalSARoyalties = totalSARoyalties + this.RoyaltiesForSAPerType(row);
                totalSAProfit = totalSAProfit + this.ProfitForSAPerType(row);
              }
              if (row.type.rulesHistory !== null && row.type.rulesHistory.length > 0) {
                totalRules = totalRules + row.type.rulesHistory?.reduce((sum, ruleHistory) => sum
                  + ruleHistory.amount.value, 0);
              }
            });

            // let transferCategoryCostSAProfitRow = this.createOptionsProfitAndRoyaltiesRow(typeGroup,totalSARoyalties,totalSAProfit);
            // typeGroup.push(transferCategoryCostSAProfitRow);

            let rulesRow = this.createRulesRow(typeGroup, totalRules);
            typeGroup.push(rulesRow);

            // TODO create last line before active line
            let transferCategoryCostLastRow = this.createActiveLine(typeGroup);

            typeGroup.push(transferCategoryCostLastRow);
            typeGroup.forEach(row => this.rowDataPreProduction.push(row));
          }
        }
      });

      // #endregion

      // #region TRANSFORM 2 - truncate lines with the same typeCode, OperationId and Adjustment Reason
      let currentOperationId = 0;
      let currentType = '';
      let OperationGroup = [];
      this.rowDataPreProduction.map((row, index) => {
        if (row.workflow === 'Starting Value') {
          if (row.reason === 'Royalties' || row.reason === 'Profit') {
            row.productSingleSA.singleSATotal = 0;
            row.productBodyAndRoofColours.bodyAndRoofColourTotal = 0;
            row.productPacket.packetTotal = 0;
            row.productDesignTrims.designTrimsTotal = 0;
            row.productExecution.executionTotal = 0;
            let rowProductSingleSA = {...row.productSingleSA};
            Object.entries(rowProductSingleSA)
              .forEach(([key, value]) => {
                row.productSingleSA[key] = 0;
              });
            let rowProductBodyAndRoofColours = {...row.productBodyAndRoofColours};
            Object.entries(rowProductBodyAndRoofColours)
              .forEach(([key, value]) => {
                row.productBodyAndRoofColours[key] = 0;
              });
            let rowProductPacket = {...row.productPacket};
            Object.entries(rowProductPacket)
              .forEach(([key, value]) => {
                row.productPacket[key] = 0;
              });
            let rowProductDesignTrims = {...row.productDesignTrims};
            Object.entries(rowProductDesignTrims)
              .forEach(([key, value]) => {
                row.productDesignTrims[key] = 0;
              });
            let rowProductExecution = {...row.productExecution};
            Object.entries(rowProductExecution)
              .forEach(([key, value]) => {
                row.productExecution[key] = 0;
              });
          }
          this.rowDataPostProduction.push(row);
          currentOperationId = 0;
          OperationGroup = [];
        } else {
          if (currentOperationId === 0) {
            // First Row after starting value
            currentOperationId = row.workflow;
            currentType = row.type.code;
            OperationGroup.push(row);

          } else if (row.workflow !== 'Active' && currentOperationId === row.workflow && currentType === row.type.code){
            //2..n rows with same OperationId
            OperationGroup.push(row);
          } else if (row.reason === 'Option Profit and Royalties') {  // You have come to the end of the current OperationGroup, first truncate operation rows with the same reason, push them, then push the active row
            let uniqueReasonList = [];
            OperationGroup.forEach(row => {
              if (!uniqueReasonList.includes(row.reason) && row.reason !== 'Option Profit and Royalties') { // does last part ever happen?
                uniqueReasonList.push(row.reason)
              }
            });

            uniqueReasonList.forEach(item => {
              let rowsWithSameReasonList = OperationGroup.filter( row => {
                return row.reason === item;
              });
              let rowsWithSameReason = this.transformOperationRows(rowsWithSameReasonList);
              this.excludeProfitRoyaltiesOptions(rowsWithSameReason);
              this.rowDataPostProduction.push(rowsWithSameReason);
            });

            this.excludeProfitRoyaltiesOptions(row);
            this.rowDataPostProduction.push(row); // push Option Profit and Royalties line

          } else if (row.reason === 'Rules') {
            let uniqueReasonList = [];
            OperationGroup.forEach(operationRow => {
              if (!uniqueReasonList.includes(operationRow.reason)) {
                uniqueReasonList.push(operationRow.reason);
              }
            });

            uniqueReasonList.forEach(distinctReason => {
              let rowsWithSameReasonList = OperationGroup.filter(operationRow => {
                return operationRow.reason === distinctReason;
              });
              let rowsWithSameReason = this.transformOperationRows(rowsWithSameReasonList);
              this.excludeProfitRoyaltiesOptions(rowsWithSameReason);
              this.rowDataPostProduction.push(rowsWithSameReason);
            });
            this.excludeProfitRoyaltiesOptions(row);
            this.rowDataPostProduction.push(row);
          } else if (row.workflow === 'Active') { // push active line
            this.rowDataPostProduction.push(row);
          } else if (row.workflow !== 'Active' && row.workflow !== 'Starting Value' && row.reason !== 'Option Profit and Royalties') {
            //new OperationID - for the same typecode
            //First truncate and save the previous group
            let uniqueReasonList = [];
            OperationGroup.forEach(row => {
              if (!uniqueReasonList.includes(row.reason)) {
                uniqueReasonList.push(row.reason)
              }
            });

            uniqueReasonList.forEach(item => {
              let rowsWithSameReasonList = OperationGroup.filter(row => {
                return row.reason === item;
              });
              let rowsWithSameReason = this.transformOperationRows(rowsWithSameReasonList);
              this.excludeProfitRoyaltiesOptions(rowsWithSameReason);
              this.rowDataPostProduction.push(rowsWithSameReason);
            });

            currentOperationId = row.workflow;
            currentType = row.type.code;
            OperationGroup = [];
            // Carry on with current row
            OperationGroup.push(row);
          }
        }
      });
      // #endregion

      // #region TRANSFORM 3 - update the active rows
      let DerivativeTypeCombo = [];
      let derivativeType = '';
      this.rowDataPostProduction.map((row, index) => {
        if (row.workflow === 'Starting Value' && derivativeType === '') {
          derivativeType = row.type.code;
          DerivativeTypeCombo.push(row);
          this.rowData.push(row);
        } else if (row.workflow === 'Starting Value' && (row.reason === 'Profit' || row.reason === 'Royalties') && row.totalTransferPrice !== 0) {
          derivativeType = row.type.code;
          DerivativeTypeCombo.push(row);
          this.rowData.push(row);
        } else if (row.workflow === 'Starting Value' && row.type.code !== derivativeType) {
          DerivativeTypeCombo = [];
          derivativeType = row.type.code;
          DerivativeTypeCombo.push(row);
          this.rowData.push(row);
        } else if (row.workflow !== 'Starting Value' && row.workflow !== 'Active' && row.type.code === derivativeType) {
          DerivativeTypeCombo.push(row);
          this.rowData.push(row);
        } else if (row.workflow === 'Active') {
          row = this.transformActiveRow(row, DerivativeTypeCombo);
          this.rowData.push(row);
          DerivativeTypeCombo = [];
        }
      });

      // #endregion

      // #region TOP filter
      let totalDataSetSortedByTimeandFiltered = this.topFilter(this.rowData);
      if (totalDataSetSortedByTimeandFiltered.length > 0) {
        this.rowData = totalDataSetSortedByTimeandFiltered;
      }

      // #endregion

      // #region Calculated Totals
      const preCalculatedColumns = this.rowData;
      this.rowData = [];
      let typeTotal = 0;
      let optionsTotal = 0;
      this.operationIdReasonMap = new Set();

      preCalculatedColumns.forEach(row => {
        typeTotal = this.calculateTypeTotal(row);
        optionsTotal = this.calculateOptionsTotal(row);
        row.typeTotal = typeTotal;
        row.optionsTotal = optionsTotal;
        if (row.workflow === 'Active') {
          row.singleSATotal = 0;
          row.bodyAndRoofColourTotal = 0;
          row.packetTotal = 0;
          row.designTrimsTotal = 0;
          row.executionTotal = 0;
          row.productBodyAndRoofColours.bodyAndRoofColourTotal = 0;
          row.productPacket.packetTotal = 0;
          row.productDesignTrims.designTrimsTotal = 0;
          row.productExecution.executionTotal = 0;
          row.optionsTotal = 0;
          row.royaltiesForSA = 0;
          row.profitForSA = 0;
          row.rules = 0;
          this.clearColumns(row);
          this.rowData.forEach(innerRow => {
            if (innerRow.type.code === row.type.code) {
              if (innerRow.workflow !== 'Active') {
                if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Royalties') {
                  row.productSingleSA.singleSATotal += innerRow.productSingleSA.singleSATotal;
                  row.productBodyAndRoofColours.bodyAndRoofColourTotal += innerRow.productBodyAndRoofColours.bodyAndRoofColourTotal;
                  row.productPacket.packetTotal += innerRow.productPacket.packetTotal;
                  row.productDesignTrims.designTrimsTotal += innerRow.productDesignTrims.designTrimsTotal;
                  row.productExecution.executionTotal += innerRow.productExecution.executionTotal;
                }
                row.singleSATotal += innerRow.productSingleSA.singleSATotal;
                row.bodyAndRoofColourTotal += innerRow.productBodyAndRoofColours.bodyAndRoofColourTotal;
                row.packetTotal += innerRow.productPacket.packetTotal;
                row.designTrimsTotal += innerRow.productDesignTrims.designTrimsTotal;
                row.executionTotal += innerRow.productExecution.executionTotal;
                row.optionsTotal += innerRow.optionsTotal;
                row.royaltiesForSA += innerRow.royaltiesForSA;
                row.profitForSA += innerRow.profitForSA;
                row.rules += innerRow.rules;

                let rowProductSingleSA = {...row.productSingleSA};
                Object.entries(rowProductSingleSA)
                  .forEach(([key, value]) => {
                    if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Active' && innerRow.reason !== 'Royalties'
                      && innerRow.type.code === row.type.code) {
                      row.productSingleSA[key] += innerRow.productSingleSA[key];
                    }
                  });
                let rowProductBodyAndRoofColours = {...row.productBodyAndRoofColours};
                Object.entries(rowProductBodyAndRoofColours)
                  .forEach(([key, value]) => {
                    if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Active' && innerRow.reason !== 'Royalties'
                      && innerRow.type.code === row.type.code) {
                      row.productBodyAndRoofColours[key] += innerRow.productBodyAndRoofColours[key];
                    }
                  });
                let rowProductPacket = {...row.productPacket};
                Object.entries(rowProductPacket)
                  .forEach(([key, value]) => {
                    if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Active' && innerRow.reason !== 'Royalties'
                      && innerRow.type.code === row.type.code) {
                      row.productPacket[key] += innerRow.productPacket[key];
                    }
                  });
                let rowProductDesignTrims = {...row.productDesignTrims};
                Object.entries(rowProductDesignTrims)
                  .forEach(([key, value]) => {
                    if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Active' && innerRow.reason !== 'Royalties'
                      && innerRow.type.code === row.type.code) {
                      row.productDesignTrims[key] += innerRow.productDesignTrims[key];
                    }
                  });
                let rowProductExecution = {...row.productExecution};
                Object.entries(rowProductExecution)
                  .forEach(([key, value]) => {
                    if (innerRow.reason !== 'Profit' && innerRow.reason !== 'Active' && innerRow.reason !== 'Royalties'
                      && innerRow.type.code === row.type.code) {
                      row.productExecution[key] += innerRow.productExecution[key];
                    }
                  });
              }
            }
          });
          this.rowData.forEach(innerRow => {
            if (innerRow.type.code === row.type.code) {
              if (innerRow.workflow !== 'Active') {
                if (innerRow.reason === 'Royalties' || innerRow.reason === 'Profit') {
                  // clear values for profits and royalties rows SPTLT-911
                  innerRow.productSingleSA.singleSATotal = 0;
                  innerRow.productBodyAndRoofColours.bodyAndRoofColourTotal = 0;
                  innerRow.productPacket.packetTotal = 0;
                  innerRow.productDesignTrims.designTrimsTotal = 0;
                  innerRow.productExecution.executionTotal = 0;
                  let rowProductSingleSA = {...innerRow.productSingleSA};
                  Object.entries(rowProductSingleSA)
                    .forEach(([key, value]) => {
                      innerRow.productSingleSA[key] = 0;
                    });
                  let rowProductBodyAndRoofColours = {...innerRow.productBodyAndRoofColours};
                  Object.entries(rowProductBodyAndRoofColours)
                    .forEach(([key, value]) => {
                      innerRow.productBodyAndRoofColours[key] = 0;
                    });
                  let rowProductPacket = {...innerRow.productPacket};
                  Object.entries(rowProductPacket)
                    .forEach(([key, value]) => {
                      innerRow.productPacket[key] = 0;
                    });
                  let rowProductDesignTrims = {...innerRow.productDesignTrims};
                  Object.entries(rowProductDesignTrims)
                    .forEach(([key, value]) => {
                      innerRow.productDesignTrims[key] = 0;
                    });
                  let rowProductExecution = {...innerRow.productExecution};
                  Object.entries(rowProductExecution)
                    .forEach(([key, value]) => {
                      innerRow.productExecution[key] = 0;
                    });
                }
              }
            }
          });
          row.productSingleSA.singleSATotal = row.singleSATotal;
          row.productBodyAndRoofColours.bodyAndRoofColourTotal = row.bodyAndRoofColourTotal;
          row.productPacket.packetTotal = row.packetTotal;
          row.productDesignTrims.designTrimsTotal = row.designTrimsTotal;
          row.productExecution.executionTotal = row.executionTotal;
          row.transferPrice = row.optionsTotal + row.typeTotal;
          row.totalTransferPrice = row.optionsTotal + row.typeTotal;
        } else if (row.workflow !== 'Starting Value') {
          row.transferPrice = typeTotal + optionsTotal;
        } else if (row.workflow === 'Starting Value' && row.reason === 'Starting Value') {
          row.transferPrice = row.typeTotal + row.optionsTotal;
        }
        if (!((row.reason === 'Royalties' || row.reason === 'Profit') && row.transferPrice.royaltiesIndicator === 'ALWAYS')) {
          this.rowData.push(row);
        }
      });
      // #endregion

      // #region TRANSFORM 4 - ExchangeRate
      if (this.selectedExchange === 'EUR') {
        this.transformExchangeRate();
      }
      // #endregion

      console.log('Final Report : ', this.rowData);
      let workflow: string[] = ['Rules', 'Active'];
      this.rowData = this.rowData
        .sort((a, b) => {
          if (a.type.code !== b.type.code) {
            return a.type.code.localeCompare(b.type.code);
          }
          if (workflow.includes(a.workflow) || workflow.includes(b.workflow)) {
            return 1;
          } else if (a.validFrom !== b.validFrom) {
            return moment(a.validFrom).toDate().getTime() - moment(b.validFrom).toDate().getTime();
          }
          if ((a.workflow !== 'Starting Value' && b.workflow !== 'Starting Value') &&
            a.releaseDate !== b.releaseDate) {
            return moment(b.releaseDate).toDate().getTime() - moment(a.releaseDate).toDate().getTime();
          } else if ((a.workflow !== 'Starting Value' && b.workflow !== 'Starting Value') &&
            a.releaseDate === b.releaseDate && (a.assignment === 'Calculation' || b.assignment === 'Calculation')) {
            return -1;
          }
        });

      this.selectedTypes = [...this.selectedTypeShadow];

      this.showReport = true;
    });
  }

  excludeProfitRoyaltiesOptions(row: any) {
    if (row.reason === 'Royalties' || row.reason === 'Profit') {
      let optionsTotal = this.calculateOptionsTotal(row);
      row.optionsTotal = optionsTotal;
      row.transferPrice = optionsTotal;
      row.productSingleSA.singleSATotal = 0;
      row.productBodyAndRoofColours.bodyAndRoofColourTotal = 0;
      row.productPacket.packetTotal = 0;
      row.productDesignTrims.designTrimsTotal = 0;
      row.productExecution.executionTotal = 0;
      let rowProductSingleSA = {...row.productSingleSA};
      Object.entries(rowProductSingleSA)
        .forEach(([key, value]) => {
          row.productSingleSA[key] = 0;
        });
      let rowProductBodyAndRoofColours = {...row.productBodyAndRoofColours};
      Object.entries(rowProductBodyAndRoofColours)
        .forEach(([key, value]) => {
          row.productBodyAndRoofColours[key] = 0;
        });
      let rowProductPacket = {...row.productPacket};
      Object.entries(rowProductPacket)
        .forEach(([key, value]) => {
          row.productPacket[key] = 0;
        });
      let rowProductDesignTrims = {...row.productDesignTrims};
      Object.entries(rowProductDesignTrims)
        .forEach(([key, value]) => {
          row.productDesignTrims[key] = 0;
        });
      let rowProductExecution = {...row.productExecution};
      Object.entries(rowProductExecution)
        .forEach(([key, value]) => {
          row.productExecution[key] = 0;
        });
    }
  }

  clearColumns(row: any) {
      let rowProductSingleSA = {...row.productSingleSA};
      Object.entries(rowProductSingleSA)
        .forEach(([key, value]) => {
          row.productSingleSA[key] = 0;
        });
      let rowProductBodyAndRoofColours = {...row.productBodyAndRoofColours};
      Object.entries(rowProductBodyAndRoofColours)
        .forEach(([key, value]) => {
          row.productBodyAndRoofColours[key] = 0;
        });
      let rowProductPacket = {...row.productPacket};
      Object.entries(rowProductPacket)
        .forEach(([key, value]) => {
          row.productPacket[key] = 0;
        });
      let rowProductDesignTrims = {...row.productDesignTrims};
      Object.entries(rowProductDesignTrims)
        .forEach(([key, value]) => {
          row.productDesignTrims[key] = 0;
        });
      let rowProductExecution = {...row.productExecution};
      Object.entries(rowProductExecution)
        .forEach(([key, value]) => {
          row.productExecution[key] = 0;
        });
  }

  calculateTypeTotal(row:any) {
    let total = row.gwmParts +
    row.bmwParts +
    row.salParts +
    row.thirdPartyParts +
    row.directLabour +
    row.inboundLogistics +
    row.warranty +
    row.importDuties +
    row.customsClearanceFee +
    row.fuelAndEnergy +
    row.variableManufacturingExpenses +
    row.outboundLogistics +
    row.nondeductableIndirectTaxes +
    row.nondeductableIndirectTaxesLinked +
    row.depreciation +
    row.indirectLabour +
    row.otherFixedExpenses +
    row.royaltiesTechnology +
    row.profit5Percent +
    row.yearlyProductionCostReduction;
    return total;
  }

  calculateOptionsTotal(row: any) {
    let total = row.productBodyAndRoofColours.bodyAndRoofColourTotal +
    row.productDesignTrims.designTrimsTotal +
    row.productPacket.packetTotal +
    row.productExecution.executionTotal +
    row.productSingleSA.singleSATotal;

    if (row.workflow === 'Active'
      || row.workflow === 'Starting Value'
      || row.workflow === 'Option Profit and Royalties'
      || row.workflow === 'Rules'
      || row.reason === 'Royalties'
      || row.reason === 'Profit') {
      total = total + (row.royaltiesForSA + row.profitForSA) + row.rules;
    }
    return total;
  }

  topFilter(totalDataSetSortedByTime: any[]) {
    let totalDataSetSortedByTimeandFiltered = [];
    console.log('Do filter');
    // 0 0 0 1
    if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom === undefined && this.effectiveTo !== undefined) {
      console.log('0 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 0 0 1 0
    else if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo === undefined) {
      console.log('0 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom > this.effectiveFrom;
      });
    }
    // 0 0 1 1
    else if (this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('0 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom > this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 0 0 0
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom === undefined && this.effectiveTo === undefined) {
      console.log('1 0 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      });
    }
    // 1 0 0 1
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom === undefined && this.effectiveTo !== undefined) {
      console.log('1 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 0 1 0
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo === undefined) {
      console.log('1 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom;
      });
    }
    // 1 0 1 1
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('1 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 1 0 0
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom === undefined && this.effectiveTo === undefined) {
      console.log('1 1 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      });
    }
    // 1 1 0 1
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom === undefined && this.effectiveTo !== undefined) {
      console.log('1 1 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 1 1 0
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo === undefined) {
      console.log('1 1 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom;
      });
    }
    // 1 1 1 1
    else if (this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('1 1 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.type.code) ?  true : false;
      }).filter((row) => {
        let dateValidFrom = new Date(row.validFrom);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    return totalDataSetSortedByTimeandFiltered;
  }

  replaceIllegalCharacters(inputStr:String) {
    return inputStr.replace(/\./g, ''); // .
  }

  createFirstRow(row:any) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: RoyaltiesIndicator[row.transferPrice.royaltiesIndicator],
      workflow: 'Starting Value',
      assignment: '',
      releaseDate: row.releaseDate,
      reason: 'Starting Value',
      validFrom: row.validFrom,
      totalTransferPrice: ((row.previousManufacturingCosts && row.previousManufacturingCosts.length > 0) ? +(row.previousManufacturingCosts.reduce((sum, prevManCost) => sum + prevManCost.price.value, 0)).toFixed(2) : 0) + row.price.value + this.SpecialEquipmentTotalPrice(row),
      transferPrice: +(row.transferPrice.transferCategoryCost.reduce((sum, transferCategoryCost) => sum + transferCategoryCost.amount.value, 0)).toFixed(2) + this.SpecialEquipmentTotalPrice(row),
      gwmParts: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.gwmParts),
      bmwParts: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.bmwParts),
      salParts: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.salParts),
      thirdPartyParts: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.thirdPartyParts),
      directLabour: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.directLabour),
      inboundLogistics: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.inboundLogistics),
      warranty: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.warranty),
      importDuties: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.importDuties),
      customsClearanceFee: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.customsClearanceFee),
      fuelAndEnergy: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.fuelAndEnergy),
      variableManufacturingExpenses: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.variableManufacturingExpenses),
      outboundLogistics: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.outboundLogistic),
      nondeductableIndirectTaxesLinked: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.nonDeductibleIndirectTaxesDirectlyLinkedToProject),
      nondeductableIndirectTaxes: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject),
      depreciation: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.depreciation),
      indirectLabour: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.indirectLabour),
      otherFixedExpenses: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.otherFixedExpenses),
      royaltiesTechnology: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.royaltiesForTechnology),
      profit5Percent: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.profit),
      yearlyProductionCostReduction: this.StartValueCategoryCostCurrentCalc(row,transferPrice.category.yearlyProductionCostReduction),
      totalOfOptions: this.SpecialEquipmentTotalPrice(row),
      // royaltiesForSA: +(this.RoyaltiesForSAFirstRow(row)).toFixed(2),
      // profitForSA: this.ProfitForSAFirstRow(row),
      royaltiesForSA: 0,
      profitForSA: 0,
      rules: 0
    };

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, colorobj.code)});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, trimobj.code)});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.PacketsMinusProfitsRoyalties(row, packetobj.code)});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, executionobj.code)});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, saobj.code)});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  createFirstRowRoyalties(row: any) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: RoyaltiesIndicator[row.transferPrice.royaltiesIndicator],
      workflow: 'Starting Value',
      assignment: '',
      releaseDate: row.releaseDate,
      reason: 'Royalties',
      validFrom: row.validFrom,
      totalTransferPrice: (this.RoyaltiesForSAFirstRow(row)).toFixed(2),
      transferPrice: (this.RoyaltiesForSAFirstRow(row)).toFixed(2),
      gwmParts: 0,
      bmwParts: 0,
      salParts: 0,
      thirdPartyParts: 0,
      directLabour: 0,
      inboundLogistics: 0,
      warranty: 0,
      importDuties: 0,
      customsClearanceFee: 0,
      fuelAndEnergy: 0,
      variableManufacturingExpenses: 0,
      outboundLogistics: 0,
      nondeductableIndirectTaxesLinked: 0,
      nondeductableIndirectTaxes: 0,
      depreciation: 0,
      indirectLabour: 0,
      otherFixedExpenses: 0,
      royaltiesTechnology: 0,
      profit5Percent: 0,
      yearlyProductionCostReduction: 0,
      totalOfOptions: 0,
      royaltiesForSA: +(this.RoyaltiesForSAFirstRow(row)).toFixed(2),
      profitForSA: 0,
      rules: 0
    };

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, colorobj.code)});
    });

    let bodyRoofColorTotal = 0;
    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, trimobj.code)});
    });

    let designTrimsTotalCalc = 0;
    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.PacketsMinusProfitsRoyalties(row, packetobj.code)});
    });

    let packetTotalCalc = 0;
    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, executionobj.code)});
    });

    let executionTotalCalc = 0;
    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, saobj.code)});
    });

    let saTotalCalc = 0;
    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  createFirstRowProfit(row: any) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: RoyaltiesIndicator[row.transferPrice.royaltiesIndicator],
      workflow: 'Starting Value',
      assignment: '',
      releaseDate: row.releaseDate,
      reason: 'Profit',
      validFrom: row.validFrom,
      totalTransferPrice: this.ProfitForSAFirstRow(row),
      transferPrice: this.ProfitForSAFirstRow(row),
      gwmParts: 0,
      bmwParts: 0,
      salParts: 0,
      thirdPartyParts: 0,
      directLabour: 0,
      inboundLogistics: 0,
      warranty: 0,
      importDuties: 0,
      customsClearanceFee: 0,
      fuelAndEnergy: 0,
      variableManufacturingExpenses: 0,
      outboundLogistics: 0,
      nondeductableIndirectTaxesLinked: 0,
      nondeductableIndirectTaxes: 0,
      depreciation: 0,
      indirectLabour: 0,
      otherFixedExpenses: 0,
      royaltiesTechnology: 0,
      profit5Percent: 0,
      yearlyProductionCostReduction: 0,
      totalOfOptions: 0,
      royaltiesForSA: 0,
      profitForSA: this.ProfitForSAFirstRow(row),
      rules: 0
    };

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, colorobj.code)});
    });

    let bodyRoofColorTotal = 0;
    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, trimobj.code)});
    });

    let designTrimsTotalCalc = 0;
    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.PacketsMinusProfitsRoyalties(row, packetobj.code)});
    });

    let packetTotalCalc = 0;
    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, executionobj.code)});
    });

    let executionTotalCalc = 0;
    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, saobj.code)});
    });

    let saTotalCalc = 0;
    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  transformExchangeRate() {
    const rowDataExchangeRates = [...this.rowData];
    this.rowData = [];

    // Build DerivativeType Combo
    let DerivativeTypeCombo = [];
    let derivativeType = '';
    rowDataExchangeRates.forEach((row,index) => {
      if (row.workflow === 'Starting Value' && derivativeType === '') {  // first Starting Value in report
        // first Starting Value
        derivativeType = row.type.code;
        DerivativeTypeCombo.push(row);
        // Populate props of the calculation structures
        this.instantiateCalculationStructures(row);
      } else if (row.workflow === 'Starting Value' && row.type.code !== derivativeType) {  // beginning of a new DerivativeTypeCombo
        // Clear DerivativeTypeCombo (previous Starting Value) into this.rowData - Note:DerivativesCombo should have only one item
        if (DerivativeTypeCombo.length === 1) { // The previous Staring Value is in DerivativeTypeCombo
          console.log('DerivativeTypeCombo[0] : ', DerivativeTypeCombo[0]);
          this.resetDerivativeTypeComboCumulativeValues();  // reset both the calculated structures
          this.adjustDerivativeTypeComboCumulativeValues(DerivativeTypeCombo[0]); // add the (original) row values before the transform
          this.exchangeRateCurrent = this.setExchangeRateCurrent(DerivativeTypeCombo[0]);
          let startingValueRow = this.transformRowWithExchangeCalculation(DerivativeTypeCombo[0]); // this.exchangeRateCurrent is set here
          this.adjustDerivativeTypeComboCumulativeCalculatedValues(startingValueRow);
          console.log('startingValueRow : ', startingValueRow);
          this.rowData.push(startingValueRow); // before push to this.rowData - all calculations for the row must be done
        }
        // start a new DerivativeTypeCombo
        DerivativeTypeCombo = [];
        derivativeType = row.type.code;
        DerivativeTypeCombo.push(row);

      } else if (row.workflow !== 'Starting Value' && row.workflow !== 'Active' && row.type.code === derivativeType) {
        // the rows between the Starting Value and Active rows

        DerivativeTypeCombo.push(row);
      } else if (row.workflow === 'Active') {  // the end of the DerivativeTypeCombo - consolidate DerivativeTypeCombo
        // Add to DerivativeTypeCombo
        DerivativeTypeCombo.push(row);

        // Iterate through DerivativeTypeCombo, do calculations and push calculates rows to this.rowData
        this.resetDerivativeTypeComboCumulativeValues();
        console.log('START WITH CALCULATIONS');
        DerivativeTypeCombo.forEach((derivativeTypeRow,index) => {
          this.exchangeRateCurrent = this.setExchangeRateCurrent(derivativeTypeRow);
          console.log('this.exchangeRateCurrent : ', this.exchangeRateCurrent);
          if (derivativeTypeRow.workflow === 'Starting Value') { // index===0
            // set initial exchangeRate for the DerivativeTypeCombo
            this.exchangeRatePrevious = this.exchangeRateCurrent;
            this.adjustDerivativeTypeComboCumulativeValues(derivativeTypeRow);
            console.log('Starting Value -> Before transform : ', derivativeTypeRow);
            let derivativeTypeComboRow = this.transformRowWithExchangeCalculation(derivativeTypeRow);
            console.log('Starting Value -> After transform : ', derivativeTypeComboRow);
            this.adjustDerivativeTypeComboCumulativeCalculatedValues(derivativeTypeComboRow);
            this.rowData.push(derivativeTypeComboRow);

          } else { // Not starting value
            if (this.exchangeRateCurrent === this.exchangeRatePrevious) { // no yellow lines
              console.log('this.exchangeRateCurrent - No Change : ', this.exchangeRateCurrent);
              this.adjustDerivativeTypeComboCumulativeValues(derivativeTypeRow);
              console.log('Not Starting Value -> Before transform : ', derivativeTypeRow);
              let derivativeTypeComboRow = this.transformRowWithExchangeCalculation(derivativeTypeRow);
              console.log('Not Starting Value -> After transform : ', derivativeTypeComboRow);
              this.adjustDerivativeTypeComboCumulativeCalculatedValues(derivativeTypeComboRow);
              this.rowData.push(derivativeTypeComboRow);

            } else { // yellow line needs to be added before line is written to rowData
              // let yellowLine = new cls(); - instantiate an empty row object
              console.log('this.exchangeRateCurrent - Change : ', this.exchangeRateCurrent);
              this.genericLine = this.createGenericLine(derivativeTypeRow); // todo: maybe this line should move

              console.log('this.genericLine -> Yellow line :', this.genericLine);

              // Add to CUM CALC, not to CALC because there are no CALC in the yellow line
              this.adjustDerivativeTypeComboCumulativeCalculatedValues(this.genericLine);
              // push yellow line to this.rowData
              this.rowData.push(this.genericLine);

              // sequence exchange rates
              this.exchangeRatePrevious = this.exchangeRateCurrent;

              this.adjustDerivativeTypeComboCumulativeValues(derivativeTypeRow);
              console.log('Line After Yellow line -> Before transform : ', derivativeTypeRow);
              let derivativeTypeComboRow = this.transformRowWithExchangeCalculation(derivativeTypeRow);
              console.log('Line After Yellow line -> After transform : ', derivativeTypeComboRow);
              this.adjustDerivativeTypeComboCumulativeCalculatedValues(derivativeTypeComboRow);
              this.rowData.push(derivativeTypeComboRow);

            }

          }

        });

      }
    });

  }

  instantiateCalculationStructures(row: any) {
    let propertySetter = false;
    for (let key in row) {
      if (key === 'transferPrice') {
        propertySetter = true;
      }
      if (!propertySetter) {
        continue;
      }
      this.derivativeTypeComboCumulativeValues[key] = row[key];
      this.derivativeTypeComboCumulativeCalculatedValues[key] = row[key];
    }
    // clear structures
    this.resetDerivativeTypeComboCumulativeValues();
  }

  setExchangeRateCurrent(row: any) {
    return this.getExchangeRateForValidFromDate(new Date(row.validFrom));
  }

  transformRowWithExchangeCalculation(row: any) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: row.includeRoyalties,
      workflow: row.workflow,
      assignment: row.assignment,
      releaseDate: row.releaseDate,
      reason: row.reason,
      validFrom: row.validFrom,
      transferPrice: row.transferPrice * this.exchangeRateCurrent,
      gwmParts: row.gwmParts * this.exchangeRateCurrent,
      bmwParts: row.bmwParts * this.exchangeRateCurrent,
      salParts: row.salParts * this.exchangeRateCurrent,
      thirdPartyParts: row.thirdPartyParts * this.exchangeRateCurrent,
      directLabour: row.directLabour * this.exchangeRateCurrent,
      inboundLogistics: row.inboundLogistics * this.exchangeRateCurrent,
      warranty: row.warranty * this.exchangeRateCurrent,
      importDuties: row.importDuties * this.exchangeRateCurrent,
      customsClearanceFee: row.customsClearanceFee * this.exchangeRateCurrent,
      fuelAndEnergy: row.fuelAndEnergy * this.exchangeRateCurrent,
      variableManufacturingExpenses: row.variableManufacturingExpenses * this.exchangeRateCurrent,
      outboundLogistics: row.outboundLogistics * this.exchangeRateCurrent,
      nondeductableIndirectTaxesLinked: row.nondeductableIndirectTaxesLinked * this.exchangeRateCurrent,
      nondeductableIndirectTaxes: row.nondeductableIndirectTaxes * this.exchangeRateCurrent,
      depreciation: row.depreciation * this.exchangeRateCurrent,
      indirectLabour: row.indirectLabour * this.exchangeRateCurrent,
      otherFixedExpenses: row.otherFixedExpenses * this.exchangeRateCurrent,
      royaltiesTechnology: row.royaltiesTechnology * this.exchangeRateCurrent,
      profit5Percent: row.profit5Percent * this.exchangeRateCurrent,
      yearlyProductionCostReduction: row.yearlyProductionCostReduction * this.exchangeRateCurrent,
      singleSATotal: row.singleSATotal * this.exchangeRateCurrent,
      royaltiesForSA: row.royaltiesForSA * this.exchangeRateCurrent,
      profitForSA: row.profitForSA * this.exchangeRateCurrent,
      rules: row.rules * this.exchangeRateCurrent,
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: row.productBodyAndRoofColours[colorobj.code] * this.exchangeRateCurrent});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: row.productDesignTrims[trimobj.code] * this.exchangeRateCurrent});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: row.productPacket[packetobj.code] * this.exchangeRateCurrent});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: row.productExecution[executionobj.code] * this.exchangeRateCurrent});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: row.productSingleSA[saobj.code] * this.exchangeRateCurrent});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  createGenericLine(row: any) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: row.includeRoyalties,
      workflow: 'Exchange Rate',
      assignment: '',
      releaseDate: row.releaseDate,
      reason: 'Exchange Rate',
      validFrom: row.validFrom,
      transferPrice: this.derivativeTypeComboCumulativeValues['transferPrice'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['transferPrice'],
      gwmParts: this.derivativeTypeComboCumulativeValues['gwmParts'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['gwmParts'],
      bmwParts: this.derivativeTypeComboCumulativeValues['bmwParts'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['bmwParts'],
      salParts: this.derivativeTypeComboCumulativeValues['salParts'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['salParts'],
      thirdPartyParts: this.derivativeTypeComboCumulativeValues['thirdPartyParts'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['thirdPartyParts'],
      directLabour: this.derivativeTypeComboCumulativeValues['directLabour'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['directLabour'],
      inboundLogistics: this.derivativeTypeComboCumulativeValues['inboundLogistics'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['inboundLogistics'],
      warranty: this.derivativeTypeComboCumulativeValues['warranty'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['warranty'],
      importDuties: this.derivativeTypeComboCumulativeValues['importDuties'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['importDuties'],
      customsClearanceFee: this.derivativeTypeComboCumulativeValues['customsClearanceFee'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['customsClearanceFee'],
      fuelAndEnergy: this.derivativeTypeComboCumulativeValues['fuelAndEnergy'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['fuelAndEnergy'],
      variableManufacturingExpenses: this.derivativeTypeComboCumulativeValues['variableManufacturingExpenses'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['variableManufacturingExpenses'],
      outboundLogistics: this.derivativeTypeComboCumulativeValues['outboundLogistics'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['outboundLogistics'],
      nondeductableIndirectTaxesLinked: this.derivativeTypeComboCumulativeValues['nondeductableIndirectTaxesLinked'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['nondeductableIndirectTaxesLinked'],
      nondeductableIndirectTaxes: this.derivativeTypeComboCumulativeValues['nondeductableIndirectTaxes'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['nondeductableIndirectTaxes'],
      depreciation: this.derivativeTypeComboCumulativeValues['depreciation'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['depreciation'],
      indirectLabour: this.derivativeTypeComboCumulativeValues['indirectLabour'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['indirectLabour'],
      otherFixedExpenses: this.derivativeTypeComboCumulativeValues['otherFixedExpenses'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['otherFixedExpenses'],
      royaltiesTechnology: this.derivativeTypeComboCumulativeValues['royaltiesTechnology'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['royaltiesTechnology'],
      profit5Percent: this.derivativeTypeComboCumulativeValues['profit5Percent'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['profit5Percent'],
      yearlyProductionCostReduction: this.derivativeTypeComboCumulativeValues['yearlyProductionCostReduction'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['yearlyProductionCostReduction'],
      singleSATotal: this.derivativeTypeComboCumulativeValues['singleSATotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['singleSATotal'],
      royaltiesForSA: this.derivativeTypeComboCumulativeValues['royaltiesForSA'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['royaltiesForSA'],
      profitForSA: this.derivativeTypeComboCumulativeValues['profitForSA'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['profitForSA'],
      rules: this.derivativeTypeComboCumulativeValues['rules'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['rules'],
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.derivativeTypeComboCumulativeValues['productBodyAndRoofColours'][colorobj.code] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productBodyAndRoofColours'][colorobj.code]});
    });
    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: this.derivativeTypeComboCumulativeValues['productBodyAndRoofColours']['bodyAndRoofColourTotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productBodyAndRoofColours']['bodyAndRoofColourTotal']});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.derivativeTypeComboCumulativeValues['productDesignTrims'][trimobj.code] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productDesignTrims'][trimobj.code]});
    });
    Object.assign(productDesignTrims, {designTrimsTotal: this.derivativeTypeComboCumulativeValues['productDesignTrims']['designTrimsTotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productDesignTrims']['designTrimsTotal']});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.derivativeTypeComboCumulativeValues['productPacket'][packetobj.code] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productPacket'][packetobj.code]});
    });
    Object.assign(productPacket, {packetTotal: this.derivativeTypeComboCumulativeValues['productPacket']['packetTotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productPacket']['packetTotal']});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.derivativeTypeComboCumulativeValues['productExecution'][executionobj.code] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productExecution'][executionobj.code]});
    });
    Object.assign(productExecution, {executionTotal: this.derivativeTypeComboCumulativeValues['productExecution']['executionTotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productExecution']['executionTotal']});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.derivativeTypeComboCumulativeValues['productSingleSA'][saobj.code] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productSingleSA'][saobj.code]});
    });
    Object.assign(productSingleSA, {singleSATotal: this.derivativeTypeComboCumulativeValues['productSingleSA']['singleSATotal'] * this.exchangeRateCurrent - this.derivativeTypeComboCumulativeCalculatedValues['productSingleSA']['singleSATotal']});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  resetDerivativeTypeComboCumulativeValues() {
    this.transferPriceCumulative = 0;
    this.exchangeRateCumulative = 0;

    for (const key in this.derivativeTypeComboCumulativeValues) {
      if (key === 'productBodyAndRoofColours'
        || key === 'productDesignTrims'
        || key === 'productExecution'
        || key === 'productPacket'
        || key === 'productSingleSA') {
        for (const innerkey in this.derivativeTypeComboCumulativeValues[key]) {
          this.derivativeTypeComboCumulativeValues[key][innerkey] = 0;
        }
      } else {
        this.derivativeTypeComboCumulativeValues[key] = 0;
      }
    }

    for (const key in this.derivativeTypeComboCumulativeCalculatedValues) {
      if (key === 'productBodyAndRoofColours'
        || key === 'productDesignTrims'
        || key === 'productExecution'
        || key === 'productPacket'
        || key === 'productSingleSA') {
        for (const innerkey in this.derivativeTypeComboCumulativeCalculatedValues[key]) {
          this.derivativeTypeComboCumulativeCalculatedValues[key][innerkey] = 0;
        }
      } else {
        this.derivativeTypeComboCumulativeCalculatedValues[key] = 0;
      }
    }
  }

  adjustDerivativeTypeComboCumulativeValues(row: any) {
    this.transferPriceCumulative += row.transferPrice;  // to be deprecated
    let propertySetter = false;
    for (let key in row) {
      if (key === 'transferPrice') {
        propertySetter = true;
      }
      if (!propertySetter) {
        continue;
      }

      if (key === 'productBodyAndRoofColours'
          || key === 'productDesignTrims'
          || key === 'productExecution'
          || key === 'productPacket'
          || key === 'productSingleSA') {
          for (const innerkey in row[key]) {
            this.derivativeTypeComboCumulativeValues[key][innerkey] += row[key][innerkey];
          }
        } else {
          this.derivativeTypeComboCumulativeValues[key] += row[key];
        }
    }
  }

  adjustDerivativeTypeComboCumulativeCalculatedValues(row: any) {
    let propertySetter = false;
    for (let key in row) {
      if (key === 'transferPrice') {
        propertySetter = true;
      }
      if (!propertySetter) {
        continue;
      }

      if (key === 'productBodyAndRoofColours'
          || key === 'productDesignTrims'
          || key === 'productExecution'
          || key === 'productPacket'
          || key === 'productSingleSA') {
          for (const innerkey in row[key]) {
            this.derivativeTypeComboCumulativeCalculatedValues[key][innerkey] += row[key][innerkey];
          }
        } else {
          this.derivativeTypeComboCumulativeCalculatedValues[key] += row[key];
        }
    }
  }

  getExchangeRateForValidFromDate(date: Date) {
    let exchangeRate = 0;
    let dateYear = date.getFullYear();
    let dateMonth = date.getMonth();

    if (this.exchangeRateMap.has(dateYear.toString())) {
      exchangeRate = this.exchangeRateMap.get(dateYear.toString())[dateMonth];
    }

    return exchangeRate;
  }

  transformActiveRow(row: any,derivativeTypeCombo:any[]) {
    let arrayLastRow = derivativeTypeCombo.length - 1;

    let obj = {
      derivativeCode: derivativeTypeCombo[arrayLastRow].derivativeCode,
      type: derivativeTypeCombo[arrayLastRow].type,
      includeRoyalties: derivativeTypeCombo[arrayLastRow].includeRoyalties,
      workflow: 'Active',
      assignment: '',
      releaseDate: derivativeTypeCombo[arrayLastRow].releaseDate,
      reason: '',
      validFrom: derivativeTypeCombo[arrayLastRow].validFrom,
      totalTransferPrice: row.totalTransferPrice,
      // transferPrice: derivativeTypeCombo.reduce((sum, row) => sum + row.transferPrice,0) + derivativeTypeCombo.reduce((sum, row) => sum + row.rules, 0),
      transferPrice: row.transferPrice,
      gwmParts: derivativeTypeCombo.reduce((sum, row) => sum + row.gwmParts,0),
      bmwParts: derivativeTypeCombo.reduce((sum, row) => sum + row.bmwParts,0),
      salParts: derivativeTypeCombo.reduce((sum, row) => sum + row.salParts,0),
      thirdPartyParts: derivativeTypeCombo.reduce((sum, row) => sum + row.thirdPartyParts,0),
      directLabour: derivativeTypeCombo.reduce((sum, row) => sum + row.directLabour,0),
      inboundLogistics: derivativeTypeCombo.reduce((sum, row) => sum + row.inboundLogistics,0),
      warranty: derivativeTypeCombo.reduce((sum, row) => sum + row.warranty,0),
      importDuties: derivativeTypeCombo.reduce((sum, row) => sum + row.importDuties,0),
      customsClearanceFee: derivativeTypeCombo.reduce((sum, row) => sum + row.customsClearanceFee,0),
      fuelAndEnergy: derivativeTypeCombo.reduce((sum, row) => sum + row.fuelAndEnergy,0),
      variableManufacturingExpenses: derivativeTypeCombo.reduce((sum, row) => sum + row.variableManufacturingExpenses,0),
      outboundLogistics: derivativeTypeCombo.reduce((sum, row) => sum + row.outboundLogistics,0),
      nondeductableIndirectTaxesLinked: derivativeTypeCombo.reduce((sum, row) => sum + row.nondeductableIndirectTaxesLinked,0),
      nondeductableIndirectTaxes: derivativeTypeCombo.reduce((sum, row) => sum + row.nondeductableIndirectTaxes,0),
      depreciation: derivativeTypeCombo.reduce((sum, row) => sum + row.depreciation,0),
      indirectLabour: derivativeTypeCombo.reduce((sum, row) => sum + row.indirectLabour,0),
      otherFixedExpenses: derivativeTypeCombo.reduce((sum, row) => sum + row.otherFixedExpenses,0),
      royaltiesTechnology: derivativeTypeCombo.reduce((sum, row) => sum + row.royaltiesTechnology,0),
      profit5Percent: derivativeTypeCombo.reduce((sum, row) => sum + row.profit5Percent,0),
      yearlyProductionCostReduction: derivativeTypeCombo.reduce((sum, row) => sum + row.yearlyProductionCostReduction,0),
      singleSATotal: derivativeTypeCombo.reduce((sum, row) => sum + row.singleSATotal,0),
      bodyAndRoofColourTotal: derivativeTypeCombo.reduce((sum, row) => sum + row.bodyAndRoofColourTotal, 0),
      packetTotal: derivativeTypeCombo.reduce((sum, row) => sum + row.packetTotal, 0),
      designTrimsTotal: derivativeTypeCombo.reduce((sum, row) => sum + row.designTrimsTotal, 0),
      executionTotal: derivativeTypeCombo.reduce((sum, row) => sum + row.executionTotal, 0),
      royaltiesForSA: derivativeTypeCombo.reduce((sum, row) => sum + row.royaltiesForSA,0),
      profitForSA: derivativeTypeCombo.reduce((sum, row) => sum + row.profitForSA,0),
      rules: derivativeTypeCombo.reduce((sum, row) => sum + row.rules, 0)
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: derivativeTypeCombo.reduce((sum, row) => sum + row.productBodyAndRoofColours[colorobj.code],0)});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: derivativeTypeCombo.reduce((sum, row) => sum + row.productDesignTrims[trimobj.code],0)});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: derivativeTypeCombo.reduce((sum, row) => sum + row.productPacket[packetobj.code],0)});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: derivativeTypeCombo.reduce((sum, row) => sum + row.productExecution[executionobj.code],0)});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: derivativeTypeCombo.reduce((sum, row) => sum + row.productSingleSA[saobj.code],0)});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  // TODO: Put extra focus on the Option Calc testing.
  createActiveLine(typeGroup:any[]) {
    let arrayLastRow = typeGroup.length - 1;
    let obj = {
      derivativeCode: typeGroup[arrayLastRow].derivativeCode,
      type: typeGroup[arrayLastRow].type,
      includeRoyalties: typeGroup[arrayLastRow].includeRoyalties,
      workflow: 'Active',
      assignment: '',
      releaseDate: typeGroup[arrayLastRow].releaseDate,
      reason: '',
      validFrom: typeGroup[arrayLastRow].validFrom,
      totalOfOptions: typeGroup[arrayLastRow].workflow === 'Rules' ? typeGroup[arrayLastRow - 1].totalOfOptions :
        typeGroup[arrayLastRow].totalOfOptions,
      totalTransferPrice: typeGroup[arrayLastRow].workflow === 'Rules' ? typeGroup[arrayLastRow - 1].totalTransferPrice
        : typeGroup[arrayLastRow].totalTransferPrice,
      transferPrice: typeGroup[arrayLastRow].workflow === 'Rules' ? typeGroup[arrayLastRow - 1].totalTransferPrice
        + typeGroup[0].totalOfOptions : typeGroup[arrayLastRow].totalTransferPrice + typeGroup[0].totalOfOptions,
      gwmParts: typeGroup.reduce((sum, row) => sum + row.gwmParts,0),
      bmwParts: typeGroup.reduce((sum, row) => sum + row.bmwParts,0),
      salParts: typeGroup.reduce((sum, row) => sum + row.salParts,0),
      thirdPartyParts: typeGroup.reduce((sum, row) => sum + row.thirdPartyParts,0),
      directLabour: typeGroup.reduce((sum, row) => sum + row.directLabour,0),
      inboundLogistics: typeGroup.reduce((sum, row) => sum + row.inboundLogistics,0),
      warranty: typeGroup.reduce((sum, row) => sum + row.warranty,0),
      importDuties: typeGroup.reduce((sum, row) => sum + row.importDuties,0),
      customsClearanceFee: typeGroup.reduce((sum, row) => sum + row.customsClearanceFee,0),
      fuelAndEnergy: typeGroup.reduce((sum, row) => sum + row.fuelAndEnergy,0),
      variableManufacturingExpenses: typeGroup.reduce((sum, row) => sum + row.variableManufacturingExpenses,0),
      outboundLogistics: typeGroup.reduce((sum, row) => sum + row.outboundLogistics,0),
      nondeductableIndirectTaxesLinked: typeGroup.reduce((sum, row) => sum + row.nondeductableIndirectTaxesLinked,0),
      nondeductableIndirectTaxes: typeGroup.reduce((sum, row) => sum + row.nondeductableIndirectTaxes,0),
      depreciation: typeGroup.reduce((sum, row) => sum + row.depreciation,0),
      indirectLabour: typeGroup.reduce((sum, row) => sum + row.indirectLabour,0),
      otherFixedExpenses: typeGroup.reduce((sum, row) => sum + row.otherFixedExpenses,0),
      royaltiesTechnology: typeGroup.reduce((sum, row) => sum + row.royaltiesTechnology,0),
      profit5Percent: typeGroup.reduce((sum, row) => sum + row.profit5Percent,0),
      yearlyProductionCostReduction: typeGroup.reduce((sum, row) => sum + row.yearlyProductionCostReduction,0),
      royaltiesForSA: typeGroup.reduce((sum, row) => sum + row.royaltiesForSA,0),
      profitForSA: typeGroup.reduce((sum, row) => sum + row.profitForSA,0),
      rules: typeGroup.reduce((sum, row) => sum + row.rules, 0)
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: typeGroup.reduce((sum, row) => sum + row.productBodyAndRoofColours[colorobj.code],0)});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: typeGroup.reduce((sum, row) => sum + row.productDesignTrims[trimobj.code],0)});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: typeGroup.reduce((sum, row) => sum + row.productPacket[packetobj.code],0)});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: typeGroup.reduce((sum, row) => sum + row.productExecution[executionobj.code],0)});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: typeGroup.reduce((sum, row) => sum + row.productSingleSA[saobj.code],0)});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  transformOperationRows(typeGroup:any[]) {
    let arrayLastRow = typeGroup.length - 2; // the row before the Options Profit and Royalties
    if (arrayLastRow < 0) {
      arrayLastRow = 0;
    }
    let obj = {
      derivativeCode: typeGroup[arrayLastRow].derivativeCode,
      type: typeGroup[arrayLastRow].type,
      includeRoyalties: typeGroup[arrayLastRow].includeRoyalties,
      workflow: typeGroup[arrayLastRow].workflow,
      assignment: typeGroup[arrayLastRow].assignment,
      releaseDate: typeGroup[arrayLastRow].releaseDate,
      reason: typeGroup[arrayLastRow].reason,
      validFrom: typeGroup[arrayLastRow].validFrom,
      transferPrice: typeGroup.reduce((sum, row) => sum + row.transferPrice,0),
      gwmParts: typeGroup.reduce((sum, row) => sum + row.gwmParts,0),
      bmwParts: typeGroup.reduce((sum, row) => sum + row.bmwParts,0),
      salParts: typeGroup.reduce((sum, row) => sum + row.salParts,0),
      thirdPartyParts: typeGroup.reduce((sum, row) => sum + row.thirdPartyParts,0),
      directLabour: typeGroup.reduce((sum, row) => sum + row.directLabour,0),
      inboundLogistics: typeGroup.reduce((sum, row) => sum + row.inboundLogistics,0),
      warranty: typeGroup.reduce((sum, row) => sum + row.warranty,0),
      importDuties: typeGroup.reduce((sum, row) => sum + row.importDuties,0),
      customsClearanceFee: typeGroup.reduce((sum, row) => sum + row.customsClearanceFee,0),
      fuelAndEnergy: typeGroup.reduce((sum, row) => sum + row.fuelAndEnergy,0),
      variableManufacturingExpenses: typeGroup.reduce((sum, row) => sum + row.variableManufacturingExpenses,0),
      outboundLogistics: typeGroup.reduce((sum, row) => sum + row.outboundLogistics,0),
      nondeductableIndirectTaxesLinked: typeGroup.reduce((sum, row) => sum + row.nondeductableIndirectTaxesLinked,0),
      nondeductableIndirectTaxes: typeGroup.reduce((sum, row) => sum + row.nondeductableIndirectTaxes,0),
      depreciation: typeGroup.reduce((sum, row) => sum + row.depreciation,0),
      indirectLabour: typeGroup.reduce((sum, row) => sum + row.indirectLabour,0),
      otherFixedExpenses: typeGroup.reduce((sum, row) => sum + row.otherFixedExpenses,0),
      royaltiesTechnology: typeGroup.reduce((sum, row) => sum + row.royaltiesTechnology,0),
      profit5Percent: typeGroup.reduce((sum, row) => sum + row.profit5Percent,0),
      yearlyProductionCostReduction: typeGroup.reduce((sum, row) => sum + row.yearlyProductionCostReduction,0),
      royaltiesForSA: typeGroup.reduce((sum, row) => sum + row.royaltiesForSA,0),
      profitForSA: typeGroup.reduce((sum, row) => sum + row.profitForSA,0),
      rules: 0,
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: typeGroup[arrayLastRow].productBodyAndRoofColours[colorobj.code]});  // TODO: Check this
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: typeGroup[arrayLastRow].productDesignTrims[trimobj.code]});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: typeGroup[arrayLastRow].productPacket[packetobj.code]});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: typeGroup[arrayLastRow].productExecution[executionobj.code]});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: typeGroup[arrayLastRow].productSingleSA[saobj.code]});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  clearList(optionList: any, reason: string, option: any) {
    if (reason === transferPrice.calculation.profit || reason === transferPrice.calculation.royalties) {
      optionList.forEach(obj => {
        Object.assign(option, {[obj.code]: 0});
      });
    }
  }

  createRulesRow(typeGroup: any[], totalRules: number) {
    let arrayLastRow = typeGroup.length - 1;
    let allowRoyalties = typeGroup[arrayLastRow].includeRoyalties;

    let obj = {
      derivativeCode: typeGroup[arrayLastRow].derivativeCode,
      type: typeGroup[arrayLastRow].type,
      includeRoyalties: allowRoyalties,
      workflow: 'Rules',
      assignment: '',
      releaseDate: typeGroup[arrayLastRow].releaseDate,
      reason: 'Rules',
      validFrom: typeGroup[arrayLastRow].validFrom,
      transferPrice: 0,
      gwmParts: 0,
      bmwParts: 0,
      salParts: 0,
      thirdPartyParts: 0,
      directLabour: 0,
      inboundLogistics: 0,
      warranty: 0,
      importDuties: 0,
      customsClearanceFee: 0,
      fuelAndEnergy: 0,
      variableManufacturingExpenses: 0,
      outboundLogistics: 0,
      nondeductableIndirectTaxesLinked: 0,
      nondeductableIndirectTaxes: 0,
      depreciation: 0,
      indirectLabour: 0,
      otherFixedExpenses: 0,
      royaltiesTechnology: 0,
      profit5Percent: 0,
      yearlyProductionCostReduction: 0,
      royaltiesForSA: 0,
      profitForSA: 0,
      rules: totalRules
    };

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: 0});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: 0});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: 0});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: 0});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: 0});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  createOperationRowss(row: any, firstOperationAdjustmentGoneThrough:boolean) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: RoyaltiesIndicator[row.transferPrice.royaltiesIndicator],
      workflow: row.operationId,
      releaseDate: row.releaseDate,
      validFrom: row.validFrom,
      totalTransferPrice: 0,
      transferPrice: 0,
      gwmParts: 0,
      bmwParts: 0,
      salParts: 0,
      thirdPartyParts: 0,
      directLabour: 0,
      inboundLogistics: 0,
      warranty: 0,
      importDuties: 0,
      customsClearanceFee: 0,
      fuelAndEnergy: 0,
      variableManufacturingExpenses: 0,
      outboundLogistics: 0,
      nondeductableIndirectTaxesLinked: 0,
      nondeductableIndirectTaxes: 0,
      depreciation: 0,
      indirectLabour: 0,
      otherFixedExpenses: 0,
      royaltiesTechnology: 0,
      profit5Percent: 0,
      royaltiesForSA:0,
      profitForSA:0,
      yearlyProductionCostReduction: 0,
      singleSATotal: 0,// this.SingleSATotal(row, innerRow),
      totalOfOptions: this.SpecialEquipmentTotalPrice(row),
      rules: 0
    }

    
    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, colorobj.code)});
    });

    let bodyRoofColorTotal = 0;
    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, trimobj.code)});
    });

    let designTrimsTotalCalc = 0;
    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.PacketsMinusProfitsRoyalties(row, packetobj.code)});
    });

    let packetTotalCalc = 0;
    Object.assign(productPacket, {packetTotal: packetTotalCalc});

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, executionobj.code)});
    });

    let executionTotalCalc = 0;
    Object.assign(productExecution, {executionTotal: executionTotalCalc});

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentMinusProfitRoyalties(row, saobj.code)});
    });

    let saTotalCalc = 0;
    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  createOperationRows(row: any, innerRow:any, index: number, innerIndex: number, firstOperationAdjustmentGoneThrough:boolean) {
    let obj = {
      derivativeCode: row.derivativeCode,
      type: row.type,
      includeRoyalties: RoyaltiesIndicator[row.transferPrice.royaltiesIndicator],
      workflow: row.operationId,
      assignment: innerRow.adjustmentReason.changeType.name,
      releaseDate: row.releaseDate,
      reason: innerRow.adjustmentReason.name,
      validFrom: row.validFrom,
      totalTransferPrice: ((row.previousManufacturingCosts && row.previousManufacturingCosts.length > 0) ? +(row.previousManufacturingCosts.reduce((sum, prevManCost) => sum + prevManCost.price.value, 0)).toFixed(2) : 0) + row.price.value + this.SpecialEquipmentTotalPrice(row),
      transferPrice: firstOperationAdjustmentGoneThrough ? innerRow.amount.value : innerRow.amount.value + this.SpecialEquipmentTotalPricePerAdjustmentReason(row,innerRow),
      gwmParts: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.gwmParts),
      bmwParts: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.bmwParts),
      salParts: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.salParts),
      thirdPartyParts: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.thirdPartyParts),
      directLabour: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.directLabour),
      inboundLogistics: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.inboundLogistics),
      warranty: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.warranty),
      importDuties: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.importDuties),
      customsClearanceFee: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.customsClearanceFee),
      fuelAndEnergy: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.fuelAndEnergy),
      variableManufacturingExpenses: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.variableManufacturingExpenses),
      outboundLogistics: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.outboundLogistic),
      nondeductableIndirectTaxesLinked: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.nonDeductibleIndirectTaxesDirectlyLinkedToProject),
      nondeductableIndirectTaxes: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject),
      depreciation: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.depreciation),
      indirectLabour: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.indirectLabour),
      otherFixedExpenses: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.otherFixedExpenses),
      royaltiesTechnology: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.royaltiesForTechnology),
      profit5Percent: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.profit),
      yearlyProductionCostReduction: this.CategoryCostCurrentCalc(innerRow,transferPrice.category.yearlyProductionCostReduction),
      singleSATotal: 0,// this.SingleSATotal(row, innerRow),
      totalOfOptions: this.SpecialEquipmentTotalPrice(row),
      royaltiesForSA: innerRow.adjustmentReason.name === 'Royalties' ? this.ProfitRoyaltiesRow(row, 'Royalties') : 0,
      profitForSA: innerRow.adjustmentReason.name === 'Profit' ? this.ProfitRoyaltiesRow(row, 'Profit') : 0,
      rules: 0
    }

    // Body and roof colour
    let productBodyAndRoofColours = {};
    this.bodyAndRoofColorList.forEach(colorobj => {
      Object.assign(productBodyAndRoofColours, {[colorobj.code]: this.SpecialEquipmentPricePlusProfitRoyalties(row, innerRow, colorobj.code)});
    });

    let bodyRoofColorTotal = 0;
    for (let key in productBodyAndRoofColours) {
      bodyRoofColorTotal += productBodyAndRoofColours[key];
    }

    // Design Trims
    let productDesignTrims = {};
    this.designTrimsList.forEach(trimobj => {
      Object.assign(productDesignTrims, {[trimobj.code]: this.SpecialEquipmentPricePlusProfitRoyalties(row, innerRow, trimobj.code)});
    });

    let designTrimsTotalCalc = 0;
    for (let key in productDesignTrims) {
      designTrimsTotalCalc += productDesignTrims[key];
    }

    // Packet
    let productPacket = {};
    this.packetList.forEach(packetobj => {
      Object.assign(productPacket, {[packetobj.code]: this.PacketPricePlusProfitRoyalties(row, innerRow, packetobj.code)});
    });

    let packetTotalCalc = 0;
    for (let key in productPacket) {
      packetTotalCalc += productPacket[key];
    }

    // Execution
    let productExecution = {};
    this.executionList.forEach(executionobj => {
      Object.assign(productExecution, {[executionobj.code]: this.SpecialEquipmentPricePlusProfitRoyalties(row, innerRow, executionobj.code)});
    });

    let executionTotalCalc = 0;
    for (let key in productExecution) {
      executionTotalCalc += productExecution[key];
    }

    // Single SA
    let productSingleSA = {};
    this.singleSaList.forEach(saobj => {
      Object.assign(productSingleSA, {[saobj.code]: this.SpecialEquipmentPricePlusProfitRoyalties(row, innerRow, saobj.code)});
    });

    let saTotalCalc = 0;
    for (let key in productSingleSA) {
      saTotalCalc += productSingleSA[key];
    }

    if (innerRow.adjustmentReason.name === 'Royalties' || innerRow.adjustmentReason.name === 'Profit') {
      bodyRoofColorTotal = 0;
      designTrimsTotalCalc = 0;
      packetTotalCalc = 0;
      executionTotalCalc = 0;
      saTotalCalc = 0;
    }

    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: bodyRoofColorTotal});
    Object.assign(productDesignTrims, {designTrimsTotal: designTrimsTotalCalc});
    Object.assign(productPacket, {packetTotal: packetTotalCalc});
    Object.assign(productExecution, {executionTotal: executionTotalCalc});
    Object.assign(productSingleSA, {singleSATotal: saTotalCalc});

    let finalObj = {...obj, productBodyAndRoofColours, productDesignTrims, productPacket, productExecution, productSingleSA}
    return finalObj;
  }

  RoyaltiesForSAFirstRow(row) {
    let royaltiesNum = 0;
    if (row.transferPrice.royaltiesIndicator === 'SOMETIMES' || row.transferPrice.royaltiesIndicator === 'ALWAYS') {
      if (row.type.specialEquipment !== null) {
        row.type.specialEquipment.map(specialEquipmentRow => {
          let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
      if (row.type.packets !== null) {
        row.type.packets.map(specialEquipmentRow => {
          let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
    }
    return royaltiesNum;
  }

  ProfitRoyaltiesRow(row, reason) {
    let sum = 0;
    let key = row.operationId + reason + row.type.code;
    if (!this.operationIdReasonMap.has(key)) {
      row.type.specialEquipment?.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory?.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === reason);
        sum = sum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
      row.type.packets?.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory?.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === reason);
        sum = sum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
      this.operationIdReasonMap.add(key);
    }
    return sum;
  }

  RoyaltiesForSAPerType(row) {
    let royaltiesNum = 0;
    if (row.transferPrice.royaltiesIndicator === 'SOMETIMES' || row.transferPrice.royaltiesIndicator === 'ALWAYS') {
      if (row.type.specialEquipment !== null) {
        row.type.specialEquipment.map(specialEquipmentRow => {
          let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
      if (row.type.packets !== null) {
        row.type.packets.map(specialEquipmentRow => {
          let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Royalties');
          royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
    }
    return royaltiesNum;
  }

  ProfitForSAPerType(row) {
    let royaltiesNum = 0;
    if (row.type.specialEquipment !== null && row.type.specialEquipment.length > 0) {
      row.type.specialEquipment.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    if (row.type.packets !== null) {
      row.type.packets.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return royaltiesNum;
  }

  ProfitForSAFirstRow(row) {
    let royaltiesNum = 0;
    if (row.type.specialEquipment !== null && row.type.specialEquipment.length > 0) {
      row.type.specialEquipment.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    if (row.type.packets !== null) {
      row.type.packets.map(specialEquipmentRow => {
        let royaltyAdjustments = specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost.filter(transferCategoryCostRow => transferCategoryCostRow.adjustmentReason.name === 'Profit');
        royaltiesNum = royaltiesNum + royaltyAdjustments.reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return royaltiesNum;
  }

  SpecialEquipmentPricePlusProfitRoyalties(row: any, innerRow: any, saCode: string) {
    let saNum = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      let filteredSA = row.type.specialEquipment.filter(x => x.code === saCode);
      if (filteredSA && filteredSA.length > 0) {
        filteredSA.map(filteredSArow => {
          saNum = saNum + filteredSArow.manufacturingCostHistory.transferPrice.transferCategoryCost
            .filter(x => x.adjustmentReason.name === innerRow.adjustmentReason.name)
          .reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
    }
    return saNum;
  }

  PacketPricePlusProfitRoyalties(row: any, innerRow: any, saCode: string) {
    let saNum = 0;
    if (row.type.packets && row.type.packets.length > 0) {
      let filteredSA = row.type.packets.filter(x => x.code === saCode);
      if (filteredSA && filteredSA.length > 0) {
        filteredSA.map(filteredSArow => {
          saNum = saNum + filteredSArow.manufacturingCostHistory.transferPrice.transferCategoryCost
            .filter(x => x.adjustmentReason.name === innerRow.adjustmentReason.name)
            .reduce((sum, item) => sum + item.amount.value, 0);
        });
      }
    }
    return saNum;
  }

  SpecialEquipmentTotalPrice(row:any) {  // todo: do this change?
    let saTotal = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      saTotal = row.type.specialEquipment.reduce((sum,item) => sum + item.manufacturingCostHistory.price.value,0);
    }
    return saTotal;
  }

  SpecialEquipmentTotalPricePerAdjustmentReason(row:any, innerRow:any) {
    let saTotal = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      row.type.specialEquipment.map(specialEquipmentRow => {
        saTotal = saTotal + specialEquipmentRow.manufacturingCostHistory.transferPrice.transferCategoryCost
        .filter(x => x.adjustmentReason.name === innerRow.adjustmentReason.name)
        .reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return saTotal;
  }

  PacketPrice(row:any, saCode: String) {  // deprecated
    let saNum = 0;
    if (row.type.packets && row.type.packets.length > 0) {
      saNum = row.type.packets.filter(x => x.code === saCode).reduce((sum,item) => sum + item.manufacturingCostHistory.price.value,0);
    }
    return saNum;
  }

  SpecialEquipmentMinusProfitRoyalties(row: any, saCode: string) {
    let optionSum = 0;
    if (row.type.specialEquipment && row.type.specialEquipment.length > 0) {
      const filteredOption = row.type.specialEquipment.filter(option => option.code === saCode);
      return this.optionSumMinusProfitRoyalties(filteredOption);
    }
    return optionSum;
  }

  PacketsMinusProfitsRoyalties(row: any, saCode: string) {
    let packetSum = 0;
    if (row.type.packets && row.type.packets.length > 0) {
      const filteredPackets = row.type.packets.filter(x => x.code === saCode);
      return this.optionSumMinusProfitRoyalties(filteredPackets);
    }
    return packetSum;
  }

  optionSumMinusProfitRoyalties(option: any) {
    let optionSum = 0;
    if (option && option.length > 0) {
      option.map(packet => {
        optionSum = optionSum + packet.manufacturingCostHistory.transferPrice.transferCategoryCost
          .filter(x => x.adjustmentReason.name !== 'Royalties' &&
            x.adjustmentReason.name !== 'Profit')
          .reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return optionSum;
  }

  optionSumProfitRoyalties(option: any) {
    let optionSum = 0;
    if (option && option.length > 0) {
      option.map(packet => {
        optionSum = optionSum + packet.manufacturingCostHistory.transferPrice.transferCategoryCost
          .reduce((sum, item) => sum + item.amount.value, 0);
      });
    }
    return optionSum;
  }

  StartValueCategoryCostCurrentCalc(row: any, categoryCostName: String) {
    return row.transferPrice.transferCategoryCost.filter( item => item.categoryCost.name === categoryCostName).reduce((sum, catCost) => sum + catCost.amount.value, 0);
  }

  CategoryCostCurrentCalc(row: any, categoryCostName: String) {
    if (row.categoryCost.name === categoryCostName) {
      return row.amount.value;
    } else {
      return 0;
    }
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  dateTimeFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY HH:mm');
  }

}
