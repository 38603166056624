import {Component} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {PlantChangeService} from "./service/PlantChangeService";
import {LanguageChangeService} from "./service/LanguageChangeService";
import {AuthorisationService} from "./service/authorisation.service";
import {SystemGlobalCache} from "../../shared/system.global.cache";
import {AuthService} from "../../services/auth.service";
import {TokenPayload} from "../users/model/user.model";

@Component({
  selector: 'header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent {

  languages = ['en','de'];
  langSelect = 'en';
  actionReload: string = "reload";
  currentUser: string;
  clients: string[] = [];
  showDialog = false;
  runtimeCompilerData = {"show":true};
  systemName: string = "FAAS";

  constructor(public translate: TranslateService,
              private plantService: PlantChangeService,
              private langService: LanguageChangeService,
              private service: AuthorisationService,
              private authService: AuthService) {

    SystemGlobalCache.setLang('en');
    this.authService.doneLoading().subscribe(loaded => {
      if (loaded) {
        let payload = authService.getTokenPayload();
        if ( payload != null && payload?.plants?.length > 0 ) {
          this.setSystemValues(payload);
        } else {
          this.showDialog = true;
        }
      }
    });
  }

  changeClient(clientName: string) {
    SystemGlobalCache.setPlant(clientName);
    if (clientName === "SAL") {
      this.systemName = "FAAS SPOTLIGHT";
      this.languages = ['en','zh'];
    } else if (clientName === "GWM") {
      this.systemName = "FAAS GWM";
      this.languages = ['en','zh'];
    } else {
      this.systemName = "FAAS";
      this.languages = ['en','de'];
    }
    this.plantService.sendPlantName(clientName);
  }

  navigateHome() {
    return false;
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.langSelect = lang;
    SystemGlobalCache.setLang(lang);
    this.langService.sendLanguage(lang);
    return false;
  }

  setSystemValues(payload: TokenPayload) {
    SystemGlobalCache.setUserQNumber(payload.sub);
    this.currentUser = payload.name;
    this.clients = payload.plants.filter(plant => plant === "GWM" || plant === "SAL");
    this.changeClient(this.clients[0]);
  }

  logout() {
    this.authService.signOut(false);
  }

}
