import {Order, PricingPeriod, Surcharge, SurchargeViewModel} from "../components/order/model/order.model";
import {OrdersViewModel} from "../components/order/model/OrdersViewModel";
import moment from "moment";

export class Transformer {

  transformOrder(order: Order): OrdersViewModel[] {
    let orders = Array.of(order);
    return this.transformOrders(orders);
  }

  transformOrders(orders: Order[]): OrdersViewModel[] {
    let transformedOrders: any = [];
    let viewModel = new OrdersViewModel();
    for (let order of orders) {
      this.baseOrder(transformedOrders, order, viewModel);
      viewModel = new OrdersViewModel();
    }
    return transformedOrders;
  }

  baseOrder(transformedOrders: any = [], order: Order, viewModel: OrdersViewModel) {
    viewModel.hasPendingOrders = false;
    viewModel.changeDate = this.dateFormatter(order.changeDate);
    viewModel.changeDateEditable = this.dateFormatter(order.changeDate);
    viewModel.ai = order.material.ai;
    viewModel.snr = order.material.snr;
    viewModel.plant = order.material.plant;
    viewModel.orderCurrency = order.orderCurrency.currency;
    viewModel.internalIdentifier = order.internalIdentifier;
    viewModel.orderNo = order.orderNo;
    viewModel.orderState = order.orderState;
    viewModel.orderStateEditable = order.orderState;
    viewModel.orderType = order.orderType;
    viewModel.quantityUnit = order.quantityUnit;
    viewModel.supplierNo = order.supplierNo;
    viewModel.version = order.version;
    if (order.relatedOrders != undefined || order.relatedOrders != null) {
      viewModel.relatedOrders = order.relatedOrders;
      viewModel.hasPendingOrders = order.relatedOrders.length > 0;
    }
    if (order.pricingPeriods.length == 0) {
      transformedOrders.push(viewModel);
    } else {
      this.pricingPeriod(transformedOrders, viewModel, order.pricingPeriods)
    }
  }

  pricingPeriod(transformedOrders: any = [], viewModel: OrdersViewModel, pricingPeriods: PricingPeriod[]) {
    let _vm = {...viewModel};
    for (let pricingPeriod of pricingPeriods) {
      _vm.pricingPeriodInternalIdentifier = pricingPeriod.internalIdentifier;
      _vm.basePriceCurrency = pricingPeriod.basePrice.currency.currency;
      _vm.basePriceValue = pricingPeriod.basePrice.value.toFixed(2);
      _vm.nettPriceValue = pricingPeriod.nettPrice.value.toFixed(2);
      _vm.surchargeValueComputed = (pricingPeriod.nettPrice.value - pricingPeriod.basePrice.value).toFixed(2);
      _vm.nettPriceCurrency = pricingPeriod.nettPrice.currency.currency;
      _vm.fromDate = this.dateFormatter1(pricingPeriod.validity.fromDate);
      _vm.fromDateEditable = this.dateFormatter(pricingPeriod.validity.fromDate);
      _vm.fromDateOriginal = this.dateFormatter(pricingPeriod.validity.fromDate);
      _vm.toDate = this.dateFormatter1(pricingPeriod.validity.toDate);
      _vm.priceUnit = pricingPeriod.priceUnit;
      transformedOrders.push(_vm);
      _vm = {...viewModel};
    }
  }
  

  transformOrdersForSurcharge(orders: Order[]) {
    let surcharges: SurchargeViewModel[] = [];
    orders.forEach( order => {
      order.pricingPeriods.forEach( pricingPeriod => {
        pricingPeriod.surcharges.forEach(surcharge => {
          let viewModel = new SurchargeViewModel();
          viewModel.id = surcharge.internalIdentifier;
          viewModel.surchargeCategory = surcharge.category;
          viewModel.surchargeCurrency = surcharge.amount.currency.currency;
          viewModel.surchargeValue = surcharge.amount.value.toFixed(2);
          viewModel.surchargeType = surcharge.surchargeType;
          viewModel.orderNo = order.orderNo;
          viewModel.fromDate = this.dateFormatter(pricingPeriod.validity.fromDate);
          viewModel.toDate = this.dateFormatter(pricingPeriod.validity.toDate);
          surcharges.push(viewModel);
        });
      });
    });
    return surcharges;
  }


  dateFormatter(params) {
    return moment(params).format('DD.MM.YYYY');
  }

  dateFormatter1(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  formatTime(params) {
    return moment(params).format('DD.MM.YYYY hh:mm:ss');
  }

  formatDateWithTime(date: string): string {
    let now = new Date();
    let time = moment(now).format('HH:mm:ss')
    let dateToFormat = date + " " + time;
    return moment(dateToFormat,'DD.MM.YYYYTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
  }

}
