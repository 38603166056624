
export class ExchangeRateTypesMapper {

  private readonly exchangeRateTypes = new Map();

  constructor() {
    this.init();
  }

  getExchangeRateType(type: string): string {
    return this.exchangeRateTypes.get(type);
  }

  init(): void {
    this.exchangeRateTypes.set("YEAR_FINAL", "Final");
    this.exchangeRateTypes.set("YEAR_ACCRUAL", "Per November");
    this.exchangeRateTypes.set("IN_MONTH_F2", "Monthly");
    this.exchangeRateTypes.set("PER_MONTH_CURR_F_C", "Current");
  }
}
