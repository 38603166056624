import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {
  ManufacturingCostChangeInput,
  ManufacturingCost,
  Operation,
  ManufacturingCostInput
} from "../../model/operations.model";
import {TranslateService} from "@ngx-translate/core";
import {OperationService} from "../../service/operation.service";
import {SystemGlobalCache} from "../../../../shared/system.global.cache";

@Component({
  selector: 'app-edit-selection-dialog',
  templateUrl: './edit-selection-dialog.component.html',
  styleUrls: ['./edit-selection-dialog.component.scss']
})
export class EditSelectionDialogComponent implements OnInit {

  dialogTitle: string;
  editSelectionForm: FormGroup;
  message: string;
  dialogText: string;
  shouldEnableRelative: boolean = false;
  shouldEnableAbsolute: boolean = true;
  loadingScreen: boolean = false;
  errorMessage: string;

  @Input() submitFn;
  @Input() shouldShow: boolean;
  @Input() cancelFn;
  @Input() manufacturingCosts: ManufacturingCost[];
  @Input() operation: Operation;


  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private service: OperationService) { }

  ngOnInit(): void {
    this.dialogTitle = this.translate.instant('ops.details.title');
    this.editSelectionForm = this.formBuilder.group({
      absoluteAdaptation: new FormControl([''])
    });
  }

  takeover() {
    this.loadingScreen = true;
    try {
      if (this.editSelectionForm.valid) {
        this.editSelectionForm.markAllAsTouched();
        let manufacturingCostChangeInput = this.getUpdateManufacturingCostModel();
        this.service.updateManufacturingCost(manufacturingCostChangeInput).subscribe(({data}) => {
          this.cancel();
        }, error => {
          this.cancel();
          this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
        });
      }
    } catch (e) {
      this.errorMessage = e.toString();
      this.loadingScreen = false;
    }
  }

  cancel() {
    this.loadingScreen = false;
    this.shouldShow = false;
    this.manufacturingCosts = undefined;
  }

  getUpdateManufacturingCostModel() {
    if (this.editSelectionForm.valid) {
      let manufacturingCostChangeInput = new ManufacturingCostChangeInput();
      let firstManufacturingCost = this.manufacturingCosts[0];
      manufacturingCostChangeInput.plant = SystemGlobalCache.plant();
      manufacturingCostChangeInput.operationId = this.operation.internalIdentifier;
      this.manufacturingCosts.forEach(manufacturingCost => {
        let manufacturingCostInput = new ManufacturingCostInput();
        manufacturingCostInput.manufacturingCostId = manufacturingCost.internalIdentifier;
        manufacturingCostInput.price = this.getPriceAdjustment(firstManufacturingCost.price.value);
        manufacturingCostChangeInput.manufacturingCostInputs.push(manufacturingCostInput);
      });
      return manufacturingCostChangeInput;
    }
    return new ManufacturingCostChangeInput();
  }

  getPriceAdjustment(inputPrice: number) {
    return +inputPrice + +this.f.absoluteAdaptation.value;
  }

  toggle() {
    this.shouldEnableRelative = !this.shouldEnableRelative;
    this.shouldEnableAbsolute = !this.shouldEnableAbsolute;
  }

  get f() {
    return this.editSelectionForm.controls;
  }
}
