<div class="bmw__margin-top__xs">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-3-tablet bmw__grid__cell--span-4 form-template-grid-start">
      <p class="bmw-large-text bmw-bold-text bmw__margin-top__xs"><b>{{ 'menu.manufacturing-cost.tabs.sacostallocations.title' | translate }}</b></p>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-5-tablet bmw__grid__cell--span-8 form-template-grid-start">
    </div>
  </div>
    <ag-grid-angular *ngIf="showGrid"
      style="height: 800px; overflow: scroll"
      class="zebra"
      [rowData]="rowData"
      [columnDefs]="runtimeCompilerData.columns"
      [defaultColDef]="runtimeCompilerData.defaultColDef"
      [gridOptions]="runtimeCompilerData.gridOptions"
      [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDefAllocation"
      [frameworkComponents]="runtimeCompilerData.frameworkComponents"
      [suppressAggFuncInHeader]="true"
      [enableCellChangeFlash]="true"
      (gridReady)="onGridReady($event)"
      (rowDataChanged)="onRefreshGridData($event)"
    >
    </ag-grid-angular>
  <div><br>
  </div>
  <p style="float: left">
    <bmw-button button="secondary" (clickEvent)="onBack()" class="bmw__margin-right__xs">
      {{ 'menu.manufacturing-cost.tabs.sacostallocations.cancel' | translate }}
    </bmw-button>
    <bmw-button *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation" button="secondary" (clickEvent)="onUpload()">
      {{ 'menu.manufacturing-cost.tabs.opsdetail.upload' | translate }}
    </bmw-button>
  </p>
  <p style="float: right">
    <bmw-button button="primary" *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation" (clickEvent)="onGetConfirmation()">
      {{ 'menu.manufacturing-cost.tabs.sacostallocations.save' | translate }}
    </bmw-button>
  </p>
</div>
<app-type-sa-dialog [showUnallocatedSACostsDialog]="showUnallocatedCosts" (saveTypeSADialog)="saveWithDefaultValues()" (cancelSADialog)="cancelUnallocatedCosts()" ></app-type-sa-dialog>
