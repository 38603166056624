export interface WeightData {
  weightings: Weight;
}

export interface WeightParent {
  data: WeightData;
}

export interface Weight {
  orderNo: string;
  material: Material;
  importDate: Date;
  weightDate: Date;
  weightType: string;
  value: string;
}

export interface Material {
  snr: string;
  ai: number;
  plant: string;
}

export enum Plant {
  MSF = 'MSF',
  NEDCAR = 'NEDCAR'
}
