import gql from "graphql-tag";

export const GRANT_ROLE_TO_USER = gql`
  mutation ($user: UserRoleInputInput!) {
    grantOrRevokeRole(user: $user)
  }
`;


export const CREATE_ROLE = gql`
  mutation ($role: RoleInputInput!) {
    createRole(role: $role)
  }
`;

export const CREATE_PERMISSIONS = gql`
  mutation ($permissions: [PermissionInputInput]!) {
    createPermission(permissions: $permissions)
  }
`;

export const ADD_PERMISSION_TO_ROLE = gql`
  mutation ($rolePermissionsInput: RolePermissionsInputInput!) {
    addPermissionsToRole(rolePermissionsInput: $rolePermissionsInput)
  }
`;

export const USER_PRIVILEDGES = gql`
  mutation ($rights: UserPriviledgeInput!) {
    userRights(rights: $rights)
  }
`;
