import {TranslateService} from "@ngx-translate/core";
import {PriceCodesMapper} from "../../../price/model/price-codes.mapper";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";
import {OperationFile, Operation, OperationSummary, OperationChangeInput} from "../../model/operations.model";
import {OperationService} from "../../service/operation.service";
import {faas} from "../../../../constants/faas.constants";


export class OperationsDisplayGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;
  public showWorkFlow: boolean = false;
  showAttachments: boolean = false;
  params: any;
  workFlows: OperationSummary[] = [];
  dialogTitle: String;
  fileList: OperationFile[];

  constructor(translate: TranslateService, private operationService: OperationService) {
    super(translate);
    this.translate = translate;
    this.params = {
      rowData: new Operation(),
      $event: {}
    }
  }

  columns = [
    {
      field: '#',
      headerName: '',
      width: 40,
      minWidth: 40,
      rowGroup: false,
      hide: false,
      editable: false,
      filter: false,
      checkboxSelection: true
    },
    {
      field: 'operationId',
      headerName: 'operationId',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'name',
      headerName: 'operationName',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'validFrom',
      headerName: 'operationValidFrom',
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'releaseDate',
      headerName: 'operationReleaseDate',
      sortable: true,
      filter: true,
      valueFormatter: this.dateTimeFormatter,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'costEntries',
      headerName: 'operationCostEntries',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'workFlowStatus',
      headerName: 'operationStatus',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.statusFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'information',
      headerName: 'operationInformation',
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.statusFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellRenderer: 'dialogueComponent',
      cellRendererParams: {
        onCellClicked: this.workflow.bind(this),
        onClick: this.attachment.bind(this)
      }
    }
  ];

  attachment(params: any) {
    this.params = params;
    this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.download');
    this.fileList = this.params.rowData.files;
    this.showAttachments = true;
  }

  workflow(params: any) {
    this.params = params;
    this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.historytitle');
    let operationHistory = this.params.rowData;
    this.operationService.getOperationHistoryById(operationHistory.internalIdentifier).subscribe(({data}) => {
      this.workFlows = [...data.operationHistoryById];
      this.showWorkFlow = true;
    });
  }

  statusFormatter(params) {
    return new PriceCodesMapper().normalizeWord(params.value)
  }
}
