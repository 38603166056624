import {Order} from "./order.model";

export class OrdersViewModel {
  orderNo: string;
  version: number;
  orderCurrency: string;
  internalIdentifier: number;
  snr: string;
  ai: number;
  plant: string
  changeDate: string;
  changeDateEditable: string;
  changeDateHasChanged: boolean;
  supplierNo: string;
  orderState: string;
  orderStateEditable: string;
  orderStateHasChanged: boolean;
  orderType: string;
  quantityUnit: string;
  priceUnit: string;
  relatedOrders: Order[];
  pricingPeriodInternalIdentifier: number;
  basePriceCurrency: string;
  basePriceValue: string;
  nettPriceCurrency: string;
  nettPriceValue: string;
  fromDate: string;
  fromDateEditable: string;
  fromDateOriginal: string;
  fromDateHasChanged: boolean;
  toDate: string;
  surchargeCategory: string;
  surchargeType: string;
  surchargeCurrency: string;
  surchargeValue: string;
  surchargeValueComputed: string;
  hasPendingOrders: boolean;
}
