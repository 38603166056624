import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ManufacturingReportsService } from '../service/manufacturing-reports.service';
import { Product } from "../../product/model/product.model";
import { ExchangeRatesService } from '../../exchange-rates/service/exchange-rates.service';

@Component({
  selector: 'app-manufacturing-reports',
  templateUrl: './manufacturing-reports.component.html',
  styleUrls: ['./manufacturing-reports.component.scss']
})
export class ManufacturingReportsComponent implements OnInit {
  dialogTitle: string;
  dialogText: string;
  showPromt = false;
  showLoading = false;
  derivativeList: any [];
  exchangeRate: number = 0;  //this will be deprecated soon.
  exchangeRateMap = new Map();
  errorMessage: string;


  constructor(
    public translate: TranslateService,
    private manufacturingReportsService: ManufacturingReportsService,
    private exchangeRatesService: ExchangeRatesService,
  ) { }

  ngOnInit(): void {
    this.loadDerivatives();
    this.loadExchangeRates();
  }

  changeTab(event) {
    console.log('Reporting - Change Tab');
  }

  loadExchangeRates(){
    // this.showLoading = true;
    this.manufacturingReportsService.getExchangeRatesYearsForPlant()
    .subscribe((result) => {
      let exchangeRateYears = result.data.exchangeRatesYearsForPlant;
      //console.log('exchangeRateYears : ', exchangeRateYears);
      //For each year - get the monthly values
      exchangeRateYears.forEach(exchangeRateYear => {
        let exchangeRateValues = [];
        this.manufacturingReportsService.getExchangeRatesPerPlantAndYear(exchangeRateYear)
        .subscribe((result) => {
          let exchangeRates = result.data.exchangeRates
            .filter(exRate => {return exRate.exchangeRateType === 'IN_MONTH_F2'})
            .sort((a,b) => a.month - b.month);

          exchangeRates.forEach(item => {
            exchangeRateValues.push(item.value);
          });
          this.exchangeRateMap.set(exchangeRateYear.toString(),exchangeRateValues);
          console.log('this.exchangeRateMap : ', this.exchangeRateMap);

        }, (error) => {
          this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
          console.log('Error : ', this.errorMessage);
          // this.showLoading = false;
        });
      });
      // this.showLoading = false;
    }, (error) => {
      this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
      console.log('Error : ', this.errorMessage);
      // this.showLoading = false;
    });
  }

  loadDerivatives() {
    this.showLoading = true;
    this.manufacturingReportsService.getProductDerivatives()
      .subscribe(({data, loading}) => {
        this.derivativeList = data.productsByPlant;
        this.sortDerivatives(this.derivativeList);
        //this.setSelectedDates();
        this.showLoading = false;
      }, (error) => {
        this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
        this.showLoading = false;
      });
  }

  sortDerivatives(derivativeList: Product[]){
    derivativeList.sort(function(a, b) {
      if (a.derivative.code < b.derivative.code) {
        return -1;
      }
      if (a.derivative.code > b.derivative.code) {
        return 1;
      }
      return 0;
    });
  }

}
