import gql from "graphql-tag";
import {Order, Plant} from "../model/order.model";
import {OrderWorkflowStateInput} from "../service/order.graphql.query";

export const LATEST_ORDER_SNR_AI_QUERY = gql`
  query latestOrdersForMaterial($snrList: [String]! $plant: PlantInput! $ai: Int $orderWorkflowState: OrderWorkflowStateInput) {
    latestOrdersForMaterial(snrList: $snrList plant: $plant ai:$ai orderWorkflowState: $orderWorkflowState){
    changeDate
    material{
      ai
      snr
      plant
    }
    internalIdentifier
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    orderNo
    orderState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity{
        fromDate
        toDate
      }
      surcharges{
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
    }
    relatedOrders {
      changeDate
      material {
       ai
       snr
       plant
      }
      internalIdentifier
      orderCurrency {
        currency
        currencyType
        displayFormat
      }
      orderNo
      orderState
      orderType
      quantityUnit
      supplierNo
      version
      workflowState
      pricingPeriods {
        internalIdentifier
        priceUnit
        basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
     }
   }
  }
 }`;


export const LATEST_ORDER_QUERY = gql`
  query order($orderNo: String! $plant: PlantInput! $orderWorkflowState: OrderWorkflowStateInput) {
    order(orderNo: $orderNo plant: $plant orderWorkflowState: $orderWorkflowState){
    changeDate
    material{
      ai
      snr
      plant
    }
    internalIdentifier
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    orderNo
    orderState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity{
        fromDate
        toDate
      }
      surcharges{
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
    }
    relatedOrders {
      changeDate
      material {
       ai
       snr
       plant
      }
      internalIdentifier
      orderCurrency {
        currency
        currencyType
        displayFormat
      }
      orderNo
      orderState
      orderType
      quantityUnit
      supplierNo
      version
      workflowState
      pricingPeriods {
        internalIdentifier
        priceUnit
        basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
     }
   }
  }
 }`;


export interface LatestOrderSnrAiQueryResponse {
  latestOrdersForMaterial: Order[];
}

export interface LatestOrderQueryResponse {
  order: Order;
}

export type LatestOrderSnrAiVariables = {
  snrList?: string[];
  plant?: Plant;
  ai?: number;
  orderWorkflowState? : OrderWorkflowStateInput
}

export type LatestOrderVariables = {
  orderNo?: string;
  plant?: Plant;
  orderWorkflowState? : OrderWorkflowStateInput
}
