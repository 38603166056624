import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NormalizeTextPipe} from "./pipes/normalize-text.pipe";
import {FaasDatePipe} from "./pipes/faas-date.pipe";
import {FaasDateTimePipe} from "./pipes/faas-date-time.pipe";

@NgModule({
  declarations: [
    NormalizeTextPipe,
    FaasDatePipe,
    FaasDateTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NormalizeTextPipe,
    FaasDatePipe,
    FaasDateTimePipe
  ]
})
export class SharedModule { }
