import {Injectable} from "@angular/core";
import {ServiceBase} from "../../../services/service.base";
import {Apollo} from "apollo-angular";
import {AuthService} from "../../../services/auth.service";
import {faas} from "../../../constants/faas.constants";
import {
  ProductionWeekPriceResponse,
  ProductionWeekUpdateRequest,
  UpdateType
} from "../model/aggregations.models";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import {
  CALCULATE_CURRENT_PRICE_FOR_MATERIAL,
  CALCULATE_CURRENT_PRICE_FOR_WEEK,
  CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_DERIVATE,
  CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL,
  CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL_AND_DERIVATE
} from "./aggregation.graphql.mutation";
import {
  ALL_RECALCULATION_REQUEST,
  PRODUCTION_WEEK_FOR,
  ProductionWeekResponse,
  RecalculationResponse
} from "./aggregation.graphql.query";
import {padLeft} from "../../../shared/functions/padding";


@Injectable({
  providedIn: 'root'
})
export class AggregationService extends ServiceBase {

  private _apollo: Apollo;

  constructor(apollo: Apollo,
              private authService: AuthService) {
    super(apollo);
    this._apollo = apollo;
  }

  updateBomPrices(model: ProductionWeekUpdateRequest) {
    if (model.updateType == UpdateType.WeekOnly) return this.calculatePriceForWeek(model);
    if (model.updateType == UpdateType.MaterialOnly) return this.calculatePriceForMaterial(model);
    if (model.updateType == UpdateType.MaterialAndWeek) return this.calculatePriceForWeekAndMaterial(model);
    if (model.updateType == UpdateType.DerivateAndWeekOnly) return this.calculatePriceForWeekAndDerivate(model);
    if (model.updateType == UpdateType.MaterialAndDerivateAndWeekOnly) return this.calculatePriceForWeekAndMaterialAndDerivate(model);
  }

  private calculatePriceForWeek(model: ProductionWeekUpdateRequest) {
    const variable = {
      week: model.week,
      year: model.year,
      plant: model.plant
    }
    return this.mutate(variable, CALCULATE_CURRENT_PRICE_FOR_WEEK , faas.aggregationServiceClientName);
  }

  private calculatePriceForWeekAndMaterial(model: ProductionWeekUpdateRequest) {
    const variable = {
      week: model.week,
      year: model.year,
      material: model.getMaterial()
    }
    return this.mutate(variable, CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL , faas.aggregationServiceClientName);
  }

  private calculatePriceForMaterial(model: ProductionWeekUpdateRequest) {
    const variable = {
      material: model.getMaterial()
    }
    return this.mutate(variable, CALCULATE_CURRENT_PRICE_FOR_MATERIAL , faas.aggregationServiceClientName);
  }

  private calculatePriceForWeekAndDerivate(model: ProductionWeekUpdateRequest) {
    const variable = {
      week: model.week,
      year: model.year,
      plant: model.plant,
      derivativeCode: model.derivativeCode
    }
    return this.mutate(variable, CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_DERIVATE , faas.aggregationServiceClientName);
  }

  private calculatePriceForWeekAndMaterialAndDerivate(model: ProductionWeekUpdateRequest) {
    const variable = {
      week: model.week,
      year: model.year,
      derivativeCode: model.derivativeCode,
      material: model.getMaterial()
    }
    return this.mutate(variable, CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL_AND_DERIVATE , faas.aggregationServiceClientName);
  }

  productionWeeks() {
    const variable = {
      plant: SystemGlobalCache.plant()
    }
    return this.query<ProductionWeekResponse>(variable, PRODUCTION_WEEK_FOR, faas.aggregationServiceClientName);
  }

  canUpdatePrice() {
    let permissions = ['T_VP_Bearbeiter_SAL'];
    return this.authService.checkPermission(permissions);
  }

  recalculationRequests() {
    const variable = {
      plant: SystemGlobalCache.plant()
    }
    return this.query<RecalculationResponse>(variable, ALL_RECALCULATION_REQUEST, faas.aggregationServiceClientName);
  }

  toDomain(responses: ProductionWeekPriceResponse[]): any {
    let results = [];
    responses.forEach((response) => {
      let dto = {};
      dto["snr"] = response.snr;
      dto["ai"] = this.formatAi(response.snr, response.ai);
      dto["auditEventType"] = this.normalizeWord(response.auditEventType.toString());
      dto["startDate"] = response.createdDate;
      dto["derivativeCode"] = response.derivativeCode;
      dto["internalIdentifier"] = response.internalIdentifier;
      dto["orderNo"] = response.orderNo;
      dto["plant"] = response.plant;
      dto["productionWeekAuditStatus"] = response.auditStatus;
      dto["endDate"] = response.statusDate;
      dto["user"] = response.user;
      dto["cwYear"] = this.formatCW(response.year, response.week);
      results.push(dto);
    })
    return results;
  }

  normalizeWord = (s: string) : string => {
    if ( s == undefined ) return "";
    let split = s.split("_");
    let finalWord = "";
    for(let s of split){
      finalWord += s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() + " ";
    }
    return finalWord;
  }

  formatCW = (year: number , week: number) : string => {
    if (year === 0 && week === 0)
      return "#";
    return year + "/" + padLeft(week,2);
  }

  formatAi = (snr: string , ai: number) : string | number => {
    if ((snr === null || snr === undefined) && ai === 0 )
      return "";
    return ai;
  }

  dispose(): void {
  }


}
