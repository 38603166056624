import gql from "graphql-tag";
import {ManufacturingCost} from "../../model/operations.model";

export const MANUFACTURINGCOST_BY_PLANT = gql `
query manufacturingCostByIds($plant: Plant!, $ids: [Long]!){
    manufacturingCostByIds(plant: $plant, ids: $ids){
      derivativeCode
      internalIdentifier
      price {
        value
      }
      packet {
        code
        generation
        internalIdentifier
        name
        sequence
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
      }
      transferPrice {
        internalIdentifier
        transferCategoryCost {
          internalIdentifier
          amount {
            value
          }
          categoryCost {
            internalIdentifier
            name
            groupCost {
              code
              internalIdentifier
              name
            }
          }
          adjustmentReason {
            internalIdentifier
            name
            changeType {
              internalIdentifier
              name
            }
          }
        }
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
      previousManufacturingCosts {
        derivativeCode
        internalIdentifier
      }
    }
  }`;

  export interface ManufacturingCostQueryResponse{
    manufacturingCostByIds: ManufacturingCost[];
}
