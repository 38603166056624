import gql from "graphql-tag";

export const CALCULATE_CURRENT_PRICE_FOR_WEEK = gql`
  mutation ($week: Int! $year: Int! $plant: Plant!) {
    calculateCurrentPriceForWeek(week: $week year: $year plant: $plant)
  }
`;

export const CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_DERIVATE = gql`
  mutation ($derivativeCode: String! $week: Int! $year: Int! $plant: Plant!) {
    calculateCurrentPriceForDerivativeCodeAndWeek(derivativeCode: $derivativeCode week: $week year: $year plant: $plant)
  }
`;

export const CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL = gql`
  mutation ($week: Int! $year: Int! $material: MaterialInput!) {
    calculateCurrentPriceForWeekAndMaterial(week: $week year: $year material: $material)
  }
`;

export const CALCULATE_CURRENT_PRICE_FOR_WEEK_AND_MATERIAL_AND_DERIVATE = gql`
  mutation ($week: Int! $year: Int! $material: MaterialInput! $derivativeCode: String!) {
    calculateCurrentPriceForDerivativeCodeWeekAndMaterial(week: $week year: $year material: $material derivativeCode: $derivativeCode)
  }
`;

export const CALCULATE_CURRENT_PRICE_FOR_MATERIAL = gql`
  mutation ($material: MaterialInput!) {
    calculateCurrentPriceForMaterial(material: $material)
  }
`;


