import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {PriceSearchRequest} from "../../../model/continous.price.model";
import moment from "moment";
import {Plant} from "../../../../order/model/order.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-price-search-by-date',
  templateUrl: './price-search-by-date.component.html',
  styleUrls: ['./price-search-by-date.component.scss']
})
export class PriceSearchByDateComponent implements OnInit {
  searchRequest: PriceSearchRequest;
  inputDate: any;

  @Output() onSearch = new EventEmitter<PriceSearchRequest>();

  constructor(private translate: TranslateService) {
    this.searchRequest = new PriceSearchRequest();
  }

  ngOnInit(): void {
  }

  search() {
    try {
      this.onDateSelect(this.inputDate);
      this.canSearch()
      this.validate();
      this.searchRequest.isError = false;
      this.searchRequest.plant = Plant[window.sessionStorage.client];
    } catch (e) {
      this.searchRequest.isError = true;
      this.searchRequest.error = e.toString();
    }
    this.onSearch.emit(this.searchRequest);
  }

  canSearch() {
    let result = this.searchRequest.knowledgeDate != null;

    if (!result)
      throw this.translate.instant('menu.pricing.order-management.error-message.please-select-knowledge-date');
  }

  onDateSelect(date: any) {
    this.searchRequest.knowledgeDate = this.dateFormatter(date);
  }

  dateFormatter(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  validate() {
    let date = new Date(this.searchRequest.knowledgeDate);
    if (isNaN(date.getTime())) {
      throw this.translate.instant('menu.pricing.order-management-error-message.invalid-knowledge-date');
    }
  }
}
