<div *ngIf="!showConfirmScreen">
  <bmw-ag-grid-theme>
    <div
      *ngIf="errorMessage"
      class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">
        {{errorMessage}}
      </bmw-inline-message>
    </div>
    <div  class="bmw__margin-top__xs" *ngIf="shouldShowGrid">
      <div class="bmw__margin-top__xs">
        <app-exchange-rates-release-grid
          [rowData]="rowData"
        ></app-exchange-rates-release-grid>
      </div>
    </div>
  </bmw-ag-grid-theme>
</div>
<div *ngIf="!showConfirmScreen && shouldShowGrid"
  class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div
    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
    <bmw-button button="secondary" type="submit" (clickEvent)="refreshData()" [disabled]="disableRefreshButton" class="bmw__margin-right__xs">
      {{ 'component.bottom-bar.refresh' | translate }}
    </bmw-button>
  </div>
  <div
    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button button="primary" type="submit" (clickEvent)="onReject()" [disabled]="noDataToActOn()" style="padding: 5px;">
      {{ 'component.bottom-bar.reject' | translate }}
    </bmw-button>
    <bmw-button button="primary" type="submit" (clickEvent)="onRelease()" [disabled]="noDataToActOn()">
      {{ 'component.bottom-bar.release' | translate }}
    </bmw-button>
  </div>
</div>
<div *ngIf="showConfirmScreen">
  <app-exchange-rates-confirm
    [changes]="confirmExchangeRates"
    (cancel)="toggleView()"
    (confirmed)="onConfirmation()"
  ></app-exchange-rates-confirm>
</div>
