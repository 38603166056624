<ag-grid-angular
  class="zebra"
  [rowData]="rowData"
  [columnDefs]="columns"
  [gridOptions]="runtimeCompilerData.gridOptions"
  [defaultColDef]="runtimeCompilerData.defaultColDef"
  [sideBar]="false"
  [domLayout]="domLayout"
  [statusBar]="false"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="onModelUpdated()"
  (rowDataChanged)="onRefreshGridData($event)"
  [pagination]="false"
  [suppressPaginationPanel]="true">
</ag-grid-angular>
