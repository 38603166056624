import {Component, HostListener, Input, OnInit} from '@angular/core';
import {GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {WeightGridColumns} from "./weight-grid.columns";
import {GridSettingsStore} from "../../../helper/grid.settings.store";
import {Router} from "@angular/router";
import {TranslateWeightingsEnum} from "../service/weightings.enum.translations";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Component({
  selector: 'app-weight-grid',
  templateUrl: './weight-grid.component.html',
  styleUrls: ['./weight-grid.component.sass']
})
export class WeightGridComponent extends GridSettingsStore implements OnInit {

  @Input() rowData: any;
  @Input() groupBy: string[];

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  weightingsEnums: TranslateWeightingsEnum;

  constructor(private translate: TranslateService, private router: Router) {
    super();
    this.weightingsEnums = new TranslateWeightingsEnum();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
      this.translateInGridEnums();
    });
    this.gridColumns = new WeightGridColumns(this.translate);
    this.translateInGridEnums(true);
  }

  ngOnInit() {
    this.gridColumns.setColumns(this.groupBy);
    this.setGridOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //restore grid
    let columnState = this.getGridState(this.router.url);
    if ( columnState != undefined ) {
      this.gridColumnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
    }
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'weightDate', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
      if (this.gridApi.getDisplayedRowCount() == 0) {
        this.gridApi.showNoRowsOverlay();
      } else if (!this.filtering) {
        this.gridApi.showLoadingOverlay();
      }
    }

    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }
    this.translateInGridEnums(true);
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums(refreshFromDefault: boolean = null) {
    if (refreshFromDefault && this.translate.currentLang === 'de') {
      this.translateRowData(this.getAllRows(), 'de');
    }
    else if (this.translate.currentLang !== SystemGlobalCache.currentLang()) {
      this.rowData = this.translateRowData(this.getAllRows(), this.translate.currentLang);
    }
  }

  translateRowData(rows: any[], lang: string = 'en'): any[] {
    if (rows?.length === 0) { return; }
    let pushRowData = [];
    let refreshHeaders = false;
    rows.forEach(row => {
      refreshHeaders = true;
      if (row !== undefined && row?.weightType?.length > 0) {
          row.weightType = this.weightingsEnums.translate(lang, row.weightType);
        pushRowData.push(row);
      }
    });
    return pushRowData;
  }

  getAllRows() {
    let rowData = [];
    this.gridApi?.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'multiple',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }

  onSaveGridState(params: any) {
    let columnState = this.gridColumnApi?.getColumnState();
    this.saveGridState(this.router.url,columnState);
  }

  refreshGrid($event: any) {
    this.gridApi.expandAll();
  }
}
