import gql from "graphql-tag";
import { QuicksightUrl } from "../../manufacturing/model/reports.model";

export const RETRIEVE_QUICKSIGHT_URL= gql`
query retrieveQuicksightUrl($reportInput: ReportInputInput!) {
  retrieveQuicksightUrl(reportInput: $reportInput) {
    quicksightUrl
  }
}`;

export interface RetrieveQuicksightUrlResponse {
  retrieveQuicksightUrl: QuicksightUrl[];
}
