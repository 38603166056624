import {DateRangeInput} from "../../order/service/order.mutations";
import {Plant} from "../../order/model/order.model";

export interface JwtAuthenticationResponse {
  token: string;
}

export interface MenuAvailabilityResponse {
  isEnabled: boolean;
}

export class JwtAuthenticationRequest {

  username: string;
  password: string;

  getRequest() {
    this.username = "qqfss20";
    this.password = "321#";
    return this;
  }
}

export interface TokenPayload {
  audience: string;
  authorities: string[];
  created: number;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  plants: string[];
  sub: string;
  name: string;
}

export interface IbvUserRoles {
  username: string;
  fullName: string;
  plants: string[];
  permissions: string[];
}


export class PermissionDto {
  description: string;
  internalIdentifier: number;
  name: string;
  checked: boolean;
}

export class Permission {
  description: string;
  internalIdentifier: number;
  name: string;
}

export class Validity {
  fromDate: string;
  toDate: string;
}

export class Role {
  creator: string;
  info: string;
  internalIdentifier: number;
  name: string;
  plant: Plant;
  workArea: WorkArea;
  validity: Validity;
  permissions: Permission[];
}

export class CurrentUser {
  CNumber: string;
  QNumber: string;
  name: string;
  role: Role[];
  validity: Validity;
}

export class Client {
  name: string;
  value: string;
}

export class UserPriviledge {
  roles: number[];
  qnumber: string;
  validity: Validity;
}

export class RolePermissionInput {
  roleId: number;
  permissions: number[];
}

export class UserRole {
  roleId: number;
  qnumber: string;
  validity: DateRangeInput;
}

export class UserRoles {
  QNumber: string;
  plant: string;
  roleName: string;
  validity: DateRangeInput;
}

export enum WorkArea {
  BMW = 'BMW',
  PRODUCTION_PARTNER = 'PRODUCTION_PARTNER'
}

export class UserRowData {
  QNumber: string;
  plant: string;
}

export class ApprovedClients {
  constructor(public name: string, public value: string) {
  }
}
