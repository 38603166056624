import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {OrderGridColumns} from "./order-grid.columns";
import {OrdersViewModel} from "../model/OrdersViewModel";
import {Router} from "@angular/router";
import {GridSettingsStore} from "../../../helper/grid.settings.store";
import {TranslateOrderManagementEnums} from "../service/order.management.enum.translations";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Component({
  selector: 'app-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.scss']
})
export class OrderGridComponent extends GridSettingsStore implements OnInit {

  @Input() rowData: any;
  @Input() groupByColumns: string[];
  @Input() hideColumns: string[];
  @Input() editableColumns: string[];
  @Input() hideBottomEditBar: boolean = false;
  @Input() showSideBar: boolean = true;
  @Input() loadingScreen: boolean = false;
  @Input() disableEditButton: boolean = false;

  @Output() onAllowEdit = new EventEmitter<string>();
  @Output() updateOrder = new EventEmitter<OrdersViewModel[]>();
  @Output() doubleClick = new EventEmitter<OrdersViewModel>();

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  selectedOrderNumber: string;
  approveRejectButtonStatus = false;
  rowSelection;
  userCollapseTriggered = false;
  paginationPageSize: any;
  orderManagementEnums: TranslateOrderManagementEnums;

  constructor(private translate: TranslateService, private router: Router) {
    super();
    this.orderManagementEnums = new TranslateOrderManagementEnums();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
      this.translateInGridEnums();
    });
    this.gridColumns = new OrderGridColumns(this.translate);
    this.translateInGridEnums(true);
  }

  ngOnInit() {
    this.gridColumns.hideColumns(this.hideColumns);
    this.rowSelection = 'single';
    this.paginationPageSize = 100;
    this.setGridOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onEnableEditColumn(): void {
    this.onAllowEdit.emit(this.selectedOrderNumber);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //restore grid
    let columnState = this.getGridState(this.router.url);
    if ( columnState != undefined ) {
      this.gridColumnApi.setColumnState(columnState);
      this.gridColumnApi.setColumnGroupState(columnState);
    }
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'fromDate', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
    setTimeout(() => {
      this.expandAfterRedraw();
    }, 0);
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
      if (this.gridApi.getDisplayedRowCount() == 0) {
        this.gridApi.showNoRowsOverlay();
      } else if (!this.filtering) {
        this.gridApi.showLoadingOverlay();
      }
    }

    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.translateInGridEnums(true);
      this.gridApi.hideOverlay();
      //Should collapse view work if there are large numbers of pending approvals
      if (!this.userCollapseTriggered) {
        setTimeout(() => {
          this.expandAfterRedraw();
        }, 50);
      }
    }
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums(refreshFromDefault: boolean = null) {
    if (refreshFromDefault && this.translate.currentLang === 'de') {
      this.translateRowData(this.getAllRows(), 'de');
    }
    else if (this.translate.currentLang !== SystemGlobalCache.currentLang()) {
      this.rowData = this.translateRowData(this.getAllRows(), this.translate.currentLang);
    }
  }

  translateRowData(rows: any[], lang: string = 'en'): any[] {
    if (rows?.length === 0) { return; }
    let pushRowData = [];
    let refreshHeaders = false;
    rows.forEach(row => {
      refreshHeaders = true;
      if (row !== undefined) {
        row.orderState = this.orderManagementEnums.translate(lang, row.orderState);
        row.quantityUnit = this.orderManagementEnums.translate(lang, row.quantityUnit);
        pushRowData.push(row);
      }
    });
    return pushRowData;
  }

  getAllRows() {
    let rowData = [];
    if (this.gridApi !== undefined) {
      this.gridApi.forEachNode(node => rowData.push(node.data));
    }
    return rowData;
  }

  toggleSideBar(): any {
     if (this.showSideBar) {
       return  {
         toolPanels: [
           {
             id: 'columns',
             labelDefault: 'Columns',
             labelKey: 'columns',
             iconKey: 'columns',
             toolPanel: 'agColumnsToolPanel'
           },
           {
             id: 'filters',
             labelDefault: 'Filters',
             labelKey: 'filters',
             iconKey: 'filter',
             toolPanel: 'agFiltersToolPanel'
           }
         ],
         defaultToolPanel: undefined
       };
     }
     return null;
  }

  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'single',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping',
        pagination: true
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }

  onRowDoubleClicked(params: any) {
    this.doubleClick.emit(params.data);
  }

  expandAfterRedraw() {
    this.gridApi.forEachNode((node) => {
      if (node?.group) {
        node.setExpanded(true)
      }
    })
  }

  onRowSelected(params: any) {
    if ( params.node.selected ) {
      this.selectedOrderNumber = params.data.orderNo;
      this.disableEditButton = !(params.data.relatedOrders == undefined || params.data.relatedOrders.length == 0);
    }
  }

  rowGroupCollapse(params: any) {
    this.userCollapseTriggered = true;
  }

  onRefreshGridData(params: any) {
    this.userCollapseTriggered = false;
  }

  onSaveGridState(params: any) {
    let columnState = this.gridColumnApi?.getColumnState();
    this.saveGridState(this.router.url,columnState);
    this.gridApi.expandAll();
  }
}
