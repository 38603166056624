import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-continuos-price-overview',
  templateUrl: './continuos-price-overview.component.html',
  styleUrls: ['./continuos-price-overview.component.scss']
})
export class ContinuosPriceOverviewComponent implements OnInit {


  constructor() {
  }

  ngOnInit(): void {

  }

}
