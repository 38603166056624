import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {OperationsDisplayGridComponent} from './operations-display-grid/operations-display-grid.component';
import {TranslateService} from '@ngx-translate/core';
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {OperationService} from "../service/operation.service";
import {Operation, OperationSummary} from '../model/operations.model';
import moment from "moment";


@Component({
  selector: 'app-operations-display',
  templateUrl: './operations-display.component.html',
  styleUrls: ['./operations-display.component.scss']
})
export class OperationsDisplayComponent implements OnInit{
  @ViewChild (OperationsDisplayGridComponent) gridComponent;
  @Output() onOperationDetailsClick = new EventEmitter<any>();
  @Output() onOperationDisplayClick = new EventEmitter<any>();
  rowData: any;
  hideGrid:boolean = false;
  errorMessage: string;
  showConfirm = false;
  showDeleteConfirm = false;
  showDeletePrompt = false;
  showPrompt = false;
  dialogText: string;
  dialogTitle: string;
  message;
  showNewOperation = false;
  showEditOperation = false;
  showCloneOperation = false;
  processing = false;
  selectedOperation: OperationSummary;
  hasCaptureRole: boolean = false;

  constructor(private operationService: OperationService, public translate: TranslateService, private plantService: PlantChangeService) {
    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.message = undefined;
    this.errorMessage = undefined;
    this.rowData = undefined;
    this.processing = true;
    this.hasCaptureRole = this.operationService.canCapture();
    this.operationService.allOperations()
      .subscribe((result) => {
        this.processing = false;
        this.rowData = result.data.operations
          .sort((a, b) => this.workflowStatusOrdinal(b.workFlowStatus) === this.workflowStatusOrdinal(a.workFlowStatus)
            ? moment(b.statusDate).toDate().getTime() - moment(a.statusDate).toDate().getTime()
            : this.workflowStatusOrdinal(a.workFlowStatus) - this.workflowStatusOrdinal(b.workFlowStatus));
        }, (error) => {
        this.processing = false;
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  workflowStatusOrdinal(workflowStatus: any): number {
    switch (workflowStatus) {
      case 'IN_PROGRESS':
        return 1;
      case 'IN_REVIEW':
        return 2;
      case 'IN_RELEASE':
        return 3;
      case 'RESET':
        return 4;
      case 'RELEASED':
        return 5;
      case 'REJECTED':
        return 6;
      case 'CANCELLED':
        return 7;
    }
  }

  refreshData(){
    this.rowData = undefined;
    this.loadData();
  }

  cloneOperation = () => {
    this.showCloneOperation = true;
    this.showEditOperation = false;
    this.hideGrid = true;
  }

  cancelOperationAction = () => {
    this.showNewOperation = false;
    this.showCloneOperation = false;
    this.showEditOperation = false;
    this.hideGrid = false;
    this.loadData();
  }

  newOperation(){
    this.message = undefined;
    this.showNewOperation = true;
    this.hideGrid = true;
  }

  editOperation(event) {
    this.selectedOperation = event;
    this.showEditOperation = true;
    this.hideGrid = true;
  }

  deleteOperation() {
    this.showDeleteConfirm = false;
    this.processing = true;
    this.operationService.deleteOperation(this.selectedOperation.internalIdentifier)
      .subscribe((result) => {
        this.processing = false;
        this.selectedOperation = undefined;
        this.message = this.translate.instant('messages.delete-success');
        this.refreshData();
      }, (error) => {
        this.processing = false;
        this.selectedOperation = undefined;
        this.message = this.translate.instant('messages.delete-failure');
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  public submit = (success) => {
    if (success && success !== 'false') {
      this.refreshData();
      this.showNewOperation = false;
    } else {
      this.message = this.translate.instant('shared.messages.opsnotsaved');  //Operation could not be saved
    }
  }

  selectOperationDetails(operation: OperationSummary): void {
    this.onOperationDetailsClick.emit(operation);
  }

  setSelectedOperation($event) {
    this.selectedOperation = $event;
  }

  getDeleteConfirmation(event) {
    if (!event) {
      this.dialogTitle = this.translate.instant('messages.select-operation'); //Nothing is selected to open.
      this.showDeletePrompt = true;
    } else {
      this.selectedOperation = event;
      this.dialogTitle = this.translate.instant('messages.confirm-deletion-faas2.1'); //Nothing is selected to open.
      this.showDeleteConfirm = true;
    }
  }

  openOperation(event) {
    this.message = undefined;
    if (!event.internalIdentifier) {
      this.dialogTitle = this.translate.instant('messages.noseltoopen'); //Nothing is selected to open.
      this.dialogText = this.translate.instant('messages.selecttoopen');  //Please select an operation to open.
      this.showPrompt = true;
    } else {
      this.selectOperationDetails(event);
    }
  }
}
