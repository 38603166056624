import gql from "graphql-tag";
import {OrderState, Plant} from "../model/order.model";

export const UPDATE_ORDER = gql`
  mutation ($orderInput: OrderChangeInputInput!) {
    updateOrder(orderInput: $orderInput)
  }
`;

export const APPROVE_ORDER = gql`
  mutation ($orderInput: OrderChangeInputInput!) {
    approveOrderChange(orderInput: $orderInput)
  }
`;


export const REJECT_ORDER = gql`
  mutation ($orderInput: OrderChangeInputInput!) {
    rejectOrderChange(orderInput: $orderInput)
  }
`;

export class OrderChangeInputInput  {
  material?: MaterialInput;
  orderNo?: string;
  orderState: OrderState;
  internalIdentifier?: number;
  pricingPeriods: PricingPeriodChangeInputInput[];
  changeDate?: string;
}

export class MaterialInput  {
  snr?: string;
  plant?: string;
  ai?: number;
}

export class PricingPeriodChangeInputInput  {
  internalIdentifier?: number;
  validity: DateRangeInput;
}

export class DateRangeInput  {
  toDate?: string;
  fromDate?: string;
}
