import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IBreadcrumb {
  label: string;
  url: string;
}

/**
 * BreadcrumbsService is a state machine that exposes a $breadcrumbs observable
 * so that app components can set their own breadcrumbs dynamically.
 *
 * @export
 * BreadcrumbsService
 */
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  $breadcrumbs = new BehaviorSubject<IBreadcrumb[]>([]);
  constructor() { }

  update(breadcrumbs: IBreadcrumb[]) {
    this.$breadcrumbs.next(breadcrumbs);
  }

  reset() {
    this.$breadcrumbs.next([]);
  }

  asObservable() {
    return this.$breadcrumbs.asObservable();
  }
}
