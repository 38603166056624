import gql from "graphql-tag";
import {Product, ProductConfiguration, SpecialEquipment, Type} from "../model/product.model";


export const PRODUCTS_QUERY = gql`
  query productsByPlant($plant: Plant!) {
    productsByPlant(plant:$plant) {
      internalIdentifier
      validity {
        fromDate
        toDate
      }
      derivative {
        internalIdentifier
        code
        derivativeImportStatus
      }
   }
 }`;

export const DERIVATIVE_TYPE_QUERY = gql`
  query productConfigurationTypesByDerivatives($plant: Plant!, $derivative: [String]!) {
  productConfigurationTypesByDerivatives(plant: $plant, derivative: $derivative) {
    internalIdentifier
    code
    typeImportStatus
    validity{
      fromDate
      toDate
    }
  }
 }`;

export const TYPE_SPECIAL_EQUIPMENT_QUERY = gql`
  query productConfigurationSpecialEquipmentByTypes($plant: Plant!, $type: [String]!) {
  productConfigurationSpecialEquipmentByTypes(plant: $plant, type: $type) {
    internalIdentifier
    code
    specialEquipmentImportStatus
  }
 }`;

export const PRODUCT_CONFIG_STATUSES_QUERY = gql`
  query productConfigurationStatuses($plant: Plant!) {
  productConfigurationStatuses(plant: $plant) {
    productionTypes {
    productionTypeCode
    productionTypeImportStatus
    }
    productDataStatuses {
      productDataImportStatus
      productDataStatusCode
      productDataStatusCodeNo
    }
    buildTypes {
      buildTypeCode
      buildTypeImportStatus
    }
  }
 }`;

export interface ProductsQueryResponse {
  productsByPlant: Product[];
}

export interface AllocatedProductsQueryResponse {
  allocatedProductsByPlant: Product[];
}

export interface UnAllocatedProductsQueryResponse {
  productsExcludingAllocatedBy: Product[];
}

export interface TypesQueryResponse {
  productConfigurationTypesByDerivatives: Type[];
}

export interface SpecialEquipmentQueryResponse {
  productConfigurationSpecialEquipmentByTypes: SpecialEquipment[];
}

export interface ProductConfigStatusesResponse {
  productConfigurationStatuses: ProductConfiguration;
}
