<bmw-fieldset opened="true" data-testid="fieldset-price-header">
  <bmw-fieldset-header>{{ 'menu.pricing.continuous-prices.overview.material' | translate }}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <h5 data-testid="text-priceStates" class="bmw-h5 bmw__margin-top__m bmw__margin-bottom__m">{{ 'menu.pricing.continuous-prices.overview.price-state' | translate }}: {{priceStates}}</h5>
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell--span-12">
        <div class="bmw__grid__inner">
          <div class="bmw__grid__cell--span-5">
            <p data-testid="text-snr" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.snr' | translate }}:</b> {{price.material.snr | uppercase }}-{{price.material.ai | aiToString }}</p>
            <p data-testid="text-currency" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.currency' | translate }}:</b> {{price?.currency.currency}}</p>
            <p data-testid="text-priceUnit" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.price-unit' | translate }}:</b> {{priceUnit}}</p>
          </div>
          <div class="bmw__grid__cell--span-6">
            <p data-testid="text-fromDate" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.valid-from' | translate }}:</b> {{fromDate}}</p>
            <p data-testid="text-nettPrice" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.nett-price' | translate }}:</b> {{price.nettPrice.value | moneyFormatter }}</p>
          </div>
        </div>
      </div>
    </div>
  </bmw-fieldset-container>
</bmw-fieldset>
<div class="bmw__margin-top__s" *ngIf="price.priceComponents?.length > 0">
  <app-price-component [priceComponents]="price.priceComponents"></app-price-component>
</div>

