import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Operation, OperationChangeInput, OperationSummary} from "../model/operations.model";
import {OperationService} from "../service/operation.service";
import {format} from "date-fns";

@Component({
  selector: 'app-clone-operation',
  templateUrl: './clone-operation.component.html',
  styleUrls: ['./clone-operation.component.scss']
})
export class CloneOperationComponent implements OnInit {

  operationForm: FormGroup;
  loadingScreen: boolean = false;
  errorMessage: string;
  minDate: Date = new Date();

  @Input() type: string;
  @Input() public cancelFn;
  @Input() public getOperationFn;
  @Input() public selectedOperation: Operation;
  @Output() public openOperationDetails = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private service: OperationService) {
    this.selectedOperation = new Operation();
  }

  ngOnInit(): void {
    this.operationForm = this.formBuilder.group({
      operationName: ['', Validators.required ],
      validFrom: [''],
      comment: ['']
    });
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  submit() {
    this.loadingScreen = true;
    try {
      this.operationForm.markAllAsTouched();
      let operation = this.getOperationModel();
      this.service.cloneOperation(operation).subscribe(({data}) => {
        this.service.getOperationDetailByName(operation.name).subscribe((operationDetails) => {
          operation = operationDetails.data.operationByName;
          operation.cloned = true;
          this.openOperationDetails.emit(operation);
          this.loadingScreen = false;
          this.cancel();
        }, error => {
          this.loadingScreen = false;
          this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
        });
      }, error => {
        this.loadingScreen = false;
        this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
      });
    } catch (e) {
      this.errorMessage = e.toString();
      this.loadingScreen = false;
    }
  }

  cancel() {
    this.cancelFn();
  }

  getOperationModel() {
    if (this.operationForm.valid) {
      let model = new OperationChangeInput();
      model.internalIdentifier = this.selectedOperation.internalIdentifier;
      model.name = this.f.operationName.value;
      model.statusReason = this.f.comment.value;
      model.validFrom = format(this.f.validFrom.value, 'yyyy-MM-dd');
      return model;
    }
    return null;
  }

  get f() {
    return this.operationForm.controls;
  }

}
