import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService  {

  constructor() {}

  getNotificationMessages(){
    return [{
      "notificationDate": new Date(),
      "messageType": "Maintenance",
      "notificationPriority": "Low",
      "notificationMessage": "No Messages"
    },{
      "notificationDate": new Date(),
      "messageType": "Prices",
      "notificationPriority": "Low",
      "notificationMessage": "No Messages"
    }]
  }

}
