import {BasePrice, Material, Order, Validity} from "../../order/model/order.model";
type Nullable<T> = T | null;


export class Weight {
  importDate: Date;
  internalIdentifier: number;
  orderNo: string;
  value: number;
  weightDate: string;
  weightType: string;
}

export class ExchangeRate {
  currency: Currency;
  exchangeRateType: string;
  month: number;
  value: number;
  year: number;
}

export class ExchangeRateViewModel {
  orderNo: string;
  currency: string;
  exchangeRateType: string;
  month: number;
  value: number;
  year: number;
}

export interface PriceComponentData {
  weightFactor: number;
  order: Order;
  exchangeRate?: ExchangeRate;
  weight: Weight;
}

export class Price {
  internalIdentifier: number;
  material: Material;
  basePrice: BasePrice;
  nettPrice: BasePrice;
  calculatedOn: Date;
  currency: Currency;
  orderCurrency: Currency;
  priceType: PriceType;
  priceState: string[];
  surcharges: Surcharges;
  validity: Validity;
  priceComponents: PriceComponentData[];
  priceWorkflowState: PriceWorkflowState;
  user: string;
  reason: string;
  statusDate: Date;
  priceUnit: string;
}


export class PriceDetail {
  internalIdentifier: number;
  snr: string;
  ai: number;
  priceUnit: string;
  quantityUnit: string;
  validFrom: string;
  currency: string;
  priceType: string;
  priceState: string;
  basePrice: number;
  nettPrice: number;
  oldNettPrice: number;
  priceWorkflowState: PriceWorkflowState;
  orderNo: string;
  orderVersion: number;
}

export interface PriceData {
  price: Price;
}

export interface Surcharges {
  currency: Currency;
  value: number;
}

export interface Currency {
  currency: string;
  currencyType: string;
  displayFormat?: any;
  internalIdentifier: number;
  plant: string;
  scale: number;
}

export class PriceSearchRequest {
  snr: Nullable<string>;
  ai: Nullable<number>;
  plant: string;
  knowledgeDate: string;
  isError: boolean;
  error: string;
}
export enum PriceWorkflowState {
  APPROVED = 'APPROVED',
  NEW = 'NEW',
  REJECTED = 'REJECTED',
  TRANSFERRED = 'TRANSFERRED',
  UPDATED = 'UPDATED'
}

export enum PriceType {
  CONTINUOUS = 'CONTINUOUS',
  MONTHLY_FREEZE = 'MONTHLY_FREEZE',
  DAILY_FREEZE = 'DAILY_FREEZE',
  MANUAL = 'MANUAL'
}
