import {
  BuildType,
  BuildTypeCode,
  DateRange,
  Derivative,
  ImportStatus,
  Product,
  ProductConfigurationChangeInput,
  ProductConfigurationViewModel,
  ProductDataStatus,
  ProductionType,
  ProductionTypeCode,
  SpecialEquipment,
  Type
} from "../model/product.model";
import {Plant} from "../../order/model/order.model";
import {format} from "date-fns";

export class ProductsTransformer {

    getDeselectedProductConfigChangeInput(productConfigViewModel: ProductConfigurationViewModel) {
      let productConfigChangeInput= new ProductConfigurationChangeInput();
      productConfigChangeInput.inactivate = false;

      let typeList = [];
      productConfigViewModel.selectedTypes.forEach((typeCode: string) => {
        let selectedType: Type = productConfigViewModel.typeList.find((type: Type) => {
          return type.code === typeCode;
        });
        let type = new Type();
        type.code = selectedType.code;
        type.typeImportStatus = ImportStatus.ACTIVE;
        typeList.push(type);
      });
      productConfigChangeInput.types = typeList;

      let specialEquipmentList = [];
      productConfigViewModel.selectedSpecialEquipments.forEach((specialEquipmentCode: string) => {
        let selectedSpecialEquipment: SpecialEquipment = productConfigViewModel.specialEquipmentList.find((specialEquipment: SpecialEquipment) => {
          return specialEquipment.code === specialEquipmentCode;
        });

        let specialEquip = new SpecialEquipment();
        specialEquip.code = selectedSpecialEquipment.code;
        specialEquip.specialEquipmentImportStatus = ImportStatus.ACTIVE;
        specialEquipmentList.push(specialEquip);
      });
      productConfigChangeInput.specialEquipments = specialEquipmentList;

      return productConfigChangeInput;
    }

    getProductConfigChangeInput(productConfigViewModel: ProductConfigurationViewModel) {

    let productConfigChangeInput= new ProductConfigurationChangeInput();
    productConfigChangeInput.inactivate = false;

    let plant = Plant[window.sessionStorage.client];
    productConfigChangeInput.plant = plant

    let validity = new DateRange();
    validity.fromDate = format(productConfigViewModel.datesSelected[0], 'yyyy-MM-dd');
    validity.toDate = format(productConfigViewModel.datesSelected[1], 'yyyy-MM-dd');
    productConfigChangeInput.validity = validity;

    let derivativeList = [];
    productConfigViewModel.selectedDerivatives.forEach((derivativeCode: string) => {
      let product : Product = productConfigViewModel.derivativeList.find((product: Product) => {
        return product.derivative.code === derivativeCode;
      });
      let derivative = new Derivative();
      derivative.code = product.derivative.code;
      derivative.derivativeImportStatus = ImportStatus.ACTIVE;
      derivative.plant = plant;
      derivativeList.push(derivative);
    });
    productConfigChangeInput.derivatives = derivativeList;

    let typeList = [];
    productConfigViewModel.selectedTypes.forEach((typeCode: string) => {
      let selectedType: Type = productConfigViewModel.typeList.find((type: Type) => {
        return type.code === typeCode;
      });
      let type = new Type();
      type.code = selectedType.code;
      type.typeImportStatus = ImportStatus.ACTIVE;
      typeList.push(type);
    });
    productConfigChangeInput.types = typeList;

    let specialEquipmentList = [];
    productConfigViewModel.selectedSpecialEquipments.forEach((specialEquipmentCode: string) => {
      let selectedSpecialEquipment: SpecialEquipment = productConfigViewModel.specialEquipmentList.find((specialEquipment: SpecialEquipment) => {
        return specialEquipment.code === specialEquipmentCode;
      });

      let specialEquip = new SpecialEquipment();
      specialEquip.code = selectedSpecialEquipment.code;
      specialEquip.specialEquipmentImportStatus = ImportStatus.ACTIVE;
      specialEquipmentList.push(specialEquip);
    });
    productConfigChangeInput.specialEquipments = specialEquipmentList;

    let productDataStatusList = [];
    productConfigViewModel.selectedProductDataStatuses.forEach((productDataStatusCode: string) => {
      let selectedProductDataStatus: ProductDataStatus = productConfigViewModel.productDataStatusList.find((productDataStatus: ProductDataStatus) => {
        return productDataStatus.productDataStatusCodeNo == +productDataStatusCode;
      });
      let prodDataStatus = new ProductDataStatus();
      prodDataStatus.productDataStatusCodeNo = selectedProductDataStatus.productDataStatusCodeNo;
      prodDataStatus.productDataStatusCode = selectedProductDataStatus.productDataStatusCode;
      prodDataStatus.productDataImportStatus = ImportStatus.ACTIVE;
      productDataStatusList.push(prodDataStatus);
    })
    productConfigChangeInput.productDataStatuses = productDataStatusList;

    let productionTypesList = [];
    productConfigViewModel.selectedProductionTypes.forEach((productionTypeCode: string) => {
      let selectedProductionType: ProductionType = productConfigViewModel.productionTypeList.find((productionType: ProductionType) => {
        return productionType.productionTypeCode === ProductionTypeCode[productionTypeCode];
      });
      let prodType = new ProductionType();
      prodType.productionTypeCode = selectedProductionType.productionTypeCode;
      prodType.productionTypeImportStatus = ImportStatus.ACTIVE;
      productionTypesList.push(prodType);
    });
    productConfigChangeInput.productionTypes = productionTypesList;

    let buildTypeList = [];
    productConfigViewModel.selectedBuildTypes.forEach((buildTypeCode: string) => {
      let selectedBuildType: BuildType = productConfigViewModel.buildTypeList.find((buildType: BuildType) => {
        return buildType.buildTypeCode === BuildTypeCode[buildTypeCode];
      });
      let newBuildType = new BuildType();
      newBuildType.buildTypeCode = selectedBuildType.buildTypeCode;
      newBuildType.buildTypeImportStatus = ImportStatus.ACTIVE;
      buildTypeList.push(newBuildType);
    });
    productConfigChangeInput.buildTypes = buildTypeList;

    return productConfigChangeInput;
  }
}
