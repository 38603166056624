import {ImprintComponent} from './components/imprint/imprint.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OrderHistoryComponent} from './components/order/order-history/order-history.component';
import {OrderManagementComponent} from "./components/order/order-management/order-management.component";
import {WeightingsComponent} from "./components/weightings/weightings.component";
import {OrderLatestEditableComponent} from "./components/order/order-latest/order-latest-editable.component";
import { ExchangeRatesManagementComponent } from "./components/exchange-rates/exchange-rates-management/exchange-rates-management.component";
import { ExchangeRatesOverviewComponent } from "./components/exchange-rates/exchange-rates-overview/exchange-rates-overview.component";
import { ExchangeRatesReleaseComponent } from "./components/exchange-rates/exchange-rates-release/exchange-rates-release.component";
import {ContinuosPriceOverviewComponent} from "./components/price/continous-price/continuos-price-overview/continuos-price-overview.component";
import {CurrencyOverviewComponent} from "./components/currency/currency-overview/currency-overview.component";
import {ManualPricesComponent} from "./components/price/manual-prices/manual-prices.component";
import {FreezePriceManagementComponent} from "./components/price/freeze-price/freeze-price-management/freeze-price-management.component";
import {ProductMasterDataComponent} from "./components/product/product-master-data/product-master-data.component";
import {UserManagementComponent} from "./components/users/user-management/user-management.component";
import {ManufacturingComponent} from "./components/manufacturing/manufacturing.component";
import {HomeComponent} from "./components/home/home.component";
import { ManufacturingReportsComponent } from './components/manufacturing/manufacturing-reports/manufacturing-reports.component';
import {PathResolveService} from "./services/path-resolve.service";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {AggregationHeaderComponent} from "./components/aggregation/aggregation-header/aggregation-header.component";
import {VehicleComponent} from "./components/vehicle/vehicle.component";
import {QuicksightReportsParentComponent} from "./components/quicksight-reports/quicksight-reports-parent/quicksight-reports-parent.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: '404',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'basic-table-template',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'period-end-closing',
    component: AggregationHeaderComponent,
    pathMatch: 'full',
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'order-history',
    component: OrderHistoryComponent
  },
  {
    path: 'order-management',
    component: OrderManagementComponent
  },
  {
    path: 'order-management/:snr/:orderNumber',
    component: OrderManagementComponent
  },
  {
    path: 'weightings',
    component: WeightingsComponent
  },
  {
    path: 'latest-order',
    component: OrderLatestEditableComponent
  },
  {
    path: 'exchange-rates-management',
    component: ExchangeRatesManagementComponent
  },
  {
    path: 'exchange-rates-overview',
    component: ExchangeRatesOverviewComponent
  },
  {
    path: 'exchange-rates-release',
    component: ExchangeRatesReleaseComponent
  },
  {
    path: 'continuous-price',
    component: ContinuosPriceOverviewComponent
  },
  {
    path: 'continuous-price/:snr',
    component: ContinuosPriceOverviewComponent
  },
  {
    path: 'currency',
    component: CurrencyOverviewComponent
  },
  {
    path: 'manual-prices',
    component: ManualPricesComponent
  },
  {
    path: 'freeze-prices',
    component: FreezePriceManagementComponent
  },
  {
    path: 'prod-master-data',
    component: ProductMasterDataComponent
  },
  {
    path: 'manufacturing',
    component: ManufacturingComponent
  },
  {
    path: 'vehicle',
    component: VehicleComponent
  },
  {
    path: 'manufacturing-reports',
    component: ManufacturingReportsComponent
  },
  {
    path: 'budget-planning',
    loadChildren: () => import('./components/budget-planning/budget-planning.module').then(m => m.BudgetPlanningModule)
  },
  {
    path: 'prod-master-data-ned-msf',
    loadChildren: () => import('./components/product-master-data/product-master-data.module').then(m => m.ProductMasterDataModule)
  },
  {
    path: 'user-management',
    component: UserManagementComponent
  },
  {
    path: 'quicksight-reports',
    component: QuicksightReportsParentComponent
  },
  {
    path: '**',
    resolve: {
      path: PathResolveService
    },
    component: NotFoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

