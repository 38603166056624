<p class="bmw-large-text bmw-bold-text bmw__margin-top__xs"><b>{{ 'menu.manufacturing-cost.tabs.rules-history.title' | translate }}</b></p>
<bmw-label>{{ 'menu.manufacturing-cost.tabs.rules-history.description' | translate }}</bmw-label>
<br>
<bmw-ag-grid-theme>
  <ag-grid-angular data-testid="rules-history-grid" *ngIf="rowData"
                   style="height: 500px; overflow: scroll"
                   class="zebra"
                   [columnDefs]="runtimeCompilerData.columns"
                   [rowData]="rowData"
                   [pagination]="true"
                   [rowMultiSelectWithClick]="false"
                   [suppressRowClickSelection]="true"
                   [gridOptions]="runtimeCompilerData.gridOptions"
                   [defaultColDef]="runtimeCompilerData.defaultColDef"
                   [sideBar]="runtimeCompilerData.sideBar"
                   [statusBar]="runtimeCompilerData.statusBar"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRefreshGridData($event)"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (modelUpdated)="onModelUpdated($event)"
                   [groupDefaultExpanded]="0"
                   [animateRows]="true"
                   [getRowStyle]="getRowStyle"
  >
  </ag-grid-angular>
</bmw-ag-grid-theme>
