import gql from "graphql-tag";

export const UPLOAD_FILE_FOR_OPERATION = gql `
	mutation ($operationInput: OperationChangeInputInput!) {
	 uploadFileForOperation(operationInput: $operationInput)
	}
 `;

export const UPLOAD_FILE_FOR_COST_ALLOCATIONS = gql `
	mutation ($operationInput: OperationChangeInputInput!) {
	 uploadFileForCostAllocations(operationInput: $operationInput)
	}
 `;

export const DELETE_FILE_FOR_OPERATION = gql `
	mutation ($operationInternalIdentifier: Long!, $fileInternalIdentifier: Long!) {
	 deleteFileForOperationBy(operationInternalIdentifier: $operationInternalIdentifier, fileInternalIdentifier: $fileInternalIdentifier)
	}
  `;

export const ALLOCATE_MANUFACTURING_COST_TO_OPERATION = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    allocateManufacturingCostToOperation(operationInput: $operationInput)
  }
  `;

export const SUBMIT_OPERATION_FOR_REVIEW = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForInReview(operationInput: $operationInput)
  }
`;

export const SUBMIT_OPERATION_FOR_IN_RELEASE = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForInRelease(operationInput: $operationInput)
  }
  `;

export const SUBMIT_OPERATION_FOR_RELEASE = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForRelease(operationInput: $operationInput)
  }
`;

export const SUBMIT_OPERATION_FOR_DECLINE = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForDecline(operationInput: $operationInput)
  }
`;

export const SUBMIT_OPERATION_FOR_RESET = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForReset(operationInput: $operationInput)
  }
`;

export const SUBMIT_OPERATION_FOR_CANCEL = gql `
  mutation ($operationInput: OperationChangeInputInput!) {
    submitOperationForCancel(operationInput: $operationInput)
  }
`;

export const REMOVE_MANUFACTURING_COSTS = gql `
  mutation ($manufacturingCostChangeInput: ManufacturingCostChangeInputInput!) {
    removeManufacturingCosts(manufacturingCostChangeInput: $manufacturingCostChangeInput)
  }
`;
