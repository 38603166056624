<bmw-ag-grid-theme>
  <div *ngIf="showNewOperation" class="bmw__margin-top__xs">
    <app-new-operation (openOperationDetails)="openOperation($event)" [type]="'new'" [cancelFn]="cancelOperationAction" [submitFn]="submit"></app-new-operation>
  </div>
  <div *ngIf="showCloneOperation" class="bmw__margin-top__xs">
    <app-clone-operation (openOperationDetails)="openOperation($event)" [selectedOperation]="selectedOperation" [type]="'clone'" [cancelFn]="cancelOperationAction" ></app-clone-operation>
  </div>
  <div *ngIf="showEditOperation" class="bmw__margin-top__xs">
    <app-edit-operation [type]="'edit'" [cancelFn]="cancelOperationAction" [submitFn]="submit" [operation]="selectedOperation" ></app-edit-operation>
  </div>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="processing"></bmw-progress-circle>
      <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
        <bmw-inline-message type="info">{{message}}</bmw-inline-message>
      </div>
      <div class="bmw__margin-top__xs" [hidden]="hideGrid">
        <div data-testid="grid-shouldShowGrid" class="bmw__margin-top__xs" >
          <app-operations-display-grid data-testid="grid-operations"
          [rowData]="rowData"
          [hasCaptureRole]="hasCaptureRole"
          (operationSelected) = "setSelectedOperation($event)"
          (deleteOperation)="getDeleteConfirmation($event)"
          (editOperation)="editOperation($event)"
          (newOperation)="newOperation()"
          (openExistingOperation)="openOperation($event)"
          (cloneExistingOperation)="cloneOperation()"
        ></app-operations-display-grid>
        </div>
      </div>

</bmw-ag-grid-theme>

<bmw-dialog data-testid="dialog-delete"
    [type]="'warning'"
    [show]="showDeleteConfirm"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [secondaryLabel]="'Cancel'"
    [primaryDisabled]="false"
    [secondaryDisabled]="false"
    (primaryClick)="deleteOperation()"
    (secondaryClick)="showDeleteConfirm = false"
    (closeClick)="showDeleteConfirm = false">
    <p class="bmw-regular-text">{{ 'messages.confirm-deletion-text' | translate }}
    </p>
  </bmw-dialog>

<bmw-dialog data-testid="dialog-delete-nothing-selected"
  [type]="'warning'"
  [show]="showDeletePrompt"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (secondaryClick)="showDeletePrompt = false"
  (closeClick)="showDeletePrompt = false">
  <p class="bmw-regular-text">{{ 'messages.nothing-selected-to-delete' | translate }}
  </p>
</bmw-dialog>

<bmw-dialog data-testid="dialog-regular-text-1"
  [type]="'warning'"
  [show]="showConfirm"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (secondaryClick)="showConfirm = false"
  (closeClick)="showConfirm = false">
  <p class="bmw-regular-text">{{ dialogText }}
  </p>
</bmw-dialog>

  <bmw-dialog
    [type]="'info'"
    [show]="showPrompt"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [primaryDisabled]="false"
    (primaryClick)="showPrompt = false"
    (closeClick)="showPrompt = false"
  >
    <p class="bmw-regular-text">{{ dialogText }}</p>
  </bmw-dialog>
