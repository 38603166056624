import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {OperationService} from "../../service/operation.service";
import {TranslateService} from '@ngx-translate/core';
import {Plant} from 'src/app/components/order/model/order.model';
import {CostAllocationsGridComponent} from './cost-allocations-grid/cost-allocations-grid.component';
import {Operation, RoyaltiesIndicator} from "../../model/operations.model";
import * as _ from "lodash";
import {transferPrice} from "../../service/transfer-price-constants";
import {calculateProfit} from "../../service/transfer-price-calculator";

@Component({
  selector: 'app-cost-allocations',
  templateUrl: './cost-allocations.component.html',
  styleUrls: ['./cost-allocations.component.scss']
})
export class CostAllocationsComponent implements OnInit {
  errorMessage: string;
  warnMessage: string;
  new = false;
  @ViewChild (CostAllocationsGridComponent) gridComponent;

  @Input() selectedDetail: any[];
  @Input() operation: Operation;

  @Output() showAllocate = new EventEmitter<boolean>();

  map = new Map();
  rowData: any;
  showConfirm = false;
  dialogText: string;
  selectedTypes: any;
  uploadType: string;
  openAllocationGrid: boolean = false;
  processing = false;
  manufacturingCostUpdated = false;
  message = undefined;
  whenOperationIsUnderEvaluation: boolean = true;
  statusesAllowedForCapturer = ["IN_PROGRESS", "RESET", "DECLINED"];
  hasCapturerRole: boolean;
  showUploadDialog = false;
  dialogTitle: string;

  constructor(
    private operationService: OperationService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.message = undefined;
    this.toggleSaveButton();
    if(!this.selectedDetail || this.selectedDetail.length === 0){
      this.new = true;
    } else {
      this.loadData();
    }
  }

  loadData(){
    let plant = Plant[window.sessionStorage.client];
    let ids:number[] = [];
    let mappedData:any;

    this.selectedDetail.forEach(sd => {
      ids.push(sd.priceId);
    });

    this.selectedDetail = _.sortBy(this.selectedDetail, function(costAllocationSelection) {
      return [costAllocationSelection.derivate, costAllocationSelection.type];
    });

    this.processing = true;
    this.operationService.getAllocations(plant, ids).subscribe(allocations => {
      if(allocations){
        this.processing = true;
        allocations.data.manufacturingCostByIds = _.sortBy(allocations.data.manufacturingCostByIds, function(costAllocationRow) {
          return [costAllocationRow.derivativeCode, costAllocationRow.type.code];
        });
        allocations.data.manufacturingCostByIds.forEach(manufacturingCost => {
          let transferCategoryCost = manufacturingCost.transferPrice.transferCategoryCost;
          mappedData = this.operationService.mapAllocationData(transferCategoryCost, manufacturingCost.internalIdentifier);
          this.map.set(manufacturingCost.internalIdentifier,mappedData);  //can priceId be replaced with something else?

        });
        this.rowData = this.map.get(ids[0]);
        this.processing = false;
      } else {
        this.rowData = [...mappedData];
        this.processing = false;
      }
      this.openAllocationGrid = true;
      this.new = false;
    }, error => {
      this.processing = false;
      this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  onBack(showAllocate:boolean){
    this.selectedDetail = null;
    this.showAllocate.emit(showAllocate || this.manufacturingCostUpdated);
  }

  onSave(params){
    this.processing = true;
    this.operationService.saveManufacturingCosts(params).subscribe((result) => {
      this.processing = false;
      this.message = this.translate.instant('messages.allocation-save-succsess');
      this.onBack(true);
    }, (error) => {
      this.message = this.translate.instant('messages.allocation-save-failure');
      this.processing = false;
      this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      this.onBack(false);
    });
  }

  onUpload(params) {
    this.errorMessage = '';
    this.dialogTitle = this.translate.instant('messages.fileupload');
    this.dialogText = this.translate.instant('messages.browseupload');
    this.selectedTypes = [];
    this.selectedTypes = params;
    this.uploadType = 'type';
    this.showUploadDialog = true;
  }

  refresh(params)  {
    this.gridComponent.changeAllocation();
    this.showUploadDialog = false;
    this.rowData = params;
    this.manufacturingCostUpdated = true;
    this.processing = false;
  }

  cancelUploadAttachment() {
    this.showUploadDialog = false;
  }

  private toggleSaveButton() {
    this.whenOperationIsUnderEvaluation = !this.statusesAllowedForCapturer.includes(this.operation.workFlowStatus);
    this.hasCapturerRole = this.operationService.canCapture();
  }
}
