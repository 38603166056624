import { Component, OnInit } from '@angular/core';
import {PriceSearchRequest} from "../../model/continous.price.model";
import {FreezePriceService} from "../service/freeze.price.service";
import {ManualPricesTransformer} from "../../service/manual-prices-transformer";

@Component({
  selector: 'app-freeze-price-date-search',
  templateUrl: './freeze-price-date-search.component.html',
  styleUrls: ['./freeze-price-date-search.component.scss']
})
export class FreezePriceDateSearchComponent extends ManualPricesTransformer implements OnInit {
  loading: any;
  errorMessage: any;
  priceByDate: any;
  selectedPrice: any;
  hideOverviewGrid: boolean = true;
  hideColumns: string[];
  rowSelection: string;

  constructor(private service: FreezePriceService) {
    super();
    this.hideColumns = ["#"];
    this.rowSelection = 'single';
  }

  ngOnInit(): void {
  }

  search(request: PriceSearchRequest) {
    this.loading = true;
    this.errorMessage = null;
    this.priceByDate = undefined;

    if (!request.isError) {
      this.service.searchFreezePriceByDate(request).subscribe(({data, loading}) => {
        this.loading = false;
        this.priceByDate = this.toGridDto(data.freezePricesForDate);
      }, (error) => {
        this.loading = false;
        this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
      });
    } else {
      this.loading = false;
      this.errorMessage = request.error;
    }
  }

  onError(error: string) {
    this.errorMessage = error;
    this.hideOverviewGrid = true;
  }

  onCancelView() {
    this.hideOverviewGrid = true;
  }

  onViewSelectedPrice(id: any) {
    this.loading = true;
    this.service.getPriceById(id).subscribe(({data, loading}) => {
      this.loading = false;
      this.selectedPrice = data.priceByIdentifier;
    }, (error) => {
      this.loading = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  closePriceView() {
    this.loading = false;
    this.errorMessage = undefined;
    this.selectedPrice = undefined;
  }
}
