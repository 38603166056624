import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperationService} from "../service/operation.service";
import {Operation, OperationChangeInput, OperationFile} from '../model/operations.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit {
  @Input() showUpload: boolean;
  @Input() dialogTitle: string;
  @Input() dialogText: string;
  fileForm: FormGroup;
  @Output() uploadAttachment = new EventEmitter();
  @Output() cancelAttachment = new EventEmitter();
  @Input() operation;
  loadingScreen: boolean = false;
  files: any[] = [];
  message = undefined;
  processing: boolean;

  constructor(private operationService: OperationService, public translate: TranslateService) {
  }

  ngOnInit(): void {
    this.fileForm = new FormGroup({
      attachFile: new FormControl('', [Validators.required]),
    });
  }


  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  Base64ToFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, {type: mimeType});
        })
    );
  }


  public addAttachment(selectedFiles) {
    this.fileForm.markAllAsTouched();
    if (this.fileForm.valid) {
      this.processing = true;
      this.message = null;

      let fileInputs: OperationFile[] = [];
      let operationChangeInput = new OperationChangeInput();
      operationChangeInput.internalIdentifier = this.operation.internalIdentifier;

      if(!this.isValidFile()) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-error');
        return;
      }

      this.files.forEach(file => {
        let fileInput = new OperationFile();
        fileInput.name = file.name;
        this.getBase64(file).then(
          data => {
            fileInput.content = <string>data;
            fileInputs.push(fileInput);
            operationChangeInput.files = fileInputs;
            this.operationService.uploadFileForOperation(operationChangeInput).subscribe((response) => {
                this.showUpload = false;
                this.processing = false;
                this.fileForm.reset();
                this.files = null;
                this.uploadAttachment.emit();
              },
              (error) => {
                this.processing = false;
                console.error(JSON.stringify(error));
                this.message = this.translate.instant('messages.file-error');  //Invalid filetype. Allowed filetypes: xlsx,xls,doc,docx,pdf,csv,ser,txt
              });
          })
      })
    }
  }

  cancel() {
    this.message = undefined;
    this.fileForm.reset();
    this.files = null;
    this.cancelAttachment.emit();
  }

  onFileChange(event) {
    this.files=[];
    const reader = new FileReader();
    for (const file of event.target.files) {
      if (file.size <= 26214400) { // less than 25MB
        this.files.push(file);
      } else {
        this.message = this.translate.instant('messages.filesize');
      }
    }
  }

  isValidFile():boolean {
    if(this.files[0] && this.files[0].size == 0) {
      return false;
    }
    return true;
  }
}
