import {RuleHistory} from '../model/operations.model';

export class OperationRulesHistoryTransformer {
  constructor() {
  }

  transformRulesHistory(ruleHistory: RuleHistory) {
    return {
      derivativeCode: ruleHistory.derivativeCode,
      typeName: ruleHistory.typeName,
      validFrom: ruleHistory.validFrom,
      releaseDate: ruleHistory.releaseDate,
      operationId: ruleHistory.operationId,
      name: ruleHistory.operationName,
      typeCode: ruleHistory.modelTypeCode,
      specialEquipmentCode1: ruleHistory.specialEquipmentCode1,
      description1: ruleHistory.description1,
      specialEquipmentCode2: ruleHistory.specialEquipmentCode2,
      description2: ruleHistory.description2,
      status: ruleHistory.ruleStatus,
      amount: ruleHistory.amount.value
    };
  }
}
