import gql from "graphql-tag";

export const LOGGED_IN_USER_QUERY = gql`
  query currentLoggedInUserFullName{
      currentLoggedInUserFullName
  }
`;

export interface CurrentLoggedInUserFullName {
  currentLoggedInUserFullName: string;
}

