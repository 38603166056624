<div class="bmw__grid">
  <div *ngIf="!errorMessage" class="bmw__margin-top__xs" [hidden]="!flipScreenOnAddOrEdit">
      <bmw-ag-grid-theme>
        <app-users-overview-grid
          [rowData]="rowData"
          (addUser)="addNewUser($event)"
          (editUser)="editUser($event)"
        ></app-users-overview-grid>
      </bmw-ag-grid-theme>
  </div>
  <form [formGroup]="maintenanceForm" class="reversed"  [hidden]="flipScreenOnAddOrEdit">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-6-mobile bmw__grid__cell--span-12-tablet bmw__grid__cell--span-6 main-form form-template-grid-start">
        <bmw-progress-circle [fullScreen]="true" loadingLabel="Wait..." [show]="loadingScreen"></bmw-progress-circle>
        <div *ngIf="errorMessage" class="bmw__margin-top__xs">
          <bmw-inline-message type="error">
            {{errorMessage}}
          </bmw-inline-message>
        </div>
        <div *ngIf="this.selectedQNumber == null">
          <h4 class="bmw-h4">{{'menu.administration.roles.permissions.roles.grant-roles' | translate}}</h4>
        </div>
        <div *ngIf="this.selectedQNumber != null">
          <h4 class="bmw-h4">{{'menu.administration.roles.permissions.roles.modify-roles-for' | translate}}{{this.selectedQNumber}}</h4>
        </div>
        <div class="bmw__grid__inner bmw__margin-top__l">
          <div
            class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-4 main-form">
            <bmw-inputfield id="name" class="bmw__margin-top__xs">
              <bmw-label>Q Number*</bmw-label>
              <input
                bmwInput
                type="text"
                name="name"
                placeholder="E.g.: QX12345Y"
                formControlName="name"
                data-e2e="name"
                style="text-transform: uppercase;"
                [disabled]="disableOnEdit"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div
            class="bmw__grid__cell bmw__grid__cell--span-2-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
            <bmw-button button="secondary" type="submit" (clickEvent)="cancel()" style="margin-right: 5px;">
              {{ 'component.search-bar.cancel' | translate }}
            </bmw-button>
            <bmw-button button="primary" type="submit" (clickEvent)="save()" [disabled]="saveDisabledForNoRoles">
              {{ 'component.bottom-bar.save' | translate }}
            </bmw-button>
          </div>
        </div>
        <div class="bmw__margin-top__m">
          <div
            class="bmw__grid__cell bmw__grid__cell--span-6-mobile bmw__grid__cell--span-12-tablet bmw__grid__cell--span-6 main-form">
            <bmw-combo-box [placeholder]="'Please select'"
                           [type]="'MULTI'"
                           [(ngModel)]="selectedRoles"
                           required="true"
                           [ngModelOptions]="{standalone: true}">
              <bmw-label>Role</bmw-label>
              <bmw-combo-box-dropdown-item *ngFor="let d of roles" value="{{d.internalIdentifier}}"
                                           label="{{d.name}} - {{d.plant}}"></bmw-combo-box-dropdown-item>
            </bmw-combo-box>
          </div>
        </div>
        <div class="bmw__margin-top__m">
          <bmw-label for="validity-period">Valid From - Valid To*</bmw-label>
          <bmw-datepicker
            #datepicker
            [inputId]="'validity-period'"
            required="true"
            placeholder="DD.MM.YYYY"
            dateFormat="dd.mm.yy"
            showButtonBar="true"
            [(ngModel)]="period"
            showWeek="true"
            selectionMode="range"
            [ngModelOptions]="{standalone: true}"
            (keydown)="onKeyDown($event)">
          </bmw-datepicker>
        </div>
      </div>
    </div>
  </form>
</div>
