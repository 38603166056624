import {Component, OnInit} from '@angular/core';
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {ProductService} from "../service/product.service";
import {Subscription} from "rxjs";
import {
  BuildType,
  ImportStatus,
  Product,
  ProductConfigurationChangeInput,
  ProductConfigurationViewModel,
  ProductDataStatus, ProductDataStatusCode,
  ProductionType,
  SpecialEquipment,
  Type
} from "../model/product.model";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {parse} from 'date-fns';
import {OperationService} from "../../manufacturing/service/operation.service";
import {ManufacturingCostsHistoryTransformer} from "../../manufacturing/service/manufacturing-cost-history-transformer";


@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  styleUrls: ['./product-configuration.component.scss'],

})
export class ProductConfigurationComponent implements OnInit {

  configurationForm: FormGroup;
  private subscription: Subscription;
  datesSelected: {};
  selectedDerivatives: any;
  derivativeList: any [];
  selectedProductDataStatuses: any;
  productDataStatusList: any [];
  selectedTypes: any[];
  unselectedTypes: any[];
  typeList: any[];
  selectedProductionTypes: any;
  productionTypeList: any [];
  selectedSpecialEquipments: any;
  unselectedSpecialEquipments: any[];
  specialEquipmentList: any [];
  selectedBuildTypes: any;
  buildTypeList: any [];
  disableStartImportButton: boolean = false;
  noSeriesCalculationRole: boolean = false;
  showDeletePrompt: boolean = false;
  manufacturingCostsHistoryTransformer: ManufacturingCostsHistoryTransformer;

  loadingScreen = false;
  errorMessage: string;
  infoMessage: string;

  fromDate: Date;
  toDate: Date;
  validity = {
    inputModel: [
      this.fromDate,
      this.toDate
    ]
  };
  dialogTitle: any;


  constructor(private translate: TranslateService, private productService: ProductService, private plantService: PlantChangeService, private fb: FormBuilder, private operationService : OperationService) {

    this.manufacturingCostsHistoryTransformer = new ManufacturingCostsHistoryTransformer();
    this.subscription = this.plantService.onChange().subscribe(message => {
      if (message) {
        this.initialisePageValues()
      }
    });
  }

  ngOnInit(): void {
    this.initialiseFormComponents();
    this.initialisePageValues();
    this.noSeriesCalculationRole = !this.productService.hasSeriesCalculationRole();
  }

  initialisePageValues() {
    this.infoMessage = undefined;
    this.errorMessage = undefined;
    this.initialiseAllProductConfigTypes();
    this.loadDerivatives();
  }

  loadDerivatives() {
    this.loadingScreen = true;
    this.productService.getProductDerivatives()
      .subscribe(({data, loading}) => {
        this.derivativeList = data.productsByPlant;
        this.sortDerivatives(this.derivativeList);
        this.setSelectedDates();
        this.derivativeList.forEach((product: Product) =>{
          if (product.derivative.derivativeImportStatus === ImportStatus.ACTIVE){
            this.selectedDerivatives.push(product.derivative.code);
            this.configurationForm.get('selectedDerivatives').setErrors({'firstError': null});
            this.configurationForm.get('selectedDerivatives').updateValueAndValidity();
          }
        });
        this.synchroniseSelectedWithCheckBox(this.derivativeList, this.selectedDerivatives, "derivativeCheckbox");
        this.loadingScreen = false;
        this.loadProductConfigStatuses();
        this.loadTypesForDerivatives(this.selectedDerivatives);
      }, (error) => {
        this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
        this.loadingScreen = false;
      });
  }

  sortDerivatives(derivativeList: Product[]){
    derivativeList.sort(function(a, b) {
      if (a.derivative.code < b.derivative.code) {
        return -1;
      }
      if (a.derivative.code > b.derivative.code) {
        return 1;
      }
      return 0;
    });
  }

  setSelectedDates() {
    if (this.derivativeList.length > 0) {
      if (this.derivativeList[0].validity){
        if (this.derivativeList[0].validity.fromDate) {
          this.validity.inputModel[0] = parse(this.derivativeList[0].validity.fromDate, 'yyyy-MM-dd', new Date());
        }

        if (this.derivativeList[0].validity.toDate) {
          this.validity.inputModel[1] = parse(this.derivativeList[0].validity.toDate, 'yyyy-MM-dd', new Date());
        }
        this.datesSelected = this.validity.inputModel;
        this.configurationForm.get('timePeriod').setErrors({'firstError': null});
        this.configurationForm.get('timePeriod').updateValueAndValidity();
      }
    }
  }

  loadTypesForDerivatives(selectedDerivativeCodes) {
    if (selectedDerivativeCodes.length > 0) {
      this.loadingScreen = true;
      this.productService.typesForDerivative(selectedDerivativeCodes)
        .subscribe(({data, loading}) => {
          this.typeList = data.productConfigurationTypesByDerivatives;
          this.sortTypesForDerivatives(this.typeList);
          this.selectedTypes = [];
          this.typeList.forEach((type: Type) => {
            if (type.typeImportStatus === ImportStatus.ACTIVE) {
              this.selectedTypes.push(type.code);
            }
            this.configurationForm.get('selectedTypes').setErrors({'firstError': null});
            this.configurationForm.get('selectedTypes').updateValueAndValidity();
          });
          this.synchroniseSelectedWithCheckBox(this.typeList, this.selectedTypes, "typeCheckbox")
          this.loadSpecialEquipmentForTypes(this.selectedTypes);
          this.loadingScreen = false;

        }, (error) => {
          this.loadingScreen = false;
          this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
        });
    }
  }

  sortTypesForDerivatives(typeList: Type[]){
    typeList.sort(function(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  loadSpecialEquipmentForTypes(selectedTypes) {
    if (selectedTypes.length > 0) {
      this.loadingScreen = true;
      this.productService.specialEquipmentForTypes(selectedTypes)
        .subscribe(({data, loading}) => {
          this.specialEquipmentList = data.productConfigurationSpecialEquipmentByTypes;
          this.sortSpecialEquipment(this.specialEquipmentList);
          this.selectedSpecialEquipments = [];
          this.specialEquipmentList.forEach((specialEquipment: SpecialEquipment) => {
            if (specialEquipment.specialEquipmentImportStatus === ImportStatus.ACTIVE) {
              this.selectedSpecialEquipments.push(specialEquipment.code);
            }
            this.configurationForm.get('selectedSpecialEquipments').setErrors({'firstError': null});
            this.configurationForm.get('selectedSpecialEquipments').updateValueAndValidity();
          });
          this.synchroniseSelectedWithCheckBox(this.specialEquipmentList, this.selectedSpecialEquipments, "specialEquipmentCheckbox");
          this.loadingScreen = false;
        }, (error) => {
          this.loadingScreen = false;
          this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
        });
    }
  }

  sortSpecialEquipment(specialEquipmentList: SpecialEquipment[]){
    specialEquipmentList.sort(function(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  loadProductConfigStatuses() {
    this.loadingScreen = true;
    this.productService.productConfigStatuses()
      .subscribe(({data, loading}) => {
        this.setProductDataStatus(data.productConfigurationStatuses.productDataStatuses);
        this.setProductionTypes(data.productConfigurationStatuses.productionTypes);
        this.setBuildTypes(data.productConfigurationStatuses.buildTypes);
        this.loadingScreen = false;
      }, (error) => {
        this.loadingScreen = false;
        this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  setProductDataStatus(productDataStatus: ProductDataStatus[]) {
    this.productDataStatusList = [];
    productDataStatus.forEach((status: ProductDataStatus) => {
      let dataStatus = {};
      dataStatus["productDataStatusCodeNo"] = ProductDataStatusCode[status.productDataStatusCode];
      dataStatus["productDataStatusCode"] = status.productDataStatusCode;
      this.productDataStatusList.push(dataStatus)
      if (status.productDataImportStatus === ImportStatus.ACTIVE) {
        this.selectedProductDataStatuses.push(ProductDataStatusCode[status.productDataStatusCode]);
        this.configurationForm.get('selectedProductDataStatuses').setErrors({'firstError': null});
        this.configurationForm.get('selectedProductDataStatuses').updateValueAndValidity();
      }
    })
    this.sortProductDataStatus(this.productDataStatusList);
    this.synchroniseSelectedWithCheckBox(this.productDataStatusList, this.selectedProductDataStatuses, "productDataStatusCheckbox");
  }

  sortProductDataStatus(productDataStatusList){
    productDataStatusList.sort(function(a, b) {
      if (a.productDataStatusCodeNo < b.productDataStatusCodeNo) {
        return -1;
      }
      if (a.productDataStatusCodeNo > b.productDataStatusCodeNo) {
        return 1;
      }
      return 0;
    });
  }

  setProductionTypes(productionTypes: ProductionType[]){
    this.productionTypeList = productionTypes;
    this.sortProductionTypes(this.productionTypeList);
    this.productionTypeList.forEach((productionType: ProductionType) => {
      if(productionType.productionTypeImportStatus === ImportStatus.ACTIVE){
        this.selectedProductionTypes.push(productionType.productionTypeCode)
        this.configurationForm.get('selectedProductTypes').setErrors({'firstError': null});
        this.configurationForm.get('selectedProductTypes').updateValueAndValidity();
      }
    });
    this.synchroniseSelectedWithCheckBox(this.productionTypeList, this.selectedProductionTypes, "productionTypeCheckbox");
  }

  sortProductionTypes(productionTypeList: ProductionType[]){
    productionTypeList.sort(function(a, b) {
      if (a.productionTypeCode < b.productionTypeCode) {
        return -1;
      }
      if (a.productionTypeCode > b.productionTypeCode) {
        return 1;
      }
      return 0;
    });
  }

  private setBuildTypes(buildTypes: BuildType[]) {
    this.buildTypeList = buildTypes;
    this.sortBuildTypes(this.buildTypeList);
    this.buildTypeList.forEach((buildType: BuildType) => {
      if (buildType.buildTypeImportStatus === ImportStatus.ACTIVE){
        this.selectedBuildTypes.push(buildType.buildTypeCode);
        this.configurationForm.get('selectedBuildTypes').setErrors({'firstError': null});
        this.configurationForm.get('selectedBuildTypes').updateValueAndValidity();
      }
    });
    this.synchroniseSelectedWithCheckBox(this.buildTypeList, this.selectedBuildTypes, "buildTypeCheckbox");
  }

  sortBuildTypes(buildTypeList: BuildType[]){
    buildTypeList.sort(function(a, b) {
      if (a.buildTypeCode < b.buildTypeCode) {
        return -1;
      }
      if (a.buildTypeCode > b.buildTypeCode) {
        return 1;
      }
      return 0;
    });
  }

  derivativeChanged(selectedDerivativeCodes) {
    this.synchroniseSelectedWithCheckBox(this.derivativeList,selectedDerivativeCodes, "derivativeCheckbox");
    if (selectedDerivativeCodes.length > 0) {
      this.loadTypesForDerivatives(selectedDerivativeCodes);
      this.loadSpecialEquipmentForTypes(this.selectedTypes);
    }else{
      this.selectedTypes = [];
      this.configurationForm.get("typeCheckbox").setValue(false);
      this.selectedSpecialEquipments = [];
      this.configurationForm.get("specialEquipmentCheckbox").setValue(false);
    }
  }

  typeChanged(selectedTypeCodes) {
    this.synchroniseSelectedWithCheckBox(this.typeList, selectedTypeCodes, "typeCheckbox");
    if (selectedTypeCodes.length > 0) {
      this.loadSpecialEquipmentForTypes(selectedTypeCodes);
    }
  }

  specialEquipmentChanged(selectedSpecialEquipment) {
    this.synchroniseSelectedWithCheckBox(this.specialEquipmentList, selectedSpecialEquipment, "specialEquipmentCheckbox");
  }

  buildTypeChanged(selectedBuildTypes) {
    this.synchroniseSelectedWithCheckBox(this.buildTypeList, selectedBuildTypes, "buildTypeCheckbox");
  }

  productionTypeChanged(selectedProductionTypes) {
    this.synchroniseSelectedWithCheckBox(this.productionTypeList, selectedProductionTypes, "productionTypeCheckbox");
  }

  productDataStatusChanged(selectedProductDataStatuses) {
    this.synchroniseSelectedWithCheckBox(this.productDataStatusList, selectedProductDataStatuses, "productDataStatusCheckbox");
  }

  synchroniseSelectedWithCheckBox(mainDataList, selectedList, checkboxName){
    if (selectedList.length ==  mainDataList.length){
      this.configurationForm.get(checkboxName).setValue(true);
    }else{
      this.configurationForm.get(checkboxName).setValue(false);
    }
  }

  derivativeCheckboxChanged($event: Event) {
    this.selectedDerivatives = [];
    if (this.configurationForm.get("derivativeCheckbox").value) {
      this.derivativeList.forEach((product: Product) => {
        this.selectedDerivatives.push(product.derivative.code);
      });
    }
  }

  productDataStatusCheckboxChanged($event: Event) {
    this.selectedProductDataStatuses = [];
    if (this.configurationForm.get("productDataStatusCheckbox").value) {
      this.productDataStatusList.forEach((productDataStatus: ProductDataStatus) => {
        this.selectedProductDataStatuses.push(productDataStatus.productDataStatusCodeNo);
      });
    }
  }

  typeCheckboxChanged($event: Event) {
    this.selectedTypes = this.selectOrDeselect(this.selectedTypes, this.typeList, "typeCheckbox");
  }

  productionTypeCheckboxChanged($event: Event) {
    this.selectedProductionTypes = [];
    if (this.configurationForm.get("productionTypeCheckbox").value) {
      this.productionTypeList.forEach((productionType: ProductionType) => {
        this.selectedProductionTypes.push(productionType.productionTypeCode);
      });
    }
  }

  specialEquipmentCheckboxChanged($event: Event) {
    this.selectedSpecialEquipments = this.selectOrDeselect(this.selectedSpecialEquipments, this.specialEquipmentList, "specialEquipmentCheckbox");
  }

  buildTypeCheckboxChanged($event: Event) {
    this.selectedBuildTypes = [];
    if (this.configurationForm.get("buildTypeCheckbox").value) {
      this.buildTypeList.forEach((buildType: BuildType) => {
        this.selectedBuildTypes.push(buildType.buildTypeCode);
      });
    }
  }

  selectOrDeselect(selectionList, itemList, componentName) {
    selectionList = [];
    if (this.configurationForm.get(componentName).value) {
      for(let item of itemList){
        selectionList.push(item.code);
      }
    }
    return selectionList;
  }

  saveConfiguration() {
    this.showDeletePrompt = false;
    this.infoMessage = undefined;
    this.errorMessage = undefined;
    if (this.formValid()) {
      this.loadingScreen = true;
      this.operationService.allManufacturingCosts()
        .subscribe((result) => {
          this.unselectedTypes = this.typeList.filter(e =>  !this.selectedTypes.includes(e.code)).map(e => e.code);
          this.unselectedSpecialEquipments = this.specialEquipmentList.filter(e =>  !this.selectedSpecialEquipments.includes(e.code)).map(e => e.code);
          if(this.operationService.manufacturingCostsHistoryExists(this.getDeselectedProductConfigChangeInput(), result.data.manufacturingCostHistoryByPlant)){
            this.dialogTitle = this.translate.instant('messages.title');
            this.showDeletePrompt = true;
            this.loadingScreen = false;
            return;
          } else {
            this.productService.saveProductConfig(this.getProductConfigChangeInput()).subscribe(({data}) => {
              this.loadingScreen = false;
              this.infoMessage = this.translate.instant('messages.save-success'); //Selection saved successfully
            },(error) => {
              this.loadingScreen = false;
              this.errorMessage = this.translate.instant('messages.save-failure'); //Selection could not be saved
              this.errorMessage = this.errorMessage + " : " + this.productService.removeGraphQLErrorOnMessage(error.message);
            });
          }
        })

    }else{
      this.errorMessage = this.translate.instant('messages.provide-required-details');
    }
  }

  private formValid(): boolean {
    return this.selectedDerivatives.length > 0 &&
      this.selectedTypes.length > 0 &&
      this.selectedSpecialEquipments.length > 0 &&
      this.selectedProductDataStatuses.length > 0 &&
      this.selectedProductionTypes.length > 0 &&
      this.selectedBuildTypes.length > 0 &&
      this.datesSelected != null;
  }

  private getProductConfigChangeInput(): ProductConfigurationChangeInput{
    let productConfigurationViewModel = new ProductConfigurationViewModel();
    productConfigurationViewModel.datesSelected = this.datesSelected;
    productConfigurationViewModel.selectedDerivatives = this.selectedDerivatives;
    productConfigurationViewModel.derivativeList = this.derivativeList;
    productConfigurationViewModel.selectedTypes = this.selectedTypes;
    productConfigurationViewModel.typeList = this.typeList;
    productConfigurationViewModel.selectedSpecialEquipments = this.selectedSpecialEquipments;
    productConfigurationViewModel.specialEquipmentList = this.specialEquipmentList;
    productConfigurationViewModel.selectedProductDataStatuses = this.selectedProductDataStatuses;
    productConfigurationViewModel.productDataStatusList = this.productDataStatusList;
    productConfigurationViewModel.selectedProductionTypes = this.selectedProductionTypes;
    productConfigurationViewModel.productionTypeList = this.productionTypeList;
    productConfigurationViewModel.selectedBuildTypes = this.selectedBuildTypes;
    productConfigurationViewModel.buildTypeList = this.buildTypeList;

    return this.productService.getProductConfigChangeInput(productConfigurationViewModel);
  }

  private getDeselectedProductConfigChangeInput(): ProductConfigurationChangeInput{
    let productConfigurationViewModel = new ProductConfigurationViewModel();
    productConfigurationViewModel.selectedTypes = this.unselectedTypes;
    productConfigurationViewModel.typeList = this.typeList;
    productConfigurationViewModel.selectedSpecialEquipments = this.unselectedSpecialEquipments;
    productConfigurationViewModel.specialEquipmentList = this.specialEquipmentList;

    return this.productService.getDeselectedProductConfigChangeInput(productConfigurationViewModel);
  }


  triggerSalImport() {
    this.loadingScreen = true;
    this.productService.startDataImport().subscribe(({data}) => {
      this.disableStartImportButton = true;
      this.loadingScreen = false;
    },(error) => {
      this.loadingScreen = false;
      this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  initialiseAllProductConfigTypes() {
    this.selectedDerivatives = [];
    this.derivativeList = [];
    this.selectedTypes = []
    this.typeList = [];
    this.selectedSpecialEquipments = [];
    this.specialEquipmentList = [];
    this.selectedProductDataStatuses = [];
    this.productDataStatusList = [];
    this.selectedProductionTypes = [];
    this.productionTypeList = [];
    this.selectedBuildTypes = [];
    this.buildTypeList = [];
  }

  initialiseFormComponents() {
    this.configurationForm = this.fb.group({
      timePeriod: [''],
      selectedDerivatives: [''],
      selectedProductDataStatuses: [''],
      selectedTypes: [''],
      selectedProductTypes: [''],
      selectedSpecialEquipments: [''],
      selectedBuildTypes: [''],
      derivativeCheckbox: [''],
      productDataStatusCheckbox: [''],
      typeCheckbox: [''],
      productionTypeCheckbox: [''],
      specialEquipmentCheckbox: [''],
      buildTypeCheckbox: [''],
    });
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === 39 || $event.keyCode === 37) {
      $event.stopPropagation();
    }
  }

  closeErrorMessage() {
    this.errorMessage = null;
  }

  closeInfoMessage() {
    this.infoMessage = null;
  }

  okClick() {
    let componentName  = "typeCheckbox";
    this.showDeletePrompt = false;
    this.configurationForm.get(componentName).setValue(true);
    this.selectedTypes = this.selectOrDeselect(this.selectedTypes, this.typeList, componentName);
  }
}
