import gql from 'graphql-tag';
import {RuleHistory} from '../model/operations.model';

export const RETRIEVE_RULES_HISTORY = gql`
  query retrieveOperationRulesHistory($plant: Plant!) {
    retrieveOperationRulesHistory(plant: $plant) {
      derivativeCode
      typeName
      description1
      description2
      modelTypeCode
      operationId
      operationName
      releaseDate
      ruleId
      ruleStatus
      specialEquipmentCode1
      specialEquipmentCode2
      validFrom
      amount {
        ROUNDING_MODE
        value
      }
    }
  }`;

export interface RulesHistoryResponse {
  retrieveOperationRulesHistory: RuleHistory[];
}
