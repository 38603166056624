<div
  *ngIf="editableErrorMessage"
  class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
  <bmw-inline-message type="error">
    {{editableErrorMessage}}
  </bmw-inline-message>
</div>
<div class="bmw__margin-top__s">
  <ag-grid-angular
    style="height: 500px; overflow: scroll"
    class="zebra"
    [rowData]="rowData"
    [columnDefs]="runtimeCompilerData.columns"
    [gridOptions]="runtimeCompilerData.gridOptions"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [sideBar]="runtimeCompilerData.sideBar"
    [statusBar]="runtimeCompilerData.statusBar"
    [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (modelUpdated)="onModelUpdated($event)"
    (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    [rowSelection]="rowSelection"
    [pagination]="true"
    [suppressPaginationPanel]="false">
  </ag-grid-angular>
</div>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Saving..." [show]="loadingScreen"></bmw-progress-circle>
<div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
      <bmw-button button="secondary" type="submit" (clickEvent)="onCancel()"  style="padding: 5px;">
        {{ 'component.bottom-bar.cancel' | translate }}
      </bmw-button>
      <bmw-button button="primary" type="submit" (clickEvent)="onSave()" >
        {{ 'component.bottom-bar.save' | translate }}
      </bmw-button>
    </div>
  </div>
</div>
