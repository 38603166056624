<bmw-progress-circle [fullScreen]="true" loadingLabel="Initialising..." [show]="loading"></bmw-progress-circle>
<bmw-theme>
  <div class="top-bar">
    <header></header>
  </div>
  <div class="router-outlet-wrapper router-outlet-wrapper--add-padding">
    <router-outlet></router-outlet>
  </div>
  <app-menu [path]="locationPath"></app-menu>
  <footer>
    <span class="bmw-small-text">Version: {{ templatesVersion }}</span>
    <span class="bmw-small-text">Copyright {{currentYear}} BMW Group | <a class="bmw-small-text" routerLink="imprint">Imprint</a></span>
  </footer>
</bmw-theme>


<bmw-dialog
  [type]="'info'"
  [show]="showDialog"
  [closeButton]="true"
  [title]="'Information'"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="true"
  [secondaryDisabled]="true"
  (primaryClick)="runtimeCompilerData.show = true"
  (secondaryClick)="runtimeCompilerData.show = false"
  (closeClick)="runtimeCompilerData.show = true">
  {{message}}
</bmw-dialog>

<bmw-dialog
  [type]="'error'"
  [show]="showErrorDialog"
  [closeButton]="true"
  [title]="'No rights on system'"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="logout()"
  (closeClick)="runtimeCompilerData.show = false">
  {{errorMessage}}
</bmw-dialog>

