import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';

@Component({
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.less'],
})
export class ImprintComponent implements OnInit {
  constructor(breadcrumbsService: BreadcrumbsService) {
    breadcrumbsService.reset();
  }

  ngOnInit(): void {}
}
