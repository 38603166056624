import {Component, Input, OnInit} from '@angular/core';
import {PlantChangeService} from "../../../header/service/PlantChangeService";
import {FreezePriceService} from "../service/freeze.price.service";
import {PriceSummaryCellData} from "../model/freeze.price.models";
import {ManualPricesTransformer} from "../../service/manual-prices-transformer";
import {Price, PriceDetail} from "../../model/continous.price.model";
import {ReleaseReject} from "../model/release.reject.model";
import {faas} from "../../../../constants/faas.constants";
import {TabSelectionService} from "../service/TabSelectionService";

@Component({
  selector: 'app-freeze-price-overview',
  templateUrl: './freeze-price-overview.component.html',
  styleUrls: ['./freeze-price-overview.component.scss']
})
export class FreezePriceOverviewComponent extends ManualPricesTransformer implements OnInit {
  hideOverviewGrid: boolean = true;
  errorMessage: any;
  rowData: any;
  rowDetailsData: any;
  loading: boolean = false;
  isReject: boolean;
  selectedPrice: Price = undefined;
  prices: any = [];
  rowSelection: string;
  action: string;
  showApproveRejectButtons: boolean = false;
  hideColumns: string[];

  constructor(private service: FreezePriceService,
              private plantService: PlantChangeService,
              private tabService: TabSelectionService) {
    super();
    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.loadPriceSummary();
      }
    });

    this.tabService.onChange().subscribe(tab => {
      if (tab.text == "a") {
        this.closeAndRefreshPriceView();
      }
    });

    this.rowSelection = 'multiple';
  }

  ngOnInit(): void {
    this.loadPriceSummary();
  }

  private loadPriceSummary() {
    this.rowData = undefined;
    this.errorMessage = undefined;
    this.selectedPrice = undefined;
    this.prices = undefined;

    this.service.getPriceSummary().subscribe(({data, loading}) => {
      this.rowData = data.priceSummary;
    }, (error) => {
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  onDoubleClick(e: PriceSummaryCellData,reload: boolean) {
    this.loading = true;
    this.rowDetailsData = undefined;
    this.hideButtonIfNoRole(e.hideApproveRejectButtons);
    let params = e;
    if (!reload){
      params = e.get();
      this.preserveSearchParameters(params);
    }

    this.service.getPriceForWorkFlowType(params).subscribe(({data, loading}) => {
      if ( data.freezePricesForWorkflowType?.length == 0 ) {
        this.closeAndRefreshPriceView();
      } else {
        this.hideOverviewGrid = false;
        this.loading = false;
        this.rowDetailsData = this.toDisplayDto(data.freezePricesForWorkflowType);
      }
    }, (error) => {
      console.log('test', error);
      this.closeAndRefreshPriceView();
    });
  }

  onError(error: string) {
    this.errorMessage = error;
    this.hideOverviewGrid = true;
  }

  onCancelView() {
    this.hideOverviewGrid = true;
  }

  onViewSelectedPrice(price: any) {
    this.loading = true;
    this.service.getPriceById(price).subscribe(({data, loading}) => {
      this.loading = false;
      this.selectedPrice = data.priceByIdentifier;
    }, (error) => {
      this.loading = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  closePriceView() {
    this.loading = false;
    this.errorMessage = undefined;
    this.selectedPrice = undefined;
    this.prices = undefined;
  }

  closeAndRefreshPriceView() {
    this.closePriceView();
    this.hideOverviewGrid = true;
    this.onRefresh();
  }

  onConfirmation() {
    this.loading = true;
    this.errorMessage = undefined;

    try {
      let prices = this.toPriceDetailDto(this.prices);
      this.isReject == true ? this.reject(prices) : this.approve(prices);
    } catch (e) {
      this.errorMessage = e.toString();
    } finally {
      this.closePriceView();
    }
  }

  approve(prices: Price[]) {
    this.rowDetailsData = undefined;
    this.service.approveFreezePricesForBatch(prices).subscribe(({ data }) => {
      this.reload();
      this.loading = false;
      this.service.triggerApproveFreezePrice().subscribe(({ data }) => {
        console.log("Triggered the batch job")
      });
    },(error) => {
      this.loading = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  reject(prices: Price[]) {
    this.rowDetailsData = undefined;
    this.service.rejectFreezePrices(prices).subscribe(({ data }) => {
      this.reload();
      this.loading = false;
    },(error) => {
      this.loading = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }


  releaseReject(param: ReleaseReject) {
    this.isReject = param.isReject;
    this.action = param.action;
    if (param.isPriceObject){
      let data = this.priceDetailDto(param.data);
      this.prices = data;
    } else {
      this.prices = param.data;
    }
  }


  preserveSearchParameters(e: PriceSummaryCellData) {
    sessionStorage.setItem(faas.freezePriceOverviewParameters, JSON.stringify(e));
  }

  reload() {
    let json = sessionStorage.getItem(faas.freezePriceOverviewParameters);
    if ( json != null || json.length > 0 ) {
      let request = JSON.parse(json);
      this.onDoubleClick(request,true);
    }
  }

  onRefresh() {
    //refresh the one for the overview
    this.loadPriceSummary();
  }

  hideButtonIfNoRole(isPriceIsOpen: boolean) {
    this.showApproveRejectButtons = this.service.canReleaseOrReject() && isPriceIsOpen;
  }
}
