<form [formGroup]="orderSearchForm">
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <bmw-fieldset opened="true">
      <bmw-fieldset-header>{{ 'component.search-bar.search-criteria' | translate }}</bmw-fieldset-header>
      <bmw-fieldset-container>
        <div class="bmw__grid__inner">
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield id="snr" icon="iwp-icon-gen_add_row"
                            (iconClicked)="setPartsDialogVisibility(true)">
              <bmw-label>{{ 'component.search-bar.snr' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                name="snr"
                style="text-transform: uppercase;"
                formControlName="snr"
                data-e2e="snr"
                [placeholder]="searchPlaceHolder"
                (keyup)="snrInputChanged($event)"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield id="ai">
              <bmw-label>{{ 'component.search-bar.ai' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                name="ai"
                maxlength="2"
                formControlName="ai"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield id="orderNumber">
              <bmw-label>{{ 'component.search-bar.order-number' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                name="orderNumber"
                style="text-transform: uppercase;"
                formControlName="orderNumber"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div *ngIf="!hideWorkFlowStatus" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
              <bmw-label>{{ 'component.search-bar.approval-status' | translate }}</bmw-label>
              <div style="margin-top: 5px;">
                <bmw-checkbox [formControlName]="'approvalCheckBox'" [name]="'approvalCheckBox'">
                  <bmw-label>{{ 'component.search-bar.pending-approval-status' | translate }}</bmw-label>
                </bmw-checkbox>
              </div>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
            <bmw-button button="primary" type="submit" (clickEvent)="search()" style="margin-right: 10px;">
              {{ 'component.search-bar.search' | translate }}
            </bmw-button>
            <bmw-button button="secondary" type="submit" (clickEvent)="clearFilters()">
              {{ 'component.search-bar.clear' | translate }}
            </bmw-button>
          </div>
        </div>
      </bmw-fieldset-container>
    </bmw-fieldset>
  </div>
</form>

<app-part-search-dialog
  [showPartSearchDialog]="showMultipleSearchDialog"
  [partData]="partData"
  (saveView)="updatePartDataState($event)"
  (cancelView)="onClose($event)"
  (dataChanged)="reEvaluatePartData($event)"
></app-part-search-dialog>
