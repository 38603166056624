import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeText'
})
export class NormalizeTextPipe implements PipeTransform {

  transform(value: any): string {
    return this.normalizeWord(value);
  }

  normalizeWord = (s: string) => {
    if ( s == undefined ) return "";
    let split = s.split("_");
    let finalWord = "";
    for(let s of split){
      finalWord += s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() + " ";
    }
    return finalWord;
  }

}
