import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {OAuthModuleConfig, OAuthResourceServerErrorHandler, OAuthService, OAuthStorage} from "angular-oauth2-oidc";
import {Observable} from "rxjs";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor{

  constructor(
    private authStorage: OAuthStorage,
    private errorHandler: OAuthResourceServerErrorHandler,
    private oauthService : OAuthService,
    private moduleConfig?: OAuthModuleConfig
  ) {
  }

  private checkUrl(url: string): boolean {
    let found = this.moduleConfig.resourceServer.allowedUrls.find(u => url.startsWith(u));
    return !!found;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let url = req.url.toLowerCase();

    if (!this.moduleConfig) return next.handle(req);
    if (!this.moduleConfig.resourceServer) return next.handle(req);
    if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);
    if (!this.checkUrl(url)) return next.handle(req);

    let sendAccessToken = this.moduleConfig.resourceServer.sendAccessToken ;

    if (sendAccessToken) {

      let token = this.oauthService.getIdToken();
      let header = 'Bearer ' + token;

      let headers = req.headers
        .set('Authorization', header)
        .set('access-token', this.oauthService.getAccessToken());

      req = req.clone({ headers });
    }

    return next.handle(req);

  }

}
