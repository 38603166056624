import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Price, PriceDetail} from "../../model/continous.price.model";
import {FaasDatesHelper} from "../../../../helper/faas.dates.helper";
import {PriceCodesMapper} from "../../model/price-codes.mapper";
import {ReleaseReject} from "../model/release.reject.model";
import {PriceUnit} from "../../../order/model/order.model";
import {SelectedItemInfo} from "@bmw-ds/components/context-menu/context-menu-event-provider.directive";

@Component({
  selector: 'app-freeze-price-main-details',
  templateUrl: './freeze-price-main-details.component.html',
  styleUrls: ['./freeze-price-main-details.component.scss']
})
export class FreezePriceMainDetailsComponent extends PriceCodesMapper implements OnInit {

  @Input() price: Price;
  @Input() showButtons: boolean = true;

  @Output() onCancel = new EventEmitter();
  @Output() release = new EventEmitter<ReleaseReject>();
  @Output() reject = new EventEmitter<ReleaseReject>();

  calculatedDate: string;
  fromDate: string;
  toDate: string;
  priceStates: string;
  priceType: string;
  loadingScreen: any;
  priceUnit: string;
  searchParams: string;
  opened = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.searchParams = `${this.price.material.snr}-${this.price.material.ai}`;
    this.calculatedDate = FaasDatesHelper.formatDateTimeToString(this.price.calculatedOn);
    this.fromDate = FaasDatesHelper.formatDateToString(this.price.validity?.fromDate);
    this.toDate = FaasDatesHelper.formatDateToString(this.price.validity?.toDate);
    this.priceStates = this.getFormattedPricingState(this.price.priceState);
    this.priceType = this.getFormattedPriceType(this.price?.priceType?.toUpperCase());
    this.priceUnit = PriceUnit[this.price?.priceUnit] == undefined ? "" : PriceUnit[this.price?.priceUnit];
  }

  toggleMenu() {
    this.opened = !this.opened;
  }

  cancel() {
    this.onCancel.emit();
  }

  onReject() {
    let release = new ReleaseReject();
    release.data = this.toPriceDetail(this.price);
    release.isReject = true;
    release.isPriceObject = true;
    release.action = "reject";
    this.reject.emit(release);
  }

  onRelease() {
    let release = new ReleaseReject();
    release.data = this.toPriceDetail(this.price);
    release.isReject = false;
    release.isPriceObject = true;
    release.action = "release";
    this.release.emit(release);
  }

  toPriceDetail(price: Price): PriceDetail {
    let priceDetail = new PriceDetail();
    priceDetail.snr = price.material.snr;
    priceDetail.ai = price.material.ai;
    priceDetail.validFrom = FaasDatesHelper.formatDateToSortableDateString(price.validity.fromDate);
    priceDetail.basePrice = price.basePrice.value;
    priceDetail.nettPrice = price.nettPrice.value;
    priceDetail.currency = price.currency.currency;
    priceDetail.internalIdentifier = price.internalIdentifier;
    priceDetail.priceUnit = price.priceUnit;
    priceDetail.priceWorkflowState = price.priceWorkflowState;
    priceDetail.quantityUnit = price.priceComponents[0]?.order.quantityUnit;
    return priceDetail;
  }

  selected(params: SelectedItemInfo) {
    console.log('selected menu', params);
  }
}
