import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../../header/service/LanguageChangeService";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-part-search-dialog',
  templateUrl: './part-search-dialog.component.html',
  styleUrls: ['./part-search-dialog.component.scss']
})

export class PartSearchDialogComponent implements OnInit {

  @Input() showPartSearchDialog: boolean = true;
  @Input() partData = [ { "partNumberId": "" } ];
  @Output() cancelView = new EventEmitter();
  @Output() saveView = new EventEmitter<any>();
  @Output() dataChanged = new EventEmitter<any>();
  searchTitle: string = "";
  myData: any;
  subscription: Subscription;

  ngOnInit(): void {
    this.translateDefinitions()
    this.showPartSearchDialog = false;
  }

  constructor(private translate: TranslateService, private langService: LanguageChangeService) {
    this.subscription = this.langService.onChange().subscribe( e=> {
      this.translateDefinitions();
    });
  }

  onClose() {
    this.showPartSearchDialog = false;
    this.myData = undefined;
    this.onSave();
  }

  onSave() {
    if (this.myData == undefined) {
      this.partData = [ { "partNumberId": "" } ];
      this.updatePartListState(this.partData)
    }
    this.saveView.emit(this.myData);
  }

  updatePartListState(params) {
    this.myData = params;
    this.dataChanged.emit(this.myData);
  }

  translateDefinitions() {
    this.searchTitle = this.translate.instant('dialogs.part-search-dialog.search-title');
  }
}
