import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class PartGridColumns extends GridColumnBase implements GridColumnDefinition {

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  translate: TranslateService;
  columns = [
    {
      field: 'partNumberId',
      headerName: 'dialogs.part-search-dialog.part-numbers-column',
      enableRowGroup: false,
      checkboxSelection: false,
      editable: false,
      resizable: false,
      hide: false,
      rowGroup: false,
      headerHeight: 32,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }];
}
