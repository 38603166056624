import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AgGridBase} from "../../../../ag.grid.base";
import {PartGridColumns} from "../part-grid/part-grid.columns";
import {TranslateService} from "@ngx-translate/core";
import {ProcessDataFromClipboardParams} from "ag-grid-community";

@Component({
  selector: 'app-part-grid',
  templateUrl: './part-grid.component.html',
  styleUrls: ['./part-grid.component.scss']
})
export class PartGridComponent extends AgGridBase<PartGridColumns> {

  @Input() partData = [ { "partNumberId": "" } ];
  @Output() onPaste = new EventEmitter<any>();
  rowSelection = 'single';
  copyHeadersToClipboard: true
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'left'
      }
    ]
  }

  constructor(translate: TranslateService) {
    super(new PartGridColumns(translate));
  }

  processDataFromClipboard(params: ProcessDataFromClipboardParams) {
    if(this.partData.length == 1 && this.partData[0].partNumberId == "") {
      this.partData.pop();
    }
    let array = new Array<{ partNumberId: string }>();
    navigator.clipboard.readText().then(s => {
      s.split("\r\n").forEach((part) => {
        this.addToPartsList(array, part);
      });
      this.partData = this.partData.concat(array);
      this.onPaste.emit(this.partData);
    });
  }

  addToPartsList(array, part) {
    for (let partItem of array) {
      if(partItem.partNumberId == part) { return; }
    }
    if( part.length < 1 ) { return; }
    for (let partItem of this.partData) {
      if(partItem.partNumberId == part) { return; }
    }
    array.push({"partNumberId": part});
  }
}
