<bmw-ag-grid-theme>
    <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="processing"></bmw-progress-circle>
    <div *ngIf="errorMessage" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
    </div>
    <app-manufacturing-costs-grid data-testid="grid-manufacturing-costs"
                                  [rowData]="rowData">
    </app-manufacturing-costs-grid>
</bmw-ag-grid-theme>

