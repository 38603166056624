import gql from "graphql-tag";
import {Price, PriceDetail, PriceType} from "../../model/continous.price.model";
import {PriceSummary} from "../model/pricesummary.model";

export const FREEZE_PRICES_SUMMARY_QUERY = gql`
  query priceSummary($plant: PlantInput!) {
    priceSummary(plant: $plant) {
      date
      open
      rejected
      released
    }
}`;

export const FREEZE_PRICES_WORKFLOW_TYPE_QUERY = gql`
  query freezePricesForWorkflowType($priceDate:String! , $plant: PlantInput! , $workflowState: PriceWorkflowStateInput!) {
     freezePricesForWorkflowType(priceDate: $priceDate , plant: $plant , workflowState: $workflowState) {
        internalIdentifier
        snr
        ai
        priceUnit
        quantityUnit
        validFrom
        currency
        priceType
        priceState
        basePrice
        nettPrice
        oldNettPrice
        priceWorkflowState
        orderNo
        orderVersion
     }
}`;

export const FREEZE_PRICES_WORKFLOW_QUERY = gql`
  query freezePricesForWorkflowState($priceDate:String! , $plant: PlantInput! , $workflowState: PriceWorkflowStateInput!) {
     freezePricesForWorkflowState(priceDate: $priceDate , plant: $plant , workflowState: $workflowState) {
      priceUnit
      material {
          ai
          plant
          snr
       }
      internalIdentifier
      priceWorkflowState
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
    nettPrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    calculatedOn
    currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    priceType
    priceState
    surcharges {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    validity {
      fromDate
      toDate
    }
    priceComponents {
      weightFactor
      order {
        orderNo
    orderState
    material {
      ai
      plant
      snr
    }
    changeDate
    internalIdentifier
    orderCurrency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderState
    workflowState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        category
        internalIdentifier
        surchargeType
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
      }
    }
      }
      exchangeRate {
         currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        exchangeRateType
        month
        value
        year
      }
      weight {
        importDate
        internalIdentifier
        orderNo
        value
        weightDate
        weightType
      }
    }
  }
}`;

export const FREEZE_PRICES_BY_ID_QUERY = gql`
  query priceByIdentifier($internalIdentifier: Long!) {
    priceByIdentifier(internalIdentifier: $internalIdentifier) {
    priceUnit
     material {
          ai
          plant
          snr
       }
      internalIdentifier
      priceWorkflowState
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
    nettPrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    calculatedOn
    currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    priceType
    priceState
    surcharges {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    validity {
      fromDate
      toDate
    }
    priceComponents {
      weightFactor
      order {
        orderNo
    orderState
    material {
      ai
      plant
      snr
    }
    changeDate
    internalIdentifier
    orderCurrency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderState
    workflowState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        category
        internalIdentifier
        surchargeType
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
      }
    }
      }
      exchangeRate {
         currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        exchangeRateType
        month
        value
        year
      }
      weight {
        importDate
        internalIdentifier
        orderNo
        value
        weightDate
        weightType
      }
    }
  }
}`;

export const FREEZE_PRICE_QUERY =gql`
query freezePrice($snr: String! , $ai: Int! , $plant: PlantInput! , $knowledgeDate: String! $priceType: PriceTypeInput) {
  freezePrice(snr: $snr , ai: $ai  , plant: $plant , knowledgeDate: $knowledgeDate priceType: $priceType) {
    priceUnit
    material {
      ai
      plant
      snr
    }
    basePrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    nettPrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    calculatedOn
    currency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderCurrency {
      currency
      currencyType
      displayFormat
    }
    priceType
    priceState
    surcharges {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    validity {
      fromDate
      toDate
    }
    priceComponents {
      weightFactor
      order {
        orderNo
        orderState
        material {
          ai
          plant
          snr
        }
        changeDate
        internalIdentifier
        orderCurrency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        orderState
        workflowState
        orderType
        quantityUnit
        supplierNo
        version
        pricingPeriods {
          internalIdentifier
          priceUnit
          basePrice {
            currency {
              currency
              currencyType
              displayFormat
              internalIdentifier
              plant
              scale
            }
            value
          }
          nettPrice {
            currency {
              currency
              currencyType
              displayFormat
              internalIdentifier
              plant
              scale
            }
            value
          }
          validity {
            fromDate
            toDate
          }
          surcharges {
            category
            internalIdentifier
            surchargeType
            amount {
              currency {
                currency
                currencyType
                displayFormat
                internalIdentifier
                plant
                scale
              }
              value
            }
          }
        }
      }
      exchangeRate {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        exchangeRateType
        month
        value
        year
      }
      weight {
        importDate
        internalIdentifier
        orderNo
        value
        weightDate
        weightType
      }
    }
  }
}`;


export const FREEZE_PRICES_BY_DATE = gql`
  query freezePricesForDate($priceDate: String! , $plant: PlantInput!) {
    freezePricesForDate(priceDate: $priceDate , plant: $plant) {
      priceUnit
      material {
          ai
          plant
          snr
       }
      internalIdentifier
      priceWorkflowState
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
    nettPrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    calculatedOn
    currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    priceType
    priceState
    surcharges {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    validity {
      fromDate
      toDate
    }
    priceComponents {
      weightFactor
      order {
        orderNo
    orderState
    material {
      ai
      plant
      snr
    }
    changeDate
    internalIdentifier
    orderCurrency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderState
    workflowState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        category
        internalIdentifier
        surchargeType
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
      }
    }
      }
      exchangeRate {
         currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        exchangeRateType
        month
        value
        year
      }
      weight {
        importDate
        internalIdentifier
        orderNo
        value
        weightDate
        weightType
      }
    }
  }
}`;

export interface PricesSummaryResponse {
  priceSummary: PriceSummary[];
}

export interface FreezePriceForWorkFlowResponse {
  freezePricesForWorkflowState: Price[];
}

export interface FreezePriceForWorkFlowTypeResponse {
  freezePricesForWorkflowType: PriceDetail[];
}

export interface FreezePriceByIdResponse {
  priceByIdentifier: Price;
}

export interface FreezePriceResponse {
  freezePrice: Price;
}

export interface FreezePriceSearchDateResponse {
  freezePricesForDate: Price[];
}
