import gql from "graphql-tag";

export const ADD_EXCHANGE_RATES = gql`
  mutation ($exchangeRates: [ExchangeRateInput]!) {
    addExchangeRates(exchangeRates: $exchangeRates)
  }
`;

export const UPDATE_EXCHANGE_RATES = gql`
  mutation ($updateExchangeRates: [ExchangeRateInput]!) {
    updateExchangeRates(updateExchangeRates: $updateExchangeRates)
  }
`;

export const REJECT_EXCHANGE_RATES = gql`
  mutation ($rejectExchangeRates: [ExchangeRateInput]!) {
    rejectExchangeRates(rejectExchangeRates: $rejectExchangeRates)
  }
`;

export const RELEASE_EXCHANGE_RATES = gql`
  mutation ($approveExchangeRates: [ExchangeRateInput]!) {
    approveExchangeRates(approveExchangeRates: $approveExchangeRates)
  }
`;

export const FREEZE_EXCHANGE_RATES = gql`
  mutation ($freezeExchangeRates: [ExchangeRateInput]!) {
    freezeExchangeRates(freezeExchangeRates: $freezeExchangeRates)
  }
`;
