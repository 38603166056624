import {Apollo} from 'apollo-angular';
import {Injectable} from '@angular/core';
import {ServiceBase} from "../../../services/service.base";
import {faas} from "../../../constants/faas.constants";
import {Plant} from '../../order/model/order.model';
import {RETRIEVE_QUICKSIGHT_URL, RetrieveQuicksightUrlResponse} from "./quicksight-reports.graphql.query";
import {ReportInputInput} from "../../manufacturing/model/reports.model";

@Injectable({
  providedIn: 'root'
})
export class QuicksightReportsService extends ServiceBase {

  constructor(apollo: Apollo) {
    super(apollo);
  }

  getQuicksightUrl(reportInputInput: ReportInputInput) {
    reportInputInput.plant = Plant[window.sessionStorage.client];

    const variable = {
      reportInput: reportInputInput
    };

    return this.query<RetrieveQuicksightUrlResponse>(variable, RETRIEVE_QUICKSIGHT_URL, faas.reportServiceClientName);
  }


  dispose(): void {
  }
}
