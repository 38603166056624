<div class="bmw__grid__cell--span-12">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell--span-5">
      <bmw-label class="data-align"><b>{{ 'year' | translate }}:</b>  {{exchangeRate.year}}</bmw-label>
     </div>
    <div class="bmw__grid__cell--span-6">
      <bmw-label class="data-align"><b>{{ 'currency' | translate }}:</b>  {{exchangeRate.currency}}</bmw-label>
    </div>
  </div>
</div>
<div *ngIf="exchangeRate.perNov > 0">
  <bmw-label class="data-align"><b>{{ 'perNov' | translate }}:</b>  {{exchangeRate.perNov}}</bmw-label>
</div>
<div *ngIf="exchangeRate.final > 0">
  <bmw-label class="data-align"><b>{{ 'final' | translate }}:</b>  {{exchangeRate.final}}</bmw-label>
</div>
<hr class="divider-line">
