import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExchangeRateViewModel, PriceComponentData} from "../../model/continous.price.model";
import {FaasDatesHelper} from "../../../../helper/faas.dates.helper";
import {WeightViewModel} from "../../../weightings/model/weight.view.model";
import {ExchangeRateTypesMapper} from "../../../exchange-rates/model/exchange-rate-types.mapper";
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../../../header/service/LanguageChangeService";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-price-component',
  templateUrl: './price-component.component.html',
  styleUrls: ['./price-component.component.scss']
})
export class PriceComponent extends ExchangeRateTypesMapper implements OnInit , OnDestroy {

  @Input() priceComponents: PriceComponentData[];
  exchangeRateData: any = [];
  weightData: any = [];
  hideWeightings = true;
  hideExchangeRates = true;
  hideOrders = true;
  subscription: Subscription;
  weightingsColumns: any = [];
  exchangeRateColumns: any = [];

  constructor(private translate: TranslateService,
              private langService: LanguageChangeService) {
    super();
    this.subscription = this.langService.onChange().subscribe( e=> {
      this.translateAndLoadColumns();
    });
  }

  ngOnInit(): void {
    this.translateAndLoadColumns();
    this.validateAndLoadData();
  }

  validateAndLoadData() {
    this.getWeight();
    this.getExchangeRate();
    if (this.priceComponents?.some(p => p.order != null)) {
      this.hideOrders = false;
    }
  }

  getWeight() {
    let weights: WeightViewModel[] = [];
    this.priceComponents.forEach(priceComponent => {
      const weight = priceComponent.weight;
      if (weight){
        this.hideWeightings = false;
        let weightViewModel = new WeightViewModel();
        weightViewModel.importDate = FaasDatesHelper.formatDateTimeToString(weight.importDate);
        weightViewModel.orderNo = weight.orderNo;
        weightViewModel.weightValue = weight.value.toLocaleString();
        weightViewModel.weightDate = FaasDatesHelper.formatStringDateToString(weight.weightDate);
        weightViewModel.weightType = weight.weightType;
        weights.push(weightViewModel);
      }
    });
    this.weightData = weights;
  }

  getExchangeRate() {
    let exchangeRates: ExchangeRateViewModel[] = [];
    this.priceComponents.forEach(priceComponent => {
      const exchangeRate = priceComponent.exchangeRate;
      if (exchangeRate) {
        this.hideExchangeRates = false;
        let exchangeRateViewModel = new ExchangeRateViewModel();
        exchangeRateViewModel.currency = exchangeRate.currency.currency;
        exchangeRateViewModel.orderNo = priceComponent.order.orderNo;
        exchangeRateViewModel.exchangeRateType = this.getExchangeRateType(exchangeRate.exchangeRateType);
        exchangeRateViewModel.value = exchangeRate.value;
        exchangeRateViewModel.month = exchangeRate.month;
        exchangeRateViewModel.year = exchangeRate.year;
        exchangeRates.push(exchangeRateViewModel);
      }
    });
    this.exchangeRateData = exchangeRates;
  }

  translateAndLoadColumns() {
    this.weightingsColumns = [
      {field: 'orderNo', headerName: this.localizeHeader('orderNo')},
      {field: 'importDate', headerName: this.localizeHeader('importDate'), sortable: true, sort: 'asc'},
      {field: 'weightValue', headerName: this.localizeHeader('weightValue'), type: 'rightAligned'},
      {field: 'weightDate', headerName: this.localizeHeader('weightDate')},
      {field: 'weightType', headerName: this.localizeHeader('weightType')}
    ];

    this.exchangeRateColumns = [
      {field: 'orderNo', headerName: this.localizeHeader('orderNo')},
      {field: 'currency', headerName: this.localizeHeader('currency')},
      {field: 'exchangeRateType', headerName: this.localizeHeader('exchangeRateType')},
      {field: 'month', headerName: this.localizeHeader('month')},
      {field: 'value', headerName: this.localizeHeader('value'), type: 'rightAligned',},
      {field: 'year', headerName: this.localizeHeader('year')}
    ];
  }


  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
