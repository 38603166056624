import {Component, OnInit} from '@angular/core';
import {Price, PriceSearchRequest} from "../../model/continous.price.model";
import {ContinuousPriceService} from "../../service/continuous-price.service";
import {ServiceBus} from "../../../../services/service.bus";

@Component({
  selector: 'app-price-main-component',
  templateUrl: './price-main-component.component.html',
  styleUrls: ['./price-main-component.component.scss']
})
export class PriceMainComponentComponent implements OnInit {

  errorMessage: string;
  loading: boolean = false;
  selectedPrice: Price;


  constructor(private service: ContinuousPriceService,
              private bus : ServiceBus) {
  }

  ngOnInit(): void {
    this.subscriber();
  }

  subscriber = () => {
    this.bus.onResult<Price>().subscribe(result => {
      this.loading = false;
      window.document.body.click();
      if (!result.isError) {
        this.selectedPrice = result.data;
      } else {
        this.errorMessage = result.errorMessage;
      }
    });
  }

  search(request: PriceSearchRequest) {
    this.loading = true;
    this.errorMessage = null;
    this.selectedPrice = undefined;

    if (!request.isError) {
      this.service.queryPrice(request);
    } else {
      this.errorMessage = request.error;
      this.loading = false;
    }
  }

  clearFilters() {
    this.loading = false;
    this.errorMessage = null;
    this.selectedPrice = undefined;
  }
}
