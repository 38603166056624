<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.pricing.exchange-rates.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s" *ngIf="!isPlantSAlOrGWM">
        <bmw-content-tab (selectionChange)="tabClick($event)">
          <bmw-content-tab-label for="overview">{{ 'menu.pricing.exchange-rates.overview.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="overview">
            <app-exchange-rates-overview></app-exchange-rates-overview>
          </bmw-content-tab-content>
          <div [hidden]="true">
            <bmw-content-tab-label for="release">{{ 'menu.pricing.exchange-rates.release.title' | translate }}</bmw-content-tab-label>
            <bmw-content-tab-content id="release">
              <app-exchange-rates-release></app-exchange-rates-release>
            </bmw-content-tab-content>
            <bmw-content-tab-label for="currency">{{ 'menu.pricing.exchange-rates.currency.title' | translate }}</bmw-content-tab-label>
            <bmw-content-tab-content id="currency">
              <app-currency-overview></app-currency-overview>
            </bmw-content-tab-content>
          </div>
        </bmw-content-tab>
      </div>
      <div class="table-wrapper bmw__margin-top__s" *ngIf="isPlantSAlOrGWM">
        <bmw-content-tab (selectionChange)="tabClick($event)">
          <bmw-content-tab-label for="overview_sal">{{ 'menu.pricing.exchange-rates.overview.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="overview_sal">
            <app-exchange-rates-overview></app-exchange-rates-overview>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="currency_sal">{{ 'menu.pricing.exchange-rates.currency.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="currency_sal">
            <app-currency-overview></app-currency-overview>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
