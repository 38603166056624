import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlantChangeService} from '../../header/service/PlantChangeService';
import {VehicleStatusService} from '../../vehicle/service/vehicle-status.service';
import {VehicleStatusTransformer} from '../../vehicle/service/vehicle-status.transformer';
import {VehicleStatus} from "../model/vehicle.model";

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.scss']
})
export class VehicleStatusComponent implements OnInit {

  rowData: any;
  message = undefined;
  dialogTitle: string;
  dialogText: string;
  loadingScreen: boolean;
  errorMessage: string;
  vehicleStatusTransformer: VehicleStatusTransformer;
  hasReleaseVehicleRole: boolean = false;
  selectedVehicle: VehicleStatus;
  yearToFilter: string;
  monthToFilter: string;

  constructor(
    public translate: TranslateService,
    private plantService: PlantChangeService,
    private vehicleStatusService: VehicleStatusService
  ) {

    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadVehicleStatus();
      }
    });
    this.vehicleStatusTransformer = new VehicleStatusTransformer();
  }

  ngOnInit(): void {
    this.yearToFilter = new Date().getFullYear().toString();
    this.monthToFilter = (new Date().getMonth() + 1).toString();
    this.loadVehicleStatus();
  }

  loadVehicleStatus() {
    this.resetVariables();
    this.retrieveVehicleStatus([this.yearToFilter, this.monthToFilter]);
  }

  private resetVariables() {
    this.hasReleaseVehicleRole = this.vehicleStatusService.canReleaseVehicle();
    this.message = undefined;
    this.rowData = undefined;
  }

  retrieveVehicleStatus(yearMonth) {
    this.loadingScreen = true;
    this.vehicleStatusService.retrieveVehicleStatus(yearMonth[0], yearMonth[1])
      .subscribe((result) => {
        this.loadingScreen = false;
        this.rowData = result.data.retrieveVehicleInformation.map(row => {
          return this.vehicleStatusTransformer.transformVehicleStatus(row);
        });
      }, (error) => {
        this.loadingScreen = false;
        this.errorMessage = this.vehicleStatusService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  setSelectedVehicle($event) {
    this.selectedVehicle = $event;
  }

  reCalculate() {
    this.vehicleStatusService.sendVehicleRecalculate(this.selectedVehicle["vin"])
      .subscribe((result) => {
        this.loadingScreen = false;
        this.resetVariables();
        for(let i = 9999 ; i >= 9997; i--) {
          this.retrieveVehicleStatus([i, 13]);
        }
        this.yearToFilter = this.selectedVehicle["year"];
        this.monthToFilter = this.selectedVehicle["month"];
        this.loadVehicleStatus();
      }, (error) => {
        this.loadingScreen = false;
        this.errorMessage = this.vehicleStatusService.removeGraphQLErrorOnMessage(error.message);
      });
  }

}
