import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";
import {TranslateService} from "@ngx-translate/core";
import {Operation} from "../../model/operations.model";

export class ManufacturingCostsGridColumns extends GridColumnBase implements GridColumnDefinition {
  translate: TranslateService;
  params: any;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
    this.params = {
      rowData: new Operation(),
      $event: {}
    }
  }

  columns = [
    {
      field: 'derivativeCode',
      headerName: 'derivativeCode',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'type.name',
      headerName: "typeName",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'type.code',
      headerName: "typeCode",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'type.productionType.productionTypeCode',
      headerName: 'productionTypeCode',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'specialEquipmentName',
      headerName: "specialEquipmentName",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'specialEquipmentCode',
      headerName: "specialEquipmentCode",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'price.value',
      headerName: "price",
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.moneyFormatter,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'validFrom',
      headerName: "validFrom",
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'releaseDate',
      headerName: "releaseDate",
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.dateTimeFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'operationId',
      headerName: "operationId",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'operationName',
      headerName: "operationName",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'group',
      sortable: true,
      filter: true,
      resizable: true,
      rowGroup: true,
      hide: true
    }, {
      field: 'status',
      headerName: 'status',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }

  ];

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }
}
