import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";

export class FreezePriceOverviewGridColumns {

  constructor(private translate: TranslateService) { }

  columns = [
     {
       field: 'date',
       headerName: 'Date',
       resizable: true,
       rowGroup: false,
       hide: false,
       editable: false,
       keyCreator: this.dateKeyCreator,
       valueFormatter: this.dateFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'open',
       headerName: 'No. of Open Prices',
       resizable: true,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
    {
      field: 'released',
      headerName: 'No. of Released Prices',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'rejected',
      headerName: 'No. of Rejected Prices',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },

   ];

   localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    return moment(params.value).format('DD.MM.YYYY');
  }

  dateKeyCreator(params) {
    let dateObject = params.value;
    return dateObject.name;
  }

}
