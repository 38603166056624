import { Pipe, PipeTransform } from '@angular/core';
import {FaasDatesHelper} from "../../helper/faas.dates.helper";

@Pipe({
  name: 'faasDateTime'
})
/**
 * faas date for use for date with this format to give you
 * the standard faas date format of DD.MM.YYYY HH:mm
 * return moment(date,'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm');
 */
export class FaasDateTimePipe implements PipeTransform {

  transform(value: string): string {
    return FaasDatesHelper.formatStringDateTimestampToString(value);
  }

}
