import {Injectable} from '@angular/core';
import {ServiceBase} from '../../../services/service.base';
import {Apollo} from 'apollo-angular';
import {Plant} from '../../order/model/order.model';
import {faas} from '../../../constants/faas.constants';
import {
  RETRIEVE_VEHICLE_INFORMATION,
  VehicleStatusResponse
} from '../vehicle-status/vehicle-status.graphql.query';
import {AuthService} from "../../../services/auth.service";
import {SEND_VEHICLE_RECALCULATE} from "../vehicle-status/vehicle-status.graphql.mutation";

@Injectable({
  providedIn: 'root'
})
export class VehicleStatusService extends ServiceBase {

  constructor(apollo: Apollo, private authService: AuthService) {
    super(apollo);
  }

  retrieveVehicleStatus(year, month) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      year: year,
      month: (month === undefined || month === null) ? '' : (month.length === 1) ? '0' + month : month
    };
    return this.query<VehicleStatusResponse>(variable, RETRIEVE_VEHICLE_INFORMATION, faas.pimaServiceClientName);
  }

  sendVehicleRecalculate(vin: string) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      vin: vin
    };
    return this.mutate(variable, SEND_VEHICLE_RECALCULATE, faas.pimaServiceClientName);
  }

  dispose(): void {
  }

  canReleaseVehicle() {
    let permissions = ['T_FreigProdstueli_Bearbeiter_BMW_SAL','T_FreigProdstueli_Bearbeiter_GWM'];
    return this.authService.checkPermission(permissions);
  }
}
