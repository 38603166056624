<div class="bmw__grid__cell--span-12">
  <div class="bmw__grid__inner">
    <div data-testid="test" class="bmw__grid__cell--span-5">
      <bmw-label data-testid="continuous-price-snr" class="data-align"><b>{{ 'menu.pricing.continuous-prices.overview.snr' | translate }}:</b> {{price.snr}} </bmw-label>
    </div>
    <div class="bmw__grid__cell--span-6">
      <bmw-label data-testid="continuous-price-ai" class="data-align"><b>{{ 'menu.pricing.continuous-prices.overview.ai' | translate }}:</b> {{price.ai | aiToString}} </bmw-label>
    </div>
  </div>
</div>
<div>
  <bmw-label data-testid="price-type" class="data-align"><b>{{ 'menu.pricing.continuous-prices.overview.price-type' | translate }}:</b> {{price.priceType}} </bmw-label>
</div>
<div>
  <bmw-label data-testid="currency-new" class="data-align"><b>{{ 'menu.pricing.continuous-prices.overview.currency' | translate }}:</b> {{price.currencyNew}} </bmw-label>
</div>
<div>
  <bmw-label data-testid="price-state" class="data-align"><b>{{ 'menu.pricing.continuous-prices.overview.price-state' | translate }}:</b> {{price.priceState}} </bmw-label>
</div>
<hr class="divider-line">
