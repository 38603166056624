import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import {AgGridCellDatePickerComponent} from "../order-grid/ag-grid.celldatepicker.component";
import moment from "moment";
import {PriceUnit} from "../model/order.model";
import {padLeft} from "../../../shared/functions/padding";

export class OrderGridEditableColumns {

  constructor(private translate: TranslateService) { }

  setGroupingColumns(col: string[]): void {
    if ( col == undefined )
      return;

    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        c.rowGroup = true;
      }
    });
  }

  hideColumns(col: string[]): void {
    if ( col == undefined )
      return;

    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        c.hide = true;
      }
    });
  }

  columns = [
     {
       field: 'snr',
       headerName: 'SNR',
       width: 150,
       minWidth: 150,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'ai',
       headerName: 'AI',
       width: 150,
       minWidth: 150,
       valueFormatter: this.aiFormatter,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'orderNo',
       headerName: 'Order No',
       width: 150,
       minWidth: 150,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'version',
       headerName: 'Order Version',
       width: 150,
       minWidth: 150,
       rowGroup: false,
       hide: false,
       editable: false,
       //type: 'rightAligned',
       headerValueGetter: this.localizeHeader.bind(this)
     },
    {
      field: 'fromDateEditable' ,
      headerName: 'Valid From',
      width: 150,
      minWidth: 150,
      hide: false,
      editable: true,
      valueSetter: this.isValidFromLessThanExpiry,
      cellRenderer: this.showEditIcon,
      cellEditorFramework: AgGridCellDatePickerComponent,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
     {
       field: 'toDate' ,
       headerName: 'Expiry Date',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       valueFormatter: this.dateFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'basePriceValue' ,
       headerName: 'Base Value',
       width: 150,
       minWidth: 150,
       hide: false,
       type: 'rightAligned',
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'nettPriceValue' ,
       headerName: 'Net Value',
       width: 150,
       minWidth: 150,
       hide: false,
       type: 'rightAligned',
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
    {
      field: 'orderState',
      headerName: 'Order Status',
      width: 150,
      minWidth: 150,
      hide: false,
      editable: true,
      cellRenderer: this.showDropDownIcon,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
         values: ['OPENED', 'CANCELLED', 'CLOSED']
      },
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'changeDate',
      headerName: 'Order Date',
      width: 150,
      minWidth: 150,
      hide: false,
      editable: true,
      cellRenderer: this.showEditIcon,
      //cellEditor: 'datePicker',
      cellEditorFramework: AgGridCellDatePickerComponent,
      headerValueGetter: this.localizeHeader.bind(this)
    },
     {
       field: 'orderCurrency',
       headerName: 'Order Currency',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'priceUnit',
       headerName: 'Price Unit',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       valueFormatter: this.priceUnitFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'quantityUnit',
       headerName: 'Quantity Unit',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'supplierNo',
       headerName: 'Supplier',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
   ];

   localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  showEditIcon(params) {
     if (params.value != undefined ) { // to cater for the group by if you have cellRenderer .. it will show undefined in blank rows
       return params.colDef.editable == true ? // only show icon if column is set to editable = true
         '<span>' + params.value + ' <i class="iwp-icon-gen_calendar"></i></span>'
         : params.value;
     }
     return '';
  }

  showDropDownIcon(params) {
    if (params.value != undefined ) { // to cater for the group by if you have cellRenderer .. it will show undefined in blank rows
      return params.colDef.editable == true ? // only show icon if column is set to editable = true
        '<span>' + params.value + ' <i class="iwp-icon-gen_sort_list_desc"></i></span>'
        : params.value;
    }
    return '';
  }

  isValidFromLessThanExpiry(params): boolean {
    let validFromDate = params.newValue;
    let formattedDate = "";
    if (validFromDate.includes(".")) {
      validFromDate = validFromDate.replace(".", "-");
      validFromDate = validFromDate.replace(".", "-");
      //DD-MM-YYYY
      let split =  validFromDate.toString().split("-");
      formattedDate = split[2] + "-" + split[1] + "-" + split[0];
    }
    let validFrom = moment(formattedDate, 'YYYY-MM-DD');
    let expiryDate = moment(params.data.toDate, 'YYYY-MM-DD');
    let result = validFrom.diff(expiryDate, 'days') < 0;
    if (result) {
      params.data.fromDateEditable = params.newValue;
      params.data.fromDate = params.newValue;
    }
    return result;
  }

  dateFormatter(params) {
    return moment(params.value).format('DD.MM.YYYY');
  }

  priceUnitFormatter(params) {
    return PriceUnit[params.value] == undefined ? "" : PriceUnit[params.value];
  }

  aiFormatter(params) {
    if (params.value != undefined) {
      return padLeft(params.value,2);
    }
  }

}
