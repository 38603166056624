import { Component, HostListener, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ManufacturingReportsService } from '../../service/manufacturing-reports.service';
import { Product, ImportStatus, Type } from '../../../product/model/product.model';
import { GridApi, GridOptions, ICellRendererParams} from 'ag-grid-community';
import {EMPTY_STRING, isUndefinedOrEmpty} from '../../../../../app/shared/functions/typescript.utils';
import moment from 'moment';
import {faas} from "../../../../constants/faas.constants";

@Component({
  selector: 'app-paid-transferprice-dynamic',
  templateUrl: './paid-transferprice-dynamic.component.html',
  styleUrls: ['./paid-transferprice-dynamic.component.scss']
})
export class PaidTransferpriceDynamicComponent implements OnInit {
  @Input() derivativeList: any[] = [];
  @Input() exchangeRateMap: any;
  exchangeRate: number = 1;
  applyExchangeRate = false;
  selectedExchange: string;
  filterForm: FormGroup;
  showReport = false;
  showLoading = false;
  selectedDerivatives: any [];
  selectedTypes: any[];
  selectedTypeShadow: any[];
  typeList: any[];
  effectiveFrom: Date;
  effectiveTo: Date;
  datesSelected: {};
  rowData = [];
  rowDataPreProduction = [];
  dialogTitle: string;
  dialogText: string;
  errorMessage: string;
  showPrompt = false;
  validity = {
    inputModel: [
      this.effectiveFrom,
      this.effectiveTo
    ]
  };

  runtimeCompilerData = {
    columns: [
    ],
    gridOptions: {
      getRowStyle: params => {
        return { order: params.node.rowIndex };
      },
      postSort: params => {
        // it needs to wait until new order is set
        setTimeout(() => {
          params.forEach(param => {
            param.updateData(param.data);
          });
        });
      },
      ensureDomOrder: true,
      suppressMovableColumns: false,
      rowSelection: 'multiple',
      rowDeselection: true,
      rowHeight: 32,
      headerHeight: 32,
      enableRangeSelection: true,
      enableRangeHandle: true,
      rowGroupPanelShow: 'onlyWhenGrouping'
    } as GridOptions,
    defaultColDef: {
      sortable: true,
      unSortIcon: true,
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: 'textFilter',
      defaultColToEdit: '',
      autoHeight: true,
      resizable: true
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel'
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel'
        }
      ],
      defaultToolPanel: undefined
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalAndFilteredRowCountComponent',
          align: 'left'
        },
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'center'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    }
  };

  private gridApi: GridApi;

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  constructor(private manufacturingReportsService: ManufacturingReportsService,private translate: TranslateService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
   }

  ngOnInit(): void {
    this.initialiseFormComponents();
    this.initialisePageValues();
  }

  initialisePageValues() {
    this.dialogTitle = undefined;
    this.dialogText = undefined;
    this.errorMessage = undefined;
    this.selectedExchange = 'CNY';
    this.applyExchangeRate = false;
    this.initialiseTypes();
  }

  initialiseFormComponents() {
    this.filterForm = new FormGroup({
      selectedDerivatives: new FormControl(),
      derivativeCheckbox: new FormControl(),
      selectedTypes: new FormControl(),
      typeCheckbox: new FormControl(),
      selectedExchange: new FormControl()
    });
  }

  initialiseTypes() {
    this.selectedDerivatives = [];
    this.selectedTypes = [];
    this.selectedTypeShadow = [];
    this.typeList = [];
  }

  synchroniseSelectedWithCheckBox(mainDataList, selectedList, checkboxName) {
    if (mainDataList.length > 0 && selectedList.length ==  mainDataList.length){
      this.filterForm.get(checkboxName).setValue(true);
    }else{
      this.filterForm.get(checkboxName).setValue(false);
    }
  }

  selectedDerivativesChanged(event){
    console.log('selectedDerivativesChanged event: ', event);
    this.synchroniseSelectedWithCheckBox(this.typeList, this.selectedTypes, 'typeCheckbox')
    this.loadTypesForDerivatives(event);
  }

  loadTypesForDerivatives(selectedDerivativeCodes) {
    if (selectedDerivativeCodes.length > 0) {
      this.showLoading = true;
      this.manufacturingReportsService.typesForDerivative(selectedDerivativeCodes)
        .subscribe(({data, loading}) => {
          this.typeList = data.productConfigurationTypesByDerivatives;
          this.sortTypesForDerivatives(this.typeList);
          this.selectedTypes = [];
          this.showLoading = false;
        }, (error) => {
          this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
        });
    } else {
      this.typeList = [];
      this.selectedTypes = [];
      this.selectedTypeShadow = [];
    }
  }

  sortTypesForDerivatives(typeList: Type[]){
    typeList.sort(function(a, b) {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }

  derivativeCheckboxChanged($event: Event) {
    this.selectedDerivatives = [];
    if (this.filterForm.get('derivativeCheckbox').value) {
      this.derivativeList.forEach((product) => {
        this.selectedDerivatives.push(product.derivative.code);
      });
    }
    this.loadTypesForDerivatives(this.selectedDerivatives);
  }

  typeCheckboxChanged($event: Event) {
    this.selectedTypes = [];
    if (this.filterForm.get('typeCheckbox').value) {
      this.typeList.forEach((type) => {
        this.selectedTypes.push(type.code);
      })
    }
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    console.log('onGridReady');
  }

  refreshData(){
    this.showReport = false;
    this.rowData = [];
    this.showLoading = true;

    console.log('Selected Exchange : ', this.selectedExchange);

    this.selectedTypeShadow = [...this.selectedTypes];

    this.manufacturingReportsService.paidTransferPrice(this.selectedDerivatives, this.selectedTypes, this.effectiveFrom, this.effectiveTo)
    .subscribe((result) => {
      this.showLoading = false;
      this.rowData = [];
      this.rowDataPreProduction = [];

      let totalDataSetSortedByTime = result.data.reportProductPaidTransferPricePerVinByPlant
      .sort((a, b) => a.type.localeCompare(b.type.toString())
      || moment(a.fnDate).toDate().getTime() - moment(b.fnDate).toDate().getTime());

      //#region TRANSFORM - transformData
      let transformedRowData = totalDataSetSortedByTime.map(row => {
        return this.transformData(row);
      });

      console.log('transformedRowData : ', transformedRowData);

      if (transformedRowData && transformedRowData.length > 0) {
        this.buildReportColumns(transformedRowData[0]);
      } else {
        this.runtimeCompilerData.columns = [];
      }

      transformedRowData.forEach(row => {
        this.rowData.push(row);
      });

      //#endregion

      // #region TOP filter
      const tempRowData = this.rowData;

      let totalDataSetSortedAndFiltered = [];

      const totalDataSetSortedByTimeandFiltered = this.topFilter(tempRowData);
      if(totalDataSetSortedByTimeandFiltered.length > 0){
        totalDataSetSortedAndFiltered = totalDataSetSortedByTimeandFiltered;
      } else if (totalDataSetSortedByTimeandFiltered.length === 0 && (this.selectedDerivatives.length > 0 || this.selectedTypes.length > 0 || this.effectiveTo != null || this.effectiveFrom != null)) {
        //Do nothing: totalDataSetSortedAndFiltered will stay empty, so will this.rowData and no rows will be returned
        console.log('There is no data for filter selection');
      } else {
        console.log('0 0 0 0');
        totalDataSetSortedAndFiltered = tempRowData;
      }

      this.rowData = [];

      totalDataSetSortedAndFiltered.forEach(row => {
        this.rowData.push(row);
      });

      // #endregion

      //#region Calculated Totals
      const preCalculatedColumns = this.rowData;
      this.rowData = [];
      let typeTotal = 0;
      let optionsTotal = 0;

      preCalculatedColumns.forEach(row => {
        typeTotal = this.calculateTypeTotal(row);
        optionsTotal = this.calculateOptionsTotal(row);
        row.typeTotal = typeTotal;
        row.optionsTotal = optionsTotal;
        this.rowData.push(row);
      });
      //#endregion

      //#region TRANSFORM  - ExchangeRate
      if (this.selectedExchange === 'EUR') {
        this.applyExchangeRate = true;
        this.exchangeRate =  this.getExchangeRatePerLastDate();
        console.log('Current Exchange Rate : ', this.exchangeRate);
        this.transformExchangeRate(this.rowData);
      } else {
        this.applyExchangeRate = false;
      }
      //#endregion

      console.log('this.rowData : ', this.rowData);
      this.selectedTypes = [...this.selectedTypeShadow];
      this.showLoading = false;
      this.showReport = true;
      this.manufacturingReportsService.dateRange(this.effectiveFrom, this.effectiveTo).subscribe((result) => {
        if (result.data.retrieveDateRange.adjusted) {
          this.dialogTitle = 'Maximum ' + result.data.retrieveDateRange.periodMaxUnit + ' period of ' + result.data.retrieveDateRange.periodMaxValue;
          this.dialogText = 'Data displayed from ' + result.data.retrieveDateRange.fromDate + ' -> ' + result.data.retrieveDateRange.toDate;
          this.showPrompt = true;
        }
      })
    });
  }

  downloadData() {
    this.showLoading = true;
    if (this.effectiveFrom !== undefined) {
      this.effectiveFrom.setDate(this.effectiveFrom.getDate() + 1);
    }
    if (this.effectiveTo !== undefined) {
      this.effectiveTo.setDate(this.effectiveTo.getDate() + 1);
    }
    this.manufacturingReportsService.download(this.selectedDerivatives, this.selectedTypes, this.effectiveFrom, this.effectiveTo).subscribe(({data}) => {
      this.showLoading = false;
      this.downloadExcel(data.downloadPaidTransferPricePerVinByPlant.content, data.downloadPaidTransferPricePerVinByPlant.name);
    }, (error) => {
      this.showLoading = false;
      this.errorMessage = this.manufacturingReportsService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  downloadExcel(base64String: string, fileName: string): void {
    try {
      const decodedString = atob(base64String);
      if (!decodedString) {
        throw new Error("Invalid base64 string");
      }

      const byteNumbers = new Array(decodedString.length);
      for (let i = 0; i < decodedString.length; i++) {
        byteNumbers[i] = decodedString.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: faas.contentType});

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      }, 100);
    } catch (error) {
      this.showPrompt = true;
      this.dialogTitle = this.translate.instant('messages.download-error');
      this.dialogText = this.translate.instant('messages.download-error-message');
    }
  }


  getExchangeRatePerLastDate() {

    let latestDateRow = this.rowData.reduce((a, b) => {
      return new Date(a.f2Date) > new Date(b.f2Date) ? a : b;
    });

    let latestDate = new Date(latestDateRow.f2Date);
    console.log('latestDate : ', latestDate);

    let year = latestDate.getFullYear();

    let month = latestDate.getMonth();

    let monthlyValues = this.exchangeRateMap.get(year.toString());
    console.log('monthlyValues : ', monthlyValues);

    if (monthlyValues === undefined) {
      this.errorMessage = `The exchange rate that corresponds to report date: ${latestDateRow.validFrom} could not be found. Exchange rate is set to a value of 1.`;
      return 1
    }
    return monthlyValues[month];
  }

  calculateTypeTotal(row:any) {
    let total = row.gwmParts +
    row.bmwParts +
    row.salParts +
    row.thirdPartyParts +
    row.directLabour +
    row.inboundLogistics +
    row.warranty +
    row.importDuties +
    row.customsClearanceFee +
    row.fuelAndEnergy +
    row.variableManufacturingExpenses +
    row.outboundLogistics +
    row.nonDeductibleIndirectTaxesDirectlyLinkedToProject +
    row.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject +
    row.depreciation +
    row.indirectLabour +
    row.otherFixedExpenses +
    row.royaltiesTechnology +
    row.profit5Percent +
    row.yearlyProductionCostReduction;
    return total;
  }

  calculateOptionsTotal(row:any) {
    let total = row.productBodyAndRoofColours.bodyAndRoofColourTotal +
    row.productDesignTrims.designTrimsTotal +
    row.productPackets.packetTotal +
    row.productExecutions.executionTotal +
    row.productSingleSas.singleSATotal +
    row.profitForSA +
    row.royaltiesForSA;
    return total;
  }

  topFilter(totalDataSetSortedByTime: any[]){
    let totalDataSetSortedByTimeandFiltered = [];
    console.log('Do filter');
    // 0 0 0 1
    if(this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo !== undefined){
      console.log('0 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 0 0 1 0
    else if(this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo == undefined) {
      console.log('0 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom;
      });
    }
    // 0 0 1 1
    else if(this.selectedDerivatives.length < 1 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('0 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 0 0 0
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo == undefined){
      console.log('1 0 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      });
    }
    // 1 0 0 1
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom == undefined && this.effectiveTo !== undefined){
      console.log('1 0 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 0 1 0
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo == undefined){
      console.log('1 0 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom;
      });
    }
    // 1 0 1 1
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length < 1 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined){
      console.log('1 0 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 1 0 0
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom == undefined && this.effectiveTo == undefined){
      console.log('1 1 0 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.typeCode) ?  true : false;
      });
    }
    // 1 1 0 1
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom == undefined && this.effectiveTo !== undefined){
      console.log('1 1 0 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.typeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom <= this.effectiveTo;
      });
    }
    // 1 1 1 0
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo == undefined) {
      console.log('1 1 1 0');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.typeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom;
      });
    }
    // 1 1 1 1
    else if(this.selectedDerivatives.length > 0 && this.selectedTypeShadow.length > 0 && this.effectiveFrom !== undefined && this.effectiveTo !== undefined) {
      console.log('1 1 1 1');
      totalDataSetSortedByTimeandFiltered = totalDataSetSortedByTime.filter((row) => {
        return this.selectedDerivatives.includes(row.derivativeCode) ?  true : false;
      }).filter((row) => {
        return this.selectedTypeShadow.includes(row.typeCode) ?  true : false;
      }).filter((row) => {
        if(row.f2Date === null) { return false};
        let dateValidFrom = new Date(row.f2Date);
        return dateValidFrom >= this.effectiveFrom && dateValidFrom <= this.effectiveTo;
      });
    }
    return totalDataSetSortedByTimeandFiltered;
  }

  transformData(row: any) {
    let obj =  {
      derivativeCode: row.derivative,
      typeCode: row.type,
      typeName: row.designation,
      vinNumber: row.vin,
      f2Date: row.f2Date,
      fnDate: row.fnDate,
      includeRoyalties: row.royaltiesIndicator,
      transferPrice: row.transferPrice,
      gwmParts: row.productCategories.gwmParts,
      bmwParts: row.productCategories.bmwParts,
      salParts: row.productCategories.salParts,
      thirdPartyParts: row.productCategories.thirdPartyParts,
      directLabour: row.productCategories.directLabour,
      inboundLogistics: row.productCategories.inboundLogistics,
      warranty: row.productCategories.warranty,
      importDuties: row.productCategories.importDuties,
      customsClearanceFee: row.productCategories.customsClearanceFee,
      fuelAndEnergy: row.productCategories.fuelAndEnergy,
      variableManufacturingExpenses: row.productCategories.variableManufacturingExpenses,
      outboundLogistics: row.productCategories.outboundLogistics,
      nonDeductibleIndirectTaxesDirectlyLinkedToProject: row.productCategories.nonDeductibleIndirectTaxesDirectlyLinkedToProject,
      nonDeductibleIndirectTaxesNotDirectlyLinkedToProject: row.productCategories.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject,
      depreciation: row.productCategories.depreciation,
      indirectLabour: row.productCategories.indirectLabour,
      otherFixedExpenses: row.productCategories.otherFixedExpenses,
      royaltiesTechnology: row.productCategories.royaltiesForTechnology,
      profit5Percent: row.productCategories.profitFivePercent,
      yearlyProductionCostReduction: row.productCategories.yearlyProductionCostReduction,
      royaltiesForSA: row.royaltiesForOptions,
      profitForSA: row.profitForOptions,
      rules: row.rules,
    };


    //Body and roof colour
    let productBodyAndRoofColours = {};
    Object.assign(productBodyAndRoofColours, {bodyAndRoofColourTotal: row.productBodyAndRoofColours[0].total});
    row.productBodyAndRoofColours[0].productOptions.forEach(color => {
      Object.assign(productBodyAndRoofColours, {[this.replaceIllegalCharacters(color.description)]: color.numberOfKeys === 0 && color.amount === 0 ? ' - ' : color.amount});
    });

    //Design Trims
    let productDesignTrims = {};
    Object.assign(productDesignTrims, {designTrimsTotal: row.productDesignTrims[0].total});
    row.productDesignTrims[0].productOptions.forEach(trim => {
      Object.assign(productDesignTrims, {[this.replaceIllegalCharacters(trim.description)]: trim.numberOfKeys === 0 && trim.amount === 0 ? ' - ' : trim.amount});
    });

    //Packet
    let productPackets = {};
    Object.assign(productPackets, {packetTotal: row.productPackets[0].total});
    row.productPackets[0].productOptions.forEach(packet => {
      Object.assign(productPackets, {[this.replaceIllegalCharacters(packet.description)]: packet.numberOfKeys === 0 && packet.amount === 0 ? ' - ' : packet.amount});
    });

    //Execution
    let productExecutions = {};
    Object.assign(productExecutions, {executionTotal: row.productExecutions[0].total});
    row.productExecutions[0].productOptions.forEach(execution => {
      Object.assign(productExecutions, {[this.replaceIllegalCharacters(execution.description)]: execution.numberOfKeys === 0 && execution.amount === 0 ? ' - ' : execution.amount});
    });

    //Single SA
    let productSingleSas = {};
    Object.assign(productSingleSas, {singleSATotal: row.productSingleSas[0].total});
    row.productSingleSas[0].productOptions.forEach(singleSA => {
      Object.assign(productSingleSas, {[this.replaceIllegalCharacters(singleSA.description)]: singleSA.numberOfKeys === 0 && singleSA.amount === 0 ? ' - ' : singleSA.amount});
    });


    let finalObj = {...obj, productBodyAndRoofColours,productDesignTrims,productPackets,productExecutions,productSingleSas}
    return finalObj;
  }

  buildReportColumns(row:any) {

    let initialStructure:any[] = [
      {
        field: 'derivativeCode',
        headerName: 'derivativeCode',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 120,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      },
      {
        field: 'typeCode',
        headerName: 'typeCode',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      },
      {
        field: 'typeName',
        headerName: 'typeName',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      },
      {
        field: 'vinNumber',
        headerName: 'vinNumber',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      },
      {
        field: 'f2Date',
        headerName: 'f2Date',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 160,
        valueFormatter: this.dateTimeFormatter,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'fnDate',
        headerName: 'fnDate',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 160,
        valueFormatter: this.dateTimeFormatter,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'includeRoyalties',
        headerName: 'includeRoyaltiesApplicable',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      },
       {
        field: 'transferPrice',
        headerName: 'transferPrice',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'typeTotal',
        headerName: 'typeTotal',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'gwmParts',
        headerName: 'gwmParts',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'bmwParts',
        headerName: 'bmwParts',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'salParts',
        headerName: 'salParts',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'thirdPartyParts',
        headerName: 'thirdPartyParts',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'directLabour',
        headerName: 'directLabour',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'inboundLogistics',
        headerName: 'inboundLogistics',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'warranty',
        headerName: 'warranty',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'importDuties',
        headerName: 'importDuties',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'customsClearanceFee',
        headerName: 'customsClearanceFee',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'fuelAndEnergy',
        headerName: 'fuelAndEnergy',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'variableManufacturingExpenses',
        headerName: 'variableManufacturingExpenses',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'outboundLogistics',
        headerName: 'outboundLogistics',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'nonDeductibleIndirectTaxesDirectlyLinkedToProject',
        headerName: 'nondeductableIndirectTaxesLinked',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'nonDeductibleIndirectTaxesNotDirectlyLinkedToProject',
        headerName: 'nondeductableIndirectTaxes',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'depreciation',
        headerName: 'depreciation',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'indirectLabour',
        headerName: 'indirectLabour',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'otherFixedExpenses',
        headerName: 'otherFixedExpenses',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'royaltiesTechnology',
        headerName: 'royaltiesTechnology',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'profit5Percent',
        headerName: 'profit5Percent',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'yearlyProductionCostReduction',
        headerName: 'yearlyProductionCostReduction',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
       {
        field: 'optionsTotal',
        headerName: 'All Options (Total)',
        sortable: true,
        filter: true,
        resizable: true,
        minWidth : 150,
        valueFormatter: this.moneyFormatter,
        type: 'rightAligned',
        headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
       },
    ];

    for (let key in row) {
      //Body and roof colour
      if (key === 'productBodyAndRoofColours') {

        let productBodyAndRoofColours = {
          field: 'bodyAndRoofColour',
          headerName: 'bodyAndRoofColour',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        };

        let productBodyAndRoofColoursChildren = [];

        for (let key in row.productBodyAndRoofColours) {

          if (key === 'bodyAndRoofColourTotal') {
            let totalClosed = {
              field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
              headerName: 'bodyAndRoofColourTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'closed',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productBodyAndRoofColoursChildren.push(totalClosed);

            let totalOpen = {
              field: 'productBodyAndRoofColours.bodyAndRoofColourTotal',
              headerName: 'bodyAndRoofColourTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productBodyAndRoofColoursChildren.push(totalOpen);
          } else {
            let keyStr = 'productBodyAndRoofColours.' + key;
            let genericChildObject = {
              field: keyStr,
              headerName: key,
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productBodyAndRoofColoursChildren.push(genericChildObject);
          }
        }

        Object.assign(productBodyAndRoofColours, {children:productBodyAndRoofColoursChildren});

        initialStructure.push(productBodyAndRoofColours);

      }

      //Design Trims
      if (key === 'productDesignTrims'){
        let productDesignTrims = {
          field: 'designTrims',
          headerName: 'designTrims',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        };

        let productDesignTrimsChildren = [];
        for (let key in row.productDesignTrims) {
          if (key === 'designTrimsTotal') {
            let totalClosed = {
              field: 'productDesignTrims.designTrimsTotal',
              headerName: 'designTrimsTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'closed',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productDesignTrimsChildren.push(totalClosed);

            let totalOpen = {
              field: 'productDesignTrims.designTrimsTotal',
              headerName: 'designTrimsTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productDesignTrimsChildren.push(totalOpen);

          } else {
            let keyStr = 'productDesignTrims.' + key;
            let genericChildObject = {
              field: keyStr,
              headerName: key,
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productDesignTrimsChildren.push(genericChildObject);
          }
        }

        Object.assign(productDesignTrims, {children:productDesignTrimsChildren});

        initialStructure.push(productDesignTrims);

      }

      //Packet
      if (key === 'productPackets'){
        let productPackets = {
          field: 'packet',
          headerName: 'packet',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        };

        let productPacketsChildren = [];
        for (let key in row.productPackets) {
          if (key === 'packetTotal') {
            let totalClosed = {
              field: 'productPackets.packetTotal',
              headerName: 'packetTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'closed',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productPacketsChildren.push(totalClosed);

            let totalOpen = {
              field: 'productPackets.packetTotal',
              headerName: 'packetTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productPacketsChildren.push(totalOpen);

          } else {
            let keyStr = 'productPackets.' + key;
            let genericChildObject = {
              field: keyStr,
              headerName: key,
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productPacketsChildren.push(genericChildObject);
          }
        }

        Object.assign(productPackets, {children:productPacketsChildren});

        initialStructure.push(productPackets);

      }

      //Execution
      if (key === 'productExecutions'){
        let productExecutions = {
          field: 'execution',
          headerName: 'execution',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        };

        let productExecutionsChildren = [];
        for (let key in row.productExecutions) {
          if (key === 'executionTotal') {
            let totalClosed = {
              field: 'productExecutions.executionTotal',
              headerName: 'executionTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'closed',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productExecutionsChildren.push(totalClosed);

            let totalOpen = {
              field: 'productExecutions.executionTotal',
              headerName: 'executionTotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productExecutionsChildren.push(totalOpen);

          } else {
            let keyStr = 'productExecutions.' + key;
            let genericChildObject = {
              field: keyStr,
              headerName: key,
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productExecutionsChildren.push(genericChildObject);
          }
        }

        Object.assign(productExecutions, {children:productExecutionsChildren});

        initialStructure.push(productExecutions);

      }

      //Single SA
      if (key === 'productSingleSas'){
        let productSingleSas = {
          field: 'singleSA',
          headerName: 'singleSA',
          headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
        };

        let productSingleSasChildren = [];
        for (let key in row.productSingleSas) {
          if (key === 'singleSATotal') {
            let totalClosed = {
              field: 'productSingleSas.singleSATotal',
              headerName: 'singleSATotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'closed',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productSingleSasChildren.push(totalClosed);

            let totalOpen = {
              field: 'productSingleSas.singleSATotal',
              headerName: 'singleSATotal',
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productSingleSasChildren.push(totalOpen);

          } else {
            let keyStr = 'productSingleSas.' + key;
            let genericChildObject = {
              field: keyStr,
              headerName: key,
              sortable: true,
              filter: true,
              resizable: true,
              minWidth : 150,
              columnGroupShow: 'open',
              valueFormatter: this.moneyFormatter,
              type: 'rightAligned',
              headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
            }
            productSingleSasChildren.push(genericChildObject);
          }
        }

        Object.assign(productSingleSas, {children:productSingleSasChildren});

        initialStructure.push(productSingleSas);

      }
    }

    //Royalties for Options
    let royaltiesForSA = {
      field: 'royaltiesForSA',
      headerName: 'royaltiesForSA',
      sortable: true,
      filter: true,
      resizable: true,
      minWidth : 150,
      valueFormatter: this.moneyFormatter,
      type: 'rightAligned',
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }
    initialStructure.push(royaltiesForSA);

    //Profit for Options
    let profitForSA = {
      field: 'profitForSA',
      headerName: 'profitForSA',
      sortable: true,
      filter: true,
      resizable: true,
      minWidth : 150,
      valueFormatter: this.moneyFormatter,
      type: 'rightAligned',
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }
    initialStructure.push(profitForSA);

    //Rules
    let rules = {
      field: 'rules',
      headerName: 'salReportRules',
      sortable: true,
      filter: true,
      resizable: true,
      minWidth : 150,
      valueFormatter: this.moneyFormatter,
      type: 'rightAligned',
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }
    initialStructure.push(rules);

    this.runtimeCompilerData.columns = Array.from(initialStructure);
  }

  replaceIllegalCharacters(inputStr:String){
    return inputStr.replace(/\./g, ''); //.
  }

  transformExchangeRate(dataSet: any[]){
    dataSet.forEach((row,index) => {
      for(let key in row){
        if(key === 'productBodyAndRoofColours'
          || key === 'productDesignTrims'
          || key === 'productExecutions'
          || key === 'productPackets'
          || key === 'productSingleSas'){
          for(let innerkey in row[key]) {
            row[key][innerkey] = (row[key][innerkey] * this.exchangeRate).toFixed(2);
          }
        } else if (typeof row[key] === 'number'){
          row[key] = (row[key] * this.exchangeRate).toFixed(2);
        }
      }
    });
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  dateTimeFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY HH:mm');
  }
}
