import {AppComponent} from "./app.component";
import {FormTemplateComponent} from "./components/form-template/form-template.component";
import {BasicTableTemplateComponent} from "./components/basic-table-template/basic-table-template.component";
import {MasterDetailTemplateComponent} from "./components/master-detail-template/master-detail-template.component";
import {OverviewComponent} from "./components/overview/overview.component";
import {ImprintComponent} from "./components/imprint/imprint.component";
import {HeaderComponent} from "./components/header/header.component";
import {TranslateComponent} from "./components/translate-component/translate.component";
import {OrderHistoryComponent} from "./components/order/order-history/order-history.component";
import {OrderManagementComponent} from "./components/order/order-management/order-management.component";
import {WeightingsComponent} from "./components/weightings/weightings.component";
import {BmwexampleComponent} from "./components/bmwexample/bmwexample.component";
import {OrderGridComponent} from "./components/order/order-grid/order-grid.component";
import {WeightGridComponent} from "./components/weightings/weight-grid/weight-grid.component";
import {OrderSearchBarComponent} from "./components/order/order-search-bar/order-search-bar.component";
import {AgGridCellDatePickerComponent} from "./components/order/order-grid/ag-grid.celldatepicker.component";
import {OrderGridEditImageCellRenderComponent} from "./components/order/order-grid/order-grid.edit-image.cellrender.component";
import {OrderLatestEditableComponent} from "./components/order/order-latest/order-latest-editable.component";
import {BottomSaveBarComponent} from "./components/bottom-save-bar/bottom-save-bar.component";
import {MaterialSearchBarComponent} from "./components/weightings/material-search-bar/material-search-bar.component";
import {OrderStatusCellComponent} from "./components/order/order-grid/ag-grid.cellorderstatus.component";
import {OrderDetailComponent} from "./components/order/order-detail/order-detail.component";
import {OrderDetailViewComponent} from "./components/order/order-detail-view/order-detail-view.component";
import {ExchangeRatesManagementComponent} from "./components/exchange-rates/exchange-rates-management/exchange-rates-management.component";
import {ExchangeRatesOverviewComponent} from "./components/exchange-rates/exchange-rates-overview/exchange-rates-overview.component";
import {ExchangeRatesReleaseComponent} from "./components/exchange-rates/exchange-rates-release/exchange-rates-release.component";
import {ExchangeRatesGridComponent} from "./components/exchange-rates/exchange-rates-overview/exchange-rates-grid/exchange-rates-grid.component";
import {ExchangeRatesReleaseGridComponent} from "./components/exchange-rates/exchange-rates-release/exchange-rates-release-grid/exchange-rates-release-grid.component";
import {OrderGridEditableComponent} from "./components/order/order-grid-editable/order-grid-editable.component";
import {PriceSearchBarComponent} from "./components/price/continous-price/price-search-bar/price-search-bar.component";
import {ContinuosPriceOverviewComponent} from "./components/price/continous-price/continuos-price-overview/continuos-price-overview.component";
import {PriceHeaderComponent} from "./components/price/continous-price/price-header/price-header.component";
import {PriceComponent} from "./components/price/continous-price/price-component/price-component.component";
import {OrderComponent} from "./components/price/continous-price/order/order.component";
import {ExchangeRatesConfirmHeaderComponent} from "./components/exchange-rates/exchange-rates-confirm-header/exchange-rates-confirm-header.component";
import {ExchangeRatesConfirmComponent} from "./components/exchange-rates/exchange-rates-confirm/exchange-rates-confirm.component";
import {CurrencyOverviewComponent} from "./components/currency/currency-overview/currency-overview.component";
import {ManualPricesComponent} from "./components/price/manual-prices/manual-prices.component";
import {ManualPricesGridComponent} from "./components/price/manual-prices/manual-prices-grid/manual-prices-grid.component";
import {FreezePriceDetailsComponent} from "./components/price/freeze-price/freeze-price-details/freeze-price-details.component";
import {FreezePriceManagementComponent} from "./components/price/freeze-price/freeze-price-management/freeze-price-management.component";
import {FreezePriceOverviewComponent} from "./components/price/freeze-price/freeze-price-overview/freeze-price-overview.component";
import {FreezePriceOverviewGridComponent} from "./components/price/freeze-price/freeze-price-overview/freeze-price-overview-grid/freeze-price-overview-grid.component";
import {FreezePriceDetailsGridComponent} from "./components/price/freeze-price/freeze-price-details/freeze-price-details-grid/freeze-price-details-grid.component";
import {PriceMainComponentComponent} from "./components/price/continous-price/price-main-component/price-main-component.component";
import {FreezePriceMainDetailsComponent} from "./components/price/freeze-price/freeze-price-main-details/freeze-price-main-details.component";
import {FreezePriceConfirmHeaderComponent} from "./components/price/freeze-price/freeze-price-confirm-header/freeze-price-confirm-header.component";
import {BmwGridComponent} from "./components/order/bmw-grid/bmw-grid.component";
import {FreezePriceMainComponentComponent} from "./components/price/freeze-price/search/price-main-component/freeze-price-main-component.component";
import {FreezePriceSearchBarComponent} from "./components/price/freeze-price/search/price-search-bar/freeze-price-search-bar.component";
import {FreezePriceConfirmComponent} from "./components/price/freeze-price/freeze-price-confirm/freeze-price-confirm.component";
import {FreezePriceDateSearchComponent} from "./components/price/freeze-price/freeze-price-date-search/freeze-price-date-search.component";
import {PriceSearchByDateComponent} from "./components/price/freeze-price/search/price-search-by-date/price-search-by-date.component";
import {ProductMasterDataComponent} from "./components/product/product-master-data/product-master-data.component";
import {ProductConfigurationComponent} from "./components/product/product-configuration/product-configuration.component";
import {ProductMasterDataDisplayComponent} from "./components/product/product-master-data-display/product-master-data-display.component";
import {ProductMasterDataDisplayGridComponent} from "./components/product/product-master-data-display/product-master-data-display-grid/product-master-data-display-grid.component";
import { UserManagementComponent } from './components/users/user-management/user-management.component';
import { RolesOverviewComponent} from "./components/users/roles-overview/roles-overview.component";
import { RolesOverviewGridComponent} from "./components/users/roles-overview/roles-overview-grid/roles-overview-grid.component";
import { PermissionsComponent} from "./components/users/permissions/permissions.component";
import { RolePermissionMaintananceComponent} from "./components/users/role-permission-maintanance/role-permission-maintanance.component";
import { UsersOverviewGridComponent} from "./components/users/role-permission-maintanance/users-overview-grid/users-overview-grid.component";
import { FaasComboBoxComponent} from "./components/faas-combo-box";
import { ManufacturingComponent } from "./components/manufacturing/manufacturing.component";
import { OperationsDisplayComponent } from "./components/manufacturing/operations-display/operations-display.component";
import { OperationsDisplayGridComponent } from "./components/manufacturing/operations-display/operations-display-grid/operations-display-grid.component";
import { RoleGridComponent } from './components/users/roles-overview/role-grid/role-grid.component';
import { NewOperationComponent} from "./components/manufacturing/new-operation/new-operation.component";
import { OperationDetailsComponent } from './components/manufacturing/operation-details/operation-details.component';
import { OperationDetailsGridComponent } from './components/manufacturing/operation-details/operation-details-grid/operation-details-grid.component';
import { OperationTypeSaGridComponent} from "./components/manufacturing/operation-type-sa/operation-type-sa-grid/operation-type-sa-grid.component";
import {OperationTypeSaComponent} from "./components/manufacturing/operation-type-sa/operation-type-sa.component";
import { CostAllocationsComponent } from './components/manufacturing/operation-details/cost-allocations/cost-allocations.component';
import { CostAllocationsGridComponent } from './components/manufacturing/operation-details/cost-allocations/cost-allocations-grid/cost-allocations-grid.component';
import { AddAttachmentComponent } from './components/manufacturing/add-attachment/add-attachment.component';
import { WorkflowComponent } from './components/manufacturing/workflow/workflow.component';
import {HomeComponent} from "./components/home/home.component";
import {MenuComponent} from "./components/menu/menu.component";
import { NumericEditorComponent } from "./components/manufacturing/numeric-editor/NumericEditorComponent";
import {OperationInformationCellRenderer} from "./components/manufacturing/operations-display/operations-display-grid/operation-information-cell-renderer.component";
import {EditOperationComponent} from "./components/manufacturing/edit-operation/edit-operation.component";
import {CloneOperationComponent} from "./components/manufacturing/clone-operation/clone-operation.component";
import {EditSelectionDialogComponent} from "./components/manufacturing/operation-details/edit-selection-dialog/edit-selection-dialog.component";
import { CostAllocationsSaComponent } from './components/manufacturing/operation-details/cost-allocations-sa/cost-allocations-sa.component';
import { CostAllocationsSaGridComponent } from './components/manufacturing/operation-details/cost-allocations-sa/cost-allocations-sa-grid/cost-allocations-sa-grid.component';
import {ManufacturingCostsComponent} from "./components/manufacturing/manufacturing-costs/manufacturing-costs.component";
import {ManufacturingCostsGridComponent} from "./components/manufacturing/manufacturing-costs/manufacturing-costs-grid/manufacturing-costs-grid.component";
import {DownloadAttachmentComponent} from "./components/manufacturing/download-attachment/download-attachment.component";
import { PriceExchangeRateComponent } from './components/price/continous-price/price-exchange-rate/price-exchange-rate.component';
import { PriceWeightComponent } from './components/price/continous-price/price-weight/price-weight.component';
import {CostAllocationsSaDialogComponent} from "./components/manufacturing/operation-details/cost-allocations-sa-dialog/cost-allocations-sa-dialog.component";
import { ManufacturingReportsComponent } from './components/manufacturing/manufacturing-reports/manufacturing-reports.component';
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {ProductWeekSearchBarComponent} from "./components/aggregation/product-week-search-bar/product-week-search-bar.component";
import {AggregationHeaderComponent} from "./components/aggregation/aggregation-header/aggregation-header.component";
import {AggregationUpdatePriceComponent} from "./components/aggregation/aggregation-update-price/aggregation-update-price.component";
import { PartSearchDialogComponent } from './components/order/part-search-dialog/part-search-dialog.component';
import { PartGridComponent } from './components/order/part-search-dialog/part-grid/part-grid.component';
import {RuleHistoryComponent} from "./components/manufacturing/rule-history/rule-history.component";
import {RuleHistoryGridComponent} from "./components/manufacturing/rule-history/rule-history-grid/rule-history-grid.component";
import {RecalcPriceGridComponent} from "./components/aggregation/recalc-price-grid/recalc-price-grid.component";
import {ManufacturingCostsActionedComponent} from "./components/manufacturing/manufacturing-costs-actioned/manufacturing-costs-actioned.component";
import {ManufacturingCostsActionedGridComponent} from "./components/manufacturing/manufacturing-costs-actioned/manufacturing-costs-actioned-grid/manufacturing-costs-actioned-grid.component";
import {PricingAndPaymentsGridComponent} from "./components/manufacturing/manufacturing-costs-actioned/pricing-and-payments-grid/pricing-and-payments-grid.component";
import {InformationCellRenderer} from "./components/manufacturing/manufacturing-costs-actioned/manufacturing-costs-actioned-grid/information-cell-renderer.component";
import {VehicleStatusGridComponent} from './components/vehicle/vehicle-status/vehicle-status-grid/vehicle-status-grid.component';
import {VehicleStatusComponent} from './components/vehicle/vehicle-status/vehicle-status.component';
import {VehicleComponent} from './components/vehicle/vehicle.component';
import { PaidTransferpriceDynamicComponent } from './components/manufacturing/manufacturing-reports/paid-transferprice-dynamic/paid-transferprice-dynamic.component';
import { AccumulatedPaidTransferpriceDynamicComponent } from './components/manufacturing/manufacturing-reports/accumulated-paid-transferprice-dynamic/accumulated-paid-transferprice-dynamic.component';
import { ValidTransferPriceDynamicComponent} from './components/manufacturing/manufacturing-reports/valid-transfer-price-dynamic/valid-transfer-price-dynamic.component';
import { TransitionOfAdjustmentReasonDynamicComponent } from './components/manufacturing/manufacturing-reports/transition-of-adjustment-reason-dynamic/transition-of-adjustment-reason-dynamic.component';
import {VehicleInformationCellRenderer} from "./components/vehicle/vehicle-status/vehicle-status-grid/vehicle-information-cell-renderer.component";
import {AddCostAttachmentComponent} from "./components/manufacturing/operation-details/add-cost-attachment/add-cost-attachment.component";
import {TypeNumericEditorComponent} from "./components/manufacturing/numeric-editor/TypeNumericEditorComponent";
import {CostAllocationsDialogComponent} from "./components/manufacturing/operation-details/cost-allocations/cost-allocations-dialog/cost-allocations-dialog.component";
import { QuicksightReportsParentComponent } from "./components/quicksight-reports/quicksight-reports-parent/quicksight-reports-parent.component";
import { QuicksightDashboardTargetPriceComponent } from "./components/quicksight-reports/quicksight-dashboards/target-price/quicksight-dashboard-target-price.component";
import { QuicksightDashboardReport1Component } from "./components/quicksight-reports/quicksight-dashboards/report-1/quicksight-dashboard-report-1.component";
import { QuicksightDashboardReport2Component } from "./components/quicksight-reports/quicksight-dashboards/report-2/quicksight-dashboard-report-2.component";
import { QuicksightDashboardReport4Component } from "./components/quicksight-reports/quicksight-dashboards/report-4/quicksight-dashboard-report-4.component";
import { QuicksightDashboardReport3Component } from "./components/quicksight-reports/quicksight-dashboards/report-3/quicksight-dashboard-report-3.component";


export const FaasComponents = [AppComponent,
  FormTemplateComponent,
  BasicTableTemplateComponent,
  MasterDetailTemplateComponent,
  OverviewComponent,
  ImprintComponent,
  HeaderComponent,
  TranslateComponent,
  OrderHistoryComponent,
  OrderManagementComponent,
  WeightingsComponent,
  BmwexampleComponent,
  OrderGridComponent,
  WeightGridComponent,
  OrderSearchBarComponent,
  AgGridCellDatePickerComponent,
  OrderGridEditImageCellRenderComponent,
  OrderLatestEditableComponent,
  BottomSaveBarComponent,
  MaterialSearchBarComponent,
  OrderStatusCellComponent,
  OrderDetailComponent,
  OrderDetailViewComponent,
  ExchangeRatesManagementComponent,
  ExchangeRatesOverviewComponent,
  ExchangeRatesReleaseComponent,
  ExchangeRatesGridComponent,
  ExchangeRatesReleaseGridComponent,
  OrderGridEditableComponent,
  PriceSearchBarComponent,
  ContinuosPriceOverviewComponent,
  PriceHeaderComponent,
  PriceComponent,
  OrderComponent,
  ExchangeRatesConfirmComponent,
  ExchangeRatesConfirmHeaderComponent,
  CurrencyOverviewComponent,
  ManualPricesComponent,
  ManualPricesGridComponent,
  FreezePriceDetailsComponent,
  FreezePriceManagementComponent,
  FreezePriceOverviewComponent,
  FreezePriceOverviewGridComponent,
  FreezePriceDetailsGridComponent,
  PriceMainComponentComponent,
  FreezePriceMainDetailsComponent,
  FreezePriceConfirmHeaderComponent,
  BmwGridComponent,
  FreezePriceMainComponentComponent,
  FreezePriceSearchBarComponent,
  FreezePriceConfirmComponent,
  FreezePriceDateSearchComponent,
  PriceSearchByDateComponent,
  ProductMasterDataComponent,
  ProductConfigurationComponent,
  ProductMasterDataDisplayComponent,
  ProductMasterDataDisplayGridComponent,
  PriceSearchByDateComponent,
  UserManagementComponent,
  RolesOverviewComponent,
  RolesOverviewGridComponent,
  PermissionsComponent,
  RolePermissionMaintananceComponent,
  UsersOverviewGridComponent,
  FaasComboBoxComponent,
  ManufacturingComponent,
  OperationsDisplayComponent,
  OperationsDisplayGridComponent,
  OperationTypeSaComponent,
  OperationTypeSaGridComponent,
  RoleGridComponent,
  NewOperationComponent,
  OperationDetailsComponent,
  OperationDetailsGridComponent,
  CostAllocationsComponent,
  CostAllocationsGridComponent,
  AddAttachmentComponent,
  AddCostAttachmentComponent,
  WorkflowComponent,
  HomeComponent,
  MenuComponent,
  NumericEditorComponent,
  TypeNumericEditorComponent,
  EditOperationComponent,
  CloneOperationComponent,
  OperationInformationCellRenderer,
  VehicleInformationCellRenderer,
  InformationCellRenderer,
  EditOperationComponent,
  EditSelectionDialogComponent,
  CostAllocationsSaComponent,
  CostAllocationsSaGridComponent,
  ManufacturingCostsComponent,
  ManufacturingCostsGridComponent,
  DownloadAttachmentComponent,
  PriceExchangeRateComponent,
  PriceWeightComponent,
  CostAllocationsSaDialogComponent,
  CostAllocationsDialogComponent,
  ManufacturingReportsComponent,
  NotFoundComponent,
  PartSearchDialogComponent,
  PartGridComponent,
  ManufacturingReportsComponent,
  ManufacturingReportsComponent,
  NotFoundComponent,
  ProductWeekSearchBarComponent,
  AggregationHeaderComponent,
  AggregationUpdatePriceComponent,
  RuleHistoryComponent,
  RuleHistoryGridComponent,
  RecalcPriceGridComponent,
  ManufacturingCostsActionedComponent,
  ManufacturingCostsActionedGridComponent,
  PricingAndPaymentsGridComponent,
  VehicleStatusComponent,
  VehicleStatusGridComponent,
  VehicleComponent,
  PaidTransferpriceDynamicComponent,
  AccumulatedPaidTransferpriceDynamicComponent,
  ValidTransferPriceDynamicComponent,
  TransitionOfAdjustmentReasonDynamicComponent,
  QuicksightReportsParentComponent,
  QuicksightDashboardTargetPriceComponent,
  QuicksightDashboardReport1Component,
  QuicksightDashboardReport2Component,
  QuicksightDashboardReport3Component,
  QuicksightDashboardReport4Component
];
