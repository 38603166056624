import {AuthConfig} from "angular-oauth2-oidc";
import {environment} from "../environments/environment.int";

export const authConfig: AuthConfig = {

  issuer: environment.sso.redirectUri.includes("localhost") || environment.sso.redirectUri.includes(environment.internalUrlPattern) ? environment.sso.internal.issuer : environment.sso.external.issuer,
  responseType: 'token id_token',
  redirectUri: environment.sso.redirectUri,
  clientId: environment.sso.redirectUri.includes("localhost") || environment.sso.redirectUri.includes(environment.internalUrlPattern) ? environment.sso.internal.clientId : environment.sso.external.clientId,
  scope: 'openid profile email groups'

}
