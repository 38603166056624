import {Injectable} from '@angular/core';
import {ServiceBase} from "../../../services/service.base";
import {Apollo} from "apollo-angular";
import {
  HelpDocumentType,
  ManufacturingCost,
  ManufacturingCostChangeInput, ManufacturingCostHistory,
  Operation,
  OperationChangeInput,
  TransferCategoryCost
} from "../model/operations.model";
import {faas} from "../../../constants/faas.constants";
import {NEW_OPERATION} from "../new-operation/new.operation.mutations";
import {
  DELETE_OPERATION_BY_PLANT_AND_ID,
  DELETE_OPERATIONS_BY_IDS
} from "../operations-display/operation.graphql.mutation"
import {Plant} from "../../order/model/order.model";
import {
  ALL_OPERATION_BY_PLANT, HELP_DOCUMENT_BY_NAME, HelpDocumentResponse,
  OPERATION_FILE_BY_ID,
  OPERATION_HISTORY_BY_ID,
  OPERATION_STATUS_BY_ID,
  OperationFileResponse,
  OperationHistoryResponse,
  OperationsQueryResponse,
  OperationStatusResponse
} from "../operations-display/operation.graphql.query";
import {
  CHILD_OPERATION_BY_PARENT_ID,
  ChildOperationDetailsQueryResponse,
  OPERATION_BY_ID,
  OPERATION_BY_NAME,
  OperationDetailsByNameQueryResponse,
  OperationDetailsQueryResponse
} from "../operation-details/operation-details.graphql.query";
import {
  MANUFACTURINGCOST_BY_PLANT,
  ManufacturingCostQueryResponse
} from '../operation-details/cost-allocations/cost-allocations.graphql.query';

import {ManufacturingCostsTransformer} from './manufacturing-costs-transformer';
import {
  ALLOCATE_MANUFACTURING_COST_TO_OPERATION,
  DELETE_FILE_FOR_OPERATION,
  REMOVE_MANUFACTURING_COSTS,
  UPLOAD_FILE_FOR_COST_ALLOCATIONS,
  UPLOAD_FILE_FOR_OPERATION
} from '../operation-details/operation-details.graphql.mutation';

import {CostAllocationsTransformer} from './cost-allocations-transformer';
import {UPDATE_OPERATION} from "../edit-operation/edit-operation.graphql.mutation";
import {CLONE_OPERATION} from "../clone-operation/clone.operation.mutations";
import {UPDATE_MANUFACTURING_COST} from "../operation-details/edit-selection-dialog/edit-selection-dialog.graphql.mutation";
import {ALLOCATIONS_CHANGES_SAVE} from '../operation-details/cost-allocations/cost-allocations.graphql.mutation';
import {AuthService} from "../../../services/auth.service";
import {WorkflowFactory} from "../factory/workflow/WorkflowFactory";
import {
  ALL_MANUFACTURING_COSTS_BY_PLANT,
  ALL_STATUSES_MANUFACTURING_COSTS_BY_PLANT,
  AllStatusesManufacturingCostHistoryQueryResponse,
  ManufacturingCostHistoryQueryResponse
} from "../manufacturing-costs/manufacturing-costs.graphql.query";
import {TypeSpecialEquipmentTransformer} from "../../product/service/type-special-equipment-transformer";
import {ManufacturingCostsHistoryTransformer} from "./manufacturing-cost-history-transformer";
import {ProductConfigurationChangeInput, Type} from "../../product/model/product.model";
import {
  OperationsActionedResponse,
  RETRIEVE_OPERATIONS_TO_BE_ACTIONED
} from '../manufacturing-costs-actioned/manufacturing-costs-actioned.query';
import {OperationsCountResponse, RETRIEVE_OPERATIONS_TO_BE_ACTIONED_COUNT} from '../manufacturing.component.query';


@Injectable({
  providedIn: 'root'
})
export class OperationService extends ServiceBase {

  private manufacturingCostsTransformer: ManufacturingCostsTransformer;
  private costAllocationsTransformer: CostAllocationsTransformer;
  private typeSpecialEquipmentTransformer: TypeSpecialEquipmentTransformer;
  private _apollo: Apollo;
  private manufacturingCostsHistoryTransformer: ManufacturingCostsHistoryTransformer;

  constructor(apollo: Apollo,
              private authService: AuthService) {
    super(apollo);
    this._apollo = apollo;
    this.manufacturingCostsTransformer = new ManufacturingCostsTransformer();
    this.costAllocationsTransformer = new CostAllocationsTransformer();
    this.typeSpecialEquipmentTransformer = new TypeSpecialEquipmentTransformer();
    this.manufacturingCostsHistoryTransformer = new ManufacturingCostsHistoryTransformer();
  }

  addNewOperation(model: Operation) {
    const variable = {
      operation: model
    };
    return this.mutate(variable, NEW_OPERATION , faas.productServiceClientName);
  }

  getOperationDetailByName(name: String) {
    const variable = {
      name: name
    };
    return this.query<OperationDetailsByNameQueryResponse>(variable, OPERATION_BY_NAME, faas.productServiceClientName);
  }

  cloneOperation(model: OperationChangeInput) {
    const variable = {
      operationInput: model
    };
    return this.mutate(variable, CLONE_OPERATION , faas.productServiceClientName);
  }

  allocateManufacturingCostToOperation(operationChangeInput: OperationChangeInput){
    const variable = {
      operationInput: operationChangeInput
    };
    return this.mutate(variable, ALLOCATE_MANUFACTURING_COST_TO_OPERATION, faas.productServiceClientName);
  }

  deleteOperation(identifier: number) {
    const variable = {
      internalIdentifier: identifier,
    };
    return this.mutate(variable, DELETE_OPERATION_BY_PLANT_AND_ID, faas.productServiceClientName);
  }

  deleteOperations(identifiers: number[]) {
    const variable = {
      internalIdentifiers: identifiers,
    };
    return this.mutate(variable, DELETE_OPERATIONS_BY_IDS, faas.productServiceClientName);
  }

  updateOperation(operationInput: OperationChangeInput) {
    const variable = {
      operationInput: operationInput
    };
    return this.mutate(variable, UPDATE_OPERATION, faas.productServiceClientName);
  }

  updateManufacturingCost(manufacturingCostChangeInput: ManufacturingCostChangeInput) {
    const variable = {
      manufacturingCost: manufacturingCostChangeInput
    };
    return this.mutate(variable, UPDATE_MANUFACTURING_COST, faas.productServiceClientName);
  }

  saveManufacturingCostsSA(params:any) {
    let input:ManufacturingCostChangeInput;
    input = this.costAllocationsTransformer.transformToManufacturingCostSAChangeInput(params);

    const variable = {
      manufacturingCost : input
    };
    return this.mutate(variable, ALLOCATIONS_CHANGES_SAVE, faas.productServiceClientName);
  }

  saveManufacturingCosts(params:any) {
    let input:ManufacturingCostChangeInput = this.costAllocationsTransformer.transformToManufacturingCostChangeInput(params);

    const variable = {
      manufacturingCost : input
    };
    return this.mutate(variable, ALLOCATIONS_CHANGES_SAVE, faas.productServiceClientName);
  }

  allOperations() {
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<OperationsQueryResponse>(variable, ALL_OPERATION_BY_PLANT, faas.productServiceClientName);
  }

  getOperationsToBeActioned() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<OperationsActionedResponse>(variable, RETRIEVE_OPERATIONS_TO_BE_ACTIONED, faas.productServiceClientName);
  }

  getOperationsToBeActionedCount() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<OperationsCountResponse>(variable, RETRIEVE_OPERATIONS_TO_BE_ACTIONED_COUNT, faas.productServiceClientName);
  }

  allManufacturingCosts() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<ManufacturingCostHistoryQueryResponse>(variable, ALL_MANUFACTURING_COSTS_BY_PLANT, faas.productServiceClientName);
  }

  allStatusesManufacturingCosts() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<AllStatusesManufacturingCostHistoryQueryResponse>(variable, ALL_STATUSES_MANUFACTURING_COSTS_BY_PLANT, faas.productServiceClientName);
  }

  getOperationFileById(fileId: number){
    const variable = {
      id: fileId
    };
    return this.query<OperationFileResponse>(variable, OPERATION_FILE_BY_ID, faas.productServiceClientName);
  }

  getHelpDocument(documentType: HelpDocumentType) {
    const variable = {
      helpDocumentType: documentType
    };
    return this.query<HelpDocumentResponse>(variable, HELP_DOCUMENT_BY_NAME, faas.productServiceClientName);
  }

  getOperationHistoryById(operationId: number){
    const variable = {
      id: operationId
    };
    return this.query<OperationHistoryResponse>(variable, OPERATION_HISTORY_BY_ID, faas.productServiceClientName);
  }

  getOperationStatusById(operationId: number){
    const variable = {
      id: operationId
    };
    return this.query<OperationStatusResponse>(variable, OPERATION_STATUS_BY_ID, faas.productServiceClientName);
  }

  getOperationDetail(operationId: number) {
    const variable = {
      id: operationId
    };
    return this.query<OperationDetailsQueryResponse>(variable, OPERATION_BY_ID, faas.productServiceClientName);
  }

  getChildOperationByParentId(parentOperationId: number) {
    const variable = {
      id: parentOperationId
    };
    return this.query<ChildOperationDetailsQueryResponse>(variable, CHILD_OPERATION_BY_PARENT_ID, faas.productServiceClientName);
  }


  getAttachments(operationId: number) {
    const variable = {
      operationInput: operationId
    };
    return this.query<OperationDetailsQueryResponse>(variable, OPERATION_BY_ID, faas.productServiceClientName);
  }

  getAllocations(plant: Plant, ids: number[]){
    const variable = {
      plant: plant,
      ids: ids
    }
    return this.query<ManufacturingCostQueryResponse>(variable, MANUFACTURINGCOST_BY_PLANT, faas.productServiceClientName);
  }

  workflow(action: string , operation: OperationChangeInput) {
    return new WorkflowFactory(this._apollo).executeWorkFlow(action, operation);
  }

  mapManufacturingCostData(operationId: number, manufacturingCosts: ManufacturingCost[], executionCountryCodes: Type[]) {
    return this.manufacturingCostsTransformer.transformManufacturingCosts(operationId, manufacturingCosts, executionCountryCodes);
  }

  mapAllocationData(transferCategoryCosts: TransferCategoryCost[], priceId: number) {
    return this.costAllocationsTransformer.transformToRowData(transferCategoryCosts, priceId);
  }

  mapAllocationSpecialEquipmentData(allocationDetails: any[], selectedDetail:any, specialEquipment:any, packet:any) {
    return this.costAllocationsTransformer.transformSpecialEquipmentToRowData(allocationDetails, selectedDetail, specialEquipment, packet);
  }

  uploadFileForOperation(operationChangeInput: OperationChangeInput){
    const variable = {
      operationInput: operationChangeInput
    };
    return this.mutate(variable, UPLOAD_FILE_FOR_OPERATION, faas.productServiceClientName);
  }

  uploadFileForCostAllocations(operationChangeInput: OperationChangeInput) {
    const variable = {
      operationInput: operationChangeInput
    };
    return this.mutate(variable, UPLOAD_FILE_FOR_COST_ALLOCATIONS, faas.productServiceClientName);
  }

  deleteFileForOperation(operationInternalIdentifier: number, fileInternalIdentifier: number){
    const variable = {
      operationInternalIdentifier: operationInternalIdentifier,
      fileInternalIdentifier: fileInternalIdentifier
    };
    return this.mutate(variable, DELETE_FILE_FOR_OPERATION, faas.productServiceClientName);
  }

  removeManufacturingCosts(manufacturingCostChangeInput: ManufacturingCostChangeInput) {
    const variable = {
      manufacturingCostChangeInput: manufacturingCostChangeInput
    };
    return this.mutate(variable, REMOVE_MANUFACTURING_COSTS, faas.productServiceClientName);
  }

  canCapture() {
    let permissions = ['T_VP_Bearbeiter_SAL','T_VP_Bearbeiter_GWM'];
    return this.authService.checkPermission(permissions);
  }

  canReview() {
    let permissions = ['T_VP_Pruefer_SAL','T_VP_Pruefer_GWM'];
    return this.authService.checkPermission(permissions);
  }

  canRelease() {
    let permissions = ['T_VP_Freigeber_BMW_SAL','T_VP_Freigeber_GWM'];
    return this.authService.checkPermission(permissions);
  }

  dispose(): void {
  }

  getTypeSpecialEquipmentGrouping() {
    return this.typeSpecialEquipmentTransformer.getTypeSpecialEquipmentGrouping();
  }

  manufacturingCostsHistoryExists(productConfigurationChangeInput :ProductConfigurationChangeInput, manufacturingCostHistoryByPlant :ManufacturingCostHistory[]): boolean {
    let manufacturingCostExists: boolean = false;
    manufacturingCostHistoryByPlant.map(row => {
      let manufacturingCostsHistory = this.manufacturingCostsHistoryTransformer.transformManufacturingCostHistory(row);

      productConfigurationChangeInput.types.forEach(type => {
        if (type.code == manufacturingCostsHistory.type.code) {
          console.log(type.code)
          manufacturingCostExists = true;
        }
      });

      if(productConfigurationChangeInput.inactivate){
        productConfigurationChangeInput.specialEquipments?.forEach(specialEquipment => {
          if (specialEquipment.internalIdentifier == manufacturingCostsHistory.specialEquipmentIdentifier) {
            manufacturingCostExists = true;
          }
        });

        productConfigurationChangeInput.packets?.forEach(packet => {
          if (packet.internalIdentifier == manufacturingCostsHistory.packetIdentifier) {
            manufacturingCostExists = true;
          }
        });
      } else {
        productConfigurationChangeInput.specialEquipments?.forEach(specialEquipment => {
          if (specialEquipment.code == manufacturingCostsHistory.specialEquipmentCode) {
            manufacturingCostExists = true;
          }
        });
      }
    });
    return manufacturingCostExists;
  }

}
