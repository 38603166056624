import { Component, OnInit } from '@angular/core';
import { tableData, columns } from 'src/app/mock-data';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';

@Component({
  templateUrl: './basic-table-template.component.html',
  styleUrls: ['./basic-table-template.component.less'],
})
export class BasicTableTemplateComponent implements OnInit {
  columns = columns;
  data = tableData;
  constructor(breadcrumbs: BreadcrumbsService) {
    breadcrumbs.update([
      {
        label: 'Garage',
        url: '/',
      },
      {
        label: 'Ordered Cars',
        url: '/',
      },
      {
        label: 'Basic Table Template',
        url: '/basic-table-template',
      },
    ]);
  }

  ngOnInit(): void {}
}
