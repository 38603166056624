export const faas = {
  currentOrderSearchParameters: 'current.orders.search.parameters',
  freezePriceOverviewParameters: 'price.freeze.overview.parameters',
  userWideRolesAndPermissions: 'faas.current.user.token',
  userRolesForPlant: 'user.roles.permissions',
  profileServiceClientName: 'profile',
  productServiceClientName: 'product',
  reportServiceClientName: 'report',
  orderServiceClientName: 'order',
  aggregationServiceClientName: 'periodClosing',
  pimaServiceClientName: 'pima',
  orderServiceBatchClientName: 'orderBatch',
  contentType: 'application/octet-stream'
};
