import gql from "graphql-tag";

export const DELETE_OPERATION_BY_PLANT_AND_ID = gql`
  mutation ($internalIdentifier: Long!) {
    deleteOperation(internalIdentifier: $internalIdentifier)
 }`;

export const DELETE_OPERATIONS_BY_IDS = gql`
  mutation ($internalIdentifiers: [Long]!) {
    deleteOperations(internalIdentifiers: $internalIdentifiers)
 }`;
