import {EMPTY_STRING} from "../../../../shared/functions/typescript.utils";

interface ContinuousPriceEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextContinuousPriceEnums: Record<LocalCodes, ContinuousPriceEnum[]> = {
  en: [
    //Order Type
    {name: 'SERIES', translated: 'SERIES'},
    {name: 'SERIE', translated: 'SERIES'},
    {name: 'SERIES_SPECIAL_ORDER', translated: 'SERIES_SPECIAL_ORDER'},
    {name: 'SERIE_SONDERBESTELLUNG', translated: 'SERIES_SPECIAL_ORDER'},

    //Quantity Unit
    {name: 'PIECE', translated: 'PIECE'},
    {name: 'STUECK', translated: 'PIECE'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'METER', translated: 'METER'},
    {name: 'SA', translated: 'SA'},
    {name: 'S', translated: 'S'},

    {name: 'DOZEN', translated: 'DOZEN'},
    {name: 'DUZEND', translated: 'DOZEN'},
    {name: 'PACKAGE', translated: 'PACKAGE'},
    {name: 'PAKET', translated: 'PACKAGE'},
    {name: 'MILLIMETER', translated: 'MILLIMETER'},
    {name: 'CENTIMETER', translated: 'CENTIMETER'},
    {name: 'ZENTIMETER', translated: 'CENTIMETER'},
    {name: 'DECIMETER', translated: 'DECIMETER'},
    {name: 'DEZIMETER', translated: 'DECIMETER'},
    {name: 'INCH', translated: 'INCH'},
    {name: 'FEET', translated: 'FEET'},
    {name: 'YARD', translated: 'YARD'},
    {name: 'QUAD MM', translated: 'QUAD MM'},
    {name: 'QUAD. MM', translated: 'QUAD MM'},
    {name: 'SQUARE CENTIMETERS', translated: 'SQUARE CENTIMETERS'},
    {name: 'QUAD. CM', translated: 'SQUARE CENTIMETERS'},
    {name: 'SQUARE DECIMETERS', translated: 'SQUARE DECIMETERS'},
    {name: 'QUAD. DM', translated: 'SQUARE DECIMETERS'},
    {name: 'SQUARE INCH', translated: 'SQUARE INCH'},
    {name: 'QUAD. INCH', translated: 'SQUARE INCH'},
    {name: 'SQUARE METERS', translated: 'SQUARE METERS'},
    {name: 'QUAD. M', translated: 'SQUARE METERS'},
    {name: 'SQUARE FEET', translated: 'SQUARE FEET'},
    {name: 'QUAD. F', translated: 'SQUARE FEET'},
    {name: 'CUBIC MILLIMETER', translated: 'CUBIC MILLIMETER'},
    {name: 'KUBIK MM', translated: 'CUBIC MILLIMETER'},
    {name: 'CUBIC CENTIMETERS', translated: 'CUBIC CENTIMETERS'},
    {name: 'KUBIK CM', translated: 'CUBIC CENTIMETERS'},
    {name: 'CENTISTERE', translated: 'CENTISTERE'},
    {name: 'CUBIC DECIMETER', translated: 'CUBIC DECIMETER'},
    {name: 'KUBIK DM', translated: 'CUBIC DECIMETER'},
    {name: 'CUBIC INCH', translated: 'CUBIC INCH'},
    {name: 'KUBIK INCH', translated: 'CUBIC INCH'},
    {name: 'CUBIC FEET', translated: 'CUBIC FEET'},
    {name: 'KUBIK FEET', translated: 'CUBIC FEET'},
    {name: 'CUBIC METER', translated: 'CUBIC METER'},
    {name: 'KUBIK M', translated: 'CUBIC METER'},
    {name: 'GRAMM', translated: 'GRAMM'},
    {name: 'GRAM', translated: 'GRAMM'},
    {name: 'OUNCE', translated: 'OUNCE'},
    {name: 'POUND', translated: 'POUND'},
    {name: 'KILOGRAM', translated: 'KILOGRAM'},
    {name: 'METRIC TONS', translated: 'METRIC TONS'},
    {name: 'TONNEN', translated: 'METRIC TONS'},
    {name: 'MILLILITER', translated: 'MILLILITER'},
    {name: 'DECILITER', translated: 'DECILITER'},
    {name: 'DEZILITER', translated: 'DECILITER'},
    {name: 'HECTOLITER', translated: 'HECTOLITER'},
    {name: 'HEKTOLITER', translated: 'HECTOLITER'},
    {name: 'AM_QUART', translated: 'AM_QUART'},
    {name: 'AM QUART', translated: 'AM_QUART'},
    {name: 'BRIT_QUART', translated: 'BRIT_QUART'},
    {name: 'BRIT QUART', translated: 'BRIT_QUART'},
    {name: 'AM_GALLON', translated: 'AM_GALLON'},
    {name: 'AM GALLON', translated: 'AM_GALLON'},
    {name: 'BRIT_GALLON', translated: 'BRIT_GALLON'},
    {name: 'BRIT GALLON', translated: 'BRIT_GALLON'},
    {name: 'AM_PINT', translated: 'AM_PINT'},
    {name: 'AM PINT', translated: 'AM_PINT'},
    {name: 'BRIT_PINT', translated: 'BRIT_PINT'},
    {name: 'BRIT PINT', translated: 'BRIT_PINT'},
    {name: 'AM_GILLS', translated: 'AM_GILLS'},
    {name: 'AM GILLS', translated: 'AM_GILLS'},
    {name: 'BRIT_GILLS', translated: 'BRIT_GILLS'},
    {name: 'BRIT GILLS', translated: 'BRIT_GILLS'},

    //Order State
    {name: 'CLOSED', translated: 'CLOSED'},
    {name: 'GESCHLOSSEN', translated: 'CLOSED'},
    {name: 'OPENED', translated: 'OPENED'},
    {name: 'GEÖFFNET', translated: 'OPENED'},
    {name: 'CANCELLED', translated: 'CANCELLED'},
    {name: 'STORNIERT', translated: 'CANCELLED'},

    //Exchange Rate Type
    {name: 'Current', translated: 'Current'},
    {name: 'Aktueller', translated: 'Current'},
    {name: 'Monthly', translated: 'Monthly'},
    {name: 'Monatlich', translated: 'Monthly'},
    {name: 'Per November', translated: 'Per November'},
    {name: 'Pro November', translated: 'Per November'},
    {name: 'Final', translated: 'Final'},
    {name: 'Finale', translated: 'Final'},

    //Weight Type
    {name: 'ACTUAL', translated: 'ACTUAL'},
    {name: 'AKTUELL', translated: 'ACTUAL'},
    {name: 'PLANNED', translated: 'PLANNED'},
    {name: 'GEPLANT', translated: 'PLANNED'},
    {name: 'SENTENCE', translated: 'SENTENCE'},
    {name: 'SATZ', translated: 'SENTENCE'},
  ],
  de: [
    //Order Type
    {name: 'SERIES', translated: 'SERIE'},
    {name: 'SERIE', translated: 'SERIE'},
    {name: 'SERIES_SPECIAL_ORDER', translated: 'SERIE_SONDERBESTELLUNG'},
    {name: 'SERIE_SONDERBESTELLUNG', translated: 'SERIE_SONDERBESTELLUNG'},

    //Quantity Unit
    {name: 'PIECE', translated: 'STUECK'},
    {name: 'STUECK', translated: 'STUECK'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'METER', translated: 'METER'},
    {name: 'SA', translated: 'SA'},
    {name: 'S', translated: 'S'},
    {name: 'SENTENCE', translated: 'SATZ'},
    {name: 'SATZ', translated: 'SATZ'},

    {name: 'DOZEN', translated: 'DUZEND'},
    {name: 'DUZEND', translated: 'DUZEND'},
    {name: 'PACKAGE', translated: 'PAKET'},
    {name: 'PAKET', translated: 'PAKET'},
    {name: 'MILLIMETER', translated: 'MILLIMETER'},
    {name: 'CENTIMETER', translated: 'ZENTIMETER'},
    {name: 'ZENTIMETER', translated: 'ZENTIMETER'},
    {name: 'DECIMETER', translated: 'DEZIMETER'},
    {name: 'DEZIMETER', translated: 'DEZIMETER'},
    {name: 'INCH', translated: 'INCH'},
    {name: 'FEET', translated: 'FEET'},
    {name: 'YARD', translated: 'YARD'},
    {name: 'QUAD MM', translated: 'QUAD. MM'},
    {name: 'QUAD. MM', translated: 'QUAD. MM'},
    {name: 'SQUARE CENTIMETERS', translated: 'QUAD. CM'},
    {name: 'QUAD. CM', translated: 'QUAD. CM'},
    {name: 'SQUARE DECIMETERS', translated: 'QUAD. DM'},
    {name: 'QUAD. DM', translated: 'QUAD. DM'},
    {name: 'SQUARE INCH', translated: 'QUAD. INCH'},
    {name: 'QUAD. INCH', translated: 'QUAD. INCH'},
    {name: 'SQUARE METERS', translated: 'QUAD. M'},
    {name: 'QUAD. M', translated: 'QUAD. M'},
    {name: 'SQUARE FEET', translated: 'QUAD. F'},
    {name: 'QUAD. F', translated: 'QUAD. F'},
    {name: 'CUBIC MILLIMETER', translated: 'KUBIK MM'},
    {name: 'KUBIK MM', translated: 'KUBIK MM'},
    {name: 'CUBIC CENTIMETERS', translated: 'KUBIK CM'},
    {name: 'KUBIK CM', translated: 'KUBIK CM'},
    {name: 'CENTISTERE', translated: 'CENTISTERE'},
    {name: 'CUBIC DECIMETER', translated: 'KUBIK DM'},
    {name: 'KUBIK DM', translated: 'KUBIK DM'},
    {name: 'CUBIC INCH', translated: 'KUBIK INCH'},
    {name: 'KUBIK INCH', translated: 'KUBIK INCH'},
    {name: 'CUBIC FEET', translated: 'KUBIK FEET'},
    {name: 'KUBIK FEET', translated: 'KUBIK FEET'},
    {name: 'CUBIC METER', translated: 'KUBIK M'},
    {name: 'KUBIK M', translated: 'KUBIK M'},
    {name: 'GRAMM', translated: 'GRAM'},
    {name: 'GRAM', translated: 'GRAM'},
    {name: 'OUNCE', translated: 'OUNCE'},
    {name: 'POUND', translated: 'POUND'},
    {name: 'KILOGRAM', translated: 'KILOGRAM'},
    {name: 'METRIC TONS', translated: 'TONNEN'},
    {name: 'TONNEN', translated: 'TONNEN'},
    {name: 'MILLILITER', translated: 'MILLILITER'},
    {name: 'DECILITER', translated: 'DEZILITER'},
    {name: 'DEZILITER', translated: 'DEZILITER'},
    {name: 'HECTOLITER', translated: 'HEKTOLITER'},
    {name: 'HEKTOLITER', translated: 'HEKTOLITER'},
    {name: 'AM_QUART', translated: 'AM QUART'},
    {name: 'AM QUART', translated: 'AM QUART'},
    {name: 'BRIT_QUART', translated: 'BRIT QUART'},
    {name: 'BRIT QUART', translated: 'BRIT QUART'},
    {name: 'AM_GALLON', translated: 'AM GALLON'},
    {name: 'AM GALLON', translated: 'AM GALLON'},
    {name: 'BRIT_GALLON', translated: 'BRIT GALLON'},
    {name: 'BRIT GALLON', translated: 'BRIT GALLON'},
    {name: 'AM_PINT', translated: 'AM PINT'},
    {name: 'AM PINT', translated: 'AM PINT'},
    {name: 'BRIT_PINT', translated: 'BRIT PINT'},
    {name: 'BRIT PINT', translated: 'BRIT PINT'},
    {name: 'AM_GILLS', translated: 'AM GILLS'},
    {name: 'AM GILLS', translated: 'AM GILLS'},
    {name: 'BRIT_GILLS', translated: 'BRIT GILLS'},
    {name: 'BRIT GILLS', translated: 'BRIT GILLS'},

    //Order State
    {name: 'CLOSED', translated: 'GESCHLOSSEN'},
    {name: 'GESCHLOSSEN', translated: 'GESCHLOSSEN'},
    {name: 'OPENED', translated: 'GEÖFFNET'},
    {name: 'GEÖFFNET', translated: 'GEÖFFNET'},
    {name: 'CANCELLED', translated: 'STORNIERT'},
    {name: 'STORNIERT', translated: 'STORNIERT'},

    //Exchange Rate Type
    {name: 'Current', translated: 'Aktueller'},
    {name: 'Aktueller', translated: 'Aktueller'},
    {name: 'Monthly', translated: 'Monatlich'},
    {name: 'Monatlich', translated: 'Monatlich'},
    {name: 'Per November', translated: 'Pro November'},
    {name: 'Pro November', translated: 'Pro November'},
    {name: 'Final', translated: 'Finale'},
    {name: 'Finale', translated: 'Finale'},

    //Weight Type
    {name: 'ACTUAL', translated: 'AKTUELL'},
    {name: 'AKTUELL', translated: 'AKTUELL'},
    {name: 'PLANNED', translated: 'GEPLANT'},
    {name: 'GEPLANT', translated: 'GEPLANT'},
  ]
};

export class TranslateContinuousPriceEnums {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getContinuousPriceEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getContinuousPriceEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let continuousPriceEnum = localTextContinuousPriceEnums[lang].find(e => e.name === key);
    if (continuousPriceEnum) {
      return continuousPriceEnum?.translated;
    }
    return localTextContinuousPriceEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

