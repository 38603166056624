import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-freeze-price-confirm',
  templateUrl: './freeze-price-confirm.component.html',
  styleUrls: ['./freeze-price-confirm.component.scss']
})
export class FreezePriceConfirmComponent implements OnInit {

  loadingScreen: boolean = false;
  reason: any;
  errorMessage: string;
  changeDetails: string;

  @Input() changes: any[];
  @Input() action: string;
  @Output() cancel = new EventEmitter();
  @Output() confirmed = new EventEmitter();


  constructor(private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.changeDetails = this.getAboutToActionMessage();
  }

  onCancel() {
    this.cancel.emit();
  }

  onConfirm() {
    this.errorMessage = undefined;
    if (this.reason && this.reason.trim().length > 1) {
      this.changes.forEach((price) => {
        price["reason"] = this.reason;
      });
      this.confirmed.emit();
    }
    else{
      this.errorMessage = this.getErrorMessage();
    }
  }

  getErrorMessage(): string {
    if (this.action.toLowerCase() == "reject") {
      return this.translate.instant('menu.pricing.exchange-rates.error-message.reject-reason-required');
    }
    return  this.translate.instant('menu.pricing.exchange-rates.error-message.release-reason-required');
  }

  getAboutToActionMessage(): string {
    let message = '';
    if (this.action.toLowerCase() == "reject") {
      message = this.translate.instant('menu.pricing.exchange-rates.error-message.about-to-reject');
    }
    else {
      message = this.translate.instant('menu.pricing.exchange-rates.error-message.about-to-release');
    }
    return message + ' ' + this.changes.length.toString() + ' ' + this.translate.instant('menu.pricing.exchange-rates.error-message.price_s');

  }
}
