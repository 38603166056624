<div class="bmw__grid overview-grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12">
      <h1 class="bmw-h1 bmw__margin-top__l">Templates</h1>
    </div>
    <div class="bmw__grid__inner bmw__grid__cell--span-12 bmw__margin-top__l">
      <bmw-generic-tile
        interaction="clickable"
        routerLink="/form-template"
        class="bmw__grid__cell--span-8-tablet bmw__grid__cell--span-4"
      >
        <p class="regular-text"><strong>Form Template</strong></p>
        <div class="overview-tile__image-wrapper">
          <img class="bmw__margin-top__m" src="./assets/overview/form.svg" alt="Form Template Graphic" />
        </div>
      </bmw-generic-tile>
      <bmw-generic-tile
        interaction="clickable"
        routerLink="/basic-table-template"
        class="bmw__grid__cell--span-8-tablet bmw__grid__cell--span-4"
      >
        <p class="regular-text"><strong>Basic Table Template</strong></p>
        <div class="overview-tile__image-wrapper">
          <img class="bmw__margin-top__m" src="./assets/overview/table.svg" alt="Basic Table Template Graphic" />
        </div>
      </bmw-generic-tile>
      <bmw-generic-tile
        interaction="clickable"
        routerLink="/master-detail-template"
        class="bmw__grid__cell--span-8-tablet bmw__grid__cell--span-4"
      >
        <p class="regular-text"><strong>Master-Detail Template</strong></p>
        <div class="overview-tile__image-wrapper">
          <img class="bmw__margin-top__m" src="./assets/overview/master-detail.svg" alt="Master-Detail Graphic" />
        </div>
      </bmw-generic-tile>
    </div>
  </div>
</div>
