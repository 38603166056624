import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OperationService } from "../service/operation.service";
import { Operation } from '../model/operations.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {
  @Input() showWorkflow = false;
  @Input() workflowForward = false;
  @Input() dialogTitle: string;
  @Input() public getOperationFn;
  @Input() public applyWorkflowFn;
  @Input() public cancelFn;
  workflowForm: FormGroup;
  public operation: Operation;


  constructor(private operationService: OperationService) {

  }

  ngOnInit(): void {
    this.workflowForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }

  cancel() {
    this.cancelFn();
    this.workflowForm.setValue({
      comment: ''
    });
  }

  applyWorkflow() {
    this.workflowForm.markAllAsTouched();
    if (this.workflowForm.valid) {
      let success: false;
      const operation = {internalIdentifier: 0, name: '',workFlowStatus: '' , statusReason: ''};
      //operation.operationName = this.workflowForm.value.operationName;
      operation.statusReason = this.workflowForm.value.comment;
      this.operation = this.getOperationFn();
      operation.internalIdentifier = this.operation.internalIdentifier;
      let action = '';
      if (this.operation.workFlowStatus === 'In progress' || this.operation.workFlowStatus === 'Reset' || this.operation.workFlowStatus === 'Declined') {
        if (this.workflowForward) {
          action = 'UbergabeZurPruefung';
        } else {
          operation.workFlowStatus = this.operation.workFlowStatus;
        }
      } else if (this.operation.workFlowStatus === 'In review') {
        if (this.workflowForward) {
          action = 'UbergabeZurFreigabe';
        } else {
          action = 'Zurueksetzen';
        }
      } else if (this.operation.workFlowStatus === 'In release') {
        if (this.workflowForward) {
          action = 'VorgangFreigeben';
        } else {
          operation.workFlowStatus = this.operation.workFlowStatus;
        }
      }
      console.log('Action', action);
      console.log('Status', operation.workFlowStatus);
      if (action === '') {
        // this.operationService.edit(operation).subscribe(data => {
        //   success = data.success;
        //   this.workflowForm.setValue({
        //     comment: ''
        //   });
        //   this.applyWorkflowFn(success);
        // });
      } else {
        // this.operationService.workflow(action, operation).subscribe(data => {
        //   success = data.success;
        //   console.log('MESSAGE', data.message);
        //   this.workflowForm.setValue({
        //     comment: ''
        //   });
        //   if (success && success !== 'false') {
        //     this.applyWorkflowFn(success);
        //   } else {
        //     this.applyWorkflowFn(data.message);
        //   }
        // });

      }
    }
  }

}
