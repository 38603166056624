import { BreadcrumbsService, IBreadcrumb } from './services/breadcrumbs.service';
import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import { Observable } from 'rxjs';
import {SystemGlobalCache} from "./shared/system.global.cache";
import {AuthService} from "./services/auth.service";
import {OAuthService} from "angular-oauth2-oidc";
import {authConfig} from "./sso.config";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  templatesVersion = '1.0';
  locationPath = '';
  $breadcrumbs: Observable<IBreadcrumb[]>;

  runtimeCompilerData: any;
  message: string;
  loading = true;
  showDialog: boolean = false;
  currentYear: number;
  errorMessage: string = "";
  showErrorDialog: boolean = false;


  constructor(private location: Location,
              private breadcrumbsService: BreadcrumbsService,
              private authService: AuthService,
              private oauthService : OAuthService
  ) {
    this.locationPath = location.path();
    this.$breadcrumbs = breadcrumbsService.asObservable();
    this.currentYear = (new Date()).getFullYear();
    this.configureOAuth();
  }


  configureOAuth(){
    this.oauthService.configure(authConfig);

    this.oauthService.loadDiscoveryDocument().then(() => {
      console.log('Discovery document loaded:', this.oauthService);
      this.oauthService.tryLoginImplicitFlow().then( value => {
        if(!this.oauthService.hasValidAccessToken()){
          this.oauthService.initImplicitFlow();
        } else {
          this.oauthService.loadUserProfile().then(value1 => {
            console.log(JSON.stringify(value));

            this.authService.getToken().subscribe(({data}) => {
              if (data.token?.token == null) {
                this.displayErrorAndLogout("You have no access to the system , please consult your administrator");
              } else {
                SystemGlobalCache.setToken(data.token?.token);
                this.authService.isDoneLoading(true);
                this.loading = false;
              }
            }, (error) => {
              this.displayErrorAndLogout(this.authService.removeGraphQLErrorOnMessage(error.message));
            });

          })
        }
      })
      return this.oauthService.tryLogin();
    })
  }

  displayErrorAndLogout(error: string) {
    this.loading = false;
    this.errorMessage = error;
    this.showErrorDialog = true;
  }

  logout() {
    this.oauthService.logOut();
  }

}
