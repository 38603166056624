import {Component, OnInit, ViewChild} from '@angular/core';
import {ExchangeRatesReleaseComponent} from "../exchange-rates-release/exchange-rates-release.component";
import {ExchangeRatesOverviewComponent} from "../exchange-rates-overview/exchange-rates-overview.component";
import {Plant} from "../../order/model/order.model";

@Component({
  selector: 'app-exchange-rates-management',
  templateUrl: './exchange-rates-management.component.html',
  styleUrls: ['./exchange-rates-management.component.scss']
})
export class ExchangeRatesManagementComponent implements OnInit {
  @ViewChild (ExchangeRatesReleaseComponent) exchangeRatesReleaseComponent;
  @ViewChild (ExchangeRatesOverviewComponent) exchangeRatesOverviewComponent;
  isPlantSAlOrGWM: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isPlantSAlOrGWM = Plant[window.sessionStorage.client] === Plant.SAL || Plant[window.sessionStorage.client] === Plant.GWM;
  }

  tabClick($event: string) {
    if ($event === "release"){
      this.exchangeRatesReleaseComponent.refreshData();
    }else{
      this.exchangeRatesOverviewComponent.refreshData();
    }
  }
}
