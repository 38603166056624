import {Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {FreezePriceDetailsGridColumns} from "./freeze-price-details-grid.columns";
import {GridSettingsStore} from "../../../../../helper/grid.settings.store";
import {Router} from "@angular/router";
import {ReleaseReject} from "../../model/release.reject.model";
import moment from "moment";
import {TranslateFreezePriceEnums} from "../../service/freeze.price.enum.translations";
import {EMPTY_STRING} from "../../../../../shared/functions/typescript.utils";
import {SystemGlobalCache} from "../../../../../shared/system.global.cache";


@Component({
  selector: 'app-freeze-price-details-grid',
  templateUrl: './freeze-price-details-grid.component.html',
  styleUrls: ['./freeze-price-details-grid.component.scss']
})
export class FreezePriceDetailsGridComponent extends GridSettingsStore implements OnInit   {

  @Input() rowData: any;
  @Input() showSideBar: boolean = true;
  @Input() hideColumns: string[];
  @Input() gridRowSelection: string;
  @Input() showButtons: boolean = true;

  @Output() doubleClick = new EventEmitter<any>();
  @Output() errorMessage = new EventEmitter<string>();
  @Output() cancelView = new EventEmitter();
  @Output() release = new EventEmitter<ReleaseReject>();
  @Output() reject = new EventEmitter<ReleaseReject>();


  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  approveRejectButtonStatus = false;
  rowSelection;
  loadingScreen: boolean = false;
  disableRejectReleaseButton: boolean = true;
  excelStyles: any;
  freezePriceEnums: TranslateFreezePriceEnums;


  constructor(private translate: TranslateService,
              private router: Router) {
    super();
    this.gridColumns = new FreezePriceDetailsGridColumns(this.translate);
    this.freezePriceEnums = new TranslateFreezePriceEnums();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.translateInGridEnums();
    });
    this.excelStyles = this.getExcelStyles();
    this.translateInGridEnums(true);
  }


  ngOnInit() {
    this.gridColumns.hideColumns(this.hideColumns);
    this.rowSelection = this.gridRowSelection;
    this.setGridOptions();
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums(refreshFromDefault: boolean = null) {

    if (refreshFromDefault && this.translate.currentLang === 'de') {
      this.translateRowData(this.getAllRows(), 'de');
    }
    else if (this.translate.currentLang !== SystemGlobalCache.currentLang()) {
      this.rowData = this.translateRowData(this.getAllRows(), this.translate.currentLang);
    }
  }

  translateRowData(rows: any[], lang: string = 'en'): any[] {
    if (rows?.length === 0) { return; }
    let pushRowData = [];
    rows.forEach(row => {
      if (row !== undefined) {
        row.uom = this.freezePriceEnums.translate(lang, row.uom);
        row.priceType = this.freezePriceEnums.translate(lang, row.priceType);
        row.wfStatus = this.freezePriceEnums.translate(lang, row.wfStatus);
        pushRowData.push(row);
      }
    });
    return pushRowData;
  }

  getAllRows() {
    let rowData = [];
    this.gridApi?.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onRefresh(): void {
    this.approveRejectButtonStatus = false;
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    }, 0);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    }, 0);
  }

  expandAfterRedraw() {
    this.gridApi.forEachNode((node) => {
      if(node?.group) {
        node.setExpanded(true)
      }
    })
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'fromDate', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
    setTimeout(() => {
      this.expandAfterRedraw();
    }, 0);
  }


  onModelUpdated(params): void {

    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }

    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.translateInGridEnums(true);
      this.gridApi.hideOverlay();
      this.expandAfterRedraw();
    }
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return  {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }

  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: false,
        enableRowGroup: true,
        enablePivot: false,
        enableValue: false,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }

  onCellValueChanged(params: any) {
  }

  onRowSelected(params: any) {
    if ( params.node.selected ) {
      this.disableRejectReleaseButton = false;
    }
  }

  onRowDoubleClicked(params: any) {
    this.doubleClick.emit(params.data.internalIdentifier);
  }

  onSaveGridState(params: any) {
    let columnState = this.gridColumnApi?.getColumnState();
    this.saveGridState(this.router.url, columnState);
    this.gridApi.expandAll();
  }

  onRelease() {

    if (this.gridApi.getSelectedRows().length == 0){
      return;
    }

    let release = new ReleaseReject();
    release.data = this.gridApi.getSelectedRows();
    release.isReject = false;
    release.isPriceObject = false;
    release.action = "release";
    this.release.emit(release);
  }

  onReject() {

    if (this.gridApi.getSelectedRows().length == 0){
      return;
    }

    let release = new ReleaseReject();
    release.data = this.gridApi.getSelectedRows();
    release.isReject = true;
    release.action = "reject";
    release.isPriceObject = false;
    this.reject.emit(release);
  }

  onCancel() {
    this.cancelView.emit();
  }


  rowDataChanged($event: any) {
    if (!this.showButtons) {
      this.hideColumns = ["#"];
    } else {
      this.hideColumns = [];
    }
    this.gridColumns = new FreezePriceDetailsGridColumns(this.translate);
    this.gridColumns.hideColumns(this.hideColumns);
    this.setGridOptions();
  }

  exportAsExcel() {
    let fileName = "Freeze_Prices_".concat(moment(moment.now()).format('YYYY-MM-DD'))
    const params = {
      fileName: fileName,
      sheetName: fileName,
      columnKeys: this.generateColumnsForExcel()
    }
    this.gridApi.exportDataAsExcel(params);
  }

  generateColumnsForExcel(): string[] {
    const keys = this.gridColumnApi
      .getAllGridColumns()
      .map(column => column.getColId());
    this.removeItemFrom(keys, "#");
    this.removeItemFrom(keys, "currencyNew");
    return keys;
  }

  private removeItemFrom(keys, itemKey) {
    const index = keys.indexOf(itemKey, 0);
    if (index > -1) {
      keys.splice(index, 1);
    }
    return keys;
  }

  getExcelStyles() {
    return [
      {
        id: 'currencyFormat',
        numberFormat: {
          format: '#,##0.00 €',
        },
      },
      {
        id: 'stringType',
        dataType: 'string',
      },
      {
        id: 'dateType',
        dataType: 'dateTime',
        numberFormat: {format: 'dd.mm.yyyy'}
      },
      {
        id: 'header',
        font: { bold: true },
        interior: {
          color: '#34b4eb',
          pattern: 'Solid',
        }
      },
      {
        id: 'orderNumberType',
        dataType: 'string',
        'text-decoration': 'underline',
        cursor: 'pointer'
      }
    ];
  }

  onCellDoubleClick(params: any) {
    if (params.colDef.field === "orderNo") {
      const orderNumber = params.data?.orderNo;
      if (orderNumber?.trim().length > 0) {
        this.router.navigate(['order-management', EMPTY_STRING, orderNumber]);
      }
    }
  }

}
