<ag-grid-angular
    style="height: 500px; overflow: scroll"
    class="zebra"
    [rowData]="rowData"
    [columnDefs]="runtimeCompilerData.columns"
    [gridOptions]="runtimeCompilerData.gridOptions"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [sideBar]="runtimeCompilerData.sideBar"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (modelUpdated)="onModelUpdated($event)"
    [pagination]="true"
    [rowMultiSelectWithClick]="true"
    [rowSelection]="rowSelection"
    [groupSelectsChildren]="true"
    [groupSelectsFiltered]="true"
    [groupDefaultExpanded]="0"
    [animateRows]="true"
>
</ag-grid-angular>
<div><br/></div>
<div>
  <div class="pull-left">
    <bmw-button button="secondary" (clickEvent)="onCancel()" [disabled]="allocateDisabled" class="bmw__margin-right__xs">
      {{ 'menu.manufacturing-cost.tabs.operations.cancel' | translate }}
    </bmw-button>
  </div>
  <div class="pull-right">
    <bmw-button button="primary" (clickEvent)="onTakeOver()" [disabled]="allocateDisabled" class="bmw__margin-left__xs">
      {{ 'menu.manufacturing-cost.tabs.opsdetail.save' | translate }}
    </bmw-button>
  </div>
</div>
