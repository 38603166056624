import {EMPTY_STRING} from "../../../../shared/functions/typescript.utils";

interface FreezePriceEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextFreezePriceEnums: Record<LocalCodes, FreezePriceEnum[]> = {
  en: [
    {name: 'PIECE', translated: 'PIECE'},
    {name: 'STUECK', translated: 'PIECE'},
    {name: 'DAILY', translated: 'DAILY'},
    {name: 'TÄGLICH', translated: 'DAILY'},
    {name: 'NEW', translated: 'NEW'},
    {name: 'NEU', translated: 'NEW'},
    {name: 'APPROVED', translated: 'APPROVED'},
    {name: 'BESTÄTIGT', translated: 'APPROVED'},
    {name: 'REJECTED', translated: 'REJECTED'},
    {name: 'ABGELEHNT', translated: 'REJECTED'},
    {name: 'UPDATED', translated: 'UPDATED'},
    {name: 'AKTUALISIERT', translated: 'UPDATED'},
    {name: 'TRANSFERRED', translated: 'TRANSFERRED'},
    {name: 'ÜBERTRAGEN', translated: 'TRANSFERRED'},
    {name: 'PENDING TRANSFER', translated: 'PENDING TRANSFER'},
    {name: 'AUSSTEHENDE UBERTRAGUNG', translated: 'PENDING TRANSFER'},
    {name: 'MONTHLY', translated: 'MONTHLY'},
    {name: 'MONATLICH', translated: 'MONTHLY'},
    {name: 'CONTINUOUS', translated: 'CONTINUOUS'},
    {name: 'KONTINUIERLICH', translated: 'CONTINUOUS'},
    {name: 'MANUAL', translated: 'MANUAL'},
    {name: 'MANUELL', translated: 'MANUAL'},
    {name: 'DOZEN', translated: 'DOZEN'},
    {name: 'DUZEND', translated: 'DOZEN'},
    {name: 'PACKAGE', translated: 'PACKAGE'},
    {name: 'PAKET', translated: 'PACKAGE'},
    {name: 'MILLIMETER', translated: 'MILLIMETER'},
    {name: 'CENTIMETER', translated: 'CENTIMETER'},
    {name: 'ZENTIMETER', translated: 'CENTIMETER'},
    {name: 'DECIMETER', translated: 'DECIMETER'},
    {name: 'DEZIMETER', translated: 'DECIMETER'},
    {name: 'METER', translated: 'METER'},
    {name: 'INCH', translated: 'INCH'},
    {name: 'FEET', translated: 'FEET'},
    {name: 'YARD', translated: 'YARD'},
    {name: 'QUAD MM', translated: 'QUAD MM'},
    {name: 'QUAD. MM', translated: 'QUAD MM'},
    {name: 'SQUARE CENTIMETERS', translated: 'SQUARE CENTIMETERS'},
    {name: 'QUAD. CM', translated: 'SQUARE CENTIMETERS'},
    {name: 'SQUARE DECIMETERS', translated: 'SQUARE DECIMETERS'},
    {name: 'QUAD. DM', translated: 'SQUARE DECIMETERS'},
    {name: 'SQUARE INCH', translated: 'SQUARE INCH'},
    {name: 'QUAD. INCH', translated: 'SQUARE INCH'},
    {name: 'SQUARE METERS', translated: 'SQUARE METERS'},
    {name: 'QUAD. M', translated: 'SQUARE METERS'},
    {name: 'SQUARE FEET', translated: 'SQUARE FEET'},
    {name: 'QUAD. F', translated: 'SQUARE FEET'},
    {name: 'CUBIC MILLIMETER', translated: 'CUBIC MILLIMETER'},
    {name: 'KUBIK MM', translated: 'CUBIC MILLIMETER'},
    {name: 'CUBIC CENTIMETERS', translated: 'CUBIC CENTIMETERS'},
    {name: 'KUBIK CM', translated: 'CUBIC CENTIMETERS'},
    {name: 'CENTISTERE', translated: 'CENTISTERE'},
    {name: 'CUBIC DECIMETER', translated: 'CUBIC DECIMETER'},
    {name: 'KUBIK DM', translated: 'CUBIC DECIMETER'},
    {name: 'CUBIC INCH', translated: 'CUBIC INCH'},
    {name: 'KUBIK INCH', translated: 'CUBIC INCH'},
    {name: 'CUBIC FEET', translated: 'CUBIC FEET'},
    {name: 'KUBIK FEET', translated: 'CUBIC FEET'},
    {name: 'CUBIC METER', translated: 'CUBIC METER'},
    {name: 'KUBIK M', translated: 'CUBIC METER'},
    {name: 'GRAMM', translated: 'GRAMM'},
    {name: 'GRAM', translated: 'GRAMM'},
    {name: 'OUNCE', translated: 'OUNCE'},
    {name: 'POUND', translated: 'POUND'},
    {name: 'KILOGRAM', translated: 'KILOGRAM'},
    {name: 'METRIC TONS', translated: 'METRIC TONS'},
    {name: 'TONNEN', translated: 'METRIC TONS'},
    {name: 'MILLILITER', translated: 'MILLILITER'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'DECILITER', translated: 'DECILITER'},
    {name: 'DEZILITER', translated: 'DECILITER'},
    {name: 'HECTOLITER', translated: 'HECTOLITER'},
    {name: 'HEKTOLITER', translated: 'HECTOLITER'},
    {name: 'AM_QUART', translated: 'AM_QUART'},
    {name: 'AM QUART', translated: 'AM_QUART'},
    {name: 'BRIT_QUART', translated: 'BRIT_QUART'},
    {name: 'BRIT QUART', translated: 'BRIT_QUART'},
    {name: 'AM_GALLON', translated: 'AM_GALLON'},
    {name: 'AM GALLON', translated: 'AM_GALLON'},
    {name: 'BRIT_GALLON', translated: 'BRIT_GALLON'},
    {name: 'BRIT GALLON', translated: 'BRIT_GALLON'},
    {name: 'AM_PINT', translated: 'AM_PINT'},
    {name: 'AM PINT', translated: 'AM_PINT'},
    {name: 'BRIT_PINT', translated: 'BRIT_PINT'},
    {name: 'BRIT PINT', translated: 'BRIT_PINT'},
    {name: 'AM_GILLS', translated: 'AM_GILLS'},
    {name: 'AM GILLS', translated: 'AM_GILLS'},
    {name: 'BRIT_GILLS', translated: 'BRIT_GILLS'},
    {name: 'BRIT GILLS', translated: 'BRIT_GILLS'},
    {name: 'HOUR', translated: 'HOUR'},
    {name: 'STUNDE', translated: 'HOUR'},
    {name: 'MONTH', translated: 'MONTH'},
    {name: 'MONAT', translated: 'MONTH'},
    {name: 'QUARTER', translated: 'QUARTER'},
    {name: 'QUARTAL', translated: 'QUARTER'},
    {name: 'YEAR', translated: 'YEAR'},
    {name: 'JAHR', translated: 'YEAR'},
    {name: 'SENTENCE', translated: 'SENTENCE'},
    {name: 'SATZ', translated: 'SENTENCE'},
    {name: 'PAIR', translated: 'PAIR'},
    {name: 'PAAR', translated: 'PAIR'},
    {name: 'ROLE', translated: 'ROLE'},
    {name: 'ROLLE', translated: 'ROLE'},
    {name: 'SA', translated: 'SA'},
    {name: 'S', translated: 'S'}
  ],
  de: [
    {name: 'STUECK', translated: 'STUECK'},
    {name: 'PIECE', translated: 'STUECK'},
    {name: 'TÄGLICH', translated: 'TÄGLICH'},
    {name: 'DAILY', translated: 'TÄGLICH'},
    {name: 'NEU', translated: 'NEU'},
    {name: 'NEW', translated: 'NEU'},
    {name: 'BESTÄTIGT', translated: 'BESTÄTIGT'},
    {name: 'APPROVED', translated: 'BESTÄTIGT'},
    {name: 'ABGELEHNT', translated: 'ABGELEHNT'},
    {name: 'REJECTED', translated: 'ABGELEHNT'},
    {name: 'AKTUALISIERT', translated: 'AKTUALISIERT'},
    {name: 'UPDATED', translated: 'AKTUALISIERT'},
    {name: 'ÜBERTRAGEN', translated: 'ÜBERTRAGEN'},
    {name: 'TRANSFERRED', translated: 'ÜBERTRAGEN'},
    {name: 'AUSSTEHENDE UBERTRAGUNG', translated: 'AUSSTEHENDE UBERTRAGUNG'},
    {name: 'PENDING TRANSFER', translated: 'AUSSTEHENDE UBERTRAGUNG'},
    {name: 'MONATLICH', translated: 'MONATLICH'},
    {name: 'MONTHLY', translated: 'MONATLICH'},
    {name: 'KONTINUIERLICH', translated: 'KONTINUIERLICH'},
    {name: 'CONTINUOUS', translated: 'KONTINUIERLICH'},
    {name: 'MANUELL', translated: 'MANUELL'},
    {name: 'MANUAL', translated: 'MANUELL'},
    {name: 'DUZEND', translated: 'DUZEND'},
    {name: 'DOZEN', translated: 'DUZEND'},
    {name: 'PAKET', translated: 'PAKET'},
    {name: 'PACKAGE', translated: 'PAKET'},
    {name: 'MILLIMETER', translated: 'MILLIMETER'},
    {name: 'ZENTIMETER', translated: 'ZENTIMETER'},
    {name: 'CENTIMETER', translated: 'ZENTIMETER'},
    {name: 'DEZIMETER', translated: 'DEZIMETER'},
    {name: 'DECIMETER', translated: 'DEZIMETER'},
    {name: 'METER', translated: 'METER'},
    {name: 'INCH', translated: 'INCH'},
    {name: 'FEET', translated: 'FEET'},
    {name: 'YARD', translated: 'YARD'},
    {name: 'QUAD. MM', translated: 'QUAD. MM'},
    {name: 'QUAD. MM', translated: 'QUAD MM'},
    {name: 'QUAD. CM', translated: 'QUAD. CM'},
    {name: 'QUAD. CM', translated: 'SQUARE CENTIMETERS'},
    {name: 'QUAD. DM', translated: 'QUAD. DM'},
    {name: 'QUAD. DM', translated: 'SQUARE DECIMETERS'},
    {name: 'QUAD. INCH', translated: 'QUAD. INCH'},
    {name: 'QUAD. INCH', translated: 'SQUARE INCH'},
    {name: 'QUAD. M', translated: 'QUAD. M'},
    {name: 'QUAD. M', translated: 'SQUARE METERS'},
    {name: 'QUAD. F', translated: 'QUAD. F'},
    {name: 'QUAD. F', translated: 'SQUARE FEET'},
    {name: 'KUBIK MM', translated: 'KUBIK MM'},
    {name: 'KUBIK MM', translated: 'CUBIC MILLIMETER'},
    {name: 'KUBIK CM', translated: 'KUBIK CM'},
    {name: 'KUBIK CM', translated: 'CUBIC CENTIMETERS'},
    {name: 'CENTISTERE', translated: 'CENTISTERE'},
    {name: 'KUBIK DM', translated: 'KUBIK DM'},
    {name: 'KUBIK DM', translated: 'CUBIC DECIMETER'},
    {name: 'KUBIK INCH', translated: 'KUBIK INCH'},
    {name: 'KUBIK INCH', translated: 'CUBIC INCH'},
    {name: 'KUBIK FEET', translated: 'KUBIK FEET'},
    {name: 'KUBIK FEET', translated: 'CUBIC FEET'},
    {name: 'KUBIK M', translated: 'KUBIK M'},
    {name: 'KUBIK M', translated: 'CUBIC METER'},
    {name: 'GRAMM', translated: 'GRAMM'},
    {name: 'GRAM', translated: 'GRAMM'},
    {name: 'OUNCE', translated: 'OUNCE'},
    {name: 'POUND', translated: 'POUND'},
    {name: 'KILOGRAM', translated: 'KILOGRAM'},
    {name: 'TONNEN', translated: 'TONNEN'},
    {name: 'TONNEN', translated: 'METRIC TONS'},
    {name: 'MILLILITER', translated: 'MILLILITER'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'DEZILITER', translated: 'DEZILITER'},
    {name: 'DECILITER', translated: 'DEZILITER'},
    {name: 'HEKTOLITER', translated: 'HEKTOLITER'},
    {name: 'HECTOLITER', translated: 'HEKTOLITER'},
    {name: 'AM QUART', translated: 'AM QUART'},
    {name: 'AM_QUART', translated: 'AM QUART'},
    {name: 'BRIT QUART', translated: 'BRIT QUART'},
    {name: 'BRIT_QUART', translated: 'BRIT QUART'},
    {name: 'AM GALLON', translated: 'AM GALLON'},
    {name: 'AM_GALLON', translated: 'AM GALLON'},
    {name: 'BRIT GALLON', translated: 'BRIT GALLON'},
    {name: 'BRIT_GALLON', translated: 'BRIT GALLON'},
    {name: 'AM PINT', translated: 'AM PINT'},
    {name: 'AM_PINT', translated: 'AM PINT'},
    {name: 'BRIT PINT', translated: 'BRIT PINT'},
    {name: 'BRIT_PINT', translated: 'BRIT PINT'},
    {name: 'AM GILLS', translated: 'AM GILLS'},
    {name: 'AM_GILLS', translated: 'AM GILLS'},
    {name: 'BRIT GILLS', translated: 'BRIT GILLS'},
    {name: 'BRIT_GILLS', translated: 'BRIT GILLS'},
    {name: 'STUNDE', translated: 'STUNDE'},
    {name: 'HOUR', translated: 'STUNDE'},
    {name: 'MONAT', translated: 'MONAT'},
    {name: 'MONTH', translated: 'MONAT'},
    {name: 'QUARTAL', translated: 'QUARTAL'},
    {name: 'QUARTER', translated: 'QUARTAL'},
    {name: 'JAHR', translated: 'JAHR'},
    {name: 'YEAR', translated: 'JAHR'},
    {name: 'SATZ', translated: 'SATZ'},
    {name: 'SENTENCE', translated: 'SATZ'},
    {name: 'PAAR', translated: 'PAAR'},
    {name: 'PAIR', translated: 'PAAR'},
    {name: 'ROLLE', translated: 'ROLLE'},
    {name: 'ROLE', translated: 'ROLLE'},
    {name: 'SA', translated: 'SA'},
    {name: 'S', translated: 'S'}
  ]
};

export class TranslateFreezePriceEnums {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getFreezePriceEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getFreezePriceEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let freezePriceEnum = localTextFreezePriceEnums[lang].find(e => e.name === key);
    if (freezePriceEnum) {
      return freezePriceEnum?.translated;
    }
    return localTextFreezePriceEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

