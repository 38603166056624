import { BreadcrumbsService } from './../../services/breadcrumbs.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.less'],
})
export class FormTemplateComponent implements OnInit {
  bookingForm: FormGroup;
  showDialog = false;
  formSubmited = false;

  constructor(breadcrumbs: BreadcrumbsService) {
    breadcrumbs.update([
      {
        label: 'Rental Cars',
        url: '/',
      },
      {
        label: 'Your BMW 3 Series',
        url: '/',
      },
      {
        label: 'Form Template',
        url: '/form-template',
      },
    ]);
  }

  data = [
    {
      id: 1,
      text: 'King'
    },
    {
      id: 2,
      text: 'Queen'
    },
    {
      id: 3,
      text: 'Prince'
    }
  ];

  ngOnInit(): void {
    this.bookingForm = new FormGroup({
      name: new FormControl('John Doe', [Validators.required]),
      email: new FormControl('', [Validators.email]),
      birthday: new FormControl('', [Validators.required]),
      firstLine: new FormControl(''),
      country: new FormControl(''),
      residentialCity: new FormControl(''),
      postalCode: new FormControl(''),
      licenseNumber: new FormControl(''),
      licenseDate: new FormControl('', [Validators.required]),
      yearsOfDrivingExperience: new FormControl(1, [Validators.required]),
      licenseFile: new FormControl(''),
      pickupPoint: new FormControl('dingolfing'),
      dropoffPoint: new FormControl('porto'),
      pickupDropoffDate: new FormControl(''),
      comment: new FormControl(''),
    });
  }

  submit() {
    this.formSubmited = true;
    this.bookingForm.markAllAsTouched();
    if (this.bookingForm.valid) {
      this.showDialog = true;
    }
  }

  resetForm() {
    this.formSubmited = false;
    this.bookingForm.markAsPristine();
    this.bookingForm.reset();
  }
}
