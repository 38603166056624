import {Injectable} from "@angular/core";
import {ServiceBase} from "../../../services/service.base";
import {Apollo} from "apollo-angular";
import {Plant} from "../../order/model/order.model";
import {faas} from "../../../constants/faas.constants";
import {
  OPERATION_RULE_IN_PROGRESS,
  OPERATION_RULE_QUERY,
  OperationRuleQueryResponse, OperationRulesInProgressResponse,
  RULE_PRODUCTS_QUERY,
  RuleProductsQueryResponse
} from "../rule/rule.graphql.query";
import {RuleChangeInput} from "../model/operations.model";
import {CREATE_RULES} from "../rule/rule.graphql.mutations";
import {RETRIEVE_RULES_HISTORY, RulesHistoryResponse} from "../rule-history/rule-history.graphql.query";

@Injectable({
  providedIn: 'root'
})
export class RuleService extends ServiceBase {

  constructor(apollo: Apollo) {
    super(apollo);
  }

  getProductDerivatives() {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      isActive: true
    };
    return this.query<RuleProductsQueryResponse>(variable, RULE_PRODUCTS_QUERY, faas.productServiceClientName);
  }

  createRules(ruleChangeInput: RuleChangeInput) {
    const variable = {
      ruleChangeInput: ruleChangeInput
    };
    return this.mutate(variable, CREATE_RULES, faas.productServiceClientName);
  }

  getRulesByOperationId(operationId: number){
    const variable = {
      operationId: operationId
    };
    return this.query<OperationRuleQueryResponse>(variable, OPERATION_RULE_QUERY, faas.productServiceClientName);
  }

  getRulesHistory() {
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<RulesHistoryResponse>(variable, RETRIEVE_RULES_HISTORY, faas.productServiceClientName);
  }

  getRulesInProgress() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<OperationRulesInProgressResponse>(variable, OPERATION_RULE_IN_PROGRESS, faas.productServiceClientName);
  }

  mapRulesHistory() {

  }
  dispose(): void {
  }
}
