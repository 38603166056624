import {Component, HostListener, Input, OnInit } from '@angular/core';
import {ColumnApi, GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {ExchangeRatesGridColumns} from "./exchange-rates-grid.columns";
import {Subject} from "rxjs";
import {ExchangeRatesService} from "../../service/exchange-rates.service";
import {ExchangeRateState} from "../../model/exchange-rates.model";
import {PlantChangeService} from "../../../header/service/PlantChangeService";

@Component({
  selector: 'app-exchange-rates-grid',
  templateUrl: './exchange-rates-grid.component.html',
  styleUrls: ['./exchange-rates-grid.component.scss']
})
export class ExchangeRatesGridComponent implements OnInit {

  @Input() rowData: any;
  @Input() groupBy: string;
  @Input() addExchangeRate:Subject<any>;
  @Input() cancelExchangeRateChange:Subject<any>;

  noDataUpdated:boolean = true;
  editMode:boolean = false;

  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  showNoData:boolean = false;
  temporaryDisablingOfExchangeRateButtons = true;

  constructor(private translate: TranslateService, private exchangeRatesService: ExchangeRatesService, private plantService: PlantChangeService) {
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
    this.gridColumns = new ExchangeRatesGridColumns(this.translate, this.exchangeRatesService, this.plantService);
  }

  ngOnInit() {
    this.setGridOptions();
    this.addExchangeRate.subscribe(event => {
      this.insertNewExchangeRate(event.toString());
    });
    this.cancelExchangeRateChange.subscribe(event => {
      this.cancelAnyChanges(event);
    });
  }

  private insertNewExchangeRate(year) {
    let rowIndex = 0;
    let rowDataItem = this.getEmptyRowDataItem(year);
    this.rowData.splice(rowIndex, 0, rowDataItem);
    this.gridApi.setRowData(this.rowData);
    // let rowNodeTransaction = this.gridApi.applyTransaction({add: [rowDataItem]});
    // let rowIndex = rowNodeTransaction.add[0].rowIndex;
    rowDataItem["rowIndex"] = rowIndex;
    this.setFocusOnRow(rowIndex);
  }

  private setFocusOnRow(rowIndex: number) {
    this.gridApi.startEditingCell({
      rowIndex: rowIndex,
      colKey: 'year'
    });
  }

  private cancelAnyChanges(data) {
    this.gridApi.setRowData(data);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'year', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });

  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 ) {
      if (this.showNoData) {
        this.gridApi.showNoRowsOverlay();
      }else if (!this.filtering){
        this.gridApi.showLoadingOverlay();
      }
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }
  }


  getNumericCellEditor() {
    function isCharNumeric(charStr) {
      return !!/\d/.test(charStr);
    }
    function isKeyPressedNumeric(event) {
      let charCode = getCharCodeFromEvent(event);
      const charAllowed = ["."];
      let charStr = String.fromCharCode(charCode);
      return isCharNumeric(charStr) || charAllowed.includes(charStr);
    }
    function getCharCodeFromEvent(event) {
      event = event || window.event;
      return typeof event.which === 'undefined' ? event.keyCode : event.which;
    }
    function NumericCellEditor() {}
    NumericCellEditor.prototype.init = function (params) {
      this.focusAfterAttached = params.cellStartedEdit;
      this.eInput = document.createElement('input');
      this.eInput.style.width = '100%';
      this.eInput.style.height = '100%';
      this.eInput.value = isCharNumeric(params.charPress)
        ? params.charPress
        : params.value;
      let that = this;
      this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
          that.eInput.focus();
          if (event.preventDefault) event.preventDefault();
        }
      });
    };
    NumericCellEditor.prototype.getGui = function () {
      return this.eInput;
    };
    NumericCellEditor.prototype.afterGuiAttached = function () {
      if (this.focusAfterAttached) {
        this.eInput.focus();
        this.eInput.select();
      }
    };
    NumericCellEditor.prototype.isCancelBeforeStart = function () {
      return this.cancelBeforeStart;
    };
    NumericCellEditor.prototype.isCancelAfterEnd = function () {};
    NumericCellEditor.prototype.getValue = function () {
      return this.eInput.value;
    };
    NumericCellEditor.prototype.focusIn = function () {
      let eInput = this.getGui();
      eInput.focus();
      eInput.select();
    };
    NumericCellEditor.prototype.focusOut = function () {
      //console.log('NumericCellEditor.focusOut()');
    };
    return NumericCellEditor;
  }

  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'multiple',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      editType:'fullRow',
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      },
      components:{
        numericCellEditor: this.getNumericCellEditor()
      },
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 20
    };
  }

  private getEmptyRowDataItem(year) {

    return {
      "year": year,
      "currency": "",
      "jan": 0,
      "feb": 0,
      "mar": 0,
      "apr": 0,
      "may": 0,
      "jun": 0,
      "jul": 0,
      "aug": 0,
      "sep": 0,
      "oct": 0,
      "nov": 0,
      "dec": 0,
      "perNov": 0,
      "current": 0,
      "final": 0,
      "inserted": true
    };
  }

  onRowValueChanged($event: any) {
    this.updateNodeData($event);
  }

  private updateNodeData($event: any) {
    this.noDataUpdated = false;
    if (!$event.data.inserted) {
      $event.data["updated"] = true;
      $event.data["rowIndex"] = $event.rowIndex;
    }
  }

  onRowEditingStarted($event: any) {
    if(!$event.data.inserted && !this.editMode)
      this.stopAllEditing();
  }

  getRowData() {
    let rowData = [];
    if (this.gridApi) {
      this.gridApi.forEachNode(function (node) {
        rowData.push(node.data);
      });
    }
    return rowData
  }

  stopAllEditing(){
    this.gridApi.stopEditing();
  }

  onCellClicked($event: any) {
    if ((!$event.data.inserted && ($event.colDef.field == "year" ||  $event.colDef.field == "currency")) ||
        ($event.colDef.field == "perNov" && ($event.data.perNovPend == "true" || $event.data.perNovPendState == ExchangeRateState.FROZEN)) ||
        ($event.colDef.field == "final" && $event.data.finalPend == "true")) {
      this.gridApi.stopEditing();
    }
  }

  setEditMode(mode) {
    this.editMode = mode;
  }

  onCellEditingStarted($event: any) {
    this.updateNodeData($event);
  }

  onRefreshGridData(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridColumns = new ExchangeRatesGridColumns(this.translate, this.exchangeRatesService, this.plantService);
  }

  setShowNoData(showNoData: boolean): void {
    this.showNoData = showNoData;
  }
}

