import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {OrderService} from "../service/order.service";
import {OrderSearchRequest} from "../model/OrderSearchRequest";
import {OrdersViewModel} from "../model/OrdersViewModel";
import {UPDATE_ORDER} from "../service/order.mutations";
import {Plant} from "../model/order.model";
import {faas} from "../../../constants/faas.constants";
import {OrderWorkflowStateInput} from "../service/order.graphql.query";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-order-latest-editable',
  templateUrl: './order-latest-editable.component.html',
  styleUrls: ['./order-latest-editable.component.scss']
})
export class OrderLatestEditableComponent implements OnInit {

  @Input() routedOrderNumber: string;
  @Input() routedSnr: string;

  loading: boolean = false;
  errorMessage: string;
  gridErrorMessage: string;
  formSubmitted = false;
  rowData: any;
  rowDataForEdit: any;
  hideEditGrid = true;
  showSideBar = true;
  groupByColumns: string[];
  hideColumns: string[];
  loadingScreen = false;
  selectedOrderNumber: string;
  shouldDisableEditButton = false;
  displayParentView = true;
  originalOrder: OrdersViewModel;
  pendingOrderHeaderInformation: OrdersViewModel;
  pendingOrders: OrdersViewModel[];
  hasNoOrderManagementApproveRejectRole: boolean = false;

  constructor(private translate: TranslateService,
              private orderService: OrderService,
              private route: ActivatedRoute) {
    this.groupByColumns = ["snr","ai","orderNo"];
    this.hideColumns = ["pricingPeriodInternalIdentifier"];
  }

  ngOnInit(): void {
    this.loadingScreen = false;
    this.hasNoOrderManagementApproveRejectRole = !this.orderService.canApproveAndReject();
  }


  search(request: OrderSearchRequest,fromReload: boolean = false) {
    this.loading = true;
    this.formSubmitted = true;
    this.errorMessage = null;
    this.rowData = undefined;
    this.rowDataForEdit = undefined;
    this.shouldDisableEditButton = true;
    this.preserveSearchParameters(request);

    if (request.isError) {
      this.errorMessage = request.error;
    } else {

      if (request.orderNumber != null && request.orderNumber.length > 1) {
        this.orderService.latestOrder(request.orderNumber, OrderWorkflowStateInput[request.status])
          .valueChanges
          .subscribe(({data}) => {
            const flattenedOrder = this.orderService.mapOrder(data.order);
            this.rowData = flattenedOrder;
            this.rowDataForEdit = flattenedOrder;
            this.selectedOrderNumber = data.order.orderNo;
            this.shouldDisableEditButton = data.order.relatedOrders.length > 0;
          }, (error) => {
            this.errorMessage = this.formatErrorMessage(error.message, fromReload);
          });
      } else if (request.snrList != null && request.snrList.length > 0 && request.snrList.toString().trim() != "" && request.ai != null ) {
        this.orderService.latestOrderWithSnrAi(request.ai, request.snrList, OrderWorkflowStateInput[request.status])
          .valueChanges
          .subscribe(({data, loading}) => {
            this.rowData = this.orderService.mapOrders(data.latestOrdersForMaterial);
            //this.shouldDisableEditButton = data.latestOrdersForMaterial.findIndex(p => p.relatedOrders.length > 0) >= 0;
          }, (error) => {
            this.errorMessage = this.formatErrorMessage(error.message, fromReload);
          });
      } else if (request.snrList != null && request.snrList.length > 0 && request.snrList.toString().trim() != "" && request.ai == null ) {
        this.orderService.latestOrderWithSnr(request.snrList, OrderWorkflowStateInput[request.status])
          .valueChanges
          .subscribe(({data, loading}) => {
            this.rowData = this.orderService.mapOrders(data.latestOrdersForMaterial);
            //this.shouldDisableEditButton = data.latestOrdersForMaterial.findIndex(p => p.relatedOrders.length > 0) >= 0;
          }, (error) => {
            this.errorMessage = this.formatErrorMessage(error.message, fromReload);
          });
      } else if (OrderWorkflowStateInput[request.status] == OrderWorkflowStateInput.PENDING_APPROVAL) {
        this.orderService.ordersForWorkFlowState(OrderWorkflowStateInput.PENDING_APPROVAL).valueChanges
          .subscribe(({data}) => {
            this.rowData = this.orderService.mapOrders(data.ordersForWorkFlowState);
          }, (error) => {
            this.errorMessage = this.formatErrorMessage(error.message, fromReload);
          });
      } else {
        this.errorMessage = this.translate.instant('menu.pricing.order-management.error-message.empty-parameters');
      }
    }
  }


  updateOrder(request: OrdersViewModel[]) {
    for(let order of request) {
      this.update(order,request);
    }
  }

  onDoubleClick(request: OrdersViewModel) {
    request.plant = Plant[window.sessionStorage.client];
    this.originalOrder = request;
    if ( request.relatedOrders != undefined && request.relatedOrders.length > 0 ) {
      this.pendingOrders = this.orderService.mapOrders(request.relatedOrders);
      this.pendingOrderHeaderInformation = this.pendingOrders[0];
      this.displayParentView = false;
    }
  }

  showParentView(request: boolean) {
    this.displayParentView = request;
    this.reload();
  }

  onCancel(yes: boolean) {
    this.displayParentView = yes;
  }

  switchView(yes: boolean) {
    this.hideEditGrid = true;
  }

  onAllowEdit(orderNumber: string) {
    this.filterDataForEdit(orderNumber);
    this.hideEditGrid = false;
  }

  filterDataForEdit(orderNumber: string) {
    if ( orderNumber == undefined ) {
      orderNumber = this.selectedOrderNumber;
    }
    let data = <OrdersViewModel[]>this.rowData;
    this.rowDataForEdit = data.filter( row => row.orderNo == orderNumber);
  }

  update(order: OrdersViewModel,orders: OrdersViewModel[]) {
    this.loadingScreen = true;
    this.orderService._apollo.mutate({
      mutation: UPDATE_ORDER,
      variables:  {
        orderInput: this.orderService.getOrderChangeInputFromOrder(order,orders)
      }
    }).subscribe(({ data }) => {
      this.loadingScreen = false;
      this.hideEditGrid = true;
      this.reload();
    },(error) => {
      this.errorMessage = null;//this.orderService.removeGraphQLErrorOnMessage(error.message);
      this.gridErrorMessage = this.orderService.removeGraphQLErrorOnMessage(error.message);
      this.loadingScreen = false;
    });
  }

  preserveSearchParameters(request: OrderSearchRequest) {
    if ( !request.isError ) {
      sessionStorage.setItem(faas.currentOrderSearchParameters, JSON.stringify(request));
    }
  }

  reload() {
    let json = sessionStorage.getItem(faas.currentOrderSearchParameters);
    if ( json != null || json.length > 0 ) {
      let request = JSON.parse(json);
      this.search(request,true);
    }
  }

  formatErrorMessage(errorMessage: string,reload: boolean): string {
    let message = errorMessage.replace('GraphQL error: ', '');
    if (reload) {
      if (message.localeCompare("Order does not exist for given parameters") == 0) {
        return this.translate.instant('menu.pricing.order-management.error-message.approval-rejection-order-not-exist');
      }
    }

    if (message == "Order does not exist for given parameters") {
      message = this.translate.instant('menu.pricing.order-management.error-message.order-does-not-exist');
    }
    return message;
  }

  clearFilters() {
    this.errorMessage = null;
    this.formSubmitted = false;
    this.rowData = [];
    sessionStorage.removeItem(faas.currentOrderSearchParameters);
  }
}
