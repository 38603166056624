import {ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {TranslateHomeComponentEnums} from "../../home/service/home.component.enum.translations";
import {TranslateContinuousPriceEnums} from "../../price/continous-price/service/continuous.price.enum.translations";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Component({
  selector: 'app-bmw-grid',
  templateUrl: './bmw-grid.component.html',
  styleUrls: ['./bmw-grid.component.scss']
})
export class BmwGridComponent implements OnInit , OnChanges {

  @Input() columns: any;
  @Input() rowData: any;
  @Input() redraw: boolean = false;

  gridApi: GridApi;
  runtimeCompilerData: any;
  gridColumns: any;
  domLayout: any;

  homeComponentEnums: TranslateHomeComponentEnums;
  continuousPriceEnums: TranslateContinuousPriceEnums;

  constructor(private translate: TranslateService) {
    this.homeComponentEnums = new TranslateHomeComponentEnums();
    this.continuousPriceEnums = new TranslateContinuousPriceEnums();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.translateInGridEnums();
    });
    this.translateInGridEnums(true);
  }

  ngOnInit(): void {
    this.setGridOptions();
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums(refreshFromDefault: boolean = null) {
    if (refreshFromDefault && this.translate.currentLang === 'de') {
      this.translateRowData(this.getAllRows(), 'de');
    }
    else if (this.translate.currentLang !== SystemGlobalCache.currentLang()) {
        this.rowData = this.translateRowData(this.getAllRows(), this.translate.currentLang);
    }
  }

  translateRowData(rows: any[], lang: string = 'en'): any[] {
    if (rows?.length === 0) { return; }
    let pushRowData = [];
    rows.forEach(row => {
      if (row !== undefined) {
        row.messageType = this.homeComponentEnums.translate(lang, row.messageType);
        row.notificationPriority = this.homeComponentEnums.translate(lang, row.notificationPriority);
        row.notificationMessage = this.homeComponentEnums.translate(lang, row.notificationMessage);
        pushRowData.push(row);
      }
    });
    return pushRowData;
  }

  getAllRows() {
    let rowData = [];
    if (this.gridApi) {
      this.gridApi?.forEachNode(node => rowData.push(node.data));
    }
    return rowData;
  }

  ngOnChanges(changes: SimpleChanges): void {
      setTimeout(() => {
        this.gridApi?.resetRowHeights();
        this.gridApi?.sizeColumnsToFit();
      }, 100);
  }

  onModelUpdated() { this.translateInGridEnums(true); }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (!this.gridApi) return;

    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params: any) {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    //window.addEventListener('resize', this.onResize);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onRefreshGridData($event: any) {
    setTimeout(() => {
      this.gridApi.resetRowHeights();
      this.gridApi.sizeColumnsToFit();
    }, 500);
  }

  setGridOptions() {
    this.domLayout = 'autoHeight';
    this.runtimeCompilerData = {
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'single',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 40,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: false,
        unSortIcon: true,
        editable: false,
        enableRowGroup: false,
        filter: false,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
        suppressMenu: true
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }
}
