import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aggregation-header',
  templateUrl: './aggregation-header.component.html',
  styleUrls: ['./aggregation-header.component.scss']
})
export class AggregationHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
