<ag-grid-angular
  style="height: 500px; overflow: scroll"
  class="zebra"
  [rowData]="rowData"
  [columnDefs]="runtimeCompilerData.columns"
  [gridOptions]="runtimeCompilerData.gridOptions"
  [defaultColDef]="runtimeCompilerData.defaultColDef"
  [sideBar]="runtimeCompilerData.sideBar"
  [statusBar]="runtimeCompilerData.statusBar"
  [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
  [paginationPageSize] = "paginationPageSize"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (rowGroupOpened)="rowGroupCollapse($event)"
  (modelUpdated)="onModelUpdated($event)"
  (rowDataChanged)="onRefreshGridData($event)"
  (rowSelected)="onRowSelected($event)"
  (rowDoubleClicked)="onRowDoubleClicked($event)"
  (columnValueChanged)="onSaveGridState($event)"
  (columnVisible)="onSaveGridState($event)"
  (columnPinned)="onSaveGridState($event)"
  (columnRowGroupChanged)="onSaveGridState($event)"
  (columnMoved)="onSaveGridState($event)"
  [rowSelection]="rowSelection"
  [pagination]="true">
</ag-grid-angular>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..." [show]="loadingScreen"></bmw-progress-circle>
<div [hidden]="hideBottomEditBar" class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button button="primary" type="submit" (clickEvent)="onEnableEditColumn()" [disabled]="disableEditButton"  style="padding: 5px;">
      {{ 'component.bottom-bar.edit' | translate }}
    </bmw-button>
    <bmw-button button="primary" type="submit" disabled="true" >
      {{ 'component.bottom-bar.save' | translate }}
    </bmw-button>
  </div>
</div>
