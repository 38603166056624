<bmw-dialog
  [type]="'info'"
  [show]="showUnallocatedSACostsDialog"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Save'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="ok()"
  (secondaryClick)="cancel()"
  (closeClick)="cancel()">
  <p class="bmw-regular-text">{{ dialogText }}</p>
</bmw-dialog>
