import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";

interface ManualPricesEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextManualPricesEnums: Record<LocalCodes, ManualPricesEnum[]> = {
  en: [
    //Workflow Status
    {name: 'REJECTED', translated: 'REJECTED'},
    {name: 'ABGELEHNT', translated: 'REJECTED'},
    {name: 'NEW', translated: 'NEW'},
    {name: 'NEU', translated: 'NEW'},
    {name: 'IN RELEASE', translated: 'IN RELEASE'},
    {name: 'IN FREIGABE', translated: 'IN RELEASE'},
    {name: 'IN REVIEW', translated: 'IN REVIEW'},
    {name: 'IN PRÜFUNG', translated: 'IN REVIEW'}
  ],
  de: [
    //Workflow Status
    {name: 'REJECTED', translated: 'ABGELEHNT'},
    {name: 'ABGELEHNT', translated: 'ABGELEHNT'},
    {name: 'NEW', translated: 'NEU'},
    {name: 'NEU', translated: 'NEU'},
    {name: 'IN RELEASE', translated: 'IN FREIGABE'},
    {name: 'IN FREIGABE', translated: 'IN FREIGABE'},
    {name: 'IN REVIEW', translated:'IN PRÜFUNG'},
    {name: 'IN PRÜFUNG', translated:'IN PRÜFUNG'}
  ]
};

export class TranslateManualPricesEnums {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getManualPricesEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getManualPricesEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let manualPricesEnum = localTextManualPricesEnums[lang].find(e => e.name === key);
    if (manualPricesEnum) {
      return manualPricesEnum?.translated;
    }
    return localTextManualPricesEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

