import gql from "graphql-tag";
import {ManufacturingCostHistory} from "../../model/operations.model";
import { ProductColumn } from '../../model/reports.model';

export const TRANSFER_PRICE_PER_MODEL_TYPE_BY_PLANT = gql`
  query transferPricePerModelTypeByPlant($plant: Plant!) {
    transferPricePerModelTypeByPlant(plant: $plant) {
      derivativeCode
      internalIdentifier
      price {
        value
      }
      costs
      previousManufacturingCosts {
        internalIdentifier
        price {
          value
        }
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
        rulesHistory {
          amount {
            ROUNDING_MODE
            value
          }
        }
        productionType {
          productionTypeCode
          productionTypeImportStatus
        }
        specialEquipment {
          code
          equipment
          internalIdentifier
          name
          specialEquipmentImportStatus
          type
          manufacturingCostHistory {
            derivativeCode
            internalIdentifier
            operationId
            operationInternalIdentifier
            operationName
            releaseDate
            validFrom
            price {
              ROUNDING_MODE
              value
            }
            previousManufacturingCosts {
              derivativeCode
              internalIdentifier
              price {
                ROUNDING_MODE
                value
              }
            }
            transferPrice {
              internalIdentifier
              royaltiesIndicator
              transferCategoryCost {
                internalIdentifier
                amount {
                  ROUNDING_MODE
                  value
                }
                adjustmentReason {
                  internalIdentifier
                  name
                }
                categoryCost {
                  internalIdentifier
                  name
                }
              }
            }
          }
        }
        packets {
          code
          generation
          internalIdentifier
          name
          packetImportStatus
          sequence
          manufacturingCostHistory {
            derivativeCode
            internalIdentifier
            manufacturingCostStatus
            operationId
            operationInternalIdentifier
            operationName
            releaseDate
            validFrom
            transferPrice {
              internalIdentifier
              royaltiesIndicator
              transferCategoryCost {
                internalIdentifier
                adjustmentReason {
                  internalIdentifier
                  name
                }
                categoryCost {
                  internalIdentifier
                  name
                }
                amount {
                  ROUNDING_MODE
                  value
                }
              }
            }
            price {
              ROUNDING_MODE
              value
            }
            previousManufacturingCosts {
              derivativeCode
              internalIdentifier
              manufacturingCostStatus
            }
          }
        }
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
      packet {
        code
        generation
        internalIdentifier
        name
        packetImportStatus
        sequence
      }
      releaseDate
      validFrom
      operationId
      operationName
    transferPrice {
        internalIdentifier
        royaltiesIndicator
        transferCategoryCost {
          internalIdentifier
          amount {
            value
          }
          categoryCost {
            internalIdentifier
            name
            groupCost {
              code
              internalIdentifier
              name
            }
          }
          adjustmentReason {
            internalIdentifier
            name
            changeType {
              internalIdentifier
              name
            }
          }
        }
      }
      previousTransferPrices {
        internalIdentifier
        transferCategoryCost {
          internalIdentifier
          amount {
            value
          }
          categoryCost {
            internalIdentifier
            name
            groupCost {
              code
              internalIdentifier
              name
            }
          }
          adjustmentReason {
            internalIdentifier
            name
            changeType {
              internalIdentifier
              name
            }
          }
        }
      }
    }
  }
`;

export const ALL_MANUFACTURING_COSTS_BY_PLANT_REPORT = gql`
  query manufacturingCostHistoryByPlant($plant: Plant!) {
    manufacturingCostHistoryByPlant(plant: $plant) {
        derivativeCode
        internalIdentifier
        price {
          value
        }
        previousManufacturingCosts {
          internalIdentifier
          price {
            value
          }
        }
        type {
          axlGear
          capacity
          code
          country
          cylinder
          engineName
          engineSeries
          equipBase
          fuel
          internalIdentifier
          name
          steering
          typeImportStatus
          productionType {
            productionTypeCode
            productionTypeImportStatus
          }
        }
        specialEquipment {
          code
          equipment
          internalIdentifier
          name
          specialEquipmentImportStatus
          type
        }
        packet {
          code
          generation
          internalIdentifier
          name
          packetImportStatus
          sequence
        }
        releaseDate
        validFrom
        operationId
        operationName
        transferPrice {
          internalIdentifier
          royaltiesIndicator
          transferCategoryCost {
            internalIdentifier
            amount {
              value
            }
            categoryCost {
              internalIdentifier
              name
              groupCost {
                code
                internalIdentifier
                name
              }
            }
            adjustmentReason {
              internalIdentifier
              name
              changeType {
                internalIdentifier
                name
              }
            }
          }
        }
        previousTransferPrices {
          internalIdentifier
          transferCategoryCost {
            internalIdentifier
            amount {
              value
            }
            categoryCost {
              internalIdentifier
              name
              groupCost {
                code
                internalIdentifier
                name
              }
            }
            adjustmentReason {
              internalIdentifier
              name
              changeType {
                internalIdentifier
                name
              }
            }
          }
        }
    }
  }`;

export const RETRIEVE_PRODUCT_COLUMNS_BY_PLANT = gql`
query retrieveProductColumns($plant: Plant!) {
	retrieveProductColumns(plant: $plant) {
	  code
	  grouping
	  name
	  plant
	}
}
`;

export interface ManufacturingCostHistoryQueryReportResponse {
  manufacturingCostHistoryByPlant: ManufacturingCostHistory[];
}

export interface TransferPricePerModelTypeByPlantQueryReportResponse {
  transferPricePerModelTypeByPlant: ManufacturingCostHistory[];
}

export interface RetrieveProductColumnsByPlantQueryReportResponse {
  retrieveProductColumns: ProductColumn[];
}
