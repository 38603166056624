<form class="reversed">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-6 form-template-grid-start">
      <p
        class="bmw-medium-text bmw__margin-top__xs"> {{ 'menu.pricing.order-management.history.introduction' | translate }}</p>
    </div>
  </div>
  <app-order-search-bar (onSearch)="search($event)"
                        (onClear)="clearFilters()"
                        [hideWorkFlowStatus]="true">
  </app-order-search-bar>
</form>

<div>
  <bmw-ag-grid-theme>
    <div
      *ngIf="formSubmitted && errorMessage"
      class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">
        {{errorMessage}}
      </bmw-inline-message>
    </div>
    <div *ngIf="formSubmitted && !errorMessage" class="bmw__margin-top__xs">
      <div class="bmw__margin-top__xs">
        <app-order-grid [rowData]="rowData"
                        [groupByColumns]="groupByColumns"
                        [hideColumns]="hideColumns"
                        [hideBottomEditBar]="true"
        ></app-order-grid>
      </div>
    </div>
  </bmw-ag-grid-theme>
</div>

