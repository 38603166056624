import {Weight} from "./model/weight.model";
import {WeightViewModel} from "./model/weight.view.model";
import moment from "moment";

export class WeightTransformer {

  transformWeightings(weightings: Weight[]): WeightViewModel[] {
    let transformedWeightings: any = [];
    for (let weight of weightings) {
      let viewModel = new WeightViewModel();
      viewModel.orderNo = weight.orderNo;
      viewModel.snr = weight.material.snr;
      viewModel.ai = weight.material.ai;
      viewModel.plant = weight.material.plant;
      viewModel.importDate = this.formatTime(weight.importDate);
      viewModel.weightDate = this.formatDate(weight.weightDate);
      viewModel.weightType = weight.weightType;
      viewModel.weightValue = weight.value;

      transformedWeightings.push(viewModel);
    }
    return transformedWeightings;
  }


  formatDate(params) {
    //return moment(params).format('DD.MM.YYYY');
    return moment(params).format('YYYY-MM-DD');
  }

  formatTime(params) {
    return moment(params).format('DD.MM.YYYY hh:mm:ss');
  }
}
