import { Component, OnInit } from '@angular/core';
import {ProfileService} from "../service/profile.service";
import {Permission} from "../model/user.model";
import {FormControl, FormGroup} from "@angular/forms";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {

  rows: Permission[];
  selectedItem = null;
  errorMessage: string;
  infoMessage: string;
  addDisabled = false;
  saveDisabled = true;

  permissionsForm: FormGroup;
  loadingScreen = false;
  hidden = false;
  display = true;
  isNew = false;

  constructor(private service: ProfileService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.display = true;
    this.permissionsForm = new FormGroup({
      permissionName: new FormControl(['']),
      permissionText: new FormControl(['']),
    });
  }

  loadData() {
    this.getPermissions();
  }

  getPermissions() {
    this.errorMessage = undefined;
    this.loadingScreen = true;
    this.service.getAllPermissions().subscribe(({data, loading}) => {
      this.rows = data.permissions;
      this.loadingScreen = false;
    }, (error) => {
      this.loadingScreen = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  onAddNew() {
    if (this.rows?.length > 0){
      this.selectedItem = this.rows[0];
      this.display = false;
      this.isNew = true;
      this.addDisabled = true;
      this.saveDisabled = false;
    }
  }

  cancel() {
    this.display = true;
    this.addDisabled = false;
    this.saveDisabled = true;
  }

  onSave() {
    this.display = true;
    this.isNew = false;
    this.loadingScreen = true;
    this.addDisabled = false;
    this.saveDisabled = true;

    if ( this.isValid() ) {
      let permission = this.getPermission();
      if (this.isDuplicate(permission.name)){
        this.errorMessage = this.translate.instant('menu.administration.roles.permissions.error-message.duplicate-permission');
        return;
      }
      this.service.createPermissions(Array.of(permission)).subscribe(({data}) => {
        this.getPermissions();
        this.loadingScreen = false;
      },(error) => {
        this.loadingScreen = false;
        this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
      });
    } else {
      this.loadingScreen = false;
      this.errorMessage = this.translate.instant('menu.administration.roles.permissions.error-message.empty-parameters-for-permission');
    }

  }

  getPermission(): Permission {
    let permission = new Permission();
    permission.name = this.permissionsForm.get("permissionName").value.toString();
    permission.description = this.permissionsForm.get("permissionText").value;
    return permission;
  }

  isDuplicate(name: string) {
    this.rows.forEach( p => {
      if ( p.name.toLowerCase().localeCompare(name.toLowerCase()) == 0) {
        return true;
      }
    });
    return false;
  }

  isValid(): boolean {
    return (this.permissionsForm.get("permissionName").value &&
      !_.isEmpty(this.permissionsForm.get("permissionName").value.toString().trim()) &&
      (this.permissionsForm.get("permissionText").value &&
        !_.isEmpty(this.permissionsForm.get("permissionText").value.toString().trim())));
  }
}
