
import {ReviewWorkflow} from "./review.workflow";
import {InReleaseWorkflow} from "./in-release.workflow";
import {OperationChangeInput} from "../../model/operations.model";
import {ReleaseWorkflow} from "./release.workflow";
import {ResetWorkflow} from "./reset.workflow";
import {CancelWorkflow} from "./cancel.workflow";
import {DeclineWorkflow} from "./decline.workflow";
import {IWorkFlow} from "./workflow.model";

export class WorkflowFactory {

  private actions: Record<string, IWorkFlow>;

  constructor(apollo) {
    this.actions = {
      'review': new ReviewWorkflow(apollo),
      'in-release': new InReleaseWorkflow(apollo),
      'release': new ReleaseWorkflow(apollo),
      'reset': new ResetWorkflow(apollo),
      'cancel': new CancelWorkflow(apollo),
      'decline': new DeclineWorkflow(apollo)
    }
  }

  executeWorkFlow(action: string, operation: OperationChangeInput) {
    return this.actions[action].process(operation);
  }

}
