import {Component, OnInit} from '@angular/core';
import {ContinuousPriceService} from "../service/continuous-price.service";
import {PlantChangeService} from "../../header/service/PlantChangeService";

@Component({
  selector: 'app-manual-prices',
  templateUrl: './manual-prices.component.html',
  styleUrls: ['./manual-prices.component.scss']
})
export class ManualPricesComponent implements OnInit {

  errorMessage: any;
  rowData: any;

  constructor(private service: ContinuousPriceService, private plantService: PlantChangeService) {
    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.loadManualPrices();
      }
    });
  }


  ngOnInit(): void {
    this.loadManualPrices();
  }

  private loadManualPrices() {
    this.rowData = undefined;
    this.errorMessage = undefined
    this.service.getManualPrices().subscribe(({data, loading}) => {
      this.rowData = this.service.mapManualPrices(data.manualPrices);
    }, (error) => {
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }
}
