<bmw-ag-grid-theme>
  <ag-grid-angular
    style="height: 400px; overflow: scroll"
    class="zebra"
    [rowData]="rowData"
    [columnDefs]="runtimeCompilerData.columns"
    [gridOptions]="runtimeCompilerData.gridOptions"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [sideBar]="runtimeCompilerData.sideBar"
    [statusBar]="runtimeCompilerData.statusBar"
    [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (modelUpdated)="onModelUpdated($event)"
    [rowSelection]="rowSelection"
    [rowMultiSelectWithClick]="true"
    [pagination]="true"
    [suppressPaginationPanel]="false">
  </ag-grid-angular>
</bmw-ag-grid-theme>
<div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
      <bmw-button button="secondary" type="submit" (clickEvent)="onCancel()" >
        {{ 'component.bottom-bar.cancel' | translate }}
      </bmw-button>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
      <bmw-button button="primary" type="submit" (clickEvent)="onSave()" >
        {{ 'component.bottom-bar.save' | translate }}
      </bmw-button>
    </div>
  </div>
</div>


