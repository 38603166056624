import {Component, OnInit} from '@angular/core';
import {Price, PriceSearchRequest} from "../../../model/continous.price.model";
import {FreezePriceService} from "../../service/freeze.price.service";

@Component({
  selector: 'app-freeze-price-main-component',
  templateUrl: './freeze-price-main-component.component.html',
  styleUrls: ['./freeze-price-main-component.component.scss']
})
export class FreezePriceMainComponentComponent implements OnInit {

  errorMessage: string;
  loading: boolean = false;
  selectedPrice: Price;

  constructor(private service: FreezePriceService) { }

  ngOnInit(): void {

  }

  search(request: PriceSearchRequest) {
    this.loading = true;
    this.errorMessage = null;
    this.selectedPrice = undefined;

    if (!request.isError) {
      this.service.queryFreezePrice(request).subscribe(({data, loading}) => {
        this.loading = false;
        this.selectedPrice = data.freezePrice;
      }, (error) => {
        this.loading = false;
        this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
      });
    } else {
      this.loading = false;
      this.errorMessage = request.error;
    }
  }
}
