<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h1 class="bmw-h1 bmw__margin-top__l">Vehicle Specification</h1>
      <h3 class="bmw-h3 bmw__margin-top__xxs">Vehicle Order No.1234567</h3>
      <div class="table-wrapper bmw__margin-top__l">
      </div>
    </div>
  </div>
</div>
