import gql from 'graphql-tag';
import {OperationSummary} from '../model/operations.model';

export const RETRIEVE_OPERATIONS_TO_BE_ACTIONED = gql`
  query manufacturingCostsToBeActioned($plant: Plant!) {
    manufacturingCostsToBeActioned(plant: $plant) {
      costEntries
      internalIdentifier
      name
      operationId
      statusDate
      validFrom
      workFlowStatus
      }
    }`;

export interface OperationsActionedResponse {
  manufacturingCostsToBeActioned: OperationSummary[];
}
