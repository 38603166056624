import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {PriceSearchRequest} from "../../../model/continous.price.model";
import {Plant} from "../../../../order/model/order.model";
import * as _ from "lodash";
import moment from "moment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-freeze-price-search-bar',
  templateUrl: './freeze-price-search-bar.component.html',
  styleUrls: ['./freeze-price-search-bar.component.scss']
})
export class FreezePriceSearchBarComponent implements OnInit {

  priceSearchForm: FormGroup;
  inputDate: string = "";
  searchRequest: PriceSearchRequest

  @Output() onSearch = new EventEmitter<PriceSearchRequest>();

  constructor(private formBuilder: FormBuilder,
              private translate: TranslateService) {
    this.searchRequest = new PriceSearchRequest();
  }

  ngOnInit(): void {
    this.priceSearchForm = this.formBuilder.group({
      snr: [''],
      ai: [''],
    });
  }

  search() {
    try {
      this.onDateSelect(this.inputDate);
      this.canSearch()
      this.validate();
      this.searchRequest.isError = false;
      this.searchRequest.snr = this.f.snr.value;
      this.searchRequest.ai = parseInt(this.f.ai.value);
      this.searchRequest.plant = Plant[window.sessionStorage.client];
    } catch (e) {
      this.searchRequest.isError = true;
      this.searchRequest.error = e.toString();
    }
    this.onSearch.emit(this.searchRequest);
  }

  canSearch() {
    let result = ((this.f.snr.value &&
        !_.isEmpty(this.f.snr.value.toString().trim()) &&
        this.f.ai.value &&
        !_.isEmpty(this.f.ai.value.toString().trim()) &&
        this.searchRequest.knowledgeDate != null)
    );

    if (!result)
      throw this.translate.instant('menu.pricing.order-management.error-message.snr-ai-and-knowledge-date');
  }

  onDateSelect(date) {
    this.searchRequest.knowledgeDate = this.dateFormatter(date);
  }

  onInput(params) {
    let input = params.data;
    //check if user inputs a non numeric character
    if (!this.isNumber(input)) return;
    this.inputDate += params.data;
    if (this.inputDate.length == 8) {
      this.searchRequest.knowledgeDate = this.formatDate(this.inputDate);
    }
  }

  dateFormatter(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  isNumber(s: string) {
    return Number.isInteger(parseInt(s))
  }

  formatDate(input: string): string {
    let date = this.format(input);
    return moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  validate() {
    let date = new Date(this.searchRequest.knowledgeDate);
    if (isNaN(date.getTime())) {
      throw this.translate.instant('menu.pricing.order-management.error-message.invalid-knowledge-date');
    }

    let ai = this.f.ai.value;
    if (!this.isNumber(ai))
      throw this.translate.instant('menu.pricing.order-management.error-message.valid-ai');

    let snr = this.f.snr.value;
    if (snr.length <= 1)
      throw this.translate.instant('menu.pricing.order-management.error-message.valid-snr');
  }

  format(s: string): string {
    let characterArray = Array.from(s);
    let result = "";
    for (let i = 0; i < characterArray.length; i++) {
      if (i == 4 || i == 6) {
        result += "-" + characterArray[i];
      } else {
        result += characterArray[i];
      }
    }
    return result;
  }

  get f() {
    return this.priceSearchForm.controls;
  }

  setDate(datepicker) {
    datepicker.hideOnDateTimeSelect = false;
  }

}
