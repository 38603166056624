import { Injectable } from '@angular/core';
import {ServiceBase} from "./service.base";
import {Apollo} from "apollo-angular";
import {TokenPayload} from "../components/users/model/user.model";
import {
  AuthResponse, IBV_USER_BY_Q_NUMBER,
  IBV_USER_LOGGED_IN_USER,
  IbvRoleResponse, MenuResponse, PROD_MENU_AVAIL, ROLES_TOKEN
} from "../components/users/service/user.profile.graphql.query";
import {faas} from "../constants/faas.constants";
import {SystemGlobalCache} from "../shared/system.global.cache";
import decode from "jwt-decode";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {Store} from "../shared/store";
import {coerceArray} from "@angular/cdk/coercion";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ServiceBase {

  private subject = new Subject<any>();
  private expiredSession = new Subject<any>();

  constructor(apollo: Apollo) {
    super(apollo);
  }

  isDoneLoading(loaded: boolean) {
    this.subject.next(loaded);
  }

  doneLoading(): Observable<boolean> {
    return this.subject.asObservable();
  }

  sessionHasExpired() {
    this.expiredSession.next(true);
  }

  notifyExpiredSession(): Observable<boolean> {
    return this.expiredSession.asObservable();
  }

  getToken() {
    return this.query<AuthResponse>(null, ROLES_TOKEN, faas.profileServiceClientName);
  }

  getProdMenuAvailability() {
    return this.query<MenuResponse>(null, PROD_MENU_AVAIL, faas.profileServiceClientName);
  }

  getTokenPayload(): TokenPayload {
    const token = SystemGlobalCache.token();
    if (token != undefined || token == null ){
      return decode<TokenPayload>(token);
    }
    return null;
  }

  getPermissions(plant: string): string[] {
    if (plant == undefined || plant == null){
      return null;
    }

    let token = this.getTokenPayload();
    let plantShortName = plant.substring(0,3);
    let filteredPermission = token.authorities.filter( p => p.endsWith("_" + plantShortName));
    let filteredPermissionNoPlant = token.authorities.filter( r => !(r.endsWith("_MSF") || r.endsWith("_NED") || r.endsWith("_SAL") || r.endsWith("_GWM")));
    filteredPermission.push(...filteredPermissionNoPlant);
    return token.authorities;//filteredPermission;
  }

  getIBVCurrentLoggedInUser() {
    return this.query<IbvRoleResponse>(null, IBV_USER_LOGGED_IN_USER, faas.profileServiceClientName);
  }

  getIBVUserByQNumber(qNumber: string) {
    const variables = {
      qNumber: qNumber
    };
    return this.query<IbvRoleResponse>(variables, IBV_USER_BY_Q_NUMBER, faas.profileServiceClientName);
  }


  //TODO: to fix this
  hasPermissions(permissions: string[] | string): Observable<boolean[]> {
    const array = coerceArray(permissions);
    return of(this.getTokenPayload().authorities.map( p => array.every(current => p.includes(current))));
  }


  checkPermission(permission: string[] | string ) {
    const permissions = coerceArray(permission);
    let result = false;
    let allowedPermission = this.getPermissions(SystemGlobalCache.plant());
    if ( allowedPermission == null ) return false;
    permissions.forEach( p => {
       if ( allowedPermission.indexOf(p) >= 0 ) {
         result = true;
       }
    });
   return result;
  }

  isAuthenticated(): boolean {
    const token = SystemGlobalCache.token();
    if ( token == undefined || token == null ) {
      return false;
    }
    return true;
  }

  signOut(redirect: boolean = true) {
    Store.clearAll();
    if (redirect) {
      window.location.href = "/logout.html";
    }
  }

  dispose(): void {
  }

}
