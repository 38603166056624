import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentTabComponent} from "@bmw-ds/components";
import {TranslateService} from "@ngx-translate/core";
import {Plant} from "../../order/model/order.model";

@Component({
  selector: 'app-quicksight-reports-parent',
  templateUrl: './quicksight-reports-parent.component.html',
  styleUrls: ['./quicksight-reports-parent.component.scss']
})
export class QuicksightReportsParentComponent implements OnInit {

  isSAL: boolean = false;
  isGWM: boolean = false;

  constructor(public translate: TranslateService) {
    this.checkPlant();
  }

  ngOnInit(): void {
  }

  changeTab() {
  }

  submit = () => {
  }

  checkPlant(){
    try{
      this.isGWM = Plant[window.sessionStorage.client] === Plant.GWM;
      this.isSAL = Plant[window.sessionStorage.client] === Plant.SAL;
    }catch(error){
      console.error(error);
    }
  }
}
