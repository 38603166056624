<div class="header-container">
  <div class="logo-container">
    <img src="assets/images/group-logo.svg" alt="BMW Group" class="group-logo">
    <a class="logo" href="#" (click)="navigateHome()">{{systemName}}</a>
  </div>

  <div class="links-container">
    <translate-component [hidden]="true"></translate-component>
    <span class="username-icon iwp-icon-gen_user"></span>
    <span class="username">
      {{ this.currentUser }}
    </span>
    <span class="separator">|</span>
    <select id="client-select" #clientSelect
            (change)='changeClient(clientSelect.value)'>
       <option *ngFor="let client of clients">{{client}}</option>
    </select>
    <span class="separator">|</span>
    <span class="lang" *ngFor="let lang of languages" (click)="changeLang(lang)">
      <a [class.active]="lang === langSelect" (click)="changeLang(lang)">{{lang}}</a>
    </span>
    <span class="separator">|</span>
    <a class="logout" href="/logout.html" (click)="logout()">{{ 'header.logout' | translate }}</a>
  </div>
</div>

<bmw-dialog
  [type]="'error'"
  [show]="showDialog"
  [closeButton]="true"
  [title]="'No rights on system'"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="logout()"
  (closeClick)="runtimeCompilerData.show = false">
  You cannot proceed , contact your Admin to grant your rights.
</bmw-dialog>

