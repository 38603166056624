<div class="bmw__grid">
  <form [formGroup]="bookingForm" class="reversed">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-8 form-template-grid-start">
        <h1 class="bmw-h1 bmw__margin-top__l">One last step</h1>
        <p class="bmw-large-text bmw__margin-top__xs">
          Please type in the required information to complete the booking.
        </p>
      </div>
    </div>
    <div class="bmw__grid__inner bmw__margin-top__l">
      <div
        class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-4 main-form form-template-grid-start"
      >
        <bmw-fieldset opened="true">
          <bmw-fieldset-header>Personal Data</bmw-fieldset-header>
          <bmw-fieldset-container>
            <bmw-inputfield id="name">
              <bmw-label>Name*</bmw-label>
              <input
                bmwInput
                type="text"
                name="name"
                placeholder="E.g.: John Doe"
                formControlName="name"
                data-e2e="name"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
            <div class="bmw__margin-top__m">
              <bmw-inputfield id="email">
                <bmw-label>E-mail</bmw-label>
                <input bmwInput type="text" name="email" placeholder="E.g.: john@doe.com" formControlName="email" />
                <bmw-error-message>Sorry, this is an invalid email.</bmw-error-message>
              </bmw-inputfield>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-label for="date-of-birth">Date of birth*</bmw-label>
              <bmw-datepicker
                inputId="date-of-birth"
                formControlName="birthday"
                placeholder="DD/MM/YYYY"
                data-e2e="date-of-birth"
                monthNavigator="true"
                yearNavigator="true"
                yearRange="2000:2020"
              >
                <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
              </bmw-datepicker>
            </div>
          </bmw-fieldset-container>
        </bmw-fieldset>
        <div class="bmw__margin-top__xl">
          <bmw-fieldset opened="true">
            <bmw-fieldset-header>Address</bmw-fieldset-header>
            <bmw-fieldset-container>
              <bmw-inputfield id="firstLine">
                <bmw-label>House number & Street name</bmw-label>
                <input
                  bmwInput
                  type="text"
                  name="firstLine"
                  placeholder="E.g.: 123 Main St."
                  formControlName="firstLine"
                />
              </bmw-inputfield>
              <div class="bmw__margin-top__m">
                <bmw-inputfield id="country">
                  <bmw-label>Country</bmw-label>
                  <input bmwInput type="text" name="country" placeholder="E.g.: USA" formControlName="country" />
                </bmw-inputfield>
              </div>
              <div class="bmw__margin-top__m">
                <bmw-inputfield id="residentialCity">
                  <bmw-label>Residential City</bmw-label>
                  <input
                    bmwInput
                    type="text"
                    name="residentialCity"
                    placeholder="E.g.: New York"
                    formControlName="residentialCity"
                  />
                </bmw-inputfield>
              </div>
              <div class="bmw__margin-top__m">
                <bmw-inputfield id="postalCode">
                  <bmw-label>Postal Code</bmw-label>
                  <input
                    bmwInput
                    type="text"
                    name="postalCode"
                    placeholder="E.g.: 12345"
                    formControlName="postalCode"
                  />
                </bmw-inputfield>
              </div>
            </bmw-fieldset-container>
          </bmw-fieldset>
        </div>
      </div>
      <div
        class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-3 secondary-form additional-info"
      >
        <bmw-fieldset opened="true">
          <bmw-fieldset-header>Additional Information</bmw-fieldset-header>
          <bmw-fieldset-container>
            <bmw-inputfield id="licenseNumber">
              <bmw-label>Driver's license number</bmw-label>
              <input
                bmwInput
                type="number"
                name="licenseNumber"
                placeholder="E.g.: B072RRE2I55"
                formControlName="licenseNumber"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
            <div class="bmw__margin-top__m">
              <bmw-inputfield id="licenseFile">
                <bmw-label>Driver's license</bmw-label>
                <input
                  bmwInput
                  bmwInputFile
                  type="file"
                  name="licenseFile"
                  placeholder="Choose file..."
                  formControlName="licenseFile"
                />
              </bmw-inputfield>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-label for="license-date">Driver's license date issued*</bmw-label>
              <bmw-datepicker inputId="license-date" formControlName="licenseDate" placeholder="DD/MM/YYYY">
                <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
              </bmw-datepicker>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-inputfield id="yearsOfDrivingExperience">
                <bmw-label>Years of driving experience*</bmw-label>
                <input
                  bmwInput
                  bmwInputStepper
                  min="0"
                  name="yearsOfDrivingExperience"
                  type="number"
                  placeholder=""
                  formControlName="yearsOfDrivingExperience"
                />
                <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
              </bmw-inputfield>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-combo-box [placeholder]="'Please select'" formControlName="pickupPoint">
                <bmw-label>Pick-up point</bmw-label>
                <bmw-combo-box-dropdown-item *ngFor="let d of data" value="{{d.id}}" label="{{d.text}}"></bmw-combo-box-dropdown-item>
              </bmw-combo-box>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-combo-box [placeholder]="'Please select'" formControlName="dropoffPoint">
                <bmw-label>Drop-off point</bmw-label>
                <bmw-combo-box-dropdown-item value="welt" label="BMW Welt Munich"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="dingolfing" label="Dingolfing"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="porto" label="Porto"></bmw-combo-box-dropdown-item>
              </bmw-combo-box>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-label for="date-of-pickup">Date of pick-up - Date of drop-off</bmw-label>
              <bmw-datepicker
                inputId="date-of-pickup"
                selectionMode="range"
                formControlName="pickupDropoffDate"
                placeholder="DD/MM/YYYY"
              >
                <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
              </bmw-datepicker>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-combo-box [placeholder]="'Please select'" formControlName="dropoffPoint" [type]="'MULTI'">
                <bmw-label>How did you hear about us?</bmw-label>
                <bmw-combo-box-dropdown-item value="social" label="Social Media"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="email" label="Email"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="friends" label="Friends"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="colleague" label="Colleague"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item value="intranet" label="Intranet"></bmw-combo-box-dropdown-item>
                <bmw-combo-box-dropdown-item
                  value="design-system"
                  label="From the BMW Design System"
                ></bmw-combo-box-dropdown-item>
              </bmw-combo-box>
            </div>
            <div class="bmw__margin-top__m">
              <bmw-inputfield id="comment">
                <bmw-label>Comment</bmw-label>
                <textarea
                  bmwInput
                  type="text"
                  name="comment"
                  formControlName="comment"
                  placeholder="Your comment.."
                  rows="8"
                ></textarea>
                <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
              </bmw-inputfield>
            </div>
          </bmw-fieldset-container>
        </bmw-fieldset>
        <div
          *ngIf="formSubmited && !bookingForm.valid"
          class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text"
        >
          <bmw-inline-message type="error">
            Some fields are invalid or required.
          </bmw-inline-message>
        </div>
      </div>
    </div>
    <div class="bmw__grid__inner">
      <div
        class="button-bar bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw__margin-bottom__xl"
      >
        <bmw-button button="secondary" (clickEvent)="resetForm()" class="bmw__margin-right__xs">
          Reset Form
        </bmw-button>
        <bmw-button button="primary" type="submit" (clickEvent)="submit()">
          Finish booking
        </bmw-button>
      </div>
    </div>
  </form>
</div>
<bmw-dialog
  [type]="'info'"
  [show]="showDialog"
  [closeButton]="true"
  [title]="'Your BMW 3 Series has been booked!'"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showDialog = false"
  (closeClick)="showDialog = false"
>
  <p class="bmw-regular-text">Dear {{ bookingForm.get('name').value }}, your order has been successfully submitted!</p>
</bmw-dialog>
