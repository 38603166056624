import { ImportStatus, Product } from '../model/product.model';

export class DisplayProductTransformer {
    transformDisplayProduct(productData: Product[], typeSpecialEquipmentGrouping){
      let ProdDataStatusCode = {
        "MATURE_PRODUCTION":"30",
        "PRODUCTIVE": "50",
        "INACTIVE": "80",
        "CANCELLED": "99"
      };
        let displayProd =[];
        productData.forEach(product => {
            if(product.derivative.derivativeImportStatus === ImportStatus.ACTIVE){
              let displayDerivative = {};
              displayDerivative["selected"] = false;
              displayDerivative["prod_plant"] = product.plant;
              displayDerivative["prod_derivative"] = product.derivative.code;
              if(product.derivative.types.length == 0){
                  displayProd.push(displayDerivative);
              }
              product.derivative.types.forEach(type => {
                if(type.typeImportStatus === ImportStatus.ACTIVE){
                  let displayWithType = Object.assign( {},displayDerivative);
                  displayWithType["prod_typeid"] = type.internalIdentifier;
                  displayWithType["prod_typecode"] = type.code;
                  displayWithType["prod_designationtype"] = type.name;
                  displayWithType["prod_buildtype"] = type.buildType.buildTypeCode;
                  displayWithType["prod_productiontypecode"] = type.productionType.productionTypeCode;
                  displayWithType["prod_productiondatastatusnumber"] = ProdDataStatusCode[type.productDataStatus.productDataStatusCode];
                  displayWithType["prod_sop"] = type.validity.fromDate;
                  displayWithType["prod_eop"] = type.validity.toDate;
                  displayWithType["prod_motor"] = type.engineSeries;
                  displayWithType["prod_steering"] = type.steering;
                  displayWithType["prod_displacement"] = `${type.capacity?.toLocaleString()} ccm`;
                  displayWithType["prod_country"] = type.country;
                  displayWithType["prod_satype"] = "Type";
                  displayWithType["prod_inWorkflow"] = type.inWorkflow;
                  displayProd.push(displayWithType);
                  if(type.specialEquipment.length == 0){
                      displayProd.push(displayWithType);
                  }

                  type.specialEquipment.forEach(sa => {
                    if(sa.specialEquipmentImportStatus === ImportStatus.ACTIVE){
                      let displayWithSA = Object.assign({},displayWithType);  //this should make a copy
                      displayWithSA["prod_typeid"] = type.internalIdentifier;
                      displayWithSA["prod_typecode"] = type.code;
                      displayWithSA["prod_sop"] = sa.validity?.fromDate;
                      displayWithSA["prod_eop"] = sa.validity?.toDate;
                      displayWithSA["prod_said"] = sa.internalIdentifier;
                      displayWithSA["prod_satype"] = typeSpecialEquipmentGrouping.get(sa.type).groupName;
                      displayWithSA["groupOrder"] = typeSpecialEquipmentGrouping.get(sa.type).groupOrder;
                      displayWithSA["prod_sacode"] = sa.code;
                      displayWithSA["prod_designationsa"] = sa.name;

                      displayProd.push(displayWithSA);
                    }
                  })

                  type.packets.forEach(packet => {
                    if(packet.packetImportStatus === ImportStatus.ACTIVE) {
                      let displayWithSA = Object.assign({}, displayWithType);  //this should make a copy
                      displayWithSA["prod_typeid"] = type.internalIdentifier;
                      displayWithSA["prod_typecode"] = type.code;
                      displayWithSA["prod_sop"] = packet.validity.fromDate;
                      displayWithSA["prod_eop"] = packet.validity.toDate;
                      displayWithSA["prod_said"] = packet.internalIdentifier;
                      displayWithSA["prod_satype"] = typeSpecialEquipmentGrouping.get("PA").groupName;
                      displayWithSA["groupOrder"] = typeSpecialEquipmentGrouping.get("PA").groupOrder;
                      displayWithSA["prod_sacode"] = packet.code;
                      displayWithSA["prod_designationsa"] = packet.name;

                      displayProd.push(displayWithSA);
                    }
                  })
                }
              })
            }
        })
        return displayProd;
    }

}
