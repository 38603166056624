import {NgModule} from "@angular/core";

import {
  BmwAgGridThemeModule,
  BmwThemeModule, BottomNavigationModule,
  BreadcrumbModule,
  ButtonModule, CheckboxModule, ComboBoxModule, ContentTabModule, DatepickerModule, DialogModule,
  DropdownModule, FieldsetModule, GenericTileModule, IconButtonModule, InlineMessageModule,
  InputfieldModule, LabelModule, MasterDetailListModule, NavigationMenuModule, ProgressCircleModule,
  RadiobuttonModule, TableModule, TextLinkModule, AccordionModule, PanelModule, TooltipModule
} from '@bmw-ds/components';


@NgModule({
  imports: [BmwAgGridThemeModule, BmwThemeModule, ButtonModule,BreadcrumbModule,CheckboxModule,ComboBoxModule,FieldsetModule,
            DropdownModule, InputfieldModule, RadiobuttonModule,BottomNavigationModule,ContentTabModule, InlineMessageModule,
            LabelModule, MasterDetailListModule,NavigationMenuModule, TableModule,DatepickerModule,DialogModule,
            GenericTileModule, TextLinkModule, IconButtonModule, ProgressCircleModule,AccordionModule, PanelModule, TooltipModule],
  exports: [BmwAgGridThemeModule, BmwThemeModule, ButtonModule,BreadcrumbModule,CheckboxModule,ComboBoxModule,FieldsetModule,
            DropdownModule, InputfieldModule, RadiobuttonModule,BottomNavigationModule,ContentTabModule,InlineMessageModule,
            LabelModule, MasterDetailListModule,NavigationMenuModule,TableModule,DatepickerModule,DialogModule,
            GenericTileModule, TextLinkModule, IconButtonModule, ProgressCircleModule,AccordionModule, PanelModule, TooltipModule]
})
export  class AppBmwModule {}
