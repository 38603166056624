import {Apollo} from 'apollo-angular';
import {
  AllocatedProductsQueryResponse,
  DERIVATIVE_TYPE_QUERY,
  PRODUCT_CONFIG_STATUSES_QUERY,
  ProductConfigStatusesResponse,
  PRODUCTS_QUERY,
  ProductsQueryResponse,
  SpecialEquipmentQueryResponse,
  TYPE_SPECIAL_EQUIPMENT_QUERY,
  TypesQueryResponse,
  UnAllocatedProductsQueryResponse
} from "../product-configuration/product-config.graphql.query";
import {Plant} from "../../order/model/order.model";
import {Product, ProductConfigurationChangeInput, ProductConfigurationViewModel} from "../model/product.model";
import {ProductsTransformer} from "./products-transformer";
import {Injectable} from "@angular/core";
import {ServiceBase} from "../../../services/service.base";
import {faas} from "../../../constants/faas.constants";
import {SAVE_PRODUCT_CONFIG, START_DATA_IMPORT} from "../product-configuration/product-config.graphql.mutation";
import {
  PRODUCTS_BY_PLANT_QUERY,
  UNALLOCATED_PRODUCTS_BY_PLANT_QUERY
} from "../product-master-data-display/product-master-data-display.graphql.query";
import {AuthService} from "../../../services/auth.service";
import {TypeSpecialEquipmentTransformer} from "./type-special-equipment-transformer";
import {
  EXECUTION_SPECIAL_EQUIPMENT_QUERY,
  ExecutionQueryResponse
} from "../../manufacturing/operation-details/operation-details.graphql.query";
import {ALLOCATED_PRODUCTS_BY_PLANT_QUERY} from "../../manufacturing/operation-type-sa/operation-type-sa.graphql.query";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {DisplayProductTransformer} from "./displayproduct-transformer";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends ServiceBase {

  transformer: ProductsTransformer;
  displayTransformer: DisplayProductTransformer;
  typeSpecialEquipmentTransformer: TypeSpecialEquipmentTransformer;

  constructor(apollo: Apollo,
             private authService: AuthService, private http: HttpClient) {
    super(apollo);
    this.transformer = new ProductsTransformer();
    this.displayTransformer = new DisplayProductTransformer();
    this.typeSpecialEquipmentTransformer = new TypeSpecialEquipmentTransformer();
  }

  getProductDerivatives() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<ProductsQueryResponse>(variable, PRODUCTS_QUERY, faas.productServiceClientName);
  }

  getProductDerivativesUsingHttp() {
    let plant = Plant[window.sessionStorage.client];
    let param = `products?plant=SAL&isActive=false`;
    return this.http.get<Product[]>(environment.productServiceHttpUrl + param);
  }

  getProductData(isActive?: boolean){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      isActive: isActive
    };
    return this.query<ProductsQueryResponse>(variable, PRODUCTS_BY_PLANT_QUERY, faas.productServiceClientName);
  }

  getUnallocatedProductData(operationId: number){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      operationId: operationId
    };
    return this.query<UnAllocatedProductsQueryResponse>(variable, UNALLOCATED_PRODUCTS_BY_PLANT_QUERY, faas.productServiceClientName);
  }

  getProductDataUsingHttp(isActive?: boolean){
    let plant = Plant[window.sessionStorage.client];
    //let param = `products?plant=${plant}&isActive=${isActive}`;
    let param = `products?plant=SAL&isActive=${isActive}`;
    return this.http.get<Product[]>(environment.productServiceHttpUrl + param);
  }

  getAllocatedProducts(operationId :number, isActive?:boolean){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      isActive: isActive,
      operationId: operationId
    };
    return this.query<AllocatedProductsQueryResponse>(variable, ALLOCATED_PRODUCTS_BY_PLANT_QUERY, faas.productServiceClientName);
  }

  typesForDerivative(derivatives: any){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      derivative: derivatives
    };
    return this.query<TypesQueryResponse>(variable, DERIVATIVE_TYPE_QUERY, faas.productServiceClientName);
  }

  specialEquipmentForTypes(types: any){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      type: types
    };
    return this.query<SpecialEquipmentQueryResponse>(variable, TYPE_SPECIAL_EQUIPMENT_QUERY, faas.productServiceClientName);
  }

  executionSpecialEquipmentForTypes(types: any){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      typeCodes: types
    };
    return this.query<ExecutionQueryResponse>(variable, EXECUTION_SPECIAL_EQUIPMENT_QUERY, faas.productServiceClientName);
  }

  productConfigStatuses(){
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<ProductConfigStatusesResponse>(variable, PRODUCT_CONFIG_STATUSES_QUERY, faas.productServiceClientName);
  }

  startDataImport() {
    const variable = {
      plant: Plant[window.sessionStorage.client],
    };
    return this.mutate(variable, START_DATA_IMPORT, faas.productServiceClientName);
  }

  saveProductConfig(productConfigChangeInput: ProductConfigurationChangeInput) {
    const variable = {
      productConfiguration: productConfigChangeInput
    };
    return this.mutate(variable, SAVE_PRODUCT_CONFIG, faas.productServiceClientName);
  }

  getProductConfigChangeInput(productConfigurationViewModel: ProductConfigurationViewModel) {
    return this.transformer.getProductConfigChangeInput(productConfigurationViewModel);
  }

  getDeselectedProductConfigChangeInput(productConfigurationViewModel: ProductConfigurationViewModel) {
    return this.transformer.getDeselectedProductConfigChangeInput(productConfigurationViewModel);
  }

  mapProductData(productData: any): any{
    return this.displayTransformer.transformDisplayProduct(productData, this.typeSpecialEquipmentTransformer.getTypeSpecialEquipmentGrouping());
  }

  hasSeriesCalculationRole() {
    let permissions = ['T_PSDH_Bearbeiter_BMW_SAL','T_PSDH_Bearbeiter_GWM'];
    return this.authService.checkPermission(permissions);
  }

  dispose(): void {
  }
}
