<div class="bmw__grid">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
        <h2 class="bmw-h2 bmw__margin-top__l">{{ 'reporting.title' 	| translate }}</h2>
        <div class="bmw__margin-top__l">
        </div>
        <div class="table-wrapper bmw__margin-top__s">
          <bmw-content-tab #contentTab (selectionChange)="changeTab($event)">
            <bmw-content-tab-label for="adjustment-reason">{{ 'reporting.tabs.adjustment-reason.title' | translate }}</bmw-content-tab-label>
            <bmw-content-tab-content id="adjustment-reason">
              <app-transition-of-adjustment-reason-dynamic
                [derivativeList] = "derivativeList"
                [exchangeRateMap] = "exchangeRateMap"
              ></app-transition-of-adjustment-reason-dynamic>
            </bmw-content-tab-content>
            <bmw-content-tab-label for="released-transferprice" [disabled]="false">
              {{ 'reporting.tabs.released-transferprice.title' | translate }}
            </bmw-content-tab-label>
            <bmw-content-tab-content id="released-transferprice">
              <app-valid-transfer-price-dynamic
                [derivativeList] = "derivativeList"
                [exchangeRateMap] = "exchangeRateMap"
              ></app-valid-transfer-price-dynamic>
            </bmw-content-tab-content>
            <bmw-content-tab-label for="paid-transferprice" [disabled]="false">
              {{ 'reporting.tabs.paid-transferprice.title' | translate }}
            </bmw-content-tab-label>
            <bmw-content-tab-content id="paid-transferprice">
              <app-paid-transferprice-dynamic
                [derivativeList] = "derivativeList"
                [exchangeRateMap] = "exchangeRateMap"
              ></app-paid-transferprice-dynamic>
            </bmw-content-tab-content>
            <bmw-content-tab-label for="accumulated-paid-transferprice" [disabled]="false">
              {{ 'reporting.tabs.accumulated-paid-transferprice.title' | translate }}
            </bmw-content-tab-label>
            <bmw-content-tab-content id="accumulated-paid-transferprice">
              <app-accumulated-paid-transferprice-dynamic
                [derivativeList] = "derivativeList"
                [exchangeRateMap] = "exchangeRateMap"
              ></app-accumulated-paid-transferprice-dynamic>
            </bmw-content-tab-content>
          </bmw-content-tab>

        </div>
      </div>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <div *ngIf="showLoading">
        <bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..."></bmw-progress-circle>
      </div>
    </div>
  </div>
  <bmw-dialog
    [type]="'info'"
    [show]="showPromt"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [primaryDisabled]="false"
    (primaryClick)="showPromt = false"
    (closeClick)="showPromt = false"
  >
    <p class="bmw-regular-text">{{ dialogText }}</p>
  </bmw-dialog>
