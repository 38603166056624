<div class="bmw__grid">
  <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
    <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.vehicle-status.title' | translate }}</h2>
    <div class="bmw__margin-top__l">
    </div>
    <div class="table-wrapper bmw__margin-top__s">
      <bmw-content-tab #contentTab (selectionChange)="changeTab($event)" role="tablist">
        <bmw-content-tab-label for="vehicleStatus">{{ 'menu.vehicle-status.tabs.vehicle-status.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="vehicleStatus">
          <app-vehicle-status></app-vehicle-status>
        </bmw-content-tab-content>
      </bmw-content-tab>
    </div>
  </div>
</div>
