import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {TranslateService} from '@ngx-translate/core';
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {
  Packet,
  ProductConfigurationChangeInput, ProductViewHolder,
  SpecialEquipment,
  Type
} from "../model/product.model";
import {ProductService} from "../service/product.service";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import {OperationService} from "../../manufacturing/service/operation.service";

@Component({
  selector: 'app-product-master-data-display',
  templateUrl: './product-master-data-display.component.html',
  styleUrls: ['./product-master-data-display.component.scss']
})
export class ProductMasterDataDisplayComponent {

  private subscription: Subscription;
  rowData: any;
  tempData: any[];
  selectedProducts: ProductViewHolder[] = [];
  errorMessage: string;
  warnMessage: string;
  showConfirm = false;
  showPrompt = false;
  showDeletePrompt = false;
  dialogText: string;
  art = {};
  loadingScreen = false;
  infoMessage: string;
  noSeriesCalculationRole: boolean = false;
  dialogTitle: any;

  constructor(private translate: TranslateService,
              private productService: ProductService,
              private plantService: PlantChangeService,
              private operationService: OperationService) {

    this.subscription = this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadProductData();
      }
    });
  }

  loadProductData(){
    this.noSeriesCalculationRole = !this.productService.hasSeriesCalculationRole();
    this.productService.getProductData(true)
      .subscribe(({data, loading}) => {
        this.rowData = this.productService.mapProductData(data.productsByPlant);
      }, (error) => {
        this.errorMessage = this.productService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  refreshData(){
    this.rowData = undefined;
    this.loadProductData();
  }

  deleteData(){
    const types = new Array<Type>();
    const specialEquipments = new Array<SpecialEquipment>();
    const packets = new Array<Packet>();
    this.selectedProducts.forEach((row) => {
      if (row.prod_satype === 'Type') {
        const type = new Type();
        type.code = row.prod_typecode;
        types.push(type);
      } else if (row.prod_satype === 'Packet') {
        const packet = new Packet();
        packet.code = row.prod_sacode;
        packet.internalIdentifier = row.prod_said;
        packets.push(packet);
      } else {
        const specialEquipment = new SpecialEquipment();
        specialEquipment.code = row.prod_sacode;
        specialEquipment.internalIdentifier = row.prod_said;
        specialEquipments.push(specialEquipment);
      }
    });
    this.showConfirm = false;
    this.saveConfiguration(types, specialEquipments, packets);
  }

  saveConfiguration(types, specialEquipments, packets) {
    this.showDeletePrompt = false;
    this.loadingScreen = true;
    const productConfigurationChangeInput = new ProductConfigurationChangeInput();
    productConfigurationChangeInput.plant = SystemGlobalCache.plant();
    productConfigurationChangeInput.inactivate = true;
    productConfigurationChangeInput.types = types;
    productConfigurationChangeInput.specialEquipments = specialEquipments;
    productConfigurationChangeInput.packets = packets;

    this.operationService.allManufacturingCosts()
      .subscribe((result) => {
        if(this.operationService.manufacturingCostsHistoryExists(productConfigurationChangeInput, result.data.manufacturingCostHistoryByPlant)){
          this.showDeletePrompt = true;
          this.loadingScreen = false;
          this.dialogTitle = this.translate.instant('messages.title');
        } else {
          this.productService.saveProductConfig(productConfigurationChangeInput).subscribe(({data}) => {
            this.loadingScreen = false;
            this.infoMessage = this.translate.instant('messages.delete-success');
            this.loadProductData();
          },(error) => {
            this.loadingScreen = false;
            this.errorMessage = this.translate.instant('messages.delete-failure');
            this.errorMessage = this.errorMessage + " : " + this.productService.removeGraphQLErrorOnMessage(error.message);
          });
        };
    });

  }

   getConfirmation(products: ProductViewHolder[]) {
    this.selectedProducts = [];
    if (products?.length == 0) {
      this.showPrompt = true;
    } else {
      this.selectedProducts = products;
      this.showConfirm = true;
    }
  }

  closeInfoMessage() {
    this.infoMessage = null;
  }
}
