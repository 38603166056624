import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ExchangeRate} from "../model/exchange-rates.model";
import {ExchangeRatesService} from "../service/exchange-rates.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-exchange-rates-confirm',
  templateUrl: './exchange-rates-confirm.component.html',
  styleUrls: ['./exchange-rates-confirm.component.scss']
})
export class ExchangeRatesConfirmComponent implements OnInit {
  loadingScreen: boolean = false;
  reason: any;

  @Input() changes: any[];
  @Output() cancel = new EventEmitter();
  @Output() confirmed = new EventEmitter();
  errorMessage: string;

  constructor(private exchangeRatesService: ExchangeRatesService,
              private translate: TranslateService) {}

  ngOnInit(): void {
  }

  onCancel() {
    //go back to parent screen
    this.cancel.emit();
  }

  onConfirm() {
    this.errorMessage = undefined;
    if (this.reason && this.reason.trim().length > 1) {
      this.changes.forEach((rate) => {
        rate["statusReason"] = this.reason;
      });
      this.confirmed.emit();
    }
    else{
      this.errorMessage = this.translate.instant('menu.pricing.exchange-rates.error-message.reason-required');
    }
  }
}
