import {Component, Input, OnInit} from '@angular/core';
import {Price} from "../../model/continous.price.model";
import {FaasDatesHelper} from "../../../../helper/faas.dates.helper";
import {PriceCodesMapper} from "../../model/price-codes.mapper";
import {PriceUnit} from "../../../order/model/order.model";

@Component({
  selector: 'app-price-header',
  templateUrl: './price-header.component.html',
  styleUrls: ['./price-header.component.scss']
})
export class PriceHeaderComponent extends PriceCodesMapper implements OnInit {

  @Input() price: Price;
  calculatedDate: string;
  fromDate: string;
  toDate: string;
  priceStates: string;
  priceType: string;
  priceUnit: string;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.calculatedDate = FaasDatesHelper.formatDateTimeToString(this.price?.calculatedOn);
    this.fromDate = FaasDatesHelper.formatDateToString(this.price.validity?.fromDate);
    this.toDate = FaasDatesHelper.formatDateToString(this.price.validity?.toDate);
    this.priceStates = this.getFormattedPricingState(this.price?.priceState);
    this.priceType = this.getFormattedPriceType(this.price?.priceType?.toUpperCase());
    this.priceUnit = PriceUnit[this.price?.priceUnit] == undefined ? "" : PriceUnit[this.price?.priceUnit];
  }

}
