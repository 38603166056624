<bmw-dialog id="dialog-edit-selection" data-testid="dialog-edit-selection"
  [type]="'info'"
  [show]="shouldShow"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Takeover'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="takeover()"
  (secondaryClick)="cancel()"
  (closeClick)="cancel()">
  <div *ngIf="message">
    <bmw-inline-message type="error">{{message}}</bmw-inline-message>
  </div>
  <p class="bmw-regular-text">{{ dialogText }}
  </p>
  <div class="bmw__margin-top__s">
    <div>
      <form data-testid="form-editSelectionForm" [formGroup]="editSelectionForm" class="reversed">
        <bmw-label data-testid="label-absolute-adaptation">Absolute adaptation in €:</bmw-label>
        <bmw-inputfield data-testid="input-field-relative-adaptation" id="absoluteAdaptation">
          <input data-testid="input-absolute-adaptation"
                 bmwInput
                 type="number"
                 name="absoluteAdaptation"
                 formControlName="absoluteAdaptation"
                 data-e2e="absoluteAdaptation"
          />
        </bmw-inputfield>
      </form>
    </div>
  </div>
</bmw-dialog>
<bmw-progress-circle data-testid="progress-loadingScreen" [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
