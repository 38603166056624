import {ManufacturingCost, RoyaltiesIndicator} from '../model/operations.model';
import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";
import {TypeSpecialEquipmentTransformer} from "../../product/service/type-special-equipment-transformer";
import {Type} from "../../product/model/product.model";


export class ManufacturingCostsTransformer {
  private typeSpecialEquipmentTransformer: TypeSpecialEquipmentTransformer;
  private typeSpecialEquipmentGrouping: any;

  constructor(){
    this.typeSpecialEquipmentTransformer = new TypeSpecialEquipmentTransformer();
    this.typeSpecialEquipmentGrouping = this.typeSpecialEquipmentTransformer.getTypeSpecialEquipmentGrouping();
  }

  transformManufacturingCosts(operationId: number, manufacturingCosts: ManufacturingCost[], executionCountryCodes: Type[]) {
    let manufacturingCostList = [];

    manufacturingCosts.forEach(manufacturingCost => {
      let manufacturingCostView = {};
      manufacturingCostView['priceId'] = manufacturingCost.internalIdentifier;
      manufacturingCostView['operationId'] = operationId;
      manufacturingCostView['derivate'] = manufacturingCost.derivativeCode;
      if (manufacturingCost.type.productionType == null) {
        manufacturingCostView['prodType'] = EMPTY_STRING;
      } else {
        manufacturingCostView['prodType'] = manufacturingCost.type.productionType.productionTypeCode;
      }
      if (manufacturingCost?.transferPrice?.royaltiesIndicator) {
        manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator[manufacturingCost.transferPrice.royaltiesIndicator];
      } else {
        this.determineRoyaltiesIndicatorOfType(executionCountryCodes, manufacturingCost, manufacturingCostView);
      }
      if (manufacturingCost.specialEquipment == null && manufacturingCost.packet == null) {
        manufacturingCostView['saType'] = 'T';
        manufacturingCostView['saCode'] = EMPTY_STRING;
        manufacturingCostView['designation'] = manufacturingCost.type.name;
        manufacturingCostView['sop'] = manufacturingCost.type.validity.fromDate;
        manufacturingCostView['eop'] = manufacturingCost.type.validity.toDate;
      } else if (manufacturingCost.packet) {
        manufacturingCostView['saType'] = 'PA'
        manufacturingCostView['saCode'] = manufacturingCost.packet.code;
        manufacturingCostView['designation'] = manufacturingCost.packet.name;
        manufacturingCostView['sop'] = manufacturingCost.packet.validity.fromDate;
        manufacturingCostView['eop'] = manufacturingCost.packet.validity.toDate;
      } else {
        manufacturingCostView['saType'] = manufacturingCost.specialEquipment.type;
        manufacturingCostView['saCode'] = manufacturingCost.specialEquipment.code;
        manufacturingCostView['designation'] = manufacturingCost.specialEquipment.name;
        manufacturingCostView['sop'] = manufacturingCost.specialEquipment.validity.fromDate;
        manufacturingCostView['eop'] = manufacturingCost.specialEquipment.validity.toDate;
        if (!manufacturingCost?.transferPrice?.royaltiesIndicator && manufacturingCost.specialEquipment.type === 'LA') {
          if (manufacturingCost.specialEquipment.code === '08AA') {
            manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator.ALWAYS;
          } else {
            manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator.NEVER;
          }
        }
      }
      manufacturingCostView['type'] = manufacturingCost.type.code;

      manufacturingCostView['delta'] = manufacturingCost.price?.value;

      if (manufacturingCost.previousManufacturingCosts?.length > 0) {
        let totalPreviousManufacturingCost: number = 0;
        manufacturingCost.previousManufacturingCosts.forEach(prevCost => {
          if (prevCost.price !== null) {
            totalPreviousManufacturingCost = totalPreviousManufacturingCost + prevCost.price.value;
          }
        });

        manufacturingCostView['fkOld'] = totalPreviousManufacturingCost;
      } else {
        manufacturingCostView['fkOld'] = 0;
      }

      manufacturingCostView['fkNew'] = manufacturingCost.price?.value + manufacturingCostView['fkOld'];
      manufacturingCostList.push(manufacturingCostView);
    });
    return this.sortmanufacturingCostList(manufacturingCostList);
  }

  private determineRoyaltiesIndicatorOfType(executionCountryCodes: Type[], manufacturingCost: ManufacturingCost, manufacturingCostView: {}) {
    manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator.NEVER;
    executionCountryCodes.forEach(type => {
      if (manufacturingCost.type.code === type.code) {
        let specialEquipmentSize = type.specialEquipment.length;
        type.specialEquipment.forEach(specialEquipment => {
          if (specialEquipment.code === '08AA') {
            if (specialEquipmentSize === 1) {
              manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator.ALWAYS;
            } else {
              manufacturingCostView['includeRoyalties'] = RoyaltiesIndicator.SOMETIMES;
            }
          }
        })
      }
    });
  }

  sortmanufacturingCostList(manufacturingCostList: any[]) {
      return manufacturingCostList.map(row => {
      return {
        groupOrder: this.typeSpecialEquipmentGrouping.get(row.saType).groupOrder,
        delta: row.delta,
        derivate: row.derivate,
        designation: row.designation,
        includeRoyalties: row.includeRoyalties,
        eop: row.eop,
        fkNew: row.fkNew,
        fkOld: row.fkOld,
        operationId: row.operationId,
        priceId: row.priceId,
        prodType: row.prodType,
        saCode: row.saCode,
        saType: this.typeSpecialEquipmentGrouping.get(row.saType).groupName,
        sop: row.sop,
        type: row.type
      };
    }).sort((a, b) => parseInt(a.groupOrder) - parseInt(b.groupOrder));
  }
}
