import {OrderWorkflowStateInput} from "../service/order.graphql.query";

type Nullable<T> = T | null;

export class OrderSearchRequest {
  snrList: Nullable<Array<string>>;
  orderNumber: Nullable<string>;
  ai: Nullable<number>;
  status = OrderWorkflowStateInput.APPROVED;
  plant: string;
  isError: boolean;
  error: string;
}
