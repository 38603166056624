import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {RoleOverviewGridColumns} from "./role-overview-grid.columns";
import {AgGridBase} from "../../../../ag.grid.base";

@Component({
  selector: 'app-roles-overview-grid',
  templateUrl: './roles-overview-grid.component.html',
  styleUrls: ['./roles-overview-grid.component.scss']
})
export class RolesOverviewGridComponent extends AgGridBase<RoleOverviewGridColumns> {

  @Input() showSideBar: boolean = true;
  @Input() rowData: any;
  @Output() addRole = new EventEmitter();
  @Output() doubleClick = new EventEmitter<number>();
  @Output() refresh = new EventEmitter();

  approveRejectButtonStatus = false;
  rowSelection = 'single';
  loadingScreen: boolean = false;
  paginationPageSize = 10;

  constructor(translate: TranslateService) {
    super(new RoleOverviewGridColumns(translate));
  }

  onRefresh(): void {
    this.refresh.emit();
  }

  onRefreshGridData($event: any) {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
      this.gridApi.hideOverlay();
    }
    //sort here
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 500);
    //sort date
    this.gridColumnApi.applyColumnState( {
      state: [
        {
          colId: 'name',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  }

  onNewRole(){
    this.addRole.emit();
  }

  onRowDoubleClicked(params: any) {
    if ( params.data?.internalIdentifier != undefined || params.data?.internalIdentifier != null ) {
      this.doubleClick.emit(params.data.internalIdentifier);
    }
  }
}
