import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class RoleOverviewGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
     {
       field: 'name',
       headerName: 'Name',
       resizable: true,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'workArea',
       headerName: 'Work Area',
       resizable: true,
       rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
    {
      field: 'validity.fromDate',
      headerName: 'Valid From',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'validity.toDate',
      headerName: 'Valid To',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'creator',
      headerName: 'Created By',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
   ];

}
