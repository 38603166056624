import {Component, OnInit, ViewChild} from '@angular/core';
import { OperationDetailsGridComponent } from './operation-details-grid/operation-details-grid.component';
import {TranslateService} from '@ngx-translate/core';
import {OperationService} from '../service/operation.service';
import {
  OperationFile,
  OperationChangeInput,
  Operation,
  ManufacturingCost,
  OperationSummary,
  ManufacturingCostChangeInput,
  ManufacturingCostInput, WorkflowAction
} from '../model/operations.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../product/service/product.service';
import {Plant} from '../../order/model/order.model';
import {PlantChangeService} from "../../header/service/PlantChangeService";

@Component({
  selector: 'app-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss']
})
export class OperationDetailsComponent implements OnInit {

  @ViewChild(OperationDetailsGridComponent) gridComponent;
  rowData: any;
  busy = false;
  shouldShowGrid = true;
  operation: Operation;
  fileList: OperationFile[];
  selectedDetail: any[];
  showAllocate = false;
  showRules = false;
  showAllocateSA = false;
  showTypeSADialog = false;
  message = '';
  errorMessage: string;
  dialogTitle: string;
  dialogText: string;
  showUploadDialog = false;
  showAttachments = false;
  showPromt = false;
  showPrompt = false;
  showWarning = false;
  showEditSelection = false;
  showEditOperation = false;
  workflowForm: FormGroup;
  showWorkflow = false;
  workflowForward = false;
  toReviewAndCancelDisabled = true;
  toInReleaseAndResetDisabled = true;
  releaseAndDeclineDisabled = true;
  editDisabled = false;
  editSelectionDisabled = false;
  whenOperationIsUnderEvaluation = false;
  isSystemsOperation = false;
  showTypeSA = false;
  processing = false;
  selectedManufacturingCosts: ManufacturingCost[];
  currentWorkFlowAction: string;
  hasReviewerRole = false;
  hasCapturerRole = false;
  hasReleaseRole = false;
  operationNotReadyForReview: boolean;
  operationCloned: boolean;
  submit: any;
  disableCancelOperation = false;
  statusesAllowedForCapturer = ['IN_PROGRESS', 'RESET', 'DECLINED'];
  statusesAllowedForReviewer = ['IN_REVIEW'];
  statusesAllowedForReleaser = ['IN_RELEASE'];
  workflowActions = [WorkflowAction.REVIEW, WorkflowAction.IN_RELEASE, WorkflowAction.RELEASE, WorkflowAction.RESET,
    WorkflowAction.CANCEL, WorkflowAction.DECLINE];
  showFileUploadDialog = false;
  showPriceOrRulesDialog = false;
  isPlantMsfOrNedcar = false;
  workFlows: OperationSummary[] = [];

  constructor(private operationService: OperationService,
              public translate: TranslateService,
              private productService: ProductService,
              private plantService: PlantChangeService) {

    this.checkPlants(Plant[window.sessionStorage.client]);
    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.checkPlants(plant.text);
      }
    });
  }

  ngOnInit(): void {
      this.workflowForm = new FormGroup({
      comment: new FormControl('', [Validators.required]),
    });
  }

  checkPlants(plant) {
    if (plant === Plant.NEDCAR || plant === Plant.MSF) {
      this.isPlantMsfOrNedcar = true;
    }
  }

  refresh = () =>  {
    this.showUploadDialog = false;
    this.showAttachments = false;
    this.loadOperationDetails(this.operation);
  }

  cancelUploadAttachment() {
    this.showUploadDialog = false;
  }

  cancelDownloadAttachment() {
    this.showAttachments = false;
  }

  onUpload() {
    this.errorMessage = '';
    this.dialogTitle = this.translate.instant('messages.fileupload');
    this.dialogText = this.translate.instant('messages.browseupload');
    this.showUploadDialog = true;
  }

  checkFiles(workflow: string) {
    this.dialogTitle = this.translate.instant('manufacturing-cost.tabs.opsdetail.nofilestitle');
    if (workflow === WorkflowAction.REVIEW && this.hasTransferprice() && this.hasRules()) {
      this.dialogTitle = this.translate.instant('manufacturing-cost.tabs.opsdetail.price-rulestitle');
      this.showPriceOrRulesDialog = true;
      return;
    }
    else if (workflow === WorkflowAction.REVIEW && !this.hasFiles()) {
      this.showFileUploadDialog = true;
      return;
    }
    this.onWorkflow(workflow);
  }

  onWorkflow(action: string) {
    this.showFileUploadDialog = false;
    if (this.workflowActions.includes(action as WorkflowAction)) {
      this.operationService.getOperationHistoryById(this.operation.internalIdentifier).subscribe(({data}) => {
        this.workFlows = [...data.operationHistoryById];
        const systemCreatedOperation = this.workFlows.filter(workflow => workflow.statusUser === 'PRODUCT_CRON').length > 0;
        const validFrom = new Date(this.operation.validFrom);
        const workflowChangedDate = new Date();
        if (!systemCreatedOperation && validFrom < workflowChangedDate && action !== WorkflowAction.RESET &&
          action !== WorkflowAction.DECLINE) {
          this.dialogTitle = `Submit for ${action}`;
          if (action === WorkflowAction.REVIEW) {
            this.dialogText = this.translate.instant('manufacturing-cost.tabs.opsdetail.invalid-date.review');
          } else if (action === WorkflowAction.IN_RELEASE) {
            this.dialogText = this.translate.instant('manufacturing-cost.tabs.opsdetail.invalid-date.in-release');
          } else {
            this.dialogText = this.translate.instant('manufacturing-cost.tabs.opsdetail.invalid-date.release');
          }
          this.showWarning = true;
        } else {
          this.dialogTitle = `Submit for ${action}`;
          this.workflowForm.setValue({
            comment: ''
          });
          this.workflowForm.markAsUntouched();
          this.showWorkflow = true;
          this.currentWorkFlowAction = action;
        }
      });
    }
  }

  onAllocate(event) {
    this.showAllocate = false;
    this.showEditOperation = false;
    if (event.length === 0) {
      this.dialogTitle = 'No transfer price selected.';
      this.dialogText = 'Please select a transfer price to allocate costs.';
      this.showPrompt = true;
    } else {
      this.selectedDetail = event;
      if (event[0]['saType'] === 'Type') {
        this.showAllocate = true;
        this.showAllocateSA = false;
      } else {
        this.showAllocate = false;
        this.showAllocateSA = true;
      }
    }
  }

  onRules(event) {
    this.showRules = true;
    this.showEditOperation = false;
    this.showAllocate = false;
    this.showAllocateSA = false;
    this.selectedDetail = event;
  }

  onAllocateMsg() {
    this.dialogTitle = 'You cannot select items with different SA Types.';
    this.showPromt = true;
  }

  onEditOperation() {
    this.errorMessage = undefined;
    this.showEditOperation = true;
  }

  loadOperationDetails(operationSummary: OperationSummary) {
    this.showTypeSA = false;
    this.showRules = false;
    this.showAllocate = false;
    this.showAllocateSA = false;
    this.gridComponent = {selected: undefined};
    this.operationCloned = operationSummary.cloned ? true : false;
    this.getOperationById(operationSummary.internalIdentifier);
  }

  private getOperationById(internalIdentifier: number) {
    this.processing = true;
    this.errorMessage = undefined;
    this.operation = undefined;
    this.rowData = undefined;
    this.operationService.getOperationDetail(internalIdentifier).subscribe((operation) => {
      this.processing = false;
      this.operation = operation.data.operationById;
      this.operationNotReadyForReview = this.validateManufacturingCosts();
      this.updateWorkflowButtons();
      if (this.operation.manufacturingCosts?.length > 0) {
        this.showAllocate = false;
        this.shouldShowGrid = true;
        this.productService.executionSpecialEquipmentForTypes([...new Set(this.operation.manufacturingCosts.map(manufacturingCost => manufacturingCost.type.code))])
          .subscribe(({data, loading}) => {
            this.rowData = this.operationService.mapManufacturingCostData(this.operation.internalIdentifier, this.operation.manufacturingCosts, data.productExecutionSpecialEquipmentCountryCodeByTypes);
          });
      } else {
        this.showAllocate = false;
        this.shouldShowGrid = false;
        this.rowData = undefined;
      }
    }, error => {
      this.processing = false;
      this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  applyWorkflow = () => {
    this.workflowForm.markAllAsTouched();
    if (this.workflowForm.value.comment) {
      this.processing = true;
      this.operationService.workflow(this.currentWorkFlowAction, this.getOperationChangeInput()).subscribe(({data}) => {
        this.getOperationById(this.operation.internalIdentifier);
        this.processing = false;
        this.showWorkflow = false;
        this.updateWorkflowButtons();
        this.workflowForm.setValue({
          comment: ''
        });
      });
    }
  }

  cancelEditOperation = () => {
    this.showEditOperation = false;
    this.loadOperationDetails(this.operation);
  }

  cancelWorkflow = () => {
    this.showWorkflow = false;
  }

  showOperationsDetailsAfterAllocation = (refresh: boolean) => {
    this.showAllocate = false;
    this.selectedDetail = null;
    if (refresh) {
      this.loadOperationDetails(this.operation);
    }
  }

  showOperationsDetailsAfterSAAllocation = (refresh: boolean) => {
    this.showAllocateSA = false;
    this.selectedDetail = null;
    if (refresh) {
      this.loadOperationDetails(this.operation);
    }
  }

  showOperationsDetailsAfterRules = (refresh: boolean) => {
    this.showRules = false;
    this.selectedDetail = null;
    if (refresh) {
      this.loadOperationDetails(this.operation);
    }
  }

  showPopupDialog() {
    this.showTypeSADialog = true;
  }

  cancelPopupDialog() {
    this.showTypeSADialog = false;
    this.selectedDetail = null;
    this.loadOperationDetails(this.operation);
  }

  getOperation = () => {
    return this.operation;
  }

  getManufacturingCosts = () => {
    return this.operation.manufacturingCosts;
  }


  attachments() {
    this.showAttachments = true;
    if (this.operation) {
      this.fileList = this.operation.files;
    }
  }

  addTypeSA() {
    this.showTypeSA = true;
  }

  removeTypeSA(event) {
    if (event === undefined || event.length === 0) {
      this.dialogTitle = 'No manufacturing costs selected.';
      this.dialogText = 'Please select manufacturing costs to remove.';
      this.showPrompt = true;
    } else {
      this.processing = true;
      const manufacturingCostChangeInput = new ManufacturingCostChangeInput();
      manufacturingCostChangeInput.operationId = this.operation.operationId;
      manufacturingCostChangeInput.plant = Plant[window.sessionStorage.client];
      manufacturingCostChangeInput.manufacturingCostInputs = [];
      event.forEach(manufacturingCost => {
        const manufacturingCostInput = new ManufacturingCostInput();
        manufacturingCostInput.manufacturingCostId = manufacturingCost.priceId;
        manufacturingCostChangeInput.manufacturingCostInputs.push(manufacturingCostInput);
      });
      try {
        this.operationService.removeManufacturingCosts(manufacturingCostChangeInput)
          .subscribe((data) => {
            this.processing = false;
            this.loadOperationDetails(this.operation);
          }, error => {
            this.processing = false;
            this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
          });
      } catch (error) {
        this.processing = false;
        this.errorMessage = error.message;
      }
    }
  }

  onCancel() {
    this.showTypeSA = false;
  }

  onTakeOver($event) {
    this.showTypeSA = $event;
  }

  onEditSelection(manufacturingCosts: ManufacturingCost[]) {
    this.selectedManufacturingCosts = manufacturingCosts;
    this.showEditSelection = true;
  }

  getOperationChangeInput() {
    let operation = new OperationChangeInput();
    operation.internalIdentifier = this.operation?.internalIdentifier;
    operation.statusReason = this.workflowForm.value.comment;
    return operation;
  }

  private updateWorkflowButtons() {
    this.toggleReviewButton(this.operation.workFlowStatus);
    this.toggleInReleaseButton(this.operation.workFlowStatus);
    this.toggleReleaseButton(this.operation.workFlowStatus);
    this.whenOperationIsUnderEvaluation = !this.statusesAllowedForCapturer.includes(this.operation.workFlowStatus);
    this.isSystemsOperation = this.operation.name.includes('Consistency');
  }

  private toggleReviewButton(status: string) {
    this.toReviewAndCancelDisabled = !this.statusesAllowedForCapturer.includes(status);
    this.hasCapturerRole = this.operationService.canCapture();
  }

  private toggleInReleaseButton(status: string) {
    this.toInReleaseAndResetDisabled = !this.statusesAllowedForReviewer.includes(status);
    this.hasReviewerRole = this.operationService.canReview();
  }

  private toggleReleaseButton(status: string) {
    this.releaseAndDeclineDisabled = !this.statusesAllowedForReleaser.includes(status);
    this.hasReleaseRole = this.operationService.canRelease();
  }

  hasFiles(): boolean {
    if (this.operation.files?.length === 0) {
      return false;
    }
    return true;
  }

  validateManufacturingCosts(): boolean {
    if (this.operation?.manufacturingCosts.length === 0 && this.operation?.rules.length === 0) {
      return true;
    }

    for (let manufacturingCost of this.operation?.manufacturingCosts) {
      if (manufacturingCost.type.internalIdentifier && !manufacturingCost.specialEquipment?.internalIdentifier
        && (!manufacturingCost.price || this.isNewTransferPriceSmallerThanZero(manufacturingCost))) {
        return true;
      } else if (manufacturingCost.specialEquipment?.internalIdentifier
        && !manufacturingCost.price) {
        return true;
      }
    }
    return false;
  }

  isNewTransferPriceSmallerThanZero(manufacturingCost: ManufacturingCost):boolean {
    let totalPreviousManufacturingCost: number = 0;
    if (manufacturingCost.previousManufacturingCosts?.length > 0) {
      manufacturingCost.previousManufacturingCosts.forEach(previousManufacturingCost => {
        if (previousManufacturingCost.price !== null) {
          totalPreviousManufacturingCost = totalPreviousManufacturingCost + previousManufacturingCost.price.value;
        }
      });
    }
    if ((totalPreviousManufacturingCost + manufacturingCost.price.value) < 0) {
      return true;
    } else {
      return false;
    }
  }

  private hasTransferprice() {
    return this.operation.manufacturingCosts?.length > 0;
  }

  private hasRules() {
    return this.operation.rules.length > 0;
  }
}
