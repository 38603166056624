<div class="table-wrapper bmw__margin-top__s">
  <form class="reversed">
    <div class="bmw__grid__inner">
      <div
        class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-6 form-template-grid-start">
        <p
          class="bmw-medium-text bmw__margin-top__xs"> {{ 'period-end-closing.aggregation.introduction' | translate }}</p>
      </div>
    </div>
    <div>
      <bmw-progress-circle [fullScreen]="true" loadingLabel="updating..." [show]="loading"></bmw-progress-circle>
      <div *ngIf="inLineMessage" class="bmw__margin-top__l">
        <bmw-inline-message [type]="inlineType">
          {{inLineMessage}}
        </bmw-inline-message>
      </div>
    </div>
    <app-product-week-search-bar  (onUpdate)="updateBomPrice($event)"></app-product-week-search-bar>
    <div class="bmw__margin-top__l">
      <faas-recalc-request-grid [rowData]="rowData" (refresh)="onRefresh()"></faas-recalc-request-grid>
    </div>
  </form>
</div>
