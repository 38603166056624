import {ExchangeRate, ExchangeRateState, ExchangeRateType, ISOCurrency} from "../model/exchange-rates.model";
import {Plant} from "../../order/model/order.model";

export class ExchangeRatesTransformer {

  months: string[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  transformExchangeRates = (exchangeRates: ExchangeRate[], useRelatedExchangeRates: boolean): Map<number, any> => {
    let transformedExchangeRates: any = [];
    let exchangeRatesMap = new Map();

    for (let exchangeRate of exchangeRates) {
      if (!exchangeRatesMap.has(exchangeRate.year)){
        let currencyValuesMap = new Map();
        currencyValuesMap.set(exchangeRate.currency.currency, this.setExchangeRates(exchangeRate, useRelatedExchangeRates));
        exchangeRatesMap.set(exchangeRate.year, currencyValuesMap);
      }else{
        let currencyValuesMap = exchangeRatesMap.get(exchangeRate.year);
        if (!currencyValuesMap.has(exchangeRate.currency.currency)){
          currencyValuesMap.set(exchangeRate.currency.currency, this.setExchangeRates(exchangeRate, useRelatedExchangeRates));
        }else{
          if (useRelatedExchangeRates){
            this.setReleaseExchangeRateValue(exchangeRate, currencyValuesMap.get(exchangeRate.currency.currency));
          }else {
            this.setExchangeRateValue(exchangeRate, currencyValuesMap.get(exchangeRate.currency.currency));
          }
        }
      }
    }
    exchangeRatesMap.forEach((yearMap: Map<any,any>, key: number) => {
      yearMap.forEach((currencyMap: Map<any,any>, key: any) => {
        let results = this.flattenMap(currencyMap);
        transformedExchangeRates.push(results);
      });
    });
    transformedExchangeRates.sort((a, b) => parseInt(a.year) - parseInt(b.year));
    return transformedExchangeRates;

  };

  private setExchangeRates(exchangeRate: ExchangeRate, useRelatedExchangeRates: boolean) {
    let monthsMap = new Map([
      ["year", ""],
      ["currency", ""],
      ["baseCurrency", ""],
      ["jan", "0"],
      ["jan_id", "0"],
      ["feb", "0"],
      ["feb_id", "0"],
      ["mar", "0"],
      ["mar_id", "0"],
      ["apr", "0"],
      ["apr_id", "0"],
      ["may", "0"],
      ["may_id", "0"],
      ["jun", "0"],
      ["jun_id", "0"],
      ["jul", "0"],
      ["jul_id", "0"],
      ["aug", "0"],
      ["aug_id", "0"],
      ["sep", "0"],
      ["sep_id", "0"],
      ["oct", "0"],
      ["oct_id", "0"],
      ["nov", "0"],
      ["nov_id", "0"],
      ["dec", "0"],
      ["dec_id", "0"],
      ["current", "0"],
      ["current_id", "0"],
      ["perNov", "0"],
      ["perNov_id", "0"],
      ["perNovPend", "false"],
      ["perNovPendState", ""],
      ["perNovToFreeze", "false"],
      ["final", "0"],
      ["final_id", "0"],
      ["finalPend", "false"],
      ["finalPendState", ""],
    ]);

    if(useRelatedExchangeRates){
      this.setReleaseExchangeRateValue(exchangeRate, monthsMap);
    }else {
      this.setExchangeRateValue(exchangeRate, monthsMap);
    }
    monthsMap.set("year", exchangeRate.year.toString());
    monthsMap.set("currency", exchangeRate.currency.currency);
    return monthsMap;
  }

  setExchangeRateValue(exchangeRate: ExchangeRate, monthsMap: Map<any,any>){
    if (exchangeRate.exchangeRateType === ExchangeRateType.IN_MONTH_F2) {
      let monthKey = this.months[exchangeRate.month - 1];
      monthsMap.set(monthKey, exchangeRate.value.toString());
      monthsMap.set(monthKey+"_id", exchangeRate.internalIdentifier);
    }else if(exchangeRate.exchangeRateType === ExchangeRateType.YEAR_FINAL){
      this.setFinalAndPerNovRateValues(monthsMap, "final", exchangeRate, this.hasPendingRelatedRates(exchangeRate));
    } else if(exchangeRate.exchangeRateType === ExchangeRateType.PER_MONTH_CURR_F_C){
      monthsMap.set("current", exchangeRate.value.toString());
      monthsMap.set("current_id", exchangeRate.internalIdentifier);
    }else if(exchangeRate.exchangeRateType === ExchangeRateType.YEAR_ACCRUAL){
      this.setFinalAndPerNovRateValues(monthsMap, "perNov", exchangeRate, this.hasPendingRelatedRates(exchangeRate));
    }
  }

  setReleaseExchangeRateValue(exchangeRate: ExchangeRate, monthsMap: Map<any,any>){
    if (exchangeRate.exchangeRateType === ExchangeRateType.IN_MONTH_F2) {
      let monthKey = this.months[exchangeRate.month - 1];
      monthsMap.set(monthKey, exchangeRate.value.toString());
      monthsMap.set(monthKey+"_id", exchangeRate.internalIdentifier);
    }else if(exchangeRate.exchangeRateType === ExchangeRateType.YEAR_FINAL){
      this.setFinalAndPerNovExchangeRate(exchangeRate, monthsMap, "final");
    } else if(exchangeRate.exchangeRateType === ExchangeRateType.PER_MONTH_CURR_F_C){
      monthsMap.set("current", exchangeRate.value.toString());
      monthsMap.set("current_id", exchangeRate.internalIdentifier);
    }else if(exchangeRate.exchangeRateType === ExchangeRateType.YEAR_ACCRUAL){
      this.setFinalAndPerNovExchangeRate(exchangeRate, monthsMap, "perNov");
    }
  }

  private setFinalAndPerNovExchangeRate(exchangeRate: ExchangeRate, monthsMap: Map<any, any>, rateName) {
    if (exchangeRate.relatedExchangeRates && exchangeRate.relatedExchangeRates.length > 0) {
      exchangeRate.relatedExchangeRates.forEach((rate) => {
        if (rate.state == ExchangeRateState.PENDING_APPROVAL) {
          this.setFinalAndPerNovRateValues(monthsMap, rateName, rate, "false");
        }
      });
    } else {
      this.setFinalAndPerNovRateValues(monthsMap, rateName, exchangeRate, "false");
    }
  }

  private setFinalAndPerNovRateValues(monthsMap: Map<any, any>, rateName, exchangeRate: ExchangeRate, pendingState:string) {
    monthsMap.set(rateName, exchangeRate.value.toString());
    monthsMap.set(rateName + "_id", exchangeRate.internalIdentifier);
    monthsMap.set(rateName + "Pend", pendingState);
    monthsMap.set(rateName + "PendState", exchangeRate.state);
  }

  flattenMap(myMap: Map<any,any>): any {
    return JSON.parse(JSON.stringify(
      Array.from(
        myMap.entries()
      )
        .reduce((o, [key, value]) => {
          o[key] = value;
          return o;
        }, {})
    ))
  }

  hasPendingRelatedRates(exchangeRate: ExchangeRate): string{
    let result:string = "false";

    if (exchangeRate.relatedExchangeRates) {
      exchangeRate.relatedExchangeRates.forEach((rate) => {
        if (rate.state == ExchangeRateState.PENDING_APPROVAL) {
          result = "true";
        }
      })
    }
    return result;
  }

  transformExchangeRateToSave(addedExchangeRates: any) :ExchangeRate[] {
    let results:ExchangeRate[] = [];
    addedExchangeRates.forEach((viewModelExchangeRate) => {
      let transformedExchangeRates = this.transformMonthlyExchangeRates(viewModelExchangeRate);
      this.getExchangeRateFromViewModel(transformedExchangeRates, viewModelExchangeRate, ExchangeRateType.PER_MONTH_CURR_F_C, "current");
      this.getExchangeRateFromViewModel(transformedExchangeRates, viewModelExchangeRate, ExchangeRateType.YEAR_ACCRUAL, "perNov");
      this.getExchangeRateFromViewModel(transformedExchangeRates, viewModelExchangeRate, ExchangeRateType.YEAR_FINAL, "final");
      results.push(...transformedExchangeRates);
    });
    return results;
  }

  transformMonthlyExchangeRates(viewModelExchangeRate: any):ExchangeRate[] {
    let results:ExchangeRate[] = [];
    this.months.forEach((month, index) => {
      if (viewModelExchangeRate[month]) {
        let monthlyExchangeRate = new ExchangeRate();
        monthlyExchangeRate.internalIdentifier = viewModelExchangeRate[month + "_id"]
        monthlyExchangeRate.value = Number(viewModelExchangeRate[month]);
        monthlyExchangeRate.exchangeRateType = ExchangeRateType.IN_MONTH_F2;
        monthlyExchangeRate.currency = this.getISOCurrency(viewModelExchangeRate.currency);
        monthlyExchangeRate.year = viewModelExchangeRate.year
        monthlyExchangeRate.month = index + 1;
        monthlyExchangeRate.plant = Plant[window.sessionStorage.client];
        results.push(monthlyExchangeRate);
      }
    });
    return results;
  }

  getExchangeRateFromViewModel(transformedExchangeRates:any[], viewModelExchangeRate: any, exchangeRateType: ExchangeRateType, valueName: string){
    if (viewModelExchangeRate[valueName]) {
      let rate = new ExchangeRate();
      rate.internalIdentifier = viewModelExchangeRate[valueName + "_id"];
      rate.value = Number(viewModelExchangeRate[valueName]);
      rate.exchangeRateType = exchangeRateType;
      rate.currency = this.getISOCurrency(viewModelExchangeRate.currency);
      rate.year = viewModelExchangeRate.year;
      rate.plant = Plant[window.sessionStorage.client];
      if (viewModelExchangeRate.inserted){
        if (rate.value > 0  && rate.exchangeRateType !== ExchangeRateType.PER_MONTH_CURR_F_C){
          rate.statusReason = viewModelExchangeRate.statusReason;
        }
      }else{
        rate.statusReason = viewModelExchangeRate.statusReason;
      }

      transformedExchangeRates.push(rate);
    }
  }

  getISOCurrency(currencyString: string) : ISOCurrency {
    let currency = new ISOCurrency();
    currency.currency = currencyString;
    return currency;
  }
}
