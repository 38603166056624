import gql from "graphql-tag";
import {ProductionWeek, ProductionWeekPriceResponse} from "../model/aggregations.models";

export const PRODUCTION_WEEK_FOR = gql`
   query getProductionYearWeeksFor($plant: Plant!) {
      getProductionYearWeeksFor(plant: $plant) {
        plant
        week
        year
        derivativeCode
      }
   }
`;

export const ALL_RECALCULATION_REQUEST = gql`
  query allPriceRecalculationRequest($plant: Plant!) {
      allPriceRecalculationRequest(plant: $plant) {
        ai
        auditEventType
        createdDate
        derivativeCode
        internalIdentifier
        orderNo
        plant
        auditStatus
        snr
        statusDate
        user
        week
        year
      }
  }
`;


export interface RecalculationResponse {
  allPriceRecalculationRequest: ProductionWeekPriceResponse[];
}

export interface ProductionWeekResponse {
  getProductionYearWeeksFor: ProductionWeek[];
}
