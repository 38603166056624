import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Operation, OperationChangeInput, OperationFile} from "../model/operations.model";
import {faas} from "../../../constants/faas.constants";
import {TranslateService} from "@ngx-translate/core";
import {OperationService} from "../service/operation.service";

@Component({
  selector: 'app-download-attachment',
  templateUrl: './download-attachment.component.html',
  styleUrls: ['./download-attachment.component.scss']
})
export class DownloadAttachmentComponent implements OnInit {

  @Input() showAttachments: boolean;
  @Input() operation: Operation;
  @Input() fileList: OperationFile[];
  @Output() cancelDownloadAttachment = new EventEmitter();
  translate: TranslateService;
  dialogTitle: string;
  message: string;
  dialogText: string;
  loadingScreen: boolean = false;
  errorMessage: string;

  constructor(translate: TranslateService, private operationService: OperationService) {
    this.translate = translate;
  }

  ngOnInit(): void {
    this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.download');
    this.message = '';
    this.errorMessage = '';
  }

  closeDownload() {
    this.message = '';
    this.errorMessage = '';
    this.cancelDownloadAttachment.emit();
  }

  updateFileList(internalIdentifier: number) {
    const fileIndex = this.fileList.findIndex(file => file.internalIdentifier === internalIdentifier);
    this.fileList.splice(fileIndex, 1);
    if (this.fileList.length == 0) {
      this.closeDownload();
    }
  }

  Base64ToFile(url, filename, mimeType) {
    return (fetch(url)
        .then(function (res) {
          return res.arrayBuffer();
        })
        .then(function (buf) {
          return new File([buf], filename, {type: mimeType});
        })
    );
  }

  downloadAttachment(file: OperationFile) {
    this.loadingScreen = true;
    this.operationService.getOperationFileById(file.internalIdentifier).subscribe(({data}) => {
      this.Base64ToFile(data.operationFileById.content, file.name, faas.contentType)
        .then(test => {
          this.loadingScreen = false;
          let a = document.createElement('a');
          let url = window.URL.createObjectURL(test);
          a.href = url;
          a.download = test.name;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          }, 100);
        })
        .catch(error => console.error(JSON.stringify(error)))
    });
  }

  deleteAttachment(internalIdentifier: number) {
    this.loadingScreen = true;
    this.message = '';
    this.errorMessage = '';
    try {
      this.operationService.deleteFileForOperation(this.operation.internalIdentifier, internalIdentifier)
        .subscribe((data) => {
          this.updateFileList(internalIdentifier);
          this.loadingScreen = false;
          this.message = this.translate.instant('messages.attachment-delete-success');
      }, error => {
        this.loadingScreen = false;
        this.message = this.translate.instant('messages.attachment-delete-failure');
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
    } catch (error) {
      this.loadingScreen = false;
      this.errorMessage = error.message;
    }
  }
}
