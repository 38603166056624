<div *ngIf="showPartSearchDialog">
  <bmw-dialog
    [type]="'info'"
    [show]="true"
    [closeButton]="true"
    [title]="searchTitle"
    [primaryLabel]="'OK'"
    [primaryDisabled]="false"
    (primaryClick)="onSave()"
    (closeClick)="onClose()">
    {{ 'dialogs.part-search-dialog.search-description' | translate }}
    <br>
    <br>
    <app-part-grid
      [partData]="partData"
      (onPaste)="updatePartListState($event)"
    ></app-part-grid>
  </bmw-dialog>
</div>
