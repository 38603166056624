import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";

interface WeightingsEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextWeightingsEnums: Record<LocalCodes, WeightingsEnum[]> = {
  en: [
    //Weight Type
    {name: 'PLANNED', translated: 'PLANNED'},
    {name: 'GEPLANT', translated: 'PLANNED'},
    {name: 'ACTUAL', translated: 'ACTUAL'},
    {name: 'AKTUELL', translated: 'ACTUAL'}
  ],
  de: [
    //Weight Type
    {name: 'PLANNED', translated: 'GEPLANT'},
    {name: 'GEPLANT', translated: 'GEPLANT'},
    {name: 'ACTUAL', translated: 'AKTUELL'},
    {name: 'AKTUELL', translated: 'AKTUELL'}
  ]
};

export class TranslateWeightingsEnum {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getWeightingsEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getWeightingsEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let weightingsEnum = localTextWeightingsEnums[lang].find(e => e.name === key);
    if (weightingsEnum) {
      return weightingsEnum?.translated;
    }
    return localTextWeightingsEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

