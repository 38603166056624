import gql from "graphql-tag";

export const APPROVE_PRICE = gql`
  mutation ($prices: [PriceInput]!) {
    approveFreezePrice(prices: $prices)
  }
`;

export const APPROVE_PRICES_FOR_BATCH = gql`
  mutation ($prices: [PriceInput]!) {
    approveFreezePricesForBatch(prices: $prices)
  }
`;

export const REJECT_PRICE = gql`
  mutation ($prices: [PriceInput]!) {
    rejectFreezePrice(prices: $prices)
  }
`;

export const TRIGGER_APPROVE_FREEZE_PRICE_JOB = gql`
  mutation {
    triggerApproveFreezePriceJob
  }
`;

