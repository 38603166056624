import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-vehicle-information-cell-renderer',
  template: `
    <a style="cursor: pointer" (click)="onInformationClick($event)"><span class="iwp-icon-gen_info">
    </span></a>
`
})
export class VehicleInformationCellRenderer implements ICellRendererAngularComp {
  public params: any;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onInformationClick($event) {
    if (this.params.onCellClicked instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onCellClicked(params);
    }
  }
}
