<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.budget.prod-master-data.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab (selectionChange)="tabChanged($event)">
          <bmw-content-tab-label for="data-import" [selected]="true">{{ 'menu.budget.prod-master-data.data-import.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="data-import">
            <app-product-configuration></app-product-configuration>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="data-view">{{ 'menu.budget.prod-master-data.data-view.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="data-view">
            <app-product-master-data-display></app-product-master-data-display>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
