<form [formGroup]="yearForm">
  <div *ngIf="!showConfirmScreen">
    <div class="bmw__grid__inner">
      <div
        class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
        <div
          class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
        <span>
      <div>
      <bmw-label>
      <span class="cell-bud" style="border: 1px solid black;">
        &nbsp;
        &nbsp;
      </span>&nbsp; {{ 'menu.pricing.exchange-rates.exchange-legend-release' | translate }} &nbsp;&nbsp;</bmw-label>
      </div>
      <div class="exchange-rate">
        <bmw-label>
        <span class="cell-act" style="border: 1px solid black;">
          &nbsp;
          &nbsp;
        </span>&nbsp; {{ 'menu.pricing.exchange-rates.exchange-legend-frozen' | translate }}</bmw-label>
      </div>
    </span>
        </div>
<!--        <bmw-progress-circle [fullScreen]="true" loadingLabel="loading..." [show]="loadingScreen"></bmw-progress-circle>-->
        <div
          class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
          <bmw-combo-box formControlName="exchangeRateYear"
                         (changeEvent)="filterRecordsByYear($event)">
            <bmw-label>{{ 'component.search-bar.year' | translate }}</bmw-label>
            <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let year of years" value="{{year}}"
                                         label="{{year}}"></bmw-combo-box-dropdown-item>
          </bmw-combo-box>
        </div>
      </div>
    </div>
    <bmw-ag-grid-theme>
      <div *ngIf="errorMessage"
        class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
        <bmw-inline-message type="error">
          {{errorMessage}}
        </bmw-inline-message>
      </div>
      <div
        *ngIf="validationMessage"
        class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
        <bmw-inline-message type="error">
          {{validationMessage}}
        </bmw-inline-message>
      </div>

      <div class="bmw__margin-top__xs" *ngIf="shouldShowGrid">
        <div class="bmw__margin-top__xs">
          <app-exchange-rates-grid [rowData]="rowData"
                                   [groupBy]="groupByColumns"
                                   [addExchangeRate]="addExchangeRate"
                                   [cancelExchangeRateChange]="cancelExchangeRateChange">
          </app-exchange-rates-grid>
        </div>
        <div [hidden]="!isPlantSALorGWM" class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
          <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
            <bmw-button button="secondary" type="submit" (clickEvent)="refreshData()" [disabled]="disableRefreshButton" class="bmw__margin-right__xs">
              {{ 'component.bottom-bar.refresh' | translate }}
            </bmw-button>
            <bmw-button button="secondary" type="submit" (clickEvent)="insertNewExchangeRate()" [hidden]="isNotControllingPP" [disabled]="disableInsertButton"  class="bmw__margin-right__xs">
              {{ 'component.bottom-bar.insertLine' | translate }}
            </bmw-button>
            <bmw-button button="secondary" type="submit" (clickEvent)="onEditExchangeRates()" [hidden]="isNotControllingPP" [disabled]="disableEditButton" class="bmw__margin-right__xs">
              {{ 'component.bottom-bar.edit' | translate }}
            </bmw-button>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
            <bmw-button button="primary" type="submit" (clickEvent)="onCancel()" [disabled]="disableCancelButton" class="bmw__margin-right__xs" pull-right>
              {{ 'component.bottom-bar.cancel' | translate }}
            </bmw-button>
            <bmw-button button="primary" type="submit" (clickEvent)="onSave()" [disabled]="noDataUpdatedOrInserted()" class="bmw__margin-right__xs">
              {{ 'component.bottom-bar.save' | translate }}
            </bmw-button>
            <bmw-button button="primary" type="submit" (clickEvent)="onFreeze()" [hidden]="isNotSeriesCalculation" [disabled]="noDataToFreeze()" class="bmw__margin-right__xs">
              {{ 'component.bottom-bar.freeze' | translate }}
            </bmw-button>
          </div>
        </div>
      </div>
    </bmw-ag-grid-theme>
  </div>
  <div *ngIf="showConfirmScreen">
    <app-exchange-rates-confirm
      [changes]="confirmExchangeRates"
      (cancel)="toggleView()"
      (confirmed)="onConfirmation()"
    ></app-exchange-rates-confirm>
  </div>
</form>


