<div *ngIf="shouldShowGrid">
  <ag-grid-angular
    style="height: 500px; overflow: scroll"
    class="zebra"
    [rowData]="rowData"
    [columnDefs]="runtimeCompilerData.columns"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [sideBar]="runtimeCompilerData.sideBar"
    [rowSelection]="rowSelection"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (modelUpdated)="onModelUpdated($event)"
    (rowDataChanged)="onRefreshGridData($event)"
    (rowSelected)="onRowSelected()"
    (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
    [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
    [pagination]="true"
    [groupSelectsChildren]="true"
    [groupSelectsFiltered]="true"
    [rowMultiSelectWithClick]="true">
  </ag-grid-angular>
</div>
<div><br/></div>
<div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <p style="float: left">
      <bmw-button  button="secondary" (clickEvent)="onLoadData()" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.refresh' | translate }}
      </bmw-button>
      <bmw-button *ngIf="hasCaptureRole" [disabled]="whenOperationIsUnderEvaluation" button="secondary" (clickEvent)="onUpload()">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.upload' | translate }}
      </bmw-button>
    </p>
    <p style="float: right">
      <bmw-button button="primary" (clickEvent)="onAddTypeSA()" *ngIf="hasCaptureRole" [disabled]="whenOperationIsUnderEvaluation || isSystemsOperation" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.addtypesa' | translate }}
      </bmw-button>
      <bmw-button button="primary" (clickEvent)="onRemoveTypeSA()" *ngIf="hasCaptureRole" [disabled]="removeDisabled" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.removetypesa' | translate }}
      </bmw-button>
      <bmw-button button="primary" (clickEvent)="onAllocate()" [disabled]="allocateDisabled" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.allocations' | translate }}
      </bmw-button>
      <bmw-button button="primary" (clickEvent)="onRules()"  [disabled]="rulesDisabled" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.rules' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('review')" *ngIf="hasCaptureRole" [disabled]="toReviewAndCancelDisabled || operationNotReadyForReview || operationCloned" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.toreview' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('in-release')" *ngIf="hasReviewerRole" [disabled]="toInReleaseAndResetDisabled" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.torelease' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('release')" *ngIf="hasReleaseRole" [disabled]="releaseAndDeclineDisabled" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.release' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('reset')" *ngIf="hasReviewerRole" [disabled]="toInReleaseAndResetDisabled" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.reset' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('cancel')" *ngIf="hasCaptureRole" [disabled]="toReviewAndCancelDisabled" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.cancel' | translate }}
      </bmw-button>
      <bmw-button (clickEvent)="onWorkflow('decline')" *ngIf="hasReleaseRole" [disabled]="releaseAndDeclineDisabled" button="primary" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.reject' | translate }}
      </bmw-button>
      <bmw-button button="primary" (clickEvent)="onEdit()" *ngIf="hasCaptureRole" [disabled]="whenOperationIsUnderEvaluation">
        {{ 'menu.manufacturing-cost.tabs.opsdetail.edit' | translate }}
      </bmw-button>
    </p>
  </div>
</div>
