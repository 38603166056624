import {Injectable} from "@angular/core";
import {Apollo, QueryRef} from 'apollo-angular';
import {Observable} from "rxjs";
import {HttpClient} from '@angular/common/http';
import {ExchangeRatesTransformer} from "./exchange-rates.transformer";
import {
  EXCHANGE_RATES_QUERY,
  ExchangeRatesQueryResponse,
  ExchangeRatesVariables,
  FOREIGN_CURRENCIES_QUERY,
  ForeignCurrenciesQueryResponse
} from "../exchange-rates-overview/exchange-rates.graphql.query";
import {Plant} from "../../order/model/order.model";
import {
  ADD_EXCHANGE_RATES, FREEZE_EXCHANGE_RATES,
  REJECT_EXCHANGE_RATES,
  RELEASE_EXCHANGE_RATES,
  UPDATE_EXCHANGE_RATES
} from "../exchange-rates-overview/exchange-rates.graphql.mutation";
import {
  EXCHANGE_RATES_PENDING_APPROVAL_QUERY,
  ExchangeRatesReleaseQueryResponse,
  ExchangeRatesReleaseVariables
} from "../exchange-rates-release/exchange-rates-release.graphql.query";
import {AuthService} from "../../../services/auth.service";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ExchangeRatesService {
  transformer: ExchangeRatesTransformer;
  exchangeRatesRef: QueryRef<ExchangeRatesQueryResponse, ExchangeRatesVariables>;
  exchangeRatesPendingRef: QueryRef<ExchangeRatesReleaseQueryResponse, ExchangeRatesReleaseVariables>;
  foreignCurrenciesRef: QueryRef<ForeignCurrenciesQueryResponse, ExchangeRatesVariables>;

  notExistError = this.translate.instant('menu.pricing.order-management.error-message.exchange-rate-not-exist-error');

  constructor(private translate: TranslateService,
              private apollo: Apollo, private authService: AuthService) {
    this.transformer = new ExchangeRatesTransformer();
  }

  getExchangeRates(year: number): QueryRef<ExchangeRatesQueryResponse, ExchangeRatesVariables> {
    this.exchangeRatesRef = this.apollo.watchQuery<ExchangeRatesQueryResponse, ExchangeRatesVariables>({
      query: EXCHANGE_RATES_QUERY,
      fetchPolicy: "network-only"
    });

    this.exchangeRatesRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      year: year
    });
    return this.exchangeRatesRef;
  }

  getExchangeRatesPendingApproval(): Observable<any> {
    this.exchangeRatesPendingRef = this.apollo.watchQuery<ExchangeRatesReleaseQueryResponse, ExchangeRatesReleaseVariables>({
      query: EXCHANGE_RATES_PENDING_APPROVAL_QUERY,
      fetchPolicy: "network-only"
    });

    this.exchangeRatesPendingRef.setVariables({
      plant: Plant[window.sessionStorage.client],
    });
    return this.exchangeRatesPendingRef.valueChanges;
  }

  getForeignCurrencies(): QueryRef<ForeignCurrenciesQueryResponse, ExchangeRatesVariables> {
    this.foreignCurrenciesRef = this.apollo.watchQuery<ForeignCurrenciesQueryResponse, ExchangeRatesVariables>({
      query: FOREIGN_CURRENCIES_QUERY,
      fetchPolicy: "network-only"
    });

    this.foreignCurrenciesRef.setVariables({
      plant: Plant[window.sessionStorage.client],
    });
    return this.foreignCurrenciesRef;
  }

  removeGraphQLErrorOnMessage(errorMessage: string) {
    let message = errorMessage.replace('GraphQL error: ', '');
    if (message == "Order does not exist for given parameters")
      message = this.translate.instant('menu.pricing.order-management.error-message.order-does-not-exist');

    if (message == "Exchange rates not found for given parameters")
      message = this.translate.instant('menu.pricing.order-management.error-message.exchange-rate-not-exist-error');

    return message;
  }

  mapOverviewExchangeRates(exchangeRates: any): any {
    return this.transformer.transformExchangeRates(exchangeRates, false);
  }

  mapReleaseExchangeRates(exchangeRates: any): any {
    return this.transformer.transformExchangeRates(exchangeRates, true);
  }

  transformAndAddRates(addedExchangeRates: any):Observable<any> {
    let exchangeRates = this.transformer.transformExchangeRateToSave(addedExchangeRates);
      return this.apollo.mutate({
        mutation: ADD_EXCHANGE_RATES,
        variables: {
          exchangeRates: exchangeRates
        }
      });
  }

  transformAndUpdateRates(updatedExchangeRates: any[]) {
    let exchangeRates = this.transformer.transformExchangeRateToSave(updatedExchangeRates);
    return this.apollo.mutate({
      mutation: UPDATE_EXCHANGE_RATES,
      variables: {
        updateExchangeRates: exchangeRates
      }
    });
  }

  releaseExchangeRatesPendingApproval(exchangeRatesToRelease: any): Observable<any> {
    let exchangeRates = this.transformer.transformExchangeRateToSave(exchangeRatesToRelease);
    return this.apollo.mutate({
      mutation: RELEASE_EXCHANGE_RATES,
      variables: {
        approveExchangeRates: exchangeRates
      }
    });
  }

  rejectExchangeRatesPendingApproval(exchangeRatesToReject: any): Observable<any> {
    let exchangeRates = this.transformer.transformExchangeRateToSave(exchangeRatesToReject);
    return this.apollo.mutate({
      mutation: REJECT_EXCHANGE_RATES,
      variables: {
        rejectExchangeRates: exchangeRates
      }
    });
  }

  freezeExchangeRates(exchangeRatesToFreeze: any) : Observable<any> {
    let exchangeRates = this.transformer.transformExchangeRateToSave(exchangeRatesToFreeze);
    return this.apollo.mutate({
      mutation: FREEZE_EXCHANGE_RATES,
      variables: {
        freezeExchangeRates: exchangeRates
      }
    });
  }

  getExchangeRateYears(){
    let maxYear = new Date().getFullYear() + 1, years = [];
    let minYear = 2015;
    for(let i = maxYear ; i >= minYear; i--) {
      years.push(i.toString());
    }
    return years;
  }

  isNoDataErrorOnMessage(errorMessage: string): boolean {
    return errorMessage.indexOf(this.notExistError) > -1;
  }

  isControllingPP() {
    let permissions = ['T_Waehrung_Bearbeiter_MSF','T_Waehrung_Bearbeiter_NED','T_Waehrung_Bearbeiter_SAL','T_Waehrung_Bearbeiter_GWM'];
    return this.authService.checkPermission(permissions);
  }

  isSeriesCalculation() {
    let permissions = ['T_Waehrung_Freezer_BMW_NED','T_Waehrung_Freezer_BMW_MSF'];
    return this.authService.checkPermission(permissions);
  }

}
