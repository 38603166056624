import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {OperationTypeSaGridColumns} from './operation-type-sa-grid.columns';
import {AgGridBase} from "../../../../ag.grid.base";

@Component({
  selector: 'app-operation-type-sa-grid',
  templateUrl: './operation-type-sa-grid.component.html',
  styleUrls: ['./operation-type-sa-grid.component.scss']
})
export class OperationTypeSaGridComponent extends AgGridBase<OperationTypeSaGridColumns>{
  rowSelection = 'multiple';

  @Output() takeOver = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() rowSelected = new EventEmitter();
  @Input() allocateDisabled: boolean;

  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;

  constructor(translate: TranslateService) {
    super(new OperationTypeSaGridColumns(translate));
  }

  onCancel(){
    this.cancel.emit();
  }

  onTakeOver(){
    this.takeOver.emit(this.gridApi.getSelectedRows());
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'selected', sort: 'desc'},
      {colId: 'prod_derivative', sort: 'asc'},
      {colId: 'prod_designationtype', sort: 'asc'},
      {colId: 'prod_typecode', sort: 'asc'},
      {colId: 'prod_designationsa', sort: 'asc'},
      {colId: 'prod_sacode', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

  onFirstDataRendered(params): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
    this.gridApi.forEachNode(node => {
      this.rowData.filter(f => f.selected === true).forEach(row => {
        if(row.prod_said && row.prod_typeid) {
          if(row.prod_typeid === node?.data?.prod_typeid && row.prod_said === node?.data?.prod_said){
            node.setSelected(true);
          }
        } else if(row.prod_typeid) {
          if(row.prod_typeid === node?.data?.prod_typeid && !node?.data?.prod_said) {
            node.setSelected(true);
          }
        }
      })
    })
  }
}