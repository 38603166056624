import {Injectable} from "@angular/core";
import {Apollo, QueryRef} from 'apollo-angular';
import {
  CurrentLoggedInUserFullName,
  LOGGED_IN_USER_QUERY
} from "./logged-in-user.graphql.query";
import {ServiceBase} from "../../../services/service.base";

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService extends ServiceBase {

  constructor(apollo: Apollo){
    super(apollo);
  }

  getCurrentLoggedInUserFullName(){
    return this.query<CurrentLoggedInUserFullName>(null,LOGGED_IN_USER_QUERY);
  }

  dispose(): void {
  }

}
