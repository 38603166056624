import gql from "graphql-tag";
import {Order, Plant} from "../model/order.model";

export const ORDER_HISTORY_SNR_AI_QUERY = gql`
  query ordersForMaterial($snrList: [String]! $plant: PlantInput! $ai: Int) {
    ordersForMaterial(snrList: $snrList plant: $plant ai:$ai){
    changeDate
    material{
      ai
      snr
      plant
    }
    internalIdentifier
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    orderNo
    orderState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity{
        fromDate
        toDate
      }
      surcharges{
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
    }
    relatedOrders {
      changeDate
      material {
       ai
       snr
       plant
      }
      internalIdentifier
      orderCurrency {
        currency
        currencyType
        displayFormat
      }
      orderNo
      orderState
      orderType
      quantityUnit
      supplierNo
      version
      workflowState
      pricingPeriods {
        internalIdentifier
        priceUnit
        basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
     }
   }
  }
 }`;

export const ORDER_HISTORY_QUERY = gql`
  query orders($orderNo: String! $plant: PlantInput!) {
    orders(orderNo: $orderNo plant: $plant){
    changeDate
    material{
      ai
      snr
      plant
    }
    internalIdentifier
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    orderNo
    orderState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity{
        fromDate
        toDate
      }
      surcharges{
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
    }
    relatedOrders {
      changeDate
      material {
       ai
       snr
       plant
      }
      internalIdentifier
      orderCurrency {
        currency
        currencyType
        displayFormat
      }
      orderNo
      orderState
      orderType
      quantityUnit
      supplierNo
      version
      workflowState
      pricingPeriods {
        internalIdentifier
        priceUnit
        basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
        category
        surchargeType
      }
     }
   }
  }
 }`;

export interface OrderHistoryQueryResponse {
  orders: Order[];
}

export interface OrderHistorySnrAiQueryResponse {
  ordersForMaterial: Order[];
}

export type OrderHistorySNRAIVariables = {
  snrList?: string[];
  plant?: Plant;
  ai?: number;
}

export type OrderHistoryVariables = {
  orderNo?: string;
  plant?: Plant;
}
