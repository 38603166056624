<bmw-ag-grid-theme>
  <ag-grid-angular data-testid="pricing-and-payment-grid" *ngIf="rowData"
                   style="height: 500px; overflow: scroll"
                   class="zebra"
                   [columnDefs]="runtimeCompilerData.columns"
                   [rowData]="rowData"
                   [pagination]="true"
                   [rowMultiSelectWithClick]="false"
                   [suppressRowClickSelection]="true"
                   [gridOptions]="runtimeCompilerData.gridOptions"
                   [defaultColDef]="runtimeCompilerData.defaultColDef"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRefreshGridData($event)"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (modelUpdated)="onModelUpdated($event)"
                   [groupDefaultExpanded]="0"
                   [animateRows]="true"
                   [processRowPostCreate]="this.processRowPostCreate"
  >
  </ag-grid-angular>
</bmw-ag-grid-theme>
