
import {ServiceBase} from '../../../services/service.base';
import {Apollo} from "apollo-angular";
import {Subscription} from "rxjs";
import {Plant} from "../../order/model/order.model";
import {Injectable} from "@angular/core";
import {
  ADD_CURRENCY,
  CURRENCIES_QUERY,
  CurrenciesQueryResponse,
} from "./currency.graphql";
import {ServiceBus} from "../../../services/service.bus";
import {ISOCurrency} from "../../exchange-rates/model/exchange-rates.model";
import {AuthService} from "../../../services/auth.service";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends ServiceBase {
  subscription: Subscription;
  _apollo: Apollo;

  constructor(apollo: Apollo,
              private bus: ServiceBus,
              private authService: AuthService) {
    super(apollo);
    this._apollo = apollo;
  }

  getForeignCurrencies() {
    const variable = {
      plant: SystemGlobalCache.plant()
    };

    this.subscription = this.query<CurrenciesQueryResponse>
    (variable, CURRENCIES_QUERY)
      .subscribe(({data}) => {
        this.bus.writeResult<CurrenciesQueryResponse>(data.currencies);
      },error => {
        this.bus.writeError(this.removeGraphQLErrorOnMessage(error.message));
      });
  }

  addCurrency(currencyInput: ISOCurrency) {
    const variable = {
      currency: currencyInput
    };
    return this.mutate(variable,ADD_CURRENCY);
  }

  isReleaseOfExchangeRate() {
    let permissions = ['T_Waehrung_Freigeber_BMW_MSF','T_Waehrung_Freigeber_BMW_NED'];
    return this.authService.checkPermission(permissions);
  }

  dispose(): void {
    this.subscription.unsubscribe();
  }

}
