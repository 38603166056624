import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridApi, ICellRendererParams} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY_STRING, isUndefinedOrEmpty} from "../../../../shared/functions/typescript.utils";
import moment from "moment";
import {Product, Type} from "../../../product/model/product.model";
import {Operation, RoyaltiesIndicator} from "../../model/operations.model";

const pleaseSelect = 'Please select';

@Component({
  selector: 'app-rule-grid',
  templateUrl: './rule-grid.component.html',
  styleUrls: ['./rule-grid.component.scss']
})
export class RuleGridComponent {

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  defaultColDef = {
    flex: 1,
    minWidth: 130,
    editable: true,
    resizable: true,
  };
  derivatives;
  map = new Map();
  saveDisabled = true;
  isRowSelected = false;
  rowClassRules;
  rowStyle;

  @Input() product: Product[];
  @Input() executionCountryCodes: Type[];
  @Input() operation: Operation;
  @Input() rowData: any = [];
  @Input() whenOperationIsUnderEvaluation: boolean;
  @Input() selectedDetail: any[];
  @Input() hasCapturerRole: boolean;

  @Output() back = new EventEmitter();
  @Output() save = new EventEmitter();

  columns = [
    {
      field: '#',
      headerName: '',
      width: 40,
      minWidth: 40,
      rowGroup: false,
      hide: false,
      editable: false,
      filter: false,
      checkboxSelection: true
    }, {
      field: 'derivativeCode',
      headerName: 'derivativeCode',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: this.cellEditorAllowedDerivatives.bind(this),
    }, {
      field: 'validFrom',
      headerName: "validFrom",
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'type.code',
      headerName: "typeCode",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: this.cellEditorAllowedTypes.bind(this),
    }, {
      field: 'specialEquipmentCode1',
      headerName: "specialEquipmentCode",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: this.cellEditorAllowedSpecialEquipment.bind(this),
    }, {
      field: 'specialEquipmentName1',
      headerName: "specialEquipmentDescription",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'specialEquipmentCode2',
      headerName: "specialEquipmentCode",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: this.cellEditorAllowedSpecialEquipment.bind(this),
    }, {
      field: 'specialEquipmentName2',
      headerName: "specialEquipmentDescription",
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      editable: false,
    }, {
      field: 'price.value',
      headerName: "rulePrice",
      sortable: true,
      filter: true,
      resizable: true,
      valueFormatter: this.moneyFormatter,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'includeRoyalties',
      headerName: this.translate.instant('includeRoyalties'),
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      editable: false,
    }, {
      field: 'royalties',
      headerName: this.translate.instant('royalties'),
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueGetter: this.royaltiesCalculation.bind(this),
      type: 'rightAligned',
      editable: false,
    }, {
      field: 'profit',
      headerName: this.translate.instant('profit'),
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueGetter: this.profitCalculation.bind(this),
      type: 'rightAligned',
      editable: false,
    }, {
      field: 'total',
      headerName: this.translate.instant('total'),
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueGetter: this.newTransferPriceCalculation.bind(this),
      type: 'rightAligned',
      editable: false,
    }, {
      field: 'previousRule',
      hide: true,
    }
  ];

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  loadDerivatives() {
    return this.product.map(p => p.derivative.code);
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);

    let defaultSortModel = [
      {colId: 'type.code', sort: 'asc'},
      {colId: 'specialEquipmentCode1', sort: 'asc'},
      {colId: 'specialEquipmentCode2', sort: 'asc'},
      {colId: 'validFrom', sort: 'desc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
  }

  constructor(private translate: TranslateService) {
  }

  getRowStyle = params => {
    if (params.node.data.previousRule) {
      return { 'pointer-events': 'none', opacity: '0.4' };
    } else if (this.whenOperationIsUnderEvaluation) {
      return { 'pointer-events': 'none'};
    }
  };

  onBack(){
    this.back.emit();
  }

  onSave(){
    let validationFailed = false;
    let data = [];
    let selectedMap = new Map();
    this.gridApi.forEachNode(row => {
      if (!row.data.previousRule) {
        if (row.data.specialEquipmentCode1 === row.data.specialEquipmentCode2 ||
          selectedMap.has(row.data.type.code + row.data.specialEquipmentCode1 + row.data.specialEquipmentCode2)) {
          validationFailed = true;
        }
        selectedMap.set(row.data.type.code + row.data.specialEquipmentCode1 + row.data.specialEquipmentCode2, row.data);
        selectedMap.set(row.data.type.code + row.data.specialEquipmentCode2 + row.data.specialEquipmentCode1, row.data);
      }
      if(row.data){
        row.setDataValue('royalties', this.gridApi.getValue('royalties', row));
        row.setDataValue('profit', this.gridApi.getValue('profit', row));
        row.setDataValue('total', this.gridApi.getValue('total', row));
      }
      data.push(row.data);
    });
    if (validationFailed) {
      this.save.emit(validationFailed);
    } else {
      this.saveDisabled = true;
      this.save.emit(data);
    }
  }

  addRow(){
    this.saveDisabled = true;
    this.gridApi.addItems([{
      internalIdentifier: null,
      derivativeCode:  pleaseSelect,
      type: {
        code:  pleaseSelect
      },
      specialEquipmentIdentifier1: null,
      specialEquipmentCode1: pleaseSelect,
      specialEquipmentName1: null,
      specialEquipmentIdentifier2: null,
      specialEquipmentCode2: pleaseSelect,
      specialEquipmentName2: null,
      price: {
        value: null
      },
      validFrom: this.operation.validFrom,
      includeRoyalties: null,
      previousRule: false
    }]);
  }

  removeRow() {
    this.saveDisabled = false;
    let selectedData = this.gridApi.getSelectedRows();
    this.gridApi.applyTransaction({ remove: selectedData });
  }

  onCellValueChanged(params) {
    let colId = params.column.getId();
    this.saveDisabled = false;
    if (colId === 'type.code' && params.data.type.code !== pleaseSelect) {
      params.node.setDataValue('specialEquipmentCode1', pleaseSelect);
      params.node.setDataValue('specialEquipmentCode2', pleaseSelect);
      params.node.setDataValue('specialEquipmentName1', null);
      params.node.setDataValue('specialEquipmentName2', null);
      params.node.setDataValue('includeRoyalties', this.determineRoyaltiesIndicatorOfType(params.data.type.code));
    } else if (colId === 'derivativeCode') {
      params.node.setDataValue('type.code', pleaseSelect);
    } else if (colId === 'specialEquipmentCode1' && params.data.specialEquipmentCode1 !== pleaseSelect) {
      let specialEquipmentName = this.specialEquipmentName(params.data.derivativeCode, params.data.type.code, params.data.specialEquipmentCode1);
      params.node.setDataValue('specialEquipmentName1', specialEquipmentName);
    } else if (colId === 'specialEquipmentCode2' && params.data.specialEquipmentCode2 !== pleaseSelect) {
      let specialEquipmentName = this.specialEquipmentName(params.data.derivativeCode, params.data.type.code, params.data.specialEquipmentCode2);
      params.node.setDataValue('specialEquipmentName2', specialEquipmentName);
    }
    if (params.data.derivativeCode && params.data.type?.code && params.data.specialEquipmentCode1 && params.data.specialEquipmentName1 &&
      params.data.specialEquipmentCode2 && params.data.specialEquipmentName2 && params.data.price?.value && params.data.validFrom) {
      this.saveDisabled = false;
    } else {
      this.saveDisabled = true;
    }
  }

  onRowSelected(): void {
    this.isRowSelected = this.gridApi.getSelectedRows().length >= 1;
  }

  typesForDerivativeMap = (derivativeCode) => {
    let types = this.product.filter(p => p.derivative.code === derivativeCode).map(p => p.derivative.types.map(t => t.code));
    return types[0].sort((a, b) => (a < b) ? -1 : 1);
  }

  specialEquipmentForTypeMap = (derivativeCode, typeCode) => {
    let types = this.product.filter(p => p.derivative.code === derivativeCode).map(p => p.derivative.types.filter(t => t.code === typeCode));
    let specialEquipments = types[0].map(t => t.specialEquipment.map(s => s.code));
    return specialEquipments[0].sort((a, b) => (a < b) ? -1 : 1);
  }

  specialEquipmentName = (derivativeCode, typeCode, specialEquipmentCode) => {
    let types = this.product.filter(p => p.derivative.code === derivativeCode).map(p => p.derivative.types.filter(t => t.code === typeCode));
    return types[0].map(t => t.specialEquipment.filter(s => s.code === specialEquipmentCode))[0].map(s => s.name);
  }

  cellEditorAllowedDerivatives(params) {
    return {
      values: this.product.map(p => p.derivative.code).sort((a, b) => (a < b) ? -1 : 1)
    };
  }

  cellEditorAllowedTypes(params) {
    return {
      values: this.typesForDerivativeMap(params.data.derivativeCode)
    };
  }

  cellEditorAllowedSpecialEquipment(params) {
    return {
      values: this.specialEquipmentForTypeMap(params.data.derivativeCode, params.data.type.code)
    };
  }

  private determineRoyaltiesIndicatorOfType(typeCode) {
    let includeRoyalties = RoyaltiesIndicator.NEVER;
    this.executionCountryCodes.forEach(type => {
      if (typeCode === type.code) {
        let specialEquipmentSize = type.specialEquipment.length;
        type.specialEquipment.forEach(specialEquipment => {
          if (specialEquipment.code === '08AA') {
            if (specialEquipmentSize === 1) {
              includeRoyalties = RoyaltiesIndicator.ALWAYS;
            } else {
              includeRoyalties = RoyaltiesIndicator.SOMETIMES;
            }
          }
        })
      }
    });
    return includeRoyalties;
  }

  royaltiesCalculation(params): number {
    let royaltiesTotal:number = 0;
    if(params.data){
      if (params.data['includeRoyalties'] === RoyaltiesIndicator.NEVER) {
        royaltiesTotal = 0;
      } else {
        royaltiesTotal = (Number(params.data.price.value) + Number(this.profitCalculation(params))) * 0.03;
      }
    }
    if (this.isNotNumeric(royaltiesTotal)) {
      royaltiesTotal = 0;
    }
    return Math.round((royaltiesTotal + Number.EPSILON) * 100) / 100;
  }

  profitCalculation(params): number {
    let profitTotal:number = 0;
    if(params.data){
      profitTotal = Number(params.data.price.value) * 0.05;
    }
    if (this.isNotNumeric(profitTotal)) {
      profitTotal = 0;
    }
    return Math.round((profitTotal + Number.EPSILON) * 100) / 100;
  }

  newTransferPriceCalculation(params): number {
    let total:number = 0;
    if(params.data){
      total = Number(params.data.price.value) + Number(this.profitCalculation(params)) + Number(this.royaltiesCalculation(params));
    }
    if (this.isNotNumeric(total)) {
      total = 0;
    }
    return Math.round((total + Number.EPSILON) * 100) / 100;
  }

  isNotNumeric(value: any): boolean {
    return isNaN(value - parseFloat(value));
  }
}
