import {ManufacturingCostHistory} from "../model/operations.model";
import {TypeSpecialEquipmentTransformer} from "../../product/service/type-special-equipment-transformer";

export class ManufacturingCostsHistoryTransformer {
  private typeSpecialEquipmentTransformer: TypeSpecialEquipmentTransformer;
  private typeSpecialEquipmentGrouping: any;

  constructor() {
    this.typeSpecialEquipmentTransformer = new TypeSpecialEquipmentTransformer();
    this.typeSpecialEquipmentGrouping = this.typeSpecialEquipmentTransformer.getTypeSpecialEquipmentGrouping();
  }

  transformManufacturingCostHistory(row: ManufacturingCostHistory) {
    return {
      group: !(row.specialEquipment) && !(row.packet) ? this.typeSpecialEquipmentGrouping.get('T').groupName : (row.specialEquipment) ? this.typeSpecialEquipmentGrouping.get(row.specialEquipment?.type).groupName : this.typeSpecialEquipmentGrouping.get("PA").groupName,
      groupOrder: !(row.specialEquipment) && !(row.packet) ? this.typeSpecialEquipmentGrouping.get('T').groupOrder : (row.specialEquipment) ? this.typeSpecialEquipmentGrouping.get(row.specialEquipment?.type).groupOrder : this.typeSpecialEquipmentGrouping.get("PA").groupOrder,
      internalIdentifier: row.internalIdentifier,
      derivativeCode: row.derivativeCode,
      type: row.type,
      specialEquipmentIdentifier: row.specialEquipment?.internalIdentifier,
      packetIdentifier: row.packet?.internalIdentifier,
      specialEquipmentName: (row.specialEquipment) ? row.specialEquipment.name : (row.packet) ? row.packet.name : "",
      specialEquipmentCode: (row.specialEquipment) ? row.specialEquipment.code : (row.packet) ? row.packet.code : "",
      price: {
        value: row.previousManufacturingCosts.reduce((sum, mfcCost) => sum + mfcCost.price?.value, row.price?.value),
        currency: row.price?.currency
      },
      transferPrice: row.transferPrice,
      validFrom: row.validFrom,
      releaseDate: row.releaseDate,
      operationId: row.operationId,
      operationName: row.operationName,
      previousManufacturingCosts: row.previousManufacturingCosts,
      status : row.manufacturingCostStatus
    };
  }
}
