import gql from "graphql-tag";
import {ManufacturingCostHistory} from "../model/operations.model";

export const ALL_MANUFACTURING_COSTS_BY_PLANT = gql`
  query manufacturingCostHistoryByPlant($plant: Plant!) {
    manufacturingCostHistoryByPlant(plant: $plant) {
      derivativeCode
      internalIdentifier
      price {
        value
      }
      previousManufacturingCosts {
        internalIdentifier
        price {
          value
        }
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
        productionType {
          productionTypeCode
          productionTypeImportStatus
        }
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
      packet {
        code
        generation
        internalIdentifier
        name
        sequence
      }
      releaseDate
      validFrom
      operationInternalIdentifier
      operationId
      operationName
      manufacturingCostStatus
    }
  }`;

export interface ManufacturingCostHistoryQueryResponse {
  manufacturingCostHistoryByPlant: ManufacturingCostHistory[];
}

export const ALL_STATUSES_MANUFACTURING_COSTS_BY_PLANT = gql`
  query allStatusesManufacturingCostHistoryByPlant($plant: Plant!) {
    allStatusesManufacturingCostHistoryByPlant(plant: $plant) {
      derivativeCode
      internalIdentifier
      price {
        value
      }
      previousManufacturingCosts {
        internalIdentifier
        price {
          value
        }
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
        productionType {
          productionTypeCode
          productionTypeImportStatus
        }
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
      packet {
        code
        generation
        internalIdentifier
        name
        sequence
      }
      releaseDate
      validFrom
      operationInternalIdentifier
      operationId
      operationName
      manufacturingCostStatus
    }
  }`;

export interface AllStatusesManufacturingCostHistoryQueryResponse {
  allStatusesManufacturingCostHistoryByPlant: ManufacturingCostHistory[];
}
