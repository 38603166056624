import {Directive, ElementRef, Input} from '@angular/core';
import {AuthService} from "../../services/auth.service";

@Directive({
  selector: '[isAuthorized]'
})
export class IsAuthorizedDirective {

  @Input('isAuthorized') permission: string[];

  constructor(private el: ElementRef, private authService: AuthService) { }

  ngOnInit() {
    this.authService.doneLoading().subscribe(loaded => {
      if (loaded) {
        if (!this.authService.checkPermission(this.permission)) {
          this.el.nativeElement.style.display = 'none';
        }
      }
    });
  }

}
