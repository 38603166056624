import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderSearchNotifierService {

  private subject = new Subject<any>();

  constructor() { }

  populateSearchData(name: string) {
    this.subject.next({tab: name});
  }

  onChange(): Observable<any> {
    return this.subject.asObservable();
  }
}
