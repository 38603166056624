<bmw-dialog
  [type]="'info'"
  [show]="this.suppliedColumns.showVehicleInformation"
  [title]="dialogTitle"
  [closeButton]="true"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="onInformationClose()"
  (closeClick)="onInformationClose()"
>
  <bmw-master-detail-list
    style="--list__item__col-size:60% 40%;">
    <bmw-master-detail-list-row>
      <bmw-master-detail-list-row-entry
        label="{{'typeCode'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{'menu.vehicle-status.information-code'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{'menu.vehicle-status.information-status'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>>
      <bmw-master-detail-list-row-entry
        label="{{'menu.vehicle-status.f2Date'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>>
    </bmw-master-detail-list-row>
    <bmw-master-detail-list-row
      *ngFor="let specialEquipment of this.suppliedColumns.specialEquipments">
      <bmw-master-detail-list-row-entry
        label="{{this.suppliedColumns.modelTypeCode}}"
        [bold]="highlight(specialEquipment.productStatus)"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{specialEquipment.specialEquipmentCode}}"
        [bold]="highlight(specialEquipment.productStatus)"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{specialEquipment.productStatus}}"
        [bold]="highlight(specialEquipment.productStatus)">
      </bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{this.suppliedColumns.createdDate}}"
        [bold]="highlight(specialEquipment.productStatus)">
      </bmw-master-detail-list-row-entry>>
    </bmw-master-detail-list-row>
  </bmw-master-detail-list>
</bmw-dialog>
<form [formGroup]="yearForm">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-6-tablet bmw__grid__cell--span-10 form-template-grid-start">
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-1-tablet bmw__grid__cell--span-1 form-template-grid-start">
      <bmw-combo-box formControlName="vehicleYear"
                     (changeEvent)="filterRecordsByYear($event)">
        <bmw-label>{{ 'component.search-bar.year' | translate }}</bmw-label>
        <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let year of years" value="{{year}}"
                                     label="{{year}}"></bmw-combo-box-dropdown-item>
      </bmw-combo-box>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-1-tablet bmw__grid__cell--span-1 form-template-grid-start">
      <bmw-combo-box formControlName="vehicleMonth"
                     (changeEvent)="filterRecordsByMonth($event)">
        <bmw-label>{{ 'month' | translate }}</bmw-label>
        <bmw-combo-box-dropdown-item class="iwp-icon-gen_info_ok" *ngFor="let month of months" value="{{month}}"
                                     label="{{month}}"></bmw-combo-box-dropdown-item>
      </bmw-combo-box>
    </div>
  </div>
<bmw-ag-grid-theme>
  <ag-grid-angular data-testid="rules-history-grid" *ngIf="rowData"
                   style="height: 500px; overflow: scroll"
                   class="zebra"
                   [columnDefs]="runtimeCompilerData.columns"
                   [rowData]="rowData"
                   [pagination]="true"
                   [rowMultiSelectWithClick]="false"
                   [rowSelection]="rowSelection"
                   [suppressRowClickSelection]="true"
                   [suppressMultiRangeSelection]="true"
                   [gridOptions]="runtimeCompilerData.gridOptions"
                   [defaultColDef]="runtimeCompilerData.defaultColDef"
                   [sideBar]="runtimeCompilerData.sideBar"
                   [statusBar]="runtimeCompilerData.statusBar"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRefreshGridData($event)"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (modelUpdated)="onModelUpdated($event)"
                   [frameworkComponents]="frameworkComponents"
                   (rowSelected)="onRowSelected()"
  >
  </ag-grid-angular>
</bmw-ag-grid-theme>
<div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
    <bmw-button button="secondary" (clickEvent)="onClearFilters()" class="bmw__margin-right__xs">
      {{ 'menu.vehicle-status.tabs.vehicle-status.refresh' | translate }}
    </bmw-button>
  </div>
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button data-testid="button-delete" *ngIf="hasReleaseVehicleRole" [disabled] ="!isRowSelectedForReCalculate" button="primary" (clickEvent)="onReCalculateVehicle()" class="bmw__margin-right__xs">
      {{ 'menu.vehicle-status.tabs.vehicle-status.recalculate' | translate }}
    </bmw-button>
  </div>
</div>
</form>
