<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.pricing.multi-supplier.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <bmw-content-tab>
        <bmw-content-tab-label
          for="a">{{ 'menu.pricing.multi-supplier.weight.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="a">
          <div class="table-wrapper bmw__margin-top__s">
            <form class="reversed">
              <div class="bmw__grid__inner">
                <div
                  class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-6 form-template-grid-start">
                  <p
                    class="bmw-medium-text bmw__margin-top__xs"> {{ 'menu.pricing.multi-supplier.weight.introduction' | translate }}</p>
                </div>
              </div>
              <app-material-search-bar (onSearch)="search($event)"
                                       (onClear)="clearFilters()">
              </app-material-search-bar>
            </form>

            <div>
              <bmw-ag-grid-theme>
                <div
                  *ngIf="formSubmitted && errorMessage"
                  class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
                  <bmw-inline-message type="error">
                    {{errorMessage}}
                  </bmw-inline-message>
                </div>

                <div *ngIf="formSubmitted && !errorMessage" class="bmw__margin-top__xs">
                  <div class="download-link">
                    <span>
                      <a href="#" class="bmw-regular-text ">{{'menu.pricing.multi-supplier.download' | translate}}</a>
                    </span>
                  </div>
                  <div class="bmw__margin-top__xs">
                    <app-weight-grid [rowData]="rowData" [groupBy]="groupByColumns"></app-weight-grid>
                  </div>
                </div>
              </bmw-ag-grid-theme>
            </div>
          </div>
        </bmw-content-tab-content>
      </bmw-content-tab>

    </div>
  </div>
</div>







