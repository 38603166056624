import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-bottom-save-bar',
  templateUrl: './bottom-save-bar.component.html',
  styleUrls: ['./bottom-save-bar.component.scss']
})
export class BottomSaveBarComponent implements OnInit {

  @Output() onEdit = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  edit(): void {
    this.onEdit.emit(true);
  }

}
