import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";


export class WeightGridColumns {

  constructor(private translate: TranslateService) { }

  setColumns(col : string[]): void {
    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        c.rowGroup = true;
      }
    });
  }

  columns = [
     {
       field: 'snr',
       headerName: 'SNR',
       width: 150,
       minWidth: 150,
       rowGroup: false,
       hide: true,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'ai',
       headerName: 'AI',
       width: 200,
       minWidth: 200,
       rowGroup: false,
       hide: true,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'orderNo',
       headerName: 'Order No',
       width: 200,
       minWidth: 200,
       rowGroup: false,
       hide: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'weightDate' ,
       headerName: 'WeightDate From',
       width: 200,
       minWidth: 200,
       valueFormatter: this.dateFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'weightValue' ,
       headerName: 'Value',
       width: 200,
       minWidth: 200,
       type: 'rightAligned',
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'weightType' ,
       headerName: 'Weight Type',
       width: 200,
       minWidth: 200,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'importDate' ,
       headerName: 'Import Date',
       width: 200,
       minWidth: 200,
       headerValueGetter: this.localizeHeader.bind(this)
     },
   ];

   localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (params.value === undefined ) {
      return '';
    }
    return moment(params.value).format('DD.MM.YYYY');
  }
}
