<bmw-ag-grid-theme>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="processing"></bmw-progress-circle>
    <div *ngIf="!new" class="bmw__margin-top__xs">
      <div *ngIf="errorMessage"
           class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
        <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
      </div>

      <div *ngIf="!errorMessage" class="bmw__margin-top__xs">
        <div *ngIf="warnMessage"
             class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
          <bmw-inline-message type="error">{{warnMessage}}</bmw-inline-message>
        </div>
        <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
          <bmw-inline-message type="info">{{message}}</bmw-inline-message>
        </div>

        <div>
          <app-cost-allocations-grid
            [rowData]="rowData"
            [selectedDetail]="selectedDetail"
            [map]="map"
            [whenOperationIsUnderEvaluation]="whenOperationIsUnderEvaluation"
            [hasCapturerRole]="hasCapturerRole"
            (back)="onBack(false)"
            (save)="onSave($event)"
            (upload) = "onUpload($event)"
          ></app-cost-allocations-grid>
        </div>

      </div>
    </div>
  </bmw-ag-grid-theme>
  <bmw-dialog
    [type]="'info'"
    [show]="showConfirm"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [secondaryLabel]="'Cancel'"
    [primaryDisabled]="false"
    [secondaryDisabled]="false"
    (secondaryClick)="showConfirm = false"
    (closeClick)="showConfirm = false">
    <p class="bmw-regular-text">{{ dialogText }}
    </p>
  </bmw-dialog>
<app-add-cost-attachment [showUpload]="showUploadDialog" [dialogTitle]="dialogTitle" [dialogText]="dialogText"
                         [selected]="selectedTypes" [uploadType]="uploadType"
                         (uploadAttachment)="refresh($event)" (cancelAttachment)="cancelUploadAttachment()"></app-add-cost-attachment>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Please wait..." [show]="processing"></bmw-progress-circle>
