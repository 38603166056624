import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class UsersOverviewGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: 'QNumber',
      headerName: 'qNumber',
      resizable: true,
      rowGroup: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'roleName',
      headerName: 'Role',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'plant',
      headerName: 'Plant',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'validity.fromDate',
      headerName: 'Valid From',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'validity.toDate',
      headerName: 'Valid To',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    }
  ];

}
