export const transferPrice = {

  group: {
    royalties: 'Royalties',
    royaltiesOnOptions: 'Royalties on Options',
    profitCarset: 'Profit Carset',
    profitOption: 'Profit Option (5%)'
  },

  category : {
    royaltiesForTechnology: 'Royalties for Technology',
    royaltiesForTrademark: 'Royalties for Trademark',
    profit: 'Profit (5%)',
    gwmParts: 'GWM Parts',
    bmwParts: 'BMW Parts',
    salParts: 'SAL Parts',
    thirdPartyParts: 'Third Party Parts',
    directLabour: 'Direct labour',
    inboundLogistics: 'Inbound Logistics',
    warranty: 'Warranty',
    importDuties: 'Import Duties',
    customsClearanceFee: 'Customs clearance fee',
    fuelAndEnergy: 'Fuel and energy',
    variableManufacturingExpenses: 'Variable manufacturing expenses',
    outboundLogistic: 'Outbound Logistic',
    nonDeductibleIndirectTaxesDirectlyLinkedToProject: 'Non-deductible indirect taxes - directly linked to project',
    nonDeductibleIndirectTaxesNotDirectlyLinkedToProject: 'Non-deductible indirect taxes - not directly linked to project',
    depreciation: 'Depreciation',
    indirectLabour: 'Indirect labour',
    otherFixedExpenses: 'Other fixed expenses',
    yearlyProductionCostReduction: 'Yearly production cost reduction'
  },

  changeType : {
    changeOnBehalfOfBMW: 'Change on behalf of BMW',
    changeOnBehalfOfSAL: 'Change on behalf of SAL'
  },

  adjustmentReason : {
    techChange: 'Technical Change Request BMW',
    premises: 'Premises',
    volumeChange: 'Volume Changes',
    bmwOther: 'Other',
    fivePercent: '5% Margin Requirements SAL',
    effect: 'SAL Effects with BMW Impact',
    salOther: 'Other'
  },

  changeTypeForAdjustmentReason : {
    techChange: 'Change on behalf of BMW',
    premises: 'Change on behalf of BMW',
    volumeChange: 'Change on behalf of BMW',
    bmwOther: 'Change on behalf of BMW',
    fivePercent: 'Change on behalf of SAL',
    effect: 'Change on behalf of SAL',
    salOther: 'Change on behalf of SAL'
  },

  calculation : {
    profit: 'Profit',
    royalties: 'Royalties'
  }
}
