import gql from "graphql-tag";
import {Product, Type} from "../model/product.model";

export const START_DATA_IMPORT = gql`
  mutation ($plant: Plant!) {
    importProducts(plant: $plant)
  }
`;

export const SAVE_PRODUCT_CONFIG = gql`
  mutation ($productConfiguration: ProductConfigurationChangeInputInput!) {
    saveProductConfiguration(productConfiguration: $productConfiguration)
  }
`;
