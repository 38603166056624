import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "ag-grid-enterprise";

if (environment.production) {
  enableProdMode();
}
//set ag-grid key
LicenseManager.setLicenseKey("CompanyName=BMW (South Africa) (Pty) Ltd,LicensedApplication=BMW ZA Hub,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=2,AssetReference=AG-010146,ExpiryDate=16_March_2022_[v2]_MTY0NzM4ODgwMDAwMA==2e00d0b78502ac3e0fba7fb4e4f31919");
//boostrap the app
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
