import {HostListener, Input, OnInit} from "@angular/core";
import {GridApi, GridOptions} from "ag-grid-community";
import {GridColumnDefinition} from "./shared/grid.column.definition";

export class AgGridBase<T extends GridColumnDefinition> implements OnInit {

  @Input() showSideBar: boolean = true;
  @Input() rowData: any = [];

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  domLayout: any;
  showNoData:boolean = true;
  suppliedColumns: T;

  constructor(columns: T) {
    columns.translate.onLangChange.subscribe(() => {
      this.gridApi?.refreshHeader();
      this.gridApi?.refreshToolPanel();
    });
    this.suppliedColumns = columns;
  }

  ngOnInit(): void {
    this.setGridOptions();
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.formatGridApi();
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.formatGridApi();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.runtimeCompilerData.gridOptions.api?.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });

    let defaultSortModel = [
      {colId: 'internalIdentifier', sort: 'desc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
  }

  onRefreshGridData($event: any) {
    if ( this.gridApi != undefined ) {
      this.gridApi.expandAll();
      this.formatGridApi();
      //sort here
      setTimeout(() => {
        this.gridApi.redrawRows();
        this.gridApi.sizeColumnsToFit();
      }, 500);
    }
  }

  formatGridApi() {
    this.gridApi?.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi?.sizeColumnsToFit();
    this.gridApi?.resetRowHeights();
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0) {
      if (this.gridApi.getDisplayedRowCount() == 0) {
        this.gridApi.showNoRowsOverlay();
      } else if (!this.filtering) {
        this.gridApi.showLoadingOverlay();
      }
    }
    
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }

    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 500);
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }

  setGridOptions() {
    this.domLayout = 'autoHeight';
    this.runtimeCompilerData = {
      columns: this.suppliedColumns?.columns,
      gridOptions: <GridOptions> {
        getRowStyle: params => {
          return { order: params.node.rowIndex };
        },
        postSort: params => {
          //it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'multiple',
        suppressRowDeselection: true,
        rowHeight: 50,
        headerHeight: 50,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      },
      defaultColDef: {
        flex: 1,
        enableValue: true,
        cellClass: 'cell-wrap-text',
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 230,
        pinned: 'left'
      }
    };
  }
}
