import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";
import {EMPTY_STRING, isUndefinedOrEmpty} from "../../../../shared/functions/typescript.utils";

export class OperationDetailsGridColumns extends GridColumnBase implements GridColumnDefinition {
  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: '#',
      headerName: '',
      width: 40,
      minWidth: 40,
      rowGroup: false,
      hide: false,
      editable: false,
      filter: false,
      checkboxSelection: true
    }, {
      field: 'derivate',
      headerName: 'ops.details.grid.derivate',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'prodType',
      headerName: 'ops.details.grid.prodType',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'operationId',
      headerName: 'operationId',
      resizable: true,
      rowGroup: false,
      hide: true,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'saType',
      headerName: 'ops.details.grid.saType',
      resizable: true,
      rowGroup: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'type',
      headerName: 'ops.details.grid.type',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'saCode',
      headerName: 'ops.details.grid.saCode',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'designation',
      headerName: 'ops.details.grid.designation',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'includeRoyalties',
      headerName: 'ops.details.grid.includeRoyalties',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'fkOld',
      valueFormatter: this.moneyFormatter,
      headerName: 'ops.details.grid.fkOld',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'delta',
      valueFormatter: this.moneyFormatter,
      headerName: 'ops.details.grid.delta',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: true,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'fkNew',
      valueFormatter: this.moneyFormatter,
      headerName: 'ops.details.grid.fkNew',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      cellStyle: {textAlign: 'right'},
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }, {
      field: 'sop',
      headerName: 'ops.details.grid.sop',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellRenderer: 'dateFormatterComponent',
    }, {
      field: 'eop',
      headerName: 'ops.details.grid.eop',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
      cellRenderer: 'dateFormatterComponent',
    }, {
      field: 'priceId',
      headerName: 'ops.details.grid.priceId',
      resizable: true,
      checkboxSelection: true,
      rowGroup: false,
      hide: true,
      editable: false,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this)
    }
  ];

  public moneyFormatter(params): string {
    if (isUndefinedOrEmpty(params.value))
      return EMPTY_STRING;

    let value: string = EMPTY_STRING;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }
}
