import { Component, Input,  Output, EventEmitter, OnInit, HostListener, ComponentFactoryResolver } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { GridApi, GridOptions } from 'ag-grid-community';
import { NumericEditorComponent } from '../../../numeric-editor/NumericEditorComponent';
import { Plant } from 'src/app/components/order/model/order.model';
import {AgGridBase} from "../../../../../ag.grid.base";
import {CostAllocationsSaGridColumns} from "./cost-allocations-sa-grid.columns";
import {RoyaltiesIndicator} from "../../../model/operations.model";

@Component({
  selector: 'app-cost-allocations-sa-grid',
  templateUrl: './cost-allocations-sa-grid.component.html',
  styleUrls: ['./cost-allocations-sa-grid.component.scss']
})
export class CostAllocationsSaGridComponent extends AgGridBase<CostAllocationsSaGridColumns> implements OnInit {
  rowSelection = 'single';
  @Input() rowData: any;
  @Input() whenOperationIsUnderEvaluation: boolean;
  @Input() hasCapturerRole: boolean;
  @Input() selectedDetail: any[];
  @Input() ids: any[];
  @Output() back = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() upload = new EventEmitter();
  showSideBar: boolean = false;
  selectedDetailShadow: any[];
  showGrid:boolean = true; //Not currently used?
  new = false;
  selection: any;
  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  domLayout: any;
  showNoData:boolean = false; //Not currently used?
  showUnallocatedCosts: boolean = false;
  payloadObject = {};



  constructor(private translate: TranslateService) {
    super(new CostAllocationsSaGridColumns(translate));
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
    });
  }

  ngOnInit(): void {
    this.gridColumns = this.suppliedColumns.columns;
    this.setGridOptions();

    if(!this.selectedDetail || this.selectedDetail.length === 0){
      this.new = true;
    } else {
      this.selection = this.selectedDetail[0];
      this.selectedDetailShadow = [...this.selectedDetail];
    }
  }

  cancelUnallocatedCosts() {
    this.showUnallocatedCosts = false;
  }

  onGridReady(params){
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.runtimeCompilerData.gridOptions.api?.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
    const sort = [
      {
        colId: "derivative",
        sort: "asc",
      },
      {
        colId: "desigtype",
        sort: "asc",
      },
      {
        colId: "saCode",
        sort: "asc",
      },

    ];
    this.gridApi.setSortModel(sort);
  }

  setGridOptions(){
    this.domLayout = 'autoHeight';
    this.runtimeCompilerData = {
      columns: this.gridColumns,
      gridOptions: <GridOptions>{
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping',
        pagination: true,
      },
      defaultColDef: {
        flex: 1,
        enableValue: true,
        cellClass: 'cell-wrap-text',
        enableRowGroup: true,
        enablePivot: true,
        sortable: true,
        autoHeight: true,
        resizable: true,
        filter: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      },
      frameworkComponents:  {
        numericEditorComponent: NumericEditorComponent
      }

    };
  }

  onBack(){
    this.back.emit();
  }

  onGetConfirmation() {
    let outputRows: any[] = [];
    this.gridApi.forEachNode(row => {
      let total: number = 0;
      let profitTotal: number = 0;
      let royaltiesTotal: number = 0;
      if (row.data) {
        let mapkey = row.data.priceId;
        let rowObj = Object.assign({}, row.data);
        let technicalChange = row.data['techChange'];
        let bmwOther = row.data['bmwOther'];
        let effect = row.data['effect'];
        let fivePercent = row.data['fivePercent'];
        let premises = row.data['premises'];
        let salOther = row.data['salOther'];
        let volumeChange = row.data['volumeChange'];
        let transferPriceOld = row.data['tpOld'];
        profitTotal = this.profitCalculation(row);
        royaltiesTotal = this.royaltiesCalculation(row);
        total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange + profitTotal + royaltiesTotal;
        rowObj['royalties'] = royaltiesTotal;
        rowObj['profit'] = profitTotal;
        rowObj['deltaPrice'] = total;
        rowObj['valueChanged'] = row.data.valueChanged;
        outputRows.push(rowObj);
      }
    });

    this.payloadObject['ids'] = [...this.ids];
    this.payloadObject['rows'] = [...outputRows];
    this.payloadObject['price'] = 0;
    this.payloadObject["opsId"] = this.selectedDetailShadow[0].operationId;
    this.payloadObject["plant"] = Plant[window.sessionStorage.client];

    if (this.isAllocationCostsCaptured(outputRows)) {
      this.save.emit(this.payloadObject);
    }
    else {
      this.showUnallocatedCosts = true;
    }
  }

  profitCalculation(params): number {
    let total: number = 0;
    let profitTotal: number = 0;
    if (params.data) {
      if (params.data['saCode'] !== '08AA') {
        let technicalChange = params.data['techChange'];
        let bmwOther = params.data['bmwOther'];
        let effect = params.data['effect'];
        let fivePercent = params.data['fivePercent'];
        let premises = params.data['premises'];
        let salOther = params.data['salOther'];
        let volumeChange = params.data['volumeChange'];
        total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange;
        profitTotal = total * 0.05;
      } else {
        profitTotal = +params.data['profit'];
      }
    }
    return profitTotal;
  }

  royaltiesCalculation(params): number {
    let total: number = 0;
    let royaltiesTotal: number = 0;
    if (params.data) {
      if (params.data['saCode'] !== '08AA') {
        let technicalChange = params.data['techChange'];
        let bmwOther = params.data['bmwOther'];
        let effect = params.data['effect'];
        let fivePercent = params.data['fivePercent'];
        let premises = params.data['premises'];
        let salOther = params.data['salOther'];
        let volumeChange = params.data['volumeChange'];
        total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange;
        if (params.data.includeRoyalties === RoyaltiesIndicator.NEVER) {
          if (params.data.royalties !== 0) {
            royaltiesTotal = params.data.royalties;
          } else {
            royaltiesTotal = 0;
          }
        } else {
          royaltiesTotal = (total + this.profitCalculation(params)) * 0.03;
        }
      } else if (params.data.saCode === '08AA' && params.data.includeRoyalties === RoyaltiesIndicator.SOMETIMES) {
        royaltiesTotal = params.data.royalties;
      } else {
        royaltiesTotal = +params.data['royalties'];
      }
    }
    return royaltiesTotal;
  }

  onUpload() {
    let outputRows: any[] = [];
    this.gridApi.forEachNode(row => {
      if (row.data) {
        let mapkey = row.data.priceId;
        let rowObj = Object.assign({}, row.data);
        rowObj['royalties'] = this.suppliedColumns.royaltiesMap.get(mapkey);
        rowObj['profit'] = this.suppliedColumns.profitMap.get(mapkey);
        rowObj['deltaPrice'] = this.suppliedColumns.deltaPriceMap.get(mapkey);
        rowObj['valueChanged'] = this.suppliedColumns.valueChanged.get(mapkey);
        outputRows.push(rowObj);
      }
    });

    this.payloadObject['ids'] = [...this.ids];
    this.payloadObject['rows'] = [...outputRows];
    this.payloadObject['price'] = 0;
    this.payloadObject["opsId"] = this.selectedDetailShadow[0].operationId;
    this.payloadObject["plant"] = Plant[window.sessionStorage.client];
    this.upload.emit(this.payloadObject); // take in what's on screen
  }

  saveWithDefaultValues() {
    this.cancelUnallocatedCosts();
    this.save.emit(this.payloadObject);
  }

  isAllocationCostsCaptured(rows: any[]): boolean {
    let returnValue: boolean = true;
    rows.forEach(row => {
      if ((row['valueChanged'] === undefined || row['valueChanged'] === false) && (row['royalties'] === 0 && row['profit'] === 0 && row['tpNew'] === 0)) {
        returnValue = false;
      }
    });
    return returnValue;
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
  }

  onRefreshGridData($event: any) {
    if ( this.gridApi != undefined ) {
      this.gridApi.expandAll();
      this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.resetRowHeights();
      setTimeout(() => {
        this.gridApi.redrawRows();
        this.gridApi.sizeColumnsToFit();
      }, 500);
    }
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }

    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 500);
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }
}
