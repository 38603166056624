<div class="bmw__margin-top__m"></div>
<bmw-fieldset opened="true">
  <bmw-fieldset-header>{{ 'menu.pricing.order-management.current.current-order' | translate }}
    - {{originalOrder.orderNo}}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <app-order-detail
      [baseOrder]="originalOrder"
      [rowData]="rowData">
    </app-order-detail>
  </bmw-fieldset-container>
</bmw-fieldset>
<hr class="divider-line">
<div class="bmw__margin-top__xl"></div>
<bmw-fieldset opened="false">
  <bmw-fieldset-header>{{ 'menu.pricing.order-management.current.pending-approval' | translate }}
    - {{pendingOrderHeader.orderNo}}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <app-order-detail
      [baseOrder]="pendingOrderHeader"
      [rowData]="pendingOrders">
    </app-order-detail>
  </bmw-fieldset-container>
</bmw-fieldset>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..." [show]="loadingScreen"></bmw-progress-circle>
<div
  class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div
    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
    <bmw-button button="secondary" type="submit" (clickEvent)="cancel()">
      {{ 'component.bottom-bar.cancel' | translate }}
    </bmw-button>
  </div>
  <div
    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right" [hidden]="hasNoOrderManagementRole">
    <bmw-button button="primary" type="submit" (clickEvent)="onReject()"
                style="padding: 5px;">
      {{ 'component.bottom-bar.reject' | translate }}
    </bmw-button>
    <bmw-button button="primary" type="submit" (clickEvent)="onApprove()">
      {{ 'component.bottom-bar.approve' | translate }}
    </bmw-button>
  </div>
</div>

