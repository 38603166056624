<bmw-ag-grid-theme>
  <div *ngIf="!showAllocate" class="bmw__margin-top__xs">
    <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">{{message}}</bmw-inline-message>
    </div>
    <bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..." [show]="loadingScreen"></bmw-progress-circle>
    <div><br/></div>
    <div class="bmw__margin-top__xs" *ngIf="shouldShowGrid">
              <app-operation-type-sa-grid
                [rowData]="rowData"
                (takeOver) = "onTakeOver($event)"
                (cancel)="onCancel()"
              ></app-operation-type-sa-grid>
            </div>
  </div>
</bmw-ag-grid-theme>
