import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";
import {TranslateService} from "@ngx-translate/core";
import {Operation} from "../../model/operations.model";

export class PricingAndPaymentColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;
  params: any;
  dialogTitle: String;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
    this.params = {
      rowData: new Operation(),
      $event: {}
    };
  }

  columns = [
    {
      field: 'derivative',
      headerName: 'derivative',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'typeCode',
      headerName: 'typeCode',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'description',
      headerName: 'description',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'vinNumber',
      headerName: 'vinNumber',
      sortable: true,
      filter: true,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'f2Date',
      headerName: 'f2Date',
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'fnDate',
      headerName: 'fnDate',
      sortable: true,
      filter: true,
      valueFormatter: this.dateFormatter,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }, {
      field: 'price',
      headerName: 'price',
      sortable: true,
      filter: true,
      valueFormatter: this.dateTimeFormatter,
      resizable: true,
      headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    }
  ];

}
