import {Component} from "@angular/core";
import {AgGridBase} from "../../../../ag.grid.base";
import {ManufacturingCostsGridColumns} from "./manufacturing-costs-grid.columns";
import {TranslateService} from "@ngx-translate/core";
import {OperationService} from "../../service/operation.service";
import {GridApi} from "ag-grid-community";

@Component({
  selector: 'app-manufacturing-costs-grid',
  templateUrl: './manufacturing-costs-grid.component.html',
  styleUrls: ['./manufacturing-costs-grid.component.scss']
})
export class ManufacturingCostsGridComponent extends AgGridBase<ManufacturingCostsGridColumns> {

  gridApi: GridApi;
  filtering = false;
  runtimeCompilerData: any;

  constructor(private translate: TranslateService, private operationService: OperationService) {
    super(new ManufacturingCostsGridColumns(translate));
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'type.code', sort: 'asc'},
      {colId: 'specialEquipmentCode', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onClearFilters(){
    this.gridApi.setFilterModel(null);
  }

  onExcelDownload() {
    this.runtimeCompilerData.gridOptions.api.exportDataAsExcel();
  }

  getRowStyle = params => {
    if (params.node?.data?.status === 'ACTIVE' && !params.node?.group) {
      return { 'pointer-events': 'none'};
    } else if (!params.node?.group) {
      return { 'pointer-events': 'none', opacity: '0.4' };
    }
  }
}
