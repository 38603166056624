<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.pricing.freeze-prices.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab id="mainTab" (selectionChange)="moveToSummaryOverview($event)">
          <bmw-content-tab-label for="a">{{ 'menu.pricing.freeze-prices.overview.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="a">
            <app-freeze-price-overview></app-freeze-price-overview>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="b">{{ 'menu.pricing.freeze-prices.search.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="b">
            <app-freeze-price-main-component></app-freeze-price-main-component>
          </bmw-content-tab-content>
          <div [hidden]="true">
            <bmw-content-tab-label for="c">{{ 'menu.pricing.freeze-prices.search-with-date.title' | translate }}</bmw-content-tab-label>
            <bmw-content-tab-content id="c">
              <app-freeze-price-date-search></app-freeze-price-date-search>
            </bmw-content-tab-content>
          </div>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
