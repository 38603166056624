import {Component} from '@angular/core';
import {WeightService} from "./service/weight.service";
import {MaterialSearchRequest} from "./material-search-bar/model/MaterialSearchRequest";

@Component({
  selector: 'weightings-component',
  templateUrl: './weightings.component.html',
  styleUrls: ['./weightings.component.scss'],

})
export class WeightingsComponent {

  loading: boolean = false;
  errorMessage: string;
  formSubmitted = false;
  rowData: any;
  groupByColumns: string[];

  constructor(private weightService: WeightService) {
    this.groupByColumns = ["snr","ai"];
  }

  search(request: MaterialSearchRequest) {
    this.loading = true;
    this.formSubmitted = true;
    this.errorMessage = null;

    if (request.isError) {
      this.errorMessage = request.error;
    } else {
      this.weightService.weightBySnrAi(request.ai, request.snr)
        .valueChanges
        .subscribe(({data, loading}) => {
          this.rowData = this.weightService.mapWeightings(data.weightForMaterial);
        }, (error) => {
          this.errorMessage = this.weightService.removeGraphQLErrorOnMessage(error.message);
        });
    }
  }

  clearFilters() {
    this.loading = false;
    this.formSubmitted = false;
    this.errorMessage = null;
    this.rowData = [];
  }
}
