import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY_STRING, isUndefinedOrEmpty} from "./functions/typescript.utils";

export class GridColumnBase {

  translate: TranslateService;

  protected constructor(translate: TranslateService) {
    this.translate = translate;
  }

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  hideZeroValues(params){
    if (params.value === 0 ) {
      return EMPTY_STRING;
    }
    return params.value;
  }

  dateTimeFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY HH:mm');
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }
}
