<div class="navigation-menu-wrapper">
  <bmw-navigation-menu (itemSelectedEvent)="goToPage($event)">
    <bmw-navigation-menu-item [isAuthorized]="['T_AV_Job_Controller_BMW_NED','T_AV_Job_Controller_BMW_MSF']"
                              [disabled]="true" icon="iwp-icon-iwp_dashboard"
                              label="{{ 'menu.work-list.title' | translate }}"
                              id="form_template"></bmw-navigation-menu-item>

    <bmw-navigation-menu-item
      [isAuthorized]="['T_SNR_Read_BMW_NED','T_SNR_Read_BMW_MSF','T_SNR_Read_NED','T_SNR_Read_MSF']" [disabled]="true"
      icon="iwp-icon-pluto_material_price_premisses"
      label="{{ 'menu.material.title' | translate }}" id="materialspectrum"></bmw-navigation-menu-item>

    <bmw-navigation-menu-item
      [isAuthorized]="['T_Freeze_Read_BMW_MSF','T_Freeze_Read_BMW_NED','T_Freeze_Read_NED','T_Freeze_Read_MSF','T_VP_Read_SAL']"
      icon="iwp-icon-pms_pcheck" label="{{ 'menu.pricing.title' | translate }}">
      <bmw-navigation-menu-submenu>
        <bmw-navigation-menu-submenu-item *ngIf="!isSALorGWM" [selected]="path === '/order-management'"
                                          id="order_management">{{ 'menu.pricing.order-management.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item
          [isAuthorized]="['T_Waehrung_Read_NED','T_Waehrung_Read_MSF','T_Waehrung_Read_SAL','T_Waehrung_Read_GWM','T_Waehrung_Read_BMW_NED','T_Waehrung_Read_BMW_MSF','T_Waehrung_Read_BMW_SAL']"
          [selected]="path === '/exchange-rates-management'"
          id="exchange_rates">{{ 'menu.pricing.exchange-rates.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item *ngIf="!isSALorGWM" [selected]="path === '/weightings'"
                                          id="weightings">{{ 'menu.pricing.multi-supplier.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item *ngIf="!isSALorGWM" [selected]="path === '/continuous-price'"
                                          id="continuous_price">{{ 'menu.pricing.continuous-prices.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item *ngIf="!isSALorGWM"
                                          [isAuthorized]="['T_ManPreise_Read_BMW_NED','T_ManPreise_Read_BMW_MSF','T_ManPreise_Read_NED','T_ManPreise_Read_MSF']"
                                          [selected]="path === '/manual-prices'"
                                          id="manual_prices">{{ 'menu.pricing.manual-prices.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item *ngIf="!isSALorGWM"
                                          [isAuthorized]="['T_Freeze_Read_BMW_MSF','T_Freeze_Read_BMW_NED','T_Freeze_Read_NED','T_Freeze_Read_MSF']"
                                          [selected]="path === '/freeze-prices'"
                                          id="freeze_prices">{{ 'menu.pricing.freeze-prices.title' | translate }}</bmw-navigation-menu-submenu-item>
      </bmw-navigation-menu-submenu>
    </bmw-navigation-menu-item>

    <bmw-navigation-menu-item id="manufacturing" icon="iwp-icon-fls_part_list"
                              label="{{ 'menu.manufacturing-cost.title' | translate }}"
                              *ngIf="isSALorGWM" [isAuthorized]="['T_VP_Read_SAL','T_VP_Read_GWM']"
                              [selected]="path === '/manufacturing'">
    </bmw-navigation-menu-item>

    <bmw-navigation-menu-item [disabled]="isDisabledInProd" *ngIf="!isSALorGWM" icon="iwp-icon-fls_part_list"
                              label="{{ 'menu.budget.title' | translate }}"
                              [isAuthorized]="['T_BudgetPlan_Read_BMW_MSF','T_BudgetPlan_Read_BMW_NED']">
      <bmw-navigation-menu-submenu>
        <bmw-navigation-menu-submenu-item [selected]="path === '/prod-master-data-ned-msf'"
                                          [isAuthorized]="['T_PSDH_Read_BMW_MSF','T_PSDH_Read_BMW_NED']"
                                          id="prod_master_data_msf_ned">{{ 'menu.budget.prod-master-data.title' | translate }}
        </bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item [selected]="path === '/budget-planning'"
                                          [isAuthorized]="['T_FK_Read_BMW_MSF','T_FK_Read_BMW_NED','T_FK_Read_MSF','T_FK_Read_NED']"
                                          id="budget_planning">{{ 'menu.budget.manufacturing-cost.title' | translate }}
        </bmw-navigation-menu-submenu-item>
      </bmw-navigation-menu-submenu>
    </bmw-navigation-menu-item>

    <bmw-navigation-menu-item [isAuthorized]="['T_VP_Read_SAL','T_VP_Read_GWM']"
                              label="{{ 'menu.reports.title' | translate }}"
                              icon="iwp-icon-gen_report_datacheck">

      <bmw-navigation-menu-submenu>
        <bmw-navigation-menu-submenu-item id="manufacturing_reports"
                                  *ngIf="isSALorGWM" [isAuthorized]="['T_VP_Read_SAL','T_VP_Read_GWM']"
                                  [selected]="path === '/manufacturing-reports'">
          {{ 'menu.budget.manufacturing-reports.title' | translate }}
        </bmw-navigation-menu-submenu-item>

        <bmw-navigation-menu-submenu-item id="quicksight_reports"
                                          *ngIf="isSALorGWM" [isAuthorized]="['T_VP_Read_SAL','T_VP_Read_BMW_SAL','T_VP_Read_GWM']"
                                          [selected]="path === '/quicksight-reports'">
          {{ 'menu.quicksight-reports.title' | translate }}
        </bmw-navigation-menu-submenu-item>
      </bmw-navigation-menu-submenu>
    </bmw-navigation-menu-item>

    <bmw-navigation-menu-item
      *ngIf="isSALorGWM" [isAuthorized]="['T_PSDH_Read_BMW_MSF','T_PSDH_Read_BMW_NED','T_PSDH_Read_BMW_SAL','T_PSDH_Read_GWM']"
      icon="iwp-icon-pms_set_z_price_sel" label="{{ 'menu.budget.prod-master-data.title' | translate }}"
      [selected]="path === '/prod-master-data'" id="prod_master_data">
    </bmw-navigation-menu-item>

    <bmw-navigation-menu-item id="vehicle" *ngIf="isSALorGWM"
                              [isAuthorized]="['T_FreigProdstueli_Read_BMW_SAL','T_FreigProdstueli_Read_GWM']"
                              icon="iwp-icon-light_lastfalldefinition"
                              label="{{ 'menu.vehicle-status.title' | translate }}"
                              [selected]="path === '/vehicle'"></bmw-navigation-menu-item>

    <bmw-navigation-menu-item *ngIf="!isSALorGWM"
                              [isAuthorized]="['T_MatBil_Read_BMW_NED','T_MatBil_Read_BMW_MSF','T_MatBil_Read_NED','T_MatBil_Read_MSF']"
                              [disabled]="true" icon="iwp-icon-gen_aggregation"
                              label="{{ 'menu.material-balance.title' | translate }}"
                              [selected]="path === '/home'"></bmw-navigation-menu-item>
    <bmw-navigation-menu-item id="aggregation" [disabled]="isDisabledInProd" *ngIf="!isSALorGWM"
                              [isAuthorized]="['T_Nachkalk_Read_BMW_NED','T_Nachkalk_Read_BMW_MSF','T_Nachkalk_Read_NED','T_Nachkalk_Read_MSF']"
                              icon="iwp-icon-pluto_psp_calendarization"
                              label="{{ 'menu.period-end-closing.title' | translate }}"
                              [selected]="path === '/period-end-closing'"></bmw-navigation-menu-item>

    <bmw-navigation-menu-item *ngIf="!isSALorGWM" [isAuthorized]="['T_Admin_IT_BMW']" [disabled]="true"
                              icon="iwp-icon-mwp_admin" label="{{ 'menu.administration.title' | translate }}">
      <bmw-navigation-menu-submenu>
        <bmw-navigation-menu-submenu-item [selected]="path === '/user-management'"
                                          id="user_management">{{ 'menu.administration.roles.title' | translate }}</bmw-navigation-menu-submenu-item>
        <bmw-navigation-menu-submenu-item
          [disabled]="true">{{ 'menu.administration.settings.title' | translate }}</bmw-navigation-menu-submenu-item>
      </bmw-navigation-menu-submenu>
    </bmw-navigation-menu-item>
  </bmw-navigation-menu>

</div>
