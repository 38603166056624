import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CustomMissingTranslationHandler} from './components/translate-component/custom-missing-translation-handler';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AgGridModule} from 'ag-grid-angular';
import {AppBmwModule} from "./app-bmw.module";
import 'ag-grid-enterprise';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgSelectModule} from '@ng-select/ng-select';
import {OrderService} from "./components/order/service/order.service";
import {GraphQLModule} from './graphql.module';
import {FaasComponents} from "./app-faas.components";
import {CookieService} from "ngx-cookie-service";
import {CommonModule} from "@angular/common";
import {ClickOutsideDirective, ListFilterPipe} from "./components/faas-combo-box";
import {PermissionSystemDirective} from "./shared/directives/permission.system.directive";
import {AuthGuard} from "./shared/guard/auth.guard";
import {RoleGuard} from "./shared/guard/role.guard";
import { IsAuthorizedDirective } from './shared/directives/is-authorized.directive';
import { AistringPipe } from './shared/pipes/aistring.pipe';
import {OperationInformationCellRenderer} from "./components/manufacturing/operations-display/operations-display-grid/operation-information-cell-renderer.component";
import { MoneyFormatterPipe } from './shared/pipes/money-formatter.pipe';
import {ContextMenuModule} from "@bmw-ds/components";
import { RuleComponent } from './components/manufacturing/rule/rule.component';
import { RuleGridComponent } from './components/manufacturing/rule/rule-grid/rule-grid.component';
import { MatBadgeModule } from '@angular/material/badge';
import {InformationCellRenderer} from './components/manufacturing/manufacturing-costs-actioned/manufacturing-costs-actioned-grid/information-cell-renderer.component';
import {SharedModule} from "./shared/shared.module";
import {VehicleInformationCellRenderer} from "./components/vehicle/vehicle-status/vehicle-status-grid/vehicle-information-cell-renderer.component";
import {OAuthModule} from "angular-oauth2-oidc";
import {HeadersInterceptor} from "./headers.interceptor";


export function createTranslateLoader(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/languages/', '.json');
}

@NgModule({
    declarations: [FaasComponents, ClickOutsideDirective, ListFilterPipe, PermissionSystemDirective, IsAuthorizedDirective, AistringPipe, MoneyFormatterPipe, RuleComponent, RuleGridComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AgGridModule.withComponents([OperationInformationCellRenderer, InformationCellRenderer, VehicleInformationCellRenderer]),
        MatBadgeModule,
        AppBmwModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: CustomMissingTranslationHandler
            }
        }),
        NgSelectModule,
        GraphQLModule,
        ContextMenuModule,
        OAuthModule.forRoot({
          resourceServer:{
            allowedUrls: ["/auth-service/graphql","/report-service/graphql","/product-service/graphql","/aggregation-service/graphql","/aggregation-service-legacy/graphql","/order-service-batch/graphql"],
            sendAccessToken: true
          }
        })
    ],
    providers: [OrderService, CookieService, ListFilterPipe, AuthGuard, RoleGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadersInterceptor,
      multi: true
    }],
    bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}

