<bmw-ag-grid-theme>
  <ag-grid-angular
      style="height: 500px; overflow: scroll"
      class="zebra"
      [rowData]="rowData"
      [columnDefs]="runtimeCompilerData.columns"
      [defaultColDef]="runtimeCompilerData.defaultColDef"
      [sideBar]="runtimeCompilerData.sideBar"
      [rowSelection]="rowSelection"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
      (modelUpdated)="onModelUpdated($event)"
      (rowDataChanged)="onRefreshGridData($event)"
      (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
      [rowMultiSelectWithClick]="true"
      [pagination]="true">
  </ag-grid-angular>
</bmw-ag-grid-theme>
<div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
    <bmw-button button="secondary" type="submit" (clickEvent)="onRefresh()" >
      {{ 'menu.budget.prod-master-data.data-view.refresh' | translate }}
    </bmw-button>
  </div>
  <div
    class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button button="primary" type="submit" (clickEvent)="onDelete()" [hidden]="noSeriesCalculationRole">
      {{ 'menu.budget.prod-master-data.data-view.delete' | translate }}
    </bmw-button>
  </div>
</div>
