import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../shared/grid.column.definition";


export class RecalcPriceGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: 'internalIdentifier',
      headerName: 'ID',
      resizable: true,
      rowGroup: false,
      hide: true,
      editable: false,
      sortable: false,
      valueFormatter: this.hideZeroValues,
      headerValueGetter: this.localizeHeader.bind(this)
    },{
      field: 'cwYear',
      headerName: 'year',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      valueFormatter: this.hideZeroValues,
      headerValueGetter: this.localizeHeader.bind(this)
    }/*,
    {
      field: 'week',
      headerName: 'week',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      valueFormatter: this.hideZeroValues,
      headerValueGetter: this.localizeHeader.bind(this)
    }*/,
    {
      field: 'derivativeCode',
      headerName: 'derivativeCode',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'snr',
      headerName: 'snr',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'ai',
      headerName: 'ai',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'orderNo',
      headerName: 'orderNo',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'auditEventType',
      headerName: 'auditEventType',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'user',
      headerName: 'user',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'startDate',
      headerName: 'startDate',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: true,
      sort: 'desc',
      sortingOrder: 'desc',
      valueFormatter: this.dateTimeFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'endDate',
      headerName: 'endDate',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      valueFormatter: this.dateTimeFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'productionWeekAuditStatus',
      headerName: 'productionWeekAuditStatus',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      sortable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
  ];


}
