import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ProfileService} from "../service/profile.service";
import {
  CurrentUser, Role,
  UserPriviledge,
  UserRole, UserRowData,
  Validity
} from "../model/user.model";
import moment from "moment";
import * as _ from "lodash";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {DateRangeInput} from "../../order/service/order.mutations";
import {FaasDatesHelper} from "../../../helper/faas.dates.helper";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-role-permission-maintanance',
  templateUrl: './role-permission-maintanance.component.html',
  styleUrls: ['./role-permission-maintanance.component.scss']
})
export class RolePermissionMaintananceComponent implements OnInit {

  maintenanceForm: FormGroup;
  roles: Role[];
  newUserPriviledge: boolean = false;
  flipScreenOnAddOrEdit: boolean = true;
  userRole: UserRole;
  validity: DateRangeInput;
  searchedUser: CurrentUser;
  loadingScreen: boolean;
  errorMessage: string;
  rowData: any = [];
  selectedRoles: any = [];
  selectedQNumber: string = null;
  saveDisabledForNoRoles: boolean = false;

  min: Date;
  max: Date;
  period = {};
  runtimeCompilerData = {
    inputModel: [
      this.min,
      this.max
    ]
  };
  disableOnEdit: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private service: ProfileService,
              private plantService: PlantChangeService,
              private translate: TranslateService) {
    this.userRole = new UserRole();
    this.validity = new DateRangeInput();
    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.loadRoles();
      }
    });
  }

  ngOnInit(): void {
    this.maintenanceForm = this.formBuilder.group({
      name: ['', Validators.required ]
    });

    this.runtimeCompilerData.inputModel[0] = new Date();
    this.runtimeCompilerData.inputModel[1] = moment().add(365,'days').toDate();
    this.period = this.runtimeCompilerData.inputModel;
  }

  loadData(){
    this.loadUserRoles();
  }

  search(qNumber: string) {
    this.loadingScreen = true;
    this.errorMessage = null;

    if (!_.isEmpty(qNumber?.toString().trim())) {
      let userName = qNumber.toString().toUpperCase();
        this.service.getCurrentUserByQNumber(userName).subscribe(({data}) => {
          this.extracted(data.user);
          this.flipScreenOnAddOrEdit = false;
        }, (error) => {
          this.loadingScreen = false;
          this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
        });
    }
  }

  save() {
    this.loadingScreen = true;
    try {
      this.maintenanceForm.markAllAsTouched();
      if (this.maintenanceForm.valid) {
        let rights = this.getUserRights();
        this.service.userPriviledges(rights).subscribe(({data}) => {
          this.loadUserRoles();
          this.flipScreenOnAddOrEdit = true;
          this.loadingScreen = false;
        }, error => {
          this.loadingScreen = false;
          this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
        });
      }
    } catch (e) {
      this.errorMessage = e.toString();
      this.loadingScreen = false;
    }
  }

  addNewUser(params: any){
    this.disableOnEdit = false;
    this.newUserPriviledge = true;
    this.selectedQNumber = null;
    this.selectedRoles = [];
    this.searchedUser = null;
    this.maintenanceForm.setValue({
      name: ""
    });
    this.loadRoles();
    this.flipScreenOnAddOrEdit = false;
  }

  editUser(userRowData: UserRowData) {
    this.selectedRoles = [];
    this.disableOnEdit = true;
    this.newUserPriviledge = false;
    this.loadRoles();
    this.selectedQNumber = userRowData.QNumber;
    this.search(this.selectedQNumber);
  }

  cancel(){
    this.disableOnEdit = false;
    this.loadUserRoles();
    this.errorMessage = undefined;
    this.flipScreenOnAddOrEdit = true;
  }

  loadRoles(plant: string = null){
    this.roles = undefined;
    this.errorMessage = undefined;

    this.service.getRolesForAllPlants().subscribe(({data, loading}) => {
      this.roles = data.allRoles;
      this.saveDisabledForNoRoles = false;
    }, (error) => {
      this.saveDisabledForNoRoles = true;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  loadUserRoles() {
    this.rowData = undefined;
    this.errorMessage = undefined;

    this.service.getRolesAllocatedToUsers().subscribe(({data, loading}) => {
      this.rowData = data.userRoles;
    }, (error) => {
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  getUserRights() : UserPriviledge {
    let userPriviledge = new UserPriviledge();
    let result : any = [];
    let validity = new Validity();

    validity.fromDate = FaasDatesHelper.formatFaasDate(this.dateFormatter(this.period[0]));
    validity.toDate = FaasDatesHelper.formatFaasDate(this.dateFormatter(this.period[1]));

    for ( let i = 0 ; i < this.selectedRoles.length ; i++ ){
      result.push(Number.parseInt(this.selectedRoles[i]));
    }

    userPriviledge.qnumber = this.f.name.value;
    userPriviledge.validity = validity;
    userPriviledge.roles = result;

    return userPriviledge;
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  dateFormatter(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  isValidFromLessThanExpiry(dateFrom,dateTo: string): boolean {
    let validFromDate = moment(dateFrom, 'YYYY-MM-DD');
    let expiryDate = moment(dateTo, 'YYYY-MM-DD');
    let result = validFromDate.diff(expiryDate, 'days') < 0;
    if (!result) {
      this.errorMessage = this.translate.instant('menu.administration.roles.permissions.error-message.valid-from-less-than-expiry');
    }
    return result;
  }

  extracted(user: CurrentUser) {
    this.runtimeCompilerData.inputModel = [];
    this.period = {};

    this.searchedUser = user;
    this.f.name.setValue(user.QNumber);
    user?.role.forEach(r => {
      this.selectedRoles.push(r.internalIdentifier.toString());
    });
    this.runtimeCompilerData.inputModel[0] = FaasDatesHelper.convertStringToDate(user.validity?.fromDate);
    this.runtimeCompilerData.inputModel[1] = FaasDatesHelper.convertStringToDate(user.validity?.toDate);
    this.period = this.runtimeCompilerData.inputModel;
    this.loadingScreen = false;
  }

  get f() {
    return this.maintenanceForm.controls;
  }

}
