import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OperationService} from "../service/operation.service";
import {
  ManufacturingCost,
  Status,
  Operation,
  OperationChangeInput,
  Type
} from '../model/operations.model';
import {OperationTypeSaGridComponent} from "./operation-type-sa-grid/operation-type-sa-grid.component";
import {ProductService} from "../../product/service/product.service";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {Packet, SpecialEquipment} from "../../product/model/product.model"; import {element} from "protractor"; import {types} from "util";


@Component({
  selector: 'app-operation-type-sa',
  templateUrl: './operation-type-sa.component.html',
  styleUrls: ['./operation-type-sa.component.scss']
})
export class OperationTypeSaComponent implements OnInit {
  @ViewChild (OperationTypeSaGridComponent) gridComponent;
  @Input() operation :Operation;
  rowData: any;
  productsData: any;
  allocatedProductsData: any;
  shouldShowGrid:boolean = true;
  showAllocate = false;
  message = undefined;
  dialogTitle: string;
  dialogText: string;
  @Output() takeOver = new EventEmitter();
  @Output() cancel = new EventEmitter();
  loadingScreen: boolean = false;

  constructor(
    private productService: ProductService,
    public translate: TranslateService,
    private plantService: PlantChangeService,
    private operationService: OperationService
  ) {

    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadProductData();
      }
    });

  }

  ngOnInit(): void {
    this.loadProductData();
  }

  loadProductData() {
    let productsDiff:any;
    let existingProducts:any[] = [];
    this.productService.getProductData(true)
      .subscribe(({data, loading}) => {
        this.productsData = this.productService.mapProductData(data.productsByPlant);
        this.productService.getAllocatedProducts(this.operation.operationId, true).subscribe(({data}) => {
          this.allocatedProductsData = this.productService.mapProductData(data.allocatedProductsByPlant);
          productsDiff = this.productsData.filter(function(obj) {
              return !this.allocatedProductsData.some(function(obj2) {
                  return obj.prod_typeid && obj.prod_typeid === obj2.prod_typeid && ((typeof(obj.prod_said) === 'undefined' && typeof(obj2.prod_said) === 'undefined' && obj2.prod_inWorkflow) || (obj.prod_said && obj.prod_said === obj2.prod_said));
              });
          },this);

          this.productsData.forEach( prodData => {
            this.operation.manufacturingCosts.forEach(manufacturingCost => {
            if(prodData.prod_said && prodData.prod_typeid) {
               if(prodData.prod_typeid === manufacturingCost.type.internalIdentifier &&
                 (prodData.prod_said === manufacturingCost.specialEquipment?.internalIdentifier || prodData.prod_said === manufacturingCost.packet?.internalIdentifier)){
                 prodData.selected = true;
                 existingProducts.push(prodData);
               }
            } else if(prodData.prod_typeid) {
              if(prodData.prod_typeid === manufacturingCost.type.internalIdentifier &&
                !manufacturingCost.specialEquipment && !manufacturingCost.packet) {
                prodData.selected = true;
                existingProducts.push(prodData);
              }
            }
            })
          })

          let allProducts = existingProducts.concat(productsDiff);
          this.productsData = allProducts.filter((item, index) => {
              return (allProducts.indexOf(item) == index);
          });

          this.rowData = this.productsData.sort((a, b) => parseInt(a.groupOrder) - parseInt(b.groupOrder));
        });
      });
  }

  onCancel(){
    this.cancel.emit();
  }

  onTakeOver($event){
    this.loadingScreen = true;
    let operationChangeInput: OperationChangeInput = new OperationChangeInput();
    const manufacturingCostList = [];

    $event.forEach(manCost => {
      const manufacturingCost : ManufacturingCost = new ManufacturingCost();
      manufacturingCost.type = new Type();
      manufacturingCost.manufacturingCostStatus = Status.NEW;
      manufacturingCost.type.internalIdentifier = manCost.prod_typeid;
      if (manCost.prod_said) {
        if (manCost.prod_satype == 'Packet') {
          manufacturingCost.packet = new Packet();
          manufacturingCost.packet.internalIdentifier = manCost.prod_said;
        } else {
          manufacturingCost.specialEquipment = new SpecialEquipment();
          manufacturingCost.specialEquipment.internalIdentifier = manCost.prod_said;
        }

      }
      manufacturingCostList.push(manufacturingCost);
    });

    operationChangeInput.manufacturingCosts = manufacturingCostList;
    operationChangeInput.internalIdentifier = this.operation.internalIdentifier;

    this.operationService.allocateManufacturingCostToOperation(operationChangeInput).subscribe((result) => {
      this.getOperationDetails();
    },(error)=> {
      this.getOperationDetails();
    })
  }

  getOperationDetails() {
    this.operationService.getOperationDetail(this.operation.internalIdentifier).subscribe((OperationDetails) => {
      this.operation = OperationDetails.data.operationById;
      this.loadingScreen = false;
      this.takeOver.emit(false);
    });
  }
}
