import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlantChangeService} from '../../header/service/PlantChangeService';
import {OperationService} from '../service/operation.service';
import {Operation, OperationSummary} from '../model/operations.model';

@Component({
  selector: 'app-manufacturing-costs-actioned',
  templateUrl: './manufacturing-costs-actioned.component.html',
  styleUrls: ['./manufacturing-costs-actioned.component.scss']
})
export class ManufacturingCostsActionedComponent implements OnInit {

  @Output() onOperationDetailsClick = new EventEmitter<any>();
  costsToBeActionedData: any;
  pricingData: any;
  message = undefined;
  dialogTitle: string;
  dialogText: string;
  showPrompt = false;
  loadingScreen = false;
  errorMessage: string;
  selected: Operation;
  hasCaptureRole = false;
  showDeleteConfirm = false;
  operationsToDelete: Operation[] = [];

  constructor(
    public translate: TranslateService,
    private plantService: PlantChangeService,
    private operationService: OperationService
  ) {

    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadOperations();
      }
    });
  }

  ngOnInit(): void {
    this.loadOperations();
    this.hasCaptureRole = this.operationService.canCapture();

  }

  loadOperations() {
    this.message = undefined;
    this.costsToBeActionedData = undefined;
    this.loadingScreen = true;

    this.operationService.getOperationsToBeActioned()
      .subscribe((result) => {
        this.loadingScreen = false;
        this.costsToBeActionedData = result.data.manufacturingCostsToBeActioned;
        this.pricingData = [{
          derivative: 'derivative',
          typeCode: 'typeCode',
          description: 'description',
          vinNumber: 'vinNumber',
          f2Date: 'f2Date',
          fnDate: 'fnDate',
          price: 'price'
        }];
      }, (error) => {
        this.loadingScreen = false;
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
  }

  setSelectedOperation($event) {
    this.selected = $event;
  }

  openOperation(selectedOperation) {
    this.message = undefined;
    if (selectedOperation.length === 0 || selectedOperation.length > 1) {
      this.dialogTitle = (selectedOperation.length > 1) ? this.translate.instant('messages.toomanytoopen') : this.translate.instant('messages.noseltoopen');
      this.dialogText = this.translate.instant('messages.selecttoopen');
      this.showPrompt = true;
    } else {
      this.selectOperationDetails(selectedOperation[0]);
    }
  }

  deleteOperations(operations) {
    this.message = undefined;
    if (operations.length === 0) {
      this.dialogTitle = this.translate.instant('messages.noseltoopen');
      this.dialogText = this.translate.instant('messages.selecttoopen');
      this.showPrompt = true;
    } else {
      this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.delete')
      this.operationsToDelete = operations;
      this.showDeleteConfirm = true;
    }
  }

  selectOperationDetails(operation: OperationSummary): void {
    this.onOperationDetailsClick.emit(operation);
  }

  deleteOperation() {
    this.showDeleteConfirm = false;
    this.loadingScreen = true;
    const internalIdentifiers = [];
    this.operationsToDelete.forEach(operation => internalIdentifiers.push(operation.internalIdentifier))
    this.operationService.deleteOperations(internalIdentifiers)
      .subscribe((result) => {
        this.loadingScreen = false;
        this.operationsToDelete = undefined;
        this.message = this.translate.instant('messages.delete-success');
        this.loadOperations();
      }, (error) => {
        this.loadingScreen = false;
        this.operationsToDelete = undefined;
        this.message = this.translate.instant('messages.delete-failure');
        this.errorMessage = this.operationService.removeGraphQLErrorOnMessage(error.message);
      });
  }
}
