import gql from "graphql-tag";
import {Price, PriceData} from "../model/continous.price.model";
import {Plant} from "../../order/model/order.model";

export const CONTINUOUS_PRICE_QUERY = gql`
  query price($snr: String! , $ai: Int! , $plant: PlantInput! , $knowledgeDate: String! ) {
    price(snr: $snr , ai: $ai  , plant: $plant , knowledgeDate: $knowledgeDate ) {
    priceUnit
    material {
      ai
      plant
      snr
    }
    basePrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    nettPrice {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    calculatedOn
    currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
    orderCurrency {
        currency
        currencyType
        displayFormat
    }
    priceType
    priceState
    surcharges {
      currency {
        currency
        currencyType
        displayFormat
        internalIdentifier
        plant
        scale
      }
      value
    }
    validity {
      fromDate
      toDate
    }
    priceComponents {
      weightFactor
      order {
        orderNo
    orderState
    material {
      ai
      plant
      snr
    }
    changeDate
    internalIdentifier
    orderCurrency {
      currency
      currencyType
      displayFormat
      internalIdentifier
      plant
      scale
    }
    orderState
    workflowState
    orderType
    quantityUnit
    supplierNo
    version
    pricingPeriods {
      internalIdentifier
      priceUnit
      basePrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      nettPrice {
        currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      surcharges {
        category
        internalIdentifier
        surchargeType
        amount {
          currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
          value
        }
      }
    }
      }
      exchangeRate {
         currency {
          currency
          currencyType
          displayFormat
          internalIdentifier
          plant
          scale
        }
        exchangeRateType
        month
        value
        year
      }
      weight {
        importDate
        internalIdentifier
        orderNo
        value
        weightDate
        weightType
      }
    }
  }
}`;


export const MANUAL_PRICES_QUERY = gql`
  query manualPrices($plant: PlantInput!) {
    manualPrices(plant: $plant) {
      material {
        ai
        plant
        snr
      }
      basePrice {
        currency {
          currency
        }
        value
      }
      nettPrice {
        currency {
          currency
        }
        value
      }
      calculatedOn
      currency {
        currency
      }
      orderCurrency {
        currency
      }
      priceType
      priceState
      surcharges {
        currency {
          currency
        }
        value
      }
      validity {
        fromDate
        toDate
      }
      reason
      user
      priceWorkflowState
      statusDate
    }
}`;

export interface ContinuousPriceResponse {
    price: PriceData;
}

export interface ContinuousPriceVariables {
  knowledgeDate: string;
  snr: string;
  plant: Plant;
  ai: number;
}

export interface ManualPricesResponse {
  manualPrices: Price[];
}
