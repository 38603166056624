<bmw-fieldset opened="true">
  <bmw-fieldset-header>{{ 'menu.pricing.freeze-prices.title' | translate }}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <h4 class="bmw-h4 bmw__margin-top__m bmw__margin-bottom__m">{{ 'menu.pricing.continuous-prices.overview.selected-price' | translate }}</h4>
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell--span-12">
        <div class="bmw__grid__inner">
          <div class="bmw__grid__cell--span-5">
            <p data-testid="material-snr" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.snr' | translate }}:</b>{{price.material.snr}}-{{price.material.ai | aiToString }}</p>
            <p data-testid="currency" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.currency' | translate }}:</b> {{price?.currency.currency}}</p>
            <p data-testid="price-states" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.price-state' | translate }}: </b> {{priceStates}}</p>
            <p data-testid="price-unit" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.price-unit' | translate }}:</b> {{priceUnit}}</p>
          </div>
          <div class="bmw__grid__cell--span-6">
            <p data-testid="calculated-date" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.calculated-on' | translate }}:</b> {{calculatedDate}}</p>
            <p data-testid="from-date" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.valid-from' | translate }}:</b> {{fromDate}}</p>
            <p data-testid="nett-price" class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.continuous-prices.overview.nett-price' | translate }}:</b> {{price.nettPrice.value | moneyFormatter }}</p>
          </div>
        </div>
      </div>
    </div>
  </bmw-fieldset-container>
</bmw-fieldset>
<div class="bmw__margin-top__s" *ngIf="price.priceComponents?.length > 0">
  <app-price-component [priceComponents]="price.priceComponents"></app-price-component>
</div>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..." [show]="loadingScreen"></bmw-progress-circle>
<div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
    <bmw-button data-testid="cancel" button="secondary" type="submit" (clickEvent)="cancel()" >
      {{ 'component.bottom-bar.cancel' | translate }}
    </bmw-button>
  </div>
  <div *ngIf="showButtons" class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button data-testid="reject" button="primary" type="submit" (clickEvent)="onReject()"
                style="padding: 5px;">
      {{ 'component.bottom-bar.reject' | translate }}
    </bmw-button>
    <bmw-button data-testid="release" button="primary" type="submit" (clickEvent)="onRelease()" >
      {{ 'component.bottom-bar.release' | translate }}
    </bmw-button>
  </div>
</div>

