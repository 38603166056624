import {Component, ViewChild, AfterViewInit} from '@angular/core';
import {transferPrice} from "../service/transfer-price-constants";
import {RoyaltiesIndicator} from "../model/operations.model";

@Component({
  selector: 'app-numeric-editor-cell',
  template: `
    <input #i [value]="params.value" [disabled]="editDisabled" (keypress)="onKeyPress($event)" (keydown)="onKeyDown($event)"/>
  `
})
export class NumericEditorComponent implements AfterViewInit {
  @ViewChild('i') textInput;
  params;
  row;
  column;
  editDisabled: boolean = false;

  constructor() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.textInput && this.textInput.nativeElement) {
        this.textInput.nativeElement.focus();
        this.textInput.nativeElement.select();
      }
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.row = params.data;
    this.column = params.column;

    if ((this.column.colDef.headerName === 'Royalties For Technology' && this.params.data.saCode !== '08AA') ||
      this.column.colDef.headerName === 'Profit'
      ) {
      this.editDisabled = true;
    }

    if (this.column.colDef.headerName === 'Royalties For Technology' &&
      this.params.data.includeRoyalties === RoyaltiesIndicator.SOMETIMES && this.params.data.saCode === '08AA') {
      this.editDisabled = false;
    }
  }

  getValue() {
    if (isNotNumeric(this.textInput.nativeElement.value)) {
      this.textInput.nativeElement.value = 0;
    }
    function isNotNumeric(value: any): boolean {
      return isNaN(value - parseFloat(value));
    }

    return this.textInput.nativeElement.value;
  }

  onKeyPress(event) {
    if (!isNumeric(event)) {
      event.preventDefault();
    }

    function isNumeric(ev) {
      return /\d/.test(ev.key) || isCharDecimal(ev) || isCharMinus(ev);
    }

    function isCharDecimal(ev) {
      return !!/\./.test(ev.key);
    }

    function isCharMinus(ev) {
      return !!/\-/.test(ev.key);
    }
  }

  onKeyDown(event) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }
}
