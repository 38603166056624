import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {OrdersViewModel} from "../model/OrdersViewModel";
import {GridApi, GridOptions} from "ag-grid-community";
import {TranslateService} from "@ngx-translate/core";
import {Plant} from "../model/order.model";
import {OrderGridEditableColumns} from "./order-grid-editable.columns";
import {TranslateOrderManagementEnums} from "../service/order.management.enum.translations";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Component({
  selector: 'app-order-grid-editable',
  templateUrl: './order-grid-editable.component.html',
  styleUrls: ['./order-grid-editable.component.scss']
})
export class OrderGridEditableComponent implements OnInit {

  @Input() rowData: any;
  @Input() groupByColumns: string[];
  @Input() hideColumns: string[];
  @Input() editableColumns: string[];
  @Input() editMode: boolean = false;
  @Input() showSideBar: boolean = true;
  @Input() loadingScreen: boolean = false;
  @Input() editableErrorMessage: string = null;

  @Output() onCancelClick = new EventEmitter<boolean>();
  @Output() updateOrder = new EventEmitter<OrdersViewModel[]>();
  @Output() doubleClick = new EventEmitter<OrdersViewModel>();

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  orderManagementEnums: TranslateOrderManagementEnums;

  approveRejectButtonStatus = false;
  editedRowsMap = new Map();
  rowSelection;

  constructor(private translate: TranslateService) {
    this.gridColumns = new OrderGridEditableColumns(this.translate);
    this.orderManagementEnums = new TranslateOrderManagementEnums();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
      this.gridApi.refreshToolPanel();
      this.translateInGridEnums();
    });
    this.translateInGridEnums(true);
  }

  ngOnInit() {
    this.gridColumns.hideColumns(this.hideColumns);
    this.rowSelection = 'single';
    this.setGridOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onRefresh(): void {
    this.approveRejectButtonStatus = false;
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    }, 0);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    }, 0);
  }

  expandAfterRedraw() {
    this.gridApi.forEachNode((node) => {
      if(node?.group) {
        node.setExpanded(true)
      }
    })
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'fromDate', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
    setTimeout(() => {
      this.expandAfterRedraw();
    }, 0);
  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.translateInGridEnums(true);
      this.gridApi.hideOverlay();
      this.expandAfterRedraw();
    }
  }

  //https://www.ag-grid.com/angular-grid/localisation/
  translateInGridEnums(refreshFromDefault: boolean = null) {
    if (refreshFromDefault && this.translate.currentLang === 'de') {
      this.translateRowData(this.getAllRows(), 'de');
    }
    else if (this.translate.currentLang !== SystemGlobalCache.currentLang()) {
      this.rowData = this.translateRowData(this.getAllRows(), this.translate.currentLang);
    }
  }

  translateRowData(rows: any[], lang: string = 'en'): any[] {
    if (rows?.length === 0) { return; }
    let pushRowData = [];
    let refreshHeaders = false;
    rows.forEach(row => {
      refreshHeaders = true;
      if (row !== undefined) {
        row.orderState = this.orderManagementEnums.translate(lang, row.orderState);
        row.orderType = this.orderManagementEnums.translate(lang, row.orderType);
        row.quantityUnit = this.orderManagementEnums.translate(lang, row.quantityUnit);
        pushRowData.push(row);
      }
    });
    return pushRowData;
  }

  getAllRows() {
    let rowData = [];
    if (this.gridApi !== undefined) {
      this.gridApi?.forEachNode(node => rowData.push(node.data));
    }
    return rowData;
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return  {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }

  setGridOptions() {
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'single',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }

  onCellValueChanged(params: any) {
    params.data.plant = Plant[window.sessionStorage.client];
    //can only edit if no pending approval is in place...
    if ( params.data.relatedOrders == undefined || params.data.relatedOrders.length == 0) {
      this.editedRowsMap.set(params.data.pricingPeriodInternalIdentifier,params.data);
    }
  }

  onSave() {
    this.loadingScreen = true;
    this.gridApi.stopEditing();
    this.onRefresh();
    let updatedOrders: any = [];
    for ( let value of this.editedRowsMap.values() ) {
      updatedOrders.push(value);
    }
    this.updateOrder.emit(updatedOrders);
  }

  onCancel() {
    this.onCancelClick.emit();
  }

}
