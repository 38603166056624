<div class="table-wrapper bmw__margin-top__s">
  <form class="reversed">
    <app-price-search-by-date data-testid="search-by-date" (onSearch)="search($event)"></app-price-search-by-date>
  </form>
  <div>
    <bmw-progress-circle [fullScreen]="true" loadingLabel="loading..." [show]="loading"></bmw-progress-circle>
    <div *ngIf="errorMessage" class="bmw__margin-top__l">
      <bmw-inline-message type="error">
        {{errorMessage}}
      </bmw-inline-message>
    </div>
    <bmw-ag-grid-theme>
      <div class="bmw__margin-top__l">
          <div *ngIf="!errorMessage && !selectedPrice && priceByDate" class="bmw__margin-top__xs">
            <div class="bmw__margin-top__xs">
              <app-freeze-price-details-grid
                [rowData]="priceByDate"
                [showButtons]="false"
                [gridRowSelection]="rowSelection"
                [hideColumns]="hideColumns"
                (doubleClick)="onViewSelectedPrice($event)"
                (errorMessage)="onError($event)">
              </app-freeze-price-details-grid>
            </div>
          </div>
      </div>

      <div *ngIf="selectedPrice" class="bmw__margin-top__xs">
        <app-freeze-price-main-details
          [price]="selectedPrice"
          [showButtons]="false"
          (onCancel)="closePriceView()">
        </app-freeze-price-main-details>
      </div>
    </bmw-ag-grid-theme>
  </div>
</div>

