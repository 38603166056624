import {Plant} from "../../order/model/order.model";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

export class ProductType {
  derivative: string;
  designation: string;
  plant: Plant;
  productBodyAndRoofColours: ProductCategoryGroup[];
  productCategories: ProductCategories;
  productDesignTrims: ProductCategoryGroup[];
  productExecutions: ProductCategoryGroup[];
  productPackets: ProductCategoryGroup[];
  productSingleSas: ProductCategoryGroup[];
  royaltiesIndicator: string;
  transferPrice: number;
  transferPriceNoOfKeys?: number;
  type: string;
  rules: number;
  fnDate: Date;
  f2Date: Date;
  profitForOptions: number;
  royaltiesForOptions: number;
}

export class ProductColumn {
  code: string;
  grouping: string;
  name: string;
  plant: Plant;
}

export class QuicksightUrl {
  quicksightUrl: String;
}

export class ReportInputInput{
  plant: Plant;
  report: String;
}

export class AWSUrl {
  awsUrl: String;
}

export class ProductVehicle {
  derivative: string;
  designation: string;
  f2Date: Date;
  fnDate: Date;
  plant: Plant;
  productBodyAndRoofColours: ProductCategoryGroup[];
  productCategories: ProductCategories;
  productDesignTrims: ProductCategoryGroup[];
  productExecutions: ProductCategoryGroup[];
  productPackets: ProductCategoryGroup[];
  productSingleSas: ProductCategoryGroup[];
  royaltiesIndicator: string;
  transferPrice: number;
  price: number;
  type: string;
  vin: string;
}

export class ProductCategoryGroup {
  total: number;
  productOptions: ProductOptions[];
}

export class ProductOptions {
  amount: number;
  description: string;
  numberOfKeys?: number;
}

export class ProductCategories {
  bmwParts: number;
  bmwPartNoOfKeys?: number;
  customsClearanceFee: number;
  customsClearanceFeeNoOfKeys?: number;
  depreciation: number;
  depreciationNoOfKeys?: number;
  directLabour: number;
  directLabourNoOfKeys?: number;
  fuelAndEnergy: number;
  fuelAndEnergyNoOfKeys?: number;
  gwmParts: number;
  gwmPartNoOfKeys?: number;
  importDuties: number;
  importDutiesNoOfKeys?: number;
  inboundLogistics: number;
  inboundLogisticsNoOfKeys?: number;
  indirectLabour: number;
  indirectLabourNoOfKeys?: number;
  nonDeductibleIndirectTaxesDirectlyLinkedToProject: number;
  nonDeductibleIndirectTaxesDirectlyLinkedToProjectNoOfKeys?: number;
  nonDeductibleIndirectTaxesNotDirectlyLinkedToProject: number;
  nonDeductibleIndirectTaxesNotDirectlyLinkedToProjectNoOfKeys?: number;
  otherFixedExpenses: number;
  otherFixedExpensesNoOfKeys?: number;
  outboundLogistics: number;
  outboundLogisticsNoOfKeys?: number;
  profitFivePercent: number;
  profitFivePercentNoOfKeys?: number;
  royaltiesForTechnology: number;
  royaltiesForTechnologyNoOfKeys?: number;
  salParts: number;
  salPartNoOfKeys?: number;
  thirdPartyParts: number;
  thirdPartyPartNoOfKeys?: number;
  variableManufacturingExpenses: number;
  variableManufacturingExpensesNoOfKeys?: number;
  warranty: number;
  warrantyNoOfKeys?: number;
  yearlyProductionCostReduction: number;
  yearlyProductionCostReductionNoOfKeys?: number;
}

export class ManufacturingCostChangeInput {
  operationId: number;
  manufacturingCostInputs: ManufacturingCostInput[];
  plant: Plant;
}

export class ManufacturingCostInput {
  manufacturingCostId: number;
  price: number;
  transferPrice: TransferPrice;
}

export class Money {
  value: number;
  currency: ISOCurrency;
}
export class ISOCurrency {
  internalIdentifier: number;
  plant: Plant;
  currency: string;
  scale: number;
  displayFormat: string;
  currencyType: CurrencyType;

  dummyBaseCurrency(): ISOCurrency {
    this.internalIdentifier = 0;
    this.currencyType = CurrencyType.BASE;
    this.currency = '';
    this.scale = 5;
    this.plant = SystemGlobalCache.plant();
    return this;
  }
}

export enum CurrencyType {
  BASE = 'BASE', FOREIGN = 'FOREIGN'
}

export class OperationHistory {
  statusUser: string;
  statusReason: string;
  statusDate: string;
  workFlowStatus: string;
}

export class OperationFile {
  internalIdentifier: number;
  name: string;
  content: string;
}

class BaseOperation {
  internalIdentifier: number;
  operationId: number;
  name: string;
  releaseDate: string;
  statusDate: string;
  statusReason: string;
  statusUser: string;
  validFrom: string;
  workFlowStatus: string;
  costEntries: number;
  files: OperationFile[];
  plant: Plant;
  manufacturingCosts: ManufacturingCost[];
  rules: Rule[];
  cloned: boolean;
}

export class Operation extends BaseOperation {};

export type OperationSummary = Omit<BaseOperation,"manufacturingCosts">;

export class OperationChangeInput {
  name: string;
  internalIdentifier: number;
  operationId: number;
  validFrom: string;
  statusReason: string;
  manufacturingCosts: ManufacturingCost[];
  files: OperationFile[];
  rules: Rule[];
  cloned: boolean;
}

export class RuleChangeInput {
  operationId: number;
  plant: Plant;
  ruleInputs: Rule[];
}

export class Rule {
  internalIdentifier: number;
  amount: Money;
  derivativeCode: string;
  type: Type;
  specialEquipments: SpecialEquipment[];
  previousRules: Rule[];
  status: Status;
  royaltiesIndicator: RoyaltiesIndicator;
  transferCategoryCosts: TransferCategoryCost[];
  validFrom: string;
}

export class RuleHistory {
  derivativeCode: string;
  typeName: string;
  validFrom: string;
  releaseDate: string;
  operationId: number;
  operationName: string;
  modelTypeCode: string;
  specialEquipmentCode1: string;
  description1: string;
  specialEquipmentCode2: string;
  description2: string;
  ruleStatus: Status;
  amount: Money;
}

export class ManufacturingCost {
  internalIdentifier: number;
  derivativeCode: string;
  specialEquipment: SpecialEquipment;
  type: Type;
  price: Money;
  validity: DateRange;
  packet: Packet;
  transferPrice: TransferPrice;
  previousManufacturingCosts: ManufacturingCost[];
  manufacturingCostStatus: Status;
}

export class ManufacturingCostHistory {
  internalIdentifier: number;
  derivativeCode: string;
  specialEquipment: SpecialEquipment;
  type: Type;
  price: Money;
  packet: Packet;
  transferPrice: TransferPrice;
  validFrom: string;
  releaseDate: string;
  operationId: number;
  operationName: string;
  previousManufacturingCosts: ManufacturingCost[];
  manufacturingCostStatus: string;
}

export class TransferPrice {
  internalIdentifier: number;
  royaltiesIndicator: RoyaltiesIndicator;
  transferCategoryCost: TransferCategoryCost[];
}

export class TransferCategoryCost {
  adjustmentReason: AdjustmentReason;
  amount: Money;
  categoryCost: CategoryCost;
  internalIdentifier: number;
  typeLink?: string;
}

export class CategoryCost {
  groupCost: GroupCost;
  internalIdentifier: number;
  name: string;
}

export class GroupCost {
  code: number;
  internalIdentifier: number;
  name: string;
}

export class ColorEquip {
  colorCode: string;
  internalIdentifier: number;
  name: string;
  optionalValidity: DateRange;
}

export class AdjustmentReason {
  changeType: ChangeType;
  internalIdentifier: number;
  name: string;
}

export class ChangeType {
  internalIdentifier: number;
  name: string;
}

export class DateRange {
  periodMaxValue: number;
  periodMaxUnit: string;
  adjusted: boolean;
  fromDate: string;
  toDate: string;
}

export class Type {
  axlGear?: string;
  capacity?: number;
  code?: string;
  country?: string;
  cylinder?:number;
  engineName?: string;
  engineSeries?: string;
  equipBase?: string;
  fuel?: string;
  internalIdentifier?: number;
  name?: string;
  steering?: string;
  typeImportStatus?: ImportStatus;
  validity?: DateRange;
  productionType?: ProductionType;
  specialEquipment?: SpecialEquipment[];
  packets?: Packet[];
  rulesHistory?: RuleHistory[];
}

export class SpecialEquipment {
  internalIdentifier: number;
  code: string;
  name: string;
  equipment: string;
  type: string;
  colorEquip: ColorEquip;
  specialEquipmentImportStatus: ImportStatus;
  validity: DateRange;
  manufacturingCostHistory?: ManufacturingCostHistory;
}

export class Packet {
  internalIdentifier: number;
  code: string;
  name: string;
  validity: DateRange;
  generation: number;
  sequence: number;
  packetImportStatus: ImportStatus;
  manufacturingCostHistory?:ManufacturingCostHistory;
}

export enum ImportStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}
export enum ProductionTypeCode {
  SKD = "SKD",
  CKD = "CKD",
  CBU = "CBU"
}

export class ProductionType {
  productionTypeCode: ProductionTypeCode;
  productionTypeImportStatus: ImportStatus;
}

export interface UploadFile {
  internalIdentifier?: number;
  name?: string;
  content?: string;
}

export interface FileList {
  files?: Array<UploadFile>;
  property?: string;
}

export enum RoyaltiesIndicator {
  ALWAYS='Y',
  SOMETIMES='Y/N',
  NEVER='N'
}

export enum Status {
  NEW='NEW',
  ACTIVE='ACTIVE',
  OVERWRITTEN='OVERWRITTEN'
}
