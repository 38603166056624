import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const textKey = params && params.key;

    if (textKey.split('.').indexOf('ERROR') > -1) {
      return 'Unknown Error';
    }
  }
}