<ag-grid-angular
  style="height: 500px; overflow: scroll"
  class="zebra"
  [rowData]="rowData"
  [columnDefs]="runtimeCompilerData.columns"
  [gridOptions]="runtimeCompilerData.gridOptions"
  [defaultColDef]="runtimeCompilerData.defaultColDef"
  [sideBar]="false"
  [statusBar]="runtimeCompilerData.statusBar"
  [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
  [pivotMode]="false"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="onModelUpdated($event)"
  (rowDataChanged)="onRefreshGridData($event)"
  (columnRowGroupChanged)="onColumnRowGroupChanged($event)"
  [pagination]="true"
  [editType]="runtimeCompilerData.editType"
  [components]="runtimeCompilerData.components"
  (rowValueChanged)="onRowValueChanged($event)"
  (rowEditingStarted)="onRowEditingStarted($event)"
  (cellClicked)="onCellClicked($event)"
  (cellEditingStarted)="onCellEditingStarted($event)"
>
</ag-grid-angular>
