<div class="download-link">
  <span>
    <a role="button" (click)="exportAsExcel()" class="bmw-regular-text ">{{'menu.pricing.multi-supplier.download' | translate}}</a>
  </span>
</div>
<bmw-ag-grid-theme>
  <ag-grid-angular
    data-testid="details-grid"
    style="height: 400px; overflow: scroll"
    class="zebra"
    [rowData]="rowData"
    [columnDefs]="runtimeCompilerData.columns"
    [gridOptions]="runtimeCompilerData.gridOptions"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [sideBar]="runtimeCompilerData.sideBar"
    [statusBar]="runtimeCompilerData.statusBar"
    [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
    [allowContextMenuWithControlKey]="true"
    [suppressContextMenu]="true"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (modelUpdated)="onModelUpdated($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    (rowDataChanged)="rowDataChanged($event)"
    (rowSelected)="onRowSelected($event)"
    (rowDoubleClicked)="onRowDoubleClicked($event)"
    (columnRowGroupChanged)="onSaveGridState($event)"
    (columnValueChanged)="onSaveGridState($event)"
    (columnVisible)="onSaveGridState($event)"
    (columnPinned)="onSaveGridState($event)"
    (columnResized)="onSaveGridState($event)"
    (columnMoved)="onSaveGridState($event)"
    (cellDoubleClicked)="onCellDoubleClick($event)"
    [rowSelection]="rowSelection"
    [rowMultiSelectWithClick]="true"
    [pagination]="true"
    [excelStyles]="excelStyles"
    [suppressPaginationPanel]="false">
  </ag-grid-angular>
</bmw-ag-grid-theme>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Saving..." [show]="loadingScreen"></bmw-progress-circle>
<div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
      <bmw-button button="secondary" type="submit" (clickEvent)="onCancel()" >
        {{ 'component.bottom-bar.cancel' | translate }}
      </bmw-button>
    </div>
    <div *ngIf="showButtons" class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
      <bmw-button button="primary" type="submit" (clickEvent)="onRelease()" [disabled]="disableRejectReleaseButton" style="padding-right: 5px;">
        {{ 'component.bottom-bar.release' | translate }}
      </bmw-button>
      <bmw-button button="primary" type="submit" (clickEvent)="onReject()" [disabled]="disableRejectReleaseButton" >
        {{ 'component.bottom-bar.reject' | translate }}
      </bmw-button>
    </div>
  </div>
</div>

