import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";

export class ManualPricesGridColumns {
  constructor(private translate: TranslateService){
  }

  columns = [
    {
      field: 'snr',
      headerName: 'SNR',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'ai',
      headerName: 'AI',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'basePriceValue',
      headerName: 'Base Price',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      type: 'rightAligned',
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'nettPriceValue',
      headerName: 'Nett Price',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      type: 'rightAligned',
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'calculatedOn',
      headerName: 'Calculated On',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'priceCurrency',
      headerName: 'Currency',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'priceState',
      headerName: 'Price State',
      width: 250,
      minWidth: 250,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'statusDate',
      headerName: 'Status Date',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'workflowState',
      headerName: 'Workflow State',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'fromDate',
      headerName: 'From Date',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'toDate',
      headerName: 'To Date',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'user',
      headerName: 'User',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: 150,
      minWidth: 150,
      rowGroup: false,
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
  ];

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (params.value === undefined ) {
      return '';
    }
    return moment(params.value).format('DD.MM.YYYY');
  }
}
