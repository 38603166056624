import { Component, OnInit } from '@angular/core';
import {TabSelectionService} from "../service/TabSelectionService";

@Component({
  selector: 'app-freeze-price-management',
  templateUrl: './freeze-price-management.component.html',
  styleUrls: ['./freeze-price-management.component.scss']
})
export class FreezePriceManagementComponent implements OnInit {

  constructor(private tabService: TabSelectionService) { }

  ngOnInit(): void {
  }

  moveToSummaryOverview(tab: string) {
    this.tabService.sendSelectedTabName(tab);
  }
}
