import {Component, OnInit, ViewChild} from '@angular/core';
import {ExchangeRatesService} from "../service/exchange-rates.service";
import {ExchangeRatesReleaseGridComponent} from "./exchange-rates-release-grid/exchange-rates-release-grid.component";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-exchange-rates-release',
  templateUrl: './exchange-rates-release.component.html',
  styleUrls: ['./exchange-rates-release.component.scss']
})
export class ExchangeRatesReleaseComponent implements OnInit {

  @ViewChild (ExchangeRatesReleaseGridComponent) gridComponent;
  disableRefreshButton: boolean = true;
  errorMessage: string;
  rowData: any;
  private exchangeRatesToProcess: any;
  showConfirmScreen:boolean = false;
  confirmExchangeRates: any;
  rejectMode:boolean = false;
  shouldShowGrid:boolean = true;
  subscription: Subscription;

  constructor(private exchangeRatesService: ExchangeRatesService, private plantService: PlantChangeService) {
    this.subscription = this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadPendingApprovalExchangeRates()
      }
    });
  }

  ngOnInit(): void {
    this.loadPendingApprovalExchangeRates();
  }

  private loadPendingApprovalExchangeRates() {
    this.shouldShowGrid = true;
    this.rowData = undefined;
    this.rejectMode = false;
    this.errorMessage = undefined
    this.disableRefreshButton = true;

    this.exchangeRatesService.getExchangeRatesPendingApproval().subscribe(({data, loading}) => {
            this.rowData = this.exchangeRatesService.mapReleaseExchangeRates(data.exchangeRatesPendingApproval);
            this.disableRefreshButton = false;
    }, (error) => {
      this.shouldShowGrid = false;
      this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  private rejectPendingApprovalExchangeRates() {
    this.rowData = undefined;
    this.rejectMode = false;
    this.errorMessage = undefined
    this.exchangeRatesService.rejectExchangeRatesPendingApproval(this.exchangeRatesToProcess).subscribe(({data, loading}) => {
      this.refreshData();
    }, (error) => {
      this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
      this.disableRefreshButton = false;
    });
  }

  private releasePendingApprovalExchangeRates() {
    this.rowData = undefined;
    this.rejectMode = false;
    this.errorMessage = undefined
    this.exchangeRatesService.releaseExchangeRatesPendingApproval(this.exchangeRatesToProcess).subscribe(({data, loading}) => {
      this.refreshData();
    }, (error) => {
      this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
      this.disableRefreshButton = false;
    });
  }

  onRelease() {
    this.processPendingExhangeRates(false);
  }

  noDataToActOn() {
    if (this.gridComponent) {
      return this.gridComponent.getRowData().filter(rate => rate.finalPend == "true" || rate.perNovPend == "true").length == 0
    }else{
      return true;
    }
  }

  onReject() {
    this.processPendingExhangeRates(true);
  }

  processPendingExhangeRates(rejectMode:boolean) {
    this.exchangeRatesToProcess = [];
    let ratesData = this.gridComponent.getRowData().filter(rate => rate.finalPend == "true" || rate.perNovPend == "true");
    if (ratesData.length > 0) {
      ratesData.forEach((rateItem) => {
        this.exchangeRatesToProcess.push(this.createAppropriateRate(rateItem));
      });

      this.rejectMode = rejectMode;
      this.confirmExchangeRates = this.exchangeRatesToProcess;
      this.showConfirmScreen = this.exchangeRatesToProcess.length > 0;
    }
  }

  private createAppropriateRate(rate) {
    let result = {};
    if (rate["perNovPend"] == "true") {
      result["perNov"] = rate["perNov"];
      result["perNov_id"] = rate["perNov_id"];
      result["statusReason"] = rate["statusReason"];
    }
    if (rate["finalPend"] == "true") {
      result["final"] = rate["final"];
      result["final_id"] = rate["final_id"];
      result["statusReason"] = rate["statusReason"];
    }
    result["year"] = rate["year"];
    result["currency"] = rate["currency"];
    return result;
  }

  refreshData() {
    this.loadPendingApprovalExchangeRates();
  }

  toggleView() {
    this.rejectMode = false;
    this.showConfirmScreen = false;
  }

  onConfirmation() {
    this.showConfirmScreen = false;
    this.submitToUpdate();
  }

  private submitToUpdate() {
    if (this.rejectMode){
      this.rejectPendingApprovalExchangeRates();
    }else{
      this.releasePendingApprovalExchangeRates();
    }

  }
}
