import {Plant} from "../../order/model/order.model";
import {ImportStatus, Packet, ProductionType, SpecialEquipment} from "../../product/model/product.model";
import {ISOCurrency} from "../../exchange-rates/model/exchange-rates.model";

export class ManufacturingCostChangeInput {
  operationId: number;
  manufacturingCostInputs: ManufacturingCostInput[];
  plant: Plant;
}

export class ManufacturingCostInput {
  manufacturingCostId: number;
  price: number;
  transferPrice: TransferPrice;
}

export class Money {
  value: number;
  currency: ISOCurrency;
}

export class OperationHistory {
  statusUser: string;
  statusReason: string;
  statusDate: string;
  workFlowStatus: string;
}

export class OperationFile {
  internalIdentifier: number;
  name: string;
  content: string;
}

export class HelpDocument {
  internalIdentifier: number;
  name: string;
  content: string;
}

export enum HelpDocumentType {
  MANUAL = 'MANUAL',
  TRANSFERPRICE = 'TRANSFERPRICE',
  REPORTING = 'REPORTING'
}

class BaseOperation {
  internalIdentifier: number;
  operationId: number;
  name: string;
  releaseDate: string;
  statusDate: string;
  statusReason: string;
  statusUser: string;
  validFrom: string;
  workFlowStatus: string;
  costEntries: number;
  files: OperationFile[];
  plant: Plant;
  manufacturingCosts: ManufacturingCost[];
  rules: Rule[];
  cloned: boolean;
}

export class Operation extends BaseOperation {}

export type OperationSummary = Omit<BaseOperation,"manufacturingCosts">;

export class OperationChangeInput {
  name: string;
  internalIdentifier: number;
  operationId: number;
  validFrom: string;
  statusReason: string;
  manufacturingCosts: ManufacturingCost[];
  files: OperationFile[];
  rules: Rule[];
  cloned: boolean;
}

export class RuleChangeInput {
  operationId: number;
  plant: Plant;
  ruleInputs: Rule[];
}

export class Rule {
  internalIdentifier: number;
  amount: Money;
  derivativeCode: string;
  type: Type;
  specialEquipments: SpecialEquipment[];
  previousRules: Rule[];
  status: Status;
  royaltiesIndicator: RoyaltiesIndicator;
  transferCategoryCosts: TransferCategoryCost[];
  validFrom: string;
}

export class RuleHistory {
  derivativeCode: string;
  typeName: string;
  validFrom: string;
  releaseDate: string;
  operationId: number;
  operationName: string;
  modelTypeCode: string;
  specialEquipmentCode1: string;
  description1: string;
  specialEquipmentCode2: string;
  description2: string;
  ruleStatus: Status;
  amount: Money;
}

export class ManufacturingCost {
  internalIdentifier: number;
  derivativeCode: string;
  specialEquipment: SpecialEquipment;
  type: Type;
  price: Money;
  validity: DateRange;
  packet: Packet;
  transferPrice: TransferPrice;
  previousManufacturingCosts: ManufacturingCost[];
  manufacturingCostStatus: Status;
  rulesHistory?: RuleHistory[];
}

export class ManufacturingCostHistory {
  internalIdentifier: number;
  derivativeCode: string;
  specialEquipment: SpecialEquipment;
  type: Type;
  price: Money;
  packet: Packet;
  transferPrice: TransferPrice;
  validFrom: string;
  releaseDate: string;
  operationId: number;
  operationName: string;
  previousManufacturingCosts: ManufacturingCost[];
  manufacturingCostStatus: string;
  costs: {};
}

export class TransferPrice {
  internalIdentifier: number;
  royaltiesIndicator: RoyaltiesIndicator;
  transferCategoryCost: TransferCategoryCost[];
}

export class TransferCategoryCost {
  adjustmentReason: AdjustmentReason;
  amount: Money;
  categoryCost: CategoryCost;
  internalIdentifier: number;
}

export class CategoryCost {
  groupCost: GroupCost;
  internalIdentifier: number;
  name: string;
}

export class GroupCost {
  code: number;
  internalIdentifier: number;
  name: string;
}

export class ColorEquip {
  colorCode: string;
  internalIdentifier: number;
  name: string;
  optionalValidity: DateRange;
}

export class AdjustmentReason {
  changeType: ChangeType;
  internalIdentifier: number;
  name: string;
}

export class ChangeType {
  internalIdentifier: number;
  name: string;
}

export class DateRange {
  fromDate: string;
  toDate: string;
}

export class Type {
  axlGear?: string;
  capacity?: number;
  code?: string;
  country?: string;
  cylinder?:number;
  engineName?: string;
  engineSeries?: string;
  equipBase?: string;
  fuel?: string;
  internalIdentifier?: number;
  name?: string;
  steering?: string;
  typeImportStatus?: ImportStatus;
  validity?: DateRange;
  productionType?: ProductionType;
  rulesHistory?: RuleHistory[];
}

export class FKHistory {
  internalIdentifier: number;
  derivativeCode: string;
  specialEquipment: SpecialEquipment;
  type: Type;
  price: Money;
  packet: Packet;
  transferPrice: TransferPrice;
  validFrom: string;
  releaseDate: string;
  operationId: number;
  operationName: string;
  f2ValidFrom:string;
  previousManufacturingCosts: ManufacturingCost[];
  manufacturingCostStatus: string;
  costs: {};
  vpbOn: string;
}

export interface UploadFile {
  internalIdentifier?: number;
  name?: string;
  content?: string;
}

export interface FileList {
  files?: Array<UploadFile>;
  property?: string;
}

export enum RoyaltiesIndicator {
  ALWAYS='Y',
  SOMETIMES='Y/N',
  NEVER='N'
}

export enum Status {
  NEW='NEW',
  ACTIVE='ACTIVE',
  OVERWRITTEN='OVERWRITTEN'
}

export enum WorkflowAction {
  REVIEW = 'review',
  IN_RELEASE = 'in-release',
  RELEASE = 'release',
  RESET = 'reset',
  CANCEL = 'cancel',
  DECLINE = 'decline'
}
