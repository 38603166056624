import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridApi} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {VehicleStatusGridColumns} from './vehicle-status-grid.columns';
import {AgGridBase} from '../../../../ag.grid.base';
import {VehicleInformationCellRenderer} from "./vehicle-information-cell-renderer.component";
import {SpecialEquipment, VehicleStatus} from "../../model/vehicle.model";
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-vehicle-status-grid',
  templateUrl: './vehicle-status-grid.component.html',
  styleUrls: ['./vehicle-status-grid.component.scss']
})
export class VehicleStatusGridComponent extends AgGridBase<VehicleStatusGridColumns> {
  gridApi: GridApi;
  filtering = false;
  frameworkComponents: any;
  dialogTitle: String;
  @Input() hasReleaseVehicleRole: boolean;
  isRowSelectedForReCalculate: boolean = false;
  @Output() reCalculateVehicle = new EventEmitter<VehicleStatus>();
  @Output() vehicleSelected = new EventEmitter<any>();
  selected: VehicleStatus;
  rowSelection = 'single';
  isRowSelected: boolean = false;
  statusForRecalculate = ["BLOCKED_MISSING_PRICE"];
  years: any = [];
  months: any = [];
  yearToFilter: string;
  monthToFilter: string;
  @Output() refresh = new EventEmitter<any>();
  yearForm: FormGroup;

  constructor(private translate: TranslateService) {
    super(new VehicleStatusGridColumns(translate));
    this.frameworkComponents = {
      dialogueComponent: VehicleInformationCellRenderer
    };
    this.dialogTitle = this.translate.instant('menu.vehicle-status.information-title');
    this.yearToFilter = new Date().getFullYear().toString();
    this.monthToFilter = (new Date().getMonth() + 1).toString();
    this.initPickers();
    this.years = this.getYears();
    this.months = this.getMonths();
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'derivate', sort: 'asc'},
      {colId: 'order', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
  }


  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onInformationClose() {
    this.suppliedColumns.showVehicleInformation = false;
    this.suppliedColumns.specialEquipments = new Array<SpecialEquipment>();
  }
  onClearFilters(){
    this.gridApi.setFilterModel(null);
  }

  highlight(productStatus: string) {
    return productStatus !== 'ACTIVE';
  }

  onReCalculateVehicle() {
    this.selected.year = this.yearToFilter;
    this.selected.month = this.monthToFilter;
    this.reCalculateVehicle.emit(this.selected);
  }

  onRowSelected(): void {
    this.isRowSelectedForReCalculate = false;
    this.isRowSelected = this.gridApi.getSelectedRows().length === 1;
    this.selected = this.isRowSelected ? this.gridApi.getSelectedRows()[0] : undefined;
    if (this.selected) {
      this.isRowSelectedForReCalculate = this.statusForRecalculate.includes(this.selected.vehicleStatus);
      this.vehicleSelected.emit(this.selected);
    } else {
      this.vehicleSelected.emit(undefined);
    }
  }

  filterRecordsByYear(year: string) {
    if (year == null) return;
    this.yearToFilter = year;
    this.refresh.emit([this.yearToFilter, this.monthToFilter]);
  }

  filterRecordsByMonth(month: string) {
    if (month == null) this.refresh.emit([this.yearToFilter]);
    this.monthToFilter = month;
    this.refresh.emit([this.yearToFilter, this.monthToFilter]);
  }

  initPickers = () => {
    this.yearForm = new FormGroup({
      vehicleYear: new FormControl(this.yearToFilter),
      vehicleMonth: new FormControl(this.monthToFilter)
    });
  }

  getYears(){
    let maxYear = new Date().getFullYear(), years = [];
    let minYear = 2022;
    for(let i = maxYear ; i >= minYear; i--) {
      years.push(i.toString());
    }
    return years;
  }

  getMonths(){
    let maxMonth = 12, months = [];
    let minMonth = 1;
    for(let i = minMonth ; i <= maxMonth; i++) {
      months.push(i.toString());
    }
    return months;
  }
}
