import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {OperationService} from "../service/operation.service";
import {Operation, OperationChangeInput} from "../model/operations.model";
import {format} from "date-fns";

@Component({
  selector: 'app-edit-operation',
  templateUrl: './edit-operation.component.html',
  styleUrls: ['./edit-operation.component.scss']
})
export class EditOperationComponent implements OnInit {

  editOperationForm: FormGroup;
  loadingScreen: boolean = false;
  errorMessage: string;
  minDate: Date = new Date();

  @Input() type: string;
  @Input() public submitFn;
  @Input() public cancelFn;
  @Input() operation: Operation;


  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private service: OperationService) {
  }

  ngOnInit(): void {
    this.editOperationForm = this.formBuilder.group({
      operationName: [this.operation.name, Validators.required],
      validFrom: [new Date(this.operation.validFrom), [Validators.required]],
      comment: ['']
    });
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  onSubmit() {
    if (this.editOperationForm.valid) {
      this.loadingScreen = true;
      try {
        this.editOperationForm.markAllAsTouched();
        if (this.editOperationForm.valid) {
          let operation = this.getUpdateModel();
          this.service.updateOperation(operation).subscribe(({data}) => {
            this.loadingScreen = false;
            this.cancelEditOperation();
          }, error => {
            this.loadingScreen = false;
            this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
          });
        }
      } catch (exception) {
        this.errorMessage = exception.toString();
        this.loadingScreen = false;
      }
    }
    else {
      this.errorMessage = "All field are required";
    }
  }

  getUpdateModel() {
    if (this.editOperationForm.valid) {
      let model = new OperationChangeInput();
      model.name = this.f.operationName.value;
      model.validFrom = format(this.f.validFrom.value, 'yyyy-MM-dd');
      model.internalIdentifier = this.operation.internalIdentifier;
      return model;
    }
    return new OperationChangeInput();
  }

  cancelEditOperation() {
    this.cancelFn();
  }

  getOperationModel() {
    if (this.editOperationForm.valid) {
      let model = new Operation();
      model.name = this.f.operationName.value;
      return model;
    }
    return new Operation();
  }

  get f() {
    return this.editOperationForm.controls;
  }

}
