<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.pricing.manual-prices.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <bmw-content-tab>
        <bmw-content-tab-label
          for="a">{{ 'menu.pricing.manual-prices.over-view.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="a">
          <div class="table-wrapper bmw__margin-top__s">
            <div>
              <bmw-ag-grid-theme>
                <div *ngIf="errorMessage"
                  class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
                  <bmw-inline-message type="error">
                    {{errorMessage}}
                  </bmw-inline-message>
                </div>
                <div *ngIf="!errorMessage" class="bmw__margin-top__xs">
                  <div class="bmw__margin-top__xs">
                    <app-manual-prices-grid
                      [rowData]="rowData"
                    ></app-manual-prices-grid>
                  </div>
                </div>
              </bmw-ag-grid-theme>
            </div>
          </div>
        </bmw-content-tab-content>
      </bmw-content-tab>
    </div>
  </div>
</div>
