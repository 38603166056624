import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../../shared/grid.column.definition";
import {EMPTY_STRING, isUndefinedOrEmpty} from "../../../../../shared/functions/typescript.utils";
import {ICellRendererParams} from "ag-grid-community";
import {RoyaltiesIndicator} from "../../../model/operations.model";

export class CostAllocationsSaGridColumns extends GridColumnBase implements GridColumnDefinition {
  translate: TranslateService;
  valueChanged = new Map();
  profitMap = new Map();
  royaltiesMap = new Map();
  deltaPriceMap = new Map();

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: 'hasCapturerRole',
      hide: true,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'whenOperationIsUnderEvaluation',
      hide: true,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'derivative',
      headerName: this.translate.instant('derivative'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },
    {
      field: 'desigtype',
      headerName: this.translate.instant('desigtype'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },
    {
      field: 'saCode',
      headerName: this.translate.instant('saCode'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },
    {
      field: 'description',
      headerName: this.translate.instant('description'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },{
      field: 'includeRoyalties',
      headerName: this.translate.instant('includeRoyalties'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },
    {
      field: 'tpOld',
      headerName: this.translate.instant('tpOld'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
    },
    {
      field: 'changeon',
      headerName: this.translate.instant('changeon'),
      headerValueGetter: this.localizeHeader.bind(this),
      children: [{
        field: 'techChange',
        headerName: this.translate.instant('techChange'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resizable: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        cellEditor: 'numericEditorComponent',
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        type: 'rightAligned',
      }, {
        field: 'premises',
        headerName: this.translate.instant('premises'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }, {
        field: 'volumeChange',
        headerName: this.translate.instant('volumeChange'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }, {
        field: 'bmwOther',
        headerName: this.translate.instant('bmwOther'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }]
    }, {
      field: 'changesal',
      headerName: this.translate.instant('changesal'),
      headerValueGetter: this.localizeHeader.bind(this),
      children: [{
        field: 'fivePercent',
        headerName: this.translate.instant('fivePercent'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }, {
        field: 'effect',
        headerName: this.translate.instant('effect'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }, {
        field: 'salOther',
        headerName: this.translate.instant('salOther'),
        headerValueGetter: this.localizeHeader.bind(this),
        sortable: true,
        filter: true,
        resize: true,
        editable: true,
        enableValue: true,
        valueFormatter: this.moneyFormatter,
        valueSetter: this.valueSetter.bind(this),
        valueGetter: this.valueGetter.bind(this),
        cellEditor: 'numericEditorComponent',
        type: 'rightAligned',
      }]
    },{
      field: 'royalties',
      headerName: this.translate.instant('royalties'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueSetter: this.valueSetter.bind(this),
      valueGetter: this.royaltiesCalculation.bind(this),
      editable: true,
      cellEditor: 'numericEditorComponent',
      type: 'rightAligned',
    },{
      field: 'profit',
      headerName: this.translate.instant('profit'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueGetter: this.profitCalculation.bind(this),
      editable: true,
      cellEditor: 'numericEditorComponent',
      type: 'rightAligned',
    },{
      field: 'tpNew',
      headerName: this.translate.instant('tpNew'),
      headerValueGetter: this.localizeHeader.bind(this),
      sortable: true,
      filter: true,
      resize: true,
      valueFormatter: this.moneyFormatter,
      valueGetter: this.newTransferPriceCalculation.bind(this),
      type: 'rightAligned',
    },
    {
      headerName: "categoryId",
      field: "categoryId",
      width: 20,
      hide: true,
      suppressToolPanel: true
    },
    {
      headerName: "priceId",
      field: "priceId",
      width: 20,
      hide: true,
      suppressToolPanel: true
    }

  ];

  public moneyFormatter(params): string {
    if (isUndefinedOrEmpty(params.value))
      return EMPTY_STRING;

    let value: string = EMPTY_STRING;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  royaltiesCalculation(params): number {
    let total;
    let royaltiesTotal;
    if (params.data) {
      if (params.data.saCode !== '08AA') {
        let technicalChange;
        let bmwOther;
        let effect;
        let fivePercent;
        let premises;
        let salOther;
        let volumeChange;
        technicalChange = params.data.techChange;
        bmwOther = params.data.bmwOther;
        effect = params.data.effect;
        fivePercent = params.data.fivePercent;
        premises = params.data.premises;
        salOther = params.data.salOther;
        volumeChange = params.data.volumeChange;
        total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange;
        if (params.data.includeRoyalties === RoyaltiesIndicator.NEVER) {
          if (params.data.royalties !== 0) {
            royaltiesTotal = params.data.royalties;
          } else {
            royaltiesTotal = 0;
          }
        } else {
          royaltiesTotal = (total + this.profitCalculation(params)) * 0.03;
        }
      } else if (params.data.saCode === '08AA' && params.data.includeRoyalties === RoyaltiesIndicator.SOMETIMES) {
        royaltiesTotal = params.data.royalties;
      } else {
        royaltiesTotal = +params.data['royalties'];
      }
      this.royaltiesMap.set(params.data.priceId, royaltiesTotal);
    }

    return royaltiesTotal;
  }

  valueGetter(params): number {
    if(params.data){
      if (params.data[params.colDef.field] === undefined) {
        params.data[params.colDef.field] = 0;
      }
      return params.data[params.colDef.field];
    }
  }

  valueSetter(params) {
    if (params.oldValue !== +params.newValue) {
      this.valueChanged.set(params.data.priceId, true);
      params.data[params.colDef.field] = +params.newValue;
      return true;
    }
    return false;
  }

  profitCalculation(params): number {
    let total:number = 0;
    let profitTotal:number = 0;
    if (params.data) {
      if (params.data['saCode'] !== '08AA') {
        let technicalChange = params.data['techChange'];
        let bmwOther = params.data['bmwOther'];
        let effect = params.data['effect'];
        let fivePercent = params.data['fivePercent'];
        let premises = params.data['premises'];
        let salOther = params.data['salOther'];
        let volumeChange = params.data['volumeChange'];
        total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange;
        profitTotal = total * 0.05;
        this.profitMap.set(params.data.priceId,profitTotal);
      } else {
        profitTotal = +params.data['profit'];
        this.profitMap.set(params.data.priceId,profitTotal);
      }
    }
    return profitTotal;
  }

  newTransferPriceCalculation(params): number {
    let total:number = 0;
    let profitTotal:number = 0;
    let royaltiesTotal:number = 0;
    if(params.data) {
      let technicalChange = params.data['techChange'];
      let bmwOther = params.data['bmwOther'];
      let effect = params.data['effect'];
      let fivePercent = params.data['fivePercent'];
      let premises = params.data['premises'];
      let salOther = params.data['salOther'];
      let volumeChange = params.data['volumeChange'];
      let transferPriceOld = params.data['tpOld'];
      profitTotal = this.profitMap.get(params.data.priceId);
      royaltiesTotal = this.royaltiesMap.get(params.data.priceId);
      total = technicalChange + bmwOther + effect + fivePercent + premises + salOther + volumeChange + profitTotal + royaltiesTotal;
      this.deltaPriceMap.set(params.data.priceId, total);
      total += transferPriceOld;
    }
    return total;
  }
}
