import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MockingService {
  constructor(private http: HttpClient) {
  }

  getFreezePriceSummary(): Observable<any> {
    return this.http.get('./assets/pricesummary.json')
  }

  getFreezePriceDetails(): Observable<any> {
    return this.http.get('./assets/pricedetails.json')
  }
}
