import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ServiceBase } from "../../../services/service.base";
import { faas } from "../../../constants/faas.constants";
import { from } from 'rxjs';
import { Plant } from '../../order/model/order.model';
import { ManufacturingReportsTransformer } from '../service/manufacturing-reports-transformer';
import {
  MANUFACTURING_REPORT_PRODUCTS_QUERY,
  MANUFACTURING_REPORT_DERIVATIVE_TYPE_QUERY,
  TRANSFER_PRICE_TRANSITION_PER_MODEL_TYPE_BY_PLANT,
  MANUFACTURING_REPORT_EXCHANGE_RATES_QUERY,
  MANUFACTURING_REPORT_EXCHANGE_RATES_YEARS_FOR_PLANT,
  ManufacturingReportProductsQueryResponse,
  ManufacturingReportTypesQueryResponse,
  TransferPriceTransitionPerModelTypeByPlantQueryReportResponse,
  ManufacturingReportExchangeRatesQueryResponse,
  ManufacturingReportExchangeRatesYearsForPlantQueryResponse,
  DateRangeQueryResponse, RETRIEVE_DATE_RANGE_QUERY, DOWNLOAD, Download,
} from "../manufacturing-reports/manufacturing-reports.graphql.query";
import {
  ALL_MANUFACTURING_COSTS_BY_PLANT_REPORT, ManufacturingCostHistoryQueryReportResponse,
  TRANSFER_PRICE_PER_MODEL_TYPE_BY_PLANT, TransferPricePerModelTypeByPlantQueryReportResponse,
  RETRIEVE_PRODUCT_COLUMNS_BY_PLANT, RetrieveProductColumnsByPlantQueryReportResponse
} from "../manufacturing-reports/valid-transfer-price-dynamic/valid-transfer-price.graphql.query";

import {
  MANUFACTURING_REPORT_PAID_TRANSFERPRICE, ManufacturingReportPaidTransferPriceQueryResponse
} from "../manufacturing-reports/manufacturing-reports.graphql.query";

import {
  MANUFACTURING_REPORT_ACCUMULATED_PAID_TRANSFERPRICE, ManufacturingReportAccumulatedPaidTransferPriceQueryResponse
} from '../manufacturing-reports/manufacturing-reports.graphql.query';



@Injectable({
  providedIn: 'root'
})
export class ManufacturingReportsService extends ServiceBase {
  manufacturingReportsTransformer: ManufacturingReportsTransformer;

  constructor(apollo: Apollo) {
    super(apollo);
    this.manufacturingReportsTransformer = new ManufacturingReportsTransformer();
  }

  getProductDerivatives() {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      isActive: true
    };
    return this.query<ManufacturingReportProductsQueryResponse>(variable, MANUFACTURING_REPORT_PRODUCTS_QUERY, faas.productServiceClientName);
  }

  typesForDerivative(derivatives: any){
    const variable = {
      plant: Plant[window.sessionStorage.client],
      derivative: derivatives
    };
    return this.query<ManufacturingReportTypesQueryResponse>(variable, MANUFACTURING_REPORT_DERIVATIVE_TYPE_QUERY, faas.productServiceClientName);
  }

  getDynamicColumns() {
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<RetrieveProductColumnsByPlantQueryReportResponse>(variable, RETRIEVE_PRODUCT_COLUMNS_BY_PLANT, faas.reportServiceClientName);
  }

  allManufacturingCosts() {
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<ManufacturingCostHistoryQueryReportResponse>(variable, ALL_MANUFACTURING_COSTS_BY_PLANT_REPORT, faas.productServiceClientName);
  }

  transferPriceTransitionPerModelTypeByPlant(){
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<TransferPriceTransitionPerModelTypeByPlantQueryReportResponse>(variable, TRANSFER_PRICE_TRANSITION_PER_MODEL_TYPE_BY_PLANT, faas.productServiceClientName);
  }

  transferPricePerModelTypeByPlant(){
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<TransferPricePerModelTypeByPlantQueryReportResponse>(variable, TRANSFER_PRICE_PER_MODEL_TYPE_BY_PLANT, faas.productServiceClientName);
  }

  paidTransferPrice(derivatives: string[], types: string[], validFrom: Date, validTo: Date) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      derivatives,
      types,
      validFrom: validFrom === undefined ? null : this.formatDate(validFrom),
      validTo: validTo === undefined ? null :  this.formatDate(validTo)
    };
    return this.query<ManufacturingReportPaidTransferPriceQueryResponse>(variable, MANUFACTURING_REPORT_PAID_TRANSFERPRICE,
      faas.reportServiceClientName);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  download(derivatives: string[], types: string[], validFrom: Date, validTo: Date) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      derivatives,
      types,
      validFrom: validFrom === undefined ? null : this.formatDate(validFrom),
      validTo: validTo === undefined ? null : this.formatDate(validTo)
    };
    return this.query<Download>(variable, DOWNLOAD,
      faas.reportServiceClientName);
  }

  accumulatedPaidTransferPrice(derivatives: string[], types: string[], validFrom: Date, validTo: Date) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      derivatives,
      types,
      validFrom: validFrom === undefined ? null : this.formatDate(validFrom),
      validTo: validTo === undefined ? null : this.formatDate(validTo)
    };
    return this.query<ManufacturingReportAccumulatedPaidTransferPriceQueryResponse>(variable,
      MANUFACTURING_REPORT_ACCUMULATED_PAID_TRANSFERPRICE, faas.reportServiceClientName);
  }

  dateRange(validFrom: Date, validTo: Date) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      validFrom: validFrom === undefined ? null : this.formatDate(validFrom),
      validTo: validTo === undefined ? null : this.formatDate(validTo)
    };
    return this.query<DateRangeQueryResponse>(variable,
      RETRIEVE_DATE_RANGE_QUERY, faas.reportServiceClientName);
  }

  getExchangeRatesPerPlantAndYear(year: number){
    const variable = {
      plant:Plant[window.sessionStorage.client],
      year: year,
    };
    return this.query<ManufacturingReportExchangeRatesQueryResponse>(variable, MANUFACTURING_REPORT_EXCHANGE_RATES_QUERY, faas.orderServiceClientName);
  }

  getExchangeRatesYearsForPlant() {
    const variable = {
      plant:Plant[window.sessionStorage.client]
    };
    return this.query<ManufacturingReportExchangeRatesYearsForPlantQueryResponse>(variable, MANUFACTURING_REPORT_EXCHANGE_RATES_YEARS_FOR_PLANT, faas.orderServiceClientName);
  }

  dispose(): void {
  }
}
