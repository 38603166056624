<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'period-end-closing.aggregation.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <bmw-content-tab>
        <bmw-content-tab-label
          for="a">{{ 'period-end-closing.aggregation.overview' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="a">
          <app-aggregation-update-price></app-aggregation-update-price>
        </bmw-content-tab-content>
      </bmw-content-tab>
    </div>
  </div>
</div>
