import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrdersViewModel} from "../model/OrdersViewModel";
import {OrderService} from "../service/order.service";
import {APPROVE_ORDER, REJECT_ORDER, UPDATE_ORDER} from "../service/order.mutations";


@Component({
  selector: 'app-order-detail-view',
  templateUrl: './order-detail-view.component.html',
  styleUrls: ['./order-detail-view.component.scss']
})
export class OrderDetailViewComponent implements OnInit {

  loadingScreen = false;
  errorMessage: string;


  @Input() pendingOrderHeader: OrdersViewModel;
  @Input() originalOrder: OrdersViewModel;
  @Input() pendingOrders: OrdersViewModel[];
  @Input() rowData: any;
  @Input() hasNoOrderManagementRole: boolean;

  @Output() onApproveReject = new EventEmitter<boolean>();
  @Output() onCancel = new EventEmitter<boolean>();


  constructor(private orderService: OrderService) { }

  ngOnInit(): void {
  }

  onReject() {
    this.execute(REJECT_ORDER);
  }

  onApprove() {
    this.execute(APPROVE_ORDER);
  }

  execute(mutationPath) {
    this.loadingScreen = true;
    this.orderService._apollo.mutate({
      mutation: mutationPath,
      variables:  {
        orderInput: this.orderService.getOrderChangeInputFromOrder(this.originalOrder,[])
      }
    }).subscribe(({ data }) => {
      this.loadingScreen = false;
      this.onApproveReject.emit(true);
    },(error) => {
      this.errorMessage = this.orderService.removeGraphQLErrorOnMessage(error.message);
    });
  }

  cancel() {
    this.onCancel.emit(true);
  }

}
