import gql from 'graphql-tag';
import {OperationSummary} from './model/operations.model';

export const RETRIEVE_OPERATIONS_TO_BE_ACTIONED_COUNT = gql`
  query manufacturingCostsToBeActioned($plant: Plant!) {
    manufacturingCostsToBeActioned(plant: $plant) {
      internalIdentifier
      }
    }`;

export interface OperationsCountResponse {
  manufacturingCostsToBeActioned: OperationSummary[];
}
