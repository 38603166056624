import {PriceComponentData} from "../../../model/continous.price.model";
import {PriceUnit} from "../../../../order/model/order.model";
import moment from "moment";
import {Transformer} from "../../../../../helper/transformer";

export class PriceComponentOrder extends Transformer {

  private priceComponents: PriceComponentData[];
  private internalIdentifier: number;
  private fromDate: string;
  private toDate: string;
  private basePriceValue: string;
  private basePriceCurrency: string;
  private nettPriceValue: string;
  private nettPriceCurrency: string;
  private priceUnit: string;
  private orderNumber: string;
  private version: number;
  private state: string;
  private supplierNumber: string;
  private printDate: string;
  private quantityUnit: string;
  private orderType: string;

  constructor(priceComponents: PriceComponentData[]) {
    super();
    this.priceComponents = priceComponents;
  }

  ordersForPriceComponent = () => {
    let orders = this.priceComponents.map(o => o.order);
    let transformedOrders = this.transformOrders(orders);
    let orderList: PriceComponentOrder[] = [];
    let orderViewModel = {...this};

    transformedOrders.forEach(order => {
      orderViewModel.internalIdentifier = order.internalIdentifier;
      orderViewModel.fromDate = this.dateFormatter(order.fromDate);
      orderViewModel.toDate = this.dateFormatter(order.toDate);
      orderViewModel.basePriceValue = order.basePriceValue;
      orderViewModel.basePriceCurrency = order.basePriceCurrency;
      orderViewModel.nettPriceValue = order.nettPriceValue;
      orderViewModel.nettPriceCurrency = order.nettPriceCurrency;
      orderViewModel.orderNumber = order.orderNo;
      orderViewModel.state = order.orderState;
      orderViewModel.supplierNumber = order.supplierNo;
      orderViewModel.printDate = order.changeDate;
      orderViewModel.version = order.version;
      orderViewModel.orderType = order.orderType;
      orderViewModel.quantityUnit = order.quantityUnit;
      orderViewModel.priceUnit = PriceUnit[order.priceUnit] == undefined ? "" : PriceUnit[order.priceUnit];
      orderList.push(orderViewModel);
      orderViewModel = {...this};
    });
    return orderList.sort( (a, b) => b.orderNumber.localeCompare(a.orderNumber));
  }

  dateFormatter(params) {
    return moment(params).format('DD.MM.YYYY');
  }

}
