<form [formGroup]="priceSearchForm">
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <bmw-fieldset opened="true">
      <bmw-fieldset-header>{{ 'component.search-bar.search-criteria' | translate }}</bmw-fieldset-header>
      <bmw-fieldset-container>
        <div class="bmw__grid__inner">
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield id="snr">
              <bmw-label>{{ 'component.search-bar.snr' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                style="text-transform: uppercase;"
                maxlength="7"
                formControlName="snr"
                data-e2e="snr"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-inputfield id="ai">
              <bmw-label>{{ 'component.search-bar.ai' | translate }}</bmw-label>
              <input
                bmwInput
                type="text"
                name="ai"
                maxlength="2"
                formControlName="ai"
              />
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-inputfield>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
            <bmw-label>{{ 'component.search-bar.knowledgeDate' | translate }}</bmw-label>
            <bmw-datepicker
              #datepicker
              required="true"
              placeholder="DD.MM.YYYY"
              dateFormat="dd.mm.yy"
              showButtonBar="true"
              [hideOnDateTimeSelect]="true"
              (onClickOutside)="setDate(datepicker)"
              (onSelect)="onDateSelect($event)"
              [(ngModel)]="inputDate"
              [ngModelOptions]="{standalone: true}"
              showWeek="true">
              <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
            </bmw-datepicker>
          </div>
          <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
            <bmw-button button="primary" type="submit" (clickEvent)="search()" >
              {{ 'component.search-bar.search' | translate }}
            </bmw-button>
          </div>
        </div>
      </bmw-fieldset-container>
    </bmw-fieldset>
  </div>
</form>

