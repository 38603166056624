import {Plant} from "../../order/model/order.model";


export class Derivative {
  internalIdentifier: number;
  code: string;
  costCentre: number;
  plant: Plant;
  types: Type[];
  derivativeImportStatus:ImportStatus;
}

export class Product {
  internalIdentifier: number;
  plant: Plant;
  derivative: Derivative;
  validity: DateRange;
}

export class Packet {
  internalIdentifier: number;
  code: string;
  name: string;
  validity: DateRange;
  generation: number;
  sequence: number;
  packetImportStatus: ImportStatus;
}

export class DateRange {
  fromDate: string;
  toDate: string;
}

export class ColorEquip {
  internalIdentifier: number;
  colorCode: string;
  name: string;
  optionalValidity: DateRange;
}

export class SpecialEquipment {
  internalIdentifier: number;
  code: string;
  name: string;
  equipment: string;
  type: string;
  colorEquip: ColorEquip;
  specialEquipmentImportStatus: ImportStatus;
  validity: DateRange;
}

export class ProductionType {
  productionTypeCode: ProductionTypeCode;
  productionTypeImportStatus: ImportStatus;
}

export class BuildType {
  buildTypeCode: BuildTypeCode;
  buildTypeImportStatus: ImportStatus;
}

export class ProductDataStatus {
  productDataStatusCode: ProductDataStatusCode;
  productDataImportStatus: ImportStatus;
  productDataStatusCodeNo: number;
}

export class Type {
  internalIdentifier: number;
  code: string;
  name: string;
  country: string;
  fuel: string;
  steering: string;
  cylinder: number;
  capacity: number;
  productionType: ProductionType;
  axlGear: string;
  engineSeries: string;
  engineName: string;
  validity: DateRange;
  equipBase: string;
  buildType: BuildType;
  packets: Packet[];
  typeImportStatus: ImportStatus;
  specialEquipment: SpecialEquipment[];
  productDataStatus: ProductDataStatus;
  inWorkflow?: boolean;
}

export class ProductConfiguration {
  productDataStatuses: ProductDataStatus[];
  productionTypes: ProductionType[];
  buildTypes: BuildType[];
}

export enum ProductDataStatusCode {
  MATURE_PRODUCTION = 30,
  PRODUCTIVE = 50,
  INACTIVE = 80,
  CANCELLED = 99
}

export enum ProductionTypeCode {
  SKD = "SKD",
  CKD = "CKD",
  CBU = "CBU"
}

export enum BuildTypeCode {
  OPTION = "OPTION",
  SERIE = "SERIE",
  TYPSERIE = "TYPSERIE"
}

export enum ImportStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE"
}

export class ProductConfigurationChangeInput {
  inactivate: boolean;
  validity: DateRange;
  derivatives: Derivative[];
  types: Type[];
  specialEquipments: SpecialEquipment[];
  packets: Packet[];
  productDataStatuses: ProductDataStatus[];
  productionTypes: ProductionType[];
  buildTypes: BuildType[];
  plant: Plant;
}

export class ProductConfigurationViewModel {
  datesSelected: {};
  selectedDerivatives: any[];
  derivativeList: any[];
  selectedTypes: any[];
  typeList: any[];
  selectedSpecialEquipments: any[];
  specialEquipmentList: any[];
  selectedProductDataStatuses: any[];
  productDataStatusList: any[];
  selectedProductionTypes: any[];
  productionTypeList: any[];
  selectedBuildTypes: any[];
  buildTypeList: any[];
}

export class ProductViewHolder {
  prod_satype: string;
  prod_typecode: string;
  prod_sacode: string;
  prod_said: number;
}
