<div class="bmw__grid__inner">
  <div class="bmw__grid__cell--span-12">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell--span-5">
        <p data-testid="text-snr" class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.continuous-prices.overview.snr' | translate }}
          :</b> {{baseOrder.material.snr}}-{{baseOrder.material.ai | aiToString | uppercase }}</p>
      </div>
    </div>
  </div>
  <div class="bmw__grid__cell--span-12">
    <bmw-ag-grid-theme>
      <app-bmw-grid
        [rowData]="orderRowData"
        [columns]="orderColumns">
      </app-bmw-grid>
    </bmw-ag-grid-theme>
  </div>
</div>
<div class="bmw__margin-top__l"></div>
<bmw-fieldset data-testid="fieldset-hideExchangeRates" [opened]="true" [hidden]="hideExchangeRates">
  <bmw-fieldset-header (clickEmit)="reDrawSurchargeGrid($event)" (click)="reDrawSurchargeGrid($event)">{{ 'menu.pricing.continuous-prices.overview.exchange-rate' | translate }}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <app-price-exchange-rate [priceComponents]="priceComponents" [resize]="resizeSurchargeGrid"></app-price-exchange-rate>
  </bmw-fieldset-container>
</bmw-fieldset>
<div class="bmw__margin-top__m"></div>
<bmw-fieldset data-testid="fieldset-hideWeightings" [opened]="true" [hidden]="hideWeightings">
  <bmw-fieldset-header (clickEmit)="reDrawSurchargeGrid($event)" (click)="reDrawSurchargeGrid($event)">{{ 'menu.pricing.continuous-prices.overview.weight' | translate }}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <app-price-weight [priceComponents]="priceComponents" [resize]="resizeSurchargeGrid"></app-price-weight>
  </bmw-fieldset-container>
</bmw-fieldset>
<div class="bmw__margin-top__m"></div>
<bmw-fieldset [opened]="true" [hidden]="hideSurcharges" >
  <bmw-fieldset-header (clickEmit)="reDrawSurchargeGrid($event)" (click)="reDrawSurchargeGrid($event)">{{ 'menu.pricing.continuous-prices.overview.surcharge-heading' | translate }}</bmw-fieldset-header>
  <bmw-fieldset-container>
    <bmw-ag-grid-theme>
      <app-bmw-grid
        [rowData]="surchargeData"
        [columns]="surchargeColumns"
        [redraw]="resizeSurchargeGrid">
      </app-bmw-grid>
    </bmw-ag-grid-theme>
  </bmw-fieldset-container>
</bmw-fieldset>

