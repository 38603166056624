import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {OperationDetailsGridColumns} from './operation-details-grid.columns';
import {AgGridBase} from '../../../../ag.grid.base';
import {ManufacturingCost, Money} from '../../model/operations.model';
import {OperationService} from '../../service/operation.service';

@Component({
  selector: 'app-operation-details-grid',
  templateUrl: './operation-details-grid.component.html',
  styleUrls: ['./operation-details-grid.component.scss']
})
export class OperationDetailsGridComponent extends AgGridBase<OperationDetailsGridColumns> {
  rowSelection = 'multiple';

  @Input() operationNotReadyForReview: boolean;
  @Input() operationCloned: boolean;
  @Input() toReviewAndCancelDisabled: boolean;
  @Input() toInReleaseAndResetDisabled: boolean;
  @Input() releaseAndDeclineDisabled: boolean;
  @Input() editSelectionDisabled: boolean;
  @Input() saveDisabled: boolean;
  @Input() whenOperationIsUnderEvaluation: boolean;
  @Input() isSystemsOperation: boolean;
  @Input() enableDelete: boolean;
  @Input() shouldShowGrid: boolean;
  @Input() hasReviewerRole: boolean;
  @Input() hasCaptureRole: boolean;
  @Input() hasReleaseRole: boolean;
  @Input() isMsfOrNedcar: boolean;

  @Output() refresh = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Output() allocate = new EventEmitter<any>();
  @Output() rules = new EventEmitter<any>();
  @Output() allocatemsg = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() workflow = new EventEmitter<string>();
  @Output() reset = new EventEmitter();
  @Output() approverRelease = new EventEmitter();
  @Output() reviewerRelease = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() addTypeSA = new EventEmitter();
  @Output() removeTypeSA = new EventEmitter();
  @Output() editSelection = new EventEmitter<ManufacturingCost[]>();

  allocateDisabled = true;
  rulesDisabled = false;
  removeDisabled = true;
  selected: ManufacturingCost[] = [];
  isRowSelected: boolean;

  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;



  constructor(private operationService: OperationService, translate: TranslateService) {
    super(new OperationDetailsGridColumns(translate));
    this.isRowSelected = false;
  }


  onGridReady(params): void {
    super.onGridReady(params);
    const sort = [
      {
        colId: 'derivate',
        sort: 'asc',
      },
      {
        colId: 'type',
        sort: 'asc',
      },
      {
        colId: 'saCode',
        sort: 'asc',
      },

    ];
    this.gridApi.setSortModel(sort);
  }

  onRowSelected(): void {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.isRowSelected = true;
      this.selected = [];
      this.gridApi.getSelectedRows().forEach(row => {
        this.selected.push(row);
      });
      this.allocateDisabled = this.hasType() && this.hasSA();
      this.removeDisabled = this.whenOperationIsUnderEvaluation || this.isSystemsOperation;
    } else {
      this.removeDisabled = true;
      this.isRowSelected = false;
      this.selected = [];
      return;
    }
  }

  hasType(): boolean {
    for (let manufacturingCost of this.selected) {
      if(manufacturingCost['saType'] === 'Type') {
        return true;
        break;
      }
    }
    return false;
  }

  hasSA(): boolean {
    for (let manufacturingCost of this.selected) {
      if (manufacturingCost['saType'] != 'Type') {
        return true;
        break;
      }
    };
    return false;
  }

  onColumnRowGroupChanged(params) {
    this.gridApi.expandAll();
  }

  onLoadData() {
    this.refresh.emit();
  }

  onUpload() {
    this.upload.emit();
  }

  onAllocate() {
    this.allocate.emit(this.selected);
  }

  onRules() {
    this.rules.emit(this.selected);
  }

  onWorkflow(action: string) {
    this.workflow.emit(action);
  }

  onEdit() {
    this.edit.emit();
  }

  onAddTypeSA() {
    this.addTypeSA.emit();
  }

  onRemoveTypeSA() {
    this.removeDisabled = true;
    this.removeTypeSA.emit(this.selected);
  }

  onEditSelection() {
    if (this.gridApi.getSelectedRows()?.length === 0) {
      return;
    }

    let operations: ManufacturingCost[] = [];
    this.gridApi.getSelectedRows().forEach(params => {
      let holder = new ManufacturingCost();
      holder.internalIdentifier = params.priceId;
      holder.price = new Money();
      holder.price.value = params.fkNew;
      operations.push(holder);
    });
    this.editSelection.emit(operations);
  }

  onClearFilters() {
    this.gridApi.setFilterModel(null);
  }

  onModelUpdated(params): void {
    super.onModelUpdated(params);
  }
}
