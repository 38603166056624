import {Plant} from "../components/order/model/order.model";
import {Store} from "./store";


export class SystemGlobalCache extends Store {

  private static FAAS_CLIENT = "faas.selected.client";
  private static FAAS_QNUMBER = "faas.current.user.qnumber";
  private static FAAS_LANG = "faas.current.lang";
  private static FAAS_USER_JWT_TOKEN = "faas.current.user.token";

  static plant() {
    return Plant[this.getInSessionCache(this.FAAS_CLIENT) as string];
  }

  static setPlant(plant: string) {
    window.sessionStorage.client = plant;
    this.saveInSessionCache<string>(this.FAAS_CLIENT,plant);
  }

  static userQNumber() {
    return this.getInSessionCache<string>(this.FAAS_QNUMBER);
  }

  static setUserQNumber(qNumber: string) {
    this.saveInSessionCache<string>(this.FAAS_QNUMBER, qNumber);
  }

  static setToken(token: string) {
    this.saveInSessionCache<string>(this.FAAS_USER_JWT_TOKEN, token);
  }

  static token() {
    return this.getInSessionCache<string>(this.FAAS_USER_JWT_TOKEN);
  }

  static setLang(lang: string) {
    this.saveInSessionCache<string>(this.FAAS_LANG, lang);
  }

  static currentLang() {
    return this.getInSessionCache<string>(this.FAAS_LANG);
  }

}
