import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {UsersOverviewGridColumns} from "./users-overview-grid.columns";
import {AgGridBase} from "../../../../ag.grid.base";
import {UserRowData} from "../../model/user.model";

@Component({
  selector: 'app-users-overview-grid',
  templateUrl: './users-overview-grid.component.html',
  styleUrls: ['./users-overview-grid.component.scss']
})
export class UsersOverviewGridComponent extends AgGridBase<UsersOverviewGridColumns> {

  @Input() rowData: any;
  @Output() addUser = new EventEmitter();
  @Output() editUser = new EventEmitter<UserRowData>();

  rowSelection = 'single';
  loadingScreen: boolean = false;
  paginationPageSize = 10;

  constructor(translate: TranslateService) {
    super(new UsersOverviewGridColumns(translate));
  }

  onRefresh(): void {
    location.reload();
  }

  onNewUser() {
    this.addUser.emit();
  }

  onRowDoubleClicked(params: any) {
    if ( params.data?.QNumber != undefined || params.data?.QNumber != null ) {
      let userRowData = new UserRowData();
      userRowData.QNumber = params.data.QNumber;
      userRowData.plant = params.data.plant;
      this.editUser.emit(userRowData);
    }
  }
}
