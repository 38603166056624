import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {
  CONTINUOUS_PRICE_QUERY,
  ContinuousPriceResponse,
  MANUAL_PRICES_QUERY,
  ManualPricesResponse
} from "./continous.price.graphql.query";
import {Plant} from "../../order/model/order.model";
import {ServiceBase} from "../../../services/service.base";
import {Subscription} from "rxjs";
import {ServiceBus} from "../../../services/service.bus";
import {Price, PriceSearchRequest} from "../model/continous.price.model";
import {ManualPricesTransformer} from "./manual-prices-transformer";

@Injectable({
  providedIn: 'root'
})
export class ContinuousPriceService extends ServiceBase {
  subscription: Subscription;
  transformer: ManualPricesTransformer;
  constructor(apollo: Apollo,
              private bus: ServiceBus) {
    super(apollo);
    this.transformer = new ManualPricesTransformer();
  }

  queryPrice(request: PriceSearchRequest) {

    const variables = {
      plant: Plant[window.sessionStorage.client],
      snr: request.snr?.trim(),
      ai: request.ai,
      knowledgeDate: request.knowledgeDate
    };

    this.subscription = this.query<ContinuousPriceResponse>
    (variables, CONTINUOUS_PRICE_QUERY)
      .subscribe(({data}) => {
        this.bus.writeResult(data.price);
      },error => {
        this.bus.writeError(this.removeGraphQLErrorOnMessage(error.message));
      });
  }

  dispose(): void {
    this.subscription.unsubscribe();
  }

  getManualPrices() {
    const variable = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<ManualPricesResponse> (variable, MANUAL_PRICES_QUERY);
  }

  mapManualPrices(manualPrices: Price[]): any {
    return this.transformer.transformFromDomain(manualPrices);
  }
}
