import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterContentChecked
} from '@angular/core';
import {ExchangeRatesService} from "../service/exchange-rates.service";
import {Observable, Subject, Subscription} from "rxjs";
import {ExchangeRatesGridComponent} from "./exchange-rates-grid/exchange-rates-grid.component";
import {FormControl, FormGroup} from "@angular/forms";
import _ from "lodash";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import {Plant} from "../../order/model/order.model";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-exchange-rates-overview',
  templateUrl: './exchange-rates-overview.component.html',
  styleUrls: ['./exchange-rates-overview.component.scss']
})
export class ExchangeRatesOverviewComponent implements OnInit , AfterContentChecked , OnDestroy {


  @ViewChild (ExchangeRatesGridComponent) exchangeRateGrid;
  addExchangeRate:Subject<any> = new Subject();
  cancelExchangeRateChange:Subject<any> = new Subject();

  months: string[] = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  errorMessage: string;
  rowData: any;
  data: any[] = [];
  dataBeforeSave: any[] = [];
  exchangeRatesToSave: any[] = [];
  loading: boolean = false;
  groupByColumns: string[];
  validationMessage;
  disableInsertButton: boolean = true;
  disableRefreshButton: boolean = true;
  disableEditButton: boolean = true;
  noDataInserted: boolean = true;
  showConfirmScreen:boolean = false;
  confirmExchangeRates: any;
  years: any = [];
  yearForm: FormGroup;
  loadingScreen: boolean = false;
  private yearToFilter: number;
  private editMode: boolean = false;
  shouldShowGrid: boolean = true;
  subscription: Subscription;
  exchangeRatesToFreeze: any;
  disableCancelButton: any = true;
  isNotControllingPP = false;
  isNotSeriesCalculation = false;
  isPlantSALorGWM = true;

  constructor(private exchangeRatesService: ExchangeRatesService,
              private plantService: PlantChangeService,
              private ref: ChangeDetectorRef,
              private translate: TranslateService) {
    this.subscription = this.plantService.onChange().subscribe(message => {
      if (message) {
        this.checkPlant();
        this.loadExchangeRates(this.yearToFilter)
      }
    });
    this.checkPlant();
  }

  ngOnInit(): void {
    this.yearToFilter = (new Date()).getFullYear();
    this.years = this.exchangeRatesService.getExchangeRateYears();
    this.initYearPicker();
    this.loadExchangeRates(this.yearToFilter);
    this.isNotControllingPP = !this.exchangeRatesService.isControllingPP();
    this.isNotSeriesCalculation = !this.exchangeRatesService.isSeriesCalculation();
  }

  checkPlant() {
    try {
      this.isPlantSALorGWM = Plant[window.sessionStorage.client] === Plant.SAL
        || Plant[window.sessionStorage.client] === Plant.GWM;
    } catch (error) {
      console.error(error);
    }
  }

  initYearPicker = () => {
    this.yearForm = new FormGroup({
      exchangeRateYear: new FormControl(this.yearToFilter.toString())
    });
  }

  loadExchangeRates(year: number){
    this.disableCancelButton = true;
    if (this.exchangeRateGrid) {
      this.exchangeRateGrid.setShowNoData(false);
    }
    this.shouldShowGrid = true;
    this.toggleButtonsOnloading(true);
    this.exchangeRatesService.getExchangeRates(year)
      .valueChanges
      .subscribe(({data, loading}) => {
        this.data = [];
        this.rowData = this.exchangeRatesService.mapOverviewExchangeRates(data.exchangeRates);
        this.rowData.forEach(val => this.data.push(Object.assign({}, val)));
        this.toggleButtonsOnloading(false);
      }, (error) => {
        this.toggleButtonsOnloading(false);
        this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
        if (this.exchangeRatesService.isNoDataErrorOnMessage(this.errorMessage)) {
          this.rowData = [];
          this.data = [];
          if (this.exchangeRateGrid) {
            this.exchangeRateGrid.setShowNoData(true);
          }
          this.disableEditButton = true;
        }else{
          this.shouldShowGrid = false;
        }
      });
  }

  private toggleButtonsOnloading(toggle:boolean): void {
    if (toggle){
      this.errorMessage = null;
      this.validationMessage = null;
      this.rowData = [];
      this.noDataInserted = toggle;
      if (this.exchangeRateGrid) {
        this.exchangeRateGrid.noDataUpdated = toggle;
      }
    }
    this.loading = toggle;
    this.loadingScreen = toggle;
    this.disableRefreshButton = toggle;
    this.disableInsertButton = toggle;
    this.disableEditButton = toggle;
  }

  refreshData(): void {
    this.editMode = false;
    this.exchangeRateGrid.setEditMode(this.editMode);
    this.loadExchangeRates(this.yearToFilter);
  }

  insertNewExchangeRate() {
    this.disableCancelButton = false;
    this.noDataInserted = false;
    this.editMode = false;
    this.disableEditButton = true;
    this.addExchangeRate.next(this.yearToFilter);
  }

  onSave() {
    this.exchangeRateGrid.stopAllEditing();
    this.validationMessage = undefined;
    this.errorMessage = undefined;
    this.dataBeforeSave = this.exchangeRateGrid.getRowData();
    let addedExchangeRates = this.dataBeforeSave.filter(f => f.inserted || f.updated);
    if (addedExchangeRates.length > 0) {
      this.validateAllExchangeRatesToBeNumber(addedExchangeRates);
      if (!this.validationMessage) {
        addedExchangeRates.forEach((exchangeRate) => {
          if (!exchangeRate.year || !exchangeRate.currency) {
            this.validationMessage = this.translate.instant('menu.pricing.exchange-rates.error-message.year-currency-required');
            this.exchangeRateGrid.setFocusOnRow(exchangeRate.rowIndex);
          } else {
            this.validateCurrentExchangeRate(exchangeRate);
          }
        });
        this.saveAndReload(addedExchangeRates);
      }
    }
  }

  private validateCurrentExchangeRate(exchangeRate) {
    if (SystemGlobalCache.plant() != Plant.SAL) {
      for (let month of this.months) {
        if (exchangeRate[month] != 0 && exchangeRate.current == 0) {
          this.setValidationMessage(this.translate.instant('menu.pricing.exchange-rates.error-message.current-required'), exchangeRate);
          break;
        }
      }
    }
  }

  validateAllExchangeRatesToBeNumber(addedExchangeRates){
    let message = this.translate.instant('menu.pricing.exchange-rates.error-message.rate-as-number');
    addedExchangeRates.forEach((exchangeRate) => {
      for (let month of this.months) {
        if (isNaN(Number(exchangeRate[month]))) {
          this.setValidationMessage(message, exchangeRate)
          break;
        }
      }
      if (!this.validationMessage) {
        if (isNaN(Number(exchangeRate["current"])) || isNaN(Number(exchangeRate["perNov"])) || isNaN(Number(exchangeRate["final"]))) {
          this.setValidationMessage(message, exchangeRate);
        }
      }
    });
  }

  private setValidationMessage(message, exchangeRate) {
    this.validationMessage = message;
    this.exchangeRateGrid.setFocusOnRow(exchangeRate.rowIndex);
  }

  private saveAndReload(addedExchangeRates) {
    if (!this.validationMessage) {
      this.exchangeRatesToSave = this.getExchangeRatesToSave(addedExchangeRates, this.data);
      if (this.exchangeRatesToSave.length > 0) {
        this.checkForConfirmation(this.exchangeRatesToSave);
        if (!this.showConfirmScreen) {
          this.submitToSave();
        }
      }
    }
  }

  private submitToSave() {
    this.toggleButtonsOnloading(true);
    let saveExchangeRates:Observable<any>;
    if (this.editMode){
      saveExchangeRates = this.exchangeRatesService.transformAndUpdateRates(this.exchangeRatesToSave);
    }else{
      saveExchangeRates = this.exchangeRatesService.transformAndAddRates(this.exchangeRatesToSave);
    }
    saveExchangeRates.subscribe(({data}) => {
      this.refreshData();
    }, (error) => {
      this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
      this.toggleButtonsOnloading(false);
      this.disableInsertButton = this.editMode;
      this.disableEditButton = !this.editMode;
      this.dataBeforeSave.forEach(val => this.rowData.push(Object.assign({}, val)));
      this.cancelExchangeRateChange.next(this.dataBeforeSave);
    });
  }

  private doSubmitForFreeze() {
    this.toggleButtonsOnloading(true);
    this.exchangeRatesService.freezeExchangeRates(this.exchangeRatesToFreeze).subscribe(({data}) => {
      this.exchangeRatesToFreeze = [];
      this.refreshData();
    }, (error) => {
      this.toggleButtonsOnloading(false);
      this.errorMessage = this.exchangeRatesService.removeGraphQLErrorOnMessage(error.message);
      this.dataBeforeSave.forEach(val => this.rowData.push(Object.assign({}, val)));
      this.cancelExchangeRateChange.next(this.dataBeforeSave);
    });
  }

  noDataUpdatedOrInserted(){
    if (this.exchangeRateGrid) {
      return this.noDataInserted && this.exchangeRateGrid.noDataUpdated
    }else{
      return this.noDataInserted;
    }
  }

  onCancel() {
    this.toggleButtonsOnloading(false);
    this.noDataInserted = true;
    this.exchangeRateGrid.noDataUpdated = true;
    this.editMode = false;
    this.exchangeRateGrid.setEditMode(this.editMode);
    this.validationMessage = undefined;
    this.errorMessage = undefined;
    this.rowData = [];
    this.data.forEach(val => this.rowData.push(Object.assign({}, val)));
    this.cancelExchangeRateChange.next(this.data);
    this.disableCancelButton = true;
    this.disableEditButton = this.exchangeRateGrid.showNoData;
  }

  getExchangeRatesToSave(addedExchangeRates, loadedData) {
    let exchangeResult = [];
    let array1 = [];
    let array2 = [];

    addedExchangeRates.forEach(exchangeRate => {
      if (exchangeRate.inserted){
        exchangeResult.push(exchangeRate);
      }else{
        array1.push(exchangeRate);
        array2.push(loadedData[exchangeRate.rowIndex]);
      }
    });

    array1.forEach((item, index) => {
      let difference = this.diff(array2[index],item)
      if(difference && !_.isEmpty(difference)) {
        exchangeResult.push(difference);
      }
    });
    return exchangeResult;
  }

  diff(exchangeRate1, exchangeRate2) {
    const result = {};
    if (Object.is(exchangeRate1, exchangeRate2)) {
      return undefined;
    }
    Object.keys(exchangeRate1 || {}).concat(Object.keys(exchangeRate2 || {})).forEach(key => {
      if(exchangeRate2[key] !== exchangeRate1[key] && !Object.is(exchangeRate1[key], exchangeRate2[key])) {
        if (key !== "updated" && key !== "rowIndex") {
          result[key] = exchangeRate2[key];
          result["year"] = exchangeRate2["year"];
          result["currency"] = exchangeRate2["currency"];
          result[key +"_id"] = exchangeRate2[key +"_id"];
          if (exchangeRate2["updated"]) {
            result["updated"] = exchangeRate2["updated"];
          }
        }
      }
    });
    return result;
  }

  onEditExchangeRates() {
    this.disableInsertButton = true;
    this.disableCancelButton = false;
    this.editMode = true;
    this.exchangeRateGrid.setEditMode(true);
  }

  toggleView() {
    this.showConfirmScreen = false;
    this.rowData = [];
    this.dataBeforeSave.forEach(val => this.rowData.push(Object.assign({}, val)));
    this.cancelExchangeRateChange.next(this.dataBeforeSave);
  }

  onConfirmation() {
    this.showConfirmScreen = false;
    if (this.exchangeRatesToFreeze && this.exchangeRatesToFreeze.length > 0){
      this.doSubmitForFreeze();
    }else {
      this.submitToSave();
    }
  }

  filterRecordsByYear(year: number) {
    if (year == null) return;
    this.yearToFilter = year;
    this.loadExchangeRates(this.yearToFilter);
  }

  private checkForConfirmation(addedExchangeRates: any[]) {
    let exchangeRateToConfirm = [];
    addedExchangeRates.forEach((exchangeRate) => {
      if (exchangeRate.inserted && (exchangeRate.final > 0 || exchangeRate.perNov > 0)) {
        exchangeRateToConfirm.push(exchangeRate);
      }else{
        if (exchangeRate.updated && (exchangeRate.perNov || exchangeRate.final)){
          exchangeRateToConfirm.push(exchangeRate);
        }
      }
    })
    this.confirmExchangeRates = exchangeRateToConfirm;
    this.showConfirmScreen = exchangeRateToConfirm.length > 0;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onFreeze() {
    this.exchangeRatesToFreeze = [];
    this.dataBeforeSave = this.exchangeRateGrid.getRowData();
    let ratesData = this.dataBeforeSave.filter(rate => rate.perNovToFreeze == "true");
    if (ratesData.length > 0) {
      ratesData.forEach((rateItem) => {
        this.exchangeRatesToFreeze.push(this.createAppropriateRate(rateItem));
      });
      this.confirmExchangeRates = this.exchangeRatesToFreeze;
      this.showConfirmScreen = this.exchangeRatesToFreeze.length > 0;
    }
  }

  noDataToFreeze() {
    if (this.exchangeRateGrid) {
      return this.exchangeRateGrid.getRowData().filter(rate => rate.perNovToFreeze == "true").length == 0 || this.editMode
    }else{
      return this.editMode ;
    }
  }

  private createAppropriateRate(rate) {
    let result = {};
    result["perNov"] = rate["perNov"];
    result["perNov_id"] = rate["perNov_id"];
    result["statusReason"] = rate["statusReason"];

    result["year"] = rate["year"];
    result["currency"] = rate["currency"];
    result["title"] = "Freezing exchange rates";
    return result;
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }


}
