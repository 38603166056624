import {Injectable} from "@angular/core";
import {ServiceBase} from "../../../../services/service.base";
import {Apollo} from "apollo-angular";
import {Plant} from "../../../order/model/order.model";
import {
  FREEZE_PRICE_QUERY,
  FREEZE_PRICES_BY_DATE,
  FREEZE_PRICES_BY_ID_QUERY,
  FREEZE_PRICES_SUMMARY_QUERY,
  FREEZE_PRICES_WORKFLOW_QUERY,
  FREEZE_PRICES_WORKFLOW_TYPE_QUERY,
  FreezePriceByIdResponse,
  FreezePriceForWorkFlowResponse,
  FreezePriceForWorkFlowTypeResponse,
  FreezePriceResponse,
  FreezePriceSearchDateResponse,
  PricesSummaryResponse
} from "./freeze.price.graphql.query";
import {PriceSummaryCellData} from "../model/freeze.price.models";

import {Price, PriceSearchRequest} from "../../model/continous.price.model";
import {
  APPROVE_PRICE,
  APPROVE_PRICES_FOR_BATCH,
  REJECT_PRICE,
  TRIGGER_APPROVE_FREEZE_PRICE_JOB
} from "./freeze.price.mutations";
import {AuthService} from "../../../../services/auth.service";
import {faas} from "../../../../constants/faas.constants";

@Injectable({
  providedIn: 'root'
})
export class FreezePriceService extends ServiceBase {

  private _apollo: Apollo;

  constructor(apollo: Apollo,
              private authService: AuthService) {
    super(apollo);
    this._apollo = apollo;
  }

  getPriceSummary() {
    const variables = {
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<PricesSummaryResponse>(variables, FREEZE_PRICES_SUMMARY_QUERY);
  }

  getPriceForWorkFlowState(e: PriceSummaryCellData) {
    const variables = {
      priceDate: e.priceDate,
      plant: Plant[window.sessionStorage.client],
      workflowState: e.workflowState
    };
    return this.query<FreezePriceForWorkFlowResponse>(variables,FREEZE_PRICES_WORKFLOW_QUERY);
  }

  getPriceForWorkFlowType(e: PriceSummaryCellData) {
    const variables = {
      priceDate: e.priceDate,
      plant: Plant[window.sessionStorage.client],
      workflowState: e.workflowState
    };
    return this.query<FreezePriceForWorkFlowTypeResponse>(variables,FREEZE_PRICES_WORKFLOW_TYPE_QUERY);
  }

  getPriceById(id: number) {
    const variables = {
      internalIdentifier: id
    };
    return this.query<FreezePriceByIdResponse>(variables,FREEZE_PRICES_BY_ID_QUERY);
  }

  queryFreezePrice(request: PriceSearchRequest) {
    const variable = {
      plant: Plant[window.sessionStorage.client],
      snr: request.snr?.trim(),
      ai: request.ai,
      knowledgeDate: request.knowledgeDate
    };
    return this.query<FreezePriceResponse> (variable, FREEZE_PRICE_QUERY);
  }

  approveFreezePrices(priceInput: any[]) {
    const variable = {
      prices:priceInput
    };
    return this.mutate(variable,APPROVE_PRICE);
  }

  approveFreezePricesForBatch(priceInput: any[]) {
    const variable = {
      prices:priceInput
    };
    return this.mutate(variable,APPROVE_PRICES_FOR_BATCH);
  }

  rejectFreezePrices(priceInput: Price[]) {
    const variable = {
      prices: priceInput
    };
    return this.mutate(variable,REJECT_PRICE);
  }

  searchFreezePriceByDate(request: PriceSearchRequest) {
    const variables = {
      priceDate: request.knowledgeDate,
      plant: Plant[window.sessionStorage.client]
    };
    return this.query<FreezePriceSearchDateResponse>(variables,FREEZE_PRICES_BY_DATE);
  }

  canReleaseOrReject() {
    let permissions = ['T_Freeze_Freigeber_MSF','T_Freeze_Freigeber_NED'];
    return this.authService.checkPermission(permissions);
  }

  triggerApproveFreezePrice() {
    return this.mutate(null, TRIGGER_APPROVE_FREEZE_PRICE_JOB, faas.orderServiceBatchClientName);
  }

  dispose(): void {
  }
}
