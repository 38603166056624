import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";

interface OrderManagementEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextOrderManagementEnums: Record<LocalCodes, OrderManagementEnum[]> = {
  en: [
    //Order Type
    {name: 'SERIES', translated: 'SERIES'},
    {name: 'SERIE', translated: 'SERIES'},
    {name: 'SERIES_SPECIAL_ORDER', translated: 'SERIES_SPECIAL_ORDER'},
    {name: 'SERIE_SONDERBESTELLUNG', translated: 'SERIES_SPECIAL_ORDER'},

    //Quantity Unit
    {name: 'PIECE', translated: 'PIECE'},
    {name: 'STUECK', translated: 'PIECE'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'METER', translated: 'METER'},

    //Order State
    {name: 'CLOSED', translated: 'CLOSED'},
    {name: 'GESCHLOSSEN', translated: 'CLOSED'},
    {name: 'OPENED', translated: 'OPENED'},
    {name: 'GEÖFFNET', translated: 'OPENED'},
    {name: 'CANCELLED', translated: 'CANCELLED'},
    {name: 'STORNIERT', translated: 'CANCELLED'}
  ],
  de: [
    {name: 'SERIES', translated: 'SERIE'},
    {name: 'SERIE', translated: 'SERIE'},
    {name: 'SERIES_SPECIAL_ORDER', translated: 'SERIE_SONDERBESTELLUNG'},
    {name: 'SERIE_SONDERBESTELLUNG', translated: 'SERIE_SONDERBESTELLUNG'},

    {name: 'PIECE', translated: 'STUECK'},
    {name: 'STUECK', translated: 'STUECK'},
    {name: 'LITER', translated: 'LITER'},
    {name: 'METER', translated: 'METER'},

    {name: 'CLOSED', translated: 'GESCHLOSSEN'},
    {name: 'GESCHLOSSEN', translated: 'GESCHLOSSEN'},
    {name: 'OPENED', translated: 'GEÖFFNET'},
    {name: 'GEÖFFNET', translated: 'GEÖFFNET'},
    {name: 'CANCELLED', translated: 'STORNIERT'},
    {name: 'STORNIERT', translated: 'STORNIERT'}
  ]
};

export class TranslateOrderManagementEnums {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getOrderManagementEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getOrderManagementEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let orderManagementEnum = localTextOrderManagementEnums[lang].find(e => e.name === key);
    if (orderManagementEnum) {
      return orderManagementEnum?.translated;
    }
    return localTextOrderManagementEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

