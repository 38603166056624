export class WeightViewModel {
  orderNo: string;
  weightValue: string;
  weightDate: string;
  importDate: string;
  snr: string;
  ai: number;
  plant: string;
  weightType: string;
}
