import {
  ManufacturingCostChangeInput,
  ManufacturingCostInput,
  RoyaltiesIndicator,
  TransferCategoryCost,
  TransferPrice
} from '../model/operations.model';
import {transferPrice} from "./transfer-price-constants";

export class CostAllocationsTransformer{

  transformToRowData(transferCategoryCosts: TransferCategoryCost[], priceId: number){
    let rowData = [];
    let rowDataMap = new Map();

    try{
      if(transferCategoryCosts.length == 0){
        return rowData;
      }

      const adjustmentReasons = transferPrice.adjustmentReason;
      const changeTypes = transferPrice.changeTypeForAdjustmentReason;
      transferCategoryCosts.forEach(row => {
        let transferCategoryCost = {};
        let thisAmount = 0;
        if (row.amount != null){
          thisAmount = row.amount.value;
        }

        let mapKey = row.categoryCost.name.toString() + ":" + row.categoryCost.groupCost.name.toString();
        let mapValue : any;

        transferCategoryCost['category'] = row.categoryCost.name;
        transferCategoryCost['categoryId'] = row.categoryCost.internalIdentifier;
        transferCategoryCost['group'] = row.categoryCost.groupCost.name;
        transferCategoryCost['priceId'] = priceId;

        if (row.adjustmentReason == null) {
          for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
            transferCategoryCost[adjustmentReasonKey] = 0;
          }
        } else {
          for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
            if (row.adjustmentReason.name === adjustmentReasons[adjustmentReasonKey] &&
                row.adjustmentReason.changeType.name === changeTypes[adjustmentReasonKey]) {
              transferCategoryCost[adjustmentReasonKey] = thisAmount;
            } else {
              transferCategoryCost[adjustmentReasonKey] = 0;
            }
          }
        }

        if(rowDataMap.has(mapKey)) {
          mapValue = rowDataMap.get(mapKey);
          for (let adjustmentReasonKey in transferPrice.adjustmentReason) {
            if (transferCategoryCost[adjustmentReasonKey] != 0) mapValue[adjustmentReasonKey] = transferCategoryCost[adjustmentReasonKey];
          }
          rowDataMap.set(mapKey,mapValue);

        } else {
          rowDataMap.set(mapKey, transferCategoryCost);
        }
      });

      for (let value of rowDataMap.values()) {
        rowData.push(value);
      }

      return rowData;

    } catch (err) {
      console.log('An error occurred in cost-allocations-transformer : ', err.message);
      return [];
    }
  }

  transformSpecialEquipmentToRowData(allocationdetails: TransferCategoryCost[], selectedDetail: any, specialEquipment: any, packet:any){
    let rowData = [];
    let rowDataMap = new Map();

    try{
      if(allocationdetails.length == 0){
        return rowData;
      }

      allocationdetails.forEach(row => {
        let newCostAllocationRow = {};
        let thisAmount = 0;
        if (row.amount != null){
          thisAmount = row.amount.value;
        }

        let mapKey = row.categoryCost.name.toString() + ":" + row.categoryCost.groupCost.name.toString();
        let mapValue : any;

        newCostAllocationRow['priceId'] = selectedDetail['priceId'];
        newCostAllocationRow['derivative'] = selectedDetail['derivate'];
        newCostAllocationRow['desigtype'] = selectedDetail['type'];
        newCostAllocationRow['saCode'] = selectedDetail['saCode'];
        if (specialEquipment) {
          newCostAllocationRow['description'] = specialEquipment['name'];
        } else if (packet) {
          newCostAllocationRow['description'] = packet['name'];
        }
        newCostAllocationRow['includeRoyalties'] = selectedDetail['includeRoyalties'];
        newCostAllocationRow['tpOld'] = selectedDetail['fkOld'];
        newCostAllocationRow['priceId'] = selectedDetail['priceId'];
        newCostAllocationRow['tpNew'] = 0;

        newCostAllocationRow['category'] = row.categoryCost.name;
        newCostAllocationRow['categoryId'] = row.categoryCost.internalIdentifier;

        newCostAllocationRow['group'] = row.categoryCost.groupCost.name;

        if(row.adjustmentReason == null){
          this.setValues(newCostAllocationRow);
        } else {
          let adjReason = row.adjustmentReason.name;
          switch(adjReason) {
            case "Technical Change Request BMW":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow['techChange'] = thisAmount;
            break;

            case "Premises":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow["premises"] = thisAmount;
            break;

            case "Volume Changes":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow["volumeChange"] = thisAmount;
            break;

            case "Other":
              if(row.adjustmentReason.changeType.name === "Change on behalf of BMW"){
                this.setValues(newCostAllocationRow);
                newCostAllocationRow["bmwOther"] = thisAmount;
              } else {
                this.setValues(newCostAllocationRow);
                newCostAllocationRow["salOther"] = thisAmount;
              }
            break;

            case "5% Margin Requirements SAL":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow["fivePercent"] = thisAmount;
            break;

            case "SAL Effects with BMW Impact":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow["effect"] = thisAmount;
            break;

            case "Royalties":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow['royalties'] = thisAmount;
              break;

            case "Profit":
              this.setValues(newCostAllocationRow);
              newCostAllocationRow['profit'] = thisAmount;
              break;

          }
        }
        if(rowDataMap.has(mapKey)) {
          mapValue = rowDataMap.get(mapKey);
          if (newCostAllocationRow['bmwOther'] != 0) mapValue['bmwOther'] = newCostAllocationRow['bmwOther'];
          if (newCostAllocationRow['effect'] != 0) mapValue['effect'] = newCostAllocationRow['effect'];
          if (newCostAllocationRow['fivePercent'] != 0) mapValue['fivePercent'] = newCostAllocationRow['fivePercent'];
          if (newCostAllocationRow['premises'] != 0) mapValue['premises'] = newCostAllocationRow['premises'];
          if (newCostAllocationRow['salOther'] != 0) mapValue['salOther'] = newCostAllocationRow['salOther'];
          if (newCostAllocationRow['techChange'] != 0) mapValue['techChange'] = newCostAllocationRow['techChange'];
          if (newCostAllocationRow['total'] != 0) mapValue['total'] = newCostAllocationRow['total'];
          if (newCostAllocationRow['volumeChange'] != 0) mapValue['volumeChange'] = newCostAllocationRow['volumeChange'];
          if (newCostAllocationRow['royalties'] != 0) mapValue['royalties'] = newCostAllocationRow['royalties'];
          if (newCostAllocationRow['profit'] != 0) mapValue['profit'] = newCostAllocationRow['profit'];
          rowDataMap.set(mapKey,mapValue);

        } else {
          rowDataMap.set(mapKey, newCostAllocationRow);
        }
      });

      for (let value of rowDataMap.values()) {
        rowData.push(value);
      }

      return  rowDataMap.values().next().value;

    } catch (err) {
      console.log('An error occurred in cost-allocations-transformer : ', err.message);
      return [];
    }
  }

  private setValues(newCostAllocationRow: {}) {
    newCostAllocationRow['bmwOther'] = 0;
    newCostAllocationRow['effect'] = 0;
    newCostAllocationRow['fivePercent'] = 0;
    newCostAllocationRow['premises'] = 0;
    newCostAllocationRow['salOther'] = 0;
    newCostAllocationRow['techChange'] = 0;
    newCostAllocationRow['total'] = 0;
    newCostAllocationRow['volumeChange'] = 0;
    newCostAllocationRow['royalties'] = 0;
    newCostAllocationRow['profit'] = 0;
  }

  transformToManufacturingCostChangeInput(params:any):ManufacturingCostChangeInput {
    let transferCategoryCostArr = [];
    params.rows.forEach(row => {
      let categoryCost = {};
      categoryCost["internalIdentifier"] = row.categoryId;
      for (let key in row) {
        let transferCategoryCost = {};
        let adjustmentReason = {};

        if(key == "techChange") {
          adjustmentReason["internalIdentifier"] = 1;
          transferCategoryCost["amount"] = row["techChange"];
        } else if (key == "premises") {
          adjustmentReason["internalIdentifier"] = 2;
          transferCategoryCost["amount"] = row["premises"];
        } else if (key == "volumeChange") {
          adjustmentReason["internalIdentifier"] = 3;
          transferCategoryCost["amount"] = row["volumeChange"];
        } else if (key == "bmwOther") {
          adjustmentReason["internalIdentifier"] = 4;
          transferCategoryCost["amount"] = row["bmwOther"];

        } else if (key == "fivePercent") {
          adjustmentReason["internalIdentifier"] = 5;
          transferCategoryCost["amount"] = row["fivePercent"];

        } else if (key == "effect") {
          adjustmentReason["internalIdentifier"] = 6;
          transferCategoryCost["amount"] = row["effect"];

        } else if (key == "salOther") {
          adjustmentReason["internalIdentifier"] = 7;
          transferCategoryCost["amount"] = row["salOther"];
        } else {
          continue;
        }
        transferCategoryCost["categoryCost"] = Object.assign({}, categoryCost);
        transferCategoryCost["adjustmentReason"] = Object.assign({}, adjustmentReason);
        transferCategoryCostArr.push(transferCategoryCost);
      }

    });

    let manufacturingCostChangeInput:ManufacturingCostChangeInput = new ManufacturingCostChangeInput();
    manufacturingCostChangeInput.operationId = params.opsId;
    manufacturingCostChangeInput.plant = params.plant;
    manufacturingCostChangeInput.manufacturingCostInputs = [];
    let currentPriceRoyaltiesIndicator = params.royaltiesMap.get(params.selectedPriceId);
    params.ids.forEach(id => {
      let manufacturingCost = new ManufacturingCostInput();
      manufacturingCost.manufacturingCostId = id;
      let transferPrice = new TransferPrice();
      transferPrice.royaltiesIndicator = params.royaltiesMap.get(id);
      if (RoyaltiesIndicator[transferPrice.royaltiesIndicator] === RoyaltiesIndicator.NEVER) {
        let totalCalculatedProfit = 0;
        let totalManualProfit = 0;
        transferPrice.transferCategoryCost = transferCategoryCostArr.filter(transferCategoryCost => transferCategoryCost.categoryCost.internalIdentifier !== 18).map(transferCategoryCost => {
          if (transferCategoryCost.categoryCost.internalIdentifier === 20) {
            let newTransferCategoryCost = new TransferCategoryCost();
            totalManualProfit += +transferCategoryCost.amount;
            newTransferCategoryCost.adjustmentReason = transferCategoryCost.adjustmentReason;
            newTransferCategoryCost.categoryCost = transferCategoryCost.categoryCost;
            if (transferCategoryCost.adjustmentReason.internalIdentifier === 1) {
              newTransferCategoryCost.amount = params.calculatedProfit["techChange"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 2) {
              newTransferCategoryCost.amount = params.calculatedProfit["premises"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 3) {
              newTransferCategoryCost.amount = params.calculatedProfit["volumeChange"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 4) {
              newTransferCategoryCost.amount = params.calculatedProfit["bmwOther"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 5) {
              newTransferCategoryCost.amount = params.calculatedProfit["fivePercent"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 6) {
              newTransferCategoryCost.amount = params.calculatedProfit["effect"];
            } else if (transferCategoryCost.adjustmentReason.internalIdentifier === 7) {
              newTransferCategoryCost.amount = params.calculatedProfit["salOther"];
            }
            totalCalculatedProfit += +newTransferCategoryCost.amount;
            return newTransferCategoryCost;
          } else {
            console.log('Eval 3');
            return transferCategoryCost;
          }
        });
        transferPrice.transferCategoryCost = transferCategoryCostArr.filter(transferCategoryCost => transferCategoryCost.categoryCost.internalIdentifier !== 18);
        if (RoyaltiesIndicator[currentPriceRoyaltiesIndicator] === RoyaltiesIndicator.NEVER) {
          manufacturingCost.price = params.price + totalCalculatedProfit;
        } else {
          manufacturingCost.price = params.price - params.totalRoyalties - totalManualProfit + totalCalculatedProfit;
        }
      } else {
        transferPrice.transferCategoryCost = transferCategoryCostArr;
        if (RoyaltiesIndicator[currentPriceRoyaltiesIndicator] === RoyaltiesIndicator.NEVER) {
          manufacturingCost.price = params.price + params.totalRoyalties;
        } else {
          manufacturingCost.price = params.price;
        }
      }
      manufacturingCost.transferPrice = transferPrice;
      manufacturingCostChangeInput.manufacturingCostInputs.push(manufacturingCost);
    });
    return manufacturingCostChangeInput;

  }

  transformToManufacturingCostSAChangeInput(params:any):ManufacturingCostChangeInput {
    let output:ManufacturingCostChangeInput = new ManufacturingCostChangeInput();
    output.manufacturingCostInputs = [];
    params.rows.forEach(row => {
      let transferCategoryCostArr = [];
      let manufacturingCost = new ManufacturingCostInput();
      manufacturingCost.manufacturingCostId = row["priceId"];
      manufacturingCost.price = row["deltaPrice"];
      let transferPrice = {
        internalIdentifier: null,
        royaltiesIndicator: null,
        transferCategoryCost: null
      };
      transferPrice.royaltiesIndicator = Object.keys(RoyaltiesIndicator).filter(x => RoyaltiesIndicator[x] === row.includeRoyalties)[0];
      let categoryCost = {};
      categoryCost["internalIdentifier"] = row.categoryId;
      for (let key in row) {
        let transferCategoryCost = {};
        let adjustmentReason = {};

        if(key == "techChange") {
          adjustmentReason["internalIdentifier"] = 1;
          transferCategoryCost["amount"] = row["techChange"];
        } else if (key == "premises") {
          adjustmentReason["internalIdentifier"] = 2;
          transferCategoryCost["amount"] = row["premises"];
        } else if (key == "volumeChange") {
          adjustmentReason["internalIdentifier"] = 3;
          transferCategoryCost["amount"] = row["volumeChange"];
        } else if (key == "bmwOther") {
          adjustmentReason["internalIdentifier"] = 4;
          transferCategoryCost["amount"] = row["bmwOther"];

        } else if (key == "fivePercent") {
          adjustmentReason["internalIdentifier"] = 5;
          transferCategoryCost["amount"] = row["fivePercent"];

        } else if (key == "effect") {
          adjustmentReason["internalIdentifier"] = 6;
          transferCategoryCost["amount"] = row["effect"];

        } else if (key == "salOther") {
          adjustmentReason["internalIdentifier"] = 7;
          transferCategoryCost["amount"] = row["salOther"];

        } else if (key == "royalties") {
          adjustmentReason["internalIdentifier"] = 8;
          transferCategoryCost["amount"] = row["royalties"];

        }else if (key == "profit") {
          adjustmentReason["internalIdentifier"] = 9;
          transferCategoryCost["amount"] = row["profit"];
        } else {
          continue;
        }
        transferCategoryCost["categoryCost"] = Object.assign({}, categoryCost);
        transferCategoryCost["adjustmentReason"] = Object.assign({}, adjustmentReason);
        transferCategoryCostArr.push(transferCategoryCost);
      }
      transferPrice.transferCategoryCost = transferCategoryCostArr;
      manufacturingCost.transferPrice = transferPrice;
      output.manufacturingCostInputs.push(manufacturingCost);

    });
    output.operationId = params.opsId;
    output.plant = params.plant;
    return output;

  }

}
