<bmw-label><b>{{ 'menu.manufacturing-cost.tabs.rules.title' | translate }}</b></bmw-label>
<bmw-label>{{ 'menu.manufacturing-cost.tabs.rules.help' | translate }}</bmw-label>
<bmw-label>{{ 'menu.manufacturing-cost.tabs.rules.description' | translate }}</bmw-label>
<br>
<ag-grid-angular data-testid="grid-operations-display" *ngIf="rowData"
                 style="height: 500px; overflow: scroll"
                 class="zebra"
                 [columnDefs]="columns"
                 [rowData]="rowData"
                 [defaultColDef]="defaultColDef"
                 (gridReady)="onGridReady($event)"
                 (cellValueChanged)="onCellValueChanged($event)"
                 (firstDataRendered)="onFirstDataRendered($event)"
                 [rowClassRules]="rowClassRules"
                 [rowStyle]="rowStyle"
                 [getRowStyle]="getRowStyle"
                 (rowSelected)="onRowSelected()"
>
</ag-grid-angular>
<br>
<p style="float: left">
  <bmw-button button="secondary" (clickEvent)="onBack()" class="bmw__margin-right__xs">
    {{ 'menu.manufacturing-cost.tabs.rules.cancel' | translate }}
  </bmw-button>
  <bmw-button button="secondary" (clickEvent)="addRow()" *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation" class="bmw__margin-right__xs">
    {{ 'menu.manufacturing-cost.tabs.rules.add' | translate }}
  </bmw-button>
  <bmw-button button="secondary" (clickEvent)="removeRow()" *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation || !isRowSelected" class="bmw__margin-right__xs">
    {{ 'menu.manufacturing-cost.tabs.rules.delete' | translate }}
  </bmw-button>
</p>
<p style="float: right">
  <bmw-button button="primary" (clickEvent)="onSave()" *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation || saveDisabled" class="bmw__margin-left__xs">
    {{ 'menu.manufacturing-cost.tabs.rules.save' | translate }}
  </bmw-button>
</p>
