import {GridColumnBase} from '../../../../shared/grid.column.base';
import {GridColumnDefinition} from '../../../../shared/grid.column.definition';
import {TranslateService} from '@ngx-translate/core';
import { RuleHistory } from '../../model/operations.model';

export class RuleHistoryGridColumns extends GridColumnBase implements GridColumnDefinition {

  translate: TranslateService;
  params: any;
  dialogTitle: string;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
    this.params = {
      rowData: new RuleHistory(),
      $event: {}
    };
  }

  columns = [{
    field: 'derivativeCode',
    headerName: 'derivativeCode',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'typeName',
    headerName: 'typeName',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'typeCode',
    headerName: 'typeCode',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'specialEquipmentCode1',
    headerName: 'specialEquipmentCode',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'description1',
    headerName: 'specialEquipmentDescription',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'specialEquipmentCode2',
    headerName: 'specialEquipmentCode',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'description2',
    headerName: 'specialEquipmentDescription',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'amount',
    headerName: 'rulePrice',
    sortable: true,
    filter: true,
    resizable: true,
    valueFormatter: this.moneyFormatter,
    cellStyle: {textAlign: 'right'},
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'validFrom',
    headerName: 'operationValidFrom',
    sortable: true,
    filter: true,
    resizable: true,
    valueFormatter: this.dateFormatter,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    editable: false,
  }, {
    field: 'releaseDate',
    headerName: 'operationReleaseDate',
    sortable: true,
    filter: true,
    resizable: true,
    valueFormatter: this.dateTimeFormatter,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
    editable: false,
  }, {
    field: 'operationId',
    headerName: 'operationId',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'name',
    headerName: 'operationName',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }, {
    field: 'status',
    headerName: 'status',
    sortable: true,
    filter: true,
    resizable: true,
    headerValueGetter: this.localizeHeaderWithHeaderName.bind(this),
  }
  ];
}
