
/**
  @value - the input value to pad
  @number of zeros - padding
 */
import {EMPTY_STRING, isUndefinedOrEmpty} from "./typescript.utils";

export function padLeft(value: number, numberOfZeros: number): string {
  if (isUndefinedOrEmpty(value)) return EMPTY_STRING;
  let s = value + EMPTY_STRING;
  while (s.length < numberOfZeros) s = "0" + s;
  return s;
}
