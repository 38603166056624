import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentTabComponent} from '@bmw-ds/components';
import {TranslateService} from '@ngx-translate/core';
import {OperationService} from '../manufacturing/service/operation.service';
import {VehicleStatusComponent} from './vehicle-status/vehicle-status.component';

@Component({
  selector: 'app-vehicle',
  templateUrl: './vehicle.component.html',
  styleUrls: ['./vehicle.component.scss']
})
export class VehicleComponent implements OnInit {
  @ViewChild('contentTab') tabComponent: ContentTabComponent;
  @ViewChild(VehicleStatusComponent) vehicleStatusComponent: VehicleStatusComponent;
  dialogTitle: string;
  dialogText: string;
  showPrompt = false;
  operation: any;

  constructor(
    public translate: TranslateService,
    public operationService: OperationService
  ) { }

  ngOnInit(): void {
  }

  changeTab(event) {
  }

  changeTabToVehicleInformation() {
    this.vehicleStatusComponent.loadVehicleStatus();
    this.tabComponent.setSelected('vehicleStatus');
  }
}
