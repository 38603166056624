import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-type-sa-dialog',
  templateUrl: './cost-allocations-sa-dialog.component.html',
  styleUrls: ['./cost-allocations-sa-dialog.component.scss']
})
export class CostAllocationsSaDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  dialogText: string;
  @Input() showUnallocatedSACostsDialog: boolean;
  @Output() saveTypeSADialog = new EventEmitter();
  @Output() cancelSADialog = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.dialogTitle = this.translate.instant('dialogs.saTypeCost');
    this.dialogText = this.translate.instant('messages.saTypeCost');
  }

  ok() {
    this.saveTypeSADialog.emit();
  }

  cancel() {
    this.showUnallocatedSACostsDialog = false;
    this.cancelSADialog.emit();
  }
}
