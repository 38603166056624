<bmw-ag-grid-theme>
  <div *ngIf="errorMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message data-testid = "error-message" type="error">
      {{errorMessage}}
    </bmw-inline-message>
  </div>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="loading..." [show]="loading"></bmw-progress-circle>
  <div data-testid="" *ngIf="!errorMessage && !selectedPrice && !prices" class="bmw__margin-top__xs">
    <div class="bmw__margin-top__xs">
      <app-freeze-price-grid-overview data-testid="freeze-price-grid-overview"
        [hidden]="!hideOverviewGrid"
        [rowData]="rowData"
        (doubleClick)="onDoubleClick($event,false)"
        (refresh)="onRefresh()"
      ></app-freeze-price-grid-overview>
      <app-freeze-price-details-grid
        [rowData]="rowDetailsData"
        [hidden]="hideOverviewGrid"
        [gridRowSelection]="rowSelection"
        [hideColumns]="hideColumns"
        [showButtons]="showApproveRejectButtons"
        (doubleClick)="onViewSelectedPrice($event)"
        (release)="releaseReject($event)"
        (reject)="releaseReject($event)"
        (cancelView)="closeAndRefreshPriceView()"
        (errorMessage)="onError($event)">
      </app-freeze-price-details-grid>
    </div>
  </div>



  <div *ngIf="selectedPrice && !prices" class="bmw__margin-top__xs">
    <app-freeze-price-main-details
      [price]="selectedPrice"
      [showButtons]="showApproveRejectButtons"
      (reject)="releaseReject($event)"
      (release)="releaseReject($event)"
      (onCancel)="closePriceView()">
    </app-freeze-price-main-details>
  </div>

  <div *ngIf="prices" class="bmw__margin-top__xs">
    <app-freeze-price-confirm
      [changes]="prices"
      [action]="action"
      (cancel)="closePriceView()"
      (confirmed)="onConfirmation()"
    ></app-freeze-price-confirm>
  </div>
</bmw-ag-grid-theme>
