
export class Store {

  //session store
  static saveInSessionCache<T>(key: string, value: T) {
    sessionStorage.setItem(key , JSON.stringify(value));
  }

  static getInSessionCache<T>(key: string): T {
    let cachedValue = sessionStorage.getItem(key) ;
    return cachedValue != null ? JSON.parse(cachedValue) : undefined;
  }

  static deleteFromSessionCache(key: string) {
    sessionStorage.removeItem(key);
  }

  saveInLocalStorageCache<T>(key: string, value: T) {
    localStorage.setItem(key , JSON.stringify(value));
  }

  getInLocalStorageCache<T>(key: string): T {
    let cachedValue = localStorage.getItem(key) ;
    return cachedValue != null ? JSON.parse(cachedValue) : undefined;
  }

  static clearAll(){
    sessionStorage.clear();
  }

}
