import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-operation-information-cell-renderer',
  template: `
    <a style="cursor: pointer" (click)="onWorkflowClick($event)"><span class="iwp-icon-gen_info">
    </span></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <ng-container *ngIf="files && files.length > 0">
      <a style="cursor: pointer" (click)="onDownloadClick($event)"><span class="iwp-icon-ci_paperclip"></span></a>
    </ng-container>
`
})
export class OperationInformationCellRenderer implements ICellRendererAngularComp {
  public params: any;
  files;

  constructor() {
  }

  agInit(params: any): void {
    this.params = params;
    this.files = this.params.data?.files;
  }

  refresh(params?: any): boolean {
    return true;
  }

  onDownloadClick($event) {
    if (this.params.onClick instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onClick(params);
    }
  }

  onWorkflowClick($event) {
    if (this.params.onCellClicked instanceof Function) {
      const params = {
        event: $event,
        rowData: this.params.node.data
      };
      this.params.onCellClicked(params);
    }
  }
}
