import gql from "graphql-tag";
import {Plant} from "../../order/model/order.model";
import {ExchangeRate} from "../model/exchange-rates.model";

export const EXCHANGE_RATES_PENDING_APPROVAL_QUERY = gql`
  query exchangeRatesPendingApproval($plant: PlantInput!) {
    exchangeRatesPendingApproval(plant:$plant) {
    internalIdentifier
    currency{
      internalIdentifier
      currency
      currencyType
      displayFormat
      scale
    }
    baseCurrency{
      internalIdentifier
      currency
      currencyType
      displayFormat
      scale
    }
    exchangeRateType
    year
    month
    value
    state
    statusDate
    statusReason
    relatedExchangeRates{
      internalIdentifier
      currency{
        internalIdentifier
        currency
        currencyType
        displayFormat
        scale
      }
      exchangeRateType
      year
      month
      value
      state
      statusDate
      statusReason
    }
  }
 }`;

export interface ExchangeRatesReleaseQueryResponse {
  exchangeRatesPendingApproval: ExchangeRate[];
}

export type ExchangeRatesReleaseVariables = {
  plant?: Plant;
}

