
<div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__inner">
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
          <bmw-button button="primary" type="submit">
            {{ 'component.bottom-bar.refresh' | translate }}
          </bmw-button>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
          <bmw-button button="primary" type="submit">
            {{ 'component.bottom-bar.reject' | translate }}
          </bmw-button>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form">
          <bmw-button button="primary" type="submit">
            {{ 'component.bottom-bar.approve' | translate }}
          </bmw-button>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
          <bmw-button button="primary" type="submit" (clickEvent)="edit()">
            {{ 'component.bottom-bar.edit' | translate }}
          </bmw-button>
        </div>
        <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-2 main-form search-button">
          <bmw-button button="primary" type="submit" >
            {{ 'component.bottom-bar.save' | translate }}
          </bmw-button>
        </div>
      </div>
</div>


