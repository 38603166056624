import {Component, Input, OnInit} from "@angular/core";
import * as QuicksightEmbedding from "amazon-quicksight-embedding-sdk";
import {QuicksightReportsService} from "../../service/quicksight-reports.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {ReportInputInput} from "../../../manufacturing/model/reports.model";

@Component({
  selector: 'quicksight-embedded-dashboard-report-3',
  templateUrl: './quicksight-dashboard-report-3.component.html',
  styleUrls:['./quicksight-dashboard-report-3.component.scss']
})
export class QuicksightDashboardReport3Component implements OnInit {
  showLoading = false;
  @Input() dashboard: String;
  isDashboardLoaded = false;

  constructor(private quicksightReportsService: QuicksightReportsService, private router: Router, public translate: TranslateService) {
  }

  public stopLoading() {
    this.showLoading = false;
  }

  ngOnInit() {
    this.showLoading = true;
    this.getDashboardURL();
  }

  public getDashboardURL() {
    let reportInput: ReportInputInput = new ReportInputInput();
    reportInput.report = this.dashboard;
    this.quicksightReportsService.getQuicksightUrl(reportInput).subscribe((result) => {
      if(result.data.retrieveQuicksightUrl['quicksightUrl'] != 'Error') {
        this.Dashboard(result.data.retrieveQuicksightUrl['quicksightUrl']);
        this.stopLoading();
      }else{
        this.stopLoading();
        confirm(this.translate.instant('menu.quicksight-reports-error.title'));
        this.router.navigateByUrl('/');
      }
    });
  }

  public async Dashboard(embeddedURL: any){
    if (this.isDashboardLoaded) {
      return;
    }

    this.isDashboardLoaded = true;
    const containerDiv = document.getElementById("dashboardContainerReport3");
    const embeddingContext = await QuicksightEmbedding.createEmbeddingContext();
    const embeddedDashboard = await embeddingContext.embedDashboard({
      url: embeddedURL,
      container:containerDiv,
      height: '100%',
      width: '100%'
    });
  }
}
