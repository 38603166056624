import gql from 'graphql-tag';
import {VehicleStatus} from '../model/vehicle.model';

export const RETRIEVE_VEHICLE_INFORMATION = gql`
query retrieveVehicleInformation($plant: Plant!, $year: String!, $month: String!) {
  retrieveVehicleInformation(plant: $plant, year: $year, month: $month) {
    derivate
    vehicleModelType
    plannedDate
    f2Date
    createdDate
    productGroup
    requestNumber
    validityDate
    vin17
    transferPrice {
      vehiclePriceStatus
      amount{
        value
      }
      reason
    }
    specialEquipments {
      specialEquipmentCode
      productStatus
    }
  }
}`;

export interface VehicleStatusResponse {
  retrieveVehicleInformation: VehicleStatus[];
}
