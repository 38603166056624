<ag-grid-angular
  style="height: 400px; overflow: auto"
  class="zebra"
  [rowData]="rowData"
  [columnDefs]="runtimeCompilerData.columns"
  [gridOptions]="runtimeCompilerData.gridOptions"
  [defaultColDef]="runtimeCompilerData.defaultColDef"
  [sideBar]="false"
  [paginationPageSize] = "paginationPageSize"
  [statusBar]="runtimeCompilerData.statusBar"
  [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDef"
  (firstDataRendered)="onFirstDataRendered($event)"
  (gridReady)="onGridReady($event)"
  (modelUpdated)="onModelUpdated($event)"
  (rowDataChanged)="onRefreshGridData($event)"
  (rowDoubleClicked)="onRowDoubleClicked($event)"
  [rowSelection]="rowSelection"
  [pagination]="true"
  [suppressPaginationPanel]="false">
</ag-grid-angular>
<div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <div
      class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
      <bmw-button button="primary" type="submit" (clickEvent)="onNewUser()">
        {{ 'component.bottom-bar.add' | translate }}
      </bmw-button>
    </div>
  </div>
</div>


