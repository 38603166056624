<div class="half-screen">
  <div *ngIf="errorMessage"
    class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="error">
      {{errorMessage}}
    </bmw-inline-message>
  </div>
<!--  <div class="bmw__margin-top__s" *ngFor="let c of changes">-->
<!--    <app-freeze-price-confirm-header [price]="c"></app-freeze-price-confirm-header>-->
<!--  </div>-->
  <div class="bmw__margin-top__s">
    <h4 class="bmw-h4"><b>{{changeDetails}}</b></h4>
  </div>
  <div class="bmw__margin-top__s">
    <div style="max-height:400px;">
      <form class="reversed" autocomplete="off">
        <bmw-inputfield  id="reason-textArea">
          <bmw-label><b>{{ 'reason' | translate }}</b></bmw-label>
          <textarea
            bmwInput
            type="text"
            name="reasonTextArea"
            placeholder="{{ 'menu.pricing.exchange-rates.overview.enter-reason' | translate }}"
            minlength="0"
            maxlength="255"
            #textarea
            [(ngModel)]="reason"
            style="resize: none"
          ></textarea>
          <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
        </bmw-inputfield>
      </form>
    </div>
  </div>
  <bmw-progress-circle data-testid="loading-icon" [fullScreen]="true" loadingLabel="Saving..." [show]="loadingScreen"></bmw-progress-circle>
  <div>
    <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
      <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
        <bmw-button data-testid="cancel" button="secondary" type="submit" (clickEvent)="onCancel()" iconLeft="iwp-icon-gen_close" style="padding: 5px;">
          {{ 'component.bottom-bar.cancel' | translate }}
        </bmw-button>
        <bmw-button data-testid="onConfirm" button="primary" type="submit" (clickEvent)="onConfirm()">
          {{ 'component.bottom-bar.confirm' | translate }}
        </bmw-button>
      </div>
    </div>
  </div>
</div>


