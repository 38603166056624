import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";

interface HomeComponentEnum {
  name: string;
  translated: string;
}

export type LocalCodes = 'en' | 'de' ;

const localTextHomeComponentEnums: Record<LocalCodes, HomeComponentEnum[]> = {
  en: [
    //Message Type
    {name: 'Maintenance', translated: 'Maintenance'},
    {name: 'Art', translated: 'Maintenance'},
    {name: 'Prices', translated: 'Prices'},
    {name: 'Preise', translated: 'Prices'},

    //Priority
    {name: 'Low', translated: 'Low'},
    {name: 'Niedrig', translated: 'Low'},
    {name: 'Medium', translated: 'Medium'},
    {name: 'Mittel', translated: 'Medium'},
    {name: 'High', translated: 'High'},
    {name: 'Hoch', translated: 'High'},

    //Messages
    {name: 'No Messages', translated: 'No Messages'},
    {name: 'Keine Nachrichten', translated: 'No Messages'}
  ],
  de: [
    //Message Type
    {name: 'Maintenance', translated: 'Art'},
    {name: 'Art', translated: 'Art'},
    {name: 'Prices', translated: 'Preise'},
    {name: 'Preise', translated: 'Preise'},

    //Priority
    {name: 'Low', translated: 'Niedrig'},
    {name: 'Niedrig', translated: 'Niedrig'},
    {name: 'Medium', translated: 'Mittel'},
    {name: 'Mittel', translated: 'Mittel'},
    {name: 'High', translated: 'Hoch'},
    {name: 'Hoch', translated: 'Hoch'},

    //Messages
    {name: 'No Messages', translated: 'Keine Nachrichten'},
    {name: 'Keine Nachrichten', translated: 'Keine Nachrichten'}
  ]
};

export class TranslateHomeComponentEnums {

  translate(lang: string, key: string) {
    if (this.isNotValidKey(key)) {
      return EMPTY_STRING;
    }
    return this.getHomeComponentEnum(lang, key);
  }

  isNotValidKey(key: string): boolean {
    return (key == undefined || key?.length < 2)
  }

  getHomeComponentEnum(lang: string, key: string): string {
    let reverseGermanToEnglish = "de";
    let homeComponentEnum = localTextHomeComponentEnums[lang].find(e => e.name === key);
    if (homeComponentEnum) {
      return homeComponentEnum?.translated;
    }
    return localTextHomeComponentEnums[reverseGermanToEnglish].find(e => e.name === key)?.translated;
  }
}

