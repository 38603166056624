import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";
import {PriceUnit} from "../model/order.model";
import {padLeft} from "../../../shared/functions/padding";


export class OrderGridColumns {

  constructor(private translate: TranslateService) { }

  setGroupingColumns(col: string[]): void {
    if ( col == undefined )
      return;

    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        //c.rowGroup = true;
      }
    });
  }

  hideColumns(col: string[]): void {
    if ( col == undefined )
      return;

    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        //c.hide = true;
      }
    });
  }

  columns = [
     {
       field: 'snr',
       headerName: 'SNR',
       width: 150,
       minWidth: 150,
       //rowGroup: false,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'ai',
       headerName: 'AI',
       width: 150,
       minWidth: 200,
       //rowGroup: false,
       hide: false,
       editable: false,
       valueFormatter: this.aiFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'orderNo',
       headerName: 'Order No',
       //resizable: true,
       width: 150,
       minWidth: 200,
       //rowGroup: false,
       hide: false,
       editable: false,
       cellRenderer: this.showPendingIcon,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'version',
       headerName: 'Order Version',
       width: 150,
       minWidth: 200,
       //rowGroup: false,
       hide: false,
       editable: false,
       //type: 'rightAligned',
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'fromDate' ,
       headerName: 'Valid From',
       width: 150,
       minWidth: 200,
       hide: false,
       editable: false,
       filter: true,
       valueFormatter: this.dateFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'toDate' ,
       headerName: 'Expiry Date',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       filter: true,
       cellRenderer: this.showNoIcon,
       valueFormatter: this.dateFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'basePriceValue' ,
       headerName: 'Base Value',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       type: 'rightAligned',
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'nettPriceValue' ,
       headerName: 'Net Value',
       width: 150,
       minWidth: 150,
       hide: false,
       type: 'rightAligned',
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'orderState',
       headerName: 'Order Status',
       width: 150,
       minWidth: 150,
       filter: true,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'changeDate',
       headerName: 'Order Date',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'orderCurrency',
       headerName: 'Order Currency',
       width: 150,
       minWidth: 150,
       hide: false,
       filter: true,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'priceUnit',
       headerName: 'Price Unit',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       valueFormatter: this.priceUnitFormatter,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'quantityUnit',
       headerName: 'Quantity Unit',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
     {
       field: 'supplierNo',
       headerName: 'Supplier',
       width: 150,
       minWidth: 150,
       hide: false,
       editable: false,
       headerValueGetter: this.localizeHeader.bind(this)
     },
   ];

   localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  cellStyle(params) {
    if (params.data.hasPendingOrders == "true"){
      return { backgroundColor:  "#B0C3B0" };
    }
  }

  dateFormatter(params) {
    if (params.value === undefined ) {
      return '';
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  showPendingIcon(params) {
    if (params.value != undefined && params.data?.hasPendingOrders != undefined ) {
      return params.data?.hasPendingOrders == true ? // only show icon if column is set to editable = true
        '<span><i class="iwp-icon-gen_info_info"></i>  ' + params.value + '   </span>'
        : params.value;
    }
    return '';
  }

  aiFormatter(params) {
     if (params.value != undefined) {
       return padLeft(params.value,2);
     }
  }

  showNoIcon(params) {
    if (params.value != undefined && params.data?.hasPendingOrders != undefined ) {
      // to cater for the group by if you have cellRenderer .. it will show undefined in blank rows
        let date = moment(params.value).format('DD.MM.YYYY');
        return params.data.hasPendingOrders == true ? // only show icon if column is set to editable = true
          '<span> ' + date + ' </span>'
          : date;
      }
      return '';
  }

  priceUnitFormatter(params) {
     return PriceUnit[params.value] == undefined ? "" : PriceUnit[params.value];
  }

}
