import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'translate-component',
  templateUrl: './translate.component.html',
  styleUrls: ['./translate.component.scss'],
})
export class TranslateComponent implements OnInit {

  constructor(
    public translate: TranslateService,
  ) {
    translate.addLangs(['en', 'de', 'zh']);
    translate.setDefaultLang('de');
    const lang: string = 'en';
    translate.use('en'.match(/en|de/) ? lang : translate.defaultLang);
  }

  public ngOnInit() {
    //console.log('Translate: using language ',this.translate.currentLang);
  }

}
