import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {PriceSearchRequest} from "../../model/continous.price.model";
import {Plant} from "../../../order/model/order.model";
import * as _ from "lodash";
import moment from "moment";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-price-search-bar',
  templateUrl: './price-search-bar.component.html',
  styleUrls: ['./price-search-bar.component.scss']
})
export class PriceSearchBarComponent implements OnInit {
  priceSearchForm: FormGroup;
  searchRequest: PriceSearchRequest;
  inputDate: any;

  @Output() onSearch = new EventEmitter<PriceSearchRequest>();
  @Output() onClear : EventEmitter<any> = new EventEmitter();

  constructor(private translate: TranslateService) {
    this.searchRequest = new PriceSearchRequest();
  }

  ngOnInit(): void {
    this.priceSearchForm = new FormGroup({
      snr: new FormControl(['']),
      ai: new FormControl([''])
    });
  }

  search() {
    try {
      this.onDateSelect(this.inputDate);
      this.canSearch()
      this.validate();
      this.searchRequest.isError = false;
      this.searchRequest.snr = this.priceSearchForm.get("snr").value;
      this.searchRequest.ai = parseInt(this.priceSearchForm.get("ai").value);
      this.searchRequest.plant = Plant[window.sessionStorage.client];
    } catch (e) {
      this.searchRequest.isError = true;
      this.searchRequest.error = e.toString();
    }
    this.onSearch.emit(this.searchRequest);
  }

  canSearch() {
    let result = ((this.priceSearchForm.get("snr").value &&
        !_.isEmpty(this.priceSearchForm.get("snr").value.toString().trim()) &&
        this.priceSearchForm.get("ai").value &&
        !_.isEmpty(this.priceSearchForm.get("ai").value.toString().trim()) &&
        this.searchRequest.knowledgeDate != null)
    );

    if (!result)
      throw this.translate.instant('menu.pricing.order-management.error-message.snr-ai-and-knowledge-date'); // "Please select SNR, AI and Knowledge date to search."
  }

  onDateSelect(date) {
    if (!date) {
      throw this.translate.instant('menu.pricing.order-management.error-message.invalid-knowledge-date');
    }
    this.searchRequest.knowledgeDate = this.dateFormatter(date);
  }

  dateFormatter(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  isNumber(s: string) {
    return Number.isInteger(parseInt(s))
  }

  validate() {
    let date = new Date(this.searchRequest.knowledgeDate);
    if (isNaN(date.getTime())) {
      throw this.translate.instant('menu.pricing.order-management.error-message.invalid-knowledge-date');
    }

    let ai = this.priceSearchForm.get("ai").value;
    if (!this.isNumber(ai))
      throw this.translate.instant('menu.pricing.order-management.error-message.valid-ai');

    let snr = this.priceSearchForm.get("snr").value.toString();
    if (snr.length <= 1)
      throw this.translate.instant('menu.pricing.order-management.error-message.valid-snr');
  }

  setDate(datepicker) {
    datepicker.hideOnDateTimeSelect = false;
  }

  clearFilters() {
    this.priceSearchForm.setValue({
      snr: null,
      ai: null
    });
    this.inputDate = null;
    this.searchRequest.ai = null;
    this.searchRequest.snr = null;
    this.searchRequest.knowledgeDate = null;
    this.searchRequest.isError = false;
    this.searchRequest.error =  null;
    this.onClear.emit();
  }
}
