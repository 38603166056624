import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class OperationTypeSaGridColumns extends GridColumnBase implements GridColumnDefinition {
  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: '#',
      headerName: '',
      width: 40,
      minWidth: 40,
      rowGroup: false,
      hide: false,
      editable: false,
      filter: false,
      checkboxSelection: true
    },
    {
      field: 'prod_derivative',
      headerName: 'Derivate',
      headerValueGetter: this.localizeHeader.bind(this),
      resizable: true
    },
    {
      field: 'prod_designationtype' ,
      headerName: 'Designation Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_typecode' ,
      headerName: 'Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_satype',
      headerName: 'SA Type',
      rowGroup: true,
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_designationsa',
      headerName: 'Designation SA',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_sacode' ,
      headerName: 'SA Code',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_sop',
      headerName: 'valid from',
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_eop',
      headerName: 'valid to',
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'selected',
      headerName: 'selected',
      width: 40,
      minWidth: 40,
      checkboxSelection: true,
      headerValueGetter: this.localizeHeader.bind(this),
      cellRendererParams: { checkbox: true },
      hide: true
    }
  ];


}