import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import {ExchangeRatesService} from "../../service/exchange-rates.service";
import {ExchangeRateState} from "../../model/exchange-rates.model";
import {PlantChangeService} from "../../../header/service/PlantChangeService";
import {Plant} from "../../../order/model/order.model";
import {SystemGlobalCache} from "../../../../shared/system.global.cache";

export class ExchangeRatesGridColumns {

  foreignCurrencies = [];
  selectedPlant: string;

  constructor(private translate: TranslateService, private exchangeRatesService: ExchangeRatesService, private plantService: PlantChangeService ) {
    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadForeignCurrencies();
      }
    });
    this.loadForeignCurrencies();
  }


  columns = [
    {
      headerName: 'Foreign Currencies',
      field: 'foreignCurrencyParentHeader',
      cellStyle: {textAlign: 'center'},
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this),
      children:
        [
          {
            field: 'year',
            headerName: 'Year',
            resizable: true,
            rowGroup: false,
            hide: false,
            pinned: "left",
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: this.getExchangeRateYears(),
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'currency',
            headerName: 'Foreign Currency',
            resizable: true,
            rowGroup: false,
            hide: false,
            pinned: "left",
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
              values: this.foreignCurrencies,
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
        ]
    },
    {
      headerName:'Monthly Currency Rates',
      field: 'monthlyParentHeader',
      cellStyle: {textAlign: 'center'},
      headerValueGetter: this.localizeHeader.bind(this),
      children: [
        {
          field: 'jan',
          headerName: 'Jan',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this),

        },
        {
          field: 'feb',
          headerName: 'Feb',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'mar',
          headerName: 'Mar',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'apr',
          headerName: 'Apr',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'may',
          headerName: 'May',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'jun',
          headerName: 'Jun',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'jul',
          headerName: 'Jul',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'aug',
          headerName: 'Aug',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'sep',
          headerName: 'Sep',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'oct',
          headerName: 'Oct',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          headerValueGetter: this.localizeHeader.bind(this),
          comparator: this.amountComparator,
        },
        {
          field: 'nov',
          headerName: 'Nov',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'dec',
          headerName: 'Dec',
          hide: false,
          resizable: true,
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        }
      ]
    },
    {
      headerName:'Annual Average Exchange Rates',
      field: 'annualParentHeader',
      cellStyle: {textAlign: 'center'},
      hide: false,
      headerValueGetter: this.localizeHeader.bind(this),
      children:
      [
        {
          field: 'current' ,
          headerName: 'Current',
          resizable: true,
          pinned: "right",
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'perNov' ,
          headerName: 'Per November',
          resizable: true,
          pinned: "right",
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          comparator: this.amountComparator,
          cellStyle: this.cellStyle,
          cellRenderer: params => {
            return this.checkboxRenderer(params, 'perNovToFreeze');
          },
          headerValueGetter: this.localizeHeader.bind(this)
        },
        {
          field: 'final' ,
          headerName: 'Final',
          resizable: true,
          pinned: "right",
          rowGroup: false,
          type: 'rightAligned',
          cellEditor: 'numericCellEditor',
          valueFormatter: this.numberFormatter,
          cellStyle: this.cellStyle,
          comparator: this.amountComparator,
          headerValueGetter: this.localizeHeader.bind(this)
        }
      ]
    }];

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  private loadForeignCurrencies() {
    this.exchangeRatesService.getForeignCurrencies().valueChanges.subscribe(({data, loading}) => {
      data.foreignCurrencies.forEach((isoCurrency) => {
        if (!this.foreignCurrencies.includes(isoCurrency.currency)){
          this.foreignCurrencies.push(isoCurrency.currency);
        }
      })
      this.foreignCurrencies.sort();
    });
  }

  getExchangeRateYears(){
    return this.exchangeRatesService.getExchangeRateYears();
  }

  cellStyle(params) {
    if ((params.colDef.field == "perNov" && params.data.perNovPend == "true") ||
      (params.colDef.field == "final" && params.data.finalPend == "true")){
      // return { backgroundColor:  "#BDC6D6" };
      return { backgroundColor:  "#B0C3B0" };
    }else if (params.colDef.field == "perNov" && params.data.perNovPendState == ExchangeRateState.FROZEN){
      return { backgroundColor:  "#FFE2CD" };
    }
  }

  numberFormatter(params) {
    if ( params.value == null || params.value == "" ) return 0;
    return Number(params.value);
  }

  private checkboxRenderer(params, checkboxName) {
    if (params.node.data["perNovPendState"] == ExchangeRateState.APPROVED && params.node.data["perNovPend"] == "false") {
      let span = document.createElement("span");
      span.style.cssText = 'float: right;'
      let checkbox = this.createCheckbox(checkboxName, params);
      checkbox.style.cssText = 'margin-left: 10px;'
      let label = document.createElement('label');
      let tn = document.createTextNode(params.value);
      label.htmlFor = checkboxName;
      label.appendChild(tn);
      span.appendChild(label);
      span.appendChild(checkbox);
      return span;
    }
    else{
      return this.getBasicRender(params);
    }
  }

  private getBasicRender(params){
    let span = document.createElement("span");
    span.innerHTML = params.value;
    span.style.cssText = 'float: right;'
    return span;
  }

  private createCheckbox(checkboxName, params) {
    let checkbox = document.createElement('input');
    checkbox.type = "checkbox";
    checkbox.id = checkboxName;
    checkbox.checked = (params.node.data[checkboxName] =="true") ;
    checkbox.addEventListener('click', event => {
      if (params.node.data[checkboxName] == "true"){
        params.node.data[checkboxName] = 'false';
      }else{
        params.node.data[checkboxName] = 'true';
      }
    });
    return checkbox;
  }

  amountComparator(number1, number2) {
    if (number1 === null && number2 === null) {
      return 0;
    }
    if (number1 === null) {
      return -1;
    }
    if (number2 === null) {
      return 1;
    }
    return number1 - number2;
  }

  hideAnnualExchangeRates(params) {
    return this.selectedPlant === Plant.SAL;
  }

}
