<div class="bmw__grid">
  <bmw-dialog
    [type]="'info'"
    [show]="showDeletePrompt"
    [closeButton]="true"
    [title]="dialogTitle"
    [primaryLabel]="'Ok'"
    [primaryDisabled]="false"
    (primaryClick)="okClick()"
    (closeClick)="showDeletePrompt = false">
    <p class="bmw-regular-text">{{ 'messages.delete-error' | translate }}</p>
  </bmw-dialog>

  <form [formGroup]="configurationForm" class="reversed">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4 form-template-grid-start">
        <p class="bmw-large-text bmw-bold-text bmw__margin-top__xs"><b>{{ 'menu.budget.prod-master-data.data-import.first-header' | translate }}</b></p>
        <p class="bmw-large-text bmw__margin-top__xs">{{ 'menu.budget.prod-master-data.data-import.second-header' | translate }}</p>
      </div>
    </div>
    <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
    <div *ngIf="errorMessage" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error" (closeEvent)="closeErrorMessage()">{{errorMessage}}</bmw-inline-message>
      <div class="bmw__margin-top__l">
      </div>
    </div>

    <div *ngIf="infoMessage" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message *ngIf="infoMessage" type="info" (closeEvent)="closeInfoMessage();">
        {{infoMessage}}
      </bmw-inline-message>
    </div>

    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-6-tablet bmw__grid__cell--span-10 form-template-grid-start">
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-2 form-template-grid-start">
        <bmw-label [for]="'time-period'">{{ 'menu.budget.prod-master-data.data-import.date-selection' | translate }}</bmw-label>
        <bmw-datepicker
          #datepicker
          formControlName="timePeriod"
          [inputId]="'time-period'"
          required="true"
          placeholder="DD/MM/YYYY  -  DD/MM/YYYY"
          dateFormat="dd/mm/yy"
          showButtonBar="true"
          [(ngModel)]="datesSelected"
          showWeek="true"
          selectionMode="range"
          (keydown)="onKeyDown($event)">
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-datepicker>
      </div>
    </div>

    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">
        <bmw-combo-box formControlName="selectedDerivatives" (ngModelChange)="derivativeChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" required="true" [(ngModel)]="selectedDerivatives">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.derivative' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let product of derivativeList" [value]="product.derivative.code" [label]="product.derivative.code" ></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="derivativeCheckbox" (change)="derivativeCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">

        <bmw-combo-box formControlName="selectedProductDataStatuses" (ngModelChange)="productDataStatusChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedProductDataStatuses" required="true">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.status' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let d of productDataStatusList" [value]="d.productDataStatusCodeNo" [label]="d.productDataStatusCodeNo"></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="productDataStatusCheckbox" (change)="productDataStatusCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">
        <bmw-combo-box formControlName="selectedTypes" (ngModelChange)="typeChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedTypes" required="true">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.type' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let d of typeList" [value]="d.code" [label]="d.code"></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="typeCheckbox" (change)="typeCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">
        <bmw-combo-box formControlName="selectedProductTypes" (ngModelChange)="productionTypeChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedProductionTypes" required="true">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.product-type' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let d of productionTypeList" [value]="d.productionTypeCode" [label]="d.productionTypeCode"></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="productionTypeCheckbox" (change)="productionTypeCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">
        <bmw-combo-box formControlName="selectedSpecialEquipments" (ngModelChange)="specialEquipmentChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedSpecialEquipments" required="true">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.sa-code' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let d of specialEquipmentList" [value]="d.code" [label]="d.code"></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="specialEquipmentCheckbox" (change)="specialEquipmentCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-4-tablet bmw__grid__cell--span-6 form-template-grid-start">
        <bmw-combo-box formControlName="selectedBuildTypes" (ngModelChange)="buildTypeChanged($event)" [type]="'MULTI'" placeholder="{{ 'messages.please-select' | translate }}" [(ngModel)]="selectedBuildTypes" required="true">
          <bmw-label>{{ 'menu.budget.prod-master-data.data-import.build-type' | translate }}</bmw-label>
          <bmw-combo-box-dropdown-item *ngFor="let d of buildTypeList" [value]="d.buildTypeCode" [label]="d.buildTypeCode"></bmw-combo-box-dropdown-item>
          <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
        </bmw-combo-box>
        <bmw-checkbox formControlName="buildTypeCheckbox" (change)="buildTypeCheckboxChanged($event);">{{ 'menu.budget.prod-master-data.data-import.select-all' | translate }}</bmw-checkbox>
      </div>

    </div>
    <div class="bmw__margin-top__l">
    </div>

    <div class="pull-button-right" [hidden]="noSeriesCalculationRole">
      <bmw-button button="primary" type="submit" (clickEvent)="saveConfiguration()">
        {{ 'component.bottom-bar.save' | translate }}
      </bmw-button>
    </div>

  </form>
</div>
