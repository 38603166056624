export const environment = {
  production: false,
  authServiceWebsocketUrl: 'ws://localhost:8080/order-service/changes',
  //authServiceWebsocketUrl: 'ws://faas20nsf-int.bmwgroup.net/order-service/changes',
  productServiceWebsocketUrl: 'ws://localhost:8080/product-service/import',
  sso: {
    internal: {
      issuer: 'https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/intranetb2x',
      clientId: '35bf35d5-360c-402b-afc4-d10d85abde73'
    },
    external: {
      issuer: 'https://auth-i.bmwgroup.com:443/auth/oauth2/realms/root/realms/internetb2x',
      clientId: '999a29b2-b52e-4e98-8d55-18b48cd6fbe9'
    },
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    scope: 'openid profile email groups',
    redirectUri: window.location.origin,
    oidc: true
  },
  internalUrlPattern: 'faas-int.eu'
};
