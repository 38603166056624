import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class TabSelectionService {
  private subject = new Subject<any>();

  sendSelectedTabName(name: string) {
    this.subject.next({ text: name });
  }

  onChange(): Observable<any> {
    return this.subject.asObservable();
  }
}
