<bmw-ag-grid-theme>
  <div *ngIf="errorMessage">
    <div class="bmw__grid__inner bmw__margin-top__xs">
      <div
        class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-10-tablet bmw__grid__cell--span-8 main-form">
        <bmw-inline-message type="error">
          {{errorMessage}}
        </bmw-inline-message>
      </div>
      <div
        class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-4 main-form search-button">
        <bmw-button button="primary" type="submit" (clickEvent)="addRole()">
          {{ 'component.bottom-bar.add' | translate }}
        </bmw-button>
      </div>
    </div>
  </div>
  <div *ngIf="!errorMessage" class="bmw__margin-top__xs" [hidden]="!hideAddNewRoleView">
    <div class="bmw__margin-top__xs">
      <app-roles-overview-grid
        [rowData]="rowData"
        (addRole)="addRole()"
        (refresh)="refreshData()"
        (doubleClick)="editRole($event)"
      ></app-roles-overview-grid>
    </div>
  </div>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="saving..."
                       [show]="loading"></bmw-progress-circle>
  <form [formGroup]="roleMaintenanceForm" class="reversed" [hidden]="hideAddNewRoleView">
    <div *ngIf="this.selectedRole == null">
      <h4 class="bmw-h4 bmw__margin-top__xs">Add New Role</h4>
    </div>
    <div *ngIf="this.selectedRole != null">
      <h4 class="bmw-h4 bmw__margin-top__xs">Modify - {{this.selectedRole?.name}}</h4>
    </div>
    <div class="bmw__margin-top__l" *ngIf="this.selectedRole == null">
      <bmw-inputfield id="roleName">
        <bmw-label>Role*</bmw-label>
        <input
          bmwInput
          type="text"
          name="roleName"
          placeholder="E.g.: Change Management"
          formControlName="roleName"
          data-e2e="roleName"
          style="text-transform: uppercase;"
        />
        <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
      </bmw-inputfield>
    </div>
    <div class="bmw__margin-top__m">
      <bmw-combo-box [placeholder]="'Please select'" formControlName="workAreaText">
        <bmw-label>Work Area*</bmw-label>
        <bmw-combo-box-dropdown-item *ngFor="let d of workArea" value="{{d.name}}"
                                     label="{{d.value}}"></bmw-combo-box-dropdown-item>
      </bmw-combo-box>
    </div>
    <div class="bmw__margin-top__m">
      <bmw-label for="validity-period">Valid From - Valid To*</bmw-label>
      <bmw-datepicker
        #datepicker
        [inputId]="'validity-period'"
        required="true"
        placeholder="DD.MM.YYYY"
        dateFormat="dd.mm.yy"
        showButtonBar="true"
        [(ngModel)]="period"
        showWeek="true"
        selectionMode="range"
        [ngModelOptions]="{standalone: true}"
        (keydown)="onKeyDown($event)">
      </bmw-datepicker>
    </div>
    <div class="bmw__margin-top__m">
      <app-role-grid [rowData]="permissionRawData"
                     (cancelView)="cancel()"
                     (saveRole)="saveRole($event)"></app-role-grid>
    </div>
  </form>
</bmw-ag-grid-theme>
