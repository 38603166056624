export class TypeSpecialEquipmentTransformer {

  getTypeSpecialEquipmentGrouping() {
    let grouping = new Map();
    grouping.set('T', {
      groupName: 'Type',
      groupOrder: 1
    });
    grouping.set('L', {
      groupName: 'Body and roof colour',
      groupOrder: 2
    });
    grouping.set('P', {
      groupName: 'Design Trims',
      groupOrder: 3
    });
    grouping.set('PA', {
      groupName: 'Packet',
      groupOrder: 4
    });
    grouping.set('LA', {
      groupName: 'Execution',
      groupOrder: 5
    });
    grouping.set('SA', {
      groupName: 'Single SA',
      groupOrder: 6
    });
    return grouping;
  }
}
