import gql from "graphql-tag";
import {
  CurrentUser,
  IbvUserRoles,
  JwtAuthenticationResponse,
  MenuAvailabilityResponse,
  Permission,
  Role,
  UserRoles
} from "../model/user.model";


export const ROLES_TOKEN = gql`
  query token {
      token {
        token
      }
  }
`;

export const PROD_MENU_AVAIL = gql`
  query menuEnabledInProd {
      menuEnabledInProd {
        isEnabled
      }
  }
`;

export const INVALIDATE_SESSION = gql`
  query logout {
     logout {
       fullName
       userName
     }
  }
`;

export const IBV_USER_LOGGED_IN_USER = gql`
  query ibvCurrentUser {
      ibvCurrentUser {
        username
        fullName
        plants
        permissions
      }
  }
`;

export const IBV_USER_BY_Q_NUMBER = gql`
  query ibvUser($qNumber: String!) {
      ibvUser(qNumber: $qNumber) {
        username
        fullName
        plants
        permissions
      }
  }
`;

export const CURRENT_LOGGED_IN_USER = gql`
    query currentUser {
      currentUser {
        CNumber
        QNumber
        name
        role {
          creator
          info
          internalIdentifier
          name
          plant
          workArea
          permissions {
            internalIdentifier
            name
          }
        }
      }
   }
`;

export const ROLES_FOR_PLANT = gql`
  query roles($plant: PlantInput!) {
      roles(plant: $plant) {
        creator
        info
        internalIdentifier
        name
        plant
        workArea
        permissions {
          name
          internalIdentifier
        }
        validity {
          fromDate
          toDate
        }
      }
  }
`;

export const ROLES_FOR_ALL_PLANTS = gql`
  query allRoles {
      allRoles {
        creator
        info
        internalIdentifier
        name
        plant
        workArea
        permissions {
          name
          internalIdentifier
        }
        validity {
          fromDate
          toDate
        }
      }
  }
`;

export const ALL_PERMISSIONS = gql`
  query permissions {
      permissions {
        internalIdentifier
        name
        description
      }
  }
`;

export const ALL_USER_ROLES = gql`
  query userRoles {
    userRoles {
      QNumber
      plant
      roleName
      validity {
        fromDate
        toDate
      }
    }
  }
`

export const USER_BY_Q_NUMBER = gql`
  query user($qNumber: String!) {
      user(qNumber: $qNumber) {
        CNumber
        QNumber
        name
        validity {
          fromDate
          toDate
        }
        role {
          creator
          info
          internalIdentifier
          name
          plant
          workArea
          permissions {
            internalIdentifier
            name
          }
        }
      }
  }
`;

export interface AllRolesResponse {
  allRoles: Role[];
}

export interface CurrentUserResponse {
  currentUser: CurrentUser;
}

export interface RoleResponse {
  roles: Role[];
}

export interface PermissionResponse {
  permissions: Permission[];
}

export interface UserResponse {
  user: CurrentUser;
}

export interface UserRolesResponse {
  userRoles: UserRoles;
}

export interface IbvRoleResponse {
  ibvUser: IbvUserRoles[];
}

export interface AuthResponse {
  token: JwtAuthenticationResponse;
}

export interface MenuResponse {
  menu: MenuAvailabilityResponse;
}

export interface LogOutResponse {
  logout: IbvUserRoles;
}
