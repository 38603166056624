
export class PriceCodesMapper {
  private readonly pricingCodes = new Map();

  constructor() {
    this.init();
  }

  getFormattedPricingState(priceState: string[]): string {
    try{
      return this.formatPriceStates(priceState).join(" , ");
    }catch (e) {
      return "No Order Available (11)";
    }
  }

  getFormattedPriceType(type: string): string {
    return this.normalizeWord(type);
  }

  init(): void {
    this.pricingCodes.set("OK", "00");
    this.pricingCodes.set("MULTI_SUPPLIER", "01");
    this.pricingCodes.set("EXCLUSION", "02");
    this.pricingCodes.set("MANUAL_PRICE", "03");
    this.pricingCodes.set("MIXED_PRICE", "05");
    this.pricingCodes.set("WRONG_MULTSUPPLIER_INFO", "06");
    this.pricingCodes.set("DIFF_CURR_IN_MULTIPRICE", "07");
    this.pricingCodes.set("MISSING_MULTSUPPLIER_ORDER_ZERO_PERCENT", "08");
    this.pricingCodes.set("NO_ORDER_AVAILABLE", "11");
    this.pricingCodes.set("NO_MATERIAL_AVAILABLE", "12");
    this.pricingCodes.set("MULTI_PRICE", "13");
    this.pricingCodes.set("MISSING_MULTISUPPLIER_ORDER", "15");
    this.pricingCodes.set("NO_EXCHANGE_RATE", "16");
    this.pricingCodes.set("REFERENCE_PRICE", "17");
    this.pricingCodes.set("ORDER_CLOSED", "18");
    this.pricingCodes.set("ORDER_CANCELLED", "19");
    this.pricingCodes.set("DECISION_ON_EXCLUSION_INCLUSION_IS_OPEN", "27");
    this.pricingCodes.set("CALCULATED_PRICE_ON_ACTUAL_WEIGHTING", "31");
    this.pricingCodes.set("CALCULATED_PRICE_ON_PLANNED_WEIGHTING", "33");
    this.pricingCodes.set("BILLING_PRICE_DATE_MISMATCH", "34");
    this.pricingCodes.set("NO_BILLING_FOR_MATERIAL", "35");
    this.pricingCodes.set("AMBIGUOUS_PRICE_DATA", "36");
    this.pricingCodes.set("PARTIAL_TYPE_EXCLUSION_DH", "24");
    this.pricingCodes.set("EXCLUSION_STATUS_FOUR_PARTIAL", "62");
    this.pricingCodes.set("EXCLUSION_STATUS_THREE_PARTIAL", "64");
  }

  formatPriceStates = (states: string[]): string[] => {
    let arr: any = [];
    for (let state of states) {
      arr.push(this.addCodeToWord(state));
    }
    return arr;
  };

  normalizeWord = (s: string) => {
    if ( s == undefined ) return "";
    let split = s.split("_");
    let finalWord = "";
    for(let s of split){
      finalWord += s.charAt(0).toUpperCase() + s.slice(1).toLowerCase() + " ";
    }
    return finalWord;
  }

  addCodeToWord(state: string): string {
    return this.normalizeWord(state) + " (" + this.pricingCodes.get(state) + ")";
  }
}
