<h4
  class="bmw-h4 bmw__margin-top__m bmw__margin-bottom__m">{{ 'menu.pricing.order-management.current.order-details' | translate }}</h4>
<div class="bmw__grid__inner">
  <div class="bmw__grid__cell--span-12">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell--span-5">
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.order-number' | translate }}
          :</b> {{baseOrder.orderNo}}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.snr' | translate }}:</b> {{baseOrder.snr}}</p>
        <p class="bmw-component-text"><b class="data-align">{{ 'menu.pricing.order-management.current.ai' | translate }}
          :</b> {{baseOrder.ai | aiToString }}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.plant' | translate }}:</b> {{baseOrder.plant}}
        </p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.order-state' | translate }}
          :</b> {{baseOrder.orderState}}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.currency' | translate }}
          :</b> {{baseOrder.orderCurrency}}</p>
      </div>
      <div class="bmw__grid__cell--span-6">
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.print-date' | translate }}
          :</b> {{baseOrder.changeDate}}</p>
<!--        <p class="bmw-component-text"><b-->
<!--          class="data-align">{{ 'menu.pricing.order-management.current.price-unit' | translate }}-->
<!--          :</b> {{baseOrder.priceUnit}}</p>-->
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.quantity-unit' | translate }}
          :</b> {{baseOrder.quantityUnit}}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.order-type' | translate }}
          :</b> {{baseOrder.orderType}}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.supplier-number' | translate }}
          :</b> {{baseOrder.supplierNo}}</p>
        <p class="bmw-component-text"><b
          class="data-align">{{ 'menu.pricing.order-management.current.version' | translate }}
          :</b> {{baseOrder.version}}</p>
      </div>
    </div>
  </div>
  <div class="bmw__grid__cell--span-12">
    <h4
      class="bmw-h4 bmw__margin-bottom__s">{{ 'menu.pricing.order-management.current.pricing-period' | translate }}</h4>
    <div class="bmw__margin-top__s">
      <bmw-ag-grid-theme class="bmw__margin-top__s">
        <app-bmw-grid
          [rowData]="pricingData"
          [columns]="pricingColumns">
        </app-bmw-grid>
      </bmw-ag-grid-theme>
    </div>
  </div>
</div>
<div [hidden]="!showSurcharges" class="bmw__margin-top__m">
  <bmw-fieldset [opened]="false">
    <bmw-fieldset-header (clickEmit)="reDrawSurchargeGrid($event)" (click)="reDrawSurchargeGrid($event)"><h4
      class="bmw-h4 bmw__margin-bottom__s">{{ 'menu.pricing.order-management.current.surcharge' | translate }}</h4>
    </bmw-fieldset-header>
    <bmw-fieldset-container>
        <bmw-ag-grid-theme>
          <app-bmw-grid
            [rowData]="surchargeData"
            [columns]="surchargeColumns"
            [redraw]="resizeSurchargeGrid">
          </app-bmw-grid>
        </bmw-ag-grid-theme>
    </bmw-fieldset-container>
  </bmw-fieldset>
</div>


