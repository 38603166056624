import {ICellRendererParams} from "ag-grid-community";
import {ExchangeRateState} from "../../model/exchange-rates.model";
import {TranslateService} from "@ngx-translate/core";

export class ExchangeRatesReleaseGridColumns {

  constructor(private translate: TranslateService) {
  }

  columns = [
    {
      headerName: 'Foreign Currencies',
      field: 'foreignCurrencyParentHeader',
      type: 'rightAligned',
      headerValueGetter: this.localizeHeader.bind(this),
      children:
        [
          {
            field: 'year',
            headerName: 'Year',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            hide: false,
            pinned: "left",
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'currency',
            headerName: 'Foreign Currency',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            hide: false,
            pinned: "left",
            headerValueGetter: this.localizeHeader.bind(this)
          },
        ]
    },
    {
      headerName:'Monthly Currency Rates',
      field: 'monthlyParentHeader',
      cellStyle: {textAlign: 'center'},
      headerValueGetter: this.localizeHeader.bind(this),
      children:
        [
          {
            field: 'jan',
            headerName: 'Jan',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'feb',
            headerName: 'Feb',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'mar',
            headerName: 'Mar',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'apr',
            headerName: 'Apr',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'may',
            headerName: 'May',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'jun',
            headerName: 'Jun',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'jul',
            headerName: 'Jul',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'aug',
            headerName: 'Aug',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'sep',
            headerName: 'Sep',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'oct',
            headerName: 'Oct',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'nov',
            headerName: 'Nov',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            cellEditor: 'numericCellEditor',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'dec',
            headerName: 'Dec',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            rowGroup: false,
            type: 'rightAligned',
            comparator: this.amountComparator,
            cellEditor: 'numericCellEditor',
            headerValueGetter: this.localizeHeader.bind(this)
          }
        ]
    },
    {
      headerName: 'Annual Average Exchange Rates',
      field: 'annualParentHeader',
      headerValueGetter: this.localizeHeader.bind(this),
      children:
        [
          {
            field: 'current',
            headerName: 'Current',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            pinned: "right",
            rowGroup: false,
            type: 'rightAligned',
            comparator: this.amountComparator,
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'perNov',
            headerName: 'Per November',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            pinned: "right",
            rowGroup: false,
            comparator: this.amountComparator,
            cellRenderer: params => {
              return this.checkboxRenderer(params, 'perNovPend');
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
          {
            field: 'final',
            headerName: 'Final',
            // width: 100,
            // minWidth: 100,
            resizable: true,
            pinned: "right",
            rowGroup: false,
            type: 'rightAligned',
            comparator: this.amountComparator,
            cellRenderer: params => {
              return this.checkboxRenderer(params, 'finalPend');
            },
            headerValueGetter: this.localizeHeader.bind(this)
          },
        ]
    }];

  private checkboxRenderer(params, checkboxName) {
    if (params.node.data[checkboxName + "State"] == ExchangeRateState.PENDING_APPROVAL) {
      let span = document.createElement("span");
      span.style.cssText = 'float: right;'
      let checkbox = this.createCheckbox(checkboxName, params);
      checkbox.style.cssText = 'margin-left: 10px;'
      let label = document.createElement('label');
      let tn = document.createTextNode(params.value);
      label.htmlFor = checkboxName;
      label.appendChild(tn);
      span.appendChild(label);
      span.appendChild(checkbox);
      return span;
    }
    else{
      return this.getBasicRender(params);
    }
  }

  private getBasicRender(params){
    let span = document.createElement("span");
    span.innerHTML = params.value;
    span.style.cssText = 'float: right;'
    return span;
  }

  private createCheckbox(checkboxName, params) {
    let checkbox = document.createElement('input');
    checkbox.type = "checkbox";
    checkbox.id = checkboxName;
    checkbox.checked = (params.node.data[checkboxName] =="true") ;
    checkbox.addEventListener('click', event => {
      if (params.node.data[checkboxName] == "true"){
        params.node.data[checkboxName] = 'false';
      }else{
        params.node.data[checkboxName] = 'true';
      }
    });
    return checkbox;
  }

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }

  amountComparator(number1, number2) {
    if (number1 === null && number2 === null) {
      return 0;
    }
    if (number1 === null) {
      return -1;
    }
    if (number2 === null) {
      return 1;
    }
    return number1 - number2;
  }

}
