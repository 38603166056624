import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {OperationsDisplayGridColumns} from './operations-display-grid.columns';
import {AgGridBase} from "../../../../ag.grid.base";
import {Operation, OperationHistory, OperationSummary} from "../../model/operations.model";
import {OperationInformationCellRenderer} from "./operation-information-cell-renderer.component";
import {OperationService} from "../../service/operation.service";


@Component({
  selector: 'app-operations-display-grid',
  templateUrl: './operations-display-grid.component.html',
  styleUrls: ['./operations-display-grid.component.scss']
})
export class OperationsDisplayGridComponent extends AgGridBase<OperationsDisplayGridColumns> {

  @Input() hasCaptureRole: boolean;

  @Output() deleteOperation = new EventEmitter<Operation>();
  @Output() newOperation = new EventEmitter<any>();
  @Output() editOperation = new EventEmitter<any>();
  @Output() openExistingOperation = new EventEmitter<any>();
  @Output() cloneExistingOperation = new EventEmitter<Operation>();
  @Output() operationSelected = new EventEmitter<any>();
  @Output() operationHistory = new EventEmitter<OperationHistory>();
  @Output() clearFilters = new EventEmitter<any>();

  selected: Operation;
  frameworkComponents: any;
  isRowSelected: boolean = false;
  isOperationReleased: boolean;
  dialogTitle: String;
  isRowSelectedForDelete: boolean = false;
  isRowSelectedForEdit: boolean = false;
  statusForEdit = ["IN_PROGRESS", "RESET", "DECLINED"];
  statusForDelete = ["IN_PROGRESS"];
  rowSelection = 'single';

  constructor(private translate: TranslateService, private operationService: OperationService) {
    super(new OperationsDisplayGridColumns(translate, operationService));
    this.frameworkComponents = {
      dialogueComponent: OperationInformationCellRenderer
    }
    this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.historytitle');
  }

  processNewOperation() {
    this.newOperation.emit(this.selected);
  }

  openOperation() {
    this.openExistingOperation.emit(this.selected);
  }

  onCloneOperation() {
    this.cloneExistingOperation.emit(this.selected);
  }

  rowDoubleClicked(param: any) {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.isRowSelected = true;
      this.selected = this.gridApi.getSelectedRows()[0];
      this.operationSelected.emit(this.selected);
    }
    this.openExistingOperation.emit(this.selected);
  }

  onRowSelected(): void {
    this.isRowSelectedForEdit = false;
    this.isRowSelectedForDelete = false;
    this.isRowSelected = this.gridApi.getSelectedRows().length === 1;
    this.selected = this.isRowSelected ? this.gridApi.getSelectedRows()[0] : undefined;
    if (this.selected) {
      this.isRowSelectedForDelete = this.statusForDelete.includes(this.selected.workFlowStatus);
      this.isRowSelectedForEdit = this.statusForEdit.includes(this.selected.workFlowStatus);
      this.operationSelected.emit(this.selected);
      this.isOperationReleased = this.selected.workFlowStatus === 'RELEASED' ? true : false;
    } else {
      this.operationSelected.emit(undefined);
    }
  }

  onModelUpdated(params) {
    super.onModelUpdated(params);
    this.highlightSelectedOperation();
  }

  onEditOperation() {
    this.editOperation.emit(this.selected);
  }

  onDeleteOperation() {
    this.deleteOperation.emit(this.selected);
  }

  onDownloadClose() {
    this.suppliedColumns.showAttachments = false;
  }

  onOperationHistoryClose() {
    this.suppliedColumns.showWorkFlow = false;
    this.suppliedColumns.workFlows = new Array<OperationSummary>();
  }

  onClearFilters(){
    this.gridApi.setFilterModel(null);
  }

  highlightSelectedOperation() {
    if (this.selected !== undefined) {
      this.gridApi.forEachNode(node => node.data.name === this.selected.name ? node.setSelected(true) : 0);
    }
  }

  cancelDownloadAttachment() {
    this.suppliedColumns.showAttachments = false;
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.runtimeCompilerData.gridOptions.api?.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

}
