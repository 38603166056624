import {Component, OnDestroy, OnInit} from '@angular/core';
import moment from "moment";
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../header/service/LanguageChangeService";
import {Subscription} from "rxjs";
import {NotificationService} from "./service/notification.service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit , OnDestroy {

  data: any;
  notificationColumns: any = [];
  subscription: Subscription;
  resizeGrid: boolean;
  rowData: any;

  constructor(private translate: TranslateService,
              private langService: LanguageChangeService,
              private notificationService: NotificationService) {

    this.subscription = this.langService.onChange().subscribe( e=> {
      this.translateAndLoadColumns(e.text);
    });
  }

  ngOnInit(): void {
    this.translateAndLoadColumns("en");
    this.loadData();
  }

  translateAndLoadColumns(lang: string) {
    if (lang == "en") {
      this.notificationColumns = [
        {field:'notificationDate',headerName: "Date", valueFormatter: this.dateFormatter , sortable: true , width: 60},
        {field:'messageType',headerName: "Message Type" , sortable: false , width: 60},
        {field:'notificationPriority',headerName: "Priority", cellStyle: this.priorityFormatter, sortable: false , width: 60},
        {field:'notificationMessage',headerName: "Message", sortable: false , resizable: true},
      ];
    } else if (lang == "zh") {
      this.notificationColumns = [
        {field:'notificationDate',headerName: "日期", valueFormatter: this.dateFormatter , sortable: true , width: 60},
        {field:'messageType',headerName: "消息类型" , sortable: false , width: 60},
        {field:'notificationPriority',headerName: "优先级", cellStyle: this.priorityFormatter, sortable: false , width: 60},
        {field:'notificationMessage',headerName: "消息", sortable: false , resizable: true},
      ];
    } else {
      this.notificationColumns = [
        {field:'notificationDate',headerName: "Datum" , valueFormatter: this.dateFormatter , sortable: true , width: 60},
        {field:'messageType',headerName: "Nachrichtentyp" , sortable: false , width: 60},
        {field:'notificationPriority',headerName: "Priorität" , cellStyle: this.priorityFormatter, sortable: false , width: 60},
        {field:'notificationMessage',headerName: "Nachricht", sortable: false , resizable: true},
      ];
    }

    this.resizeGrid = true;
  }


  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  dateFormatter(params) {
    if (params.value === undefined ) {
      return '';
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  priorityFormatter(params){
    if (params.value === undefined ) {
      return '';
    }
    if (params.value === "High") {
      return {color: 'red', fontWeight: 'bold'};
    }
  }

  private loadData() {
    this.data = this.notificationService.getNotificationMessages();
    this.translateAndLoadColumns(this.translate.currentLang);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
