<bmw-ag-grid-theme>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
  <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="info">{{message}}</bmw-inline-message>
  </div>
  <app-manufacturing-costs-actioned-grid data-testid="grid-rule-history-grid"
                         [rowData]="costsToBeActionedData"
                         (operationSelected) = "setSelectedOperation($event)"
                         (openExistingOperation)="openOperation($event)"
                         (deleteOperation)="deleteOperations($event)"
                         [hasCaptureRole]="hasCaptureRole"
  ></app-manufacturing-costs-actioned-grid>
  <br/>
</bmw-ag-grid-theme>
<bmw-dialog
  [type]="'info'"
  [show]="showPrompt"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showPrompt = false"
  (closeClick)="showPrompt = false"
>
  <p class="bmw-regular-text">{{ dialogText }}</p>
</bmw-dialog>
<bmw-dialog data-testid="dialog-delete"
            [type]="'warning'"
            [show]="showDeleteConfirm"
            [closeButton]="true"
            [title]="dialogTitle"
            [primaryLabel]="'Ok'"
            [secondaryLabel]="'Cancel'"
            [primaryDisabled]="false"
            [secondaryDisabled]="false"
            (primaryClick)="deleteOperation()"
            (secondaryClick)="showDeleteConfirm = false"
            (closeClick)="showDeleteConfirm = false">
  <p class="bmw-regular-text">{{ 'messages.confirm-deletion-text' | translate }}
  </p>
</bmw-dialog>
