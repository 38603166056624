import {Plant} from "../../order/model/order.model";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

export class ExchangeRate {
  internalIdentifier: number;
  value: number;
  currency: ISOCurrency;
  baseCurrency: ISOCurrency;
  exchangeRateType: ExchangeRateType;
  year: number;
  month: number;
  plant: Plant;
  state: ExchangeRateState;
  statusReason: string;
  statusDate:string;
  user: string;
  relatedExchangeRates: ExchangeRate[];
}

export enum CurrencyType {
  BASE = 'BASE', FOREIGN = 'FOREIGN'
}

export enum ExchangeRateType {
  YEAR_FINAL = 'YEAR_FINAL',
  YEAR_ACCRUAL = 'YEAR_ACCRUAL',
  IN_MONTH_F2 = 'IN_MONTH_F2',
  PER_MONTH_CURR_F_C = 'PER_MONTH_CURR_F_C',
  YEAR_BUDGET = 'YEAR_BUDGET'
}

export class ISOCurrency {
  internalIdentifier: number;
  plant: Plant;
  currency: string;
  scale: number;
  displayFormat: string;
  currencyType: CurrencyType;

  dummyBaseCurrency() : ISOCurrency {
    this.internalIdentifier = 0;
    this.currencyType = CurrencyType.BASE;
    this.currency = "";
    this.scale = 5;
    this.plant = SystemGlobalCache.plant()
    return this;
  }
}

export enum ExchangeRateState {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  FROZEN = 'FROZEN',
  UPDATED = 'UPDATED',
  NEW = 'NEW'
}
