import gql from "graphql-tag";
import {Operation} from "../model/operations.model";
import {Type} from "../../product/model/product.model";

export const OPERATION_BY_ID = gql `
query operationById($id: Long!) {
	operationById(id: $id) {
		files {
    content
    internalIdentifier
    name
    }
    internalIdentifier
    operationId
    name
    plant
    releaseDate
    statusDate
    statusReason
    statusUser
    validFrom
    workFlowStatus
    rules {
      derivativeCode
      internalIdentifier
      royaltiesIndicator
      status
    }
    manufacturingCosts {
      derivativeCode
      internalIdentifier
      packet {
        code
        generation
        internalIdentifier
        name
        sequence
        validity {
          fromDate
          toDate
        }
      }
      previousManufacturingCosts {
        derivativeCode
        internalIdentifier
      }
      price {
        value
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
        validity {
          fromDate
          toDate
        }
      }
      transferPrice {
        internalIdentifier
        royaltiesIndicator
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
        productionType {
          productionTypeCode
          productionTypeImportStatus
        }
        validity {
          fromDate
          toDate
        }
      }
      previousManufacturingCosts {
        derivativeCode
        internalIdentifier
        price {
          value
        }
      }
    }
	}
}`;

export const CHILD_OPERATION_BY_PARENT_ID = gql `
query childOperationByParentId($id: Long!) {
	childOperationByParentId(id: $id) {
		files {
    content
    internalIdentifier
    name
    }
    internalIdentifier
    operationId
    name
    plant
    releaseDate
    statusDate
    statusReason
    statusUser
    validFrom
    workFlowStatus
    rules {
      derivativeCode
      internalIdentifier
      royaltiesIndicator
      status
    }
    manufacturingCosts {
      derivativeCode
      internalIdentifier
      packet {
        code
        generation
        internalIdentifier
        name
        sequence
      }
      previousManufacturingCosts {
        derivativeCode
        internalIdentifier
      }
      price {
        value
      }
      specialEquipment {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
      transferPrice {
        internalIdentifier
        royaltiesIndicator
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
      }
    }
	}
}`;

export const EXECUTION_SPECIAL_EQUIPMENT_QUERY = gql`
query productExecutionSpecialEquipmentCountryCodeByTypes($plant: Plant!, $typeCodes: [String]!){
  productExecutionSpecialEquipmentCountryCodeByTypes(plant: $plant, typeCodes: $typeCodes) {
    code
    specialEquipment {
      code
      name
      specialEquipmentImportStatus
    }
  }
}`;

export const OPERATION_BY_NAME = gql `
query operationByName($name: String!) {
	operationByName(name: $name) {
        files {
        content
        internalIdentifier
        name
        }
        internalIdentifier
        operationId
        name
        plant
        releaseDate
        statusDate
        statusReason
        statusUser
        validFrom
        workFlowStatus
        rules {
          derivativeCode
          internalIdentifier
          royaltiesIndicator
          status
        }
        manufacturingCosts {
          derivativeCode
          internalIdentifier
          packet {
            code
            generation
            internalIdentifier
            name
            sequence
            validity {
              fromDate
              toDate
            }
          }
          previousManufacturingCosts {
            derivativeCode
            internalIdentifier
          }
          price {
            value
          }
          specialEquipment {
            code
            equipment
            internalIdentifier
            name
            specialEquipmentImportStatus
            type
            validity {
              fromDate
              toDate
            }
          }
          transferPrice{
            internalIdentifier
            royaltiesIndicator
          }
          type {
            axlGear
            capacity
            code
            country
            cylinder
            engineName
            engineSeries
            equipBase
            fuel
            internalIdentifier
            name
            steering
            typeImportStatus
            productionType {
              productionTypeCode
              productionTypeImportStatus
            }
            validity {
              fromDate
              toDate
            }
          }
          previousManufacturingCosts {
            derivativeCode
            internalIdentifier
            price {
              value
            }
          }
        }
	}
}`;

export interface OperationDetailsQueryResponse{
    operationById: Operation;
}

export interface OperationDetailsByNameQueryResponse{
    operationByName: Operation;
}

export interface ChildOperationDetailsQueryResponse{
  childOperationByParentId: Operation;
}

export interface ExecutionQueryResponse {
  productExecutionSpecialEquipmentCountryCodeByTypes: Type[];
}

