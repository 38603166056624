import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-cost-allocations-dialog',
  templateUrl: './cost-allocations-dialog.component.html',
  styleUrls: ['./cost-allocations-dialog.component.scss']
})
export class CostAllocationsDialogComponent implements OnInit {
  dialogTitle: string;
  message: string;
  dialogText: string;
  @Input() showUnallocatedTypesDialog: boolean;
  @Output() saveTypeDialog = new EventEmitter();
  @Output() cancelDialog = new EventEmitter();

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.dialogTitle = this.translate.instant('dialogs.typeCost');
    this.dialogText = this.translate.instant('messages.typeCost');
  }

  ok() {
    this.saveTypeDialog.emit();
  }

  cancel() {
    this.showUnallocatedTypesDialog = false;
    this.cancelDialog.emit();
  }
}
