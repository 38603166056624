export const paths = {
  order_management: 'order-management',
  weightings: 'weightings',
  home: 'home',
  exchange_rates: 'exchange-rates-management',
  continuous_price: 'continuous-price',
  manual_prices: 'manual-prices',
  freeze_prices: 'freeze-prices',
  prod_master_data: 'prod-master-data',
  user_management: 'user-management',
  manufacturing: 'manufacturing',
  quicksight_reports: 'quicksight-reports'
}
