<bmw-dialog
  [type]="'info'"
  [show]="showAttachments"
  [closeButton]="true"
  [title]="dialogTitle"
  [secondaryLabel]="'Cancel'"
  [secondaryDisabled]="false"
  (secondaryClick)="closeDownload()"
  (closeClick)="closeDownload()">
  <div *ngIf="message">
    <bmw-inline-message type="info">{{message}}</bmw-inline-message>
  </div>
  <div *ngIf="errorMessage">
    <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
  </div>
  <div *ngIf="fileList">
    <div *ngFor="let file of fileList">
      <a class="clickable" (click)="downloadAttachment(file)">{{file.name}}</a>&nbsp;&nbsp;&nbsp;
      <a class="clickable" (click)="deleteAttachment(file.internalIdentifier)"><span class="iwp-icon-gen_delete"></span></a>
    </div>
  </div>
</bmw-dialog>
<bmw-progress-circle data-testid="progress-loadingScreen" [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
