import {Component, Input} from '@angular/core';
import {GridApi, ICellRendererParams} from 'ag-grid-community';
import {EMPTY_STRING, isUndefinedOrEmpty} from '../../../../shared/functions/typescript.utils';
import moment from "moment";
import {TranslateService} from '@ngx-translate/core';
import {AgGridBase} from '../../../../ag.grid.base';
import {RuleHistoryGridColumns} from './rule-history-grid.columns';

@Component({
  selector: 'app-rule-history-grid',
  templateUrl: './rule-history-grid.component.html',
  styleUrls: ['./rule-history-grid.component.scss']
})
export class RuleHistoryGridComponent extends AgGridBase<RuleHistoryGridColumns> {

  gridApi: GridApi;
  filtering = false;

  constructor(private translate: TranslateService) {
    super(new RuleHistoryGridColumns(translate));
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  public moneyFormatter(params): string {
    let value;
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (params.value > 0 || params.value < 0) {
      value = formatter.format(params.value);
    }
    return value;
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'specialEquipmentCode1', sort: 'asc'},
      {colId: 'specialEquipmentCode2', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.sortRulesHistory();
  }

  sortRulesHistory() {
    let statusMap = new Map();
    this.rowData = this.rowData.sort((a, b) => moment(b.validFrom).toDate().getTime() - moment(a.validFrom).toDate().getTime())
      .sort((a, b) => moment(b.releaseDate).toDate().getTime() - moment(a.releaseDate).toDate().getTime())
      .map(row => {
        let key = row.typeCode + row.specialEquipmentCode1 + row.specialEquipmentCode2;
        if (statusMap.has(key)) {
          if (row.status === 'ACTIVE') {
            row.status = 'PREVIOUS';
          }
        } else {
          statusMap.set(key, row);
        }
        return row;
      });
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  getRowStyle = params => {
    if (params.node?.data?.status === 'ACTIVE' && !params.node?.group) {
      return { 'pointer-events': 'none'};
    } else if (!params.node?.group) {
      return { 'pointer-events': 'none', opacity: '0.4' };
    }
  }
}
