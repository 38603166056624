import {Component} from '@angular/core';
import {OrderService} from "../service/order.service";
import {OrderSearchRequest} from "../model/OrderSearchRequest";
import {TranslateService} from "@ngx-translate/core";
import {faas} from "../../../constants/faas.constants";

@Component({
  selector: 'order-history-component',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],

})
export class OrderHistoryComponent {

  loading: boolean = false;
  errorMessage: string;
  formSubmitted = false;
  rowData: any;
  groupByColumns: string[];
  hideColumns: string[];

  constructor(private orderService: OrderService,
              private translate: TranslateService) {
    this.groupByColumns = [""];
    this.hideColumns = ["pricingPeriodInternalIdentifier"];
  }


  search(request: OrderSearchRequest) {
    this.loading = true;
    this.formSubmitted = true;
    this.errorMessage = undefined;
    this.preserveSearchParameters(request);

    if (request.isError) {
      this.errorMessage = request.error;
    } else {

      if (request.snrList != null && request.ai != null) {
        this.orderService.orderHistoryWithSnrAi(request.ai, request.snrList)
          .valueChanges
          .subscribe(({data, loading}) => {
            this.rowData = this.orderService.mapOrders(data.ordersForMaterial);
          }, (error) => {
            this.errorMessage = this.orderService.removeGraphQLErrorOnMessage(error.message);
          });
      } else if (request.snrList != null && request.ai == null) {
        this.orderService.orderHistoryWithSnr(request.snrList)
          .valueChanges
          .subscribe(({data, loading}) => {
            this.rowData = this.orderService.mapOrders(data.ordersForMaterial);
          }, (error) => {
            this.errorMessage = this.orderService.removeGraphQLErrorOnMessage(error.message);
          });
      }
      else if (request.orderNumber != null) {
        this.orderService.orderHistory(request.orderNumber)
          .valueChanges
          .subscribe(({data}) => {
            this.rowData = this.orderService.mapOrders(data.orders);
          }, (error) => {
            this.errorMessage = this.orderService.removeGraphQLErrorOnMessage(error.message);
          });
      }
    }
  }

  preserveSearchParameters(request: OrderSearchRequest) {
    if ( !request.isError ) {
      sessionStorage.setItem(faas.currentOrderSearchParameters, JSON.stringify(request));
    }
  }

  clearFilters() {
    this.errorMessage = null;
    this.formSubmitted = false;
    this.rowData = [];
    sessionStorage.removeItem(faas.currentOrderSearchParameters);
  }

}


