import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-exchange-rates-confirm-header',
  templateUrl: './exchange-rates-confirm-header.component.html',
  styleUrls: ['./exchange-rates-confirm-header.component.scss']
})
export class ExchangeRatesConfirmHeaderComponent implements OnInit {

  @Input() exchangeRate: any;

  constructor() { }

  ngOnInit(): void {
  }

}
