<bmw-dialog
  [type]="'warning'"
  [show]="showWorkflow"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="applyWorkflow()"
  (secondaryClick)="cancel()"
  (closeClick)="cancel()">
  <form [formGroup]="workflowForm" class="reversed">
    <bmw-inputfield id="comment">
      <bmw-label>{{ 'manufacturing-cost.tabs.operations.comment' | translate }}</bmw-label>
      <textarea
        bmwInput
        type="text"
        name="comment"
        formControlName="comment"
        placeholder="Your comment.."
        rows="8"
        maxlength="255"
      ></textarea>
      <bmw-error-message>{{ 'messages.err-field-empty' | translate }}</bmw-error-message>
    </bmw-inputfield>
  </form>
</bmw-dialog>
