import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {ProductionWeek, ProductionWeekUpdateRequest, UpdateType, WeekDerivateCode} from "../model/aggregations.models";
import {AggregationService} from "../service/aggregation.service";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-product-week-search-bar',
  templateUrl: './product-week-search-bar.component.html',
  styleUrls: ['./product-week-search-bar.component.scss']
})
export class ProductWeekSearchBarComponent implements OnInit {

  updateRequest: ProductionWeekUpdateRequest;
  productionWeekSearchForm: FormGroup;
  productionMap : Map<number,WeekDerivateCode[]>;
  loading: boolean = false;

  years: any = [];
  weeks: any = [];
  derivateCode: any = [];

  @Output() onUpdate = new EventEmitter<ProductionWeekUpdateRequest>();

  constructor(private service: AggregationService,
              private translate: TranslateService) {
    this.updateRequest = new ProductionWeekUpdateRequest();
    this.productionMap = new Map<number, WeekDerivateCode[]>();
  }

  ngOnInit(): void {
    this.productionWeekSearchForm = new FormGroup({
      snr: new FormControl(['']),
      ai: new FormControl(['']),
      productionWeek: new FormControl(['']),
      productionYear: new FormControl(['']),
      derivate: new FormControl(['']),
    });
    this.getProductionWeekForPlant();
  }

  update() {
    try {
      this.checkInvalidSelection();
      this.updateModel();
    } catch (e) {
      this.updateRequest.isError = true;
      this.updateRequest.error = e.toString();
    }
    this.onUpdate.emit(this.updateRequest);
  }


  updateModel() {

    this.getUpdateType();

    this.updateRequest.isError = false;
    this.updateRequest.error = "";
    this.updateRequest.week = this.productionWeekSearchForm.get("productionWeek").value;
    this.updateRequest.year = this.productionWeekSearchForm.get("productionYear").value;
    this.updateRequest.derivativeCode = this.productionWeekSearchForm.get("derivate").value;
    this.updateRequest.snr = this.parse(this.productionWeekSearchForm.get("snr").value);
    this.updateRequest.ai = parseInt(this.productionWeekSearchForm.get("ai").value);
    this.updateRequest.plant = SystemGlobalCache.plant();
  }

  getUpdateType() {

    let year = this.productionWeekSearchForm.get("productionYear").value;
    let week = this.productionWeekSearchForm.get("productionWeek").value;
    let derivate = this.productionWeekSearchForm.get("derivate").value;
    let snr = this.productionWeekSearchForm.get("snr").value;
    let ai = this.productionWeekSearchForm.get("ai").value;

    this.validateMaterial();

    if (this.isNumber(year) && this.isNumber(week) && this.parse(derivate) && this.parse(snr) && this.isNumber(ai)) {
      this.updateRequest.updateType = UpdateType.MaterialAndDerivateAndWeekOnly;
      return;
    }

    if (this.isNumber(year) && this.isNumber(week) && this.parse(derivate)) {
      this.updateRequest.updateType = UpdateType.DerivateAndWeekOnly;
      return;
    }

    if (this.isNumber(year) && this.isNumber(week) && this.parse(snr) && this.isNumber(ai)) {
      this.updateRequest.updateType = UpdateType.MaterialAndWeek;
      return;
    }

    if (this.parse(snr) && this.isNumber(ai)) {
      this.updateRequest.updateType = UpdateType.MaterialOnly;
      return;
    }

    if (this.isNumber(year) && this.isNumber(week)) {
      this.updateRequest.updateType = UpdateType.WeekOnly;
      return;
    }

  }

  getProductionWeekForPlant() {
    this.loading = true;
    this.service.productionWeeks().subscribe(({data, loading}) => {
      data.getProductionYearWeeksFor.forEach(p => {
        this.loadProductionWeekMap(p);
      });
      this.setYears();
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }

  loadProductionWeekMap(productionWeek: ProductionWeek) {
    let weekDerivate = new WeekDerivateCode();
    if (this.productionMap.has(productionWeek.year)) {
      let cachedWeeks = this.productionMap.get(productionWeek.year);
      weekDerivate.week = productionWeek.week;
      weekDerivate.derivativeCode = productionWeek.derivativeCode;
      cachedWeeks.push(weekDerivate);
      this.productionMap[productionWeek.year] = cachedWeeks;
    } else {
      weekDerivate.week = productionWeek.week;
      weekDerivate.derivativeCode = productionWeek.derivativeCode;
      this.productionMap.set(productionWeek.year, Array.of(weekDerivate));
    }
  }

  setYears() {
    this.productionMap.forEach( (value: WeekDerivateCode[], key: number) => {
      this.years.push(key);
    });
    this.years.reverse();
  }

  getWeeksAndDerivateCodes(year: number) {
    const weekWithDuplicates = this.productionMap[year].map(e => e.week);
    this.weeks = weekWithDuplicates.filter((n, i) => weekWithDuplicates.indexOf(n) === i).sort((a, b) => parseInt(a) - parseInt(b));
    const derivateCodesWithDuplicates = this.productionMap[year].map(e=> e.derivativeCode);
    this.derivateCode = derivateCodesWithDuplicates.filter((n, i) => derivateCodesWithDuplicates.indexOf(n) === i).sort((a, b) => a.localeCompare(b));
  }

  isNumber(s: string) {
    return Number.isInteger(parseInt(s))
  }

  parse(s: string) {
    return s?.length <= 1 ? undefined : s;
  }

  checkInvalidSelection() {

    let controlIndex = 0;

    let year = this.productionWeekSearchForm.get("productionYear").value;
    let week = this.productionWeekSearchForm.get("productionWeek").value;
    let derivate = this.productionWeekSearchForm.get("derivate").value;
    let snr = this.productionWeekSearchForm.get("snr").value;
    let ai = this.productionWeekSearchForm.get("ai").value;

    if (this.isNumber(year)) controlIndex = 1;
    if (this.isNumber(week)) controlIndex += 2;
    if (this.parse(derivate)) controlIndex += 3;
    if (this.parse(snr)) controlIndex += 4;
    if (this.isNumber(ai)) controlIndex += 5;

    if (controlIndex == 4 || controlIndex == 5 || controlIndex == 7 || controlIndex == 10)
      throw this.translate.instant('messages.material-invalid-incorrect-combination');

    if (controlIndex == 1 || controlIndex == 2)
      throw this.translate.instant('messages.production-week-invalid-incorrect-combination');

    if (controlIndex == 0 || controlIndex == 8 || controlIndex == 13)
      throw this.translate.instant('messages.please-select-prod-week-or-material');
  }

  validateMaterial() {
    let snr = this.productionWeekSearchForm.get("snr").value;
    let ai = this.productionWeekSearchForm.get("ai").value;

    if ((!_.isEmpty(snr?.toString().trim()) && _.isEmpty(ai?.toString().trim()))
      || (_.isEmpty(snr?.toString().trim()) && !_.isEmpty(ai?.toString().trim())))
      throw this.translate.instant('messages.material-invalid-incorrect-combination');

    return true;
  }
}
