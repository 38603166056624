<div class="bmw__grid">
  <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
    <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.manufacturing-cost.title' | translate }}</h2>
    <div class="bmw__margin-top__l">
    </div>
    <div class="table-wrapper bmw__margin-top__s">
      <bmw-content-tab #contentTab (selectionChange)="changeTab($event)" role="tablist">
        <bmw-content-tab-label
          for="operations">{{ 'menu.manufacturing-cost.tabs.operations.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="operations">
          <app-operations-display
            (onOperationDetailsClick)="changeTabToOperationsDetails($event)"></app-operations-display>
        </bmw-content-tab-content>
        <bmw-content-tab-label
          for="operationDetails">{{ 'menu.manufacturing-cost.tabs.opsdetail.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="operationDetails">
          <app-operation-details></app-operation-details>
        </bmw-content-tab-content>
        <bmw-content-tab-label
          for="manufacturingCosts">{{ 'menu.manufacturing-cost.tabs.transfer-price.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="manufacturingCosts">
          <app-manufacturing-costs></app-manufacturing-costs>
        </bmw-content-tab-content>
        <bmw-content-tab-label matBadgeSize="small" matBadgeColor="primary" matBadge="{{tobeActionedCount}}"
                               for="tobeActioned">{{ 'menu.manufacturing-cost.tabs.tobe-actioned.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="tobeActioned">
          <app-manufacturing-costs-actioned
            (onOperationDetailsClick)="changeTabToOperationsDetails($event)"></app-manufacturing-costs-actioned>
        </bmw-content-tab-content>
        <bmw-content-tab-label
          for="rulesHistory">{{ 'menu.manufacturing-cost.tabs.rules-history.title' | translate }}</bmw-content-tab-label>
        <bmw-content-tab-content id="rulesHistory">
          <app-rule-history></app-rule-history>
        </bmw-content-tab-content>
      </bmw-content-tab>
    </div>
  </div>
</div>
<bmw-dialog
  [type]="'info'"
  [show]="showPrompt"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showPrompt = false"
  (closeClick)="showPrompt = false"
>
  <p class="bmw-regular-text">{{ dialogText }}</p>
</bmw-dialog>
