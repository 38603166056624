import { VehicleStatus } from '../model/vehicle.model';

export class VehicleStatusTransformer {
  constructor() {
  }

  transformVehicleStatus(vehicleStatus: VehicleStatus) {
    return {
      derivate: vehicleStatus.derivate,
      modelTypeCode: vehicleStatus.vehicleModelType,
      order: vehicleStatus.requestNumber,
      prodType: vehicleStatus.productGroup,
      vin: vehicleStatus.vin17,
      transferPrice: vehicleStatus.transferPrice.amount.value,
      f2Date: vehicleStatus.f2Date,
      createdDate: vehicleStatus.createdDate.toString().split('T')[0],
      plannedDate: vehicleStatus.plannedDate,
      transferPriceProd: vehicleStatus.validityDate,
      vehicleStatus: vehicleStatus.transferPrice.vehiclePriceStatus,
      reason: vehicleStatus.transferPrice.reason,
      specialEquipments: vehicleStatus.specialEquipments
    };
  }
}
