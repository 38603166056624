<bmw-dialog
  [type]="'info'"
  [show]="showUpload"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="addAttachment($event)"
  (secondaryClick)="cancel()"
  (closeClick)="cancel()">
  <div *ngIf="message">
    <bmw-inline-message type="error">{{message}}</bmw-inline-message>
  </div>
  <p class="bmw-regular-text">{{ dialogText }}
  </p>
  <form [formGroup]="fileForm" class="reversed">
    <bmw-inputfield id="attachFile">
      <input
        bmwInput
        bmwInputFile
        type="file"
        name="attachFile"
        accept=".xlsx ,.xls ,.doc ,.docx ,.pdf ,.csv ,.ser ,.txt ,.pptx ,.ppt ,.pptm"
        placeholder="{{ 'messages.choosefile' | translate }}"
        formControlName="attachFile"
        (change)="onFileChange($event)"
      />
    </bmw-inputfield>
  </form>
</bmw-dialog>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Please wait..." [show]="processing"></bmw-progress-circle>
