import {TranslateService} from "@ngx-translate/core";
import {ICellRendererParams} from "ag-grid-community";
import moment from "moment";
import {PriceUnit} from "../../../../order/model/order.model";
import {padLeft} from "../../../../../shared/functions/padding";
import {EMPTY_STRING} from "../../../../../shared/functions/typescript.utils";


export class FreezePriceDetailsGridColumns {

  constructor(private translate: TranslateService) {
  }

  hideColumns(col: string[]): void {
    if ( col == undefined )
      return;

    this.columns.forEach(c => {
      if ( col.includes(c.field) ) {
        c.hide = true;
      }
    });
  }

  columns = [
    {
      field: '#',
      headerName: '',
      width: 80,
      minWidth: 80,
      rowGroup: false,
      hide: false,
      editable: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
    },
    {
      field: 'freezePriceSNR',
      headerName: 'SNR',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      cellClass: 'stringType',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'freezePriceAI',
      headerName: 'AI',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      valueFormatter: this.aiFormatter,
      headerValueGetter: this.localizeHeader.bind(this),
      cellClass: 'stringType'
    },
    {
      field: 'uom',
      headerName: 'UoM',
      resizable: true,
      rowGroup: false,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'fDate' ,
      headerName: 'Valid From (Curr.MK(F2))',
      resizable: true,
      hide: false,
      editable: false,
      valueFormatter: this.dateFormatter,
      cellClass: 'dateType',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'oldNettPriceValue' ,
      headerName: 'Old MK',
      resizable: true,
      hide: false,
      editable: false,
      cellStyle: {'text-align': 'right'},
      cellClass: 'currencyFormat',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'nettPriceFreezePriceValue' ,
      headerName: 'New MK',
      resizable: true,
      hide: false,
      editable: false,
      cellStyle: {'text-align': 'right'},
      cellClass: 'currencyFormat',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'currencyNew' ,
      headerName: 'Currency',
      resizable: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'priceState' ,
      headerName: 'priceState',
      resizable: true,
      hide: false,
      editable: false,
      cellClass: 'stringType',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'pe' ,
      headerName: 'PE',
      resizable: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'orderNo' ,
      headerName: 'Order No',
      resizable: true,
      hide: false,
      editable: false,
      cellClass: 'stringType',
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'version' ,
      headerName: 'Order Version',
      resizable: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'priceType' ,
      headerName: 'Price Type',
      resizable: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
    {
      field: 'wfStatus',
      headerName: 'WF Status',
      resizable: true,
      hide: false,
      editable: false,
      headerValueGetter: this.localizeHeader.bind(this)
    },
  ];

  localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.field;
    return this.translate.instant(headerIdentifier);
  }


  dateFormatter(params) {
    if (params.value == null || params.value == undefined){
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  priceUnitFormatter(params) {
    return PriceUnit[params.value];
  }

  aiFormatter(params) {
    if (params.value != undefined) {
      return padLeft(params.value,2);
    }
  }

}
