import {Price, PriceDetail, PriceWorkflowState} from "../model/continous.price.model";
import {PriceCodesMapper} from "../model/price-codes.mapper";
import {FaasDatesHelper} from "../../../helper/faas.dates.helper";
import {BasePrice, Currency, Material, Plant, PriceUnit} from "../../order/model/order.model";
import {padLeft} from "../../../shared/functions/padding";
import {EMPTY_STRING} from "../../../shared/functions/typescript.utils";

export class ManualPricesTransformer extends PriceCodesMapper {

  transformFromDomain(manualPrices: Price[]): any {
    let results = [];
    manualPrices.forEach((price) =>{
      let manualPrice = {};
      manualPrice["snr"] = price.material.snr;
      manualPrice["ai"] = price.material.ai;
      manualPrice["basePriceValue"] = price.basePrice.value;
      manualPrice["nettPriceValue"] = price.nettPrice.value;
      manualPrice["calculatedOn"] = FaasDatesHelper.formatDateToSortableDateString(price.calculatedOn);
      manualPrice["priceCurrency"] = price.currency.currency;
      manualPrice["priceType"] = price.priceType;
      manualPrice["priceState"] = this.getFormattedPricingState(price.priceState);
      manualPrice["fromDate"] = FaasDatesHelper.formatDateToSortableDateString(price.validity.fromDate);
      manualPrice["toDate"] = FaasDatesHelper.formatDateToSortableDateString(price.validity.toDate);
      manualPrice["workflowState"] = price.priceWorkflowState;
      manualPrice["user"] = price.user;
      manualPrice["reason"] = price.reason;
      manualPrice["statusDate"] =  FaasDatesHelper.formatDateToSortableDateString(price.statusDate);

      results.push(manualPrice);
    })
    return results;
  }

  toDto(price: Price): any {
    let prices = Array.of(price);
    return this.toGridDto(prices);
  }

  priceDetailDto(price: any): any {
    let prices = Array.of(price);
    return this.toDisplayDto(prices);
  }

  toGridDto(freezePrices: Price[]): any {
    let list = [];
    freezePrices.forEach((price) => {
      let freezePrice = {};
      freezePrice["snr"] = price.material.snr;
      freezePrice["ai"] = padLeft(price.material.ai,2);
      freezePrice["basePriceValue"] = price.basePrice.value;
      freezePrice["nettPriceValue"] = price.nettPrice.value;
      freezePrice["uom"] = price.priceComponents[0]?.order.quantityUnit;
      freezePrice["fDate"] = FaasDatesHelper.formatDateToSortableDateString(price.validity.fromDate);
      freezePrice["currencyNew"] = price.currency.currency;
      freezePrice["priceType"] = this.getFormattedPriceType(price.priceType?.toUpperCase());
      freezePrice["pe"] =  price.priceUnit;
      freezePrice["priceState"] = this.getFormattedPricingState(price.priceState);
      freezePrice["wfStatus"] = price.priceWorkflowState;
      freezePrice["internalIdentifier"] = price.internalIdentifier;
      freezePrice["psa"] = price.priceComponents[0]?.order.orderNo;
      freezePrice["reason"] = price.reason;
      freezePrice["orderCounter"] = price.priceComponents[0]?.order.version;
      freezePrice["original_price"] = price;
      list.push(freezePrice);
    })
    return list;
  }


  toDisplayDto(freezePrices: PriceDetail[]): any {
    let list = [];
    freezePrices.forEach((price) => {
      let freezePrice = {};
      let priceUnit = PriceUnit[price.priceUnit];
      freezePrice["freezePriceSNR"] = price.snr;
      freezePrice["freezePriceAI"] = padLeft(price.ai,2);
      freezePrice["basePriceValue"] = this.round(price.basePrice);
      freezePrice["nettPriceFreezePriceValue"] = this.round(price.nettPrice);
      freezePrice["oldNettPriceValue"] = this.round(price.oldNettPrice);
      freezePrice["uom"] = price.quantityUnit;
      freezePrice["fDate"] = price.validFrom?.substring(0,10) ;
      freezePrice["currencyNew"] = price.currency;
      freezePrice["priceState"] = price.priceState;
      freezePrice["priceType"] = this.formattedPriceType(price.priceType);
      freezePrice["pe"] = priceUnit === undefined ? "" : priceUnit;
      freezePrice["wfStatus"] = price.priceWorkflowState;
      freezePrice["internalIdentifier"] = price.internalIdentifier;
      freezePrice["reason"] = "";
      freezePrice["original_price"] = price;
      freezePrice["orderNo"] = price.orderNo;
      freezePrice["version"] = price.orderVersion;
      list.push(freezePrice);
    });
    return list;
  }

  round(value: number) {
    let m = Number((Math.abs(value) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(value);
  }

  toPriceDto(prices: any): Price[] {
    let list = [];
    prices.forEach((p) => {
      let originalPrice = p["original_price"];
      let price = new Price();
      price.reason = p["reason"];
      price.internalIdentifier = originalPrice.internalIdentifier;
      price.material = this.copyMaterial(originalPrice.material);
      price.basePrice = this.copyPrice(originalPrice.basePrice);
      price.nettPrice = this.copyPrice(originalPrice.nettPrice);
      list.push(price);
    });
    return list;
  }

  toPriceDetailDto(prices: any): Price[] {
    let list = [];
    prices.forEach((p) => {
      let originalPrice = p["original_price"];
      let price = new Price();
      price.reason = p["reason"];
      price.internalIdentifier = originalPrice.internalIdentifier;
      price.material = this.cloneMaterial(originalPrice);
      price.basePrice = this.clonePrice(originalPrice);
      price.nettPrice = this.clonePrice(originalPrice);
      list.push(price);
    });
    return list;
  }

  private copyMaterial(copy: Material) {
    let material = new Material();
    material.snr = copy.snr;
    material.ai = copy.ai;
    material.plant = copy.plant;
    return material;
  }

  copyPrice(copy: BasePrice) {
    let price = new BasePrice();
    price.value = copy.value;
    price.currency = this.copyCurrency(copy.currency);
    return price;
  }

  copyCurrency(copy: Currency ) {
    let currency = new Currency();
    currency.currency = copy.currency;
    currency.internalIdentifier = copy.internalIdentifier;
    currency.plant = copy.plant;
    currency.currencyType = copy.currencyType;
    return currency;
  }

  private cloneMaterial(originalPrice: any) {
    let material = new Material();
    material.snr = originalPrice.snr;
    material.ai = originalPrice.ai;
    material.plant = Plant[window.sessionStorage.client];
    return material;
  }

  clonePrice(originalPrice: any) {
    let price = new BasePrice();
    price.value = originalPrice.basePrice;
    price.currency = this.cloneCurrency(originalPrice.currency);
    return price;
  }

  cloneCurrency(originalPrice: any ) {
    let currency = new Currency();
    currency.currency = originalPrice.currency;
    currency.internalIdentifier = 50505;
    currency.plant = Plant[window.sessionStorage.client];
    currency.currencyType = "BASE";
    return currency;
  }

  formattedPriceType(type: string) {
    if (type === undefined || type === null) {
      return EMPTY_STRING;
    }
    return (type?.localeCompare("DAILY_FREEZE") == 0) ? "DAILY" : "MONTHLY";
  }

}
