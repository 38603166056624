<bmw-ag-grid-theme>
  <div *ngIf="errorMessage"
       class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
    <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
  </div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
    <div *ngIf="showLoading">
      <bmw-progress-circle [fullScreen]="true" loadingLabel="Loading..."></bmw-progress-circle>
    </div>
  </div>
  <div>
    <app-rule-grid
      [rowData]="rowData"
      [product]="product"
      [executionCountryCodes]="executionCountryCodes"
      [operation]="operation"
      [selectedDetail]="selectedDetail"
      [whenOperationIsUnderEvaluation]="whenOperationIsUnderEvaluation"
      [hasCapturerRole]="hasCapturerRole"
      (back)="onBack(false)"
      (save)="onSave($event)"
    ></app-rule-grid>
  </div>
</bmw-ag-grid-theme>
