import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {Operation, OperationSummary} from "../model/operations.model";
import {OperationService} from "../service/operation.service";
import {format} from "date-fns";
import {SystemGlobalCache} from "../../../shared/system.global.cache";

@Component({
  selector: 'app-new-operation',
  templateUrl: './new-operation.component.html',
  styleUrls: ['./new-operation.component.scss']
})
export class NewOperationComponent implements OnInit {

  operationForm: FormGroup;
  loadingScreen: boolean = false;
  errorMessage: string;
  minDate: Date = new Date();

  @Input() type: string;
  @Input() public submitFn;
  @Input() public cancelFn;
  @Input() public getOperationFn;
  @Output() public openOperationDetails = new EventEmitter<any>();

  constructor(private translate: TranslateService,
              private formBuilder: FormBuilder,
              private service: OperationService) { }

  ngOnInit(): void {
    this.operationForm = this.formBuilder.group({
      operationName: ['', Validators.required ],
      validFrom: ['', Validators.required],
      comment: ['', Validators.required]
    });
    this.minDate.setDate(this.minDate.getDate() + 1);
  }

  submit() {
    this.errorMessage = undefined;
    this.loadingScreen = true;
    try {
      this.operationForm.markAllAsTouched();
      let operation = this.getOperationModel();
      this.service.addNewOperation(operation).subscribe((result) => {
        this.service.getOperationDetailByName(this.getOperationModel().name).subscribe((operationDetails) => {
          let operation : OperationSummary;
          operation = operationDetails.data.operationByName;
          this.openOperationDetails.emit(operation);
          this.loadingScreen = false;
          this.cancel();
        }, error => {
          this.loadingScreen = false;
          this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
        });
      }, error => {
        this.loadingScreen = false;
        this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
      });
    } catch (e) {
      this.errorMessage = e.toString();
      this.loadingScreen = false;
    }
  }

  cancel() {
    this.cancelFn();
  }

  getOperationModel() {
    if (this.operationForm.valid) {
      let model = new Operation();
      model.plant = SystemGlobalCache.plant();
      model.name = this.f.operationName.value;
      model.statusReason = this.f.comment.value;
      model.validFrom = format(this.f.validFrom.value, 'yyyy-MM-dd');
      return model;
    }
    return new Operation();
  }

  get f() {
    return this.operationForm.controls;
  }

}
