import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperationService} from "../../service/operation.service";
import {TranslateService} from "@ngx-translate/core";
import {
  DateRange,
  ManufacturingCost,
  ManufacturingCostChangeInput, Money,
  OperationChangeInput,
  OperationFile, RuleHistory, Status, TransferPrice, Type
} from "../../model/operations.model";
import {Packet, SpecialEquipment} from "../../../product/model/product.model";
import * as XLSX from 'xlsx';
import {Plant} from "../../../order/model/order.model";

@Component({
  selector: 'app-add-cost-attachment',
  templateUrl: './add-cost-attachment.component.html',
  styleUrls: ['./add-cost-attachment.component.scss']
})
export class AddCostAttachmentComponent implements OnInit {
  @Input() showUpload: boolean;
  @Input() dialogTitle: string;
  @Input() dialogText: string;
  fileForm: FormGroup;
  @Output() uploadAttachment = new EventEmitter();
  @Output() cancelAttachment = new EventEmitter();
  @Input() operation;
  @Input() selected;
  @Input() uploadType;
  @Input() acceptFileType:any;
  loadingScreen: boolean = false;
  files: any[] = [];
  message = undefined;
  processing: boolean;
  error: {};
  arrayBuffer: any;
  showConfirm = false;
  confirmDialogTitle: string;
  confirmDialogText: string;

  constructor(private operationService: OperationService, public translate: TranslateService) {
  }

  ngOnInit(): void {
    if(this.acceptFileType===''){
      this.acceptFileType=".xlsx";
    }
    this.fileForm = new FormGroup({
      attachFile: new FormControl('', [Validators.required]),
    });
  }

  public addAttachment() {
    this.fileForm.markAllAsTouched();
    if (this.fileForm.valid) {
      this.processing = true;
      this.message = null;
      if (this.uploadType === 'options') {
        this.optionsRead();
      } else if (this.uploadType === 'type') {
        this.typesRead();
      }
    }
  }

  typesRead() {
    const columnNames = ['Category', 'Technical Change Request BMW/GWM', 'Premises', 'Volume Changes', 'Other',
      'TP Old', '5% Margin Requirements SAL', 'SAL Effects with BMW/GWM Impact', 'Other_1'];
    let fileReader = new FileReader();
    let arraylist = [];
    fileReader.readAsArrayBuffer(this.files[0]);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, {type: 'binary'});
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      arraylist = XLSX.utils.sheet_to_json(worksheet, {raw: true});

      if (arraylist.length > 30) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-modified-extra');
        return;
      }
      if (arraylist.length < 30) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-modified-missing');
        return;
      }

      this.error = {};
      arraylist.forEach(excelRow => {
        Object.entries(excelRow)
          .forEach(([key, value]) => {
            if (!columnNames.includes(key)) {
              this.message = this.translate.instant('messages.file-modified-column-renamed') + key;
            }
          });
        if (this.message !== null) {
          this.processing = false;
          return;
        }
        if (excelRow['Category'] === undefined ||
          excelRow['Technical Change Request BMW/GWM'] === undefined ||
          excelRow['Premises'] === undefined ||
          excelRow['Volume Changes'] === undefined ||
          excelRow['Other'] === undefined ||
          excelRow['5% Margin Requirements SAL'] === undefined ||
          excelRow['SAL Effects with BMW/GWM Impact'] === undefined ||
          excelRow['Other_1'] === undefined) {
          this.processing = false;
          this.message = this.translate.instant('messages.file-modified-columns');
          return;
        }

        if (isNaN(excelRow['Technical Change Request BMW/GWM'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Technical Change Request BMW/GWM'];
          return;
        } else if (isNaN(excelRow['Premises'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Premises'];
          return;
        } else if (isNaN(excelRow['Volume Changes'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Volume Changes'];
          return;
        } else if (isNaN(excelRow['Other'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Other'];
          return;
        } else if (isNaN(excelRow['5% Margin Requirements SAL'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['5% Margin Requirements SAL'];
          return;
        } else if (isNaN(excelRow['SAL Effects with BMW/GWM Impact'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['SAL Effects with BMW/GWM Impact'];
          return;
        } else if (isNaN(excelRow['Other_1'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Other_1'];
          return;
        }

        switch (excelRow['Category']) {
          case 'GWM Parts':
            this.selected.filter(x => x.category === 'GWM Parts')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'GWM Parts')[0].salOther = excelRow['Other_1'];
            break;
          case 'BMW Parts':
            this.selected.filter(x => x.category === 'BMW Parts')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'BMW Parts')[0].salOther = excelRow['Other_1'];
            break;
          case 'SAL Parts':
            this.selected.filter(x => x.category === 'SAL Parts')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'SAL Parts')[0].salOther = excelRow['Other_1'];
            break;
          case 'Third Party Parts':
            this.selected.filter(x => x.category === 'Third Party Parts')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Third Party Parts')[0].salOther = excelRow['Other_1'];
            break;
          case 'Direct labour':
            this.selected.filter(x => x.category === 'Direct labour')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Direct labour')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Direct labour')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Direct labour')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Direct labour')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Direct labour')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Direct labour')[0].salOther = excelRow['Other_1'];
            break;
          case 'Inbound Logistics':
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Inbound Logistics')[0].salOther = excelRow['Other_1'];
            break;
          case 'Warranty':
            this.selected.filter(x => x.category === 'Warranty')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Warranty')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Warranty')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Warranty')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Warranty')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Warranty')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Warranty')[0].salOther = excelRow['Other_1'];
            break;
          case 'Import Duties':
            this.selected.filter(x => x.category === 'Import Duties')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Import Duties')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Import Duties')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Import Duties')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Import Duties')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Import Duties')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Import Duties')[0].salOther = excelRow['Other_1'];
            break;
          case 'Customs clearance fee':
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Customs clearance fee')[0].salOther = excelRow['Other_1'];
            break;
          case 'Fuel and energy':
            this.selected.filter(x => x.category === 'Fuel and energy')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Fuel and energy')[0].salOther = excelRow['Other_1'];
            break;
          case 'Variable manufacturing expenses':
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Variable manufacturing expenses')[0].salOther = excelRow['Other_1'];
            break;
          case 'Outbound Logistic':
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Outbound Logistic')[0].salOther = excelRow['Other_1'];
            break;
          case 'Non-deductible indirect taxes - directly linked to project':
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - directly linked to project')[0].salOther = excelRow['Other_1'];
            break;
          case 'Non-deductible indirect taxes - not directly linked to project':
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Non-deductible indirect taxes - not directly linked to project')[0].salOther = excelRow['Other_1'];
            break;
          case 'Depreciation':
            this.selected.filter(x => x.category === 'Depreciation')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Depreciation')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Depreciation')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Depreciation')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Depreciation')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Depreciation')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Depreciation')[0].salOther = excelRow['Other_1'];
            break;
          case 'Indirect labour':
            this.selected.filter(x => x.category === 'Indirect labour')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Indirect labour')[0].salOther = excelRow['Other_1'];
            break;
          case 'Other fixed expenses':
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Other fixed expenses')[0].salOther = excelRow['Other_1'];
            break;
          case 'Yearly production cost reduction':
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].techChange = excelRow['Technical Change Request BMW/GWM'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].premises = excelRow['Premises'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].volumeChange = excelRow['Volume Changes'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].bmwOther = excelRow['Other'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].fivePercent = excelRow['5% Margin Requirements SAL'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].effect = excelRow['SAL Effects with BMW/GWM Impact'];
            this.selected.filter(x => x.category === 'Yearly production cost reduction')[0].salOther = excelRow['Other_1'];
            break;
        }
      });

      this.processing = false;
      this.confirmDialogTitle = this.translate.instant('titles.type-upload');
      this.confirmDialogText = this.translate.instant('messages.type-upload');
      this.showConfirm = true;
    };
  }

  continueUpload() {
    this.clearFileForm();
    if (this.message === null) {
      this.uploadAttachment.emit(this.selected);
    }
  }

  cancelUpload() {
    this.clearFileForm();
    this.cancelAttachment.emit();
  }

  clearFileForm() {
    this.showConfirm = false;
    this.fileForm.reset();
    this.files = [];
  }

  optionsRead() {
    const columnNames = ['Deriv.', 'Type', 'SA Code', 'Designation', 'Royalties', 'TP Old',
      'Technical Change Request BMW/GWM', 'Premises', 'Volume Changes', 'Other',  '5% Margin Requirements SAL',
      'SAL Effects with BMW/GWM Impact', 'Other_1', 'Royalties For Technology', 'Profit', 'TP New'];
    let fileReader = new FileReader();
    let arraylist = [];
    fileReader.readAsArrayBuffer(this.files[0]);
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      let data = new Uint8Array(this.arrayBuffer);
      let arr = new Array();
      for (let i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, {type: 'binary'});
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      arraylist = XLSX.utils.sheet_to_json(worksheet, {raw: true});

      if (arraylist.length > this.selected.rows.length) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-modified-extra');
        return;
      }
      if (arraylist.length < this.selected.rows.length) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-modified-missing');
        return;
      }

      let updatedRows = [];
      const ROYALTIES_CALC = 0.0315;
      const PROFIT_CALC = 0.05;
      if (Object.entries(arraylist[0]).length < columnNames.length) {
        this.processing = false;
        this.message = this.translate.instant('messages.file-modified-columns');
        return;
      }
      let saCodes: any = [];
      arraylist.forEach(excelRow => {
        Object.entries(excelRow)
          .forEach(([key, value]) => {
            if (!columnNames.includes(key)) {
              this.message = this.translate.instant('messages.file-modified-column-renamed') + key;
            }
          });

        if (this.message !== null) {
          this.processing = false;
          return;
        }

        if (isNaN(excelRow['Technical Change Request BMW/GWM'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Technical Change Request BMW/GWM']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['Premises'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Premises']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['Volume Changes'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Volume Changes']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['Other'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Other']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['5% Margin Requirements SAL'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['5% Margin Requirements SAL']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['SAL Effects with BMW/GWM Impact'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['SAL Effects with BMW/GWM Impact']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        } else if (isNaN(excelRow['Other_1'])) {
          this.processing = false;
          this.message = this.translate.instant('messages.incorrect-value-entered') + ': ' + excelRow['Other_1']
            + ' ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1);
          return;
        }
        this.error = {};
        let option = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
          option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']))[0];

        if (option !== undefined && !saCodes.includes(option.derivative + option.desigtype + option.saCode)) {
          let tpNewCalc = (option.deltaPrice === undefined ? 0 : option.deltaPrice) + (option.tpOld === undefined ? 0 : option.tpOld);
          if (option.description !== undefined && option.description !== excelRow['Designation']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Designation',
              columnValue: option.description,
              optionColumnValue: excelRow['Designation']
            };
          } else if (option.includeRoyalties !== undefined && option.includeRoyalties !== excelRow['Royalties']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Royalties',
              columnValue: option.includeRoyalties,
              optionColumnValue: excelRow['Royalties']
            };
          } else if (option.tpOld !== undefined && option.tpOld !== excelRow['TP Old']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'TP Old',
              columnValue: this.translate.instant('messages.file-modified-tp-old')
                + ': ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1),
              optionColumnValue: excelRow['TP Old']
            };
          } else if (option.royalties !== undefined && option.royalties !== excelRow['Royalties For Technology']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Royalties For Technology',
              columnValue: this.translate.instant('messages.file-modified-royalties')
                + ': ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1),
              optionColumnValue: excelRow['Royalties For Technology']
            };
          } else if (option.profit !== undefined && option.profit !== excelRow['Profit']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Profit',
              columnValue: this.translate.instant('messages.file-modified-profit')
                + ': ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1),
              optionColumnValue: excelRow['Profit']
            };
          } else if (tpNewCalc !== excelRow['TP New']) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'TP New',
              columnValue: this.translate.instant('messages.file-modified-tp-new')
                + ': ' + this.translate.instant('messages.edited-location') + ' ' + (excelRow['__rowNum__'] + 1),
              optionColumnValue: excelRow['TP New']
            };
          } else {
            saCodes.push(option.derivative + option.desigtype + option.saCode);
          }
        } else {
          let deriv = this.selected.rows.filter(option => option.derivative !== excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let type = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype !== excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let saCode = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) !== Number(excelRow['SA Code'])) || option.saCode !== excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let desig = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description !== excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let royalties = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties !== excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let tpOld = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld !== excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let royaltiesTech = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties !== excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let profit = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit !== excelRow['Profit'] && option.deltaPrice + option.tpOld === excelRow['TP New'])[0];
          let tpNew = this.selected.rows.filter(option => option.derivative === excelRow['Deriv.'] &&
            option.desigtype === excelRow['Type'] && ((!isNaN(option.saCode) && Number(option.saCode) === Number(excelRow['SA Code'])) || option.saCode === excelRow['SA Code']) &&
            option.description === excelRow['Designation'] && option.includeRoyalties === excelRow['Royalties'] &&
            option.tpOld === excelRow['TP Old'] && option.royalties === excelRow['Royalties For Technology'] &&
            option.profit === excelRow['Profit'] && option.deltaPrice + option.tpOld !== excelRow['TP New'])[0];

          if (deriv !== undefined && !saCodes.includes(deriv.derivative + deriv.desigtype + deriv.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Deriv.',
              columnValue: deriv.derivative,
              optionColumnValue: excelRow['Deriv.']
            };
          } else if (saCode !== undefined && !saCodes.includes(saCode.derivative + saCode.desigtype + saCode.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'SA Code',
              columnValue: saCode.saCode,
              optionColumnValue: excelRow['SA Code']
            };
          } else if (type !== undefined && !saCodes.includes(type.derivative + type.desigtype + type.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Type',
              columnValue: type.desigtype,
              optionColumnValue: excelRow['Type']
            };
          } else if (desig !== undefined && !saCodes.includes(desig.derivative + desig.desigtype + desig.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Designation',
              columnValue: desig.description,
              optionColumnValue: excelRow['Designation']
            };
          } else if (royalties !== undefined && !saCodes.includes(royalties.derivative + royalties.desigtype + royalties.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Royalties',
              columnValue: royalties.includeRoyalties,
              optionColumnValue: excelRow['Royalties']
            };
          } else if (tpOld !== undefined && !saCodes.includes(tpOld.derivative + tpOld.desigtype + tpOld.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'TP Old',
              columnValue: this.translate.instant('messages.file-modified-tp-old'),
              optionColumnValue: excelRow['TP Old']
            };
          } else if (royaltiesTech !== undefined && !saCodes.includes(royaltiesTech.derivative + royaltiesTech.desigtype + royaltiesTech.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Royalties For Technology',
              columnValue: this.translate.instant('messages.file-modified-royalties'),
              optionColumnValue: excelRow['Royalties For Technology']
            };
          } else if (profit !== undefined && !saCodes.includes(profit.derivative + profit.desigtype + profit.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'Profit',
              columnValue: this.translate.instant('messages.file-modified-profit'),
              optionColumnValue: excelRow['Profit']
            };
          } else if (tpNew !== undefined && !saCodes.includes(tpNew.derivative + tpNew.desigtype + tpNew.saCode)) {
            this.error = {
              rowNum: excelRow['__rowNum__'] + 1,
              columnName: 'TP New',
              columnValue: this.translate.instant('messages.file-modified-tp-new'),
              optionColumnValue: excelRow['TP New']
            };
          }
        }

        if (this.error['columnName'] === 'Royalties For Technology' || this.error['columnName'] === 'Profit' ||
          this.error['columnName'] === 'TP New' || this.error['columnName'] === 'TP Old') {
          this.message = this.error['columnValue'];
          this.processing = false;
          return;
        } else if (this.error['columnName']  !== undefined) {
          this.message = this.error['columnName'] + ': ' + this.error['optionColumnValue'] + ' ' +
            this.translate.instant('messages.edited') + ' ' + this.error['columnValue'] + ' ' +
            this.translate.instant('messages.edited-location') + ' ' + this.error['rowNum'];
          this.processing = false;
          return;
        }
        if (option !== undefined && this.error['columnName'] === undefined) {
          let total = excelRow['Technical Change Request BMW/GWM'] +
            excelRow['Premises'] +
            excelRow['Volume Changes'] +
            excelRow['Other'] +
            excelRow['5% Margin Requirements SAL'] +
            excelRow['SAL Effects with BMW/GWM Impact'] +
            excelRow['Other_1'];
          let royaltiesCalc = option.includeRoyalties === 'N' ? 0 : total * ROYALTIES_CALC;
          let profitCalc = total * PROFIT_CALC;
          let updatedRow = {
            priceId: option.priceId,
            derivative: option.derivative,
            desigtype: option.desigtype,
            saCode: option.saCode,
            description: option.description,
            includeRoyalties: option.includeRoyalties,
            tpOld: option.tpOld,
            tpNew: option.tpNew,
            category: option.category,
            categoryId: option.categoryId,
            group: option.group,
            techChange: excelRow['Technical Change Request BMW/GWM'],
            premises: excelRow['Premises'],
            volumeChange: excelRow['Volume Changes'],
            bmwOther: excelRow['Other'],
            fivePercent: excelRow['5% Margin Requirements SAL'],
            effect: excelRow['SAL Effects with BMW/GWM Impact'],
            salOther: excelRow['Other_1'],
            royalties: royaltiesCalc,
            profit: profitCalc,
            hasCapturerRole: option.hasCapturerRole,
            whenOperationIsUnderEvaluation: option.whenOperationIsUnderEvaluation,
            deltaPrice: total + royaltiesCalc + profitCalc
          };
          updatedRows.push(updatedRow);
        }
      });
      this.processing = false;
      if (this.message === null) {
        this.files = [];
        this.fileForm.reset();
        this.uploadAttachment.emit(updatedRows);
      }
    };
  }

  cancel() {
    this.message = undefined;
    this.fileForm.reset();
    this.files = null;
    this.cancelAttachment.emit();
  }

  onFileChange(event) {
    this.files = [];
    const reader = new FileReader();
    for (const file of event.target.files) {
      if (file.size <= 26214400) { // less than 25MB
        this.files.push(file);
      } else {
        this.message = this.translate.instant('messages.filesize');
      }
    }
  }
}
