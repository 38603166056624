import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS , APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const orderServiceUri = '/order-service/graphql';
const profileServiceUri = '/auth-service/graphql';
const productServiceUri = '/product-service/graphql';
const aggregationServiceUri = '/aggregation-service/graphql';
const aggregationServiceLegacyUri = '/aggregation-service-legacy/graphql';
const orderBatchServiceUri = '/order-service-batch/graphql';
const reportServiceUri = '/report-service/graphql';


export function createDefaultApollo(httpLink: HttpLink) {
  return {
    link: httpLink.create({ uri: orderServiceUri }) ,
    cache: new InMemoryCache(),
  };
}

export function createProfileServiceApollo(httpLink: HttpLink) {
  return {
    profile: {
      name: 'profile',
      link: httpLink.create({uri: profileServiceUri}),
      cache: new InMemoryCache(),
    },
    product: {
      name: 'product',
      link: httpLink.create({uri: productServiceUri}),
      cache: new InMemoryCache(),
    },
    orderBatch: {
      name: 'orders-batch',
      link: httpLink.create({uri: orderBatchServiceUri}),
      cache: new InMemoryCache(),
    },
    report: {
      name: 'report',
      link: httpLink.create({uri: reportServiceUri}),
      cache: new InMemoryCache(),
    },
    order: {
      name: 'order',
      link: httpLink.create({uri: orderServiceUri}),
      cache: new InMemoryCache(),
    },
    pima: {
      name: 'pima',
      link: httpLink.create({uri: aggregationServiceUri}),
      cache: new InMemoryCache(),
    },
    periodClosing: {
      name: 'periodClosing',
      link: httpLink.create({uri: aggregationServiceLegacyUri}),
      cache: new InMemoryCache(),
    }
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createDefaultApollo,
      deps: [HttpLink],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [HttpLink],
      useFactory: createProfileServiceApollo
    }

  ],
})
export class GraphQLModule { }
