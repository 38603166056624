import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GridApi, ICellRendererParams} from 'ag-grid-community';
import {TranslateService} from '@ngx-translate/core';
import {EMPTY_STRING, isUndefinedOrEmpty} from '../../../../shared/functions/typescript.utils';
import moment from "moment";
import {ManufacturingCostsActionedColumns} from './manufacturing-costs-actioned.columns';
import {AgGridBase} from '../../../../ag.grid.base';
import {ManufacturingCost, Operation, OperationSummary} from '../../model/operations.model';
import {OperationService} from '../../service/operation.service';
import {InformationCellRenderer} from './information-cell-renderer.component';

@Component({
  selector: 'app-manufacturing-costs-actioned-grid',
  templateUrl: './manufacturing-costs-actioned-grid.component.html',
  styleUrls: ['./manufacturing-costs-actioned-grid.component.scss']
})
export class ManufacturingCostsActionedGridComponent extends AgGridBase<ManufacturingCostsActionedColumns> {

  @Output() openExistingOperation = new EventEmitter<any>();
  @Output() operationSelected = new EventEmitter<any>();
  @Output() deleteOperation = new EventEmitter<any>();
  @Input() hasCaptureRole: boolean;
  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  frameworkComponents: any;
  rowSelection = 'multiple';
  selected: Operation[] = [];
  isRowSelected = false;
  dialogTitle: string;
  isRowSelectedForDelete: boolean = false;
  statusForDelete = ["IN_PROGRESS"];


  constructor(private translate: TranslateService, private operationService: OperationService) {
    super(new ManufacturingCostsActionedColumns(translate, operationService));
    this.frameworkComponents = {
      dialogueComponent: InformationCellRenderer
    };
    this.dialogTitle = this.translate.instant('menu.manufacturing-cost.tabs.operations.historytitle');
  }

  localizeHeaderWithHeaderName(parameters: ICellRendererParams): string {
    const headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  dateFormatter(params) {
    if (isUndefinedOrEmpty(params.value)) {
      return EMPTY_STRING;
    }
    return moment(params.value).format('DD.MM.YYYY');
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    const defaultSortModel = [
      {colId: 'operationId', sort: 'asc'}
    ];
    this.gridApi.setSortModel(defaultSortModel);
  }

  onModelUpdated(params) {
    super.onModelUpdated(params);
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onRowSelected(): void {
    this.isRowSelectedForDelete = this.gridApi.getSelectedRows().length > 0;
    this.isRowSelected = this.gridApi.getSelectedRows().length == 1;
    this.selected = this.isRowSelected || this.isRowSelectedForDelete ? this.gridApi.getSelectedRows() : undefined;
    if (this.selected) {
      this.selected.forEach(selected => {
        if (this.isRowSelectedForDelete) {
          this.isRowSelectedForDelete = this.statusForDelete.includes(selected.workFlowStatus);
        }
      });
      this.operationSelected.emit(this.selected);
    } else {
      this.operationSelected.emit(undefined);
    }
  }

  rowDoubleClicked(param: any) {
    if (this.gridApi.getSelectedRows().length > 0) {
      this.selected = [param];
      this.operationSelected.emit(this.selected);
    }
    this.openExistingOperation.emit(this.selected);
  }

  openOperation() {
    this.openExistingOperation.emit(this.selected);
  }

  onOperationHistoryClose() {
    this.suppliedColumns.showWorkFlow = false;
    this.suppliedColumns.workFlows = new Array<OperationSummary>();
  }

  onDeleteOperation() {
    this.deleteOperation.emit(this.selected);
  }
}
