import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import * as _ from "lodash";
import {OrderSearchRequest} from "../model/OrderSearchRequest";
import {faas} from "../../../constants/faas.constants";
import {Plant} from "../model/order.model";
import {OrderWorkflowStateInput} from "../service/order.graphql.query";
import {TranslateService} from "@ngx-translate/core";
import {OrderSearchNotifierService} from "../service/order-search-notifier.service";

@Component({
  selector: 'app-order-search-bar',
  templateUrl: './order-search-bar.component.html',
  styleUrls: ['./order-search-bar.component.scss']
})

export class OrderSearchBarComponent implements OnInit {
  @Input() hideWorkFlowStatus: boolean;
  @Input() routedOrderNumber: string;
  @Input() routedSnr: string;
  @Output() onSearch = new EventEmitter<OrderSearchRequest>();
  @Output() onClear : EventEmitter<any> = new EventEmitter();

  orderSearchForm: FormGroup;
  searchRequest: OrderSearchRequest;
  showMultipleSearchDialog: boolean = false;
  partData = [ { "partNumberId": "" } ];
  searchPlaceHolder = "";
  newDataPasted: boolean = false;

  constructor(private translate: TranslateService,
              private orderSearchNotifierService: OrderSearchNotifierService) {
    this.searchRequest = new OrderSearchRequest();
    this.orderSearchNotifierService.onChange().subscribe( o => {
      if ( o.tab ) {
        this.restoreSearchParameters();
      }
    });
  }

  onClose(params) {
    this.updatePartDataState([ { "partNumberId": "" } ]);
  }

  reEvaluatePartData(params: any) {
    this.newDataPasted = true;
  }

  snrInputChanged(params: any) {
    if (this.orderSearchForm.get('snr').value?.length > 0) {
      this.partData = [{"partNumberId": this.orderSearchForm.get('snr').value}];
    }
    else if (this.orderSearchForm.get('snr').value?.length == 0 && !this.hasMultiplePartNumbers()) {
      this.partData = [ { "partNumberId": "" } ];
    }
  }

  updatePartDataState(params) {
    if (params[0]?.partNumberId?.length == 0 && this.orderSearchForm.get('snr').value?.length != 0) {
      this.partData = [ { "partNumberId": this.orderSearchForm.get('snr').value } ];
    }
    else if (this.newDataPasted) {
      this.partData = params;
      this.newDataPasted = false;
    }
    this.handlePlaceHolderProperties(false);
  }

  handlePlaceHolderProperties(setDialogVisibility: boolean = null) {
    this.setPartsDialogVisibility(setDialogVisibility);

    if(this.hasMultiplePartNumbers()) {
      this.orderSearchForm.get("snr").setValue(['']);
      this.searchPlaceHolder = "...";
    }
    else {
      this.searchPlaceHolder = "";
    }
  }

  setPartsDialogVisibility(params: boolean = null) {
    params != null ? this.showMultipleSearchDialog = params : "";
  }

  hasMultiplePartNumbers() {
    return (this.partData.length > 1 && this.partData[0].partNumberId != "");
  }

  getSnrValue() {
    let snrArray = new Array<string>();
    if(this.hasMultiplePartNumbers() && this.partData[0]?.partNumberId != '') {
      this.partData.forEach((part) => {
        snrArray.push(part.partNumberId.toString().trim());
      });
    } else if (this.partData.length == 1) {
      snrArray.push(this.partData[0].partNumberId);
    }
    else if (this.orderSearchForm.get("snr").value?.length > 2) {
      snrArray.push(this.orderSearchForm.get("snr").value?.toString().trim());
    }
    return snrArray;
  }

  ngOnInit(): void {

    this.orderSearchForm = new FormGroup({
      snr: new FormControl(['']),
      ai: new FormControl(['']),
      orderNumber: new FormControl(['']),
      approvalCheckBox: new FormControl(false, {
        validators: Validators.pattern('true')
      }),
    });

    if (this.routedSnr) {
      this.orderSearchForm.setValue({
        snr: this.routedSnr,
        ai: null,
        orderNumber: null,
        approvalCheckBox: false
      });
      this.search();
    } else if (this.routedOrderNumber) {
      this.orderSearchForm.setValue({
        snr: null,
        ai: null,
        orderNumber: this.routedOrderNumber,
        approvalCheckBox: false
      });
      this.search();
    } else {
      this.restoreSearchParameters();
    }

    if (this.orderSearchForm.get('snr').value?.length > 0) {
      this.partData = [ { "partNumberId": this.orderSearchForm.get('snr').value } ];
    }
  }

  search() {
    try {
      this.canSearch();
      this.searchRequest.isError = false;
      this.searchRequest.status = this.orderSearchForm.get("approvalCheckBox").value ? OrderWorkflowStateInput.PENDING_APPROVAL : null;
      this.searchRequest.plant = Plant[window.sessionStorage.client];
    }catch (e) {
      this.searchRequest.isError = true;
      this.searchRequest.error =  e.toString();
    }
    this.onSearch.emit(this.searchRequest);
  }

  canSearch() {
    if (this.orderSearchForm.get("approvalCheckBox").value){
      this.searchRequest.ai = null;
      this.searchRequest.snrList = null;
      this.searchRequest.orderNumber = null;
      return true;
    }

    let isEmpty = this.cannotBeEmpty();

    if (!isEmpty)
      throw this.translate.instant('menu.pricing.order-management.error-message.either-snr-ai');
    else {
      this.validate();
    }
  }

  cannotBeEmpty() {
    return (this.getSnrValue() &&
      !_.isEmpty(this.getSnrValue()) ||
      this.orderSearchForm.get("ai").value &&
      !_.isEmpty(this.orderSearchForm.get("ai").value.toString().trim())) ||
      (this.orderSearchForm.get("orderNumber").value &&
        !_.isEmpty(this.orderSearchForm.get("orderNumber").value.toString().trim()));
  }

  clearFilters() {
    this.orderSearchForm.setValue({
      snr: null,
      ai: null,
      orderNumber: null,
      approvalCheckBox: false
    });
    this.searchRequest.ai = null;
    this.searchRequest.snrList = null;
    this.searchRequest.orderNumber = null;
    this.searchRequest.isError = false;
    this.searchRequest.error =  null;
    this.onClear.emit();
  }

  validate() {
    let snr = this.getSnrValue();
    let orderNumber = this.orderSearchForm.get("orderNumber").value;
    let ai = this.orderSearchForm.get("ai").value;
    //clean
    orderNumber = orderNumber?.toString().trim();
    ai = ai?.toString().trim();

    //if there is snr ai must be there also
    if (!_.isEmpty(snr?.toString().trim())) {

      //edge cases 1
      //snr & ai search only - if there is an order number reject
      if (orderNumber?.length >= 1 && (ai?.length == 0 || ai == null)) {
        throw this.translate.instant('menu.pricing.order-management.error-message.either-snr-ai');
      }

      //edge cases 2
      //snr & ai search only - if there is an order number reject
      if (orderNumber?.length >= 1 && ai?.length >= 1) {
        throw this.translate.instant('menu.pricing.order-management.error-message.either-snr-ai');
      }

      //valid case allow snr on its own
      if (ai?.length == undefined || ai?.length == 0 || ai?.length >= 1) {
        this.searchRequest.ai = (ai?.length == undefined || ai?.length == 0) ? null : parseInt(ai);
        this.searchRequest.snrList = this.getSnrValue();
        this.searchRequest.orderNumber = null;
        return true;
      }
    }
    else {
      if (ai?.length >= 1) {
        throw this.translate.instant('menu.pricing.order-management.error-message.either-snr-ai');
      }
    }

    if (orderNumber?.length >= 1 && ai?.length >= 1) {
      throw this.translate.instant('menu.pricing.order-management.error-message.either-snr-ai');
    }

    if (orderNumber?.length >= 1 && (ai?.length == 0 || ai == null)) {
      this.searchRequest.ai = null;
      this.searchRequest.snrList = null;
      this.searchRequest.orderNumber = orderNumber;
      return true;
    }
  }

  restoreSearchParameters() {
    let json = sessionStorage.getItem(faas.currentOrderSearchParameters);
    if ( json != null || json != undefined) {
      let searchParams = JSON.parse(json);
      let snrList = searchParams.snrList?.length > 1 ? [] : searchParams.snrList;
      if (searchParams.snrList?.length > 0) {
        this.partData = [ { "partNumberId": searchParams.snrList[0] } ];
      }
      this.orderSearchForm.setValue({
        snr: snrList,
        ai: searchParams.ai,
        orderNumber: searchParams.orderNumber,
        approvalCheckBox: false
      });
    }
  }

  //TODO: these will be fetched from a service call
  statuses = [
    {
      id: 'APPROVED',
      name: 'APPROVED'
    },
    {
      id: 'PENDING_APPROVAL',
      name: 'PENDING APPROVAL'
    }
  ]


}
