import {Injectable} from "@angular/core";
import {ServiceBase} from "../../../services/service.base";
import {Apollo} from "apollo-angular";
import {
  ALL_PERMISSIONS, ALL_USER_ROLES, AllRolesResponse, CURRENT_LOGGED_IN_USER,
  CurrentUserResponse, PermissionResponse, RoleResponse, ROLES_FOR_ALL_PLANTS, ROLES_FOR_PLANT, USER_BY_Q_NUMBER,
  UserResponse, UserRolesResponse
} from "./user.profile.graphql.query";
import {
  ApprovedClients,
  Client,
  CurrentUser,
  Permission,
  Role,
  RolePermissionInput, TokenPayload,
  UserPriviledge,
  UserRole
} from "../model/user.model";
import {faas} from "../../../constants/faas.constants";
import {
  ADD_PERMISSION_TO_ROLE,
  CREATE_PERMISSIONS,
  CREATE_ROLE,
  GRANT_ROLE_TO_USER, USER_PRIVILEDGES
} from "./user.profile.mutations";
import {SystemGlobalCache} from "../../../shared/system.global.cache";
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends ServiceBase {

  constructor(apollo: Apollo){
    super(apollo);
  }

  getCurrentUser() {
    return this.query<CurrentUserResponse>(null, CURRENT_LOGGED_IN_USER, faas.productServiceClientName);
  }

  getCurrentUserByQNumber(qNumber: string) {
    const variables = {
      qNumber: qNumber
    };
    return this.query<UserResponse>(variables, USER_BY_Q_NUMBER, faas.profileServiceClientName);
  }

  getAllPermissions() {
    return this.query<PermissionResponse>(null, ALL_PERMISSIONS, faas.profileServiceClientName);
  }

  getRolesForPlant(plantInput: string = null) {
    if (plantInput == null) plantInput = SystemGlobalCache.plant();
    const variables = {
      plant: plantInput
    };
    return this.query<RoleResponse>(variables, ROLES_FOR_PLANT, faas.profileServiceClientName);
  }

  getRolesForAllPlants() {
    return this.query<AllRolesResponse>(null, ROLES_FOR_ALL_PLANTS, faas.profileServiceClientName);
  }

  getRolesAllocatedToUsers() {
    return this.query<UserRolesResponse>(null, ALL_USER_ROLES, faas.profileServiceClientName);
  }

  grantRoleToUser(userRoleInput: UserRole) {
    const variable = {
      user: userRoleInput
    };
    return this.mutate(variable, GRANT_ROLE_TO_USER, faas.profileServiceClientName);
  }

  createRole(roleInput: Role) {
    const variable = {
      role: roleInput
    };
    return this.mutate(variable, CREATE_ROLE, faas.profileServiceClientName);
  }

  userPriviledges(rightsInput: UserPriviledge) {
    const variable = {
      rights: rightsInput
    };
    return this.mutate(variable, USER_PRIVILEDGES, faas.profileServiceClientName);
  }

  createPermissions(permissionInput: Permission[]) {
    const variable = {
      permissions: permissionInput
    };
    return this.mutate(variable, CREATE_PERMISSIONS, faas.profileServiceClientName);
  }

  addPermissionToRole(rolePermissionsInput: RolePermissionInput) {
    const variable = {
      rolePermissionsInput: rolePermissionsInput
    };
    return this.mutate(variable, ADD_PERMISSION_TO_ROLE, faas.profileServiceClientName);
  }

  getClientsForUser(currentUser: CurrentUser)  {
    let clients: any = [], map = new Map();

    currentUser.role.forEach( r => {
      map.set(r.plant,r.plant);
    });

    map.forEach( (value: string , key: string) => {
      let client = new Client();
      client.name = value;
      client.value = key;
      clients.push(client);
    })
    return clients;
  }


  dispose(): void {

  }

}
