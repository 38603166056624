import { Pipe, PipeTransform } from '@angular/core';
import {padLeft} from "../functions/padding";

@Pipe({
  name: 'aiToString'
})
export class AistringPipe implements PipeTransform {

  transform(value: any): string | null {
    return padLeft(value,2);
  }

}
