<bmw-ag-grid-theme>
  <ag-grid-angular data-testid="price-to-be-actioned-grid" *ngIf="rowData"
                   style="height: 500px; overflow: scroll"
                   class="zebra"
                   [columnDefs]="runtimeCompilerData.columns"
                   [rowData]="rowData"
                   [pagination]="true"
                   [rowSelection]="rowSelection"
                   [rowMultiSelectWithClick]="true"
                   [gridOptions]="runtimeCompilerData.gridOptions"
                   [defaultColDef]="runtimeCompilerData.defaultColDef"
                   [sideBar]="runtimeCompilerData.sideBar"
                   [statusBar]="runtimeCompilerData.statusBar"
                   [frameworkComponents]="frameworkComponents"
                   (rowSelected)="onRowSelected()"
                   (modelUpdated)="onModelUpdated($event)"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRefreshGridData($event)"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (rowDoubleClicked)="rowDoubleClicked($event.data)"
  >
  </ag-grid-angular>
</bmw-ag-grid-theme>
<div><br/></div>
<div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
  <div class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
    <bmw-button data-testid="button-open" [disabled] ="!isRowSelected"   button="primary" (clickEvent)="openOperation()" class="bmw__margin-right__xs">
      {{ 'menu.manufacturing-cost.tabs.operations.open' | translate }}
    </bmw-button>
    <bmw-button data-testid="button-delete" *ngIf="hasCaptureRole" [disabled] ="!isRowSelectedForDelete" button="primary" (clickEvent)="onDeleteOperation()" class="bmw__margin-right__xs">
      {{ 'menu.manufacturing-cost.tabs.operations.delete' | translate }}
    </bmw-button>
  </div>
</div>

<bmw-dialog
  [type]="'info'"
  [show]="this.suppliedColumns.showWorkFlow"
  [title]="dialogTitle"
  [closeButton]="true"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="onOperationHistoryClose()"
  (closeClick)="onOperationHistoryClose()"
>
  <bmw-master-detail-list
    style="--list__item__col-size:60% 40%;">
    <bmw-master-detail-list-row>
      <bmw-master-detail-list-row-entry
        label="{{'menu.manufacturing-cost.tabs.operations.wfstatus'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{'menu.manufacturing-cost.tabs.operations.statususer'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{'menu.manufacturing-cost.tabs.operations.statusdate'| translate}}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label = "{{'menu.manufacturing-cost.tabs.operations.statusreason'| translate}}"
        [bold] = "true"></bmw-master-detail-list-row-entry>
    </bmw-master-detail-list-row>
    <bmw-master-detail-list-row
      *ngFor="let workflow of this.suppliedColumns.workFlows">
      <bmw-master-detail-list-row-entry
        label="{{workflow.workFlowStatus | normalizeText }}"
        [bold]="true"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{workflow.statusUser}}"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{workflow.statusDate | faasDateTime }}"></bmw-master-detail-list-row-entry>
      <bmw-master-detail-list-row-entry
        label="{{workflow.statusReason}}"></bmw-master-detail-list-row-entry>
    </bmw-master-detail-list-row>
  </bmw-master-detail-list>
</bmw-dialog>
