<bmw-ag-grid-theme>
  <ag-grid-angular
    style="height: 300px; text-transform: uppercase;"
    [ngClass]="{ zebra: true, 'vertical-grid': false }"
    [rowData]="partData"
    [columnDefs]="runtimeCompilerData.columns"
    [gridOptions]="runtimeCompilerData.gridOptions"
    [defaultColDef]="runtimeCompilerData.defaultColDef"
    [statusBar]="statusBar"
    (pasteStart)="processDataFromClipboard($event)"
    [suppressLastEmptyLineOnPaste]="true">
  </ag-grid-angular>
</bmw-ag-grid-theme>
