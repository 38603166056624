import gql from "graphql-tag";
import {Order, Plant} from "../../order/model/order.model";
import {ExchangeRate, ISOCurrency} from "../model/exchange-rates.model";

export const EXCHANGE_RATES_QUERY = gql`
  query exchangeRates($plant: PlantInput! $year: Int!) {
    exchangeRates(plant:$plant, year:$year) {
    internalIdentifier
    currency{
      internalIdentifier
      currency
      currencyType
      displayFormat
      scale
    }
    baseCurrency{
      internalIdentifier
      currency
      currencyType
      displayFormat
      scale
    }
    exchangeRateType
    year
    month
    value
    state
    statusDate
    statusReason
    relatedExchangeRates{
      internalIdentifier
      currency{
        internalIdentifier
        currency
        currencyType
        displayFormat
        scale
      }
      exchangeRateType
      year
      month
      value
      state
      statusDate
      statusReason
    }
  }
 }`;

export const FOREIGN_CURRENCIES_QUERY = gql`
  query foreignCurrencies($plant: PlantInput!) {
    foreignCurrencies(plant:$plant) {
        internalIdentifier
        currency
        currencyType
        displayFormat
        plant
        scale
      }
 }`;

export interface ExchangeRatesQueryResponse {
  exchangeRates: ExchangeRate[];
}

export type ExchangeRatesVariables = {
  plant?: Plant;
  year?: number;
}

export interface ForeignCurrenciesQueryResponse {
  foreignCurrencies: ISOCurrency[];
}
