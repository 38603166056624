<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.quicksight-reports.title'  | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab #contentTab (selectionchange)="changeTab()">
          <bmw-content-tab-label for="report-1" [disabled]="false">
            {{'menu.report-1.title' | translate}}
          </bmw-content-tab-label>
          <bmw-content-tab-content id="report-1">
            <quicksight-embedded-dashboard-report-1 [dashboard]="'report_1'" *ngIf="isGWM || isSAL"></quicksight-embedded-dashboard-report-1>
          </bmw-content-tab-content>

          <bmw-content-tab-label for="report-2" [disabled]="false">
            {{'menu.report-2.title' | translate}}
          </bmw-content-tab-label>
          <bmw-content-tab-content id="report-2">
            <quicksight-embedded-dashboard-report-2 [dashboard]="'report_2'" *ngIf="isGWM || isSAL"></quicksight-embedded-dashboard-report-2>
          </bmw-content-tab-content>

          <bmw-content-tab-label for="report-3" [disabled]="false">
            {{'menu.report-3.title' | translate}}
          </bmw-content-tab-label>
          <bmw-content-tab-content id="report-3">
            <quicksight-embedded-dashboard-report-3 [dashboard]="'report_3'" *ngIf="isGWM || isSAL"></quicksight-embedded-dashboard-report-3>
          </bmw-content-tab-content>

          <bmw-content-tab-label for="report-4" [disabled]="false">
            {{'menu.report-4.title' | translate}}
          </bmw-content-tab-label>
          <bmw-content-tab-content id="report-4">
            <quicksight-embedded-dashboard-report-4 [dashboard]="'report_4'" *ngIf="isGWM || isSAL"></quicksight-embedded-dashboard-report-4>
          </bmw-content-tab-content>

          <bmw-content-tab-label for="target-price" [disabled]="false" *ngIf="isSAL">
            {{ 'menu.target-price.title' | translate }}
          </bmw-content-tab-label>
          <bmw-content-tab-content id="target-price">
            <quicksight-embedded-dashboard-target-price [dashboard]="'target_price'" *ngIf="isSAL"></quicksight-embedded-dashboard-target-price>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>
