import { ITableHeader } from '@bmw-ds/components/table/table.component';

export interface ITableData {
  description: string;
  addedOn: string;
  package: string;
  partNumber: string;
  listPrice: number;
  monthlyRent: number;
  specification: string;
}

export const tableData: ITableData[] = [
  {
    description: 'Cooper S Cabrio',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: 'WJ51',
    listPrice: 30600,
    monthlyRent: 192.78,
    specification: 'Model',
  },
  {
    description: 'White Silver metallic',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: 'A62',
    listPrice: 500,
    monthlyRent: 3.15,
    specification: 'Car paint',
  },
  {
    description: 'Dinamica-/Leathercombination Carbon Black',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: 'GSE1',
    listPrice: 650,
    monthlyRent: 4.1,
    specification: 'Upholstery',
  },
  {
    description: '17\'\' LM Rims Track Spoke black',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '2HW',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Sport-Automatic gearbox with twin-clutch',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '2TC',
    listPrice: 2000,
    monthlyRent: 12.6,
    specification: 'Special equipment',
  },
  {
    description: 'John Cooper Works Leather Steeringwheel',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '2XC',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Exterior Mirror Pacakge',
    addedOn: '02.13.2020',
    package: '9BD',
    partNumber: '313',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Model lettering elimination',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '320',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Windbreak',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '387',
    listPrice: 330,
    monthlyRent: 2.08,
    specification: 'Special equipment',
  },
  {
    description: 'John Cooper Works Aerodynamic Kit',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '3A1',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Mirror Caps black',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '3BE',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Piano Black Exterior',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '3L2',
    listPrice: 150,
    monthlyRent: 0.95,
    specification: 'Special equipment',
  },
  {
    description: 'Interior and Exterior Mirror Package',
    addedOn: '02.13.2020',
    package: '9BD',
    partNumber: '430',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Interior Mirror automatically dimming',
    addedOn: '02.13.2020',
    package: '9BD',
    partNumber: '431',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Armrest front',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '473',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Storage Package',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '493',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Seat Heating Driver & Passenger',
    addedOn: '02.13.2020',
    package: '9BD',
    partNumber: '494',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Piano Black Interior',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '4BD',
    listPrice: 130,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'John Cooper Works Sport Seats',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '4FN',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'MINI Excitement Package',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '4V9',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'MINI Driving Modes',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '4VA',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Park Distance Control',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '508',
    listPrice: 399.99,
    monthlyRent: 2.52,
    specification: 'Special equipment',
  },
  {
    description: 'Automatic Air Conditioning',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '534',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Speed Control with Brake Function',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '544',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Light Package',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '563',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'LED-Foglamps',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '5A1',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'LED-Headlamps',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '5A2',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Parking assistant',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '5DP',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Flexible CarSharing Preparation',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '5GE',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'ConnectedDrive Services',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6AK',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Real Time Traffic Information',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6AM',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Remote Services',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6AP',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Apple CarPlay',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6CP',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Radio MINI Visual Boost',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6FP',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'MINI Connected',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6NM',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'MINI Connected XL',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6NT',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Telephony with Wireless Charging',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6NW',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'MINI navigation system',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6UM',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Multifunctional instrument display',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '6WB',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Connected navigation plus',
    addedOn: '02.13.2020',
    package: '7GK',
    partNumber: '7GK',
    listPrice: 2550,
    monthlyRent: 16.07,
    specification: 'Special equipment',
  },
  {
    description: 'John Cooper Works Trim',
    addedOn: '02.13.2020',
    package: '7KQ',
    partNumber: '7KQ',
    listPrice: 4350,
    monthlyRent: 27.41,
    specification: 'Special equipment',
  },
  {
    description: 'Refrigerant',
    addedOn: '02.13.2020',
    package: '-',
    partNumber: '8R9',
    listPrice: 0,
    monthlyRent: 0,
    specification: 'Special equipment',
  },
  {
    description: 'Business package',
    addedOn: '02.13.2020',
    package: '9BD',
    partNumber: '9BD',
    listPrice: 700.01,
    monthlyRent: 4.41,
    specification: 'Special equipment',
  },
];
export const columns: ITableHeader[] = [
  {
    field: 'description',
    headerName: 'Description',
    sortable: true,
  },
  {
    field: 'addedOn',
    headerName: 'Added on',
    sortable: true,
  },
  {
    field: 'package',
    headerName: 'Package',
    sortable: true,
  },
  {
    field: 'partNumber',
    headerName: 'Part Number',
    sortable: true,
  },
  {
    field: 'listPrice',
    headerName: 'List price (in EUR)',
    sortable: true,
  },
  {
    field: 'monthlyRent',
    headerName: 'Monthly rent (in EUR)',
    sortable: true,
  },
  {
    field: 'specification',
    headerName: 'Specification',
    sortable: true,
  },
];

export interface IFoundCar {
  car: string;
  price: string;
  availableFrom: string;
  carPaint: string;
  upholstery: string;
  listPrice: string;
  transmission: string;
}

export const foundCars: IFoundCar[] = [
  {
    car: 'X4 xDrive30i',
    transmission: 'Automatic transmission',
    price: '648.63 €',
    availableFrom: '02.06.2020',
    carPaint: 'Saphirblack metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '75,320.17€',
  },

  {
    car: '540i xDrive Touring',
    transmission: 'Manual transmission',
    price: '623.35 €',
    availableFrom: '05.06.2020',
    carPaint: 'Alpinwhite uni',
    upholstery: 'Leather Dakota black/black',
    listPrice: '71,380.75€',
  },
  {
    car: '420d Coupé',
    transmission: 'Manual transmission',
    price: '518.31 €',
    availableFrom: '03.08.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '57,998.53€',
  },
  {
    car: '220i Gran Tourer',
    transmission: 'Automatic transmission',
    price: '435.39 €',
    availableFrom: '02.06.2020',
    carPaint: 'Imperialblue Brillianteffect metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '51,463.15€',
  },

  {
    car: '420i Coupé',
    transmission: 'Automatic transmission',
    price: '471.15 €',
    availableFrom: '10.08.2020',
    carPaint: 'Mineralwhite metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '55,963.85€',
  },
  {
    car: 'X5 M50d.',
    transmission: 'Manual transmission',
    price: '785.82 €',
    availableFrom: '05.06.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '83,383.13€',
  },
  {
    car: 'X5 xDrive40i',
    transmission: 'Automatic transmission',
    price: '644.07 €',
    availableFrom: '20.07.2020',
    carPaint: 'Carbonblack metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '111,310.17€',
  },
  {
    car: 'X3 x Drive30i',
    transmission: 'Automatic transmission',
    price: '512.12 €',
    availableFrom: '13.07.2020',
    carPaint: 'San Marino Blue',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '57,298.96€',
  },
  {
    car: 'X3 M',
    transmission: 'Manual transmission',
    price: '778.35 €',
    availableFrom: '02.06.2020',
    carPaint: 'Saphirblack metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '98,347.32€',
  },
  {
    car: 'X1 sDrive18i',
    transmission: 'Manual transmission',
    price: '402.30 €',
    availableFrom: '03.08.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '48,483.15€',
  },
  {
    car: 'M135i xDrive',
    transmission: 'Automatic transmission',
    price: '458.10 €',
    availableFrom: '07.09.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '50,963.23€',
  },
  {
    car: '750i xDrive Limousine',
    transmission: 'Automatic transmission',
    price: '1,127.09 €',
    availableFrom: '05.06.2020',
    carPaint: 'Imperialblue Brillianteffect metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '142,832.72€',
  },
  {
    car: '520i Touring',
    transmission: 'Manual transmission',
    price: '492.59 €',
    availableFrom: '10.08.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '53,413.93€',
  },
  {
    car: '118i',
    transmission: 'Manual transmission',
    price: '300.20 €',
    availableFrom: '03.08.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '36,833.15€',
  },
  {
    car: 'M240i Cabrio',
    transmission: 'Automatic transmission',
    price: '546.30 €',
    availableFrom: '07.09.2020',
    carPaint: 'Alpinwhite uni',
    upholstery: 'Leather Dakota black/black',
    listPrice: '58,417.42€',
  },
  {
    car: '840i xDrive Cabrio',
    transmission: 'Automatic transmission',
    price: '1,022.30 €',
    availableFrom: '02.06.2020',
    carPaint: 'San Marino Blue',
    upholstery: 'Leather Dakota black/black',
    listPrice: '136,839.83€',
  },
  {
    car: 'M850i xDrive Cabrio',
    transmission: 'Automatic transmission',
    price: '1,131.36 €',
    availableFrom: '10.08.2020',
    carPaint: 'Carbonblack metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '151,484.23€',
  },
  {
    car: 'X4 xDrive30i',
    transmission: 'Automatic transmission',
    price: '648.63 €',
    availableFrom: '02.06.2020',
    carPaint: 'Saphirblack metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '75,320.17€',
  },

  {
    car: '540i xDrive Touring',
    transmission: 'Manual transmission',
    price: '623.35 €',
    availableFrom: '05.06.2020',
    carPaint: 'Alpinwhite uni',
    upholstery: 'Leather Dakota black/black',
    listPrice: '71,380.75€',
  },
  {
    car: '420d Coupé',
    transmission: 'Manual transmission',
    price: '518.31 €',
    availableFrom: '03.08.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '57,998.53€',
  },
  {
    car: '220i Gran Tourer',
    transmission: 'Automatic transmission',
    price: '435.39 €',
    availableFrom: '02.06.2020',
    carPaint: 'Imperialblue Brillianteffect metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '51,463.15€',
  },

  {
    car: '420i Coupé',
    transmission: 'Automatic transmission',
    price: '471.15 €',
    availableFrom: '10.08.2020',
    carPaint: 'Mineralwhite metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '55,963.85€',
  },
  {
    car: 'X5 M50d.',
    transmission: 'Manual transmission',
    price: '785.82 €',
    availableFrom: '05.06.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '83,383.13€',
  },
  {
    car: 'X5 xDrive40i',
    transmission: 'Automatic transmission',
    price: '644.07 €',
    availableFrom: '20.07.2020',
    carPaint: 'Carbonblack metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '111,310.17€',
  },
  {
    car: 'X3 x Drive30i',
    transmission: 'Automatic transmission',
    price: '512.12 €',
    availableFrom: '13.07.2020',
    carPaint: 'San Marino Blue',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '57,298.96€',
  },
  {
    car: 'X3 M',
    transmission: 'Manual transmission',
    price: '778.35 €',
    availableFrom: '02.06.2020',
    carPaint: 'Saphirblack metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '98,347.32€',
  },
  {
    car: 'X1 sDrive18i',
    transmission: 'Manual transmission',
    price: '402.30 €',
    availableFrom: '03.08.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '48,483.15€',
  },
  {
    car: 'M135i xDrive',
    transmission: 'Automatic transmission',
    price: '458.10 €',
    availableFrom: '07.09.2020',
    carPaint: 'Mineralgrey metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '50,963.23€',
  },
  {
    car: '750i xDrive Limousine',
    transmission: 'Automatic transmission',
    price: '1,127.09 €',
    availableFrom: '05.06.2020',
    carPaint: 'Imperialblue Brillianteffect metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '142,832.72€',
  },
  {
    car: '520i Touring',
    transmission: 'Manual transmission',
    price: '492.59 €',
    availableFrom: '10.08.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Leather Dakota black/black',
    listPrice: '53,413.93€',
  },
  {
    car: '118i',
    transmission: 'Manual transmission',
    price: '300.20 €',
    availableFrom: '03.08.2020',
    carPaint: 'Tansanitablue II metallic',
    upholstery: 'Fabric Hexagon/Alcantara Anthracite/black',
    listPrice: '36,833.15€',
  },
  {
    car: 'M240i Cabrio',
    transmission: 'Automatic transmission',
    price: '546.30 €',
    availableFrom: '07.09.2020',
    carPaint: 'Alpinwhite uni',
    upholstery: 'Leather Dakota black/black',
    listPrice: '58,417.42€',
  },
  {
    car: '840i xDrive Cabrio',
    transmission: 'Automatic transmission',
    price: '1,022.30 €',
    availableFrom: '02.06.2020',
    carPaint: 'San Marino Blue',
    upholstery: 'Leather Dakota black/black',
    listPrice: '136,839.83€',
  },
  {
    car: 'M850i xDrive Cabrio',
    transmission: 'Automatic transmission',
    price: '1,131.36 €',
    availableFrom: '10.08.2020',
    carPaint: 'Carbonblack metallic',
    upholstery: 'Leather "Vernasca" perforated design Ivory White (SW)',
    listPrice: '151,484.23€',
  },
];

export const configurationTableData = [
  { contractDuration: '6 months', term: '8,000 mi', rentalFactor: '1.20%', monthlyRentCost: '628.20 €' },
  { contractDuration: '8 months', term: '12,000 mi', rentalFactor: '1.05%', monthlyRentCost: '549.66 €' },
  { contractDuration: '10 months', term: '18,000 mi', rentalFactor: '0.95%', monthlyRentCost: '497.34 €' },
];

export const configurationTableColumns: ITableHeader[] = [
  {
    field: 'contractDuration',
    headerName: 'Contract duration',
    sortable: true,
  },
  {
    field: 'term',
    headerName: 'Term',
    sortable: true,
  },
  {
    field: 'rentalFactor',
    headerName: 'Rental facto',
    sortable: true,
  },
  {
    field: 'monthlyRentCost',
    headerName: 'Monthly rent cost',
    sortable: true,
  },
];
