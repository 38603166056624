<bmw-ag-grid-theme>
  <bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="processing"></bmw-progress-circle>
  <div *ngIf="!showAllocate && !showEditOperation && !showTypeSA && !showAllocateSA && !showRules" class="bmw__margin-top__xs">
    <div *ngIf="message" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="info">{{message}}</bmw-inline-message>
    </div>
    <div *ngIf="errorMessage" class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">{{errorMessage}}</bmw-inline-message>
    </div>
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-6-tablet bmw__grid__cell--span-11 form-template-grid-start">
        <p class="bmw-large-text bmw-bold-text bmw__margin-top__xs"><b>{{ 'menu.manufacturing-cost.tabs.opsdetail.opname' | translate }}: {{operation?.name}}</b></p>
        <table class="bmw-regular-text bmw__margin-top__xs">
          <tr><td><td>{{ 'menu.manufacturing-cost.tabs.opsdetail.operation-id' | translate }}: </td><td><b>{{operation?.operationId}}</b></td></tr>
          <tr><td><td>{{ 'menu.manufacturing-cost.tabs.opsdetail.validfrom' | translate }}: </td><td><b>{{operation?.validFrom | faasDate }}</b></td></tr>
          <tr><td><td>{{ 'menu.manufacturing-cost.tabs.opsdetail.docs' | translate }}: </td><td><b>{{operation?.files === null ? 0 : operation?.files.length}}</b>
            <a *ngIf="operation?.files && operation?.files.length > 0" class="clickable" (click)="attachments()"><span class="iwp-icon-ci_paperclip"></span></a></td></tr>
          <tr><td><td>{{ 'menu.manufacturing-cost.tabs.opsdetail.operation-rules' | translate }}: </td><td><b>{{operation?.rules === null ? 0 : operation?.rules?.length}}</b></td></tr>
        </table>
      </div>
      <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-2-tablet bmw__grid__cell--span-1 form-template-grid-start">
        <bmw-tooltip content="{{ 'menu.manufacturing-cost.tabs.opsdetail.workflow-description' | translate }}" heading="{{ 'menu.manufacturing-cost.tabs.opsdetail.workflow-status' | translate }}" theme="normal" position="before">
          <span class="cell-workflow-status"><b>{{operation?.workFlowStatus | normalizeText }}</b></span>
        </bmw-tooltip>
      </div>
    </div>
    <div><br/></div>
    <div class="bmw__margin-top__xs">
              <app-operation-details-grid
                [rowData]="rowData"
                [shouldShowGrid]="shouldShowGrid"
                [toReviewAndCancelDisabled]="toReviewAndCancelDisabled"
                [toInReleaseAndResetDisabled]="toInReleaseAndResetDisabled"
                [releaseAndDeclineDisabled]="releaseAndDeclineDisabled"
                [editSelectionDisabled]="editSelectionDisabled"
                [whenOperationIsUnderEvaluation]="whenOperationIsUnderEvaluation"
                [isSystemsOperation]="isSystemsOperation"
                [hasReviewerRole]="hasReviewerRole"
                [hasCaptureRole]="hasCapturerRole"
                [hasReleaseRole]="hasReleaseRole"
                [operationNotReadyForReview]="operationNotReadyForReview"
                [operationCloned]="operationCloned"
                [isMsfOrNedcar]="isPlantMsfOrNedcar"
                (refresh)="refresh()"
                (upload) = "onUpload()"
                (allocate) = "onAllocate($event)"
                (rules) = "onRules($event)"
                (allocatemsg) = "onAllocateMsg()"
                (workflow) = "checkFiles($event)"
                (edit) = "onEditOperation()"
                (addTypeSA) = "addTypeSA()"
                (removeTypeSA) = "removeTypeSA($event)"
                (editSelection)="onEditSelection($event)"
              ></app-operation-details-grid>
            </div>

  </div>
  <div *ngIf="showTypeSA" class="bmw__margin-top__xs">
    <app-operation-type-sa [operation]="operation" (takeOver)="onTakeOver($event)" (cancel)="onCancel()"></app-operation-type-sa>
  </div>
  <div *ngIf="showAllocate" class="bmw__margin-top__xs">
    <app-cost-allocations
      (showAllocate)="showOperationsDetailsAfterAllocation($event)"
      [selectedDetail]="selectedDetail"
      [operation]="operation">
    </app-cost-allocations>
  </div>
  <div *ngIf="showRules" class="bmw__margin-top__xs">
    <app-rule
      (showRules)="showOperationsDetailsAfterRules($event)"
      [selectedDetail]="selectedDetail"
      [operation]="operation">
    </app-rule>
  </div>
  <div *ngIf="showAllocateSA" class="bmw__margin-top__xs">
    <app-cost-allocations-sa
      (showAllocateSA)="showOperationsDetailsAfterSAAllocation($event)"
      (saveTypeSADialog)="showPopupDialog()"
      [selectedDetail]="selectedDetail"
      [operation]="operation"
    >
    </app-cost-allocations-sa>
  </div>
  <div *ngIf="showEditOperation" class="bmw__margin-top__xs">
    <app-edit-operation [type]="'edit'" [cancelFn]="cancelEditOperation" [submitFn]="submit" [operation]="operation"></app-edit-operation>
  </div>
</bmw-ag-grid-theme>
<app-add-attachment [showUpload]="showUploadDialog" [dialogTitle]="dialogTitle" [dialogText]="dialogText"
                    [operation]="operation" (uploadAttachment)="refresh()"
                    (cancelAttachment)="cancelUploadAttachment()"></app-add-attachment>
<app-download-attachment [showAttachments]="showAttachments" [operation]="this.operation" [fileList]="fileList"
                         (cancelDownloadAttachment)="cancelDownloadAttachment()"  ></app-download-attachment>
<bmw-dialog
  [type]="'info'"
  [show]="showPrompt"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showPrompt = false"
  (closeClick)="showPrompt = false"
>
  <p class="bmw-regular-text">{{ dialogText }}</p>
</bmw-dialog>
<bmw-dialog
  [type]="'warning'"
  [show]="showWarning"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  (primaryClick)="showWarning = false"
  (closeClick)="showWarning = false"
>
  <p class="bmw-regular-text">{{ dialogText }}</p>
</bmw-dialog>
<div class="bmw__margin-top__xs">
  <app-edit-selection-dialog [shouldShow]="showEditSelection" [manufacturingCosts]="selectedManufacturingCosts" [operation]="operation">
  </app-edit-selection-dialog>
</div>

<bmw-dialog
        [type]="'warning'"
        [show]="showFileUploadDialog"
        [closeButton]="true"
        [title]="dialogTitle"
        [primaryLabel]="'Ok'"
        [secondaryLabel]="'Cancel'"
        [primaryDisabled]="false"
        [secondaryDisabled]="false"
        (primaryClick)="onWorkflow('review')"
        (secondaryClick)="showFileUploadDialog = false"
        (closeClick)="showFileUploadDialog = false">
  <p class="bmw-regular-text">{{ 'manufacturing-cost.tabs.opsdetail.nofiles' | translate  }}</p>
</bmw-dialog>

<bmw-dialog
  [type]="'warning'"
  [show]="showPriceOrRulesDialog"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="showPriceOrRulesDialog = false"
  (closeClick)="showPriceOrRulesDialog = false">
  <p class="bmw-regular-text">{{ 'manufacturing-cost.tabs.opsdetail.transferprice-rules' | translate  }}</p>
</bmw-dialog>

<bmw-dialog
  [type]="'warning'"
  [show]="showWorkflow"
  [closeButton]="true"
  [title]="dialogTitle"
  [primaryLabel]="'Ok'"
  [secondaryLabel]="'Cancel'"
  [primaryDisabled]="false"
  [secondaryDisabled]="false"
  (primaryClick)="applyWorkflow()"
  (secondaryClick)="cancelWorkflow()"
  (closeClick)="cancelEditOperation()">
  <form [formGroup]="workflowForm" class="reversed">
    <bmw-inputfield id="comment">
      <bmw-label>{{ 'manufacturing-cost.tabs.operations.comment' | translate }}</bmw-label>
      <textarea
        bmwInput
        type="text"
        name="comment"
        formControlName="comment"
        placeholder="Your comment.."
        rows="8"
      ></textarea>
      <bmw-error-message>{{ 'shared.errors.errFieldEmpty' | translate }}</bmw-error-message>
    </bmw-inputfield>
  </form>
</bmw-dialog>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Please wait..." [show]="processing"></bmw-progress-circle>
