import {transferPrice} from "./transfer-price-constants";

const profitPercentage = 0.05;
const royaltiesForTechnologyPercentage = 0.03;

export function calculateRoyalties(column, category, group) {
  const value = sumCategories.call(this, group, column);
  if (category === transferPrice.category.royaltiesForTechnology && group === transferPrice.group.royalties) {
    return Math.round(((value + (sumCategories.call(this, transferPrice.group.profitCarset, column) * profitPercentage)) * royaltiesForTechnologyPercentage + Number.EPSILON) * 100) / 100;
  }
}

export function calculateProfit(column, group) {
  const value = sumCategories.call(this, group, column);
  return Math.round((value * profitPercentage + Number.EPSILON) * 100) / 100;
}

export const royaltiesProfitCalculator = function(params): number {
  if (params.data) {
    if (params.data.category && params.data.category === transferPrice.category.royaltiesForTechnology) {
      return this.selection.includeRoyalties === 'N' ? 0 :
        calculateRoyalties.call(this, params.colDef.field, params.data.category, params.data.group);
    } else if (params.data.category && params.data.category === transferPrice.category.profit) {
      return calculateProfit.call(this, params.colDef.field, params.data.group);
    } else {
      return params.data[params.colDef.field];
    }
  } else {
    return 0;
  }
};

function sumCategories(group, column) {
  let value = 0;
  if (group === transferPrice.group.royalties || group === transferPrice.group.profitCarset) {
    if (this.runtimeCompilerData && this.rowData) {
      this.rowData.forEach((row) => {
        if (row.category === transferPrice.category.gwmParts ||
          row.category === transferPrice.category.bmwParts ||
          row.category === transferPrice.category.salParts ||
          row.category === transferPrice.category.thirdPartyParts ||
          row.category === transferPrice.category.directLabour ||
          row.category === transferPrice.category.inboundLogistics ||
          (group === transferPrice.group.royalties && row.category === transferPrice.category.warranty) ||
          (group === transferPrice.group.royalties && row.category === transferPrice.category.importDuties) ||
          row.category === transferPrice.category.customsClearanceFee ||
          row.category === transferPrice.category.fuelAndEnergy ||
          row.category === transferPrice.category.variableManufacturingExpenses ||
          row.category === transferPrice.category.outboundLogistic ||
          (group === transferPrice.group.royalties && row.category === transferPrice.category.nonDeductibleIndirectTaxesDirectlyLinkedToProject) ||
          (group === transferPrice.group.royalties && row.category === transferPrice.category.nonDeductibleIndirectTaxesNotDirectlyLinkedToProject) ||
          row.category === transferPrice.category.depreciation ||
          row.category === transferPrice.category.indirectLabour ||
          row.category === transferPrice.category.otherFixedExpenses) {
          value += Number(row[column]);
        }
      });
    }
  }
  return value;
}
