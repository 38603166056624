<div *ngIf="displayParentView">
  <form class="reversed">
    <div class="bmw__grid__inner">
      <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-6 form-template-grid-start">
        <p class="bmw-medium-text bmw__margin-top__xs"> {{ 'menu.pricing.order-management.current.introduction' | translate }}</p>
      </div>
    </div>
    <app-order-search-bar [routedOrderNumber]="routedOrderNumber"
                          [routedSnr]="routedSnr"
                          (onSearch)="search($event)"
                          (onClear)="clearFilters()"
                          [hideWorkFlowStatus]="false">
    </app-order-search-bar>
  </form>
  <bmw-ag-grid-theme>
    <div
      *ngIf="formSubmitted && errorMessage"
      class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 bmw__margin-top__l bmw-regular-text">
      <bmw-inline-message type="error">
        {{errorMessage}}
      </bmw-inline-message>
    </div>
    <div *ngIf="formSubmitted && !errorMessage" class="bmw__margin-top__xs">
      <div class="bmw__margin-top__xs">
        <app-order-grid
          [hidden]="!hideEditGrid"
          [rowData]="rowData"
          [showSideBar]="showSideBar"
          [groupByColumns]="groupByColumns"
          [hideColumns]="hideColumns"
          [loadingScreen]="loadingScreen"
          [hideBottomEditBar]="hasNoOrderManagementApproveRejectRole"
          [disableEditButton]="shouldDisableEditButton"
          (onAllowEdit)="onAllowEdit($event)"
          (updateOrder)="updateOrder($event)"
          (doubleClick)="onDoubleClick($event)">
        </app-order-grid>
        <app-order-grid-editable
          [hidden]="hideEditGrid"
          [rowData]="rowDataForEdit"
          [showSideBar]="showSideBar"
          [groupByColumns]="groupByColumns"
          [hideColumns]="hideColumns"
          [loadingScreen]="loadingScreen"
          [editableErrorMessage]="gridErrorMessage"
          (onCancelClick)="switchView($event)"
          (updateOrder)="updateOrder($event)"
          (doubleClick)="onDoubleClick($event)">
        </app-order-grid-editable>
      </div>
    </div>
  </bmw-ag-grid-theme>
</div>
<div *ngIf="!displayParentView">
  <app-order-detail-view
    [originalOrder]="originalOrder"
    [pendingOrders]="pendingOrders"
    [pendingOrderHeader]="pendingOrderHeaderInformation"
    [rowData]="rowData"
    [hasNoOrderManagementRole]="hasNoOrderManagementApproveRejectRole"
    (onApproveReject)="showParentView($event)"
    (onCancel)="onCancel($event)"
  ></app-order-detail-view>
</div>

