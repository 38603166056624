export interface OrderData {
  orders: Order[];
}

export interface OrderParent {
  data: OrderData;
}

export interface Order {
  orderNo: string;
  version: number;
  orderCurrency: Currency;
  internalIdentifier: number;
  material: Material;
  changeDate: string;
  supplierNo: string;
  orderState: string;
  orderType: string;
  quantityUnit: string;
  relatedOrders: Order[];
  pricingPeriods: PricingPeriod[];
}

export class Material {
  snr: string;
  ai: number;
  plant: Plant;
}

export class Currency {
  currency: string;
  currencyType: string;
  displayFormat?: any;
  internalIdentifier: number;
  plant: string;
  scale: number;
}

export interface PricingPeriod {
  internalIdentifier: number;
  basePrice: BasePrice;
  nettPrice: BasePrice;
  validity: Validity;
  surcharges: Surcharge[];
  orderState: string;
  weightings: any[];
  priceUnit: string;
}

export class BasePrice {
  currency: Currency;
  value: number;
}

export interface Surcharge {
  internalIdentifier: number;
  amount: BasePrice;
  category: string;
  surchargeType: string;
}

export class SurchargeViewModel {
  fromDate: string;
  toDate: string;
  orderNo: string;
  surchargeCategory: string;
  surchargeType: string;
  surchargeCurrency: string;
  surchargeValue: string;
  id: number;
}

export interface Validity {
  fromDate: Date;
  toDate: Date;
}


export enum Plant {
  MSF='MSF',
  NEDCAR='NEDCAR',
  SAL='SAL',
  GWM='GWM'
}

export enum OrderState {
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  OPENED = 'OPENED'
}

export enum PriceUnit {
  ONE = 1,
  TEN = 2,
  HUNDRED = 3,
  THOUSAND = 4
}

