import {TranslateService} from "@ngx-translate/core";
import {GridColumnBase} from "../../../../shared/grid.column.base";
import {GridColumnDefinition} from "../../../../shared/grid.column.definition";

export class ProductMasterDataDisplayGridColumns extends GridColumnBase implements GridColumnDefinition  {

  translate: TranslateService;

  constructor(translate: TranslateService) {
    super(translate);
    this.translate = translate;
  }

  columns = [
    {
      field: 'prod_said',
      headerName: 'Id',
      hide: true,
      suppressToolPanel: true,
      suppressColumnsToolPanel: true
    },
    {
      field: 'prod_derivative',
      headerName: 'Derivative',
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: true,
      resizable: true
    },
    {
      field: 'prod_plant',
      headerName: 'Plant',
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
      resizable: true
    },
    {
      field: 'prod_productiontypecode',
      headerName: 'prodArt',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_satype',
      headerName: 'SA Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_buildtype' ,
      headerName: 'Build Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false
    },
    {
      field: 'prod_typecode' ,
      headerName: 'Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_designationtype' ,
      headerName: 'Designation Type',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_sacode' ,
      headerName: 'SA Code',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_designationsa',
      headerName: 'Designation SA',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_productiondatastatusnumber',
      headerName: 'Type Status',
      resizable: true,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_sop',
      headerName: 'SOP',
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
    {
      field: 'prod_eop',
      headerName: 'EOP',
      resizable: true,
      valueFormatter: this.dateFormatter,
      headerValueGetter: this.localizeHeader.bind(this),
      checkboxSelection: false,
    },
  ];

}
