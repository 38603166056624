import moment from "moment";


export class FaasDatesHelper {

  static formatDateTimeToString(date: Date) : string {
    if ( date == null ) return "";
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }

  static formatDateToString(date: Date) : string {
    if ( date == null ) return "";
    return moment(date,'YYYY-MM-DD').format('DD.MM.YYYY');
  }

  static formatDateToSortableDateString(date: Date): string {
    if ( date == null ) return "";
    return moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
  }

  static formatStringDateToString(date: string) : string {
    if ( date == null ) return "";
    return moment(date,'YYYY-MM-DD').format('DD.MM.YYYY');
  }

  static formatStringDateTimestampToString(date: string) : string {
    if ( date == null ) return "";
    return moment(date,'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY HH:mm');
  }

  static formatStringDateToOracleDate(date: string) : string {
    if ( date == null ) return "";
    return moment(date,'DD.MM.YYYY').format('YYYY-MM-DD');
  }

  static formatStringDateTimeToString(date: string) : string {
    if ( date == null ) return "";
    return moment(date,'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
  }

  static formatFaasDate(date: string): string {
    if ( date == null ) return "";
    if ( date.includes("-")) {
      return FaasDatesHelper.formatStringDateToOracleDate(FaasDatesHelper.formatStringDateToString(date));
    }
    return FaasDatesHelper.formatStringDateToOracleDate(date);
  }

  static convertStringToDate(date: string): Date {
      return new Date(date);
  }

  static dateDifference(startDate: Date,endDate: Date) {
    let startTime = moment(startDate, 'DD-MM-YYYY hh:mm:ss');
    let endTime = moment(endDate, 'DD-MM-YYYY hh:mm:ss');

    return endTime.diff(startTime, 'hours');
  }

}
