<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-8 page-wrapper">
      <h1 class="bmw-h1 bmw__margin-top__l">Imprint</h1>

      <div class="table-wrapper bmw__margin-top__l">
        <p class="bmw-regular-text">
          This Website is maintained by Bayerische Motoren Werke Aktiengesellschaft (Petuelring 130, 80788 München).<br /><br />
          Electronic contact: xxxx@bmwgroup.com<br />
          Phone: 0049 xxx / xxx xxx<br /><br />
          The legal representatives of BMW AG are the members of the Board of Management (Oliver Zipse (Chairman), Ilka
          Horstmeier, Milan Nedeljković, Pieter Nota, Nicolas Peter, Frank Weber, Andreas Wendt) <br />
          Chairman of Supervisory Board: Norbert Reithofer <br /><br />
          Commercial register Amtsgericht München Registergericht, HRB 42243 <br />
          Value-added tax identification no.: DE129273398
        </p>
      </div>
    </div>
  </div>
</div>
