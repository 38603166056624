import {Component, EventEmitter, HostListener, Input, OnInit, Output} from "@angular/core";
import {MockingService} from "../../../../../services/mocking.service";
import {TranslateService} from "@ngx-translate/core";
import {GridApi, GridOptions} from "ag-grid-community";
import {FreezePriceOverviewGridColumns} from "./freeze-price-overview-grid.columns";
import {GridSettingsStore} from "../../../../../helper/grid.settings.store";
import {Router} from "@angular/router";
import {PriceSummaryCellData} from "../../model/freeze.price.models";

@Component({
  selector: 'app-freeze-price-grid-overview',
  templateUrl: './freeze-price-overview-grid.html',
  styleUrls: ['./freeze-price-overview-grid.component.scss']
})
export class FreezePriceOverviewGridComponent extends GridSettingsStore implements OnInit {

  @Input() showSideBar: boolean = true;
  @Input() rowData: any;
  @Output() doubleClick = new EventEmitter<PriceSummaryCellData>();
  @Output() refresh = new EventEmitter();

  gridApi: GridApi;
  gridColumnApi: any;
  filtering = false;
  runtimeCompilerData: any;
  gridColumns: any;
  approveRejectButtonStatus = false;
  rowSelection;
  domLayout: any;
  loadingScreen: boolean = false;
  paginationPageSize: any;

  constructor(private translate: TranslateService,
              private service: MockingService,
              private router: Router) {
    super();
    this.translate.onLangChange.subscribe(() => {
      this.gridApi?.refreshHeader();
      this.gridApi?.refreshToolPanel();
    });
    this.gridColumns = new FreezePriceOverviewGridColumns(this.translate);
  }


  ngOnInit() {
    this.rowSelection = 'single';
    this.paginationPageSize = 10;
    this.setGridOptions();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.resetRowHeights();
    this.gridApi.sizeColumnsToFit();
  }

  onRefresh(): void {
    this.approveRejectButtonStatus = false;
    this.gridApi.redrawRows();
    this.gridApi.sizeColumnsToFit();
    //this.gridColumnApi.setColumnsVisible(['fromDateEditable','orderStateEditable','changeDateEditable'],false);
    //this.gridColumnApi.setColumnsVisible(['fromDate','orderState','changeDate'],true);
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
    //emit
    this.refresh.emit();
  }

  isMobileViewport(): boolean {
    const breakpoint =
      parseFloat(getComputedStyle(document.body).getPropertyValue('--component-breakpoint')) *
      parseFloat(getComputedStyle(document.documentElement).fontSize);
    const width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    return width < breakpoint;
  }

  onFirstDataRendered(params): void {
    this.gridApi.expandAll();
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    this.gridApi.sizeColumnsToFit();
    this.gridApi.resetRowHeights();
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 0);
  }

  expandAfterRedraw() {
    this.gridApi.forEachNode((node) => {
      if(node?.group) {
        node.setExpanded(true)
      }
    })
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setHeaderHeight(this.isMobileViewport() ? 40 : 32);
    let defaultSortModel = [
      {colId: 'fromDate', sort: 'asc'},
    ];
    this.gridApi.setSortModel(defaultSortModel);
    this.runtimeCompilerData.gridOptions.api.addEventListener('filterChanged', (e) => {
      this.filtering = !this.filtering;
    });
  }

  onModelUpdated(params): void {
    if (this.gridApi && this.gridApi.getDisplayedRowCount() == 0 && !this.filtering) {
      this.gridApi.showLoadingOverlay();
    }
    if (this.gridApi && this.gridApi.getDisplayedRowCount() > 0) {
      this.gridApi.hideOverlay();
    }
  }

  toggleSideBar(): any {
    if (this.showSideBar) {
      return  {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel'
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel'
          }
        ],
        defaultToolPanel: undefined
      };
    }
    return null;
  }

  setGridOptions() {
    this.domLayout = 'autoHeight';
    this.runtimeCompilerData = {
      columns: this.gridColumns.columns,
      gridOptions: {
        getRowStyle: params => {
          return {order: params.node.rowIndex};
        },
        multiSortKey: 'ctrl',
        postSort: params => {
          // it needs to wait until new order is set
          setTimeout(() => {
            params.forEach(param => {
              param.updateData(param.data);
            });
          });
        },
        ensureDomOrder: true,
        suppressMovableColumns: false,
        rowSelection: 'single',
        rowDeselection: true,
        rowHeight: 32,
        headerHeight: 32,
        enableRangeSelection: true,
        enableRangeHandle: true,
        rowGroupPanelShow: 'onlyWhenGrouping'
      } as GridOptions,
      defaultColDef: {
        sortable: true,
        unSortIcon: true,
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        defaultColToEdit: '',
        autoHeight: true,
        resizable: true,
      },
      sideBar: this.toggleSideBar(),
      statusBar: {
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left'
          },
          {
            statusPanel: 'agTotalRowCountComponent',
            align: 'center'
          },
          {statusPanel: 'agFilteredRowCountComponent'},
          {statusPanel: 'agSelectedRowCountComponent'},
          {statusPanel: 'agAggregationComponent'}
        ]
      },
      autoGroupColumnDef: {
        minWidth: 200,
        pinned: 'left'
      }
    };
  }

  onCellValueChanged(params: any) {
  }


  onSaveGridState(params: any) {
    let columnState = this.gridColumnApi?.getColumnState();
    this.saveGridState(this.router.url,columnState);
    this.gridApi.expandAll();
  }

  onCellDoubleClick(params: any) {
    if (params.colDef.field === "date") {
      return;
    }

    if (params.colDef.field === "released"  &&  params.data.released == 0 )
      return;

    if (params.colDef.field === "rejected"  &&  params.data.rejected == 0 )
      return;

    if (params.colDef.field === "open"  &&  params.data.open == 0 )
      return;

    let data = new PriceSummaryCellData();
    data.status = params.colDef.field;
    data.summaryRow = params.data;
    data.hideApproveRejectButtons = params.colDef.field === "open";
    this.doubleClick.emit(data);
  }

  onRefreshGridData($event: any) {
    //sort here
    setTimeout(() => {
      this.gridApi.redrawRows();
      this.gridApi.sizeColumnsToFit();
    }, 500);
    //sort date
    this.gridColumnApi.applyColumnState( {
      state: [
        {
          colId: 'date',
          sort: 'asc',
        },
      ],
      defaultState: { sort: null },
    });
  }

}
