import {Injectable} from "@angular/core";
import {Apollo, QueryRef} from 'apollo-angular';
import {
  WEIGHT_SNR_AI_QUERY, WeightSnrAiQueryResponse, WeightSnrAiVariables,
} from "../weightings.graphql.query";
import {Plant} from "../model/weight.model";
import {WeightTransformer} from "../weightTransformer";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class WeightService {

  weightSnrAiRef: QueryRef<WeightSnrAiQueryResponse, WeightSnrAiVariables>;
  transformer: any;

  constructor(private apollo: Apollo,
              private translate: TranslateService) {
    this.transformer = new WeightTransformer();
  }


  weightBySnrAi(ai: number, snr: string): QueryRef<WeightSnrAiQueryResponse, WeightSnrAiVariables> {
    this.weightSnrAiRef = this.apollo.watchQuery<WeightSnrAiQueryResponse, WeightSnrAiVariables>({
      query: WEIGHT_SNR_AI_QUERY
    });

    this.weightSnrAiRef.setVariables({
      plant: Plant[window.sessionStorage.client],
      ai: ai,
      snr: snr
    });
    return this.weightSnrAiRef;
  }

  removeGraphQLErrorOnMessage(errorMessage: string) {
    let message = errorMessage.replace('GraphQL error: ', '');
    if (message == "Weightings not found for material")
      message = this.translate.instant('menu.pricing.order-management.error-message.weighting-not-exist');
    return message;
  }

  mapWeightings(weightings: any): any {
    return this.transformer.transformWeightings(weightings);
  }

}
