import {Observable, Subject} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ServiceBus {
  private subject = new Subject<any>();

  public writeResult<T>(value?: any) {
    this.subject.next(new ServiceResultsModel(value,false,null));
  }

  public writeError(message: string) {
    this.subject.next(new ServiceResultsModel(null, true, message));
  }

  public onResult<T>(): Observable<ServiceResultsModel<T>> {
    return this.subject.asObservable();
  }
}

export class ServiceResultsModel<T> {
  data: T;
  isError: boolean
  errorMessage: string;

  constructor(data: T, error: boolean, message: string) {
    this.data = data;
    this.isError = error;
    this.errorMessage = message;
  }
}
