import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrencyService} from "../service/currency.service";
import {ServiceBus} from "../../../services/service.bus";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {Plant} from "../../order/model/order.model";
import {CurrencyType, ISOCurrency} from "../../exchange-rates/model/exchange-rates.model";
import {FormControl, FormGroup} from "@angular/forms";
import * as _ from "lodash";
import {ADD_CURRENCY} from "../service/currency.graphql";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-currency-overview',
  templateUrl: './currency-overview.component.html',
  styleUrls: ['./currency-overview.component.scss']
})
export class CurrencyOverviewComponent implements OnInit , OnDestroy {

  rows: ISOCurrency[] = [];
  selectedItem = null;
  errorMessage: string;
  infoMessage: string;
  addDisabled = false;
  saveDisabled = true;

  currencyForm: FormGroup;
  currencyTypes: any = [];
  loadingScreen = false;
  hidden = false;
  display = true;
  isNew = false;
  notAdmin = false;

  constructor(private translate: TranslateService,
              private service: CurrencyService,
              private bus: ServiceBus,
              private plantService: PlantChangeService) { }

  ngOnInit(): void {
    this.display = true;
    this.service.getForeignCurrencies();
    this.loadCurrencyTypes();

    this.currencyForm = new FormGroup({
      currencyCode: new FormControl(['']),
      currencyScale: new FormControl(["0"]),
      currencyType: new FormControl(['']),
    });

    this.notAdmin = !this.service.isReleaseOfExchangeRate();
    this.subscribers();
  }

  subscribers = () => {
    this.bus.onResult<ISOCurrency[]>().subscribe(result => {
      this.loadingScreen = false;
      if ( !result.isError ) {
        this.rows = result.data;
        this.selectedItem = this.rows[0];
      } else {
        this.errorMessage = result.errorMessage;
      }
    });

    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.service.getForeignCurrencies();
      }
    });
  }

  loadCurrencyTypes = () => {
    this.currencyTypes.push("FOREIGN");
    this.currencyTypes.push("BASE");
  }


  ngOnDestroy(): void {
    this.service.dispose();
  }

  onSave() {
    this.display = true;
    this.isNew = false;
    this.loadingScreen = true;
    this.addDisabled = false;
    this.saveDisabled = true;

    if ( this.isValid() ) {
      this.saveCurrency(this.getCurrency());
    } else {
      this.loadingScreen = false;
      this.errorMessage = this.translate.instant('menu.pricing.exchange-rates.error-message.missing-parameters');
    }

  }

  onAddNew() {

    if (this.rows?.length == 0 ){
      let dummyCurrency = new ISOCurrency();
      this.rows.push(dummyCurrency.dummyBaseCurrency());
    }

    this.selectedItem = this.rows[0];
    this.display = false;
    this.isNew = true;
    this.addDisabled = true;
    this.saveDisabled = false;
  }

  cancel() {
    this.display = true;
    this.addDisabled = false;
    this.saveDisabled = true;
  }

  getCurrency(): ISOCurrency {
    let currency = new ISOCurrency();
    currency.currency = this.currencyForm.get("currencyCode").value.toString().toUpperCase();
    currency.scale = this.currencyForm.get("currencyScale").value;
    currency.currencyType = CurrencyType[this.currencyForm.get("currencyType").value];
    currency.plant = Plant[window.sessionStorage.client];
    return currency;
  }

  isValid(): boolean {
   return (this.currencyForm.get("currencyCode").value &&
      !_.isEmpty(this.currencyForm.get("currencyCode").value.toString().trim()) &&
      this.currencyForm.get("currencyScale").value &&
      !_.isEmpty(this.currencyForm.get("currencyScale").value.toString().trim())) ||
    (this.currencyForm.get("currencyType").value &&
      !_.isEmpty(this.currencyForm.get("currencyType").value.toString().trim()));
  }

  saveCurrency(currencyInput: ISOCurrency) {
    this.service._apollo.mutate({
      mutation: ADD_CURRENCY,
      variables:  {
        currency: currencyInput
      }
    }).subscribe(({ data }) => {
      this.rows = [];
      this.service.getForeignCurrencies();
      this.loadingScreen = false;
      this.infoMessage = this.translate.instant('menu.pricing.order-management.info-message.currency-added-success');
    },(error) => {
      this.loadingScreen = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }
}
