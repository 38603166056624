import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy} from "@angular/core";
import {AuthService} from "../../services/auth.service";

@Directive({
    selector: '[faasHasPermission]'
  })
export class PermissionSystemDirective {

  private userPermissions: any[];

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private authenticationService: AuthService) {
    const tokenPayLoad = this.authenticationService.getTokenPayload();
    this.userPermissions = tokenPayLoad.authorities;
  }


  @Input()
  set faasHasPermission(permission: any) {
    if (typeof permission !== 'string') {
      throw new Error('hasPermission value must be a string');
    }
    /** Clear the template beforehand to prevent overlap OnChanges. */
    this.viewContainer.clear();
    /** Shows Template if user has permission */
    if (this.hasPermission(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private hasPermission(permission: string[] | string) {
    return this.authenticationService.checkPermission(permission);
  }


}
