import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ExchangeRateTypesMapper} from "../../../exchange-rates/model/exchange-rate-types.mapper";
import {ExchangeRateViewModel, PriceComponentData} from "../../model/continous.price.model";
import {TranslateContinuousPriceEnums} from "../service/continuous.price.enum.translations";

@Component({
  selector: 'app-price-exchange-rate',
  templateUrl: './price-exchange-rate.component.html',
  styleUrls: ['./price-exchange-rate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceExchangeRateComponent extends ExchangeRateTypesMapper implements OnInit {

  @Input() priceComponents: PriceComponentData[];
  @Input() resize: boolean;

  exchangeRateData: any = [];
  exchangeRateColumns: any = [];

  continuousPriceEnums: TranslateContinuousPriceEnums;

  constructor(private translate: TranslateService) {
    super();
    this.continuousPriceEnums = new TranslateContinuousPriceEnums();
    this.translate.onLangChange.subscribe( e=> {
      this.translateAndLoadColumns();
      this.translateInGridEnums();
    });
  }

  ngOnInit(): void {
    this.translateAndLoadColumns();
    this.loadData();
  }

  translateAndLoadColumns() {
    this.exchangeRateColumns = [
      {field: 'orderNo', headerName: this.localizeHeader('orderNo')},
      {field: 'currency', headerName: this.localizeHeader('currency')},
      {field: 'exchangeRateType', headerName: this.localizeHeader('exchangeRateType')},
      {field: 'month', headerName: this.localizeHeader('month')},
      {field: 'value', headerName: this.localizeHeader('value'), type: 'rightAligned'},
      {field: 'year', headerName: this.localizeHeader('year')}
    ];
  }

  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  private loadData() {
    let exchangeRatesForPriceComponent: ExchangeRateViewModel[] = [];
    this.priceComponents.forEach(priceComponent => {
      const exchangeRate = priceComponent.exchangeRate;
      if (exchangeRate) {
        let exchangeRateViewModel = new ExchangeRateViewModel();
        exchangeRateViewModel.currency = exchangeRate.currency.currency;
        exchangeRateViewModel.orderNo = priceComponent.order.orderNo;
        exchangeRateViewModel.exchangeRateType = this.getExchangeRateType(exchangeRate.exchangeRateType);
        exchangeRateViewModel.value = exchangeRate.value;
        exchangeRateViewModel.month = exchangeRate.month;
        exchangeRateViewModel.year = exchangeRate.year;
        exchangeRatesForPriceComponent.push(exchangeRateViewModel);
      }
    });
    this.exchangeRateData = exchangeRatesForPriceComponent.sort( (a, b) => b.orderNo.localeCompare(a.orderNo));
    this.translateInGridEnums();
  }

  private translateInGridEnums() {
    let lang = this.translate.currentLang;
    if (this.exchangeRateData !== undefined) {
      let translatedRowData = [];
      let rows = this.exchangeRateData;
      rows.forEach(row => {
        if (row !== undefined) {
          row.exchangeRateType = this.continuousPriceEnums.translate(lang, row.exchangeRateType);
          translatedRowData.push(row);
        }
      });
      this.exchangeRateData = translatedRowData;
    }
  }
}
