import gql from "graphql-tag";
import {Product, Type} from "../../product/model/product.model";
import {Rule} from "../model/operations.model";

export const RULE_PRODUCTS_QUERY = gql`
query productsByPlant($plant: Plant!, $isActive: Boolean) {
  productsByPlant(plant:$plant, isActive: $isActive) {
    internalIdentifier
    validity {
      fromDate
      toDate
    }
    derivative {
      code
      internalIdentifier
      derivativeImportStatus
      types {
        internalIdentifier
        code
        name
        typeImportStatus
        specialEquipment {
          internalIdentifier
          code
          name
          specialEquipmentImportStatus
        }
      }
    }
  }
}`;

export const OPERATION_RULE_QUERY = gql`
query operationRulesById($operationId: Long!) {
  operationRulesById(operationId: $operationId) {
    derivativeCode
    internalIdentifier
    status
    royaltiesIndicator
    amount {
      value
    }
    transferCategoryCosts {
      internalIdentifier
      amount {
        value
      }
      categoryCost {
        internalIdentifier
        name
      }
      adjustmentReason {
        internalIdentifier
        name
      }
    }
    type {
      axlGear
      capacity
      code
      country
      cylinder
      engineName
      engineSeries
      equipBase
      fuel
      internalIdentifier
      name
      steering
      typeImportStatus
    }
    specialEquipments {
      code
      equipment
      internalIdentifier
      name
      specialEquipmentImportStatus
      type
    }
    previousRules {
      derivativeCode
      internalIdentifier
      royaltiesIndicator
      status
      validFrom
      amount {
        value
      }
      transferCategoryCosts {
        internalIdentifier
        amount {
          value
        }
        categoryCost {
          internalIdentifier
          name
        }
        adjustmentReason {
          internalIdentifier
          name
        }
      }
      type {
        axlGear
        capacity
        code
        country
        cylinder
        engineName
        engineSeries
        equipBase
        fuel
        internalIdentifier
        name
        steering
        typeImportStatus
      }
      specialEquipments {
        code
        equipment
        internalIdentifier
        name
        specialEquipmentImportStatus
        type
      }
    }
  }
}`;

export const OPERATION_RULE_IN_PROGRESS = gql`
query retrieveRulesInWorkflow($plant: Plant!) {
  retrieveRulesInWorkflow(plant: $plant) {
    internalIdentifier
    type {
      code
    }
    specialEquipments {
      code
      internalIdentifier
    }
  }
}`;

export interface RuleProductsQueryResponse {
  productsByPlant: Product[];
}

export interface OperationRuleQueryResponse {
  operationRulesById: Rule[];
}

export interface OperationRulesInProgressResponse {
  retrieveRulesInWorkflow: Rule[];
}
