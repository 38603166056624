import gql from "graphql-tag";
import {Weight, Plant} from "./model/weight.model";

export const WEIGHT_SNR_AI_QUERY = gql`
  query weightForMaterial($snr: String! $plant: PlantInput! $ai: Int) {
    weightForMaterial(snr: $snr plant: $plant ai:$ai){
    orderNo
    importDate
    internalIdentifier
    material {
      ai
      plant
      snr
    }
    value
    weightDate
    weightType
  }
 }`;

export interface WeightSnrAiQueryResponse {
  weightForMaterial: Weight[];
}

export type WeightSnrAiVariables = {
  snr?: string;
  plant?: Plant;
  ai?: number;
}
