<div class="bmw__margin-top__xs">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-3-tablet bmw__grid__cell--span-4 form-template-grid-start">
      <p class="bmw-large-text bmw-bold-text bmw__margin-top__xs"><b>{{ 'menu.manufacturing-cost.tabs.costallocations.title' | translate }}</b></p>
      <p *ngIf="selectedDetail" class="bmw-large-text bmw__margin-top__xs">{{ 'menu.manufacturing-cost.tabs.costallocations.dataselection' | translate }}</p>
      <bmw-master-detail-list
        style="--list__item__col-size:60% 40%;">
        <bmw-master-detail-list-row>
          <bmw-master-detail-list-row-entry bold="true"
            label="{{ 'ops.details.grid.derivate' | translate }}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry bold="true"
            label="{{ 'ops.details.grid.designation' | translate }}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry bold="true"
            label="{{ 'ops.details.grid.type' | translate }}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry bold="true"
            label="{{ 'ops.details.grid.includeRoyalties' | translate }}"></bmw-master-detail-list-row-entry>
        </bmw-master-detail-list-row>
        <bmw-master-detail-list-row
          *ngFor="let operationDetail of selectedDetailShadow"
          [selected]="selection === operationDetail"
          (updateEvent)="selection = operationDetail"
          (click)="changeAllocation()">
          <bmw-master-detail-list-row-entry
            label="{{operationDetail.derivate}}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry
            label="{{operationDetail.designation}}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry
            label="{{operationDetail.type}}"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry
            label="{{operationDetail.includeRoyalties}}"></bmw-master-detail-list-row-entry>
        </bmw-master-detail-list-row>
      </bmw-master-detail-list>
    </div>
    <div class="bmw__grid__cell bmw__grid__cell--span-4-mobile bmw__grid__cell--span-5-tablet bmw__grid__cell--span-8 form-template-grid-start">
    </div>
  </div>
    <ag-grid-angular *ngIf="showGrid"
      style="height: 800px; overflow: scroll"
      class="zebra"
      [rowData]="rowData"
      [columnDefs]="runtimeCompilerData.columns"
      [defaultColDef]="runtimeCompilerData.defaultColDef"
      [gridOptions]="runtimeCompilerData.gridOptions"
      [autoGroupColumnDef]="runtimeCompilerData.autoGroupColumnDefAllocation"
      [frameworkComponents]="runtimeCompilerData.frameworkComponents"
      [suppressAggFuncInHeader]="true"
      [enableCellChangeFlash]="true"
      (gridReady)="onGridReady($event)"
      (rowDataChanged)="onRefreshGridData($event)"
      (firstDataRendered)="onFirstDataRendered($event)"
    >
    </ag-grid-angular>
    <div><br/></div>
    <p style="float: left">
      <bmw-button button="secondary" (clickEvent)="onBack()" class="bmw__margin-right__xs">
                  {{ 'menu.manufacturing-cost.tabs.costallocations.cancel' | translate }}
      </bmw-button>
      <bmw-button *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation" button="secondary" (clickEvent)="onUpload()">
                  {{ 'menu.manufacturing-cost.tabs.opsdetail.upload' | translate }}
      </bmw-button>
    </p>
    <p style="float: right">
      <bmw-button button="primary" *ngIf="hasCapturerRole" [disabled]="whenOperationIsUnderEvaluation" (clickEvent)="onGetConfirmation()">
        {{ 'menu.manufacturing-cost.tabs.costallocations.save' | translate }}
      </bmw-button>
    </p>

  </div>

<app-cost-allocations-dialog [showUnallocatedTypesDialog]="showUnallocatedCosts" (saveTypeDialog)="saveWithDefaultValues()" (cancelDialog)="cancelUnallocatedCosts()" ></app-cost-allocations-dialog>
