import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {RuleService} from "../service/rule.service";
import {OperationRulesHistoryTransformer} from "../service/operation.rules-history-transformer";
import moment from "moment";

@Component({
  selector: 'app-rule-history',
  templateUrl: './rule-history.component.html',
  styleUrls: ['./rule-history.component.scss']
})
export class RuleHistoryComponent implements OnInit {
  rowData: any;
  message = undefined;
  dialogTitle: string;
  dialogText: string;
  loadingScreen: boolean = false;
  errorMessage: string;
  rulesHistoryTransformer: OperationRulesHistoryTransformer;

  constructor(
    public translate: TranslateService,
    private plantService: PlantChangeService,
    private ruleService: RuleService
  ) {

    this.plantService.onChange().subscribe(message => {
      if (message) {
        this.loadRulesHistory();
      }
    });
    this.rulesHistoryTransformer = new OperationRulesHistoryTransformer();
  }

  ngOnInit(): void {
    this.loadRulesHistory();
  }

  loadRulesHistory() {
    this.message = undefined;
    this.rowData = undefined;
    this.loadingScreen = true;

    this.ruleService.getRulesHistory()
      .subscribe((result) => {
        this.loadingScreen = false;
        this.rowData = result.data.retrieveOperationRulesHistory.map(row => {
          return this.rulesHistoryTransformer.transformRulesHistory(row);
        });
      }, (error) => {
        this.loadingScreen = false;
        this.errorMessage = this.ruleService.removeGraphQLErrorOnMessage(error.message);
      });
  }
}
