import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {LanguageChangeService} from "../../../header/service/LanguageChangeService";
import {WeightViewModel} from "../../../weightings/model/weight.view.model";
import {FaasDatesHelper} from "../../../../helper/faas.dates.helper";
import {PriceComponentData} from "../../model/continous.price.model";
import {TranslateContinuousPriceEnums} from "../service/continuous.price.enum.translations";

@Component({
  selector: 'app-price-weight',
  templateUrl: './price-weight.component.html',
  styleUrls: ['./price-weight.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceWeightComponent implements OnInit {

  @Input() priceComponents: PriceComponentData[];
  @Input() resize: boolean;

  weightData: any = [];
  weightingsColumns: any = [];

  continuousPriceEnums: TranslateContinuousPriceEnums;

  constructor(private translate: TranslateService,
              private langService: LanguageChangeService) {
    this.continuousPriceEnums = new TranslateContinuousPriceEnums();
    this.langService.onChange().subscribe( e=> {
      this.translateAndLoadColumns();
      this.translateInGridEnums();
    });
  }

  ngOnInit(): void {
    this.translateAndLoadColumns();
    this.loadData();
  }

  localizeHeader(field: string): string {
    return this.translate.instant(field);
  }

  private translateInGridEnums() {
    let lang = this.translate.currentLang;
    if (this.weightData !== undefined) {
      let translatedRowData = [];
      let rows = this.weightData;
      rows.forEach(row => {
        if (row !== undefined) {
          row.weightType = this.continuousPriceEnums.translate(lang, row.weightType);
          translatedRowData.push(row);
        }
      });
      this.weightData = translatedRowData;
    }
  }

  private loadData() {
    let weights: WeightViewModel[] = [];
    this.priceComponents.forEach(priceComponent => {
      const weight = priceComponent.weight;
      if(weight){
        let weightViewModel = new WeightViewModel();
        weightViewModel.importDate = FaasDatesHelper.formatDateTimeToString(weight.importDate);
        weightViewModel.orderNo = weight.orderNo;
        weightViewModel.weightValue = weight.value.toLocaleString();
        weightViewModel.weightDate = FaasDatesHelper.formatStringDateToString(weight.weightDate);
        weightViewModel.weightType = weight.weightType;
        weights.push(weightViewModel);
      }
    });
    this.weightData = weights.sort( (a, b) => b.orderNo.localeCompare(a.orderNo));
    this.translateInGridEnums();
  }

  private translateAndLoadColumns() {
    this.weightingsColumns = [
      {field: 'orderNo', headerName: this.localizeHeader('orderNo')},
      {field: 'importDate', headerName: this.localizeHeader('importDate'), sortable: true, sort: 'asc'},
      {field: 'weightValue', headerName: this.localizeHeader('weightValue'), type: 'rightAligned'},
      {field: 'weightDate', headerName: this.localizeHeader('weightDate')},
      {field: 'weightType', headerName: this.localizeHeader('weightType')}
    ];
  }
}
