<div class="bmw__grid">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 page-wrapper">
      <h2 class="bmw-h2 bmw__margin-top__l">{{ 'menu.pricing.order-management.title' | translate }}</h2>
      <div class="bmw__margin-top__l">
      </div>
      <div class="table-wrapper bmw__margin-top__s">
        <bmw-content-tab id="orderManagementTab" (selectionChange)="cascadeSearchData($event)">
          <bmw-content-tab-label for="currentOrder" [selected]="true">{{ 'menu.pricing.order-management.current.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="currentOrder">
            <app-order-latest-editable [routedOrderNumber]="orderNumber" [routedSnr]="snr"></app-order-latest-editable>
          </bmw-content-tab-content>
          <bmw-content-tab-label for="orderHistory">{{ 'menu.pricing.order-management.history.title' | translate }}</bmw-content-tab-label>
          <bmw-content-tab-content id="orderHistory">
            <order-history-component></order-history-component>
          </bmw-content-tab-content>
        </bmw-content-tab>
      </div>
    </div>
  </div>
</div>

