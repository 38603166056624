<div *ngIf="errorMessage" class="bmw__margin-top__l">
  <bmw-inline-message type="error">
    {{errorMessage}}
  </bmw-inline-message>
</div>
<div *ngIf="infoMessage" class="bmw__margin-top__l">
  <bmw-inline-message type="info">
    {{infoMessage}}
  </bmw-inline-message>
</div>
<bmw-progress-circle [fullScreen]="true" loadingLabel="Please Wait..." [show]="loadingScreen"></bmw-progress-circle>
<form [formGroup]="permissionsForm" class="reversed">
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 bmw__margin-top__l">
      <bmw-master-detail-list style="--list__item__col-size: 60% 40%;">
        <bmw-master-detail-list-row
          *ngFor="let row of rows"
          [selected]="selectedItem === row"
          (updateEvent)="selectedItem = row">
          <bmw-master-detail-list-row-entry label="{{ row.name }}"
                                            [bold]="true"></bmw-master-detail-list-row-entry>
          <bmw-master-detail-list-row-entry
            label="{{ row.description }}"
            [optional]="false"
          ></bmw-master-detail-list-row-entry>
        </bmw-master-detail-list-row>
        <!-- list-detail content is related to selected list-item -->
        <bmw-master-detail-list-detail *ngIf="selectedItem" (closeEvent)="selectedItem = undefined">
          <div details-header class="bmw-component-headline-text">Permission Configuration</div>
          <div *ngIf="display">
            <h3 class="bmw-h3 bmw__margin-top__m">{{ selectedItem.name }}</h3>
            <div class="bmw__grid__inner">
              <div class="bmw__grid__cell--span-5">
                <hr/>
                <p class="bmw-regular-text bmw__margin-top__s">
                   {{selectedItem.description}}
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="!display">
            <h2 class="bmw-h2 bmw__margin-top__m">New Permission</h2>
            <div class="bmw__grid__inner">
              <div class="bmw__grid__cell--span-5">
                <hr/>
                <div class="bmw__margin-top__m">
                  <bmw-inputfield id="permissionName">
                    <bmw-label>Name*</bmw-label>
                    <input
                      bmwInput
                      type="text"
                      name="permissionName"
                      placeholder="E.g.: T_FK_Read_PP"
                      formControlName="permissionName"
                    />
                  </bmw-inputfield>
                </div>
                <div class="bmw__margin-top__m">
                  <bmw-inputfield id="permissionText">
                    <bmw-label>Description*</bmw-label>
                    <input
                      bmwInput
                      name="permissionText"
                      type="text"
                      placeholder="Eg:. Production costs - read only - PP"
                      formControlName="permissionText"
                    />
                    <bmw-error-message>{{ 'menu.pricing.order-management.error-message.empty-field' | translate }}</bmw-error-message>
                  </bmw-inputfield>
                </div>
              </div>
            </div>
          </div>
          <div  *ngIf="!display" class="bmw__grid__inner">
            <div class="bmw__grid__cell--span-5">
              <div
                class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
                <div
                  class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-left">
                  <bmw-button button="secondary" type="submit" (clickEvent)="cancel()" >
                    {{ 'component.bottom-bar.cancel' | translate }}
                  </bmw-button>
                </div>
                <div
                  class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
                  <bmw-button button="primary" type="submit" (clickEvent)="onSave()" [disabled]="saveDisabled">
                    {{ 'component.bottom-bar.save' | translate }}
                  </bmw-button>
                </div>
              </div>
            </div>
          </div>
        </bmw-master-detail-list-detail>
      </bmw-master-detail-list>
    </div>
  </div>
  <div class="bmw__grid__inner">
    <div class="bmw__grid__cell bmw__grid__cell--span-12 bmw__margin-top__s">
      <div class="bmw__grid__cell--span-5">
        <div
          class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start">
          <div
            class="bmw__grid__cell bmw__grid__cell--span-8-mobile bmw__grid__cell--span-8-tablet bmw__grid__cell--span-8 pull-right">
            <bmw-button button="secondary" type="submit" (clickEvent)="onAddNew()" [disabled]="addDisabled" style="margin-left: 5px;">
              {{ 'component.bottom-bar.add' | translate }}
            </bmw-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>



