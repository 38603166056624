import {Component, OnInit} from '@angular/core';
import {PlantChangeService} from "../../header/service/PlantChangeService";
import {ProfileService} from "../service/profile.service";
import {Store} from "../../../shared/store";
import {faas} from "../../../constants/faas.constants";
import {Permission, PermissionDto, Role, Validity, WorkArea} from "../model/user.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Plant} from "../../order/model/order.model";
import moment from "moment";
import {FaasDatesHelper} from "../../../helper/faas.dates.helper";

@Component({
  selector: 'app-roles-overview',
  templateUrl: './roles-overview.component.html',
  styleUrls: ['./roles-overview.component.scss']
})
export class RolesOverviewComponent implements OnInit {

  rowData: any;
  loading: boolean = false;
  errorMessage: any;
  selectedRole: Role = null;
  hideAddNewRoleView: boolean = true;
  workArea = [
    {
      name: 'BMW',
      value: 'BMW'
    },
    {
      name: 'PRODUCTION_PARTNER',
      value: 'PRODUCTION_PARTNER'
    }
  ];
  permissionRawData: any;
  roleMaintenanceForm: FormGroup;
  min: Date;
  max: Date;
  period = {};
  runtimeCompilerData = {
    inputModel: [
      this.min,
      this.max
    ]
  };


  constructor(private service: ProfileService,
              private plantService: PlantChangeService) {

    this.plantService.onChange().subscribe(plant => {
      if (plant) {
        this.loadRoleForPlant();
      }
    });
  }

  ngOnInit(): void {
    this.roleMaintenanceForm = new FormGroup({
      roleName: new FormControl('', { validators: Validators.required }),
      workAreaText: new FormControl([''])
    });
    this.loadRoleForPlant();
  }

  private loadRoleForPlant() {
    this.rowData = undefined;
    this.errorMessage = undefined;

    this.service.getRolesForPlant().subscribe(({data, loading}) => {
      this.rowData = data.roles;
      this.hideAddNewRoleView = true;
      Store.saveInSessionCache<Role[]>(faas.userRolesForPlant, data.roles);
    }, (error) => {
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  addRole() {
    this.selectedRole = null;
    this.roleMaintenanceForm.setValue({
      roleName: "",
      workAreaText: ""
    });
    this.hideAddNewRoleView = false;
    this.runtimeCompilerData.inputModel[0] = new Date();
    this.runtimeCompilerData.inputModel[1] = moment().add(365,'days').toDate();
    this.period = this.runtimeCompilerData.inputModel;
    this.getAllPermissions();
  }

  editRole(id: number) {
    let cachedRoles = Store.getInSessionCache<Role[]>(faas.userRolesForPlant);
    cachedRoles.forEach( r => {
      if ( r.internalIdentifier == id ) {
        this.selectedRole = r;
        this.roleMaintenanceForm.setValue({
          roleName: r.name,
          workAreaText: r.workArea.toString()
        });
        this.runtimeCompilerData.inputModel[0] = FaasDatesHelper.convertStringToDate(r.validity?.fromDate);
        this.runtimeCompilerData.inputModel[1] = FaasDatesHelper.convertStringToDate(r.validity?.toDate);
        this.period = this.runtimeCompilerData.inputModel;
        this.getAllPermissions();
        this.hideAddNewRoleView = false;
        return;
      }
    })
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode === 39 || event.keyCode === 37) {
      event.stopPropagation();
    }
  }

  getAllPermissions() {
    this.errorMessage = undefined;
    this.service.getAllPermissions().subscribe(({data, loading}) => {
      if (this.selectedRole != null){
        let transformedModel = this.transformPermissionsForRoleOnEdit(data.permissions,this.selectedRole.permissions);
        this.permissionRawData = transformedModel;
      } else {
        this.permissionRawData = data.permissions;
      }
    }, (error) => {
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  cancel() {
    this.loadRoleForPlant();
    this.errorMessage = undefined;
    this.hideAddNewRoleView = true;
  }

  saveRole(e: Permission[]) {
    let role: Role;
    if (this.selectedRole == null) {
      this.roleMaintenanceForm.markAllAsTouched();
      if (this.roleMaintenanceForm.valid) {
        role = this.addOrModifyRole(false, e);
      }
    } else {
      role = this.addOrModifyRole(true, e);
    }
    this.persistRole(role);
  }

  persistRole(role: Role){
    this.loading = true;
    this.service.createRole(role).subscribe(({data}) => {
      this.errorMessage = undefined;
      this.loading = false;
      this.hideAddNewRoleView = true;
      this.loadRoleForPlant();
    }, error => {
      this.loading = false;
      this.errorMessage = this.service.removeGraphQLErrorOnMessage(error.message);
    });
  }

  addOrModifyRole(isModify: boolean = true,permissions: Permission[]): Role {
    let role = new Role();
    let validity = new Validity();

    validity.fromDate = FaasDatesHelper.formatFaasDate(this.dateFormatter(this.period[0]));
    validity.toDate = FaasDatesHelper.formatFaasDate(this.dateFormatter(this.period[1]));

    if (!isModify) {
      role.name = this.roleMaintenanceForm.get("roleName").value.toString();
      role.info = this.roleMaintenanceForm.get("roleName").value.toString();
      role.plant = Plant[window.sessionStorage.client];
    } else {
      role.internalIdentifier = this.selectedRole.internalIdentifier;
      role.name = this.selectedRole.name;
      role.info = this.selectedRole.info;
      role.plant = this.selectedRole.plant;
    }

    role.workArea = WorkArea[this.roleMaintenanceForm.get("workAreaText").value.toString()];
    role.validity = validity;
    role.permissions = permissions;

    return role;
  }

  dateFormatter(params) {
    return moment(params).format('YYYY-MM-DD');
  }

  transformPermissionsForRoleOnEdit(allPermissions: Permission[], selectedPermissions: Permission[] ) {
    let result: any = [];
    allPermissions.forEach(e => {
      let permission = new PermissionDto();
      permission.name = e.name;
      permission.internalIdentifier = e.internalIdentifier
      permission.description = e.description;
      permission.checked = false;
      selectedPermissions.forEach( i => {
        if ( e.internalIdentifier == i.internalIdentifier ){
          permission.checked = true;
        }
      });
      result.push(permission);
    });
    return result;
  }

  refreshData() {
    this.loadRoleForPlant();
  }
}
