import {OrdersViewModel} from "./OrdersViewModel";
import {Order, SurchargeViewModel} from "./order.model";
import {Transformer} from "../../../helper/transformer";

export class SurchargesForOrder extends Transformer {

  getSurchargesForOrder(orderNo: string,viewModel: OrdersViewModel[]): SurchargeViewModel[] {
    let surcharges: any = [];

    viewModel.forEach(p=> {
      if (p.orderNo == orderNo) {
        let _vm = new SurchargeViewModel();
        _vm.id = p.pricingPeriodInternalIdentifier;
        _vm.fromDate = p.fromDate;
        _vm.toDate = p.toDate;
        _vm.surchargeCategory = p.surchargeCategory;
        _vm.surchargeCurrency = p.surchargeCurrency;
        _vm.surchargeValue = p.surchargeValue;
        _vm.surchargeType = p.surchargeType;
        surcharges.push(_vm);
      }
    });
    this.sort(surcharges);
    return this.getUniqueSurchargesPrices(surcharges);
  }

  getSurcharges(orders: Order[]): SurchargeViewModel[] {
    let surcharges = this.transformOrdersForSurcharge(orders);
    this.sort(surcharges);
    return this.getUniqueSurchargesPrices(surcharges).sort( (a, b) => b.orderNo.localeCompare(a.orderNo));;
  }

  getUniqueSurchargesPrices(surcharges: SurchargeViewModel[]) {
    let map = new Map();
    for(let surcharge of surcharges) {
      map.set(surcharge.id,surcharge);
    }
    return Array.from(map.values());
  }

  sort(arr: SurchargeViewModel[]): void {
    arr.sort((a: SurchargeViewModel, b: SurchargeViewModel) => {
      return +new Date(a.fromDate) - +new Date(b.fromDate);
    });
  }




}
