import {ICellEditorAngularComp} from "ag-grid-angular";
import {Component} from "@angular/core";
import moment from "moment";

@Component({
  selector: 'date-editor-cell',
  template: `
    <div style="width:200px;min-width: 200px;max-width:200px;">
        <bmw-datepicker (onSelect)="onDateSelect($event)"
                        (onInput)="onInput($event)"
                        dateFormat="dd/mm/yyyy"
                        showButtonBar="true"
                        showWeek="true"
                        placeholder="DD.MM.YYYY">
        </bmw-datepicker>
    </div>
  `
})
export class AgGridCellDatePickerComponent implements ICellEditorAngularComp {
  private params: any;
  inputDate: string = "";

  public selectedDate: any;

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    return this.selectedDate;
  }

  isPopup(): boolean {
    return true;
  }

  onDateSelect(date) {
    this.selectedDate = this.dateFormatter(date);
    this.params.api.stopEditing();
  }

  onInput(params) {
    let input = params.data;
    //check if user inputs a non numeric character
    if (!this.isNumber(input)) return;
    this.inputDate += params.data;
    if ( this.inputDate.length == 8 ) {
      this.selectedDate = this.formatDate(this.inputDate);
      this.params.api.stopEditing();
    }
  }

  dateFormatter(params) {
    return moment(params).format('DD.MM.YYYY');
  }

  isNumber(s: string) {
    return Number.isInteger(parseFloat(s))
  }

  formatDate(input: string): string {
    let date = this.format(input);
    return moment(date,'DD.MM.YYYY').format('DD.MM.YYYY');
  }

  format(s: string): string {
    let characterArray = Array.from(s);
    let result = "";
    for(let i = 0 ; i < characterArray.length ; i++ ) {
      if ( i == 2 || i == 4 ) {
        result += "." + characterArray[i];
      } else {
        result += characterArray[i];
      }
    }
    return result;
  }
}
