import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit {

  constructor(breadcrumbsService: BreadcrumbsService) {
    breadcrumbsService.reset();
  }

  ngOnInit(): void {
  }

}
