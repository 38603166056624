import {Plant} from "../../order/model/order.model";

type Nullable<T> = T | null;

export class ProductionWeekUpdateRequest {
  snr: Nullable<string>;
  ai: Nullable<number>;
  plant: Plant;
  week: number;
  year: number;
  derivativeCode: string;
  isError: boolean;
  error: string;
  updateType: UpdateType;

  getMaterial(): MaterialInput {
    let material = new MaterialInput();
    material.plant = this.plant;
    material.ai = this.ai;
    material.snr = this.snr;
    return material;
  }
}

export class MaterialInput {
  ai: number;
  name: string;
  plant: Plant
  snr: string;
}

export interface ProductionWeek {
  year: number;
  week: number;
  plant: Plant;
  derivativeCode: string;
}

export class WeekDerivateCode {
  week: number;
  derivativeCode: string;
}

export interface ProductionWeekPriceResponse {
  ai: number;
  auditEventType: AuditEventType;
  createdDate: Date;
  derivativeCode: string;
  internalIdentifier: number;
  orderNo: string;
  plant: Plant;
  auditStatus: AuditStatus;
  snr: string;
  statusDate: Date;
  user: string;
  week: number;
  year: number;
}

export enum AuditEventType {
  EXCHANGE_RATES_UPDATED,
  ORDER_UPDATED,
  PART_UPDATED,
  PROCESSING,
  RECALCULATION_REQUEST,
  WEIGHTINGS_UPDATED
}

export enum AuditStatus {
  PROCESSED,
  UNPROCESSED,
  PROCESSING
}

export enum UpdateType {
  MaterialOnly = 0,
  MaterialAndWeek,
  DerivateAndWeekOnly,
  WeekOnly,
  MaterialAndDerivateAndWeekOnly
}
