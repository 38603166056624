<bmw-ag-grid-theme>
  <ag-grid-angular data-testid="grid-operations-display" *ngIf="rowData"
                   style="height: 500px; overflow: scroll"
                   class="zebra"
                   [columnDefs]="runtimeCompilerData.columns"
                   [rowData]="rowData"
                   [pagination]="true"
                   [rowMultiSelectWithClick]="false"
                   [suppressRowClickSelection]="true"
                   [gridOptions]="runtimeCompilerData.gridOptions"
                   [defaultColDef]="runtimeCompilerData.defaultColDef"
                   [sideBar]="runtimeCompilerData.sideBar"
                   [statusBar]="runtimeCompilerData.statusBar"
                   (gridReady)="onGridReady($event)"
                   (rowDataChanged)="onRefreshGridData($event)"
                   (firstDataRendered)="onFirstDataRendered($event)"
                   (modelUpdated)="onModelUpdated($event)"
                   [groupDefaultExpanded]="0"
                   [animateRows]="true"
                   [getRowStyle]="getRowStyle"
  >
  </ag-grid-angular>
  <div><br/></div>
  <div class="bmw__grid__cell bmw__grid__cell--span-12-mobile bmw__grid__cell--span-12 form-template-grid-start bmw__margin-top__m">
    <div class="pull-left">
      <bmw-button button="secondary" (clickEvent)="onClearFilters()" class="bmw__margin-right__xs">
        {{ 'menu.manufacturing-cost.tabs.transfer-price.refresh' | translate }}
      </bmw-button>
      <bmw-button button="secondary" (clickEvent)="onExcelDownload()">
        {{ 'menu.manufacturing-cost.tabs.transfer-price.download' | translate }}
      </bmw-button>
    </div>
  </div>
</bmw-ag-grid-theme>
